export const GET_ALL_MCHAT_BEGIN = "GET_ALL_MCHAT_BEGIN"
export const GET_ALL_MCHAT_SUCCESS = "GET_ALL_MCHAT_SUCCESS"
export const GET_ALL_MCHAT_ERROR = "GET_ALL_MCHAT_ERROR"

export const ADD_MCHAT_BEGIN = "ADD_MCHAT_BEGIN"
export const ADD_MCHAT_SUCCESS = "ADD_MCHAT_SUCCESS"
export const ADD_MCHAT_ERROR = "ADD_MCHAT_ERROR"

export const GET_MCHAT_BEGIN = "GET_MCHAT_BEGIN"
export const GET_MCHAT_SUCCESS = "GET_MCHAT_SUCCESS"
export const GET_MCHAT_ERROR = "GET_MCHAT_ERROR"

export const UPDATE_MCHAT_BEGIN = "UPDATE_MCHAT_BEGIN"
export const UPDATE_MCHAT_SUCCESS = "UPDATE_MCHAT_SUCCESS"
export const UPDATE_MCHAT_ERROR = "UPDATE_MCHAT_ERROR"

export const DELETE_MCHAT_BEGIN = "DELETE_MCHAT_BEGIN"
export const DELETE_MCHAT_SUCCESS = "DELETE_MCHAT_SUCCESS"
export const DELETE_MCHAT_ERROR = "DELETE_MCHAT_ERROR"

export const ADD_MCHAT_FORM_BEGIN = "ADD_MCHAT_FORM_BEGIN"
export const ADD_MCHAT_FORM_SUCCESS = "ADD_MCHAT_FORM_SUCCESS"
export const ADD_MCHAT_FORM_ERROR = "ADD_MCHAT_FORM_ERROR"
