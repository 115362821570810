import React from "react";
import { useNavigate } from "react-router-dom";
import DashboardLayout from "../../layout/dashboardLayout";

const Forbidden = () => {
  const navigate = useNavigate();
  return (
    <DashboardLayout>
      <div className="flex justify-center mt-48 items-center flex-col space-y-3">
        <h1 className="text-6xl text-red-500 font-bold">401</h1>
        <h3 className="text-3xl">Unauthorized</h3>
        <p>You are not authorized to access this content.</p>
        <div className="flex space-x-2">
          <button
            onClick={() => navigate("/")}
            className="p-2 bg-red-500 text-white rounded-md"
          >
            Contact Support
          </button>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default Forbidden;
