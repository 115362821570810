import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import { ArrowUturnLeftIcon } from "@heroicons/react/20/solid";
import { useDispatch, useSelector } from "react-redux";
import {
  addFormulation,
  getFormulationWithId,
  updateFormulation,
} from "../../../redux/formulation/actions";
import { FallingLinesLoader } from "../../../components/spinners/Spinner";
import Breadcrumb from "../../../components/Breadcrumb";

const AddFormulation = () => {
  const pages = [{ title: "Formulation", href: "/formulation-master" }];

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id: formulationId } = useParams();
  const { loading, FormulationData } = useSelector(
    (state) => state.formulation
  );

  const initialValues = {
    formulation: "",
  };

  const [formState, setFormState] = useState(initialValues);

  useEffect(() => {
    document.title = "New Horizon | Add-Formulation";
    if (formulationId) dispatch(getFormulationWithId(formulationId));
  }, [formulationId]);

  useEffect(() => {
    if (formulationId && FormulationData) {
      const newFormState = {
        formulation: FormulationData.formulation,
      };
      setFormState(newFormState);
    }
  }, [formulationId, FormulationData]);

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      enableReinitialize: true,
      initialValues: formState,
      onSubmit: (values, action) => {
        // TODO: Optimize code here
        const body = {
            formulation: values.formulation,
        };
        if (formulationId) dispatch(updateFormulation(formulationId, body));
        else dispatch(addFormulation(body));
        action.resetForm();
        navigate("/formulation-master");
      },
    });
  // console.log(errors);
  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <Breadcrumb pages={pages} />
      <div>
        <button
          type="button"
          onClick={() => navigate(-1)}
          className="inline-flex items-center rounded-md border mb-6 border-transparent bg-indigo-100 px-3 py-2 text-sm font-medium leading-4 text-indigo-700 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
        >
          <span className="w-5 mr-2">
            <ArrowUturnLeftIcon size={18} />
          </span>{" "}
          Back
        </button>
        <div className="sm:flex-auto">
          <h1 className="text-xl font-semibold text-gray-900">
            {formulationId ? "Update Formulation" : "Add Formulation"}
          </h1>
        </div>
      </div>
      {loading ? (
        <FallingLinesLoader />
      ) : (
        <form
          onSubmit={handleSubmit}
          className="space-y-8 divide-y divide-gray-200"
        >
          <div className="space-y-6 sm:space-y-5">
            <div className="space-y-6 sm:space-y-5 sm:pt-5">
              <div className="space-y-6 sm:space-y-5">
                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                  <label
                    htmlFor="formulation"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Formulation
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                  <input
                    type="text"
                    name="formulation"
                    id="formulation"
                    autoComplete="off"
                    onChange={handleChange}
                    placeholder="formulation"
                    onBlur={handleBlur}
                    value={values.formulation}
                    className="block w-1/2 min-w-0 flex-1 rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  />
                    {errors.formulation && touched.formulation ? (
                      <p className="text-red-700">{errors.formulation}</p>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="pt-5">
            <div className="flex justify-end">
              <button
                type="submit"
                className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              >
                Submit
              </button>
            </div>
          </div>
        </form>
      )}
    </div>
  );
};

export default AddFormulation;
