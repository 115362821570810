import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { ArrowUturnLeftIcon } from "@heroicons/react/20/solid";
import "easymde/dist/easymde.min.css";
import { useDispatch, useSelector } from "react-redux";
import {
  addProgram,
  getProgramWithId,
  updateProgram,
} from "../../../redux/ProgramMaster/actions";
import { getAllDepartments } from "../../../redux/department/actions";
import { FallingLinesLoader } from "../../../components/spinners/Spinner";
import Breadcrumb from "../../../components/Breadcrumb";
import { getAllAgeGroups } from "../../../redux/AgeGroupMaster/actions";
import axios from "axios";
import { toast } from "react-hot-toast";
import { getHeaders } from "../../../redux/auth/actions";
import SimpleMDE from "react-simplemde-editor";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
const api = process.env.REACT_APP_API_URL;
const AddActivity = () => {
  const pages = [{ title: "Activity Master", href: "/locations" }];
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { id: actId } = useParams();
  const { loading, list: ageGroupList } = useSelector(
    (state) => state.ageGroup
  );
  const initialValues = {
    module_id: "",
    age_group_id: "",
    domain_module_title: "",
  };

  const [modList, setModList] = useState({
    loading: true,
    data: [],
  });

  function extractValues(arr, name) {
    return arr.flatMap((item) => (item[name] !== undefined ? item[name] : []));
  }
  function createObjectsFromValues(values, name) {
    return values.map((value) => ({ [name]: value }));
  }
  const [formState, setFormState] = useState(initialValues);
  const [inputList, setInputList] = useState([
    {
      id: "",
      title: "",
      description: "",
    },
  ]);
  const [milestonesList, setMilestonesList] = useState([
    {
      milestones: "",
    },
  ]);
  const [concernList, setConcernList] = useState([
    {
      concern_targets: "",
    },
  ]);
  const [materialList, setmaterialList] = useState([
    {
      material_required: "",
    },
  ]);
  // milestones starts
  const handleAddMileStoneClick = () => {
    setMilestonesList([
      ...milestonesList,
      {
        milestones: "",
      },
    ]);
  };

  const handleMilestoneChange = (event, index) => {
    const newDomainList = [...milestonesList];
    newDomainList[index].milestones = event.target.value;
    setMilestonesList(newDomainList);
  };
  const handleRemoveClickMilestone = (index) => {
    let list = inputList;
    const finalList = milestonesList.filter((ele, i) => i !== index);
    setMilestonesList([...finalList]);
  };
  // milestones ends

  // concern starts
  const handleconcernClick = () => {
    setConcernList([
      ...concernList,
      {
        concern_targets: "",
      },
    ]);
  };

  const handleconcernChange = (event, index) => {
    const newDomainList = [...concernList];
    newDomainList[index].concern_targets = event.target.value;
    setConcernList(newDomainList);
  };
  const handleRemoveClickConcern = (index) => {
    let list = inputList;
    const finalList = concernList.filter((ele, i) => i !== index);
    setConcernList([...finalList]);
  };

  // concern ends

  // material starts

  const handlematerialClick = () => {
    setmaterialList([
      ...materialList,
      {
        material_required: "",
      },
    ]);
  };

  const handleMaterialChange = (event, index) => {
    const newDomainList = [...materialList];
    newDomainList[index].material_required = event.target.value;
    setmaterialList(newDomainList);
  };
  const handleRemoveClickMaterial = (index) => {
    let list = inputList;
    const finalList = materialList.filter((ele, i) => i !== index);
    setmaterialList([...finalList]);
  };

  // material ends

  const handleAddClick = () => {
    setInputList([
      ...inputList,
      {
        title: "",
        description: "",
      },
    ]);
  };
  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    let list = [...inputList];
    list[index][name] = value;
    setInputList(list);
  };
  function extractTitleAndDescription(arr) {
    let result = [];
    for (let i = 0; i < arr.length; i++) {
      let item = arr[i];
      result.push({
        id: item.id,
        title: item.title,
        description: item.description,
      });
    }
    return result;
  }

  const handleRemoveClick = (index) => {
    let list = inputList;
    const finalList = inputList.filter((ele, i) => i !== index);
    console.log(finalList, "finalList");
    // list.splice(index, 0);
    setInputList([...finalList]);
  };
  useEffect(() => {
    dispatch(getAllAgeGroups());
    getAllModules();
    document.title = "New Horizon | Activity Master";
  }, []);

  const getAllModules = async () => {
    const headers = await dispatch(getHeaders());
    setModList((prev) => ({ ...prev, loading: true }));
    try {
      const res = await axios.get(`${api}/api/module-master`, {
        headers: headers,
      });
      const { data, status } = res;
      if (status === 200) {
        setModList((prev) => ({
          ...prev,
          data: data.data,
          loading: false,
        }));
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const createActivity = async (payload, action) => {
    const res = await axios.post(`${api}/api/activity`, payload, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    if (res.status === 201) {
      await toast.success("Activity created successfully");
      action.resetForm();
      navigate("/activity-master");
    }
  };
  const updateActivity = async (payload, action) => {
    const res = await axios.put(`${api}/api/activity/${actId}`, payload, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    if (res.status === 200) {
      await toast.success("Activity Updated successfully");
      action.resetForm();
      // navigate("/activity-master");
    }
  };
  useEffect(() => {
    if (actId) {
      const fetchActivity = async () => {
        const res = await axios.get(`${api}/api/activity/${actId}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });

        if (res.status === 201) {
          const {
            domain_module_title,
            age_group_id,
            module_id,
            milestones,
            concern_targets,
            material_required,
            ActivityDescriptionMaster,
          } = res.data.data;

          setFormState({
            age_group_id,
            module_id,
            domain_module_title,
          });
          setMilestonesList(createObjectsFromValues(milestones, "milestones"));
          setConcernList(
            createObjectsFromValues(concern_targets, "concern_targets")
          );
          setmaterialList(
            createObjectsFromValues(material_required, "material_required")
          );
          setInputList(extractTitleAndDescription(ActivityDescriptionMaster));
        }
      };
      fetchActivity();
    }
  }, [actId]);
  const [Submitloading, setLoading] = useState(false);
  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    isSubmitting,
  } = useFormik({
    enableReinitialize: true,
    initialValues: formState,
    validationSchema: Yup.object({
      age_group_id: Yup.string().required("Required"),
      domain_module_title: Yup.string().required("Required"),
      module_id: Yup.string().required("Required"),
    }),
    onSubmit: async (values, action) => {
      // TODO: Optimize code here
      const body = {
        age_group_id: parseInt(values.age_group_id),
        module_id: Number(values.module_id),
        activity_domain_master: {
          domain_module_title: values.domain_module_title,
          milestones: extractValues(milestonesList, "milestones"),
          concern_targets: extractValues(concernList, "concern_targets"),
          material_required: extractValues(materialList, "material_required"),
        },
        activity_description_master: actId
          ? inputList
          : inputList.map((item) => ({
              title: item.title,
              description: item.description,
            })),
      };

      if (actId) await updateActivity(body, action);
      else await createActivity(body, action);
      action.setSubmitting(false);
      // if (programId) dispatch(updateProgram(programId, body));
      // else dispatch(addProgram(body));
      // action.resetForm();
      // navigate("/program-master");
    },
  });
  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <Breadcrumb pages={pages} />
      <div>
        <button
          type="button"
          onClick={() => navigate(-1)}
          className="inline-flex items-center rounded-md border mb-6 border-transparent bg-indigo-100 px-3 py-2 text-sm font-medium leading-4 text-indigo-700 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
        >
          <span className="w-5 mr-2">
            <ArrowUturnLeftIcon size={18} />
          </span>{" "}
          Back
        </button>
        <div className="sm:flex-auto">
          <h1 className="text-xl font-semibold text-gray-900">
            {actId ? "Update Activity" : "Add Activity"}
          </h1>
        </div>
      </div>
      {loading ? (
        <FallingLinesLoader />
      ) : (
        <form
          onSubmit={handleSubmit}
          className="space-y-8 divide-y divide-gray-200"
        >
          <div className="space-y-6 sm:space-y-5">
            <div className="space-y-6 sm:space-y-5 sm:pt-5">
              <div className="space-y-6 sm:space-y-5">
                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                  <label
                    htmlFor="name"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Age Group
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <select
                      name="age_group_id"
                      autoComplete="off"
                      value={values.age_group_id}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                    >
                      <option value="">Select Age Group</option>
                      {ageGroupList.map((ageGroup) => (
                        <option value={ageGroup.id}>{ageGroup.name}</option>
                      ))}
                    </select>
                    {errors.age_group_id && touched.age_group_id ? (
                      <p className="text-red-700">{errors.age_group_id}</p>
                    ) : null}
                  </div>
                </div>
                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                  <label
                    htmlFor="name"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Modules
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <select
                      name="module_id"
                      autoComplete="off"
                      value={values.module_id}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                    >
                      <option value="">Select Modules</option>
                      {modList.data.map((mod) => (
                        <option value={mod.id}>{mod.name}</option>
                      ))}
                    </select>
                    {errors.age_group_id && touched.age_group_id ? (
                      <p className="text-red-700">{errors.age_group_id}</p>
                    ) : null}
                  </div>
                </div>

                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                  <label
                    htmlFor="name"
                    className="block text-md font-medium text-gray-800 sm:mt-px sm:pt-2"
                  >
                    Activity Domain
                  </label>
                </div>

                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4  sm:pt-5">
                  <label
                    htmlFor="domain_module_title"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Domain Module Title
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <input
                      type="text"
                      name="domain_module_title"
                      placeholder="Domain Module Title"
                      id="domain_module_title"
                      autoComplete="off"
                      value={values.domain_module_title}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="block w-full mr-2 max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                    />
                    {errors.domain_module_title &&
                    touched.domain_module_title ? (
                      <p className="text-red-700">
                        {errors.domain_module_title}
                      </p>
                    ) : null}
                  </div>
                </div>
                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4  sm:pt-5">
                  <label
                    htmlFor="name"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    MileStone
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <button
                      type="button"
                      className=" inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-"
                      onClick={handleAddMileStoneClick}
                    >
                      Add more
                    </button>
                  </div>
                </div>
                {milestonesList.map((x, index) => (
                  <div
                    key={index}
                    className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4  sm:pt-5"
                  >
                    <label
                      htmlFor="milestones"
                      className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                    ></label>
                    <div className="mt-1 w-full flex sm:col-span-2 sm:mt-0">
                      <input
                        type="text"
                        name="milestones"
                        placeholder="milestones"
                        id="milestones"
                        autoComplete="off"
                        value={x.milestones}
                        onChange={(e) => handleMilestoneChange(e, index)}
                        onBlur={handleBlur}
                        className="block w-full mr-2 max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                      />

                      <button
                        type="button"
                        className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-"
                        onClick={() => handleRemoveClickMilestone(index)}
                      >
                        Remove
                      </button>
                    </div>
                  </div>
                ))}
                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4  sm:pt-5">
                  <label
                    htmlFor="name"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Concern Target
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <button
                      type="button"
                      className=" inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-"
                      onClick={handleconcernClick}
                    >
                      Add more
                    </button>
                  </div>
                </div>
                {concernList.map((x, index) => (
                  <div
                    key={index}
                    className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4  sm:pt-5"
                  >
                    <label
                      htmlFor="milestones"
                      className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                    ></label>
                    <div className="mt-1 w-full flex sm:col-span-2 sm:mt-0">
                      <input
                        type="text"
                        name="concern_targets"
                        placeholder="concern targets"
                        id="concern_targets"
                        autoComplete="off"
                        value={x.concern_targets}
                        onChange={(e) => handleconcernChange(e, index)}
                        onBlur={handleBlur}
                        className="block w-full mr-2 max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                      />

                      <button
                        type="button"
                        className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-"
                        onClick={() => handleRemoveClickConcern(index)}
                      >
                        Remove
                      </button>
                    </div>
                  </div>
                ))}
                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4  sm:pt-5">
                  <label
                    htmlFor="name"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Material Required
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <button
                      type="button"
                      className=" inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-"
                      onClick={handlematerialClick}
                    >
                      Add more
                    </button>
                  </div>
                </div>
                {materialList.map((x, index) => (
                  <div
                    key={index}
                    className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4  sm:pt-5"
                  >
                    <label
                      htmlFor="milestones"
                      className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                    ></label>
                    <div className="mt-1 w-full flex sm:col-span-2 sm:mt-0">
                      <input
                        type="text"
                        name="material_required"
                        placeholder="Material Required"
                        id="material_required"
                        autoComplete="off"
                        value={x.material_required}
                        onChange={(e) => handleMaterialChange(e, index)}
                        onBlur={handleBlur}
                        className="block w-full mr-2 max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                      />

                      <button
                        type="button"
                        className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-"
                        onClick={() => handleRemoveClickMaterial(index)}
                      >
                        Remove
                      </button>
                    </div>
                  </div>
                ))}
                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                  <label
                    htmlFor="name"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Activity Description Master
                  </label>
                </div>
                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4  sm:pt-5">
                  <label
                    htmlFor="name"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Activity Description Master
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <button
                      type="button"
                      className=" inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-"
                      onClick={handleAddClick}
                    >
                      Add more
                    </button>
                  </div>
                </div>
                {inputList.map((x, index) => (
                  <div
                    key={index}
                    className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4  sm:pt-5"
                  >
                    <label
                      htmlFor="name"
                      className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                    ></label>
                    <div className="mt-1 w-full flex flex-col sm:col-span-2 sm:mt-0">
                      <input
                        type="text"
                        name="title"
                        id="title"
                        value={x.title}
                        autoComplete="off"
                        onChange={(e) => handleInputChange(e, index)}
                        placeholder="Title"
                        onBlur={handleBlur}
                        className="block w-full mr-2 mb-2 max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                      />

                      {/* <input
                        type="text"
                        name="description"
                        id="description"
                        value={x.description}
                        placeholder="Description"
                        autoComplete="off"
                        onChange={(e) => handleInputChange(e, index)}
                        onBlur={handleBlur}
                        className="block w-full mr-2 max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                      /> */}
                      {/* <SimpleMDE
                        value={x.description}
                        placeholder="Description"
                        onChange={(e) => {
                          let d = {
                            target: {
                              name: "description",
                              value: e,
                            },
                          };

                          
                        }}
                      /> */}
                      <div className="mt-4 h-[50vh] pb-12">
                        <ReactQuill
                          rows={3}
                          className="h-[100%] w-[100%] "
                          theme="snow"
                          value={x.description}
                          placeholder="Description"
                          onChange={(e) => {
                            let d = {
                              target: {
                                value: e,
                                name: "description",
                              },
                            };
                            console.log(e);
                            handleInputChange(d, index);
                          }}
                        />
                      </div>
                      <button
                        type="button"
                        className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-"
                        onClick={() => handleRemoveClick(index)}
                      >
                        Remove
                      </button>
                      {/* )} */}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className="pt-5">
            <div className="flex justify-end">
              <button
                disabled={isSubmitting}
                type="submit"
                className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              >
                {isSubmitting ? "submitting...." : "Submit"}
              </button>
            </div>
          </div>
        </form>
      )}
    </div>
  );
};

export default AddActivity;
