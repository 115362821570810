export const GET_ALL_DOCTORS_BEGIN = "GET_ALL_DOCTORS_BEGIN"
export const GET_ALL_DOCTORS_SUCCESS = "GET_ALL_DOCTORS_SUCCESS"
export const GET_ALL_DOCTORS_ERROR = "GET_ALL_DOCTORS_ERROR"

export const ADD_DOCTOR_BEGIN = "ADD_DOCTOR_BEGIN"
export const ADD_DOCTOR_SUCCESS = "ADD_DOCTOR_SUCCESS"
export const ADD_DOCTOR_ERROR = "ADD_DOCTOR_ERROR"

export const GET_DOCTOR_BEGIN = "GET_DOCTOR_BEGIN"
export const GET_DOCTOR_SUCCESS = "GET_DOCTOR_SUCCESS"
export const GET_DOCTOR_ERROR = "GET_DOCTOR_ERROR"

export const UPDATE_DOCTOR_BEGIN = "UPDATE_DOCTOR_BEGIN"
export const UPDATE_DOCTOR_SUCCESS = "UPDATE_DOCTOR_SUCCESS"
export const UPDATE_DOCTOR_ERROR = "UPDATE_DOCTOR_ERROR"

export const DELETE_DOCTOR_BEGIN = "DELETE_DOCTOR_BEGIN"
export const DELETE_DOCTOR_SUCCESS = "DELETE_DOCTOR_SUCCESS"
export const DELETE_DOCTOR_ERROR = "DELETE_DOCTOR_ERROR"