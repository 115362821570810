export const GET_ALL_EXCEPTION_BEGIN = "GET_ALL_EXCEPTION_BEGIN"
export const GET_ALL_EXCEPTION_SUCCESS = "GET_ALL_EXCEPTION_SUCCESS"
export const GET_ALL_EXCEPTION_ERROR = "GET_ALL_EXCEPTION_ERROR"

export const ADD_EXCEPTION_BEGIN = "ADD_EXCEPTION_BEGIN"
export const ADD_EXCEPTION_SUCCESS = "ADD_EXCEPTION_SUCCESS"
export const ADD_EXCEPTION_ERROR = "ADD_EXCEPTION_ERROR"

export const GET_EXCEPTION_BEGIN = "GET_EXCEPTION_BEGIN"
export const GET_EXCEPTION_SUCCESS = "GET_EXCEPTION_SUCCESS"
export const GET_EXCEPTION_ERROR = "GET_EXCEPTION_ERROR"

export const UPDATE_EXCEPTION_BEGIN = "UPDATE_EXCEPTION_BEGIN"
export const UPDATE_EXCEPTION_SUCCESS = "UPDATE_EXCEPTION_SUCCESS"
export const UPDATE_EXCEPTION_ERROR = "UPDATE_EXCEPTION_ERROR"

export const DELETE_EXCEPTION_BEGIN = "DELETE_EXCEPTION_BEGIN"
export const DELETE_EXCEPTION_SUCCESS = "DELETE_EXCEPTION_SUCCESS"
export const DELETE_EXCEPTION_ERROR = "DELETE_EXCEPTION_ERROR"