import React, { useState, Component } from 'react';
import Select from 'react-select';
const Hotels = [
  { value: 1, label: 'Coral Beach Maldives' },
  { value: 2, label: 'Ilaa Beach Maldives' },
  { value: 3, label: 'Finolhu' },
  { value: 4, label: 'Arena' },
  { value: 5, label: 'Kaani Beach Hotel' },
];
export default function Relation_master(props) {
  const [selectedOptions, setSelectedOptions] = useState(null);
  let data = props.config;

  const setHandle = (e) => {
    setSelectedOptions(Array.isArray(e) ? e.map((hotel) => hotel.label) : []);
    if (props.flag == true) {
      {
        props.record_change(
          Array.isArray(e) ? e.map((hotel) => hotel.label).join() : [],
          data.ref_id
        );
      }
    }
  };

  let options = Object.keys(data.options).map((k) => {
    return { value: data.options[k], label: data.options[k] };
  });
  return (
    <>
      <div className="sm:grid sm:grid-cols-3 sm:items-start   sm:border-gray-200 ">
        <label
          htmlFor="country"
          className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
        >
          {data.label}
        </label>
        <div className="mt-1 sm:col-span-2 sm:mt-0">
          <div className=" px-2 w-80">
            <Select
              width={100}
              options={options}
              onChange={setHandle}
              isMulti
            />
          </div>
        </div>
        {/* <select
            onChange={(e) => {
              props.record_change(e.target.value, data.ref_id);
            }}
            id="country"
            name="country"
            autoComplete="country-name"
            className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
          >
            {Object.keys(data.options).map((val) => {
              {
              }
              return (
                <option value={data.options[val]}>{data.options[val]}</option>
              );
            })}
          </select> */}
      </div>
    </>
  );
}
