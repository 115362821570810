import { React, useState } from "react";
import { BellIcon, BellSlashIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";
import { toast } from "react-hot-toast";
import { useDispatch } from "react-redux";
import { getHeaders } from "../../../redux/auth/actions";

function Notifications({ data }) {
  const [isRead, setIsRead] = useState(false);

  const [getTabsIndex, setActiveTabIndex] = useState(0);

  function notificationChange(...notificationChangeclass) {
    return notificationChangeclass.filter(Boolean).join(" ");
  }

  const tabs = [
    {
      name: "All",
      href: "all",
      count: data?.fNotifications ? data?.fNotifications?.length : "0",
      current: true,
    },
    {
      name: "Read",
      href: "read",
      count: data?.readed_notifications
        ? data?.readed_notifications?.length
        : "0",
      icon: BellIcon,
      current: false,
    },
    {
      name: "Unread",
      href: "unread",
      count: data?.unread_notifications
        ? data?.unread_notifications?.length
        : "0",
      icon: BellSlashIcon,
      current: false,
    },
  ];
  const [getTabs, setActiveTab] = useState(tabs);
  const activeTabStatus = (tab, index) => {
    setActiveTabIndex(index);
    setActiveTab(
      tabs.map((item) =>
        item.name === tab.name
          ? { ...item, current: true }
          : { ...item, current: false }
      )
    );
  };
  const dispatch = useDispatch();
  async function handleMarkAsRead(id) {
    const header = await dispatch(getHeaders());
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/notification/${id}`,
      {
        method: "PUT",
        headers: header,
      }
    );
    const data = await response.json();
    toast.success(data.message);
    setIsRead(true);
  }

  console.log(tabs);
  return (
    <div>
      <div>
        <div className="sm:flex-auto">
          <h1 className="text-xl font-semibold text-gray-900">Notifications</h1>
        </div>
        <div className="hidden sm:block">
          <div className="border-b border-gray-200">
            <nav className="-mb-px flex space-x-8" aria-label="Tabs">
              {getTabs?.map((tab, index) => (
                <Link
                  onClick={() => activeTabStatus(tab, index)}
                  key={index}
                  className={notificationChange(
                    tab.current
                      ? "border-indigo-500 text-indigo-600"
                      : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200",
                    "whitespace-nowrap flex py-4 px-1 border-b-2 font-medium text-sm"
                  )}
                  aria-current={tab.current ? "page" : undefined}
                >
                  {tab.icon ? (
                    <div>
                      <tab.icon
                        className={notificationChange(
                          tab.current
                            ? "text-indigo-500"
                            : "text-gray-400 group-hover:text-gray-500",
                          "-ml-0.5 mr-2 h-5 w-5"
                        )}
                        aria-hidden="true"
                      />
                    </div>
                  ) : null}

                  {tab.name}
                  {tab.count ? (
                    <span
                      className={notificationChange(
                        tab.current
                          ? "bg-indigo-100 text-indigo-600"
                          : "bg-gray-100 text-gray-900",
                        "hidden ml-3 py-0.5 px-2.5 rounded-full text-xs font-medium md:inline-block"
                      )}
                    >
                      {tab.count}
                    </span>
                  ) : null}
                </Link>
              ))}
            </nav>
          </div>
        </div>
      </div>

      {getTabs?.map((tab, index) => (
        <div
          className={`${getTabsIndex == index ? "mt-6 flow-root " : "hidden"}`}
        >
          <ul role="list" className="-my-5 divide-gray-200">
            {tab?.href === "all" && tab?.current
              ? data?.fNotifications?.slice(0, 4).map((person, key) => (
                  <li
                    key={person.key}
                    className={`${"p-4 border-b hover:bg-gray-100 cursor-pointer"}`}
                  >
                    <div className="flex items-center space-x-4">
                      {/* <div className="flex-shrink-0">
                <img
                  className="h-8 w-8 rounded-full"
                  src={person.imageUrl}
                  alt=""
                />
              </div> */}
                      {console.log(person, "====")}
                      <Link
                        to={
                          person.action == "PATIENT_VIEW"
                            ? `/view-patient/${person.patient_id}`
                            : person.action == "ENROLLMENT_PATIENT_VIEW"
                            ? `/patient-view/${person.enrollment_id}`
                            : "/"
                        }
                        className="min-w-0 flex-1"
                      >
                        <p className="truncate text-sm font-medium text-gray-900">
                          {person.title}
                        </p>
                        <p className="truncate text-sm text-gray-500">
                          {person.body}
                        </p>
                      </Link>
                      <div
                        className="text-indigo-400 cursor-pointer hover:text-indigo-700"
                        onClick={() => handleMarkAsRead(person.id)}
                      >
                        Mark read
                      </div>
                    </div>
                  </li>
                ))
              : null}
            {tab?.href === "read" && tab?.current
              ? data?.readed_notifications?.slice(0, 4).map((person, key) => (
                  <li
                    key={key}
                    className={`${"p-4 border-b hover:bg-gray-100 cursor-pointer"}`}
                  >
                    <Link
                      to={
                        person.action == "PATIENT_VIEW"
                          ? `/view-patient/${person.patient_id}`
                          : person.action === "ENROLLMENT_PATIENT_VIEW"
                          ? `/patient-view/${person.enrollment_id}`
                          : "/"
                      }
                      className="min-w-0 flex-1"
                    >
                      <p className="truncate text-sm font-medium text-gray-900">
                        {person.title}
                      </p>
                      <p className="truncate text-sm text-gray-500">
                        {person.body}
                      </p>
                    </Link>
                  </li>
                ))
              : null}
            {tab?.href === "unread" && tab?.current
              ? data?.unread_notifications?.slice(0, 4).map((person, key) => (
                  <li
                    key={
                      person.action && person.data
                        ? person.action === "ENROLLMENT_PATIENT_VIEW"
                          ? `patient-view/${data.enrollment_id}`
                          : person.action === "PATIENT_VIEW"
                          ? `view-patient/${data.patient_id}`
                          : "/"
                        : "/"
                    }
                    className={`${"p-4 border-b hover:bg-gray-100 cursor-pointer"}`}
                  >
                    <div className="flex items-center space-x-4">
                      {/* <div className="flex-shrink-0">
                    <img
                      className="h-8 w-8 rounded-full"
                      src={person.imageUrl}
                      alt=""
                    />
                  </div> */}
                      <Link
                        to={
                          person.action == "PATIENT_VIEW"
                            ? `/view-patient/${person.patient_id}`
                            : person.action == "ENROLLMENT_PATIENT_VIEW"
                            ? `/patient-view/${person.enrollment_id}`
                            : "/"
                        }
                        className="min-w-0 flex-1"
                      >
                        <p className="truncate text-sm font-medium text-gray-900">
                          {person.title}
                        </p>
                        <p className="truncate text-sm text-gray-500">
                          {person.body}
                        </p>
                      </Link>
                      <div
                        className="text-indigo-400 cursor-pointer hover:text-indigo-700"
                        onClick={() => handleMarkAsRead(person.id)}
                      >
                        Mark read
                      </div>
                    </div>
                  </li>
                ))
              : null}
          </ul>
        </div>
      ))}
      <div className="mt-6">
        <Link
          to="/notifications"
          className="flex w-full items-center justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50"
        >
          View all
        </Link>
      </div>
    </div>
  );
}

export default Notifications;
