import { useState, useEffect } from 'react';
import { set } from 'react-hook-form';
export default function Checkbox_input(props) {
  let data = props.config;
  const [checked, setchecked] = useState(props.current_data[data.ref_id]&& props.current_data[data.ref_id].length > 0 && props.current_data[data.ref_id].includes(',')
      ? props.current_data[data.ref_id].split(',')
      : [props.current_data[data.ref_id]]
  );
  let [visibility, set_visibility] = useState(true);

  useEffect(() => {
    if (data.child) {
      let foi = Object.keys(props.all_fields).filter((field) => {
        if (props.all_fields[field].label.trim() == data.parent?.label.trim()) {
          return props.all_fields[field];
        }
      });
      if (
        props.current_data[props.all_fields[foi]?.ref_id] == data.parent?.option
      ) {
        set_visibility(true);
      } else {
        if (props.current_data[data.ref_id] != null || data.previous_value != null){
          props.record_change(null, data.ref_id);
          data.previous_value=null;
        }
        set_visibility(false);
      }
    }
  }, [props.current_data]);
  useEffect(() => {
    if (props.record_change) {
      props.record_change(checked.toString(), data.ref_id);
    }
  }, [checked]);
  function process_checkbox(e) {
    if (e.target.checked) {
      setchecked((checked) => [...checked, e.target.value]);
    } else if (e.target.checked == false) {
      let buf_arr = checked;
      let new_arr = buf_arr.filter((sin) => {
        if (sin != e.target.value) {
          return true;
        } else {
          return false;
        }
      });
      setchecked(new_arr);

      // setchecked();
    }
  }
  return (
    <>
      {visibility ? (
        <div className="border-b pl-5 pb-5">
          <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4  sm:border-gray-200 ">
            <div>
              <div
                className="text-base font-medium text-gray-900 sm:text-sm sm:text-gray-700"
                id="label-email"
              >
                {data.label}{' '}
              </div>
            </div>
            <div className="mt-4 sm:col-span-2 sm:mt-0">
              <div className="grid grid-cols-2 max-w-lg space-y-2">
                {Object.keys(data.options).map((val, index) => {
                  return (
                    <>
                      <div key={index} className="pt-6 sm:pt-5">
                        <div role="group" aria-labelledby="label-email">
                          <div className="relative flex items-start">
                            <div className="flex h-5 flex-wrap">
                              <input
                                value={data.options[val]}
                                name="comments"
                                type="checkbox"
                                checked={
                                  checked.length > 0
                                    ? checked.includes(data.options[val])
                                    : false
                                }
                                onChange={process_checkbox}
                                className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                              />
                            </div>
                            <div className="ml-3 text-sm">
                              <label className="font-medium text-gray-700">
                                {data.options[val]}
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })}
              </div>
            </div>{' '}
          </div>
        </div>
      ) : (
        ''
      )}
    </>
  );
}
