import axios from "axios";
import { getHeaders } from "../../auth/actions";
import { toast } from "react-hot-toast";
import {
  ADD_AVAILABILITY_BEGIN,
  ADD_AVAILABILITY_SUCCESS,
  ADD_AVAILABILITY_ERROR,
  GET_AVAILABILITY_BEGIN,
  GET_AVAILABILITY_SUCCESS,
  GET_AVAILABILITY_ERROR,
  UPDATE_AVAILABILITY_BEGIN,
  UPDATE_AVAILABILITY_SUCCESS,
  UPDATE_AVAILABILITY_ERROR,
  DELETE_AVAILABILITY_BEGIN,
  DELETE_AVAILABILITY_SUCCESS,
  DELETE_AVAILABILITY_ERROR,
} from "./constants";

const API = process.env.REACT_APP_API_URL;

export const getAvailability = (id) => async (dispatch) => {
  try {
    dispatch({
      type: GET_AVAILABILITY_BEGIN,
    });
    const headers = dispatch(getHeaders());
    const { data } = await axios.get(
      `${API}/api/therapist/schedule/availibility/${id}`,
      {
        headers,
      }
    );
    console.log(data.data);

    dispatch({
      type: GET_AVAILABILITY_SUCCESS,
      data: data.data,
    });
  } catch (error) {
    const msg = error.response.data.message;
    toast.error(msg);
    dispatch({
      type: GET_AVAILABILITY_ERROR,
      error: msg,
    });
  }
};

export const addAvailability = (scheduleId, body) => async (dispatch) => {
  try {
    dispatch({
      type: ADD_AVAILABILITY_BEGIN,
    });
    const headers = dispatch(getHeaders());
    const { data } = await axios.post(
      `${API}/api/therapist/schedule/availibility/${scheduleId}`,
      body,
      {
        headers,
      }
    );
    console.log(data.data);
    toast.success("Added schedule availability successfully!");
    dispatch({
      type: ADD_AVAILABILITY_SUCCESS,
      data: data.data,
    });
  } catch (error) {
    const msg = error.response.data.message;
    toast.error(msg);
    dispatch({
      type: ADD_AVAILABILITY_ERROR,
      error: msg,
    });
  }
};

export const getAvailabilityWithId = (id) => async (dispatch) => {
  try {
    dispatch({
      type: GET_AVAILABILITY_BEGIN,
    });
    const headers = dispatch(getHeaders());
    const { data } = await axios.get(
      `${API}/api/therapist/schedule/availibility/${id}`,
      {
        headers,
      }
    );
    console.log(data.data);

    dispatch({
      type: GET_AVAILABILITY_SUCCESS,
      data: data.data,
    });
  } catch (error) {
    const msg = error.response.data.message;
    toast.error(msg);
    dispatch({
      type: GET_AVAILABILITY_ERROR,
      error: msg,
    });
  }
};

export const updateAvailability = (id, body) => async (dispatch) => {
  try {
    dispatch({
      type: UPDATE_AVAILABILITY_BEGIN,
    });
    const headers = dispatch(getHeaders());
    const { data } = await axios.put(
      `${API}/api/therapist/schedule/availibility/${id}`,
      body,
      {
        headers,
      }
    );
    console.log(data.data);
    toast.success("Updated doctor successfully!");
    dispatch({
      type: UPDATE_AVAILABILITY_SUCCESS,
      data: data.data,
    });
  } catch (error) {
    const msg = error.response.data.message;
    toast.error(msg);
    dispatch({
      type: UPDATE_AVAILABILITY_ERROR,
      error: msg,
    });
  }
};

export const deleteAvailability = (id, body) => async (dispatch) => {
  try {
    dispatch({
      type: DELETE_AVAILABILITY_BEGIN,
    });
    const headers = dispatch(getHeaders());
    const { data } = await axios.delete(`${API}/api/doc-availibility/${id}`, {
      headers,
    });
    console.log(data.data);
    toast.success("Deleted schedule successfully!");
    dispatch({
      type: DELETE_AVAILABILITY_SUCCESS,
      data: data.data,
    });
  } catch (error) {
    const msg = error.response.data.message;
    toast.error(msg);
    dispatch({
      type: DELETE_AVAILABILITY_ERROR,
      error: msg,
    });
  }
};
