import React from 'react'
import Breadcrumb from '../../../components/Breadcrumb'
import Select from 'react-select'

const userList = [
    {   
        values: "TEST values",
        label: "TEST lable",
        user_id: 1,
        user_email: "abhijit1@enpointe.io",
        user_name: "Abhijit",
        user_password: "abhijit",
        user_createdAt: "2022-12-19T11:18:17.990Z",
        user_updatedAt: "2022-12-19T16:32:08.558Z",
        user_is_active: "Y",
        user_is_deleted: "N"
    },
    {
        user_id: 2,
        user_email: "ajay@enpointe.io",
        user_name: "Ajay",
        user_password: "ajay",
        user_createdAt: "2022-12-19T11:18:33.109Z",
        user_updatedAt: "2022-12-19T11:18:33.109Z",
        user_is_active: "Y",
        user_is_deleted: "N"
    },
    {
        user_id: 3,
        user_email: "priyanshu@enpointe.io",
        user_name: "Priyanshu",
        user_password: "priyanshu",
        user_createdAt: "2022-12-19T11:18:51.266Z",
        user_updatedAt: "2022-12-19T11:18:51.266Z",
        user_is_active: "Y",
        user_is_deleted: "N"
    },
    {
        user_id: 3,
        user_email: "priyanshu@enpointe.io",
        user_name: "Priyanshu",
        user_password: "priyanshu",
        user_createdAt: "2022-12-19T11:18:51.266Z",
        user_updatedAt: "2022-12-19T11:18:51.266Z",
        user_is_active: "Y",
        user_is_deleted: "N"
    },
    {
        user_id: 4,
        user_email: "sanath@enpointe.io",
        user_name: "Sanath",
        user_password: "sanath",
        user_createdAt: "2022-12-19T11:19:12.631Z",
        user_updatedAt: "2022-12-19T11:19:12.631Z",
        user_is_active: "Y",
        user_is_deleted: "N"
    },
    {
        user_id: 5,
        user_email: "abhijit@gmail.com",
        user_name: "Abhijit",
        user_password: "Abhijit@12345",
        user_createdAt: "2022-12-19T15:51:03.998Z",
        user_updatedAt: "2022-12-19T15:51:03.998Z",
        user_is_active: "Y",
        user_is_deleted: "N"
    },
    {
        user_id: 5,
        user_email: "abhijit@gmail.com",
        user_name: "Abhijit",
        user_password: "Abhijit@12345",
        user_createdAt: "2022-12-19T15:51:03.998Z",
        user_updatedAt: "2022-12-19T15:51:03.998Z",
        user_is_active: "Y",
        user_is_deleted: "N"
    },
    {
        user_id: 16,
        user_email: "user1@gmail.com",
        user_name: "user",
        user_password: "user@123",
        user_createdAt: "2022-12-19T16:34:44.952Z",
        user_updatedAt: "2022-12-19T16:34:44.952Z",
        user_is_active: "Y",
        user_is_deleted: "N"
    },
    {
        user_id: 17,
        user_email: "user32@gmail.com",
        user_name: "User3",
        user_password: "user@123",
        user_createdAt: "2022-12-19T16:41:17.507Z",
        user_updatedAt: "2022-12-19T16:41:50.834Z",
        user_is_active: "Y",
        user_is_deleted: "N"
    },
    {
        user_id: 17,
        user_email: "user32@gmail.com",
        user_name: "User3",
        user_password: "user@123",
        user_createdAt: "2022-12-19T16:41:17.507Z",
        user_updatedAt: "2022-12-19T16:41:50.834Z",
        user_is_active: "Y",
        user_is_deleted: "N"
    }
]

const userData = []

const AddUserGroup = () => {
    userList.map((val, key) => {
        const obj = {
            label: val.user_name,
            value: val.user_id,
            user_email: val.user_email,
            user_password: val.user_password,
            user_createdAt: val.user_createdAt,
            user_updatedAt: val.user_updatedAt,
            user_is_active: val.user_is_active,
            user_is_deleted: val.user_is_deleted
        }
        userData.push(obj);
    })

    const pages = [{ title: "Add User Group", href: "/add-user-group" }];


    return (
        <div>
            <Breadcrumb pages={pages} />
            <form className="space-y-8 divide-y divide-gray-200">
                <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
                    <div className="space-y-6 sm:space-y-5">
                        <div>
                            <h3 className="text-lg font-medium leading-6 text-gray-900">Add User Group</h3>
                            <p className="mt-1 max-w-2xl text-sm text-gray-500">
                                This information will be displayed publicly so be careful what you share.
                            </p>
                        </div>

                        <div className="space-y-6 sm:space-y-5">
                            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                                <label htmlFor="user-group-name" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                    User Group Name
                                </label>
                                <div className="mt-1 sm:col-span-2 sm:mt-0">
                                    <input
                                        type="text"
                                        name="user-group-name"
                                        id="user-group-name"
                                        autoComplete="user-group-name"
                                        className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                                    />
                                </div>
                            </div>
                            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                                <label htmlFor="users" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                    Users
                                </label>
                                <div className="mt-1 sm:col-span-2 sm:mt-0">
                                    {/* {userList.map ((users) => { */}
                                        <Select
                                        defaultValue={""}
                                        isMulti
                                        name="label"
                                        options={userData}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        />
                                    {/* })} */}
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <div className="pt-5">
                    <div className="flex justify-end">
                        <button
                            type="button"
                            className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        >
                            Cancel
                        </button>
                        <button
                            type="submit"
                            className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        >
                            Save
                        </button>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default AddUserGroup