export const GET_ALL_MEDICINE_NAME_BEGIN = "GET_ALL_MEDICINE_NAME_BEGIN"
export const GET_ALL_MEDICINE_NAME_SUCCESS = "GET_ALL_MEDICINE_NAME_SUCCESS"
export const GET_ALL_MEDICINE_NAME_ERROR = "GET_ALL_MEDICINE_NAME_ERROR"

export const ADD_MEDICINE_NAME_BEGIN = "ADD_MEDICINE_NAME_BEGIN"
export const ADD_MEDICINE_NAME_SUCCESS = "ADD_MEDICINE_NAME_SUCCESS"
export const ADD_MEDICINE_NAME_ERROR = "ADD_MEDICINE_NAME_ERROR"

export const GET_MEDICINE_NAME_BEGIN = "GET_MEDICINE_NAME_BEGIN"
export const GET_MEDICINE_NAME_SUCCESS = "GET_MEDICINE_NAME_SUCCESS"
export const GET_MEDICINE_NAME_ERROR = "GET_MEDICINE_NAME_ERROR"

export const UPDATE_MEDICINE_NAME_BEGIN = "UPDATE_MEDICINE_NAME_BEGIN"
export const UPDATE_MEDICINE_NAME_SUCCESS = "UPDATE_MEDICINE_NAME_SUCCESS"
export const UPDATE_MEDICINE_NAME_ERROR = "UPDATE_MEDICINE_NAME_ERROR"

export const DELETE_MEDICINE_NAME_BEGIN = "DELETE_MEDICINE_NAME_BEGIN"
export const DELETE_MEDICINE_NAME_SUCCESS = "DELETE_MEDICINE_NAME_SUCCESS"
export const DELETE_MEDICINE_NAME_ERROR = "DELETE_MEDICINE_NAME_ERROR"