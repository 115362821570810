
import axios from 'axios'
import { getHeaders } from '../auth/actions'
import { toast } from 'react-toast'
import { 
    GET_ALL_DEPARTMENT_BEGIN,
    GET_ALL_DEPARTMENT_SUCCESS,
    GET_ALL_DEPARTMENT_ERROR,
    ADD_DEPARTMENT_BEGIN,
    ADD_DEPARTMENT_SUCCESS,
    ADD_DEPARTMENT_ERROR,
    GET_DEPARTMENT_BEGIN,
    GET_DEPARTMENT_SUCCESS,
    GET_DEPARTMENT_ERROR,
    UPDATE_DEPARTMENT_BEGIN,
    UPDATE_DEPARTMENT_SUCCESS,
    UPDATE_DEPARTMENT_ERROR,
    DELETE_DEPARTMENT_BEGIN,
    DELETE_DEPARTMENT_SUCCESS,
    DELETE_DEPARTMENT_ERROR
 } from './constants'

 const API = process.env.REACT_APP_API_URL

 export const getAllDepartments = () => async(dispatch) => {
    try {
      dispatch({
        type: GET_ALL_DEPARTMENT_BEGIN
      })
      const headers = dispatch(getHeaders());
      const {data} = await axios.get(`${API}/api/department`, { headers })
      console.log(data.data)
  
      dispatch({
        type: GET_ALL_DEPARTMENT_SUCCESS, 
        data: data.data
      })
    }
    catch (error) {
      const msg = error.response.data.message;
      toast.error(msg)
      console.log(error)
      dispatch({
        type: GET_ALL_DEPARTMENT_ERROR,
        error: msg
      })
    }
  }

  
export const addDepartment = (body) => async(dispatch) => {
    try {
        dispatch({
          type: ADD_DEPARTMENT_BEGIN
        })
        const headers = dispatch(getHeaders());
        const {data} = await axios.post(`${API}/api/department`, body, { headers })
        console.log(data.data)
        toast.success("Department added successfully!")
        dispatch({
          type: ADD_DEPARTMENT_SUCCESS, 
          data: data.data
        })
      }
      catch (error) {
        console.log(error.response)
        const msg = error.response.data.message;
        toast.error(msg)
        dispatch({
          type: ADD_DEPARTMENT_ERROR,
          error: msg
        })
      }
}

export const getDepartmentWithId = (id) => async(dispatch) => {
    try {
      dispatch({
        type: GET_DEPARTMENT_BEGIN
      })
      const headers = dispatch(getHeaders());
      const {data} = await axios.get(`${API}/api/department/${id}`, { headers })
      console.log(data.data)
      dispatch({
        type: GET_DEPARTMENT_SUCCESS, 
        data: data.data
      })
    }
    catch (error) {
      console.log(error.response)
      const msg = error.response.data.message;
      toast.error(msg)
      dispatch({
        type: GET_DEPARTMENT_ERROR,
        error: msg
      })
    }
  }
  
  export const updateDepartment = (id, body) => async(dispatch) => {
    try {
      dispatch({
        type: UPDATE_DEPARTMENT_BEGIN
      })
      const headers = dispatch(getHeaders());
      const {data} = await axios.put(`${API}/api/department/${id}`, body, { headers })
      console.log(data.data)
      toast.success("Department updated successfully!")
      dispatch({
        type: UPDATE_DEPARTMENT_SUCCESS, 
        data: data.data
      })
    }
    catch (error) {
      const msg = error.response.data.message;
      toast.error(msg)
      dispatch({
        type: UPDATE_DEPARTMENT_ERROR,
        error: msg
      })
    }
  }
  
  export const deleteDepartment = (id) => async(dispatch) => {
    try {
      dispatch({
        type: DELETE_DEPARTMENT_BEGIN
      })
      const headers = dispatch(getHeaders());
      const {data} = await axios.delete(`${API}/api/department/${id}`, { headers })
      toast.success("Department deleted successfully!")
      dispatch({
        type: DELETE_DEPARTMENT_SUCCESS, 
        data: data.data
      })
    }
    catch (error) {
      const msg = error.response.data.message;
      toast.error(msg)
      dispatch({
        type: DELETE_DEPARTMENT_ERROR,
        error: msg
      })
    }
  }