import {
  GET_ALL_OPD_REPORT_BEGIN,
  GET_ALL_OPD_REPORT_SUCCESS,
  GET_ALL_OPD_REPORT_ERROR,
  GET_ALL_CANCELLED_OPD,
  GET_ALL_CANCELLED_OPD_BEGIN,
  GET_ALL_CANCELLED_OPD_ERROR,
} from "./constants";

const initState = {
  list: [],
  loading: false,
  error: null,
  cancelledList: [],
  cancelledLoading: false,
  cancelledError: null,
};

const opdReducer = (state = initState, action) => {
  const { type, data, error } = action;
  switch (type) {
    case GET_ALL_OPD_REPORT_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case GET_ALL_OPD_REPORT_SUCCESS:
      return {
        ...state,
        list: data,
        loading: false,
      };
    case GET_ALL_OPD_REPORT_ERROR:
      return {
        ...state,
        error,
        loading: false,
      };
    case GET_ALL_CANCELLED_OPD_BEGIN:
      return {
        ...state,
        cancelledLoading: true,
      };
    case GET_ALL_CANCELLED_OPD:
      return {
        ...state,
        cancelledList: data,
        cancelledLoading: false,
      };
    case GET_ALL_CANCELLED_OPD_ERROR:
      return {
        ...state,
        cancelledError: error,
        cancelledLoading: false,
      };
    default:
      return state;
  }
};

export default opdReducer;
