import { combineReducers } from "redux";
import authReducer from "./auth/reducers";
import locationReducer from "./location/reducers";
import patientReducer from "./patient/reducers";
import doctorReducer from "./doctor/reducers";
import scheduleReducer from "./schedule/reducers";
import availabilityReducer from "./availability/reducers";
import ExceptionReducer from "./exception/reducers";
import appointmentReducer from "./appointment/reducers";
import PaymentReducer from "./payments/reducer";
import opdReducer from "./opdreport/reducer";
import ReferralReducer from "./referral/reducers";
import FormsReducer from "./forms/reducers";
import DepartmentReducer from "./department/reducers";
import GoalReducer from "./GoalMaster/reducers";
import CategoryReducer from "./CategoryMaster/reducers";
import AgeGroupReducer from "./AgeGroupMaster/reducers";
import DomainReducer from "./DomainMaster/reducers";
import MedicineReducer from "./medicine/reducers";
import showAvailabilityReducer from "./ShowAvailability/reducers";
import ProgramReducer from "./ProgramMaster/reducers";
import ProgramMasterReducer from "./ProgramSessionMaster/reducers";
import patientFormReducer from "./patientForm/reducers";
import departmentFilterReducer from "./patientAccordian/reducer";
import StrengthReducer from "./Strength/reducers";
import FormulationReducer from "./formulation/reducers";
import MedicineNameReducer from "./MedicineNameMaster/reducers";
import RouteOfAdministrationReducer from "./Route of adminstration/reducers";
import MedicineMasterReducer from "./medicineMaster/reducers";
import MchatReducer from "./Amcat/reducer";
import patientRecommandationReducer from "./patientrecommandation/reduers";
import TherapistReducer from "./therapist/therpist/reducers";
import TherapistscheduleReducer from "./therapist/schedule/reducers";
import showThAvailabilityReducer from "./therapist/ShowAvailability/reducers";
import TherapistExceptionReducer from "./therapist/exception/reducers";
import TherapistAvailabilityReducer from "./therapist/availability/reducers";
import therapistAppointmentReducer from "./TherapistAppointment/reducers";
const rootReducers = combineReducers({
  auth: authReducer,
  location: locationReducer,
  patient: patientReducer,
  doctor: doctorReducer,
  schedule: scheduleReducer,
  availability: availabilityReducer,
  exception: ExceptionReducer,
  appointment: appointmentReducer,
  payment: PaymentReducer,
  opdAppointment: opdReducer,
  referral: ReferralReducer,
  forms: FormsReducer,
  department: DepartmentReducer,
  goal: GoalReducer,
  category: CategoryReducer,
  ageGroup: AgeGroupReducer,
  domain: DomainReducer,
  medicine: MedicineReducer,
  showAvailability: showAvailabilityReducer,
  programMaster: ProgramReducer,
  programSession: ProgramMasterReducer,
  patientForm: patientFormReducer,
  departmentFilter: departmentFilterReducer,
  strength: StrengthReducer,
  formulation: FormulationReducer,
  medicineName: MedicineNameReducer,
  RouteOfAdministration: RouteOfAdministrationReducer,
  medicineMaster: MedicineMasterReducer,
  Mchat: MchatReducer,
  patientRecommadation: patientRecommandationReducer,
  Therapist: TherapistReducer,
  TherapistSchedule: TherapistscheduleReducer,
  showthavailability: showThAvailabilityReducer,
  therapistExpectation: TherapistExceptionReducer,
  therapistAvailability: TherapistAvailabilityReducer,
  therapistAppointment: therapistAppointmentReducer,
});

export default rootReducers;
