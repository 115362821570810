import Text_box from './Fields/Text_box';
import React, { Fragment, useState, useEffect } from 'react';

import File_Input from './Fields/FIle_input';
import Number_input from './Fields/Number_input';
import Radio_input from './Fields/Radio_input';
import Checkbox_input from './Fields/Checkbox_input';
import Select_Input from './Fields/Select_Input';
import Text_area_input from './Fields/Text_area_input';
import EmailInput from './Fields/Email_input';
import Date_input from './Fields/Date_input';
import Password_Input from './Fields/Password_input';
import Date_time_input from './Fields/Date_time_input';
import Heading from './Fields/Heading';
import Relation_master from './Fields/Relation_master';
export default function Preview(props) {
  const [formdata, setformdata] = useState({});

  function handlesubmit(e) {
    // e.preventDefault();
    console.log(e);
  }
  function record_data(val, id) {
    setformdata({ ...formdata, [id]: val });
  }
  return (
    <>
      <div className=" items-center h-screen overflow-x-hidden overflow-y-auto fixed  z-50 outline-none focus:outline-none">
        <div className=" w-full my-6  mx-auto max-w-3xl ">
          {/*content*/}
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            {/*header*/}
            <div className="flex items-start justify-between  rounded-t"></div>
            {/*body*/}
            <div className="relative p-6 flex-auto">
              <div className="space-y-6 sm:space-y-5">
                {/* <form onSubmit={handlesubmit}> */}
                {props.sequence.map((e, index) => {
                  switch (props.fields[e].type) {
                    case 'text':
                      return (
                        <>
                          <Text_box
                            all_fields={props.fields}
                            current_data={formdata}
                            record_change={record_data}
                            config={props.fields[e]}
                            key={`${props.fields[e].fieldname}_${index}`}
                          />
                        </>
                      );
                      break;
                    case 'heading':
                      return (
                        <Heading
                          all_fields={props.fields}
                          current_data={formdata}
                          config={props.fields[e]}
                          key={`${props.fields[e].fieldname}_${index}`}
                        />
                      );
                      break;
                    case 'date':
                      return (
                        <>
                          <Date_input
                            all_fields={props.fields}
                            current_data={formdata}
                            record_change={record_data}
                            config={props.fields[e]}
                            key={`${props.fields[e].fieldname}_${index}`}
                          />
                        </>
                      );
                      break;
                    case 'date-time':
                      return (
                        <>
                          <Date_time_input
                            all_fields={props.fields}
                            current_data={formdata}
                            record_change={record_data}
                            config={props.fields[e]}
                            key={`${props.fields[e].fieldname}_${index}`}
                          />
                        </>
                      );
                      break;
                    case 'file':
                      return (
                        <>
                          <File_Input
                            all_fields={props.fields}
                            current_data={formdata}
                            record_change={record_data}
                            config={props.fields[e]}
                            key={`${props.fields[e].fieldname}_${index}`}
                          />
                        </>
                      );
                      break;
                    case 'number':
                      return (
                        <>
                          <Number_input
                            all_fields={props.fields}
                            current_data={formdata}
                            record_change={record_data}
                            config={props.fields[e]}
                            key={`${props.fields[e].fieldname}_${index}`}
                          />
                        </>
                      );
                      break;
                    case 'radio':
                      return (
                        <>
                          <Radio_input
                            all_fields={props.fields}
                            current_data={formdata}
                            record_change={record_data}
                            config={props.fields[e]}
                            key={`${props.fields[e].fieldname}_${index}`}
                          />
                        </>
                      );
                      break;
                    case 'checkbox':
                      return (
                        <>
                          <Checkbox_input
                            all_fields={props.fields}
                            current_data={formdata}
                            record_change={record_data}
                            config={props.fields[e]}
                            key={`${props.fields[e].fieldname}_${index}`}
                          />
                        </>
                      );
                      break;
                    case 'select':
                      return (
                        <>
                          <Select_Input
                            all_fields={props.fields}
                            current_data={formdata}
                            record_change={record_data}
                            config={props.fields[e]}
                            key={`${props.fields[e].fieldname}_${index}`}
                          />
                        </>
                      );
                      break;
                    case 'RelationMaster':
                      return (
                        <>
                          <Relation_master
                            all_fields={props.fields}
                            current_data={formdata}
                            record_change={record_data}
                            config={props.fields[e]}
                            key={`${props.fields[e].fieldname}_${index}`}
                          />
                        </>
                      );
                      break;
                    case 'text-area':
                      return (
                        <>
                          <Text_area_input
                            all_fields={props.fields}
                            current_data={formdata}
                            record_change={record_data}
                            config={props.fields[e]}
                            key={`${props.fields[e].fieldname}_${index}`}
                          />
                        </>
                      );
                      break;
                    case 'email':
                      return (
                        <>
                          <EmailInput
                            all_fields={props.fields}
                            current_data={formdata}
                            record_change={record_data}
                            config={props.fields[e]}
                            key={`${props.fields[e].fieldname}_${index}`}
                          />
                        </>
                      );
                      break;
                    case 'password':
                      return (
                        <>
                          <Password_Input
                            all_fields={props.fields}
                            current_data={formdata}
                            record_change={record_data}
                            config={props.fields[e]}
                            key={`${props.fields[e].fieldname}_${index}`}
                          />
                        </>
                      );
                      break;
                  }
                })}
                {/* <button type={'submit'}>letz go</button>
                </form> */}
              </div>
            </div>
            {/*footer*/}
            <div className="flex items-center justify-end p-6  border-solid border-slate-200 rounded-b">
              <button
                className="text-red-500  background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                onClick={props.closepreview}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        onClick={(e) => {
          props.closepreview();
        }}
        className="opacity-25 fixed inset-0 z-40 bg-black"
      ></div>
    </>
  );
}
