/* eslint-disable */
import axios from "axios";
import { toast } from "react-hot-toast";
// import { handleAlert } from "../alerts"
import {
  LOGIN_BEGIN,
  LOGIN_ERROR,
  LOGIN_SUCCESS,
  LOGOUT_BEGIN,
  LOGOUT_SUCCESS,
  GET_ALL_USERS_BEGIN,
  GET_ALL_USERS_SUCCESS,
  GET_ALL_USERS_ERROR,
  ADD_USER_BEGIN,
  ADD_USER_SUCCESS,
  ADD_USER_ERROR,
  GET_USER_BEGIN,
  GET_USER_SUCCESS,
  GET_USER_ERROR,
  UPDATE_USER_BEGIN,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_ERROR,
} from "./constants";

const API = process.env.REACT_APP_API_URL;

export const getHeaders = () => (dispatch, getState) => {
  const {
    auth: { user },
  } = getState();
  return {
    Authorization: `Bearer ${user.token}`,
  };
};

// ** Handle User Login
export const handleLogin = (body) => async (dispatch) => {
  try {
    toast.loading("Logging in...");
    dispatch({
      type: LOGIN_BEGIN,
    });
    const { data } = await axios.post(`${API}/api/auth/login`, body);
    await localStorage.setItem("newhorizon_user", JSON.stringify(data.data));
    toast.success("Logged in successfully!");
    dispatch({
      type: LOGIN_SUCCESS,
      data: data.data,
    });
  } catch (error) {
    const msg = error.response.data.message;
    toast.error(msg);
    dispatch({
      type: LOGIN_ERROR,
      error: msg,
    });
  }
};

// ** Handle User Logout
export const handleLogout = () => async (dispatch) => {
  console.log("REMOVE ITEM");
  try {
    dispatch({
      type: LOGOUT_BEGIN,
    });
    localStorage.removeItem("newhorizon_user");
    dispatch({
      type: LOGOUT_SUCCESS,
    });
  } catch (error) {
    const msg = error.response.data.message;
    toast.error(msg);
    dispatch({
      type: LOGIN_ERROR,
      error: msg,
    });
  }
};

export const getAllUsers = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_ALL_USERS_BEGIN,
    });
    const headers = getHeaders();
    const { data } = await axios.get(`${API}/api/auth`, { headers });
    console.log(data.data);

    dispatch({
      type: GET_ALL_USERS_SUCCESS,
      data: data.data,
    });
  } catch (error) {
    const msg = error.response.data.message;
    toast.error(msg);
    dispatch({
      type: GET_ALL_USERS_ERROR,
      error: msg,
    });
  }
};

export const addUser = (body) => async (dispatch) => {
  try {
    dispatch({
      type: ADD_USER_BEGIN,
    });
    const headers = getHeaders();
    const { data } = await axios.post(`${API}/api/auth/register`, body, {
      headers,
    });
    console.log(data.data);
    toast.success("User added successfully!");
    dispatch({
      type: ADD_USER_SUCCESS,
      data: data.data,
    });
  } catch (error) {
    const msg = error.response.data.message;
    toast.error(msg);
    dispatch({
      type: ADD_USER_ERROR,
      error: msg,
    });
  }
};

export const getUserWithId = (id) => async (dispatch) => {
  try {
    dispatch({
      type: GET_USER_BEGIN,
    });
    const headers = getHeaders();
    const { data } = await axios.get(`${API}/api/auth/${id}`, { headers });
    console.log(data.data);
    dispatch({
      type: GET_USER_SUCCESS,
      data: data.data,
    });
  } catch (error) {
    const msg = error.response.data.message;
    toast.error(msg);
    dispatch({
      type: GET_USER_ERROR,
      error: msg,
    });
  }
};

export const updateUser = (id, body) => async (dispatch) => {
  try {
    dispatch({
      type: UPDATE_USER_BEGIN,
    });
    const headers = getHeaders();
    const { data } = await axios.put(`${API}/api/auth/${id}`, body, {
      headers,
    });
    console.log(data.data);
    toast.success("User updated successfully!");
    dispatch({
      type: UPDATE_USER_SUCCESS,
      data: data.data,
    });
  } catch (error) {
    const msg = error.response.data.message;
    toast.error(msg);
    dispatch({
      type: UPDATE_USER_ERROR,
      error: msg,
    });
  }
};
