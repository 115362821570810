import React, { useEffect, useState } from 'react';

export default function Select_Input(props) {
  let [select_opt, set_select_opt] = useState(false);
  let data = props.config;
  let [visibility, set_visibility] = useState(true);
  useEffect(()=>{
    let options=Object.keys(data.options).map((val) => {
     return data.options[val];
    })
   if(props.current_data[data.ref_id]&& !options.includes(props.current_data[data.ref_id])){
    set_select_opt(true);

   }
  },[])
  useEffect(() => {
    if (data.child) {
      let foi = Object.keys(props.all_fields).filter((field) => {
        if (props.all_fields[field].label.trim() == data.parent?.label.trim()) {
          return props.all_fields[field];
        }
      });
      if (
        props.current_data[props.all_fields[foi]?.ref_id] == data.parent?.option
      ) {
        set_visibility(true);
      } else {
        if (props.current_data[data.ref_id] != null || data.defaultValue != null){
          props.record_change(null, data.ref_id);
          data.defaultValue=null;
        }
        set_visibility(false);
      }
    }
  }, [props.current_data]);
  return (
    <>
      {visibility ? (
        <div className="border-b pl-5 pb-5">
          <div
            className={` sm:grid ${
              select_opt ? 'sm:grid-cols-2 mr-40' : 'sm:grid-cols-3 '
            } sm:items-start   sm:border-gray-200 `}
          >
            <label
              htmlFor="country"
              className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
            >
              {data.label}
            </label>
            <div class="flex">
              <div className="flex-1 mt-2 sm:col-span-2 sm:mt-0">
                <select
                  onChange={(e) => {
                    if (
                      e.target.value.toLowerCase() == 'other' ||
                      e.target.value.toLowerCase() == 'others'
                    ) {
                      set_select_opt(true);
                    } else {
                      set_select_opt(false);
                      props.record_change(e.target.value, data.ref_id);
                    }
                  }}
                  value={props.current_data[data.ref_id]?props.current_data[data.ref_id]:data.defaultValue}

                  id="country"
                  name="country"
                  autoComplete="country-name"
                  className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                >
                  <option>select option</option>

                  {Object.keys(data.options).map((val) => {
               
                    return (
                      <option value={data.options[val]}>
                        {data.options[val]}
                      </option>
                    );
                  })}
                </select>
              </div>
              {select_opt ? (
                <div className="flex-1 mt-1 sm:col-span-2 sm:mt-0 ml-5">
                  <input
                    value={props.current_data[data.ref_id]?props.current_data[data.ref_id]:''}

                    placeholder={data.placeholder}
                    minLength={data.minlength}
                    maxLength={data.maxlength}
                    required={data.required}
                    onChange={(e) => {
                      props.record_change(e.target.value, data.ref_id);
                    }}
                    type="text"
                    name="textbox"
                    id="textbox"
                    className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                  />
                </div>
              ) : (
                ''
              )}
            </div>
          </div>{' '}
        </div>
      ) : (
        ''
      )}
      {/* <div className="mt-5 flex justify-left">
        <div className="mb-3 xl:w-96">
          <label
            for="exampleFormControlInput1"
            className="font-bold form-label inline-block mb-2 text-gray-700"
          >
            {data.label}
          </label>
          <select class="px-10 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ">
            {Object.keys(data.options).map((val) => {
              {
                console.log(data.options[val]);
              }
              return (
                <option value={data.options[val]}>{data.options[val]}</option>
              );
            })}
          </select>
        </div>
      </div> */}
    </>
  );
}
