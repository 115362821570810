import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import Modal from "../../../components/Modal";
import Breadcrumb from "../../../components/Breadcrumb";
import { FallingLinesLoader } from "../../../components/spinners/Spinner";
import Table from "../../../components/tables/table";
import { toast } from "react-hot-toast";
import ConfirmationModal from "../../../components/ConfirmationModal";
import Button from "../../../components/Button";
import InputBox from "../../../components/InputBox";
import axios from "axios";
import { getHeaders } from "../../../redux/auth/actions";
import { useDispatch } from "react-redux";
import { CancledAppointmentSessionLog_colums } from "../../../components/tables/tableheader";
import moment from "moment";
const initialModalState = {
  id: "",
  state: false,
};
const api = process.env.REACT_APP_API_URL;
function CancledAppointmentSessionLog() {
  const [modalData, setModalData] = useState(initialModalState);
  //   const [modal, setModal] = useState(initialModalState);
  const [query, setQuery] = useState(200);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    getAllCancledAppointmentSessionLogs(200);
    document.title = "New Horizon | Cancled Appointment Logs";
  }, []);

  const [CancledAppointmentSessionLog, setCancledAppointmentSessionLog] =
    useState({
      loading: false,
      data: [],
    });
  const adjModal = () => {
    const { state, id } = modalData;
    const initialValues = {
      form_date: "",
    };

    return (
      <Modal
        size="max-w-sm"
        open={state}
        title={`Adjust`}
        setOpen={() =>
          setModalData((prev) => ({ ...prev, state: false, id: "" }))
        }
      >
        <div className="mt-2 w-full flex items-center justify-center">
          <Formik
            initialValues={initialValues}
            onSubmit={async (values) => {
              const body = {
                date: values.form_date,
              };
              const res = await axios.put(
                `${api}/api/therapist/appointment/session-adjustment/${modalData.id}`,
                body,
                {
                  headers: {
                    Authorization:
                      "Bearer " +
                      JSON.parse(localStorage.getItem("newhorizon_user")).token,
                  },
                }
              );
              if (res.status === 200) {
                toast.success("Successfully adjustment done");
                window.location.reload();
                setModalData((prev) => ({ ...prev, state: false, id: "" }));
              }
            }}
          >
            {(formik) => (
              <form className="w-[80%]" onSubmit={formik.handleSubmit}>
                <div className="mt-2 flex items-center justify-between">
                  <input
                    type="date"
                    id="form_date"
                    name="form_date"
                    value={formik.values.form_date}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-indigo-500 sm:max-w-xs sm:text-sm"
                  />
                </div>

                <div>
                  <div className="mt-5 sm:mt-6">
                    <button
                      type="submit"
                      className="inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm"
                    >
                      Save
                    </button>
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </Modal>
    );
  };
  const getAllCancledAppointmentSessionLogs = async (limit) => {
    const headers = await dispatch(getHeaders());
    setCancledAppointmentSessionLog((prev) => ({ ...prev, loading: true }));
    try {
      const res = await axios.get(
        `${api}/api/therapist/appointment/cancelled-sessions?limit=${limit}`,
        {
          headers: headers,
        }
      );
      const { data, status } = res;
      if (status === 200) {
        setCancledAppointmentSessionLog((prev) => ({
          ...prev,
          data: data.data,
          loading: false,
        }));
      }
      console.log(res);
    } catch (error) {
      toast.error(error.message);
    }
  };

  //   const createCancledAppointmentSessionLog = async (body) => {
  //     try {
  //       const headers = await dispatch(getHeaders());
  //       const res = await axios.post(`${api}/api/evaluation-master`, body, {
  //         headers: headers,
  //       });
  //       const { data, status } = res;
  //       if (status === 201) {
  //         getAllCancledAppointmentSessionLogs();
  //         toast.success("Evaluation Master Created Successfully");
  //         setModal((prev) => ({
  //           ...prev,
  //           state: false,
  //           data: {},
  //           edit_id: "",
  //           type: "",
  //         }));
  //       }
  //     } catch (error) {
  //       toast.error(error.message);
  //     }
  //   };

  //   const getCancledAppointmentSessionLogById = async (id) => {
  //     try {
  //       const headers = await dispatch(getHeaders());
  //       const res = await axios.get(`${api}/api/evaluation-master/${id}`, {
  //         headers: headers,
  //       });
  //       const { data, status } = res;
  //       if (status === 200) {
  //         setModal((prev) => ({
  //           ...prev,
  //           type: "edit",
  //           data: {
  //             name: data.data.name,
  //           },
  //         }));
  //       }
  //     } catch (error) {
  //       toast.error(error.message);
  //     }
  //   };

  //   const updateCancledAppointmentSessionLog = async (id, body) => {
  //     try {
  //       const headers = await dispatch(getHeaders());
  //       const res = await axios.put(`${api}/api/evaluation-master/${id}`, body, {
  //         headers: headers,
  //       });
  //       const { data, status } = res;
  //       if (status === 200) {
  //         getAllCancledAppointmentSessionLogs();
  //         toast.success("Evaluation Master Updated Successfully");
  //         setModal((prev) => ({
  //           ...prev,
  //           state: false,
  //           data: {},
  //           edit_id: "",
  //           type: "",
  //         }));
  //       }
  //     } catch (error) {}
  //   };

  // const onDeleteEvaluation = async (id) => {
  //   const headers = await dispatch(getHeaders());
  //   try {
  //     const res = await axios.delete(`${api}/api/evaluation-master/${id}`, {
  //       headers: headers,
  //     });
  //     const { data, status } = res;
  //     if (status === 200) {
  //       getAllCancledAppointmentSessionLogs();
  //       toast.success("Evaluation Deleted Successfully");
  //       setConfirmationModal({ id: null, status: false });
  //     }
  //   } catch (error) {
  //     toast.error("This Evaluation might be used in some appointment");
  //   }
  // };

  //   const onDeleteOpen = (id) => {
  //     setConfirmationModal({ id: id, status: true });
  //   };
  //   const renderModal = () => {
  //     const { type, state, data, edit_id } = modal;

  //     return (
  //       <Formik
  //         initialValues={data}
  //         validationSchema={Yup.object({
  //           name: Yup.string().required("Please enter name"),
  //         })}
  //         enableReinitialize={true}
  //         onSubmit={(values, action) => {
  //           const body = {
  //             name: values.name,
  //           };
  //           if (modal.type === "edit") {
  //             updateCancledAppointmentSessionLog(modal.edit_id, body);
  //           } else {
  //             createCancledAppointmentSessionLog(body);
  //             action.resetForm();
  //           }
  //           action.setSubmitting(false);
  //         }}
  //       >
  //         {({
  //           values,
  //           errors,
  //           touched,
  //           handleChange,
  //           handleBlur,
  //           handleSubmit,
  //           isSubmitting,
  //         }) => (
  //           <Modal
  //             title={`${modal.type === "edit" ? "Update" : "Add"} Evaluation`}
  //             open={state}
  //             setOpen={() =>
  //               setModal((prev) => ({
  //                 ...prev,
  //                 state: false,
  //                 data: {},
  //                 edit_id: "",
  //                 type: "",
  //               }))
  //             }
  //           >
  //             <form onSubmit={handleSubmit}>
  //               <div className="text-left mt-4">
  //                 <div className="mt-4"></div>
  //                 <InputBox
  //                   name="name"
  //                   label="Name"
  //                   type="text"
  //                   value={values.name}
  //                   handleBlur={handleBlur}
  //                   handleChange={handleChange}
  //                   required={true}
  //                   placeholder="Enter the Name"
  //                 />
  //                 {touched.name && (
  //                   <p className="text-red-700 error_msg">{errors.name}</p>
  //                 )}
  //               </div>

  //               <div className="mt-4"></div>
  //               <Button disabled={isSubmitting} type="submit">
  //                 {modal.type === "edit" ? "Update" : "Add"} Evaluation
  //               </Button>
  //             </form>
  //           </Modal>
  //         )}
  //       </Formik>
  //     );
  //   };

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      {adjModal()}
      {/* <Breadcrumb pages={pages} /> */}
      {/* {renderModal()} */}
      {/* <ConfirmationModal
        open={confirmationModal.status}
        setOpen={setConfirmationModal}
        onDelete={() => onDeleteEvaluation(confirmationModal.id)}
      /> */}
      <div className="">
        <h1 className="text-xl font-semibold text-gray-900">
          Cancled Appointment Logs
        </h1>
      </div>
      <div className="mt-4 flex">
        {/* <button
          type="button"
          onClick={() =>
            setModal((prev) => ({
              ...prev,
              data: {},
              type: "",
              edit_id: "",
              state: true,
            }))
          }
          className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 ml-auto"
        >
          Add
        </button> */}
      </div>
      {CancledAppointmentSessionLog.loading ? (
        <FallingLinesLoader />
      ) : (
        <Table
          columns={CancledAppointmentSessionLog_colums({ setModalData })}
          data={CancledAppointmentSessionLog.data}
          fetch={async (d) => {
            setQuery((prev) => d);

            await getAllCancledAppointmentSessionLogs(d);
          }}
        />
      )}
    </div>
  );
}
export default CancledAppointmentSessionLog;
