import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import Breadcrumb from '../../../components/Breadcrumb';
import ConfirmationModal from '../../../components/ConfirmationModal';
import { FallingLinesLoader } from '../../../components/spinners/Spinner';
import Table from '../../../components/tables/table';
import { age_group_master_columns} from '../../../components/tables/tableheader';
import { getAllAgeGroups, deleteAgeGroup } from "../../../redux/AgeGroupMaster/actions";
function AgeGroupMaster() {
  const [confirmationModal, setConfirmationModal] = useState({
    status: false,
    id: null,
  });
  const pages = [{ title: "Age Group Master", href: "/age-group-master" }];
  const dispatch = useDispatch();
  const { loading, list: ageGroupList } = useSelector(
    (state) => state.ageGroup
  );
  useEffect(() => {
    document.title = "New Horizon | Age Group Master";
    dispatch(getAllAgeGroups());
  }, []);
  
  const onDeleteOpen = (id) => {
    setConfirmationModal({ id, status: true });
  };

  const onDeleteAgeGroup = (id) => {
    dispatch(deleteAgeGroup(id));
    setConfirmationModal({ id: null, status: false });
  };
  return (
    <div className="px-4 sm:px-6 lg:px-8">
    <ConfirmationModal
      open={confirmationModal.status}
      setOpen={setConfirmationModal}
      onDelete={() => onDeleteAgeGroup(confirmationModal.id)}
    />
    <Breadcrumb pages={pages} />
    <div className="sm:flex sm:items-center mb-5">
      <div className="sm:flex-auto">
        <h1 className="text-xl font-semibold text-gray-900">
          Age Group Master
        </h1>
      </div>
      <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
        <Link
          to="/add-age-group"
          type="button"
          className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
        >
          Add Age Group
        </Link>
      </div>
    </div>
    {loading ? (
      <FallingLinesLoader />
    ) : (
      <Table
        columns={age_group_master_columns({ onDeleteOpen })}
        data={ageGroupList}
      />
    )}
  </div>
  )
}

export default AgeGroupMaster