import * as Yup from "yup";

const moment = require("moment");
export const locationSchema = Yup.object({
  location_name: Yup.string()
    .max(25, "Location name must be of maximum 25 characters")
    .required("Please enter the location name"),
  location_code: Yup.string()
    .min(6, "Pin code must be at least 6 characters")
    .max(6, "Pin code must be of maximum 6 characters")
    .required("please enter the location code"),
  phone_number: Yup.string()
    .min(10, "Phone number must be at least 10 characters")
    .max(10, "Phone number must be of maximum 10 characters")
    .required("Please enter the phone number"),
});
export const MedicineSchema = Yup.object({
  generic_name: Yup.string().required("Please enter the Generic name"),

  //   strength: Yup.string().required('Please enter the strength '),

  //   formulation: Yup.string().required('Please enter the formulation '),

  //   route_of_administration: Yup.string().required(
  //     'Please enter the route of administration '
  //   ),

  //   frequency: Yup.string().required('Please enter the frequency '),

  //   medicine_time: Yup.string().required('Please enter the time '),

  //   start_date: Yup.date().required('Please enter the start date '),

  //   duration: Yup.string().required('Please enter the duration '),

  //   instructions: Yup.string().required('Please enter the instructions '),
});

export const doctorSchema = Yup.object({
  first_name: Yup.string()
    .max(25, "first name must be of maximum 25 characters")
    .matches("^[a-zA-Z ]*$", "Numbers are not allowed")
    .required("Please enter the first name"),

  last_name: Yup.string()
    .max(25, "last name must be of maximum 25 characters")
    .required("Please enter the last name")
    .matches("^[a-zA-Z ]*$", "Numbers are not allowed"),

  email: Yup.string()
    .email("Please enter a valid email address")
    .required("Please enter the email address"),

  phone_number: Yup.string()
    .required("Please enter the phone number"),

  password: Yup.string()
    .min(6, "password must be at least 6 characters")
    .required("Please enter the password"),

  appointment_cost: Yup.number().required("Please enter the appointment cost"),

  is_follow_up_cost: Yup.number().required("Please enter follow up cost"),

  Confirm_Password: Yup.string()
    .required("Please enter the password again to confirm")
    .oneOf([Yup.ref("password"), null], "Password must match"),
});

export const TherapistSchema = Yup.object({
  first_name: Yup.string()
    .max(25, "first name must be of maximum 25 characters")
    .matches("^[a-zA-Z ]*$", "Numbers are not allowed")
    .required("Please enter the first name"),

  last_name: Yup.string()
    .max(25, "last name must be of maximum 25 characters")
    .required("Please enter the last name")
    .matches("^[a-zA-Z ]*$", "Numbers are not allowed"),

  email: Yup.string()
    .email("Please enter a valid email address")
    .required("Please enter the email address"),

  phone_number: Yup.string()
    .min(10, "Phone number must be at least 10 characters")
    .max(10, "Phone number must be of maximum 10 characters")
    .required("Please enter the phone number"),

  password: Yup.string()
    .min(6, "password must be at least 6 characters")
    .required("Please enter the password"),
  Confirm_Password: Yup.string()
    .required("Please enter the password again to confirm")
    .oneOf([Yup.ref("password"), null], "Password must match"),
});
export const patientsSchema = Yup.object({
  firstName: Yup.string()
    .max(25, "first name must be of maximum 25 characters")
    .matches("^[a-zA-Z ]*$", "Numbers are not allowed")
    .required("Please enter the First name"),
  lastName: Yup.string()
    .max(25, "last name must be of maximum 25 characters")
    .required("Please enter the last name")
    .matches("^[a-zA-Z ]*$", "Numbers are not allowed"),
  fatherFirstName: Yup.string()
    .max(25, "father first name must be of maximum 25 characters")
    .matches("^[a-zA-Z ]*$", "Numbers are not allowed"),
  fatherLastName: Yup.string()
    .max(25, "father last name must be of maximum 25 characters")
    .matches("^[a-zA-Z ]*$", "Numbers are not allowed"),
  motherFirstName: Yup.string()
    .max(25, "mother first name must be of maximum 25 characters")
    .matches("^[a-zA-Z ]*$", "Numbers are not allowed"),
  motherLastName: Yup.string()
    .max(25, "mother last name must be of maximum 25 characters")
    .matches("^[a-zA-Z ]*$", "Numbers are not allowed"),
  guardiansFirstName: Yup.string()
    .max(25, "guardian first name must be of maximum 25 characters")
    .matches("^[a-zA-Z ]*$", "Numbers are not allowed"),
  guardiansLastName: Yup.string()
    .max(25, "guardian last name must be of maximum 25 characters")
    .matches("^[a-zA-Z ]*$", "Numbers are not allowed"),
  // referral_id: Yup.string().required("Please select a referral"),
  location_id: Yup.string().required("Please select a location"),
  gender: Yup.string().required("Please select a gender"),
  // phoneNo: Yup.string()
  //   .min(10, "Phone number must be at least 10 characters")
  //   .max(10, "Phone number must be of maximum 10 characters")
  //   .required("Please enter the phone number"),
  dob: Yup.string().required("Please enter the date of birth"),
  address: Yup.string().required("Please enter the address"),
});

export const userSchema = Yup.object({
  firstName: Yup.string()
    .max(25, "last name must be of maximum 25 characters")
    .required("Please enter the First name"),
  lastName: Yup.string()
    .max(25, "last name must be of maximum 25 characters")
    .required("Please enter the last name"),
  email: Yup.string()
    .email("Please enter a valid email address")
    .required("Please enter the email address"),
  password: Yup.string().min(6).required("Please enter the password"),
  confirm_password: Yup.string()
    .required("Please enter the password again to confirm")
    .oneOf([Yup.ref("password"), null], "Password must match"),
  locationId: Yup.string().required("Please select a location"),
  role: Yup.string().required("Please enter the role"),
});
export const UpdateUserSchema = Yup.object({
  firstName: Yup.string()
    .max(25, "last name must be of maximum 25 characters")
    .required("Please enter the First name"),
  lastName: Yup.string()
    .max(25, "last name must be of maximum 25 characters")
    .required("Please enter the last name"),
  email: Yup.string()
    .email("Please enter a valid email address")
    .required("Please enter the email address"),
  password: Yup.string().min(6).optional("Please enter the password"),
  confirm_password: Yup.string()
    .when("password", {
      is: (password) => password,
      then: Yup.string().required("Please enter the password again to confirm"),
      otherwise: Yup.string().optional(),
    })
    .oneOf([Yup.ref("password"), null], "Password must match"),
  locationId: Yup.string().required("Please select a location"),
  role: Yup.string().required("Please enter the role"),
});
export const scheduleSchema = Yup.object({
  name: Yup.string()
    .max(25, "name must be of maximum 25 characters")
    .required("Please enter the name"),
  start_date: Yup.date("Please select a valid date").required(
    "Please enter the start date"
  ),
  end_date: Yup.date("Please select a valid date")
    .min(Yup.ref("start_date"), "End date must be greater than start date")
    .required("Please enter the end date"),
  location_id: Yup.string().required("Please select the location"),
});

export const availabilitySchema = Yup.object({
  sunday: Yup.boolean(),
  sun_start: Yup.string().when("sunday", {
    is: true,
    then: Yup.string().required("Start time required"),
  }),
  sun_end: Yup.string().when("sunday", {
    is: true,
    then: Yup.string()
      .required("End time required")
      .test("is-greater", "end time should be greater", function (value) {
        const { sun_start } = this.parent;
        return moment(value, "HH:mm a").isSameOrAfter(
          moment(sun_start, "HH:mm a")
        );
      }),
  }),

  sun_week: Yup.string().when("sunday", {
    is: true,
    then: Yup.string().min(1, "Atleast 1").required("Repeat week required"),
    otherwise: Yup.string().optional().nullable(),
  }),

  sun_skip_week: Yup.number().when("sunday", {
    is: true,
    then: Yup.number().required("skip week is required"),
    otherwise: Yup.number().optional().nullable(),
  }),
  monday: Yup.boolean(),
  mon_start: Yup.string().when("monday", {
    is: true,
    then: Yup.string().required("Start time required"),
  }),
  mon_end: Yup.string().when("monday", {
    is: true,
    then: Yup.string()
      .required("End time required")
      .test("is-greater", "end time should be greater", function (value) {
        const { mon_start } = this.parent;
        return moment(value, "HH:mm a").isSameOrAfter(
          moment(mon_start, "HH:mm a")
        );
      }),
  }),
  mon_week: Yup.string().when("monday", {
    is: true,
    then: Yup.string().min(1, "Atleast 1").required("Repeat week required"),
    otherwise: Yup.string().optional().nullable(),
  }),
  mon_skip_week: Yup.number().when("monday", {
    is: true,
    then: Yup.number()
      .max(Yup.ref("mon_week"), "skip week should not be greater")
      .required("skip week is required"),
    otherwise: Yup.number().optional().nullable(),
  }),
  tuesday: Yup.boolean(),
  tue_start: Yup.string().when("tuesday", {
    is: true,
    then: Yup.string().required("Start time required"),
  }),
  tue_end: Yup.string().when("tuesday", {
    is: true,
    then: Yup.string()
      .required("End time required")
      .test("is-greater", "end time should be greater", function (value) {
        const { tue_start } = this.parent;
        return moment(value, "HH:mm a").isSameOrAfter(
          moment(tue_start, "HH:mm a")
        );
      }),
  }),
  tue_week: Yup.string().when("tuesday", {
    is: true,
    then: Yup.string().min(1, "Atleast 1").required("Repeat week required"),
    otherwise: Yup.string().optional().nullable(),
  }),
  tue_skip_week: Yup.number().when("tuesday", {
    is: true,
    then: Yup.number()
      .max(Yup.ref("tue_week"), "skip week should not be greater")
      .required("skip week is required"),
    otherwise: Yup.number().optional().nullable(),
  }),

  wednesday: Yup.boolean(),
  wed_start: Yup.string().when("wednesday", {
    is: true,
    then: Yup.string().required("Start time required"),
  }),
  wed_end: Yup.string().when("wednesday", {
    is: true,
    then: Yup.string()
      .required("End time required")
      .test("is-greater", "end time should be greater", function (value) {
        const { wed_start } = this.parent;
        return moment(value, "HH:mm a").isSameOrAfter(
          moment(wed_start, "HH:mm a")
        );
      }),
  }),

  wed_week: Yup.string().when("wednesday", {
    is: true,
    then: Yup.string().min(1, "Atleast 1").required("Repeat week required"),
    otherwise: Yup.string().optional().nullable(),
  }),
  wed_skip_week: Yup.number().when("wednesday", {
    is: true,
    then: Yup.number()
      .max(Yup.ref("wed_week"), "skip week should not be greater")
      .required("skip week is required"),
    otherwise: Yup.number().optional().nullable(),
  }),
  thursday: Yup.boolean(),
  thu_start: Yup.string().when("thursday", {
    is: true,
    then: Yup.string().required("Start time required"),
  }),
  thu_end: Yup.string().when("thursday", {
    is: true,
    then: Yup.string()
      .required("End time required")
      .test("is-greater", "end time should be greater", function (value) {
        const { thu_start } = this.parent;
        return moment(value, "HH:mm a").isSameOrAfter(
          moment(thu_start, "HH:mm a")
        );
      }),
  }),
  thu_week: Yup.string().when("thursday", {
    is: true,
    then: Yup.string().min(1, "Atleast 1").required("Repeat week required"),
    otherwise: Yup.string().optional().nullable(),
  }),
  thu_skip_week: Yup.number().when("thursday", {
    is: true,
    then: Yup.number()
      .max(Yup.ref("thu_week"), "skip week should not be greater")
      .required("skip week is required"),
    otherwise: Yup.number().optional().nullable(),
  }),
  friday: Yup.boolean(),
  fri_start: Yup.string().when("friday", {
    is: true,
    then: Yup.string().required("Start time required"),
  }),
  fri_end: Yup.string().when("friday", {
    is: true,
    then: Yup.string()
      .required("End time required")
      .test("is-greater", "end time should be greater", function (value) {
        const { fri_start } = this.parent;
        return moment(value, "HH:mm a").isSameOrAfter(
          moment(fri_start, "HH:mm a")
        );
      }),
  }),
  fri_week: Yup.string().when("friday", {
    is: true,
    then: Yup.string().min(1, "Atleast 1").required("Repeat week required"),
    otherwise: Yup.string().optional().nullable(),
  }),
  fri_skip_week: Yup.number().when("friday", {
    is: true,
    then: Yup.number()
      .max(Yup.ref("fri_week"), "skip week should not be greater")
      .required("skip week is required"),
    otherwise: Yup.number().optional().nullable(),
  }),

  saturday: Yup.boolean(),
  sat_start: Yup.string().when("saturday", {
    is: true,
    then: Yup.string().required("Start time required"),
  }),
  sat_end: Yup.string().when("saturday", {
    is: true,
    then: Yup.string()
      .required("End time required")
      .test("is-greater", "end time should be greater", function (value) {
        const { sat_start } = this.parent;
        return moment(value, "HH:mm a").isSameOrAfter(
          moment(sat_start, "HH:mm a")
        );
      }),
  }),
  sat_week: Yup.string().when("saturday", {
    is: true,
    then: Yup.string().min(1, "Atleast 1").required("Repeat week required"),
    otherwise: Yup.string().optional().nullable(),
  }),
  sat_skip_week: Yup.number().when("saturday", {
    is: true,
    then: Yup.number()
      .max(Yup.ref("sat_week"), "skip week should not be greater")
      .required("skip week is required"),
    otherwise: Yup.number().optional().nullable(),
  }),
});

export const overrideSchema = Yup.object({
  available: Yup.boolean(),
  date: Yup.string()
    .required("please enter a date")
    .when("check", {
      is: true,
      then: Yup.string().required("please enter a date"),
    }),
  from_time: Yup.string().required(),
  to_time: Yup.string()
    .required("this shouldn't be empty")
    .test("is-greater", "end time should be greater", function (value) {
      const { from_time } = this.parent;
      return moment(value, "HH:mm a").isSameOrAfter(
        moment(from_time, "HH:mm a")
      );
    }),
});
const parseTimeString = (timeString) => {
  const [hours, minutes] = timeString.split(":").map(Number);
  const date = new Date();
  date.setHours(hours, minutes, 0, 0);
  return date;
};
export const AppointmentSchema = Yup.object().shape({
  type: Yup.string().oneOf(["OFFLINE", "ONLINE"]).required(),
  locationId: Yup.string().required("Location is required"),
  patientId: Yup.string().required("Patient is required"),
  doctorId: Yup.string().required("Doctor is required"),
  date: Yup.date().required("Appointment Date is required"),
  time: Yup.string().required("Appointment Time is required"),
  enrolled: Yup.boolean().required(),
  cost: Yup.number().when("enrolled", {
    is: false,
    then: Yup.number().required("Cost is required"),
    otherwise: Yup.number().optional().nullable(),
  }),
  payment_type: Yup.string().when("enrolled", {
    is: false,
    then: Yup.string().oneOf(["FULL", "ADVANCE"]).required(),
    otherwise: Yup.string().optional().nullable(),
  }),
  advance_payment_amount: Yup.number().when(["payment_type", "enrolled"], {
    is: (paymentType, enrolled) => paymentType === "ADVANCE" && !enrolled,
    then: Yup.number()
      .max(Yup.ref("cost"), "Cannot be more than total cost itself")
      .required("Advance Payment Amount is required"),
    otherwise: Yup.number().optional().nullable(),
  }),
  book_type: Yup.boolean().required(),

  time_start_time: Yup.string()
    .optional()
    .test("is-after-9-am", "Start time cannot be before 9 AM", (value) => {
      if (!value) return false;
      const startTime = parseTimeString(value);
      const startHour = startTime.getHours();
      return startHour >= 9;
    }),
  time_end_time: Yup.string()
    .optional()
    .test("is-before-9-pm", "End time cannot be after 9 PM", (value) => {
      if (!value) return false;
      const endTime = parseTimeString(value);
      const endHour = endTime.getHours();
      return endHour < 21;
    }),
});
export const UpdateAppointmentSchema = Yup.object().shape({
  type: Yup.string().oneOf(["OFFLINE", "ONLINE"]).required(),
  // locationId: Yup.string().when("type",{
  //   is : "OFFLINE",
  //   then : Yup.string().required('Location is required')
  // }),
  // patientId: Yup.string().required('Patient is required'),
  doctorId: Yup.string().required("Doctor is required"),
  date: Yup.date().required("Appointment Date is required"),
  time: Yup.string().required("Appointment Time is required"),
  payment_type: Yup.string().oneOf(["FULL", "ADVANCE"]).required(),
  advance_payment_amount: Yup.number().when(["payment_type", "enrolled"], {
    is: (paymentType, enrolled) => paymentType === "ADVANCE" && !enrolled,
    then: Yup.number()
      .max(Yup.ref("cost"), "Cannot be more than total cost itself")
      .required("Advance Payment Amount is required"),
    otherwise: Yup.number().optional().nullable(),
  }),
  isFollowUp: Yup.boolean().optional(),
  cost: Yup.number().required("Cost is required"),
  book_type: Yup.boolean().required(),
});

export const ReferralSchema = Yup.object({
  name: Yup.string().required("Name is required"),
  type: Yup.string().required("Type is required"),
});
export const DepartmentSchema = Yup.object({
  name: Yup.string().required("Name is required"),
  // cost: Yup.number().required("Cost is required"),
});
export const GoalSchema = Yup.object({
  name: Yup.string().required("Name is required"),
  department_id: Yup.string().required("Select a Department"),
});
export const CategorySchema = Yup.object({
  name: Yup.string().required("Name is required"),
});
export const ageGroupSchema = Yup.object({
  name: Yup.string().required("Name is required"),
});
export const domainSchema = Yup.object({
  name: Yup.string().required("Name is required"),
  age_group_id: Yup.string().required("Select a Age Group"),
});
export const programSchema = Yup.object({
  name: Yup.string().required("Name is required"),
  // total_cost: Yup.string().required('Please enter total cost'),
  // total_sessions: Yup.string().required('Please enter total sessions'),
});
export const ProgramSessionSchema = Yup.object({
  program_id: Yup.string().required("Please select a Program"),
  department_id: Yup.string().required("Please select a Department"),
  frequency: Yup.string().required("Please enter a Frequency"),
  frequency_type: Yup.string().required("Please select a frequency type"),
});
export const medicineMasterSchema = Yup.object({
  medicine_name_id: Yup.string().required("Please select a Medicine Name"),
  formulation_id: Yup.string().required("Please select a Formulation"),
  strength_id: Yup.string().required("Please enter a Strength"),
  route_of_administration_id: Yup.string().required(
    "Please select a Route of Administration"
  ),
});

export const markPaidSchema = Yup.object({
  payment_source: Yup.string().required("Select a payment source"),
});
