export const ADD_APPOINTMENT_BEGIN = "ADD_APPOINTMENT_BEGIN";
export const ADD_APPOINTMENT_SUCCESS = "ADD_APPOINTMENT_SUCCESS";
export const ADD_APPOINTMENT_ERROR = "ADD_APPOINTMENT_ERROR";

export const GET_APPOINTMENT_BEGIN = "GET_APPOINTMENT_BEGIN";
export const GET_APPOINTMENT_SUCCESS = "GET_APPOINTMENT_SUCCESS";
export const GET_APPOINTMENT_ERROR = "GET_APPOINTMENT_ERROR";

export const GET_AVAILABLE_DATES_BEGIN = "GET_AVAILABLE_DATES_BEGIN";
export const GET_AVAILABLE_DATES_SUCCESS = "GET_AVAILABLE_DATES_SUCCESS";
export const GET_AVAILABLE_DATES_ERROR = "GET_AVAILABLE_DATES_ERROR";

export const GET_AVAILABLE_TIME_SLOTS_BEGIN = "GET_AVAILABLE_TIME_SLOTS_BEGIN";
export const GET_AVAILABLE_TIME_SLOTS_SUCCESS = "GET_AVAILABLE_TIME_SLOTS_SUCCESS";
export const GET_AVAILABLE_TIME_SLOTS_ERROR = "GET_AVAILABLE_TIME_SLOTS_ERROR";

export const UPDATE_APPOINTMENT_BEGIN = "UPDATE_APPOINTMENT_BEGIN"
export const UPDATE_APPOINTMENT_SUCCESS = "UPDATE_APPOINTMENT_SUCCESS"
export const UPDATE_APPOINTMENT_ERROR = "UPDATE_APPOINTMENT_ERROR"

export const DELETE_APPOINTMENT_BEGIN = "DELETE_APPOINTMENT_BEGIN"
export const DELETE_APPOINTMENT_SUCCESS = "DELETE_APPOINTMENT_SUCCESS"
export const DELETE_APPOINTMENT_ERROR = "DELETE_APPOINTMENT_ERROR"