import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { getAllPatients, deletePatient } from "../../../redux/patient/actions";

import Pagination from "../../../components/Pagination";
import { FallingLinesLoader } from "../../../components/spinners/Spinner";
import Breadcrumb from "../../../components/Breadcrumb";
import ConfirmationModal from "../../../components/ConfirmationModal";
import Table from "../../../components/tables/table";
import { patient_columns } from "../../../components/tables/tableheader";

const PatientsList = () => {
  const pages = [{ title: "Patients", href: "/patients" }];

  const dispatch = useDispatch();
  const { list: patientsList, loading } = useSelector((state) => state.patient);
  const [search, setsearch] = useState("");
  const [currentpage, setcurrentpage] = useState(1);
  const [postsPerPage, setpostsPerPage] = useState(7);
  const [query, setQuery] = useState(200);
  const [confirmationModal, setConfirmationModal] = useState({
    status: false,
    id: null,
  });

  useEffect(() => {
    document.title = "New Horizon | Patients";
    dispatch(getAllPatients(200));
  }, []);

  const onDeleteOpen = (id) => {
    setConfirmationModal({ id, status: true });
  };

  const handleDelete = (id) => {
    dispatch(deletePatient(id));
    setConfirmationModal({ id: null, status: false });
  };

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <ConfirmationModal
        open={confirmationModal.status}
        setOpen={setConfirmationModal}
        onDelete={() => handleDelete(confirmationModal.id)}
      />
      <Breadcrumb pages={pages} />
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-xl font-semibold text-gray-900">Patients</h1>
          <p className="mt-2 text-sm text-gray-700">
            A list of all the patients in your account including their name,
            title, email and role.
          </p>
        </div>
        <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
          <Link
            to="/add-patient"
            type="button"
            className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
          >
            Add Patient
          </Link>
        </div>
      </div>
      {loading ? (
        <FallingLinesLoader />
      ) : (
        <Table
          columns={patient_columns({ onDeleteOpen })}
          data={patientsList}
          // onEditClose={cleanModalData}
          fetch={async (d) => {
            setQuery((prev) => d);

            await dispatch(getAllPatients(d));
          }}
        />
      )}
    </div>
  );
};

export default PatientsList;
