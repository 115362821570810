import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import {
  addPatientForm,
  getAllDocuments,
} from "../../../redux/patientForm/actions";
import { useParams } from "react-router-dom";
import { getHeaders } from "../../../redux/auth/actions";
import { toast } from "react-hot-toast";
import axios from "axios";

const API = process.env.REACT_APP_API_URL;
export default function AddDocumentModal({
  DshowModal,
  setDShowModal,
  id,
  doc_id,
  setEditDocId,
  setEditDocName,
  edit_doc_name,
}) {
  const dispatch = useDispatch();
  const initialValues = {
    name: edit_doc_name ? edit_doc_name : "",
    document: "",
  };
  const [formValues, setFormValues] = useState({
    name: "",
    document: "",
    loading: false,
  });

  async function handleUpdate(values) {
    try {
      setFormValues({ ...formValues, loading: true });
      const headers = await dispatch(getHeaders());
      const res = await axios.put(
        `${API}/api/patient/document-upload/${doc_id}`,
        values,
        {
          headers: headers,
        }
      );
      toast.success("Document Updated Successfully");
      setDShowModal(false);
      setEditDocId(null);
      setEditDocName(null);
      dispatch(getAllDocuments(id));
      setFormValues({ ...formValues, loading: false });
    } catch (error) {
      toast.error("Error Updating Document");
    }
  }

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: initialValues,
      enableReinitialize: true,

      onSubmit: (values, action) => {
        //TODO: Optimize code here
        if (doc_id) {
          const body = {
            document_name: formValues.name,
          };

          handleUpdate(body);
          console.log(body);
        } else {
          uploadFile(formValues.document, formValues.name);
        }
        // console.log(formValues);
        action.resetForm();
      },
    });
  // console.log(errors)
  const uploadFile = async (file, name) => {
    setFormValues({ ...formValues, loading: true });
    const headers = await dispatch(getHeaders());
    const newHeader = {
      ...headers,
      "Content-Type": "multipart/form-data",
    };
    const formData = new FormData();
    formData.append("file", file);
    formData.append("document_name", name);

    try {
      const response = await axios.post(
        `${API}/api/patient/document-upload/${id}`,
        formData,
        {
          headers: newHeader,
        }
      );
      toast.success("Document Uploaded Successfully");
      setFormValues({ ...formValues, loading: false });
      return response.data;
    } catch (error) {
      toast.error("Error Uploading Document");
      console.error(error);
    } finally {
      dispatch(getAllDocuments(id));
      setDShowModal(false);
      setFormValues({ ...formValues, loading: false });
    }
  };

  return (
    <Transition.Root show={DshowModal} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={(e) => {
          setDShowModal(false);
          setEditDocId(null);
          setEditDocName(null);
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                <div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900"
                    >
                      {doc_id ? "Update" : "Add"} Document
                    </Dialog.Title>
                    <div className="mt-2 w-full flex items-center justify-center">
                      <form className="w-[80%]" onSubmit={handleSubmit}>
                        <div className="mb-2 w-full sm:col-span-4 sm:mt-0">
                          <input
                            type="text"
                            name="name"
                            id="name"
                            autoComplete="off"
                            required
                            placeholder="Name of the Document"
                            value={values.name}
                            onChange={(event) => {
                              setFormValues({
                                ...formValues,
                                name: event.target.value,
                              });
                              handleChange(event);
                            }}
                            onBlur={handleBlur}
                            className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-indigo-500 sm:max-w-xs sm:text-sm"
                          />
                        </div>

                        {parseInt(doc_id) > 0 ? null : (
                          <div className="mt-2 flex items-center justify-between">
                            <input
                              type="file"
                              name="document"
                              id="document"
                              autoComplete="off"
                              required={doc_id ? false : true}
                              placeholder="Name of the Document"
                              onChange={(event) => {
                                setFormValues({
                                  ...formValues,
                                  document: event.target.files[0],
                                });
                              }}
                              onBlur={handleBlur}
                              className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-indigo-500 sm:max-w-xs sm:text-sm"
                            />
                          </div>
                        )}

                        <div>
                          <div className="mt-5 sm:mt-6">
                            <button
                              disabled={formValues.loading}
                              type="submit"
                              className="inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm disabled:opacity-50 disabled:cursor-not-allowed"
                            >
                              {formValues.loading ? "Saving..." : "Save"}
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
