import React from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { toast } from "react-hot-toast";
import { useDispatch } from "react-redux";
import { getHeaders } from "../../../redux/auth/actions";
import { FallingLinesLoader } from "../../../components/spinners/Spinner";
import moment from "moment";
import { Formik } from "formik";
import ConfirmationModal from "../../../components/ConfirmationModal";
import { useState } from "react";
const api = process.env.REACT_APP_API_URL;
const ComentAndReplies = () => {
  const { comment_id } = useParams();
  const [comment, setComment] = React.useState({
    data: [],
    loading: true,
  });
  const dispatch = useDispatch();
  React.useEffect(() => {
    getComment();
  }, [comment_id]);
  const getComment = async () => {
    const headers = await dispatch(getHeaders());
    try {
      const res = await axios.get(
        `${api}/api/red-flags/rf-comment-replies/${comment_id}`,
        {
          headers: headers,
        }
      );
      const { data, status } = res;
      if (data) {
        setComment((prev) => ({ ...prev, data: data.data, loading: false }));
      }
    } catch (error) {
      toast.error(error.message);
    }
  };
  console.log(comment);
  let user = JSON.parse(localStorage.getItem("newhorizon_user"));
  const [ResolvedConfirmationModal, setResolvedConfirmationModal] = useState({
    status: false,
    id: null,
  });
  async function markResolved(id) {
    const headers = await dispatch(getHeaders());
    try {
      const response = await axios.put(
        `${api}/api/red-flags/rf-comment-resolve/${id}`,
        {},
        {
          headers,
        }
      );
      const { data } = response;
      toast.success(data.message);
      if (data) {
        getComment();
      }

      setResolvedConfirmationModal((prevState) => ({
        ...prevState,
        status: false,
        id: null,
      }));
    } catch (error) {
      toast.error(error.message);
    }
  }
  return comment.loading ? (
    <FallingLinesLoader />
  ) : (
    <div className="max-w-full  rounded-lg p-6">
      <ConfirmationModal
        open={ResolvedConfirmationModal.status}
        setOpen={setResolvedConfirmationModal}
        description="You are about to mark this comment as resolved."
        confirmationButtonText="Resolve"
        onDelete={() => {
          markResolved(ResolvedConfirmationModal.id);
        }}
        confirmationButtonColor="green"
      />
      <h1 className="text-2xl font-semibold mb-3 flex items-start justify-between gap-4">
        {comment?.data?.[0]?.comment}
        <span>
          <button
            disabled={comment?.data?.[0]?.status === "RESOLVED"}
            type="button"
            onClick={() => {
              setResolvedConfirmationModal((prevState) => ({
                ...prevState,
                status: true,
                id: comment_id,
              }));
            }}
            className="inline-flex mt-2 w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm  disabled:opacity-50 disabled:cursor-not-allowed "
          >
            {comment?.data?.[0]?.status === "RESOLVED" ? "Resolved" : "Resolve"}
          </button>
        </span>
      </h1>
      <div className="mt-8">
        {comment?.data?.[0]?.RegFlagCommentReply?.length === 0 ? (
          <p className="text-gray-500">
            No replies yet. Be the first to reply!
          </p>
        ) : (
          comment?.data?.[0]?.RegFlagCommentReply?.map((reply, index) => (
            <div key={index} className="relative mb-4">
              <div className="flex items-start mb-2">
                <div className="w-8 h-8 bg-blue-100 rounded-full flex items-center justify-center text-indigo-500 text-sm">
                  {reply.commented_by_user.firstName.charAt(0)}
                </div>

                <div className="ml-2">
                  <h3 className="text-base font-semibold">
                    {reply.commented_by_user.firstName +
                      " " +
                      reply.commented_by_user.lastName}
                  </h3>
                  <p className="text-gray-600">
                    {moment(reply.createdAt).format("MMM DD YYYY, HH:mm A")}
                  </p>
                </div>
              </div>
              <div className="relative bg-gray-100 p-4 rounded-lg border ">
                <div className="absolute z-0 w-3 h-3 border-t border-l bg-gray-100 transform rotate-45 -top-2 left-2"></div>
                <p>{reply.comment}</p>
              </div>
            </div>
          ))
        )}
        <Formik
          initialValues={{
            comment: "",
          }}
          onSubmit={async (values, { setSubmitting, resetForm }, e) => {
            const headers = await dispatch(getHeaders());
            try {
              const res = await axios.post(
                `${api}/api/red-flags/rf-comment-add-replies`,
                {
                  comment: values.comment,
                  comment_id: Number(comment_id),
                  commented_by_user_id: user.id,
                },
                {
                  headers: headers,
                }
              );
              const { data, status } = res;
              if (data) {
                toast.success(data.message);
                resetForm();
                setComment((prev) => ({
                  ...prev,
                  data: data.data,
                }));
              }
            } catch (error) {
              toast.error(error.message);
            }
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
          }) => (
            <form onSubmit={handleSubmit}>
              <div className="relative mt-4">
                <div className="flex items-start mb-2">
                  <div className="w-8 h-8 bg-blue-100 rounded-full flex items-center justify-center text-indigo-500 text-sm">
                    {user?.firstName?.charAt(0)}
                  </div>

                  <div className="ml-2 mb-4">
                    <h3 className="text-base font-semibold">
                      {user?.firstName + " " + user?.lastName}
                    </h3>
                  </div>
                </div>
                <div className="relative bg-gray-100 p-4 rounded-lg border ">
                  <div className="absolute z-0 w-3 h-3 border-t border-l bg-gray-100 transform rotate-45 -top-2 left-2"></div>
                  <textarea
                    name="comment"
                    id="comment"
                    value={values.comment}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder="Enter You Reply"
                    className="w-full border border-gray-300 rounded-md outline-none "
                    cols="3"
                  ></textarea>
                </div>
              </div>
              <div className="mt-4 flex items-center justify-end">
                <button
                  disabled={comment?.data?.[0]?.status === "RESOLVED"}
                  type="submit"
                  className="inline-flex mt-2 w-16 justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm disabled:opacity-50 disabled:cursor-not-allowed "
                >
                  {" "}
                  {comment?.data?.[0]?.status === "RESOLVED"
                    ? "Resolved"
                    : "Reply"}
                </button>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default ComentAndReplies;
