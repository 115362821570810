export const GET_ALL_GOAL_BEGIN = "GET_ALL_GOAL_BEGIN"
export const GET_ALL_GOAL_SUCCESS = "GET_ALL_GOAL_SUCCESS"
export const GET_ALL_GOAL_ERROR = "GET_ALL_GOAL_ERROR"

export const ADD_GOAL_BEGIN = "ADD_GOAL_BEGIN"
export const ADD_GOAL_SUCCESS = "ADD_GOAL_SUCCESS"
export const ADD_GOAL_ERROR = "ADD_GOAL_ERROR"

export const GET_GOAL_BEGIN = "GET_GOAL_BEGIN"
export const GET_GOAL_SUCCESS = "GET_GOAL_SUCCESS"
export const GET_GOAL_ERROR = "GET_GOAL_ERROR"

export const UPDATE_GOAL_BEGIN = "UPDATE_GOAL_BEGIN"
export const UPDATE_GOAL_SUCCESS = "UPDATE_GOAL_SUCCESS"
export const UPDATE_GOAL_ERROR = "UPDATE_GOAL_ERROR"

export const DELETE_GOAL_BEGIN = "DELETE_GOAL_BEGIN"
export const DELETE_GOAL_SUCCESS = "DELETE_GOAL_SUCCESS"
export const DELETE_GOAL_ERROR = "DELETE_GOAL_ERROR"