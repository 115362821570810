import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import Breadcrumb from "../../../components/Breadcrumb";
import { FallingLinesLoader } from "../../../components/spinners/Spinner";
import Table from "../../../components/tables/table";
import { patient_recommendation_colums } from "../../../components/tables/tableheader";
import { Togglebtn } from "../../../components/toggle switch";
import react, { useState } from "react";
import { Switch } from "@headlessui/react";

import {
  getAllPatientRecommandation,
  getPatientRecommandationWithId,
  toggleFollowUp,
} from "../../../redux/patientrecommandation/actions";

const PatientRecommendation = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const pages = [
    { title: "Patient Recommemdation", href: "/patient-recommendation" },
  ];
  const { loading, list: dataList } = useSelector(
    (state) => state.patientRecommadation
  );
  const [query, setQuery] = useState(200);
  useEffect(() => {
    if (id) {
      dispatch(getPatientRecommandationWithId(id));
    } else {
      dispatch(getAllPatientRecommandation(200));
    }
  }, []);

  const [filter, setFilter] = useState(dataList);

  useEffect(() => {
    setFilter(dataList);
  }, [dataList]);

  const onToggleFollowUp = async (id) => {
    // dispatch(toggleFollowUp(id));
    const data = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/program/patient-recommendation/toggle-follow-up/${id}`
    );
    // console.log(data);
  };

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <Breadcrumb pages={pages} />
      <div className="sm:flex sm:items-center mb-5">
        <div className="sm:flex-auto">
          <h1 className="text-xl flex items-center justify-between font-semibold text-gray-900">
            Patient Recommendation
          </h1>
        </div>
      </div>
      {loading ? (
        <FallingLinesLoader />
      ) : (
        <Table
          columns={patient_recommendation_colums({ onToggleFollowUp })}
          data={filter}
          // onEditClose={cleanModalData}
          fetch={async (e) => {
            setQuery((prev) => e);
            await dispatch(getAllPatientRecommandation(e));
          }}
        />
      )}
    </div>
  );
};

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export const Togglebtnfilter = ({ dataList, setFilter }) => {
  const [enabled, setEnabled] = useState(false);
  // const [enabled, setEnabled] = useState(true)
  // const [enabled, setEnabled] = useState(status);
  const filteredDepartments = dataList.filter(
    (department) => department.follow_up_status === true
  );
  return (
    <Switch
      checked={enabled}
      onChange={() => {
        setEnabled(!enabled);
        enabled === true ? setFilter(dataList) : setFilter(filteredDepartments);
      }}
      //   onClick={() =>fun(id)}
      className={classNames(
        enabled ? "bg-indigo-600" : "bg-gray-200",
        "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
      )}
    >
      <span className="sr-only">Use setting</span>
      <span
        className={classNames(
          enabled ? "translate-x-5" : "translate-x-0",
          "pointer-events-none relative inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
        )}
      >
        <span
          className={classNames(
            enabled
              ? "opacity-0 ease-out duration-100"
              : "opacity-100 ease-in duration-200",
            "absolute inset-0 flex h-full w-full items-center justify-center transition-opacity"
          )}
          aria-hidden="true"
        >
          <svg
            className="h-3 w-3 text-gray-400"
            fill="none"
            viewBox="0 0 12 12"
          >
            <path
              d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2"
              stroke="currentColor"
              strokeWidth={2}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </span>
        <span
          className={classNames(
            enabled
              ? "opacity-100 ease-in duration-200"
              : "opacity-0 ease-out duration-100",
            "absolute inset-0 flex h-full w-full items-center justify-center transition-opacity"
          )}
          aria-hidden="true"
        >
          <svg
            className="h-3 w-3 text-indigo-600"
            fill="currentColor"
            viewBox="0 0 12 12"
          >
            <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
          </svg>
        </span>
      </span>
    </Switch>
  );
};

export default PatientRecommendation;
