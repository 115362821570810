import React, { Fragment, useEffect, useState } from "react";
import Table from "../../../components/tables/table";
import {
  payment_columns,
  red_payment_columns,
} from "../../../components/tables/tableheader";
import { useDispatch, useSelector } from "react-redux";
import { FallingLinesLoader } from "../../../components/spinners/Spinner";
import ConfirmationModal from "../../../components/ConfirmationModal";
import { Dialog, Transition } from "@headlessui/react";
import { useFormik } from "formik";
import { markPaidSchema } from "../../../schemas";
import moment from "moment";
import axios from "axios";
import { getHeaders } from "../../../redux/auth/actions";
import { toast } from "react-hot-toast";

const RedflagPayment = () => {
  const [confirmationModal, setConfirmationModal] = useState({
    status: false,
    id: null,
  });
  const [paymentLog, setSetPaymentLog] = useState({
    loading: false,
    data: [],
  });
  const [sconfirmationModal, setsConfirmationModal] = useState({
    status: false,
    id: null,
  });
  const [showModal, setShowModal] = useState(false);
  const [paid, setPaid] = useState(false);
  const [date, setDate] = useState("");
  console.log("payment status" + paid);
  const dispatch = useDispatch();
  const [markPaidId, setMarkPaidId] = useState(null);
  const loading = false;
  const [data, setData] = useState([]);
  useEffect(() => {
    document.title = "New Horizon | Payments";
    // dispatch(getAllPayments());
    // setData(paymentList);
    if (paid) {
      getPaymentLogs();
      //   dispatch(getAllPayments());
    }
  }, [paid]);
  console.log(date);
  const markPaid = (id) => {
    setMarkPaidId(id);
  };
  const sendPaymentLinkConfirmation = (id) => {
    setsConfirmationModal({ id, status: true });
  };

  const onSend = (id) => {
    // dispatch(sendPaymentsLink(id));
    setConfirmationModal({ id: null, status: false });
  };
  const sendInvoiceLink = (id) => {
    setConfirmationModal({ id, status: true });
  };
  const sendInvoice = (id) => {
    // dispatch(sendInvoiceSmsLink(id))
    setConfirmationModal({ id: null, status: false });
  };
  if (paid) {
    setPaid(false);
  }

  //   useEffect(() => {
  //     // setData(paymentList);
  //   }, [paymentList]);
  useEffect(() => {
    getPaymentLogs();
  }, []);

  //   if (date !== "") {
  //     const newArr = paymentList.filter((item) => {
  //       var newDate = moment(item.appointment.date).format("YYYY-MM-DD");
  //       return newDate === date;
  //     });
  //     setData(newArr);
  //   }

  //   const handleChangeDate = (date) => {
  //     if (date !== "") {
  //       const newArr = paymentList.filter((item) => {
  //         var newDate = moment(item.appointment.date).format("YYYY-MM-DD");
  //         return newDate === date;
  //       });
  //       // console.log(newArr);
  //       console.log(newArr);
  //       setData(newArr);
  //     }
  //   };

  async function getPaymentLogs() {
    const headers = await dispatch(getHeaders());
    try {
      setSetPaymentLog((prev) => ({ ...prev, loading: true }));
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/red-flags/get-fourty-percent-payment`,
        { headers }
      );
      const { data } = response;
      console.log(data);
      if (data) {
        setSetPaymentLog((prev) => ({
          ...prev,
          data: data.data,
        }));
      }
      setSetPaymentLog((prev) => ({
        ...prev,
        loading: false,
      }));
    } catch (error) {
      toast.error(error.response.data.message);
    } finally {
      setSetPaymentLog((prev) => ({ ...prev, loading: false }));
    }
  }
  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <SendLinkModal
        paymentId={markPaidId}
        showModal={showModal}
        setShowModal={setShowModal}
        setPaid={setPaid}
      />
      <ConfirmationModal
        open={sconfirmationModal.status}
        setOpen={setsConfirmationModal}
        title=" "
        onSend={() => onSend(sconfirmationModal.id)}
        confirmationButtonText="Send"
        confirmationButtonColor="indigo"
        description="Do you want to send the payment link?"
      />
      <ConfirmationModal
        open={confirmationModal.status}
        setOpen={setConfirmationModal}
        title=" "
        sendInvoice={() => sendInvoice(confirmationModal.id)}
        confirmationButtonText="Send"
        confirmationButtonColor="indigo"
        description="Do you want to send the Invoice link?"
      />
      <div className="sm:flex sm:items-center mb-5">
        <div className="sm:flex-auto">
          <h1 className="text-xl font-semibold text-gray-900">Payment Logs</h1>
        </div>
        {/* <input
          type="date"
          name="date"
          // value={(e) => setDate(e.target.value)}
          onChange={(e) => handleChangeDate(e.target.value)}
          placeholder="select date"
          className="mr-2 block w-[20%] max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-indigo-500 sm:max-w-xs sm:text-sm"
        /> */}
      </div>
      {console.log(paymentLog)}
      {paymentLog.loading ? (
        <FallingLinesLoader />
      ) : (
        <Table
          columns={red_payment_columns({
            markPaid,
            setShowModal,
          })}
          data={paymentLog.data}
        />
      )}
    </div>
  );
};

export default RedflagPayment;

export function SendLinkModal({ paymentId, setPaid, showModal, setShowModal }) {
  const [confirmationModal, setConfirmationModal] = useState({
    status: false,
    id: null,
  });
  const [paySrc, setPaySrc] = useState("");
  const [showError, setShowError] = useState(false);
  const initialValues = {
    payment_id: paymentId,
    payment_source: "",
  };
  const dispatch = useDispatch();
  const markPaid = async () => {
    //TODO: Add dispach here for marking paid
    //
    // const body = {
    //   payment_id: paymentId,
    //   payment_source: paySrc,
    // };
    await markingPaid(paymentId);
    setConfirmationModal({ id: null, status: false });
    setShowModal(false);
    setPaySrc("");
    setShowError(false);

    // dispatch(getAllPayments());
    setPaid(true);
  };
  async function markingPaid(id) {
    const headers = await dispatch(getHeaders());
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/api/therapist/appointment/payment/${id}`,
        {},
        { headers }
      );
      const { data } = response;
      if (data) {
        toast.success(data.message);
      }
    } catch (error) {
      toast.error(error.response.data.message);
    }
  }
  const markPaidConfirmation = () => {
    if (paySrc === "") {
      setShowError(true);
      return;
    }
    setConfirmationModal({ status: true });
    setShowError(false);
  };

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: initialValues,
      validationSchema: markPaidSchema,

      onSubmit: (values, action) => {
        markPaidConfirmation();
        //TODO: Optimize code here
        action.resetForm();
        setPaid(true);
      },
    });
  // console.log(errors)

  return (
    <Transition.Root show={showModal} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setShowModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                <div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900"
                    >
                      Mark Paid
                    </Dialog.Title>
                    <div className="mt-2 w-full flex items-center justify-center">
                      <ConfirmationModal
                        open={confirmationModal.status}
                        setOpen={setConfirmationModal}
                        title=" "
                        markPaid={markPaid}
                        confirmationButtonText="Yes"
                        firstBtn="No"
                        confirmationButtonColor="indigo"
                        description="Do you want to mark this payment as paid?"
                      />
                      <form className="w-[80%]" onSubmit={handleSubmit}>
                        <div className="mt-2 flex items-center justify-between">
                          <select
                            id="payment_source"
                            name="payment_source"
                            onChange={(e) => setPaySrc(e.target.value)}
                            onBlur={handleBlur}
                            className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-indigo-500 sm:max-w-xs sm:text-sm"
                          >
                            <option>Payment Sources</option>
                            <option value={"RAZORPAY"}>Razorpay</option>
                            <option value={"CASH"}>Cash</option>
                            <option value={"UPI"}>UPI</option>
                            <option value={"CARD"}>Card</option>
                            <option value={"NET_BANKING"}>Net Banking</option>
                            <option value={"CHEQUE"}>Cheque</option>
                          </select>
                        </div>

                        <div>
                          <div className="mt-5 sm:mt-6">
                            <button
                              type="submit"
                              className="inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm"
                              onClick={() => {
                                markPaidConfirmation();
                              }}
                            >
                              Mark As Paid
                            </button>
                            {showError && (
                              <p className="text-red-500 text-sm">
                                Please select a payment source
                              </p>
                            )}
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
