export const GET_ALL_CATEGORY_BEGIN = "GET_ALL_CATEGORY_BEGIN"
export const GET_ALL_CATEGORY_SUCCESS = "GET_ALL_CATEGORY_SUCCESS"
export const GET_ALL_CATEGORY_ERROR = "GET_ALL_CATEGORY_ERROR"

export const ADD_CATEGORY_BEGIN = "ADD_CATEGORY_BEGIN"
export const ADD_CATEGORY_SUCCESS = "ADD_CATEGORY_SUCCESS"
export const ADD_CATEGORY_ERROR = "ADD_CATEGORY_ERROR"

export const GET_CATEGORY_BEGIN = "GET_CATEGORY_BEGIN"
export const GET_CATEGORY_SUCCESS = "GET_CATEGORY_SUCCESS"
export const GET_CATEGORY_ERROR = "GET_CATEGORY_ERROR"

export const UPDATE_CATEGORY_BEGIN = "UPDATE_CATEGORY_BEGIN"
export const UPDATE_CATEGORY_SUCCESS = "UPDATE_CATEGORY_SUCCESS"
export const UPDATE_CATEGORY_ERROR = "UPDATE_CATEGORY_ERROR"

export const DELETE_CATEGORY_BEGIN = "DELETE_CATEGORY_BEGIN"
export const DELETE_CATEGORY_SUCCESS = "DELETE_CATEGORY_SUCCESS"
export const DELETE_CATEGORY_ERROR = "DELETE_CATEGORY_ERROR"