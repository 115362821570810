import { 
    GET_ALL_MEDICINE_MASTER_BEGIN,
    GET_ALL_MEDICINE_MASTER_SUCCESS,
    GET_ALL_MEDICINE_MASTER_ERROR,
    ADD_MEDICINE_MASTER_BEGIN,
    ADD_MEDICINE_MASTER_SUCCESS,
    ADD_MEDICINE_MASTER_ERROR,
    GET_MEDICINE_MASTER_BEGIN,
    GET_MEDICINE_MASTER_SUCCESS,
    GET_MEDICINE_MASTER_ERROR,
    UPDATE_MEDICINE_MASTER_BEGIN,
    UPDATE_MEDICINE_MASTER_SUCCESS,
    UPDATE_MEDICINE_MASTER_ERROR,
    DELETE_MEDICINE_MASTER_BEGIN,
    DELETE_MEDICINE_MASTER_SUCCESS,
    DELETE_MEDICINE_MASTER_ERROR
 } from './constants'

 const initState = {
    list: [],
    loading: false,
    error: null,
  };
  
  const MedicineMasterReducer = (state = initState, action) => {
    const { type, data, error } = action;
    switch (type) {
      case GET_ALL_MEDICINE_MASTER_BEGIN:
        return {
          ...state,
          loading: true,
        };
      case GET_ALL_MEDICINE_MASTER_SUCCESS:
        return {
          ...state,
          list: data,
          loading: false,
        };
      case GET_ALL_MEDICINE_MASTER_ERROR:
        return {
          ...state,
          error,
          loading: false,
        };
      case ADD_MEDICINE_MASTER_BEGIN:
        return {
          ...state,
          loading: true,
        };
      case ADD_MEDICINE_MASTER_SUCCESS:
        return {
          ...state,
          loading: false,
          list: [...state.list, data],
        };
      case ADD_MEDICINE_MASTER_ERROR:
        return {
          ...state,
          error,
          loading: false,
        };
      case GET_MEDICINE_MASTER_BEGIN:
        return {
          ...state,
          loading: true,
          medicineMasterData: "",
        };
      case GET_MEDICINE_MASTER_SUCCESS:
        return {
          ...state,
          loading: false,
          medicineMasterData: data,
        };
      case GET_MEDICINE_MASTER_ERROR:
        return {
          ...state,
          error,
          loading: false,
        };
      case UPDATE_MEDICINE_MASTER_BEGIN:
        return {
          ...state,
          loading: true,
        };
      case UPDATE_MEDICINE_MASTER_SUCCESS:
        return {
          ...state,
          loading: false,
          list: state.list.map((ele) => {
            if (ele.id === data.id) {
              return { ...data };
            } else return ele;
          }),
        };
      case UPDATE_MEDICINE_MASTER_ERROR:
        return {
          ...state,
          error,
          loading: false,
        };
      case DELETE_MEDICINE_MASTER_BEGIN:
        return {
          ...state,
          loading: true,
        };
      case DELETE_MEDICINE_MASTER_SUCCESS:
        return {
          ...state,
          loading: false,
          list: state.list.filter((ele) => ele.id !== data.id),
        };
      case DELETE_MEDICINE_MASTER_ERROR:
        return {
          ...state,
          error,
          loading: false,
        };
      default:
        return state;
    }
  };
  
  export default MedicineMasterReducer;
  