import React, { useState, Fragment } from "react";
import { Link } from "react-router-dom";
import Table from "../../../components/tables/table";
// import { useDispatch } from "react-redux";
import { medicine_columns } from "../../../components/tables/tableheader";
import { Dialog, Transition, } from "@headlessui/react";
import {
  XMarkIcon,
} from "@heroicons/react/24/outline";

const medicine_data = [
  {
    id:1,
    medicine_name: "TEST medicine"
  }
]

function MedicineList() {
  const [modalOpenFlage, setmodalOpenFlage] = useState(false);
  const [medicine_name, setMedicineName] = useState('')
  const [medicine_id, setMedicineid] = useState('')


  // const dispatch = useDispatch();
  // const { loading, list: medicine_data } = useSelector((state) => state.doctor);
  const [medicine_dataArr, setmedicine_dataArr] = useState(medicine_data);
       
  const handleDrawer = (type, id, obj) => {
    if(type=== "edit", id){
      setMedicineid(id)
      setMedicineName(obj.medicine_name)
    } else {
      setMedicineid("")
    }
    if (modalOpenFlage === false) {     
      setmodalOpenFlage(true);
    }
  };

  const addMedicine = () => {
    if(medicine_id){
      medicine_dataArr.forEach((value, index) => {
        if(medicine_id === value.id){
          value.medicine_name = medicine_name
        }
      })
    } else{
    const obj = {
      id:parseInt(medicine_dataArr.length)+1,
      medicine_name: medicine_name
    }
    medicine_dataArr.push(obj)
  }
    setmedicine_dataArr([...medicine_dataArr])

    if (modalOpenFlage === true) {
      setMedicineName('')
      setmodalOpenFlage(false);
    };    
  }
  const removeMedicine = (id) => {
    medicine_dataArr.forEach((element, index)=> {
      if(id === element.id){
        medicine_dataArr.splice(index, 1)
      }
    });
    setmedicine_dataArr([...medicine_dataArr])
  };
  return (
    <div>
      <div className="">
        <h1 className="text-xl font-semibold text-gray-900">Medicine List</h1>
        <p className="mt-2 text-sm text-gray-700">
          A list of all the Medicines.
        </p>
      </div>
      <div className="mt-4 flex">
        <Link
          onClick={() => handleDrawer("add", "", {})}
          type="button"
          className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 ml-auto"
        >
          Add Medicine
        </Link>
      </div>

      {
      //   loading ? (
      //   <FallingLinesLoader />
      // ) : (
        <Table 
          columns={medicine_columns({ removeMedicine,  handleDrawer})} 
          data={medicine_dataArr} 
        />
      // )
    }

      
      <div>
        <Transition.Root show={modalOpenFlage} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-10"
            onClose={setmodalOpenFlage}
          >
            <div className="fixed inset-0" />
            <div className="fixed inset-0 overflow-hidden">
              <div className="absolute inset-0 overflow-hidden">
                <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                  <Transition.Child
                    as={Fragment}
                    enter="transform transition ease-in-out duration-500 sm:duration-700"
                    enterFrom="translate-x-full"
                    enterTo="translate-x-0"
                    leave="transform transition ease-in-out duration-500 sm:duration-700"
                    leaveFrom="translate-x-0"
                    leaveTo="translate-x-full"
                  >
                    <Dialog.Panel className="pointer-events-auto w-screen max-w-xs">
                      <form onSubmit={(event) => { event.preventDefault(); }} className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                        <div className="h-0 flex-1 overflow-y-auto">
                          <div className="bg-indigo-700 py-6 px-4 sm:px-6">
                            <div className="flex items-center justify-between">
                              <Dialog.Title className="text-lg font-medium text-white">
                                Add Medicine
                              </Dialog.Title>
                              <div className="ml-3 flex h-7 items-center">
                                <button
                                  type="button"
                                  className="rounded-md bg-indigo-700 text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                                  onClick={() => setmodalOpenFlage(false)}
                                >
                                  <span className="sr-only">Close panel</span>
                                  <XMarkIcon
                                    className="h-6 w-6"
                                    aria-hidden="true"
                                  />
                                </button>
                              </div>
                            </div>
                          </div>
                          <div className="flex flex-1 flex-col justify-between">
                            <div className="divide-y divide-gray-200 px-4 sm:px-6">
                              <div className="space-y-6 pt-6 pb-5">
                                <div>
                                  <label
                                    htmlFor="project-name"
                                    className="block text-sm font-medium text-gray-900"
                                  >
                                    Enter Medicine Name
                                  </label>
                                </div>
                                <div className="mt-1 sm:col-span-2 sm:mt-0">
                                  <input
                                    value={medicine_name}
                                    onChange={event => setMedicineName(event.target.value)}
                                    type="text"
                                    placeholder="Enter Medicine Name"
                                    name="medicine_name"
                                    autoComplete="off"
                                    className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-indigo-500 sm:max-w-xs sm:text-sm"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="flex flex-shrink-0 justify-end px-4 py-4">
                          <button
                            type="button"
                            className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                            onClick={() => {
                              setmodalOpenFlage(false)
                            }
                          }
                          >
                            Cancel
                          </button>
                          <button
                            onClick={addMedicine}
                            className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                          >
                            Add
                          </button>
                        </div>
                      </form>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
      </div>
    </div>
  );
}
export default MedicineList;