export const GET_ALL_REFERRAL_BEGIN = "GET_ALL_REFERRAL_BEGIN"
export const GET_ALL_REFERRAL_SUCCESS = "GET_ALL_REFERRAL_SUCCESS"
export const GET_ALL_REFERRAL_ERROR = "GET_ALL_REFERRAL_ERROR"

export const ADD_REFERRAL_BEGIN = "ADD_REFERRAL_BEGIN"
export const ADD_REFERRAL_SUCCESS = "ADD_REFERRAL_SUCCESS"
export const ADD_REFERRAL_ERROR = "ADD_REFERRAL_ERROR"

export const GET_REFERRAL_BEGIN = "GET_REFERRAL_BEGIN"
export const GET_REFERRAL_SUCCESS = "GET_REFERRAL_SUCCESS"
export const GET_REFERRAL_ERROR = "GET_REFERRAL_ERROR"

export const UPDATE_REFERRAL_BEGIN = "UPDATE_REFERRAL_BEGIN"
export const UPDATE_REFERRAL_SUCCESS = "UPDATE_REFERRAL_SUCCESS"
export const UPDATE_REFERRAL_ERROR = "UPDATE_REFERRAL_ERROR"

export const DELETE_REFERRAL_BEGIN = "DELETE_REFERRAL_BEGIN"
export const DELETE_REFERRAL_SUCCESS = "DELETE_REFERRAL_SUCCESS"
export const DELETE_REFERRAL_ERROR = "DELETE_REFERRAL_ERROR"