import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import { DepartmentSchema } from "../../../schemas";
import { ArrowUturnLeftIcon } from "@heroicons/react/20/solid";
import { useDispatch, useSelector } from "react-redux";
import {
  addDepartment,
  updateDepartment,
  getDepartmentWithId,
} from "../../../redux/department/actions";
import { getAllLocations } from "../../../redux/location/actions";
import { FallingLinesLoader } from "../../../components/spinners/Spinner";
import Breadcrumb from "../../../components/Breadcrumb";

const AddDepartment = () => {
  const pages = [{ title: "Department", href: "/department-master" }];

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id: departmentId } = useParams();
  const { loading, DepartmentData } = useSelector((state) => state.department);
  const { list: locationList } = useSelector((state) => state.location);
  const initialValues = {
    name: "",
    department_location_master: [],
  };

  const [formState, setFormState] = useState(initialValues);
  const [inputList, setInputList] = useState([]);
  const handleAddClick = () => {
    setInputList([...inputList, { location_id: "", cost: "" }]);
  };
  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    console.log(e.target.name, e.target.value, "e.target");
    let list = [...inputList];
    list[index][name] = parseInt(value);
    console.log({ index, name });

    console.log({ list });
    setInputList(list);
    // console.log(list)
  };

  const handleRemoveClick = (index) => {
    const finalList = inputList.filter((ele, i) => i !== index);
    console.log(finalList, "finalList");
    // list.splice(index, 0);
    setInputList([...finalList]);
  };

  useEffect(() => {
    dispatch(getAllLocations());
    document.title = "New Horizon | Add-Department";
    if (departmentId) dispatch(getDepartmentWithId(departmentId));
  }, [departmentId]);

  // useEffect(() => {

  // }, [inputList])

  useEffect(() => {
    if (departmentId && DepartmentData) {
      const newFormState = {
        name: DepartmentData.name,
        department_location_master: DepartmentData.department_location_master,
      };
      setInputList(DepartmentData.department_location_master);
      setFormState(newFormState);
    }
  }, [departmentId, DepartmentData]);

  // useEffect(() => {
  //   console.log(inputList, "from use effect");
  //   // setInputList(inputList)
  // }, [inputList]);

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      enableReinitialize: true,
      initialValues: formState,
      validationSchema: DepartmentSchema,

      onSubmit: (values, action) => {
        const newlist = inputList.filter((item) => item.location_id !== null);
        // TODO: Optimize code here
        const body = {
          name: values.name,
          department_location_master: [...newlist],
        };

        if (departmentId) dispatch(updateDepartment(departmentId, body));
        else dispatch(addDepartment(body));
        action.resetForm();
        navigate("/department-master");
      },
    });
  // console.log(errors);

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <Breadcrumb pages={pages} />
      <div>
        <button
          type="button"
          onClick={() => navigate(-1)}
          className="inline-flex items-center rounded-md border mb-6 border-transparent bg-indigo-100 px-3 py-2 text-sm font-medium leading-4 text-indigo-700 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
        >
          <span className="w-5 mr-2">
            <ArrowUturnLeftIcon size={18} />
          </span>
          Back
        </button>
        <div className="sm:flex-auto">
          <h1 className="text-xl font-semibold text-gray-900">
            {departmentId ? "Update Department" : "Add Department"}
          </h1>
        </div>
      </div>
      {loading ? (
        <FallingLinesLoader />
      ) : (
        <form
          onSubmit={handleSubmit}
          className="space-y-8 divide-y divide-gray-200"
        >
          <div className="space-y-6 sm:space-y-5">
            <div className="space-y-6 sm:space-y-5 sm:pt-5">
              <div className="space-y-6 sm:space-y-5">
                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                  <label
                    htmlFor="name"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Name
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <input
                      type="text"
                      name="name"
                      id="name"
                      placeholder="Enter Department Name"
                      autoComplete="off"
                      value={values.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                    />
                    {errors.name && touched.name ? (
                      <p className="text-red-700">{errors.name}</p>
                    ) : null}
                  </div>
                </div>
                {inputList.map((x, index) => (
                  <div
                    key={index}
                    className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5"
                  >
                    <select
                      id="location_id"
                      name="location_id"
                      value={x.location_id}
                      onChange={(e) => handleInputChange(e, index)}
                      onBlur={handleBlur}
                      className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-indigo-500 sm:max-w-xs sm:text-sm"
                    >
                      <option value="all">Select a location</option>

                      {locationList.map((d, idx) => (
                        <option key={idx} value={d.id}>
                          {d.name}
                        </option>
                      ))}
                    </select>
                    <div className="mt-1 flex sm:col-span-2 sm:mt-0">
                      <input
                        type="number"
                        name="cost"
                        id="cost"
                        value={x.cost}
                        placeholder="Cost"
                        autoComplete="off"
                        onChange={(e) => handleInputChange(e, index)}
                        onBlur={handleBlur}
                        className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                      />
                      {/* {errors.cost && touched.cost ? (
                          <p className="text-red-700">{errors.cost}</p>
                        ) : null} */}

                      {/* {inputList.length > 1 && ( */}
                      <button
                        type="button"
                        className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-"
                        onClick={() => handleRemoveClick(index)}
                      >
                        Remove
                      </button>
                      {/* )} */}
                    </div>
                  </div>
                ))}
                {/* {inputList.length - 1 === index && ( */}
                <button
                  type="button"
                  className="w-1/3 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-"
                  onClick={handleAddClick}
                >
                  Add more
                </button>
                {/* )} */}
              </div>
            </div>
          </div>

          <div className="pt-5">
            <div className="flex justify-end">
              <button
                type="submit"
                className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              >
                Submit
              </button>
            </div>
          </div>
        </form>
      )}
    </div>
  );
};

export default AddDepartment;
