import {
  GET_ALL_MCHAT_BEGIN,
  GET_ALL_MCHAT_SUCCESS,
  GET_ALL_MCHAT_ERROR,
  ADD_MCHAT_BEGIN,
  ADD_MCHAT_SUCCESS,
  ADD_MCHAT_ERROR,
  GET_MCHAT_BEGIN,
  GET_MCHAT_SUCCESS,
  GET_MCHAT_ERROR,
  UPDATE_MCHAT_BEGIN,
  UPDATE_MCHAT_SUCCESS,
  UPDATE_MCHAT_ERROR,
  DELETE_MCHAT_BEGIN,
  DELETE_MCHAT_SUCCESS,
  DELETE_MCHAT_ERROR,
  ADD_MCHAT_FORM_BEGIN,
  ADD_MCHAT_FORM_SUCCESS,
  ADD_MCHAT_FORM_ERROR,
} from "./constant";
const initState = {
  list: [],
  loading: false,
  error: null,
};

const MchatReducer = (state = initState, action) => {
  const { type, data, error } = action;
  switch (type) {
    case GET_ALL_MCHAT_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case GET_ALL_MCHAT_SUCCESS:
      return {
        ...state,
        list: data,
        loading: false,
      };
    case GET_ALL_MCHAT_ERROR:
      return {
        ...state,
        error,
        loading: false,
      };
    case ADD_MCHAT_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case ADD_MCHAT_SUCCESS:
      return {
        ...state,
        loading: false,
        list: [...state.list, data],
      };
    case ADD_MCHAT_ERROR:
      return {
        ...state,
        error,
        loading: false,
      };
    case GET_MCHAT_BEGIN:
      return {
        ...state,
        loading: true,
        MCHATData: "",
      };
    case GET_MCHAT_SUCCESS:
      return {
        ...state,
        loading: false,
        MCHATData: data,
      };
    case GET_MCHAT_ERROR:
      return {
        ...state,
        error,
        loading: false,
      };
    case UPDATE_MCHAT_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_MCHAT_SUCCESS:
      return {
        ...state,
        loading: false,
        list: state.list.map((ele) => {
          if (ele.id === data.id) {
            return { ...data };
          } else return ele;
        }),
      };
    case UPDATE_MCHAT_ERROR:
      return {
        ...state,
        error,
        loading: false,
      };
    case DELETE_MCHAT_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case DELETE_MCHAT_SUCCESS:
      return {
        ...state,
        loading: false,
        list: state.list.filter((ele) => ele.id !== data.id),
      };
    case DELETE_MCHAT_ERROR:
      return {
        ...state,
        error,
        loading: false,
      };
      case ADD_MCHAT_FORM_BEGIN:
        return {
          ...state,
          loading: true,
        };
      case ADD_MCHAT_FORM_SUCCESS:
        return {
          ...state,
          loading: false,
          list: [...state.list, data],
        };
      case ADD_MCHAT_FORM_ERROR:
        return {
          ...state,
          error,
          loading: false,
        };
    default:
      return state;
  }
};

export default MchatReducer;
