import axios from "axios";
import { getHeaders } from "../auth/actions";
import { toast } from "react-hot-toast";
import {
  GET_ALL_FORMS_BEGIN,
  GET_ALL_FORMS_SUCCESS,
  GET_ALL_FORMS_ERROR,
  GET_ALL_FORMS_GROUP_BEGIN,
  GET_ALL_FORMS_GROUP_SUCCESS,
  GET_ALL_FORMS_GROUP_ERROR,
} from "./constants";

const API = process.env.REACT_APP_API_URL;

export const getAllForms = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_ALL_FORMS_BEGIN,
    });
    const headers = dispatch(getHeaders());
    const { data } = await axios.get(`${API}/api/form/form-master`, {
      headers,
    });
    console.log(data.data);

    dispatch({
      type: GET_ALL_FORMS_SUCCESS,
      data: data.data,
    });
  } catch (error) {
    const msg = error.response.data.message;
    toast.error(msg);
    dispatch({
      type: GET_ALL_FORMS_ERROR,
      error: msg,
    });
  }
};

export const getAllFormGroups = (id) => async (dispatch) => {
  try {
    dispatch({
      type: GET_ALL_FORMS_GROUP_BEGIN,
    });
    const headers = dispatch(getHeaders());
    const { data } = await axios.get(
      `${API}/api/form/form-master/form-group/${id}`,
      { headers }
    );
    console.log(data.data);

    dispatch({
      type: GET_ALL_FORMS_GROUP_SUCCESS,
      data: data.data,
    });
  } catch (error) {
    const msg = error.response.data.message;
    toast.error(msg);
    dispatch({
      type: GET_ALL_FORMS_GROUP_ERROR,
      error: msg,
    });
  }
};
