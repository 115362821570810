import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import {
  addPatientForm,
  getAllDocuments,
  getAllPatientForms,
  getPatientDetails,
} from "../../../redux/patientForm/actions";
import { useParams } from "react-router-dom";
import moment from "moment";
import { getPatientWithId } from "../../../redux/patient/actions";
export default function AddFormModal({ showModal, setShowModal }) {
  const dispatch = useDispatch();
  const { id: patientId } = useParams();
  const initialValues = {
    patient_id: patientId,
    form_type: "",
    form_date: "",
  };

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: initialValues,
      onSubmit: (values, action) => {
        //TODO: Optimize code here
        const body = {
          patient_id: parseInt(patientId),
          form_type: values.form_type,
          form_date: moment(values.form_date).format(),
        };
        dispatch(addPatientForm(body));
        // window.location.reload();
        action.resetForm();
        dispatch(getAllDocuments(patientId));
        dispatch(getAllPatientForms(patientId));
        dispatch(getPatientDetails(patientId));
        if (patientId) dispatch(getPatientWithId(patientId));
        setShowModal(false);
      },
    });
  // console.log(errors)

  return (
    <Transition.Root show={showModal} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setShowModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                <div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900"
                    >
                      Add Form
                    </Dialog.Title>
                    <div className="mt-2 w-full flex items-center justify-center">
                      <form className="w-[80%]" onSubmit={handleSubmit}>
                        <div className="mt-2 flex items-center justify-between">
                          <select
                            id="form_type"
                            name="form_type"
                            value={values.form_type}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-indigo-500 sm:max-w-xs sm:text-sm"
                          >
                            <option>Select a Form Type</option>
                            <option value={"OPD"}>OPD</option>
                            <option value={"FOLLOW_UP"}>Follow Up</option>
                            <option value={"MCHAT"}>M-Chat</option>
                          </select>
                        </div>
                        {/* //TODO: Send this to backend */}
                        <div className="mt-2 flex items-center justify-between">
                          <input
                            type="date"
                            id="form_date"
                            name="form_date"
                            value={values.form_date}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-indigo-500 sm:max-w-xs sm:text-sm"
                          />
                        </div>

                        <div>
                          <div className="mt-5 sm:mt-6">
                            <button
                              type="submit"
                              className="inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm"
                            >
                              Save
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
