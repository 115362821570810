import axios from "axios";
export const app_api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

app_api.interceptors.request.use((config) => {
  if (!!localStorage.getItem("newhorizon_user")) {
    const Token_key = JSON.parse(localStorage.getItem("newhorizon_user")).token;
    const token = Token_key;
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

app_api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default app_api;
