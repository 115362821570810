import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getHeaders } from "../../../redux/auth/actions";
import axios from "axios";
import toast from "react-hot-toast";
import { useDrag, useDrop } from "react-dnd";
const ItemType = "ITEM";
const DraggableItem = ({ item, index, moveItem }) => {
  const [, ref] = useDrag({
    type: ItemType,
    item: { index },
  });

  const [, drop] = useDrop({
    accept: ItemType,
    hover: (draggedItem) => {
      if (draggedItem.index !== index) {
        moveItem(draggedItem.index, index);
        draggedItem.index = index;
      }
    },
  });

  return (
    <div
      ref={(node) => ref(drop(node))}
      className="w-full border cursor-move border-gray-300 rounded-lg px-2 py-1"
    >
      <h2>{item.domain_module_title}</h2>
    </div>
  );
};

const api = process.env.REACT_APP_API_URL;
function ReArange() {
  const [moduleList, setModuleList] = useState({
    loading: false,
    data: [],
  });
  const [modele_id, setModule_id] = useState(0);
  const dispatch = useDispatch();
  const getAllModules = async () => {
    const headers = await dispatch(getHeaders());
    setModuleList((prev) => ({ ...prev, loading: true }));
    try {
      const res = await axios.get(`${api}/api/module-master`, {
        headers: headers,
      });
      const { data, status } = res;
      if (status === 200) {
        setModuleList((prev) => ({
          ...prev,
          data: data.data,
          loading: false,
        }));
      }
    } catch (error) {
      toast.error(error.message);
    }
  };
  const [actList, setActList] = useState({
    loading: false,
    data: [],
  });
  const [items, setItems] = useState(actList.data);

  const moveItem = (fromIndex, toIndex) => {
    const reorderedItems = [...items];
    const [movedItem] = reorderedItems.splice(fromIndex, 1);
    reorderedItems.splice(toIndex, 0, movedItem);
    setItems(reorderedItems);
  };
  const getAllAct = async (id) => {
    const headers = await dispatch(getHeaders());
    setActList((prev) => ({ ...prev, loading: true }));
    try {
      const res = await axios.get(`${api}/api/activity/module-filter/${id}`, {
        headers: headers,
      });
      const { data, status } = res;

      setActList((prev) => ({
        ...prev,
        data: data.data,
        loading: false,
      }));
    } catch (error) {
      toast.error(error.message);
    }
  };
  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const newItems = [...items];
    const [movedItem] = newItems.splice(result.source.index, 1);
    newItems.splice(result.destination.index, 0, movedItem);

    setItems(newItems);
  };
  function handler(id) {
    const removeElementFromArray = actList.data.filter(
      (item) => Number(item.id) !== Number(id)
    );
    setActList({ ...actList, data: removeElementFromArray });

    const selectedElement = actList.data.filter(
      (item) => Number(item.id) === Number(id)
    );
    setItems([...items, ...selectedElement]);
  }
  const handleSave = () => {
    const headers = dispatch(getHeaders());
    const body = {
      newOrder: [
        ...items.map((item) => item.id),
        ...actList.data.map((item) => item.id),
      ],
    };
    //TODO: API Call to save the new order
    axios
      .put(`${api}/api/activity/module-sequence/${modele_id}`, body.newOrder, {
        headers: headers,
      })
      .then((res) => {
        toast.success("Reorder Successfully");
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };
  useEffect(() => {
    getAllModules();
  }, []);

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center mb-5 flex items-center flex-row justify-between">
        <div className="sm:flex-auto w-[90%]">
          <h1 className="text-xl font-semibold text-gray-900">
            Re Arrange Domains
          </h1>
          <button
            onClick={() => handleSave()}
            className="px-2 py-1 bg-indigo-200 text-blue-800 hover:bg-indigo-300 hover:text-indigo-900 rounded-md duration-300"
          >
            save
          </button>
        </div>
        <select
          id="department_id"
          name="department_id"
          onChange={(e) => {
            setModule_id(e.target.value);
            getAllAct(e.target.value);
          }}
          className="block w-full max-w-xl rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-indigo-500  sm:text-sm"
        >
          <option value="">Select a Domain Title</option>
          {moduleList.data.map((d) => (
            <option value={d.id} key={d.id}>
              {d.name}
            </option>
          ))}
        </select>
      </div>
      <div className="w-full flex items-center justify-between">
        <div className="w-full px-4 flex flex-col gap-4">
          {actList.data.map((item, index) => (
            <p
              className="border cursor-pointer border-gray-200 px-2 py-3 rounded-md"
              key={item.id}
              onClick={() => handler(item.id)}
            >
              {item.domain_module_title}{" "}
            </p>
          ))}
        </div>
        {items.length && (
          <div className="w-full self-start px-4 flex flex-col justify-start gap-4">
            <h1>Priority List</h1>
            {items.length &&
              items.map((item, index) => (
                <p
                  className="border border-gray-200 px-2 py-3 rounded-md truncate"
                  key={item.id}
                >
                  {item.domain_module_title}{" "}
                </p>
              ))}
          </div>
        )}
      </div>
    </div>
  );
}

export default ReArange;
