import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import Breadcrumb from "../../../../components/Breadcrumb";
import { FallingLinesLoader } from "../../../../components/spinners/Spinner";
import Table from "../../../../components/tables/table";
import { toast } from "react-hot-toast";
import axios from "axios";
import { getHeaders } from "../../../../redux/auth/actions";
import { useDispatch } from "react-redux";
import { MIS_session_columns } from "../../../../components/tables/tableheader";
import moment from "moment";
import { getAllLocations } from "../../../../redux/location/actions";
import { useSelector } from "react-redux";
import { getAllDoctors } from "../../../../redux/doctor/actions";

const api = process.env.REACT_APP_API_URL;
function MisConversion() {
  const pages = [{ title: "MIS Conversion " }];
  const [loading, setLoading] = useState(false);
  //   const [modal, setModal] = useState(initialModalState);
  const [quary, setQuary] = useState({
    start_date: moment().subtract(30, "days").format("YYYY-MM-DD"),
    end_date: moment().format("YYYY-MM-DD"),
    doctor_id: "",
  });

  const dispatch = useDispatch();
  const { loading: docLoading, list: docList } = useSelector(
    (state) => state.doctor
  );
  useEffect(() => {
    dispatch(getAllDoctors());
    getAllConversions();
    document.title = "New Horizon | MIS Conversion";
  }, []);
  useEffect(() => {
    getAllConversions();
  }, [quary.start_date, quary.end_date, quary.doctor_id]);

  const [appointmentList, setAppointmentList] = useState({
    loading: false,
    data: [],
    FilterData: [],
  });

  const getAllConversions = async () => {
    if (quary.doctor_id === "") {
      delete quary.locationId;
    }
    const headers = await dispatch(getHeaders());
    setAppointmentList((prev) => ({ ...prev, loading: true }));
    try {
      const res = await axios.post(
        `${api}/api/reporting/daily-mis-conversion`,
        quary,
        {
          headers: headers,
        }
      );
      const { data, status } = res;
      if (data) {
        setAppointmentList((prev) => ({
          ...prev,
          data: data.data,
          FilterData: data.data,
          loading: false,
        }));
      }
      console.log(res);
    } catch (error) {
      toast.error(error.message);
    }
  };
  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center mb-5">
        <div className="sm:flex-auto">
          <Breadcrumb pages={pages} />
          <h1 className="text-xl font-semibold text-gray-900">
            Daily MIS Conversion Report
          </h1>
        </div>
        <div className="sm:grid sm:grid-cols-2 w-max sm:items-start sm:gap-4  sm:border-gray-200 sm:pt-5">
          <div
            className={`mt-1 flex items-center justify-right  grid-cols-3
           sm:col-span-3 sm:mt-0`}
          >
            <label className="">Start: </label>
            <input
              type="date"
              name="start_date"
              value={quary.start_date}
              onChange={(e) => {
                setQuary((prev) => ({
                  ...prev,
                  start_date: moment(e.target.value).format("YYYY-MM-DD"),
                }));
              }}
              placeholder="select date"
              className="mr-2 ml-2 block w-[90%] max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-indigo-500 sm:max-w-xs sm:text-sm"
            />
            <label className="">End: </label>
            <input
              type="date"
              name="end"
              value={quary.end_date}
              onChange={(e) => {
                setQuary((prev) => ({
                  ...prev,
                  end_date: moment(e.target.value).format("YYYY-MM-DD"),
                }));
              }}
              placeholder="select date"
              className="mr-2 ml-2 block w-[90%] max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-indigo-500 sm:max-w-xs sm:text-sm"
            />
            <select
              name="locationId"
              onChange={(e) => {
                setQuary((prev) => ({
                  ...prev,
                  doctor_id: Number(e.target.value),
                }));
              }}
              className="mr-2 ml-2 block w-[90%] max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-indigo-500 sm:max-w-xs sm:text-sm"
            >
              <option value="">Select Doctor</option>
              {docList.map((item) => (
                <option key={item.id} value={item.id}>
                  DR. {item.first_name} {item.last_name}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
      {appointmentList.loading ? (
        <FallingLinesLoader />
      ) : (
        <Table
          columns={MIS_session_columns()}
          data={appointmentList.FilterData}
        />
      )}
    </div>
  );
}
export default MisConversion;
