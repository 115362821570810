import { ArrowUturnLeftIcon } from '@heroicons/react/20/solid';
import { useFormik } from 'formik';
import React from 'react'
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Breadcrumb from '../../../components/Breadcrumb'

const AddEnrolment = () => {
  const navigate = useNavigate()
  const initialValues = {
    patient_id: "",
    recommendation_id: "",
  };
  const [formState, setFormState] = useState(initialValues);
  const pages = [{ title: "Enrolment", href: "/enrolment" }];
  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
  useFormik({
    enableReinitialize: true,
    initialValues: formState,
    onSubmit: (values, action) => {
      // TODO: Optimize code here
    },
  });
  return (
    <div className="px-4 sm:px-6 lg:px-8">
    <Breadcrumb pages={pages} />
    <div>
      <button
        type="button"
          onClick={() => navigate(-1)}
        className="inline-flex items-center rounded-md border mb-6 border-transparent bg-indigo-100 px-3 py-2 text-sm font-medium leading-4 text-indigo-700 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
      >
        <span className="w-5 mr-2">
          <ArrowUturnLeftIcon size={18} />
        </span>
        Back
      </button>
      <div className="sm:flex-auto">
        <h1 className="text-xl font-semibold text-gray-900">
           Add Enrolment
        </h1>
      </div>
    </div>
    {/* {loading ? (
      <FallingLinesLoader />
    ) : ( */}
    <form
      onSubmit={handleSubmit}
      className="space-y-8 divide-y divide-gray-200"
    >
      <div className="space-y-6 sm:space-y-5">
        <div className="space-y-6 sm:space-y-5 sm:pt-5">
          <div className="space-y-6 sm:space-y-5">
          <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                htmlFor="points"
                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Patient
              </label>
              <div className="mt-1 sm:col-span-2 sm:mt-0">
                <select
                  id="points"
                  name="points"
                  autoComplete="points"
                  className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                >
                  <option>Select a Patient</option>
                  <option value="YES">Yes</option>
                  <option value="NO">No</option>
                </select>
                {errors.points && touched.points ? (
                  <p className="text-red-700">{errors.points}</p>
                ) : null}
              </div>
            </div>

            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                htmlFor="points"
                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Recommendation
              </label>
              <div className="mt-1 sm:col-span-2 sm:mt-0">
                <select
                  id="points"
                  name="points"
                  autoComplete="points"
                  className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                >
                  <option>Select a Recommendation</option>
                  <option value="YES">Yes</option>
                  <option value="NO">No</option>
                </select>
                {errors.points && touched.points ? (
                  <p className="text-red-700">{errors.points}</p>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="pt-5">
        <div className="flex justify-end">
          <button
            type="submit"
            className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          >
            Create Enrolment
          </button>
        </div>
      </div>
    </form>
    {/* // )} */}
  </div>
  )
}

export default AddEnrolment
