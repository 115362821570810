export const GET_ALL_LOCATION_BEGIN = "GET_ALL_LOCATION_BEGIN"
export const GET_ALL_LOCATION_SUCCESS = "GET_ALL_LOCATION_SUCCESS"
export const GET_ALL_LOCATION_ERROR = "GET_ALL_LOCATION_ERROR"

export const ADD_LOCATION_BEGIN = "ADD_LOCATION_BEGIN"
export const ADD_LOCATION_SUCCESS = "ADD_LOCATION_SUCCESS"
export const ADD_LOCATION_ERROR = "ADD_LOCATION_ERROR"

export const GET_LOCATION_BEGIN = "GET_LOCATION_BEGIN"
export const GET_LOCATION_SUCCESS = "GET_LOCATION_SUCCESS"
export const GET_LOCATION_ERROR = "GET_LOCATION_ERROR"

export const UPDATE_LOCATION_BEGIN = "UPDATE_LOCATION_BEGIN"
export const UPDATE_LOCATION_SUCCESS = "UPDATE_LOCATION_SUCCESS"
export const UPDATE_LOCATION_ERROR = "UPDATE_LOCATION_ERROR"

export const DELETE_LOCATION_BEGIN = "DELETE_LOCATION_BEGIN"
export const DELETE_LOCATION_SUCCESS = "DELETE_LOCATION_SUCCESS"
export const DELETE_LOCATION_ERROR = "DELETE_LOCATION_ERROR"