export const GET_ALL_DEPARTMENT_FILTER_BEGIN =
  "GET_ALL_DEPARTMENT_FILTER_BEGIN";
export const GET_ALL_DEPARTMENT_FILTER_SUCCESS =
  "GET_ALL_DEPARTMENT_FILTER_SUCCESS";
export const GET_ALL_DEPARTMENT_FILTER_ERROR =
  "GET_ALL_DEPARTMENT_FILTER_ERROR";

export const RECOMMEND_PROGRAM_BEGIN = "RECOMMEND_PROGRAM_BEGIN";
export const RECOMMEND_PROGRAM_SUCCESS = "RECOMMEND_PROGRAM_SUCCESS";
export const RECOMMEND_PROGRAM_ERROR = "RECOMMEND_PROGRAM_ERROR";
