import React from 'react'
import { useNavigate } from 'react-router-dom';
import Breadcrumb from "../../../components/Breadcrumb";
import { ArrowUturnLeftIcon } from '@heroicons/react/24/solid';

function AddInvoice() {
    const pages = [{ title: "Invoice", href: "/add-invoice" }];
    const navigate = useNavigate();

    return (
        <div>
            <Breadcrumb pages={pages} />

            <div className="mt-5 md:col-span-2 md:mt-0">
                <div>
                    <button
                        type="button"
                        onClick={() => navigate(-1)}
                        className="inline-flex items-center rounded-md border mb-6 border-transparent bg-indigo-100 px-3 py-2 text-sm font-medium leading-4 text-indigo-700 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    >
                        <span className="w-5 mr-2"><ArrowUturnLeftIcon size={18} /></span> Back
                    </button>
                </div>
                <div className="sm:flex-auto pb-6">
                    <h1 className="text-xl font-semibold text-gray-900">Invoice</h1>
                    <p className="mt-2 text-sm text-gray-700">
                        Create a invoice of patient in your account including their name,
                        title, email and role.
                    </p>
                </div>
                <form action="#" method="POST">
                    <div className="overflow-hidden shadow sm:rounded-md">
                        <div className="bg-white px-4 py-5 sm:p-6">
                            <div className='flex pb-6 items-center border-b'>
                                <div className="mt-1 basis-2/6">
                                    <label htmlFor="invoice" className="block text-sm font-medium text-gray-700">
                                        Address
                                    </label>
                                    <textarea
                                        id="address"
                                        name="address"
                                        rows={5}
                                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                        defaultValue={''}
                                    />
                                </div>
                                <div className='ml-auto'>
                                    <div>
                                        <label htmlFor="invoice" className="block text-sm font-medium text-gray-700">
                                            Invoice
                                        </label>
                                        <input
                                            type="text"
                                            name="invoice"
                                            id="invoice"
                                            autoComplete="invoice"
                                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                        />
                                    </div>
                                    <div className='mt-1'>
                                        <label htmlFor="invoice-date" className="block text-sm font-medium text-gray-700">
                                            Date
                                        </label>
                                        <input
                                            type="date"
                                            name="invoice-date"
                                            id="invoice-date"
                                            autoComplete="invoice-date"
                                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="grid grid-cols-6 gap-6 mt-5 border-b pb-7">
                                <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                                    <label htmlFor="patient-name" className="block text-sm font-medium text-gray-700">
                                        Patient Name
                                    </label>
                                    <input
                                        type="text"
                                        name="patient-name"
                                        id="patient-name"
                                        autoComplete="patient-name"
                                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                    />
                                </div>

                                <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                                    <label htmlFor="doctor-name" className="block text-sm font-medium text-gray-700">
                                        Doctor Name
                                    </label>
                                    <input
                                        type="text"
                                        name="doctor-name"
                                        id="doctor-name"
                                        autoComplete="doctor-name"
                                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                    />
                                </div>

                                <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                                    <label htmlFor="phone-number" className="block text-sm font-medium text-gray-700">
                                        Mobile No.
                                    </label>
                                    <input
                                        type="number"
                                        name="phone-number"
                                        id="phone-number"
                                        autoComplete="phone-number"
                                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col">
                            <div className="overflow-x-auto">
                                <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                                    <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                                        <table className="min-w-full divide-y divide-gray-300">
                                            <thead className="bg-gray-50">
                                                <tr>
                                                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                                        SN.
                                                    </th>
                                                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                                        Item
                                                    </th>
                                                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                        Quantity
                                                    </th>
                                                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                        Price
                                                    </th>
                                                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                        Amount
                                                    </th>
                                                    <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                                                        <button
                                                            type="button"
                                                            className="inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                                        >
                                                            Add
                                                        </button>
                                                        <span className="sr-only">Edit</span>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody className="divide-y divide-gray-200 bg-white">
                                                <tr>
                                                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">1</td>
                                                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                                        <input
                                                            type="text"
                                                            name="item-name"
                                                            id="item-name"
                                                            autoComplete="item-name"
                                                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                                        />
                                                    </td>
                                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                        <input
                                                            type="number"
                                                            name="quantity"
                                                            id="quantity"
                                                            autoComplete="quantity"
                                                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                                        />
                                                    </td>
                                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                        <input
                                                            type="number"
                                                            name="item-price"
                                                            id="item-price"
                                                            autoComplete="item-price"
                                                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                                        />
                                                    </td>
                                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                        <input
                                                            type="number"
                                                            name="amount"
                                                            id="amount"
                                                            autoComplete="amount"
                                                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                                        />
                                                    </td>
                                                    <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                                        <a href="#" className="text-indigo-600 hover:text-indigo-900">
                                                            <button
                                                                type="button"
                                                                className="inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                                            >
                                                                Remove
                                                            </button>
                                                            <span className="sr-only"></span>
                                                        </a>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td>
                                                        <div className='border-b pb-1 flex-col'>
                                                            <div className='text-md text-gray-800 flex'>
                                                                Subtotal : <span className='text-sm text-gray-500 ml-auto'>0</span>
                                                            </div>
                                                            <div className='text-md text-gray-800 flex'>
                                                                Discount : <span className='text-sm text-gray-500 ml-auto'>0</span>
                                                            </div>
                                                            <div className='text-md text-gray-800 flex'>
                                                                GST : <span className='text-sm font-bold text-gray-500 ml-auto'>0</span>
                                                            </div>
                                                        </div>
                                                        <div className='text-lg text-black-800 flex'>
                                                            Total Amount : <span className='text-md text-black-500 ml-auto'>0</span>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="bg-gray-50 px-4 py-3 text-right sm:px-6">
                            <button
                                type="submit"
                                className="inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                            >
                                Save
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default AddInvoice