import React, { useEffect } from 'react'
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import Breadcrumb from '../../../components/Breadcrumb';
import { FallingLinesLoader } from '../../../components/spinners/Spinner';
import Table from '../../../components/tables/table';
import { show_availability_columns } from '../../../components/tables/tableheader';
import { showAvailabilityId } from "../../../redux/therapist/ShowAvailability/actions"

const data = [
    {
        date: "2021-05-01",
        from_time: "10:00",
        to_time: "11:00",
        status: true,
    },
    
]
const TherepistShowAvailability = () => {
    const { id: therpistId } = useParams();
    const { loading, list: showAvailabilityList } = useSelector((state) => state.showthavailability);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(showAvailabilityId(therpistId));

      }, [therpistId]);
  return (
    <div className="px-4 sm:px-6 lg:px-8">
   
    <div className="sm:flex sm:items-center mb-5">
      {/* <div className="sm:flex-auto">
        <h1 className="text-xl font-semibold text-gray-900">Locations</h1>
      </div> */}
      <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
       
      </div>
    </div>
      {loading ? (
        <FallingLinesLoader />
      ) : (
        <Table
        columns={ show_availability_columns()}
        data={showAvailabilityList}
        // onEditClose={cleanModalData}
      />
      )}
    </div>
  )
}

export default TherepistShowAvailability
