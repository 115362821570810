import {
  GET_ALL_LOCATION_BEGIN,
  GET_ALL_LOCATION_SUCCESS,
  GET_ALL_LOCATION_ERROR,
  ADD_LOCATION_BEGIN,
  ADD_LOCATION_SUCCESS,
  ADD_LOCATION_ERROR,
  GET_LOCATION_BEGIN,
  GET_LOCATION_ERROR,
  GET_LOCATION_SUCCESS,
  UPDATE_LOCATION_BEGIN,
  UPDATE_LOCATION_SUCCESS,
  UPDATE_LOCATION_ERROR,
  DELETE_LOCATION_BEGIN,
  DELETE_LOCATION_SUCCESS,
  DELETE_LOCATION_ERROR,
} from "./constants";

const initState = {
  list: [],
  loading: false,
  error: null,
};

const LocationReducer = (state = initState, action) => {
  const { type, data, error } = action;
  switch (type) {
    case GET_ALL_LOCATION_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case GET_ALL_LOCATION_SUCCESS:
      return {
        ...state,
        list: data,
        loading: false,
      };
    case GET_ALL_LOCATION_ERROR:
      return {
        ...state,
        error,
        loading: false,
      };
    case ADD_LOCATION_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case ADD_LOCATION_SUCCESS:
      return {
        ...state,
        loading: false,
        list: [...state.list, data],
      };
    case ADD_LOCATION_ERROR:
      return {
        ...state,
        error,
        loading: false,
      };
    case GET_LOCATION_BEGIN:
      return {
        ...state,
        loading: true,
        locationData: "",
      };
    case GET_LOCATION_SUCCESS:
      return {
        ...state,
        loading: false,
        locationData: data,
      };
    case GET_LOCATION_ERROR:
      return {
        ...state,
        error,
        loading: false,
      };
    case UPDATE_LOCATION_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_LOCATION_SUCCESS:
      return {
        ...state,
        loading: false,
        list: state.list.map((ele) => {
          if (ele.id === data.id) {
            return { ...data };
          } else return ele;
        }),
      };
    case UPDATE_LOCATION_ERROR:
      return {
        ...state,
        error,
        loading: false,
      };
    case DELETE_LOCATION_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case DELETE_LOCATION_SUCCESS:
      return {
        ...state,
        loading: false,
        list: state.list.filter((ele) => ele.id !== data.id),
      };
    case DELETE_LOCATION_ERROR:
      return {
        ...state,
        error,
        loading: false,
      };
    default:
      return state;
  }
};

export default LocationReducer;
