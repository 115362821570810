import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import { locationSchema } from "../../../schemas";
import { ArrowUturnLeftIcon } from "@heroicons/react/20/solid";
import { useDispatch, useSelector } from "react-redux";
import {
  addMedicineName,
  getMedicineNameWithId,
  updateMedicineName,
} from "../../../redux/MedicineNameMaster/actions";
import { FallingLinesLoader } from "../../../components/spinners/Spinner";
import Breadcrumb from "../../../components/Breadcrumb";

const AddmedicineName = () => {
  const pages = [{ title: "Medicine Name", href: "/medicine-name-master" }];

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id: nameId } = useParams();
  const { loading, medicineNameData } = useSelector(
    (state) => state.medicineName
  );

  const initialValues = {
    name: "",
  };

  const [formState, setFormState] = useState(initialValues);

  useEffect(() => {
    document.title = "New Horizon | Add-Location";
    if (nameId) dispatch(getMedicineNameWithId(nameId));
  }, [nameId]);

  useEffect(() => {
    if (nameId && medicineNameData) {
      const newFormState = {
        name: medicineNameData.name,
      };
      setFormState(newFormState);
    }
  }, [nameId, medicineNameData]);

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      enableReinitialize: true,
      initialValues: formState,
      onSubmit: (values, action) => {
        // TODO: Optimize code here
        const body = {
          name: values.name,
        };
        if (nameId) dispatch(updateMedicineName(nameId, body));
        else dispatch(addMedicineName(body));
        action.resetForm();
        navigate("/medicine-name-master");
      },
    });
  // console.log(errors);
  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <Breadcrumb pages={pages} />
      <div>
        <button
          type="button"
          onClick={() => navigate(-1)}
          className="inline-flex items-center rounded-md border mb-6 border-transparent bg-indigo-100 px-3 py-2 text-sm font-medium leading-4 text-indigo-700 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
        >
          <span className="w-5 mr-2">
            <ArrowUturnLeftIcon size={18} />
          </span>{" "}
          Back
        </button>
        <div className="sm:flex-auto">
          <h1 className="text-xl font-semibold text-gray-900">
            {nameId ? "Update Medicine Name" : "Add Medicine Name"}
          </h1>
        </div>
      </div>
      {loading ? (
        <FallingLinesLoader />
      ) : (
        <form
          onSubmit={handleSubmit}
          className="space-y-8 divide-y divide-gray-200"
        >
          <div className="space-y-6 sm:space-y-5">
            <div className="space-y-6 sm:space-y-5 sm:pt-5">
              <div className="space-y-6 sm:space-y-5">
                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                  <label
                    htmlFor="strength"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Medicine Name
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <input
                      type="text"
                      name="name"
                      id="name"
                      autoComplete="off"
                      onChange={handleChange}
                      placeholder="Medicine Name"
                      onBlur={handleBlur}
                      value={values.name}
                      className="block w-1/2 min-w-0 flex-1 rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    />
                    {errors.name && touched.name ? (
                      <p className="text-red-700">{errors.strength}</p>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="pt-5">
            <div className="flex justify-end">
              <button
                type="submit"
                className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              >
                Submit
              </button>
            </div>
          </div>
        </form>
      )}
    </div>
  );
};

export default AddmedicineName;
