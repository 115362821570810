import React, { useEffect, useState } from 'react';
import Table from '../../../../components/tables/table';
import { Patient_Report_columns } from '../../../../components/tables/tableheader';
import { useDispatch, useSelector } from 'react-redux';
import { FallingLinesLoader } from '../../../../components/spinners/Spinner';
import { getOpdAppointmentWithId } from '../../../../redux/opdreport/action';
import { getAllLocations } from '../../../../redux/location/actions';
import moment from 'moment';
import { async } from 'q';
import axios from 'axios';
import { toast } from 'react-toast';
import PatientLineChart from './patientLine';
import Breadcrumb from '../../../../components/Breadcrumb';
import { getHeaders } from '../../../../redux/auth/actions';

const PatientReport = () => {
  const [selected, setselected] = useState('all');
  const dispatch = useDispatch();
  const { loading, list: location } = useSelector((state) => state.location);
  const { list: opdReport } = useSelector((state) => state.location);
  const [data, setData] = useState(opdReport);
  const [datefilter,set_date_filter]=useState({from:moment().subtract(3, 'month').format('YYYY-MM-DD'),to:moment().add(1,'year').format('YYYY-MM-DD'),location_id:null})

  let fromDate, toDate

  const { user } = useSelector((state) => state.auth);
  const API = process.env.REACT_APP_API_URL;

  useEffect(() => {
    document.title = 'New Horizon | OPD report';
    dispatch(getAllLocations());
    console.log(location);
  }, [selected]);

  const get_report_data = async (from=moment().subtract(3, 'month').format('YYYY-MM-DD'),to=moment().add(1,'year').format('YYYY-MM-DD'),location_id=null) => {
    const headers = dispatch(getHeaders());
      const response = await axios.post(`${API}/api/reporting/opd-report`,{from,to,location_id}, {
        headers,
      });
      //patient data
      const { data } = response;
      let apicall=data.data
      console.log(apicall);
   let tabdata = [];
    Object.keys(apicall).forEach((element) => {
      tabdata.push({
        date: element,
        no_of_children: apicall[element].no_of_children,
        dropout: apicall[element].dropout,
        no_of_child_uk: apicall[element].no_of_child_uk,
        potential: apicall[element].potential,
      });
    });
    setData(tabdata);
  };
  useEffect(() => {
    get_report_data()
  }, []);
  useEffect(()=>{ 
    console.log(datefilter,datefilter.from && datefilter.to || datefilter.location_id);
    if(datefilter.from && datefilter.to || datefilter.location_id){
    get_report_data(datefilter.from,datefilter.to,datefilter.location_id);
   }},[datefilter])
  
  const handleDateFilter = async (date) => {
    const headers = dispatch(getHeaders());
    const { data } = await axios.post(
      `${API}/api/therapist/schedule/availibility/exception/`,
      {
        "from":fromDate.toString(),
        "to":toDate.toString(),
      },
      { headers }
    );
  };

  const renderChart = () => {
    if (data != '') {
      return <PatientLineChart cdata={data} selected = {selected} />;
    } else {
      return '';
    }
  };

  const pages = [{ title: "Patients Report", href: "/patients-report" }];

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center mb-5">
        <div className="sm:flex-auto">
          <Breadcrumb pages={pages}/>
          <h1 className="text-xl font-semibold text-gray-900">
            Patients Report
          </h1>
        </div>
        <div className="sm:grid sm:grid-cols-2    w-max sm:items-start sm:gap-4  sm:border-gray-200 sm:pt-5">
          <div
            className={`mt-1 flex items-center justify-right ${
              user.role !== 'DOCTOR' && 'grid-cols-2'
            } sm:col-span-2 sm:mt-0`}
          >
            <label className=''>From: </label>
            <input
              type="date"
              name="fromDate"
              // value={(e) => setDate(e.target.value)}
              onChange={(e) => {
                set_date_filter({...datefilter,from:e.target.value}); 
            }}
              placeholder="select date"
              className="mr-2 ml-2 block w-[90%] max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-indigo-500 sm:max-w-xs sm:text-sm"
            />
            <label className=''>To: </label>
            <input
              type="date"
              name="toDate"
              // value={(e) => setDate(e.target.value)}
              onChange={(e) => {
                set_date_filter({...datefilter,to:e.target.value}); 
              }}
              placeholder="select date"
              className="mr-2 ml-2 block w-[90%] max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-indigo-500 sm:max-w-xs sm:text-sm"
            />
            {user.role === 'DOCTOR' ? null : (
              <select
                id="type"
                name="type"
                defaultValue={selected}
                onChange={(e) => {
                  set_date_filter({...datefilter,location_id:parseInt(e.target.value)}); 
                }}
                // value={(e)=>setselected(e.target.value)}
                className="block ml-1 w-full max-w-lg rounded-md border-[1px] border-gray-300 shadow-sm focus:border-[1px] focus:border-indigo-500 sm:max-w-xs sm:text-sm"
              >
                <option value="all">Select a Location</option>

                {location.map((d, idx) => (
                  <option key={idx} value={d.id}>
                    {d.name}
                  </option>
                ))}
              </select>
            )}
            {/* {errors.type && touched.type ? (
                    <p className="text-red-700">{errors.type}</p>
                  ) : null} */}
          </div>
        </div>
      </div>
      {loading ? (
        <FallingLinesLoader />
      ) : (
        <>
          <Table columns={Patient_Report_columns()} data={data} />
          {/* Spacer */}
          <div className='mt-10'></div>
          {renderChart()}
        </>
      )}
    </div>
  );
};

export default PatientReport;
