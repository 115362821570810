import React, { useEffect, useState } from "react";
import Table from "../../../../components/tables/table";
import { New_Patient_Report_columns } from "../../../../components/tables/tableheader";
import { useDispatch, useSelector } from "react-redux";
import { FallingLinesLoader } from "../../../../components/spinners/Spinner";
import { getOpdAppointmentWithId } from "../../../../redux/opdreport/action";
import { getAllLocations } from "../../../../redux/location/actions";
import moment from "moment";
import { async } from "q";
import axios from "axios";
import { toast } from "react-hot-toast";
import Breadcrumb from "../../../../components/Breadcrumb";
import { getHeaders } from "../../../../redux/auth/actions";

const NewNewPatientReport = () => {
  const [selected, setselected] = useState("all");
  const dispatch = useDispatch();
  const { loading, list: location } = useSelector((state) => state.location);
  const { list: opdReport } = useSelector((state) => state.location);
  const [data, setData] = useState({
    original: [],
    filtered: [],
  });
  const [datefilter, set_date_filter] = useState({
    from: moment().subtract(1, "month").format("YYYY-MM-DD"),
    to: moment().format("YYYY-MM-DD"),
    location_id: null,
  });

  const fromDate = moment(datefilter.from).format("YYYY-MM-DD");
  const toDate = moment(datefilter.to).format("YYYY-MM-DD");

  const { user } = useSelector((state) => state.auth);
  const API = process.env.REACT_APP_API_URL;

  useEffect(() => {
    document.title = "New Horizon | OPD report";
    dispatch(getAllLocations());
  }, [selected]);

  const get_report_data = async (
    start_date = datefilter.from,
    end_date = datefilter.to,
    location_id = null
  ) => {
    const headers = dispatch(getHeaders());
    const response = await axios.post(
      `${API}/api/reporting/patient-report`,
      { start_date, end_date },
      {
        headers,
      }
    );
    //patient data
    const { data } = response;
    let apicall = data.data;

    setData((prev) => ({ ...prev, original: apicall, filtered: apicall }));
  };
  useEffect(() => {
    get_report_data();
  }, []);
  useEffect(() => {
    if ((datefilter.from && datefilter.to) || datefilter.location_id) {
      get_report_data(datefilter.from, datefilter.to, datefilter.location_id);
    }
  }, [datefilter]);

  const handleDateFilter = async (date) => {
    const headers = dispatch(getHeaders());
    const { data } = await axios.post(
      `${API}/api/reporting/patient-report`,
      {
        start_date: fromDate.toString(),
        end_date: toDate.toString(),
      },
      { headers }
    );
  };

  const pages = [{ title: "Patients Report", href: "/patients-report" }];

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center mb-5">
        <div className="sm:flex-auto">
          <Breadcrumb pages={pages} />
          <h1 className="text-xl font-semibold text-gray-900">
            Patients Report
          </h1>
        </div>
        <div className="sm:grid sm:grid-cols-2    w-max sm:items-start sm:gap-4  sm:border-gray-200 sm:pt-5">
          <div
            className={`mt-1 flex items-center justify-right ${
              user.role !== "DOCTOR" && "grid-cols-2"
            } sm:col-span-2 sm:mt-0`}
          >
            <label className="">From: </label>
            <input
              type="date"
              name="fromDate"
              value={fromDate}
              onChange={(e) => {
                set_date_filter({ ...datefilter, from: e.target.value });
              }}
              placeholder="select date"
              className="mr-2 ml-2 block w-[90%] max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-indigo-500 sm:max-w-xs sm:text-sm"
            />
            <label className="">To: </label>
            <input
              type="date"
              name="toDate"
              value={toDate}
              onChange={(e) => {
                set_date_filter({ ...datefilter, to: e.target.value });
              }}
              placeholder="select date"
              className="mr-2 ml-2 block w-[90%] max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-indigo-500 sm:max-w-xs sm:text-sm"
            />
            <select
              id="type"
              name="type"
              defaultValue={selected}
              onChange={(e) => {
                setData((prev) => ({
                  ...prev,
                  filtered:
                    e.target.value !== "all"
                      ? prev.original.filter(
                          (item) => item.location_id == Number(e.target.value)
                        )
                      : prev.original,
                }));
              }}
              // value={(e)=>setselected(e.target.value)}
              className="block ml-1 w-full max-w-lg rounded-md border-[1px] border-gray-300 shadow-sm focus:border-[1px] focus:border-indigo-500 sm:max-w-xs sm:text-sm"
            >
              <option value={"all"}>Select a Location</option>

              {location.map((d, idx) => (
                <option key={idx} value={d.id}>
                  {d.name}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
      {loading ? (
        <FallingLinesLoader />
      ) : (
        <>
          <Table columns={New_Patient_Report_columns()} data={data.filtered} />
          {/* Spacer */}
          <div className="mt-10"></div>
        </>
      )}
    </div>
  );
};

export default NewNewPatientReport;
