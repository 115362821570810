import {
  GET_ALL_PATIENT_RECOMMANDATION_BEGIN,
  GET_ALL_PATIENT_RECOMMANDATION_SUCCESS,
  GET_ALL_PATIENT_RECOMMANDATION_ERROR,
  GET__PATIENT_RECOMMANDATION_WITH_ID_BEGIN,
  GET__PATIENT_RECOMMANDATION_WITH_ID_SUCCESS,
  GET__PATIENT_RECOMMANDATION_WITH_ID_ERROR,
  CLEAR_PATIENT_RECOMMENDATION,
  GET_PATIENT_RECOMMENDATION_DEPARTMENTS_BEGIN,
  GET_PATIENT_RECOMMENDATION_DEPARTMENTS_SUCCESS,
  GET_PATIENT_RECOMMENDATION_DEPARTMENTS_ERROR,
  CLEAR_PATIENT_RECOMMENDATION_DEPARTMENTS,
  CHANGE_ACTIVE_DEPARTMENT,
  // TOGGEL_FOLLOW_UP_BEGIN,
  // TOGGEL_FOLLOW_UP_SUCCESS,
  // TOGGEL_FOLLOW_UP_ERROR,
} from "./constants";

const initState = {
  list: [],
  departments: [],
  dTabs: [],
  loading: false,
  error: null,
  dLoading: false,
  dError: null,
};

const patientRecommandationReducer = (state = initState, action) => {
  const { type, data, error } = action;
  switch (type) {
    case GET_ALL_PATIENT_RECOMMANDATION_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case GET_ALL_PATIENT_RECOMMANDATION_SUCCESS:
      return {
        ...state,
        list: data,
        loading: false,
      };
    case GET_ALL_PATIENT_RECOMMANDATION_ERROR:
      return {
        ...state,
        error,
        loading: false,
      };
    case GET__PATIENT_RECOMMANDATION_WITH_ID_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case GET__PATIENT_RECOMMANDATION_WITH_ID_SUCCESS:
      return {
        ...state,
        list: data,
        loading: false,
      };
    case GET__PATIENT_RECOMMANDATION_WITH_ID_ERROR:
      return {
        ...state,
        error,
        loading: false,
      };
    case CLEAR_PATIENT_RECOMMENDATION:
      return { ...state, error: null, loading: false, list: [] };
    case GET_PATIENT_RECOMMENDATION_DEPARTMENTS_BEGIN:
      return { ...state, dLoading: false, dError: null };
    case GET_PATIENT_RECOMMENDATION_DEPARTMENTS_SUCCESS:
      return {
        ...state,
        dLoading: false,
        dError: null,
        departments: data,
        dTabs: data.map((d, idx) => ({
          name: d.department.name,
          indicator: parseInt(d.department.id),
          current: idx === 0,
        })),
      };
    case GET_PATIENT_RECOMMENDATION_DEPARTMENTS_ERROR:
      return {
        ...state,
        dLoading: false,
        dError: error,
      };
    case CLEAR_PATIENT_RECOMMENDATION_DEPARTMENTS:
      return {
        ...state,
        dLoading: false,
        dError: null,
        departments: [],
        dTabs: [],
      };
    case CHANGE_ACTIVE_DEPARTMENT:
      return {
        ...state,
        dTabs: state.dTabs.map((t) => {
          if (t.indicator == data) return { ...t, current: true };
          else return { ...t, current: false };
        }),
      };
    // case TOGGEL_FOLLOW_UP_BEGIN:
    //   return {
    //     ...state,
    //     loading: true,
    //   };
    // case TOGGEL_FOLLOW_UP_SUCCESS:
    //   return {
    //     ...state,
    //     loading: false,
    //   };
    // case TOGGEL_FOLLOW_UP_ERROR:
    //   return {
    //     ...state,
    //     error,
    //     loading: false,
    //   };
    default:
      return state;
  }
};

export default patientRecommandationReducer;
