/* eslint-disable */
import axios from "axios";
import { getHeaders } from "../auth/actions";
import { toast } from "react-hot-toast";
import {
  GET_ALL_ROUTE_OF_ADMINISTRATION_BEGIN,
  GET_ALL_ROUTE_OF_ADMINISTRATION_SUCCESS,
  GET_ALL_ROUTE_OF_ADMINISTRATION_ERROR,
  ADD_ROUTE_OF_ADMINISTRATION_BEGIN,
  ADD_ROUTE_OF_ADMINISTRATION_SUCCESS,
  ADD_ROUTE_OF_ADMINISTRATION_ERROR,
  GET_ROUTE_OF_ADMINISTRATION_BEGIN,
  GET_ROUTE_OF_ADMINISTRATION_SUCCESS,
  GET_ROUTE_OF_ADMINISTRATION_ERROR,
  UPDATE_ROUTE_OF_ADMINISTRATION_BEGIN,
  UPDATE_ROUTE_OF_ADMINISTRATION_SUCCESS,
  UPDATE_ROUTE_OF_ADMINISTRATION_ERROR,
  DELETE_ROUTE_OF_ADMINISTRATION_BEGIN,
  DELETE_ROUTE_OF_ADMINISTRATION_SUCCESS,
  DELETE_ROUTE_OF_ADMINISTRATION_ERROR,
} from "./constants";

const API = process.env.REACT_APP_API_URL;

export const getAllRouteOfAdministrations = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_ALL_ROUTE_OF_ADMINISTRATION_BEGIN,
    });
    const headers = dispatch(getHeaders());
    const { data } = await axios.get(
      `${API}/api/medicine/route-of-administration-master`,
      { headers }
    );
    console.log(data.data);

    dispatch({
      type: GET_ALL_ROUTE_OF_ADMINISTRATION_SUCCESS,
      data: data.data,
    });
  } catch (error) {
    const msg = error.response.data.message;
    toast.error(msg);
    console.log(error);
    dispatch({
      type: GET_ALL_ROUTE_OF_ADMINISTRATION_ERROR,
      error: msg,
    });
  }
};

export const addRouteOfAdministration = (body) => async (dispatch) => {
  try {
    dispatch({
      type: ADD_ROUTE_OF_ADMINISTRATION_BEGIN,
    });
    const headers = dispatch(getHeaders());
    const { data } = await axios.post(
      `${API}/api/medicine/route-of-administration-master`,
      body,
      { headers }
    );
    console.log(data.data);
    toast.success("Route Of Administration added successfully!");
    dispatch({
      type: ADD_ROUTE_OF_ADMINISTRATION_SUCCESS,
      data: data.data,
    });
  } catch (error) {
    console.log(error.response);
    const msg = error.response.data.message;
    toast.error(msg);
    dispatch({
      type: ADD_ROUTE_OF_ADMINISTRATION_ERROR,
      error: msg,
    });
  }
};

export const getRouteOfAdministrationWithId = (id) => async (dispatch) => {
  try {
    dispatch({
      type: GET_ROUTE_OF_ADMINISTRATION_BEGIN,
    });
    const headers = dispatch(getHeaders());
    const { data } = await axios.get(
      `${API}/api/medicine/route-of-administration-master/${id}`,
      { headers }
    );
    console.log(data.data);
    dispatch({
      type: GET_ROUTE_OF_ADMINISTRATION_SUCCESS,
      data: data.data,
    });
  } catch (error) {
    console.log(error.response);
    const msg = error.response.data.message;
    toast.error(msg);
    dispatch({
      type: GET_ROUTE_OF_ADMINISTRATION_ERROR,
      error: msg,
    });
  }
};

export const updateRouteOfAdministration = (id, body) => async (dispatch) => {
  try {
    dispatch({
      type: UPDATE_ROUTE_OF_ADMINISTRATION_BEGIN,
    });
    const headers = dispatch(getHeaders());
    const { data } = await axios.put(
      `${API}/api/medicine/route-of-administration-master/${id}`,
      body,
      { headers }
    );
    console.log(data.data);
    toast.success("Route Of Administration updated successfully!");
    dispatch({
      type: UPDATE_ROUTE_OF_ADMINISTRATION_SUCCESS,
      data: data.data,
    });
  } catch (error) {
    const msg = error.response.data.message;
    toast.error(msg);
    dispatch({
      type: UPDATE_ROUTE_OF_ADMINISTRATION_ERROR,
      error: msg,
    });
  }
};

export const deleteRouteOfAdministration = (id) => async (dispatch) => {
  try {
    dispatch({
      type: DELETE_ROUTE_OF_ADMINISTRATION_BEGIN,
    });
    const headers = dispatch(getHeaders());
    const { data } = await axios.delete(
      `${API}/api/medicine/route-of-administration-master/${id}`,
      { headers }
    );
    toast.success("Route Of Administration deleted successfully!");
    dispatch({
      type: DELETE_ROUTE_OF_ADMINISTRATION_SUCCESS,
      data: data.data,
    });
  } catch (error) {
    const msg = error.response.data.message;
    toast.error(msg);
    dispatch({
      type: DELETE_ROUTE_OF_ADMINISTRATION_ERROR,
      error: msg,
    });
  }
};
