import React, { useState } from "react";
import Modal from "../Modal";
import axios from "axios";
import { getHeaders } from "../../redux/auth/actions";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-hot-toast";
import moment from "moment";
const Api = process.env.REACT_APP_API_URL;
const initialModalState = {
  type: "",
  state: false,
  index: null,
  edit_id: "",
  data: {
    purchase_order_id: "",
    storage_location_id: "",
    quantity: "",
    item_id: "",
  },
};

const GroupRow = ({ group }) => {
  return (
    <tr>
      <td colSpan={3}>{group?.value}</td>
    </tr>
  );
};

const CustomTablePlanView = ({ data, api, dateFilter }) => {
  const dispatch = useDispatch();
  const handleClear = async (id, payload, e) => {
    setLoading({
      loading: true,
      id: id,
    });
    const headers = dispatch(getHeaders());
    try {
      const res = await axios.put(`${Api}/api/plan-builder/${id}`, payload, {
        headers: headers,
      });
      const { data, status } = res;
      if (data) {
        api(e);
        toast.success(res.data.message);
      }
    } catch (error) {
    } finally {
      setLoading({
        loading: false,
        id: null,
      });
    }
  };

  const [modal, setModal] = useState(initialModalState);
  const RenderCompleteModal = () => {
    const { type, state, data, edit_id } = modal;
    const { values, handleChange, handleBlur, handleSubmit } = useFormik({
      initialValues: {
        complete_date: "",
      },
      validationSchema: Yup.object({
        complete_date: Yup.string().required("Required"),
      }),

      onSubmit: async (values, { setSubmitting, resetForm }, e) => {
        const date = values.complete_date + "T00:00:00.000Z";
        const headers = dispatch(getHeaders());
        try {
          const res = await axios.put(
            `${Api}/api/plan-builder/mark-as-done/${edit_id}`,
            {
              date: date,
            },
            {
              headers: headers,
            }
          );
          const { data, status } = res;
          if (status === 200) {
            setModal((prev) => ({ ...prev, state: false, edit_id: "" }));
            toast.success("Marked Complete");
            api(e);
          }
        } catch (error) {
          toast.error(error.message);
        } finally {
          setSubmitting(false);
          resetForm();
        }
      },
    });
    return (
      <Modal
        title={`Mark Complete`}
        size="max-w-sm"
        open={state}
        setOpen={() =>
          setModal((prev) => ({ ...prev, state: false, edit_id: "" }))
        }
      >
        <div className="mt-2 w-full flex items-center justify-center">
          <form onSubmit={handleSubmit} className="flex flex-col w-full">
            <div className="flex items-center justify-between">
              <label htmlFor="text-sm text-left text-gray-500">
                Select Date
              </label>
              <input
                type="date"
                id="complete_date"
                name="complete_date"
                value={values.complete_date}
                onChange={handleChange}
                onBlur={handleBlur}
                className="w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-indigo-500 sm:max-w-xs sm:text-sm"
              />
            </div>

            <button
              type="submit"
              className="inline-flex mt-2 w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm"
            >
              Mark Complete
            </button>
          </form>
        </div>
      </Modal>
    );
  };
  const [loading, setLoading] = useState({
    loading: false,
    id: null,
  });
  return (
    <div className="mt-4 flex flex-col">
      {RenderCompleteModal()}
      {data?.map((item) => (
        <div key={item?.department} className="grid grid-cols-1 gap-4">
          <div className="p-4 bg-white rounded-lg shadow-md border-t border-gray-200">
            {item?.goal_activity_datas?.map((act, index) => (
              <>
                <div className="flex items-center mb-4 pb-2">
                  <h1 className="text-xl font-bold mr-4 text-gray-900 text-left">
                    {item?.goal_name}
                  </h1>
                </div>

                {act?.Plan_builder_weekly_activity?.filter((w) => {
                  if (dateFilter) {
                    return w.complete_date.includes(
                      dateFilter + "T00:00:00.000Z"
                    );
                  } else {
                    return w;
                  }
                })?.map((weekly_act) => {
                  let clear = weekly_act.clear;
                  var Isclear = clear;
                  return (
                    <>
                      <div className="flex flex-col items-start mb-8 border-b border-gray-300 pb-2">
                        <h1>
                          <span className="flex flex-col items-start ">
                            <span className="text-xl flex items-center font-bold mr-4 text-gray-900 text-left">
                              <input
                                type="checkbox"
                                checked={Isclear}
                                disabled={loading.id === weekly_act?.id}
                                onChange={() => {
                                  Isclear = clear === true ? false : true;
                                }}
                                onClick={(e) => {
                                  handleClear(
                                    weekly_act?.id,
                                    {
                                      clear: !clear,
                                    },
                                    e
                                  );
                                }}
                                className="mr-2 mt-2"
                              />{" "}
                              <div className="flex  flex-col items-start justify-center">
                                {" "}
                                <p className="text-gray-700 font-semibold text-sm self-start">
                                  {weekly_act?.complete_date &&
                                    weekly_act?.complete_date.map(
                                      (date) =>
                                        moment(date).format("DD-MM-YYYY") +
                                        " , "
                                    )}
                                </p>
                                <span> {weekly_act?.activity?.title}</span>
                              </div>
                              {loading.loading &&
                                loading.id === weekly_act?.id && (
                                  <div className="ml-4 flex justify-center items-center">
                                    <div className="animate-spin rounded-full border-t-2 border-blue-500 border-opacity-50 h-4 w-4"></div>
                                  </div>
                                )}
                            </span>
                          </span>
                        </h1>
                        <div className="text-md font-semibold text-gray-900 text-left">
                          &#x2022; {weekly_act?.activity?.description}
                        </div>
                        <span className="inline-flex self-end px-2 ml-3 text-xs mt-2 text-indigo-600 bg-indigo-100 rounded-full">
                          <button
                            className="text-indigo-900 "
                            size="default"
                            variant="outlined"
                            type="button"
                            onClick={() =>
                              setModal((prev) => ({
                                ...prev,
                                type: "complete",
                                state: true,
                                edit_id: weekly_act?.id,
                              }))
                            }
                          >
                            Mark Completed
                            <span className="sr-only">, </span>
                          </button>
                        </span>
                      </div>
                    </>
                  );
                })}
              </>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default CustomTablePlanView;
