import React, { useEffect } from "react";
import { Line } from "react-chartjs-2";
import { useState } from "react";
import Chart from "chart.js/auto";
import {
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import axios from "axios";
import moment from "moment";
Chart.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);
const api = process.env.REACT_APP_API_URL;

function OPDLineChart({ cdata, selected, tabName }) {
  const [chartData, setChartData] = useState({
    labels:  Object.values(cdata).map((item) => item.date),

    datasets: [
      {
        label: "Follow Up",
        data: Object.values(cdata).map((item) => item.followup),
        borderColor: "rgb(255, 99, 132)",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
        borderWidth: 2,
      },
      {
        label: "New Patients",
        data: Object.values(cdata).map((item) => item.new),
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
        borderWidth: 2,
      },
    ],
  });

 useEffect(()=>{
  setChartData({
    labels:  Object.values(cdata).map((item) => item.date),

    datasets: [
      {
        label: "Follow Up",
        data: Object.values(cdata).map((item) => item.followup),
        borderColor: "rgb(255, 99, 132)",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
        borderWidth: 2,
      },
      {
        label: "New Patients",
        data: Object.values(cdata).map((item) => item.new),
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
        borderWidth: 2,
      },
    ],
  })
 },[cdata])
  return (
    <>
      <div className="grid gap-4 grid-cols-2">
        <div
          className="chart-container"
          style={{ height: "20rem", width: "68rem" }}
        >
          <Line
            data={chartData}
            options={{
              maintainAspectRatio: false,

              scales: {
                y: {
                  min: 0,
                  ticks: {
                    stepSize: 5,
                  },
                },
              },
              plugins: {
                title: {
                  display: true,
                  text: (selected != 'all' ? `Dr. ${selected} - ${tabName} OPD's`: `All Doctor - ${tabName} OPD's`),
                  font: {
                    size: 24,
                    family: 'Helvetica Neue'
                  }
                },
                legend: {
                  display: true,
                },
              },
            }}
          />
        </div>
      </div>
    </>
  );
}
export default OPDLineChart;
