import { configureStore } from '@reduxjs/toolkit'
import thunk from 'redux-thunk';
import rootReducer from './rootReducer';

// const reduxDevTool =
//   process.env.NODE_ENV === 'development'
//     ? composeWithDevTools(applyMiddleware(thunk.withExtraArgument()))
//     : compose(applyMiddleware(thunk.withExtraArgument()));

// const store = createStore(rootReducer, reduxDevTool);
const store = configureStore({
  reducer: rootReducer,
  middleware: [thunk],
});

export default store;