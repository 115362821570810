import axios from "axios";
import { getHeaders } from "../auth/actions";
import { toast } from "react-hot-toast";
import {
  GET_ALL_SCHEDULE_BEGIN,
  GET_ALL_SCHEDULE_SUCCESS,
  GET_ALL_SCHEDULE_ERROR,
  ADD_SCHEDULE_BEGIN,
  ADD_SCHEDULE_SUCCESS,
  ADD_SCHEDULE_ERROR,
  GET_SCHEDULE_BEGIN,
  GET_SCHEDULE_SUCCESS,
  GET_SCHEDULE_ERROR,
  UPDATE_SCHEDULE_BEGIN,
  UPDATE_SCHEDULE_SUCCESS,
  UPDATE_SCHEDULE_ERROR,
  DELETE_SCHEDULE_BEGIN,
  DELETE_SCHEDULE_SUCCESS,
  DELETE_SCHEDULE_ERROR,
} from "./constants";

const API = process.env.REACT_APP_API_URL;

export const getAllSchedule = (id) => async (dispatch) => {
  try {
    dispatch({
      type: GET_ALL_SCHEDULE_BEGIN,
    });
    const headers = dispatch(getHeaders());
    const { data } = await axios.get(`${API}/api/schedule-master/${id}`, {
      headers,
    });
    console.log(data.data);

    dispatch({
      type: GET_ALL_SCHEDULE_SUCCESS,
      data: data.data,
    });
  } catch (error) {
    const msg = error.response.data.message;
    toast.error(msg);
    dispatch({
      type: GET_ALL_SCHEDULE_ERROR,
      error: msg,
    });
  }
};

export const addSchedule = (body) => async (dispatch) => {
  try {
    dispatch({
      type: ADD_SCHEDULE_BEGIN,
    });
    const headers = dispatch(getHeaders());
    const { data } = await axios.post(`${API}/api/schedule-master`, body, {
      headers,
    });
    console.log(data.data);
    toast.success("Added doctor successfully!");
    dispatch({
      type: ADD_SCHEDULE_SUCCESS,
      data: data.data,
    });
  } catch (error) {
    const msg = error.response.data.message;
    toast.error(msg);
    dispatch({
      type: ADD_SCHEDULE_ERROR,
      error: msg,
    });
  }
};

export const getScheduleWithId = (id) => async (dispatch) => {
  try {
    dispatch({
      type: GET_SCHEDULE_BEGIN,
    });
    const headers = dispatch(getHeaders());
    const { data } = await axios.get(
      `${API}/api/schedule-master/get-one/${id}`,
      {
        headers,
      }
    );
    console.log(data.data);

    dispatch({
      type: GET_SCHEDULE_SUCCESS,
      data: data.data,
    });
  } catch (error) {
    const msg = error.response.data.message;
    toast.error(msg);
    dispatch({
      type: GET_SCHEDULE_ERROR,
      error: msg,
    });
  }
};

export const updateSchedule = (id, body) => async (dispatch) => {
  try {
    dispatch({
      type: UPDATE_SCHEDULE_BEGIN,
    });
    const headers = dispatch(getHeaders());
    const { data } = await axios.put(`${API}/api/schedule-master/${id}`, body, {
      headers,
    });
    console.log(data.data);
    toast.success("Updated doctor successfully!");
    dispatch({
      type: UPDATE_SCHEDULE_SUCCESS,
      data: data.data,
    });
  } catch (error) {
    const msg = error.response.data.message;
    toast.error(msg);
    dispatch({
      type: UPDATE_SCHEDULE_ERROR,
      error: msg,
    });
  }
};

export const deleteSchedule = (id, body) => async (dispatch) => {
  try {
    dispatch({
      type: DELETE_SCHEDULE_BEGIN,
    });
    const headers = dispatch(getHeaders());
    const { data } = await axios.delete(`${API}/api/schedule-master/${id}`, {
      headers,
    });
    console.log(data.data);
    toast.success("Deleted schedule successfully!");
    dispatch({
      type: DELETE_SCHEDULE_SUCCESS,
      data: data.data,
    });
  } catch (error) {
    const msg = error.response.data.message;
    toast.error(msg);
    dispatch({
      type: DELETE_SCHEDULE_ERROR,
      error: msg,
    });
  }
};
