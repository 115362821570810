import {
  GET_ALL_DEPARTMENT_FILTER_BEGIN,
  GET_ALL_DEPARTMENT_FILTER_SUCCESS,
  GET_ALL_DEPARTMENT_FILTER_ERROR,
  RECOMMEND_PROGRAM_BEGIN,
  RECOMMEND_PROGRAM_SUCCESS,
  RECOMMEND_PROGRAM_ERROR,
} from "./constants";

const initState = {
  list: [],
  loading: false,
  error: null,
};

const departmentFilterReducer = (state = initState, action) => {
  const { type, data, error } = action;
  switch (type) {
    case GET_ALL_DEPARTMENT_FILTER_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case GET_ALL_DEPARTMENT_FILTER_SUCCESS:
      return {
        ...state,
        list: data,
        loading: false,
      };
    case GET_ALL_DEPARTMENT_FILTER_ERROR:
      return {
        ...state,
        error,
        loading: false,
      };
      case RECOMMEND_PROGRAM_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case RECOMMEND_PROGRAM_SUCCESS:
      return {
        ...state,
        loading: false,
        list: [...state.list, data],
      };
    case RECOMMEND_PROGRAM_ERROR:
      return {
        ...state,
        error,
        loading: false,
      };
    default:
      return state;
  }
};

export default departmentFilterReducer;
