import React, { useRef, useState } from "react";
import * as Yup from "yup";
import { Formik } from "formik";
const EditTooptip = ({ func, id }) => {
  const [btnText, setBtnText] = useState("Save");
  const initialValues = {
    name: "",
  };
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={Yup.object({
        date: Yup.string().required("value is required"),
      })}
      enableReinitialize={true}
      onSubmit={(values) => {
        //TODO: Add optimize code here
        const data = {
          date: values.date,
        };
        func(data, id);
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
      }) => (
        <div className="flex w-max gap-2 Z-[9999]">
          <form className="flex gap-2" onSubmit={handleSubmit}>
            <div>
              <input
                className={` w-[90%]appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-cyan-500 focus:outline-none focus:ring-cyan-500 sm:text-sm`}
                placeholder="enter here value"
                name="date"
                value={values.date}
                onChange={handleChange}
                onBlur={handleBlur}
                type="date"
              />
              {errors.name && touched.name && (
                <p
                  className="text-red-500 text-sm
              "
                >
                  {errors.name}
                </p>
              )}
            </div>
            <button
              className="inline-flex items-center rounded-md border mb-6 border-transparent bg-indigo-100 px-3 py-2 text-sm font-medium leading-4 text-indigo-700 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              type="submit"
            >
              Update
            </button>
          </form>
        </div>
      )}
    </Formik>
  );
};

export default EditTooptip;
