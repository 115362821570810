import {
    GET_ALL_PROGRAM_SESSION_BEGIN,
    GET_ALL_PROGRAM_SESSION_SUCCESS,
    GET_ALL_PROGRAM_SESSION_ERROR,
    ADD_PROGRAM_SESSION_BEGIN,
    ADD_PROGRAM_SESSION_SUCCESS,
    ADD_PROGRAM_SESSION_ERROR,
    GET_PROGRAM_SESSION_BEGIN,
    GET_PROGRAM_SESSION_ERROR,
    GET_PROGRAM_SESSION_SUCCESS,
    UPDATE_PROGRAM_SESSION_BEGIN,
    UPDATE_PROGRAM_SESSION_SUCCESS,
    UPDATE_PROGRAM_SESSION_ERROR,
    DELETE_PROGRAM_SESSION_BEGIN,
    DELETE_PROGRAM_SESSION_SUCCESS,
    DELETE_PROGRAM_SESSION_ERROR,
  } from "./constants";
  
  const initState = {
    list: [],
    loading: false,
    error: null,
  };
  
  const ProgramMasterReducer = (state = initState, action) => {
    const { type, data, error } = action;
    switch (type) {
      case GET_ALL_PROGRAM_SESSION_BEGIN:
        return {
          ...state,
          loading: true,
        };
      case GET_ALL_PROGRAM_SESSION_SUCCESS:
        return {
          ...state,
          list: data,
          loading: false,
        };
      case GET_ALL_PROGRAM_SESSION_ERROR:
        return {
          ...state,
          error,
          loading: false,
        };
      case ADD_PROGRAM_SESSION_BEGIN:
        return {
          ...state,
          loading: true,
        };
      case ADD_PROGRAM_SESSION_SUCCESS:
        return {
          ...state,
          loading: false,
          list: [...state.list, data],
        };
      case ADD_PROGRAM_SESSION_ERROR:
        return {
          ...state,
          error,
          loading: false,
        };
      case GET_PROGRAM_SESSION_BEGIN:
        return {
          ...state,
          loading: true,
          programSessionData: "",
        };
      case GET_PROGRAM_SESSION_SUCCESS:
        return {
          ...state,
          loading: false,
          programSessionData: data,
        };
      case GET_PROGRAM_SESSION_ERROR:
        return {
          ...state,
          error,
          loading: false,
        };
      case UPDATE_PROGRAM_SESSION_BEGIN:
        return {
          ...state,
          loading: true,
        };
      case UPDATE_PROGRAM_SESSION_SUCCESS:
        return {
          ...state,
          loading: false,
          list: state.list.map((ele) => {
            if (ele.id === data.id) {
              return { ...data };
            } else return ele;
          }),
        };
      case UPDATE_PROGRAM_SESSION_ERROR:
        return {
          ...state,
          error,
          loading: false,
        };
      case DELETE_PROGRAM_SESSION_BEGIN:
        return {
          ...state,
          loading: true,
        };
      case DELETE_PROGRAM_SESSION_SUCCESS:
        return {
          ...state,
          loading: false,
          list: state.list.filter((ele) => ele.id !== data.id),
        };
      case DELETE_PROGRAM_SESSION_ERROR:
        return {
          ...state,
          error,
          loading: false,
        };
      default:
        return state;
    }
  };
  
  export default ProgramMasterReducer;
  