export const GET_ALL_SCHEDULE_BEGIN = "GET_ALL_SCHEDULE_BEGIN"
export const GET_ALL_SCHEDULE_SUCCESS = "GET_ALL_SCHEDULE_SUCCESS"
export const GET_ALL_SCHEDULE_ERROR = "GET_ALL_SCHEDULE_ERROR"

export const ADD_SCHEDULE_BEGIN = "ADD_SCHEDULE_BEGIN"
export const ADD_SCHEDULE_SUCCESS = "ADD_SCHEDULE_SUCCESS"
export const ADD_SCHEDULE_ERROR = "ADD_SCHEDULE_ERROR"

export const GET_SCHEDULE_BEGIN = "GET_SCHEDULE_BEGIN"
export const GET_SCHEDULE_SUCCESS = "GET_SCHEDULE_SUCCESS"
export const GET_SCHEDULE_ERROR = "GET_SCHEDULE_ERROR"

export const UPDATE_SCHEDULE_BEGIN = "UPDATE_SCHEDULE_BEGIN"
export const UPDATE_SCHEDULE_SUCCESS = "UPDATE_SCHEDULE_SUCCESS"
export const UPDATE_SCHEDULE_ERROR = "UPDATE_SCHEDULE_ERROR"

export const DELETE_SCHEDULE_BEGIN = "DELETE_SCHEDULE_BEGIN"
export const DELETE_SCHEDULE_SUCCESS = "DELETE_SCHEDULE_SUCCESS"
export const DELETE_SCHEDULE_ERROR = "DELETE_SCHEDULE_ERROR"