/* eslint-disable */
import axios from "axios";
import { getHeaders } from "../auth/actions";
import { toast } from "react-hot-toast";
import {
  GET_ALL_PATIENTS_FORM_BEGIN,
  GET_ALL_PATIENTS_FORM_SUCCESS,
  GET_ALL_PATIENTS_FORM_ERROR,
  ADD_PATIENTS_FORM_BEGIN,
  ADD_PATIENTS_FORM_SUCCESS,
  ADD_PATIENTS_FORM_ERROR,
  GET_PATIENTS_FORM_BEGIN,
  GET_PATIENTS_FORM_ERROR,
  GET_PATIENTS_FORM_SUCCESS,
  UPDATE_PATIENTS_FORM_BEGIN,
  UPDATE_PATIENTS_FORM_SUCCESS,
  UPDATE_PATIENTS_FORM_ERROR,
  GET_ALL_DOCS_BEGIN,
  GET_ALL_DOCS_SUCCESS,
  GET_ALL_DOCS_ERROR,
} from "./constants";

const API = process.env.REACT_APP_API_URL;

export const getAllPatientForms = (id) => async (dispatch) => {
  try {
    dispatch({
      type: GET_ALL_PATIENTS_FORM_BEGIN,
    });
    const headers = dispatch(getHeaders());
    const { data } = await axios.get(`${API}/api/patient-form/patient/${id}`, {
      headers,
    });
    console.log(data.data);

    dispatch({
      type: GET_ALL_PATIENTS_FORM_SUCCESS,
      data: data.data,
    });
  } catch (error) {
    const msg = error.response.data.message;
    toast.error(msg);
    console.log(error);
    dispatch({
      type: GET_ALL_PATIENTS_FORM_ERROR,
      error: msg,
    });
  }
};

export const addPatientForm = (body) => async (dispatch) => {
  try {
    dispatch({
      type: ADD_PATIENTS_FORM_BEGIN,
    });
    const headers = dispatch(getHeaders());
    const { data } = await axios.post(`${API}/api/patient-form`, body, {
      headers,
    });
    console.log(data.data);
    toast.success("Patient Form added successfully!");
    dispatch({
      type: ADD_PATIENTS_FORM_SUCCESS,
      data: data.data,
    });
  } catch (error) {
    console.log(error.response);
    const msg = error.response.data.message;
    toast.error(msg);
    dispatch({
      type: ADD_PATIENTS_FORM_ERROR,
      error: msg,
    });
  }
};

export const getPatientDetails = (id) => async (dispatch) => {
  try {
    dispatch({
      type: GET_PATIENTS_FORM_BEGIN,
    });
    const headers = dispatch(getHeaders());
    const { data } = await axios.get(`${API}/api/patient/view/${id}`, {
      headers,
    });
    console.log(data.data);

    dispatch({
      type: GET_PATIENTS_FORM_SUCCESS,
      data: data.data,
    });
  } catch (error) {
    const msg = error.response.data.message;
    toast.error(msg);
    console.log(error);
    dispatch({
      type: GET_PATIENTS_FORM_ERROR,
      error: msg,
    });
  }
};
export const getAllDocuments = (id) => async (dispatch) => {
  try {
    dispatch({
      type: GET_ALL_DOCS_BEGIN,
    });
    const headers = dispatch(getHeaders());
    const { data } = await axios.get(`${API}/api/patient/documents/${id}`, {
      headers,
    });
    console.log(data.data);

    dispatch({
      type: GET_ALL_DOCS_SUCCESS,
      data: data.data,
    });
  } catch (error) {
    const msg = error.response.data.message;
    toast.error(msg);
    console.log(error);
    dispatch({
      type: GET_ALL_DOCS_ERROR,
      error: msg,
    });
  }
};
