import React from 'react'
import Breadcrumb from "../../../components/Breadcrumb";

const genericMedicine = ["Resperidone", "Fluoxitine", "Azithrowin"]
const formulationMedicine = ["Syrup", "Tablate", "Capsule"]
const strengthMedicine = ["Risnia (1mg/ml)",
    "Risdone (1mg/ml)",
    "Sizodon (1mg/ml)",
    "Risdone (1mg)",
    "Risdone (2mg)",
    "Risdone (3mg)",
    "Sizodon (1mg)",
    "Sizodon (2mg)",
    "Sizodon (3mg",
    "flunil (10mg)",
    "flunil (20mg)",
    "flunil (30mg)",
    "flunil (50mg)",
    "Fludac (10mg)",
    "Fludac (20mg)",
    "Fludac (30mg)",
    "Fludac (40mg)",
    "Fludac (50mg)",
    "Azithromycine (50mg)",
    "Azithromycine (100mg)",
    "Azithromycine (200mg)",
    "Azithromycine (2500mg)"
]

const MedicineForm = () => {
    const pages = [{ title: "MedicineForm", href: "/medicine-form" }];

    return (
        <div>
            <Breadcrumb pages={pages} />
            <div>
                <form
                    className="space-y-8 divide-y divide-gray-200"
                >
                    <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
                        <div className="space-y-6 sm:space-y-5">
                            <div>
                                <h3 className="text-lg font-medium leading-6 text-gray-900">Medicine Form</h3>
                                <p className="mt-1 max-w-2xl text-sm text-gray-500">
                                    This information will be displayed publicly so be careful what you share.
                                </p>
                            </div>
                            <div className="space-y-6 sm:space-y-5">
                                <div className="space-y-6 sm:space-y-5 sm:pt-5">
                                    <div className="space-y-6 sm:space-y-5">
                                        <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                                            <label
                                                htmlFor="generic_name"
                                                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                                            >
                                                Generic name
                                            </label>

                                            <div className="mt-1">
                                                <select
                                                    id="generic_name"
                                                    name="generic_name"
                                                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                                >
                                                    {genericMedicine.map((generic, key) => (
                                                        <option key={key} label={generic}></option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                                            <label
                                                htmlFor="strength"
                                                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                                            >
                                                Strength
                                            </label>
                                            <div className="mt-1">
                                                <select
                                                    id="strength"
                                                    name="strength"
                                                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                                >
                                                {strengthMedicine.map((straight, key) => (
                                                    <option key={key} label={straight}></option>
                                                ))}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                                            <label
                                                htmlFor="formulation"
                                                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                                            >
                                                Formulation
                                            </label>
                                            <div className="mt-1">
                                                <select
                                                    id="formulation"
                                                    name="formulation"
                                                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                                >
                                                    {formulationMedicine.map((formulation, key) => (
                                                    <option key={key} label={formulation}></option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                                            <label
                                                htmlFor="route_of_administration"
                                                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                                            >
                                                Route of Administration
                                            </label>
                                            <div className="mt-1">
                                                <select
                                                    id="route_of_administration"
                                                    name="route_of_administration"
                                                    autoComplete="route_of_administration"
                                                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                                >
                                                    <option>Oral</option>
                                                    <option>Oral</option>
                                                    <option>Oral</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                                            <label
                                                htmlFor="frequency"
                                                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                                            >
                                                Frequency
                                            </label>
                                            <div className="mt-1">
                                                <select
                                                    id="frequency"
                                                    name="frequency"
                                                    autoComplete="frequency-name"
                                                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                                >
                                                    <option>Once</option>
                                                    <option>Twice</option>
                                                    <option>Thrice</option>
                                                    <option>Four Time a day</option>

                                                </select>
                                            </div>
                                        </div>
                                        <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                                            <label
                                                htmlFor="medicine_time"
                                                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                                            >
                                                Medicine Time
                                            </label>
                                            <div className="mt-1">
                                                <select
                                                    id="medicine_time"
                                                    name="medicine_time"
                                                    autoComplete="medicine_time"
                                                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                                >
                                                    <option>Morning - Before Breakfast</option>
                                                    <option>Morning - After Breakfast</option>
                                                    <option>AfterNoone - Aftermeals</option>
                                                    <option>Evenning at 7pm</option>
                                                    <option>Bed Time</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                                            <label
                                                htmlFor="start_date"
                                                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                                            >
                                                Start Date
                                            </label>
                                            <div className="mt-1 sm:col-span-2 sm:mt-0">
                                                <input
                                                    type="date"
                                                    name="start_date"
                                                    id="start_date"
                                                    autoComplete="off"
                                                    className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                                                />
                                            </div>
                                        </div>
                                        <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                                            <label
                                                htmlFor="duration"
                                                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                                            >
                                                Duration
                                            </label>
                                            <div className="mt-1">
                                                <select
                                                    id="duration"
                                                    name="duration"
                                                    autoComplete="duration-name"
                                                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                                >
                                                    <option>30 Days</option>
                                                    <option>60 Days</option>
                                                    <option>90 Days</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                                            <label
                                                htmlFor="instructions"
                                                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                                            >
                                                Instructions
                                            </label>
                                            <div className="mt-1 sm:col-span-2 sm:mt-0">
                                                <textarea
                                                    type="text"
                                                    name="instructions"
                                                    id="instructions"
                                                    autoComplete="off"
                                                    className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="pt-5">
                                <div className="flex justify-end">
                                    <button
                                        type="submit"
                                        className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                    >
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default MedicineForm