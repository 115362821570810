import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AiOutlinePlus } from "react-icons/ai";
import { useFormik } from "formik";
import moment from "moment";
import { patientsSchema, ReferralSchema } from "../../../schemas";
import Select from "react-select";
import {
  getPatientWithId,
  addPatient,
  updatePatient,
} from "../../../redux/patient/actions";
import { FallingLinesLoader } from "../../../components/spinners/Spinner";
import Breadcrumb from "../../../components/Breadcrumb";
import { addReferral, getAllReferral } from "../../../redux/referral/action";
import { ArrowUturnLeftIcon } from "@heroicons/react/20/solid";
import { toast } from "react-hot-toast";
import { getAllLocations } from "../../../redux/location/actions";
import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { Country }  from 'country-state-city';

const Addpatients = () => {
  const [showModal, setShowModal] = useState(false);
  const pages = [{ title: "Patients", href: "/patients" }];
  var selected = "all";
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id: patientId } = useParams();
  const { loading, patientData } = useSelector((state) => state.patient);
  const { list: referralList } = useSelector((state) => state.referral);
  const { list: locationList } = useSelector((state) => state.location);
  const initialValues = {
    firstName: "",
    lastName: "",
    fatherFirstName: "",
    fatherLastName: "",
    motherFirstName: "",
    motherLastName: "",
    guardiansFirstName: "",
    guardiansLastName: "",
    location_id: "",
    gender: "",
    phoneNo: "",
    country: "",
    dob: "",
    address: "",
  };

  const [formState, setFormState] = useState(initialValues);

  useEffect(() => {
    dispatch(getAllReferral());
    dispatch(getAllLocations());
    if (patientId) dispatch(getPatientWithId(patientId));
  }, [patientId]);

  useEffect(() => {
    document.title = "New Horizon | Add-Patient";
    if (patientId && patientData) {
      const ref = referralList?.find(
        (r) => Number(r?.id) === Number(patientData?.referral_id)
      )?.name;
      const newFormState = {
        firstName: patientData.firstName,
        lastName: patientData.lastName,
        fatherFirstName: patientData.fatherFirstName,
        fatherLastName: patientData.fatherLastName,
        motherFirstName: patientData.motherFirstName,
        motherLastName: patientData.motherLastName,
        guardiansFirstName: patientData.guardiansFirstName,
        guardiansLastName: patientData.guardiansLastName,
        referral_id: patientData.referral_id,
        location_id: patientData.location_id,
        country: patientData.country,
        
        dob: moment(patientData.dob).format("yyyy-MM-DD"),
        address: patientData.address,
        referal: {
          label: ref ? ref : "",
          value: ref ? patientData.referral_id : "",
        },
        gender: patientData.gender,
      };
      setFormState(newFormState);
      setFormState(prev => ({
        ...prev,
        
        phoneNo:
        patientData?.phoneNo?.length === 10
          ? "+91" + patientData.phoneNo
          : patientData.phoneNo,
      }))
    }
  }, [patientId, patientData]);



  const {
    values,
    errors,
    setValues,
    handleBlur,
    touched,
    handleChange,
    handleSubmit,
  } = useFormik({
    enableReinitialize: true,
    initialValues: formState,
    validationSchema: patientsSchema,
    onSubmit: (values, action) => {
      const body = {
        ...values,
        referral_id: parseInt(values.referral_id),
        location_id: parseInt(values.location_id),
        phoneNo: "+" + values.phoneNo,
      };
      body.dob = moment(values.dob).format();
      delete body.referal;
      // if body.referral_id is null then remove it
      if (!body.referral_id) delete body.referral_id;
      let flags = 0;
      flags += body.fatherFirstName && body.fatherLastName ? 1 : 0;
      flags += body.motherFirstName && body.motherLastName ? 1 : 0;
      flags += body.guardiansFirstName && body.guardiansLastName ? 1 : 0;
      if (flags < 1) {
        toast.error("atleast two guardians info are required");
      } else {
        if (patientId) dispatch(updatePatient(patientId, body));
        else dispatch(addPatient(body));
        action.resetForm();
        navigate("/patients");
      }
    },
  });

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <AddRefferalModal showModal={showModal} setShowModal={setShowModal} />
      <Breadcrumb pages={pages} />
      <div>
        <button
          type="button"
          onClick={() => navigate(-1)}
          className="inline-flex items-center rounded-md border mb-6 border-transparent bg-indigo-100 px-3 py-2 text-sm font-medium leading-4 text-indigo-700 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
        >
          <span className="w-5 mr-2">
            <ArrowUturnLeftIcon size={18} />
          </span>{" "}
          Back
        </button>
        <div className="sm:flex-auto border-b pb-5">
          <h1 className="text-lg font-medium leading-6 text-gray-900">
            {" "}
            {patientId ? "Update Patient" : "Add Patient"}
          </h1>
          <p className="mt-1 max-w-2xl text-sm text-gray-500">
            This information will be displayed publicly so be careful what you
            share.
          </p>
        </div>
      </div>
      {loading ? (
        <FallingLinesLoader />
      ) : (
        <form
          onSubmit={handleSubmit}
          className="space-y-8 divide-y divide-gray-200"
        >
          <div className="space-y-6 sm:space-y-5">
            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                htmlFor="firstName"
                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                First Name
              </label>
              <div className="mt-1 sm:col-span-2 sm:mt-0">
                <input
                  className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                  type="text"
                  name="firstName"
                  placeholder="First Name"
                  id="firstName"
                  value={values.firstName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.firstName && touched.firstName ? (
                  <p className="text-red-600 text-sm">{errors.firstName}</p>
                ) : null}
              </div>
            </div>
            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                htmlFor="lastName"
                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Last Name
              </label>
              <div className="mt-1 sm:col-span-2 sm:mt-0">
                <input
                  className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                  type="text"
                  name="lastName"
                  placeholder="Last Name"
                  id="lastName"
                  value={values.lastName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.lastName && touched.lastName ? (
                  <p className="text-red-600 text-sm">{errors.lastName}</p>
                ) : null}
              </div>
            </div>
            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                htmlFor="fatherFirstName"
                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Father First Name
              </label>
              <div className="mt-1 sm:col-span-2 sm:mt-0">
                <input
                  className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                  type="text"
                  name="fatherFirstName"
                  placeholder="Father First Name"
                  id="fatherFirstName"
                  value={values.fatherFirstName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.fatherFirstName && touched.fatherFirstName ? (
                  <p className="text-red-600 text-sm">
                    {errors.fatherFirstName}
                  </p>
                ) : null}
              </div>
            </div>
            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                htmlFor="fatherLastName"
                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Father Last Name
              </label>
              <div className="mt-1 sm:col-span-2 sm:mt-0">
                <input
                  className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                  type="text"
                  name="fatherLastName"
                  placeholder="Father Last Name"
                  id="fatherLastName"
                  value={values.fatherLastName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.fatherLastName && touched.fatherLastName ? (
                  <p className="text-red-600 text-sm">
                    {errors.fatherLastName}
                  </p>
                ) : null}
              </div>
            </div>
            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                htmlFor="motherFirstName"
                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Mother First Name
              </label>
              <div className="mt-1 sm:col-span-2 sm:mt-0">
                <input
                  className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                  type="text"
                  name="motherFirstName"
                  placeholder="Mother First Name"
                  id="motherFirstName"
                  value={values.motherFirstName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.motherFirstName && touched.motherFirstName ? (
                  <p className="text-red-600 text-sm">
                    {errors.motherFirstName}
                  </p>
                ) : null}
              </div>
            </div>
            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                htmlFor="motherLastName"
                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Mother Last Name
              </label>
              <div className="mt-1 sm:col-span-2 sm:mt-0">
                <input
                  className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                  type="text"
                  name="motherLastName"
                  placeholder="Mother Last Name"
                  id="motherLastName"
                  value={values.motherLastName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.motherLastName && touched.motherLastName ? (
                  <p className="text-red-600 text-sm">
                    {errors.motherLastName}
                  </p>
                ) : null}
              </div>
            </div>
            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                htmlFor="guardiansFirstName"
                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Guardian First Name
              </label>
              <div className="mt-1 sm:col-span-2 sm:mt-0">
                <input
                  className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                  type="text"
                  name="guardiansFirstName"
                  placeholder="Guardian First Name"
                  id="guardiansFirstName"
                  value={values.guardiansFirstName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.guardiansFirstName && touched.guardiansFirstName ? (
                  <p className="text-red-600 text-sm">
                    {errors.guardiansFirstName}
                  </p>
                ) : null}
              </div>
            </div>
            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                htmlFor="guardiansLastName"
                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Guardian Last Name
              </label>
              <div className="mt-1 sm:col-span-2 sm:mt-0">
                <input
                  className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                  type="text"
                  name="guardiansLastName"
                  id="guardiansLastName"
                  placeholder="Guardian Last Name"
                  value={values.guardiansLastName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.guardiansLastName && touched.guardiansLastName ? (
                  <p className="text-red-600 text-sm">
                    {errors.guardiansLastName}
                  </p>
                ) : null}
              </div>
            </div>
            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                htmlFor="dob"
                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Country
              </label>
              <div className="mt-1 sm:col-span-2 sm:mt-0">
                <select
                  id="country"
                  name="country"
                  value={values.country}

                  onChange={(e) => {console.log(e.target.value); handleChange(e)}}
                  onBlur={handleBlur}
                  className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-indigo-500 sm:max-w-xs sm:text-sm"
                >
                  <option value="">Select a Country</option>

                  {Country.getAllCountries()?.map((d, idx) => (
                    <option key={idx} value={`${d.name}-${d.isoCode}`}>
                      {d.name}
                    </option>
                  ))}
                </select>
                {/* {errors.location_id && touched.location_id ? (
                  <p className="text-red-600 text-sm">{errors.}</p>
                ) : null} */}
              </div>
            </div>
            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                htmlFor="phone"
                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Phone
              </label>
              <div className="mt-1 sm:col-span-2 sm:mt-0">
                {/* <input
                  className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                  type="text"
                  name="phoneNo"
                  placeholder="Phone Number"
                  id="phone"
                  value={values.phoneNo}
                  onChange={handleChange}
                  onBlur={handleBlur}
                /> */}
                <PhoneInput
                  country={values.country?.split('-')?.[1]?.toLowerCase() || 'in'}
                  value={values.phoneNo}
                  onChange={(phone) => setValues({ ...values, phoneNo: phone })}
                  inputProps={{
                    name: "phone",
                    required: true,
                    autoFocus: true,
                  }}
                />
                {errors.phoneNo && touched.phoneNo ? (
                  <p className="text-red-600 text-sm">{errors.phoneNo}</p>
                ) : null}
              </div>
            </div>
            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                htmlFor="dob"
                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Date of birth
              </label>
              <div className="mt-1 sm:col-span-2 sm:mt-0">
                <input
                  className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                  type="date"
                  name="dob"
                  id="dob"
                  value={values.dob}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.dob && touched.dob ? (
                  <p className="text-red-600 text-sm">{errors.dob}</p>
                ) : null}
              </div>
            </div>
            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                htmlFor="dob"
                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Locations
              </label>
              <div className="mt-1 sm:col-span-2 sm:mt-0">
                <select
                  id="location_id"
                  name="location_id"
                  value={values.location_id}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-indigo-500 sm:max-w-xs sm:text-sm"
                >
                  <option value="all">Select a Location</option>

                  {locationList.map((d, idx) => (
                    <option key={idx} value={d.id}>
                      {d.name}
                    </option>
                  ))}
                </select>
                {errors.location_id && touched.location_id ? (
                  <p className="text-red-600 text-sm">{errors.location_id}</p>
                ) : null}
              </div>
            </div>

            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                htmlFor="dob"
                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Gender
              </label>
              <div className="mt-1 sm:col-span-2 sm:mt-0">
                <select
                  id="gender"
                  name="gender"
                  value={values.gender}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-indigo-500 sm:max-w-xs sm:text-sm"
                >
                  <option selected>Select Gender</option>
                  <option value="M">Male</option>
                  <option value="F">Female</option>
                  <option value="O">Others</option>
                  {/* {locationList.map((d, idx) => (
                    <option key={idx} value={d.id}>
                      {d.name}
                    </option>
                  ))} */}
                </select>
                {errors.gender && touched.gender ? (
                  <p className="text-red-600 text-sm">{errors.gender}</p>
                ) : null}
              </div>
            </div>

            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                htmlFor="referral_id"
                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Select a Referral
              </label>
              <div className="mt-1 grid grid-cols-2 sm:col-span-2 sm:mt-0">
                <div className="mt-1 flex  sm:col-span-2 sm:mt-0">
                  <Select
                    options={referralList.map((r) => ({
                      label: r.name,
                      value: r.id,
                    }))}
                    name="referral_id"
                    value={values.referal}
                    onChange={(value, e) => {
                      setValues({
                        ...values,
                        referal: value,
                        referral_id: value.value,
                      });
                    }}
                    className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-[1px] focus:border-indigo-500 sm:max-w-xs sm:text-sm"
                  />
                  <button
                    type="button"
                    onClick={() => {
                      setShowModal(true);
                    }}
                    className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 items-center "
                  >
                    <AiOutlinePlus size={23} />
                  </button>
                </div>
                {errors.referral_id && touched.referral_id ? (
                  <p className="text-red-600 text-sm">{errors.referral_id}</p>
                ) : null}
              </div>
            </div>
          </div>
          <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
            <label
              htmlFor="address"
              className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
            >
              Address
            </label>
            <div className="mt-1 sm:col-span-2 sm:mt-0">
              <textarea
                className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-indigo-500 sm:max-w-xs sm:text-sm"
                type="text"
                name="address"
                id="address"
                placeholder="Address"
                value={values.address}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {errors.address && touched.address ? (
                <p className="text-red-600 text-sm">{errors.address}</p>
              ) : null}
            </div>
          </div>
          <div className="pt-5">
            <div className="flex justify-end">
              <button
                type="submit"
                className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              >
                Submit
              </button>
            </div>
          </div>
        </form>
      )}
    </div>
  );
};

export function AddRefferalModal({ showModal, setShowModal }) {
  const dispatch = useDispatch();
  const { id: patientId } = useParams();
  const initialValues = {
    name: "",
    type: "",
  };
  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: initialValues,
      validationSchema: ReferralSchema,
      onSubmit: (values, action) => {
        //TODO: Optimize code here
        const body = { name: values.name, type: values.type };
        dispatch(addReferral(body));
        action.resetForm();
        setShowModal(false);
      },
    });
  // console.log(errors)

  return (
    <Transition.Root show={showModal} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setShowModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                <div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900"
                    >
                      Add Referral
                    </Dialog.Title>
                    <div className="mt-2  flex items-center justify-center">
                      <form className="w-[80%]" onSubmit={handleSubmit}>
                        <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                          <div className="mb-2 w-full sm:col-span-4 sm:mt-0">
                            <input
                              type="text"
                              name="name"
                              id="name"
                              autoComplete="off"
                              placeholder="Name"
                              value={values.name}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-indigo-500 sm:max-w-xs sm:text-sm"
                            />
                            {errors.name && touched.name ? (
                              <p className="text-red-700">{errors.name}</p>
                            ) : null}
                          </div>
                        </div>
                        <div className="mt-1 w-full sm:col-span-4 sm:mt-0">
                          <select
                            id="type"
                            name="type"
                            value={values.type}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-indigo-500 sm:max-w-xs sm:text-sm"
                          >
                            <option value="">Select a Type</option>
                            <option value="DOCTOR">Doctor</option>
                            <option value="ORGANISATION">Organization</option>
                            <option value="SCHOOL">School Name</option>
                            <option value="COUNSELLER">Counseller</option>
                            <option value="SOCIAL_MEDIA">Social media</option>
                            <option value="WOM">Word of Mouth</option>
                          </select>
                          {errors.type && touched.type ? (
                            <p className="text-red-700">{errors.type}</p>
                          ) : null}
                        </div>

                        <div>
                          <div className="mt-5 sm:mt-6 flex space-x-2">
                            <button
                              type="button"
                              onClick={() => setShowModal(false)}
                              className="inline-flex w-full justify-center rounded-md border border-indigo-200 text-indigo-700 bg-white px-4 py-2 text-base font-medium  shadow-sm  focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm"
                            >
                              close
                            </button>
                            <button
                              type="submit"
                              className="inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm"
                            >
                              Save
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default Addpatients;
