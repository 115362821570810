/* eslint-disable */
import axios from 'axios'
import { getHeaders } from '../auth/actions'
import { toast } from 'react-toast'
import {
  GET_ALL_MCHAT_BEGIN,
  GET_ALL_MCHAT_SUCCESS,
  GET_ALL_MCHAT_ERROR,
  ADD_MCHAT_BEGIN,
  ADD_MCHAT_SUCCESS,
  ADD_MCHAT_ERROR,
  GET_MCHAT_BEGIN,
  GET_MCHAT_SUCCESS,
  GET_MCHAT_ERROR,
  UPDATE_MCHAT_BEGIN,
  UPDATE_MCHAT_SUCCESS,
  UPDATE_MCHAT_ERROR,
  DELETE_MCHAT_BEGIN,
  DELETE_MCHAT_SUCCESS,
  DELETE_MCHAT_ERROR,
  ADD_MCHAT_FORM_BEGIN,
  ADD_MCHAT_FORM_SUCCESS,
  ADD_MCHAT_FORM_ERROR,
} from "./constant";

const API = process.env.REACT_APP_API_URL;

export const getAllMchats = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_ALL_MCHAT_BEGIN,
    });
    const headers = dispatch(getHeaders());
    const { data } = await axios.get(`${API}/api/mchat`, { headers });
    console.log(data.data);

    dispatch({
      type: GET_ALL_MCHAT_SUCCESS,
      data: data.data,
    });
  } catch (error) {
    const msg = error.response.data.message;
    toast.error(msg);
    console.log(error);
    dispatch({
      type: GET_ALL_MCHAT_ERROR,
      error: msg,
    });
  }
};

export const addMchat = (body) => async (dispatch) => {
  try {
    dispatch({
      type: ADD_MCHAT_BEGIN,
    });
    const headers = dispatch(getHeaders());
    const { data } = await axios.post(`${API}/api/mchat`, body, { headers });
    console.log(data.data);
    toast.success("Mchat added successfully!");
    dispatch({
      type: ADD_MCHAT_SUCCESS,
      data: data.data,
    });
  } catch (error) {
    console.log(error.response);
    const msg = error.response.data.message;
    toast.error(msg);
    dispatch({
      type: ADD_MCHAT_ERROR,
      error: msg,
    });
  }
};

export const getMchatWithId = (id) => async (dispatch) => {
  try {
    dispatch({
      type: GET_MCHAT_BEGIN,
    });
    const headers = dispatch(getHeaders());
    const { data } = await axios.get(`${API}/api/mchat/${id}`, { headers });
    console.log(data.data);
    dispatch({
      type: GET_MCHAT_SUCCESS,
      data: data.data,
    });
  } catch (error) {
    console.log(error.response);
    const msg = error.response.data.message;
    toast.error(msg);
    dispatch({
      type: GET_MCHAT_ERROR,
      error: msg,
    });
  }
};

export const updateMchat = (id, body) => async (dispatch) => {
  try {
    dispatch({
      type: UPDATE_MCHAT_BEGIN,
    });
    const headers = dispatch(getHeaders());
    const { data } = await axios.put(`${API}/api/mchat/${id}`, body, {
      headers,
    });
    console.log(data.data);
    toast.success("Mchat updated successfully!");
    dispatch({
      type: UPDATE_MCHAT_SUCCESS,
      data: data.data,
    });
  } catch (error) {
    const msg = error.response.data.message;
    toast.error(msg);
    dispatch({
      type: UPDATE_MCHAT_ERROR,
      error: msg,
    });
  }
};

export const deleteMchat = (id) => async (dispatch) => {
  try {
    dispatch({
      type: DELETE_MCHAT_BEGIN,
    });
    const headers = dispatch(getHeaders());
    const { data } = await axios.delete(`${API}/api/mchat/${id}`, { headers });
    toast.success("Mchat deleted successfully!");
    dispatch({
      type: DELETE_MCHAT_SUCCESS,
      data: data.data,
    });
  } catch (error) {
    const msg = error.response.data.message;
    toast.error(msg);
    dispatch({
      type: DELETE_MCHAT_ERROR,
      error: msg,
    });
  }
};

export const addMchatForm = (body) => async (dispatch) => {
  try {
    dispatch({
      type: ADD_MCHAT_FORM_BEGIN,
    });
    const headers = dispatch(getHeaders());
    const { data } = await axios.post(`${API}/api/mchat`, body, { headers });
    console.log(data.data);
    toast.success("Mchat form submitted successfully!");
    dispatch({
      type: ADD_MCHAT_FORM_SUCCESS,
      data: data.data,
    });
  } catch (error) {
    console.log(error.response);
    const msg = error.response.data.message;
    toast.error(msg);
    dispatch({
      type: ADD_MCHAT_FORM_ERROR,
      error: msg,
    });
  }
};