export const GET_ALL_PROGRAM_BEGIN = "GET_ALL_PROGRAM_BEGIN"
export const GET_ALL_PROGRAM_SUCCESS = "GET_ALL_PROGRAM_SUCCESS"
export const GET_ALL_PROGRAM_ERROR = "GET_ALL_PROGRAM_ERROR"

export const ADD_PROGRAM_BEGIN = "ADD_PROGRAM_BEGIN"
export const ADD_PROGRAM_SUCCESS = "ADD_PROGRAM_SUCCESS"
export const ADD_PROGRAM_ERROR = "ADD_PROGRAM_ERROR"

export const GET_PROGRAM_BEGIN = "GET_PROGRAM_BEGIN"
export const GET_PROGRAM_SUCCESS = "GET_PROGRAM_SUCCESS"
export const GET_PROGRAM_ERROR = "GET_PROGRAM_ERROR"

export const UPDATE_PROGRAM_BEGIN = "UPDATE_PROGRAM_BEGIN"
export const UPDATE_PROGRAM_SUCCESS = "UPDATE_PROGRAM_SUCCESS"
export const UPDATE_PROGRAM_ERROR = "UPDATE_PROGRAM_ERROR"

export const DELETE_PROGRAM_BEGIN = "DELETE_PROGRAM_BEGIN"
export const DELETE_PROGRAM_SUCCESS = "DELETE_PROGRAM_SUCCESS"
export const DELETE_PROGRAM_ERROR = "DELETE_PROGRAM_ERROR"