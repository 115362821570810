import React from "react";

const InputBox = ({
  value,
  handleChange,
  handleBlur,
  placeholder,
  name,
  type,
  required,
  className,
  label,
  ...props
}) => {
  return (
    <>
      {label && (
        <label
          htmlFor={name}
          className="block text-sm font-medium text-gray-700"
        >
          {label}
        </label>
      )}
      <div className={className}>
        <input
          id={name}
          onChange={handleChange}
          onBlur={handleBlur}
          placeholder={placeholder}
          name={name}
          type={type}
          autoComplete
          value={value}
          required={required}
          className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
          {...props}
        />
      </div>
    </>
    // <input
    //   value={value}
    //   onChange={handleChange}
    //   onBlur={handleBlur}
    //   type={type}
    //   placeholder={placeholder}
    //   name={name}
    //   autoComplete="off"
    //   className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-indigo-500 sm:max-w-xs sm:text-sm"
    //   {...props}
    // />
  );
};

export default InputBox;
