import {
  GET_ALL_ROUTE_OF_ADMINISTRATION_BEGIN,
  GET_ALL_ROUTE_OF_ADMINISTRATION_SUCCESS,
  GET_ALL_ROUTE_OF_ADMINISTRATION_ERROR,
  ADD_ROUTE_OF_ADMINISTRATION_BEGIN,
  ADD_ROUTE_OF_ADMINISTRATION_SUCCESS,
  ADD_ROUTE_OF_ADMINISTRATION_ERROR,
  GET_ROUTE_OF_ADMINISTRATION_BEGIN,
  GET_ROUTE_OF_ADMINISTRATION_SUCCESS,
  GET_ROUTE_OF_ADMINISTRATION_ERROR,
  UPDATE_ROUTE_OF_ADMINISTRATION_BEGIN,
  UPDATE_ROUTE_OF_ADMINISTRATION_SUCCESS,
  UPDATE_ROUTE_OF_ADMINISTRATION_ERROR,
  DELETE_ROUTE_OF_ADMINISTRATION_BEGIN,
  DELETE_ROUTE_OF_ADMINISTRATION_SUCCESS,
  DELETE_ROUTE_OF_ADMINISTRATION_ERROR,
} from "./constants";

const initState = {
  list: [],
  loading: false,
  error: null,
};

const RouteOfAdministrationReducer = (state = initState, action) => {
  const { type, data, error } = action;
  switch (type) {
    case GET_ALL_ROUTE_OF_ADMINISTRATION_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case GET_ALL_ROUTE_OF_ADMINISTRATION_SUCCESS:
      return {
        ...state,
        list: data,
        loading: false,
      };
    case GET_ALL_ROUTE_OF_ADMINISTRATION_ERROR:
      return {
        ...state,
        error,
        loading: false,
      };
    case ADD_ROUTE_OF_ADMINISTRATION_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case ADD_ROUTE_OF_ADMINISTRATION_SUCCESS:
      return {
        ...state,
        loading: false,
        list: [...state.list, data],
      };
    case ADD_ROUTE_OF_ADMINISTRATION_ERROR:
      return {
        ...state,
        error,
        loading: false,
      };
    case GET_ROUTE_OF_ADMINISTRATION_BEGIN:
      return {
        ...state,
        loading: true,
        RouteOfAdministrationData: "",
      };
    case GET_ROUTE_OF_ADMINISTRATION_SUCCESS:
      return {
        ...state,
        loading: false,
        RouteOfAdministrationData: data,
      };
    case GET_ROUTE_OF_ADMINISTRATION_ERROR:
      return {
        ...state,
        error,
        loading: false,
      };
    case UPDATE_ROUTE_OF_ADMINISTRATION_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_ROUTE_OF_ADMINISTRATION_SUCCESS:
      return {
        ...state,
        loading: false,
        list: state.list.map((ele) => {
          if (ele.id === data.id) {
            return { ...data };
          } else return ele;
        }),
      };
    case UPDATE_ROUTE_OF_ADMINISTRATION_ERROR:
      return {
        ...state,
        error,
        loading: false,
      };
    case DELETE_ROUTE_OF_ADMINISTRATION_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case DELETE_ROUTE_OF_ADMINISTRATION_SUCCESS:
      return {
        ...state,
        loading: false,
        list: state.list.filter((ele) => ele.id !== data.id),
      };
    case DELETE_ROUTE_OF_ADMINISTRATION_ERROR:
      return {
        ...state,
        error,
        loading: false,
      };
    default:
      return state;
  }
};

export default RouteOfAdministrationReducer;
