import {
    GET_ALL_PATIENTS_FORM_BEGIN,
    GET_ALL_PATIENTS_FORM_SUCCESS,
    GET_ALL_PATIENTS_FORM_ERROR,
    ADD_PATIENTS_FORM_BEGIN,
    ADD_PATIENTS_FORM_SUCCESS,
    ADD_PATIENTS_FORM_ERROR,
    GET_PATIENTS_FORM_BEGIN,
    GET_PATIENTS_FORM_ERROR,
    GET_PATIENTS_FORM_SUCCESS,
    UPDATE_PATIENTS_FORM_BEGIN,
    UPDATE_PATIENTS_FORM_SUCCESS,
    UPDATE_PATIENTS_FORM_ERROR,
    GET_ALL_DOCS_BEGIN,
    GET_ALL_DOCS_SUCCESS,
    GET_ALL_DOCS_ERROR,
} from "./constants";

const initState = {
    list: [],
    Newlist: [],
    docList: [],
    loading: false,
    error: null,
};

const patientFormReducer = (state = initState, action) => {
    const { type, data, error } = action;
    switch (type) {
        case GET_ALL_PATIENTS_FORM_BEGIN:
            return {
                ...state,
                loading: true,
            };
        case GET_ALL_PATIENTS_FORM_SUCCESS:
            return {
                ...state,
                list: data,
                loading: false,
            };
        case GET_ALL_PATIENTS_FORM_ERROR:
            return {
                ...state,
                error,
                loading: false,
            };
        case ADD_PATIENTS_FORM_BEGIN:
            return {
                ...state,
                loading: true,
            };
        case ADD_PATIENTS_FORM_SUCCESS:
            return {
                ...state,
                loading: false,
                list: [...state.list, data],
            };
        case ADD_PATIENTS_FORM_ERROR:
            return {
                ...state,
                error,
                loading: false,
            };
        case GET_PATIENTS_FORM_BEGIN:
            return {
                ...state,
                loading: true,
            };
        case GET_PATIENTS_FORM_SUCCESS:
            return {
                ...state,
                Newlist: data,
                loading: false,
            };
        case GET_PATIENTS_FORM_ERROR:
            return {
                ...state,
                error,
                loading: false,
            };
        case UPDATE_PATIENTS_FORM_BEGIN:
            return {
                ...state,
                loading: true,
            };
        case UPDATE_PATIENTS_FORM_SUCCESS:
            return {
                ...state,
                loading: false,
                list: state.list.map((ele) => {
                    if (ele.id === data.id) {
                        return {...data };
                    } else return ele;
                }),
            };
        case UPDATE_PATIENTS_FORM_ERROR:
            return {
                ...state,
                error,
                loading: false,
            };
        case GET_ALL_DOCS_BEGIN:
            return {
                ...state,
                loading: true,
            };
        case GET_ALL_DOCS_SUCCESS:
            return {
                ...state,
                docList: data,
                loading: false,
            };
        case GET_ALL_DOCS_ERROR:
            return {
                ...state,
                error,
                loading: false,
            };
        default:
            return state;
    }
};

export default patientFormReducer;