import { ArrowUturnLeftIcon } from "@heroicons/react/20/solid";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAllMchats } from "../../../redux/Amcat/action";
import { useState } from "react";
const MchatForm = () => {
  const dispatch = useDispatch();

  const { loading, list: mchatList } = useSelector((state) => state.Mchat);
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(getAllMchats());
  },[]);
  const [inputList, setInputList] = useState([]);
  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    let list = [...inputList];
    list[index][name] = value;
    setInputList(list);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(inputList, "inputList");
  };

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div>
        <button
          type="button"
          onClick={() => navigate(-1)}
          className="inline-flex items-center rounded-md border mb-6 border-transparent bg-indigo-100 px-3 py-2 text-sm font-medium leading-4 text-indigo-700 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
        >
          <span className="w-5 mr-2">
            <ArrowUturnLeftIcon size={18} />
          </span>{" "}
          Back
        </button>
        <div className="sm:flex-auto">
          <h1 className="text-xl font-semibold text-gray-900">Mchat Form</h1>
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        {mchatList.map((item, idx) => (
          <div
            key={item.id}
            className=" mt-2  bg-white px-4 py-1 sm:px-1 rounded-md w-full"
          >
            <p className="text-md font-medium  text-gray-900">
              {item.question}
            </p>
            <div className="mt-5 flex justify-start items-center">
              <div className=" flex items-center  text-sm text-gray-500">
                <div className="flex mr-4">
                  <input
                    type="radio"
                    name={`radio_value_${idx}`}
                    value={item.point_for === "YES" ? 1 : 0}
                    id="yes"
                    onChange={(e) => handleInputChange(e, idx)}
                    className="block  rounded-full  border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  />
                  <label
                    className="ml-3  block text-sm font-medium text-gray-700"
                    htmlFor="yes"
                  >
                    yes
                  </label>
                </div>
                <div className="flex ml-4">
                  <input
                    type="radio"
                    name={`radio_value_${idx}`}
                    value={item.point_for === "NO" ? 1 : 0}
                    id="no"
                    onChange={(e) => handleInputChange(e, idx)}
                    className="block  rounded-full  border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  />
                  <label
                    className="ml-3 block text-sm font-medium text-gray-700"
                    htmlFor="no"
                  >
                    No
                  </label>
                </div>
              </div>
            </div>
          </div>
        ))}
        <button
          type="submit"
          className="mt-4 text-center py-1 px-5 mr-8 mb-8 left-0 rounded-full border text-white bg-indigo-600 border-indigo-600 hover:bg-indigo-700"
        >
          Submit
        </button>
      </form>
    </div>
  );
};

export default MchatForm;
