import { useNavigate } from "react-router-dom";
import React from 'react'
import Breadcrumb from "../../../components/Breadcrumb";
import { ArrowUturnLeftIcon } from "@heroicons/react/20/solid";
import { patient_apointment_columns } from "../../../components/tables/tableheader";
import Table from "../../../components/tables/table";

const patient = [
  {
    firstName: "Nitesh",
    lastName: "Verma",
    doctor: "Ajay Chauhan",
    date: "22/12/2022",
    time: "12:20 PM",
    phone: "7235842003",
    location: "malad"
  },
  {
    firstName: "Ajay",
    lastName: "Chauhan",
    doctor: "Sagar Rasal",
    date: "23/12/2022",
    time: "11:20 PM",
    phone: "3456788765",
    location: "Goregoun"
  },
  {
    firstName: "Priyanshu",
    lastName: "Singh",
    doctor: "Monica thakur",
    date: "12/12/2022",
    time: "12:00 PM",
    phone: "98765434567",
    location: "Andheri"
  },
  {
    firstName: "Awantika",
    lastName: "Jain",
    doctor: "Sudhir Kushwaha",
    date: "24/12/2022",
    time: "01:20 PM",
    phone: "78987654345",
    location: "ghatkoper"
  }
]

function ApointmentList() {
  const pages = [{ title: "Apointment", href: "/patient-apointment" }];
  const navigate = useNavigate();

  return (
    <div>
      <Breadcrumb pages={pages} />
      <div className="px-4 sm:px-6 lg:px-8">
        <div>
          <button
            type="button"
            onClick={() => navigate(-1)}
            className="inline-flex items-center rounded-md border mb-6 border-transparent bg-indigo-100 px-3 py-2 text-sm font-medium leading-4 text-indigo-700 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          >
            <span className="w-5 mr-2"><ArrowUturnLeftIcon size={18} /></span> Back
          </button>
        </div>
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-xl font-semibold text-gray-900">Apointments</h1>
            <p className="mt-1 max-w-2xl text-sm text-gray-500">
              This information will be displayed publicly so be careful what you share.
            </p>
          </div>
        </div>
          <Table
            columns={patient_apointment_columns()}
            data={patient}
            // onEditClose={cleanModalData}
          />
      </div>
    </div>
  )
}

export default ApointmentList