import React, { useEffect, useState } from "react";
import axios from "axios";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import { Recommend } from "@mui/icons-material";
import { getAllMedicineNames } from "../../../redux/MedicineNameMaster/actions";
import {
  getAllDepartmentFilter,
  getQueryDepartmentData,
  sendRecommendationProgram,
} from "../../../redux/patientAccordian/action";
import { FallingLinesLoader } from "../../../components/spinners/Spinner";
import { VscDebugRestart } from "react-icons/vsc";
import { toast } from "react-hot-toast";
import { classNames } from "../../../utilities/classname";
import Select from "react-select";
import { getAllMedicineMasters } from "../../../redux/medicineMaster/actions";
import { findAllByTestId } from "@testing-library/react";
import { getHeaders } from "../../../redux/auth/actions";
import Modal from "../../../components/Modal";
import { capitalizeFirstLetter } from "../../../utilities/utilities";
import { TrashIcon } from "@heroicons/react/24/outline";
import app_api from "../../../utilities/api";
const Opd = ({ goals, locked, g_commnet_department }) => {
  // const [current, setCurrent] = useState(goals?.goals?
  //   Array.isArray(goals.goals)?
  //   goals?.goals?.map((elem) => elem.values.split(",")).flat(10):goals?.goals.values.split(","):[]
  // );
  function replaceComment(array1, array2) {
    if (array2.length === 0) {
      array1.forEach((obj) => {
        delete obj.OPD_Patient_goals_comments;
      });
    } else {
      array2.forEach((item) => {
        const targetId = item.department_id;
        const targetComment = item.comment;

        array1.forEach((obj) => {
          if (
            obj.OPD_Patient_goals_comments &&
            obj.OPD_Patient_goals_comments.length > 0
          ) {
            const commentObj = obj.OPD_Patient_goals_comments.find(
              (comment) => comment.department_id === targetId
            );
            if (commentObj) {
              commentObj.comment = targetComment;
            }
          }
        });
      });
    }

    return array1;
  }
  // ? Same above without error handling Function
  // function replaceComment(array1, array2) {
  //   // Iterate over each department in array2
  //   array2.forEach((item) => {
  //     const targetId = item.department_id;
  //     const targetComment = item.comment;

  //     array1.forEach((obj) => {
  //       if (obj.OPD_Patient_goals_comments && obj.OPD_Patient_goals_comments.length > 0) {
  //         const commentObj = obj.OPD_Patient_goals_comments.find((comment) => comment.department_id === targetId);
  //         if (commentObj) {
  //           commentObj.comment = targetComment;
  //         }
  //       }
  //     });
  //   });

  //   return array1;
  // }
  const { id: goadID } = useParams();
  const [data, setData] = React.useState([]);
  const formData = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/goal/department-wise/all`)
      .then((res) => {
        setData(replaceComment(res.data.data, g_commnet_department));
      });
  };
  const [inputList, setInputList] = useState(
    goals?.goals
      ? Array.isArray(goals.goals)
        ? goals?.goals
        : goals?.goals
      : []
  );

  const [additionalInfo, setAdditionalInfo] = useState([]);
  const handOnChange = (event, fieldName) => {
    const { name, value, checked } = event.target;
    // const existingInput = current

    const existingInput = inputList.find(
      (input) => input.field_name === fieldName
    );
    console.log("EXISTING", existingInput);
    if (existingInput) {
      const newInputList = inputList.map((input) => {
        if (input.field_name === fieldName) {
          return {
            field_name: fieldName,
            values: checked
              ? `${input.values},${value}`
              : input.values.split(",").length > 0
                ? input.values
                  .split(",")
                  .filter((elem) => elem != value)
                  .join(",")
                : "",
            comment: input.comment,
          };
        } else {
          return input;
        }
      });
      console.log("newInputList", existingInput);

      setInputList(newInputList);
    } else {
      console.log("addcurrentvalue", value, fieldName);
      // setCurrent([...current,value]);
      setInputList([
        ...inputList,
        {
          field_name: fieldName,
          values: value,
        },
      ]);
    }
  };

  const handOnChangeComment = (departmentId, comment) => {
    const existingInput = additionalInfo.find(
      (input) => input.department_id === departmentId
    );
    if (existingInput) {
      const newInputList = additionalInfo.map((input) => {
        if (input.department_id === departmentId) {
          return {
            department_id: Number(departmentId),
            comment: comment,
            patient_form_id: Number(goadID),
          };
        } else {
          return input;
        }
      });
      setAdditionalInfo(newInputList);
    } else {
      setAdditionalInfo([
        ...additionalInfo,
        {
          department_id: Number(departmentId),
          comment: comment,
          patient_form_id: Number(goadID),
        },
      ]);
    }
  };

  const addAditionalInfo = async (index) => {
    try {
      console.log(additionalInfo);
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/patient-form/goals-comment-add`,
        additionalInfo[0]
      );
      toast.success("Additional Info Added Successfully");
      // console.log(res.data);
      setAdditionalInfo([]);
    } catch (error) {
      console.log(error);
    }
  };
  const dispatch = useDispatch();
  const handleSubmit = async (e) => {
    const headers = await dispatch(getHeaders());
    e.preventDefault();
    try {
      const body = {
        goals: inputList,
      };
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/patient-form/goal/save/${goadID}`,
        body,
        {
          headers: headers,
        }
      );
      toast.success("Goal Added Successfully");
      // console.log(res.data);
    } catch (error) {
      console.log(error);
    }
  };
  React.useEffect(() => {
    formData();
  }, []);
  const { user } = useSelector((state) => state.auth);

  const handleChangeDisplay = (id, comment) => {
    const updatedData = data.map((element) => {
      if (element.id === id) {
        if (element.OPD_Patient_goals_comments.length === 0) {
          const newComment = {
            id: 0,
            comment: comment,
            patient_form_id: goadID,
            department_id: id,
            createdAt: new Date().toISOString(),
            updatedAt: new Date().toISOString(),
          };
          element.OPD_Patient_goals_comments.push(newComment);
        } else {
          element.OPD_Patient_goals_comments[0].comment = comment;
        }
      }
      return element;
    });
    setData(updatedData);
  };
  return (
    <>
      <form onSubmit={(e) => handleSubmit(e)}>
        <div className="px-4  sm:px-6 lg:px-8">
          {data.map((item, idx) => (
            <form>
              <div key={idx} className="px-4 mt-2 sm:px-6 lg:px-8">
                <p className="text-sm font-semibold mt-2 mb-4 text-gray-500">
                  {item.name}
                </p>
                <div className="flex items-center mt-1  justify-start">
                  <div className=" w-auto  grid grid-cols-2  justify-end">
                    {item &&
                      item &&
                      item.goal &&
                      item.goal.map((opt, idx) => (
                        <div key={idx} className="w-auto ml-6 mr-1 mb-3">
                          {/* {console.log('check for val',inputList,item.name,inputList.filter(elem=>elem.field_name==item.name)[0] )} */}

                          <input
                            id={opt.name}
                            type={`checkbox`}
                            name={item.name}
                            checked={
                              inputList
                                .filter(
                                  (elem) => elem.field_name == item.name
                                )[0]
                                ?.values?.includes(",")
                                ? inputList
                                  .filter(
                                    (elem) => elem.field_name == item.name
                                  )[0]
                                  .values.split(",")
                                  .includes(opt.name)
                                : inputList.filter(
                                  (elem) => elem.field_name == item.name
                                ).length > 0
                                  ? inputList.filter(
                                    (elem) => elem.field_name == item.name
                                  )[0].values === opt.name
                                  : false
                            }
                            value={opt.name}
                            onChange={(e) => {
                              handOnChange(e, item.name);
                            }}
                            className="w-4 h-4 mr-1 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 "
                          />
                          <label
                            htmlFor={opt.name}
                            className="ml-2 text-sm font-medium text-gray-500 "
                          >
                            {opt.name}
                          </label>
                        </div>
                      ))}
                  </div>
                </div>
                <div className="w-full ml-8">
                  <label htmlFor="SuggestionBox">
                    <h1 className="text-lg font-bold">
                      Additional Information
                    </h1>
                  </label>
                  <div className="flex-col items-center w-full justify-start">
                    <textarea
                      className="block w-full max-w rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-indigo-500 sm:max-w-xs sm:text-sm"
                      type="text"
                      name="comment"
                      value={
                        item?.OPD_Patient_goals_comments?.[0]?.comment || ""
                      }
                      onChange={(e) => {
                        handOnChangeComment(item.id, e.target.value);
                        console.log(e.target.value);
                        handleChangeDisplay(item.id, e.target.value);
                      }}
                      autoComplete="off"
                      id="address"
                      rows={6}
                      placeholder="Additional Information"
                    />

                    {user.role !== "CLINICAL_ADMIN" ? (
                      <button
                        type="button"
                        onClick={() => addAditionalInfo(idx)}
                        className="ml-1 mt-3 inline-flex justify-center rounded-md mb-8 border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-"
                      >
                        Additional Info
                      </button>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </form>
          ))}
          <hr className="mt-4 mb-4" />
        </div>
        <div className="w-full flex justify-end  mr-8 mb-4">
          {locked === false ? (
            user.role === "CLINICAL_ADMIN" ? null : (
              <button
                type="SUBMIT"
                className="mt-4 text-center py-1 px-5 mr-8 left-0 rounded-full border text-white bg-indigo-600 border-indigo-600 hover:bg-indigo-700"
              >
                Submit
              </button>
            )
          ) : null}
        </div>
      </form>
    </>
  );
};

export default Opd;

export const Prescription = (props) => {
  const [selected, setSelected] = useState(null);
  const [isHidden, setIsHidden] = useState("Hide");
  const [opt, setopt] = useState("");
  const [body, setBody] = useState({});
  const dispatch = useDispatch();
  const { loading, list: optList } = useSelector((state) => state.medicineName);
  const initialPrevModalState = {
    type: "",
    state: false,
    index: null,
    edit_id: "",
    data: {},
  };
  // const [modal, setModal] = useState(initialModalState);
  const [inputList, setInputList] = useState([
    {
      medicine_name: "",
      formulation: "",
      strength: "",
      route_of_administration: "",
      frequency: "",
      time: "",
      duration: "",
      start_date: "",
      instruction: "",
    },
  ]);
  const { id: presid } = useParams();
  const [showFields, setShowFields] = useState(inputList.map(() => true));
  const handleToggleField = (index) => {
    const newShowFields = [...showFields];
    newShowFields[index] = !newShowFields[index];
    setShowFields(newShowFields);
  };
  const handleAddClick = () => {
    setInputList([
      ...inputList,
      {
        medicine_name: "",
        formulation: "",
        strength: "",
        route_of_administration: "",
        frequency: "",
        time: "",
        duration: "",
        start_date: "",
        instruction: "",
      },
    ]);
    setShowFields([...showFields, true]);
  };

  useEffect(() => {
    setOptList({ ..._optList, medicines: optList });
  }, [optList]);

  const [_optList, setOptList] = useState({
    medicines: optList,
    strengths: [],
    formulations: [],
    routes: [],
  });

  const handleInputChange = async (e, index) => {
    const { name, value } = e.target;
    let list = [...inputList];

    switch (name) {
      case "strength":
        const strengthData = await _optList.strengths.filter(
          (elem) => elem.strength_id == Number(value)
        )[0].strength;
        list[index][name] = strengthData;
        break;

      case "formulation":
        const formulationData = await _optList.formulations.filter(
          (elem) => elem.formulation_id == value
        )[0].formulation;
        list[index][name] = formulationData;
        break;

      case "route_of_administration":
        const routeData = await _optList.routes.filter(
          (elem) => elem.route_of_administration_id == value
        )[0].route_of_administration;
        list[index][name] = routeData;
        break;

      case "medicine_name":
        const medicineData = await _optList.medicines.filter(
          (elem) => elem.id == value
        )[0].name;
        list[index][name] = medicineData;
        break;

      default:
        list[index][name] = value;
        break;
    }

    // remove the item with name "" in list
    list = list.filter((item) => item.name !== "");

    setInputList(list);
  };

  const handleRemoveClick = (index) => {
    const finalList = inputList.filter((ele, i) => i !== index);
    // list.splice(index, 0);
    setInputList([...finalList]);
  };
  useEffect(() => {
    dispatch(getAllMedicineNames());
    // setOptions(optList);01
  }, []);
  const { values, errors, touched, handleBlur, handOnChange, handleSubmit } =
    useFormik({
      onSubmit: (values, action) => {
        prescriptionData();
      },
    });

  const medDetails = async (e, fieldName) => {
    const id = parseInt(e);

    // Destructure the required fields from the body object
    const { medicine_name_id, strength_id } = body;

    let reqBody;
    if (fieldName === "medicine_name_id") {
      reqBody = { medicine_name_id: id };
    } else if (fieldName === "strength_id") {
      reqBody = { medicine_name_id, strength_id, strength_id: id };
    } else if (fieldName === "formulation_id") {
      reqBody = { medicine_name_id, strength_id, formulation_id: id };
    }

    setBody(reqBody);

    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/medicine/master/info/all/filter`,
      reqBody
    );

    setopt(res.data.data);
    setOptList({
      ..._optList,
      strengths: res.data.data.strengths || [],
      formulations: res.data.data.formulation || [],
      routes: res.data.data.route_of_administration || [],
    });
  };

  const handleInputChangem = (selectedOptions, index) => {
    let list = [...inputList];
    const timeValues = selectedOptions
      .map((option) => option.value)
      .filter((value) => value !== ""); // Exclude empty values
    list[index]["time"] = timeValues.join(",");
    setInputList(list);
  };

  const prescriptionData = async (e) => {
    e.preventDefault();
    // console.log(opt);

    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/patient-form/precription/save/${presid}`,
        inputList
      );
      toast.success("Medicine added successfully");
      setInputList([]);
    } catch (error) {
      console.log(error);
    }
  };
  const [prevDataModal, setPrevDataModal] = useState(initialPrevModalState);
  const [infoData, setInfoData] = useState({
    loading: true,
    data: [],
  });
  useEffect(() => { }, [initialPrevModalState.edit_id]);

  const fetchPreviousPresc = async (id) => {
    if (id !== undefined) {
      setInfoData({
        ...infoData,
        loading: true,
      });
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/patient-form/precious-precription/${id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      const { data } = res;
      setInfoData({
        ...infoData,
        data: data.data,
        loading: false,
      });
    }
  };
  const { user } = useSelector((state) => state.auth);
  const preDRenderModal = () => {
    const { type, state, data, edit_id } = prevDataModal;

    const handleDelete = async (id) => {
      try {
        const res = await axios.delete(
          `${process.env.REACT_APP_API_URL}/api/patient-form/prescription/${id}`
        );
        toast.success("Medicine deleted successfully");
        let newData = infoData.data.filter((item) => item.id !== id);
        setInfoData({
          ...infoData,
          data: newData,

          loading: false,
        });
      } catch (error) {
        console.log(error);
      }
    };

    return (
      <Modal
        title={`Preview Previous Prescription`}
        open={state}
        setOpen={() =>
          setPrevDataModal((prev) => ({ ...prev, state: false, edit_id: "" }))
        }
      >
        {infoData.loading ? (
          <FallingLinesLoader />
        ) : (
          infoData.data.length > 0 &&
          infoData.data.map((item, idx) => (
            <div
              key={idx}
              className="bg-white rounded-lg flex flex-col justify-left shadow-md p-6 text-left relative"
              style={{ textAlign: "left" }}
            >
              <button
                onClick={() => {
                  handleDelete(item.id);
                }}
                className="absolute top-0 right-0 p-2 bg-red-500 mt-2 mr-1 text-white rounded-md cursor-pointer "
              >
                delete
              </button>
              <h2 className="text-lg text-left font-medium mb-4">
                <p className="text-gray-600 mb-2">{idx + 1}</p>
                {item?.medicine_name}
              </h2>
              <p className="text-gray-600 mb-2">
                <span className="font-medium">Formulation:</span>{" "}
                {item?.formulation}
              </p>
              <p className="text-gray-600 mb-2">
                <span className="font-medium">Duration:</span> {item?.duration}
              </p>
              <p className="text-gray-600 mb-2">
                <span className="font-medium">Frequency:</span>{" "}
                {item?.frequency}
              </p>
              <p className="text-gray-600 mb-2">
                <span className="font-medium">Strength:</span> {item?.strength}
              </p>
              <p className="text-gray-600 mb-2">
                <span className="font-medium">Start Date:</span>{" "}
                {item?.start_date}
              </p>
              <p className="text-gray-600 mb-2">
                <span className="font-medium">Route of Administration:</span>{" "}
                {item?.route_of_administration}
              </p>
              <p className="text-gray-600 mb-2">
                <span className="font-medium">Time:</span> {item?.time}
              </p>
              <p className="text-gray-600 mb-2">
                <span className="font-medium">Instruction:</span>{" "}
                {item?.instruction}
              </p>
            </div>
          ))
        )}
      </Modal>
    );
  };

  useEffect(() => {
    dispatch(getAllMedicineMasters());
  }, []);

  return loading ? (
    <FallingLinesLoader />
  ) : (
    <div className="px-4 mt-4 sm:px-6 lg:px-8">
      {preDRenderModal()}
      <form
        onSubmit={(e) => prescriptionData(e)}
        className="space-y-8 divide-y divide-gray-200"
      >
        <div className="space-y-6 sm:space-y-5">
          <div className="w-full flex h-[20%] items-center justify-between">
            <button
              type="button"
              className="w-[10%] inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-1 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-"
              onClick={handleAddClick}
            >
              Add more
            </button>

            <button
              type="button"
              className="justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-1 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-"
              onClick={() => {
                fetchPreviousPresc(presid);

                setPrevDataModal((prev) => ({
                  ...prev,
                  state: true,
                }));
              }}
            >
              Previous Prescription
            </button>
          </div>

          <div className="space-y-6 sm:space-y-5 sm:pt-5">
            {/* form starting here */}
            {inputList.map((x, index) => (
              <div key={index} className="space-y-6 sm:space-y-5">
                <span className=" flex w-3/6 items-center justify-between text-md font-semibold text-gray-500 ">
                  <span className="">Medicine: {index + 1}</span>
                  <button
                    className="w-[10%] inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-1 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-"
                    type="button"
                    onClick={() => handleToggleField(index)}
                  >
                    {showFields[index] ? "Hide" : "Show"}
                  </button>
                </span>
                <div className={`${showFields[index] ? "" : "hidden"} `}>
                  <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 0 sm:pt-5">
                    <label
                      htmlFor="medicine_name_id"
                      className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                    >
                      Medicine name
                    </label>
                    <div className="mt-1 sm:col-span-2 sm:mt-0">
                      <select
                        id="medicine_name"
                        name="medicine_name"
                        onChange={(e) => {
                          medDetails(e.target.value, "medicine_name_id");
                          handleInputChange(e, index);
                        }}
                        onBlur={handleBlur}
                        className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-indigo-500 sm:max-w-xs sm:text-sm"
                      >
                        <option value="all">Select a Name</option>

                        {optList &&
                          optList.map((opt, idx) => (
                            <option key={idx} value={opt.id}>
                              {opt.name}
                            </option>
                          ))}
                      </select>
                      {/* {errors.medicine_name_id && touched.medicine_name_id ? (
                      <p className="text-red-700">{errors.medicine_name_id}</p>
                    ) : null} */}
                    </div>
                  </div>
                  <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4  sm:pt-5">
                    <label
                      htmlFor="location_name"
                      className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                    >
                      Strength
                    </label>
                    <div className="mt-1 sm:col-span-2 sm:mt-0">
                      <select
                        id="strength_id"
                        name="strength"
                        onChange={(e) => {
                          medDetails(e.target.value, "strength_id");
                          handleInputChange(e, index);
                        }}
                        className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-indigo-500 sm:max-w-xs sm:text-sm"
                      >
                        {/* Check if the "all" option is present */}
                        {!opt?.strengths?.some(
                          (option) => option.strength_id === "all"
                        ) && <option value="all">Select the Strength</option>}

                        {opt &&
                          opt?.strengths?.map((option, idx) => (
                            // Check if the current option is "all" and set selected
                            // attribute to true if it is
                            <option
                              key={idx}
                              value={option.strength_id}
                              selected={option.strength_id === "all"}
                            >
                              {option.strength}
                            </option>
                          ))}
                      </select>
                    </div>
                  </div>
                  <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4  sm:pt-5">
                    <label
                      htmlFor="location_name"
                      className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                    >
                      Formulation
                    </label>
                    <div className="mt-1 sm:col-span-2 sm:mt-0">
                      <select
                        id="formulation"
                        name="formulation"
                        onChange={(e) => {
                          medDetails(e.target.value, "formulation_id");
                          handleInputChange(e, index);
                        }}
                        className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-indigo-500 sm:max-w-xs sm:text-sm"
                      >
                        {/* Check if the "all" option is present */}
                        {!opt?.formulation?.some(
                          (d) => d.formulation_id === "all"
                        ) && <option value="all">Select a formulation</option>}

                        {opt &&
                          opt?.formulation.map((d, idx) => (
                            // Check if the current option is "all" and set selected
                            // attribute to true if it is
                            <option
                              key={idx}
                              value={d.formulation_id}
                              selected={d.formulation_id === "all"}
                            >
                              {d.formulation}
                            </option>
                          ))}
                      </select>

                      {errors.formulation_id && touched.formulation_id ? (
                        <p className="text-red-700">{errors.formulation_id}</p>
                      ) : null}
                    </div>
                  </div>
                  <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4  sm:pt-5">
                    <label
                      htmlFor="location_name"
                      className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                    >
                      Route of Administration
                    </label>
                    <div className="mt-1 sm:col-span-2 sm:mt-0">
                      <select
                        id="route_of_administration"
                        name="route_of_administration"
                        onChange={(e) => {
                          handleInputChange(e, index);
                        }}
                        className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-indigo-500 sm:max-w-xs sm:text-sm"
                      >
                        {/* Check if the "all" option is present */}
                        {!opt?.route_of_administration?.some(
                          (d) => d.route_of_administration_id === "all"
                        ) && (
                            <option value="all">
                              Select a Route of administration
                            </option>
                          )}

                        {opt &&
                          opt?.route_of_administration.map((d, idx) => (
                            // Check if the current option is "all" and set selected
                            // attribute to true if it is
                            <option
                              key={idx}
                              value={d.route_of_administration_id}
                              selected={d.route_of_administration_id === "all"}
                            >
                              {d.route_of_administration}
                            </option>
                          ))}
                      </select>

                      {errors.route_of_administration_id &&
                        touched.route_of_administration_id ? (
                        <p className="text-red-700">
                          {errors.route_of_administration_id}
                        </p>
                      ) : null}
                    </div>
                  </div>
                  <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4  sm:pt-5">
                    <label
                      htmlFor="location_name"
                      className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                    >
                      Frequency
                    </label>
                    <div className="mt-1 sm:col-span-2 sm:mt-0">
                      <select
                        id="frequency"
                        name="frequency"
                        onChange={(e) => {
                          handleInputChange(e, index);
                        }}
                        // onChange={handOnChange}
                        // onBlur={handleBlur}
                        className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-indigo-500 sm:max-w-xs sm:text-sm"
                      >
                        <option>Select a Frequency</option>
                        <option value="once a day">Once a day</option>
                        <option value="twice a day">Twice a day</option>
                        <option value="thrice a day">Thrice a day</option>
                        <option value="four times a day">
                          Four times a day
                        </option>

                        {/* {options &&
                        options.route_of_administration.map((d, idx) => (
                          <option key={idx} value={d.id}>
                            {d.route_of_administration}
                          </option>
                        ))} */}
                      </select>
                      {errors.route_of_administration_id &&
                        touched.route_of_administration_id ? (
                        <p className="text-red-700">
                          {errors.route_of_administration_id}
                        </p>
                      ) : null}
                    </div>
                  </div>
                  <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4  sm:pt-5">
                    <label
                      htmlFor="location_name"
                      className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                    >
                      Time
                    </label>
                    <div className="mt-1 sm:col-span-2 sm:mt-0">
                      <Select
                        name="time"
                        options={[
                          {
                            value: "Morning-before breakfast",
                            label: "Morning-before breakfast",
                          },
                          {
                            value: "morning-after breakfast",
                            label: "morning-after breakfast",
                          },
                          {
                            value: "afternoon- after meal",
                            label: "afternoon- after meal",
                          },
                          { value: "evening-7pm", label: "evening-7pm" },
                          { value: "before bedtime", label: "before bedtime" },
                        ]}
                        value={x.time
                          .split(",")
                          .map((value) => ({ value, label: value }))}
                        onChange={(selectedOptions) => {
                          handleInputChangem(selectedOptions, index);
                        }}
                        isMulti={true}
                        className="block w-full max-w-lg rounded-md  border-gray-300 shadow-sm focus:border-[1px] focus:border-indigo-500 sm:max-w-xs sm:text-sm"
                      />
                      {errors.route_of_administration_id &&
                        touched.route_of_administration_id ? (
                        <p className="text-red-700">
                          {errors.route_of_administration_id}
                        </p>
                      ) : null}
                    </div>
                  </div>
                  <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4  sm:pt-5">
                    <label
                      htmlFor="location_name"
                      className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                    >
                      Duration
                    </label>
                    <div className="mt-1 sm:col-span-2 sm:mt-0">
                      <select
                        id="duration"
                        name="duration"
                        onChange={(e) => {
                          handleInputChange(e, index);
                        }}
                        // onChange={handOnChange}
                        // onBlur={handleBlur}
                        className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-indigo-500 sm:max-w-xs sm:text-sm"
                      >
                        <option>Select a Duration</option>
                        <option value="30 days">30 days</option>
                        <option value="60 days">60 days</option>
                        <option value="90 Days">90 Days</option>

                        {/* {options &&
                        options.route_of_administration.map((d, idx) => (
                          <option key={idx} value={d.id}>
                            {d.route_of_administration}
                          </option>
                        ))} */}
                      </select>
                      {errors.route_of_administration_id &&
                        touched.route_of_administration_id ? (
                        <p className="text-red-700">
                          {errors.route_of_administration_id}
                        </p>
                      ) : null}
                    </div>
                  </div>
                  <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4  sm:pt-5">
                    <label
                      htmlFor="location_name"
                      className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                    >
                      Start Date
                    </label>
                    <div className="mt-1 sm:col-span-2 sm:mt-0">
                      <input
                        type="date"
                        name="start_date"
                        className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-indigo-500 sm:max-w-xs sm:text-sm"
                        onChange={(e) => {
                          handleInputChange(e, index);
                        }}
                      />
                    </div>
                  </div>
                  <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4  sm:pt-5">
                    <label
                      htmlFor="location_name"
                      className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                    >
                      Instruction
                    </label>
                    <div className="mt-1 sm:col-span-2 sm:mt-0">
                      <textarea
                        type="text"
                        rows="3"
                        name="instruction"
                        placeholder="Instructions"
                        className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-indigo-500 sm:max-w-xs sm:text-sm"
                        onChange={(e) => {
                          handleInputChange(e, index);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>

          <div className="pt-2 mb-3">
            <div className="flex  justify-end">
              {props.locked === false ? (
                user.role === "CLINICAL_ADMIN" ? null : (
                  <button
                    // type="SUBMIT"
                    className="mt-4 text-center py-1 px-5 mr-8 left-0 rounded-full border text-white bg-indigo-600 border-indigo-600 hover:bg-indigo-700"
                  >
                    Submit
                  </button>
                )
              ) : null}
            </div>
          </div>
        </div>
      </form>
      {/* )} */}
    </div>
  );
};

export const OpdRecoomndation = ({
  golden_rules,
  commentData,
  locked,
  fetchData,
}) => {
  // console.log("props", golden_rules);
  const [comment, setComment] = useState("");
  const [suggestedPrograms, setSuggestedPrograms] = useState({
    loading: true,
    data: [],
  });
  async function fetchSuggestedPrograms() {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/program/recommendation/get-previous-program/${id}`
    );
    setSuggestedPrograms({
      data: res.data.data,
      loading: false,
    });
  }
  useEffect(() => {
    fetchSuggestedPrograms();
  }, []);
  const [rules, setrules] = useState(
    golden_rules?.golden_rules?.length > 0 &&
      golden_rules?.golden_rules?.includes(",")
      ? golden_rules?.golden_rules?.split(",")
      : [golden_rules?.golden_rules]
  );
  // const [rules, setrules] = useState(golden_rules?.golden_rules?.split(','));
  const [selected_filters, setSelectedFilters] = useState([]);
  const [res, setres] = useState("");
  const dispatch = useDispatch();
  const { id } = useParams();
  const programId = parseInt(id);
  const { loading, list: recommendationList } = useSelector(
    (state) => state.departmentFilter
  );

  useEffect(() => {
    dispatch(getAllDepartmentFilter());
  }, []);

  // create a reset function to reset all the dropdowns
  const reset = () => {
    dispatch(getAllDepartmentFilter());
    setSelectedFilters([]);
    setres("");
  };

  const handleFilterChange = (new_filter, selected_option) => {
    console.log({ new_filter, selected_option });

    if (selected_filters.length) {
      const is_department_present = selected_filters.find(
        (e) => e.department_id === new_filter.department_id
      );

      if (is_department_present) {
        is_department_present.selected_option = selected_option;
      } else {
        new_filter.selected_option = selected_option;
        setSelectedFilters([...selected_filters, new_filter]);
      }
    } else {
      new_filter.selected_option = selected_option;
      setSelectedFilters([new_filter]);
    }

    let filters = selected_filters.length
      ? [...selected_filters, new_filter]
      : [new_filter];

    console.log("here" + { selected_filters });
    dispatch(getQueryDepartmentData(filters));
  };
  const { user } = useSelector((state) => state.auth);
  console.log(user.id);
  const sendData = async () => {
    const data = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/program/recommendation/get-program/${id}`, // TODO: make this dynamic
      selected_filters
    );
    console.log(data);
    await setres(data.data.data);
  };
  // Patient-recommandation

  const filteredDepartments = recommendationList.filter(
    (department) => department.patient_form_id !== programId
  );

  const [inputList, setInputList] = useState([
    {
      values: rules,
    },
  ]);
  const [goldenRules, setGoldenRules] = useState("");
  const handOnChange = (event) => {
    const { name, value, checked } = event.target;
    const existingInput = inputList[0];

    if (existingInput) {
      var newValues = existingInput.values.includes(",")
        ? existingInput.values.split(",")
        : existingInput.values;

      if (checked) {
        if (!newValues.includes(value)) {
          newValues.push(value);
          setrules(newValues);
        }
      } else {
        const index = newValues.indexOf(value);
        if (index > -1) {
          newValues = newValues.map((val) => {
            if (val != value) {
              return val;
            }
          });
        }
      }
      const newInputList = [{ values: newValues.join(",") }];
      setrules(newValues);
      setInputList(newInputList);
    } else {
      setInputList([
        {
          values: value,
        },
      ]);
    }
  };
  async function addComment(payload) {
    const headers = dispatch(getHeaders());
    try {
      const data = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/program/patient-recommendation/add-comment/${id}`,
        payload,
        headers
      );
      fetchData();
      if (data.data) toast.success("Comment Saved Successfully");
    } catch (error) {
      toast.error("Something went wrong");
    }
  }
  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: {
        comment: commentData?.comments,
      },
      enableReinitialize: true,
      onSubmit: (values, action) => {
        //TODO: Optimize code here
        const body = {
          comments: values.comment,
        };
        addComment(body);
      },
    });

  const handleSubmitGoldenRules = async (e) => {
    e.preventDefault();
    const body = {
      golden_rules: inputList[0].values,
    };
    // console.log(body);
    try {
      const data = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/patient-form/golden-rules/save/${id}`,
        body
      );
      toast.success("Golden Rules Saved Successfully");
    } catch (error) {
      toast.error("Something went wrong");
    }
  };
  console.log();

  const initialPrevRecomModalState = {
    type: "",
    state: false,
    index: null,
    edit_id: "",
    data: {},
  };
  const [prevDataModal, setPrevDataModal] = useState(
    initialPrevRecomModalState
  );
  const [infoData, setInfoData] = useState({
    loading: true,
    data: [],
  });

  const fetchPreviousPresc = async (id) => {
    if (id !== undefined) {
      setInfoData({
        ...infoData,
        loading: true,
      });
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/program/recommendation/get-previous-program/${id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      const { data } = res;
      setInfoData({
        ...infoData,
        data: data.data,
        loading: false,
      });
    }
  };
  // const { user } = useSelector((state) => state.auth);
  const preDRenderModal = () => {
    const { type, state, data, edit_id } = prevDataModal;

    return (
      <Modal
        title={`Preview Previous Recommendation`}
        open={state}
        setOpen={() =>
          setPrevDataModal((prev) => ({ ...prev, state: false, edit_id: "" }))
        }
      >
        {infoData.loading ? (
          <FallingLinesLoader />
        ) : (
          infoData.data.length > 0 &&
          infoData.data.map((item, idx) =>
            item.map((item, idx) => (
              <div className="bg-white rounded-lg shadow-md p-6">
                <h2 className="text-lg text-left font-medium mb-4">
                  Program Name : {item.program_name}
                </h2>
                {item.sessions.map((department, idx) => (
                  <div key={idx} className="mb-2">
                    <p className="text-gray-600 text-left font-medium">
                      Department: {department?.department_name}
                    </p>
                    <p className="text-gray-600 text-left">
                      Total session: {department.total_sessions} sessions
                    </p>
                  </div>
                ))}
              </div>
            ))
          )
        )}
      </Modal>
    );
  };
  const sendRecommendation = async (id) => {
    try {
      const body = {
        program_id: id,
        patient_form_id: programId,
        doctor_id: user.id,
      };
      dispatch(sendRecommendationProgram(body));
    } catch (error) {
      console.log(error);
    } finally {
      setTimeout(() => {
        fetchSuggestedPrograms();
      }, 2000);
    }
  };
  return (
    <div className="mt-4 ">
      <hr className="" />
      {preDRenderModal()}

      <div className="mx-auto w-[95%]">
        <div className="w-full flex justify-end">
          <button
            type="button"
            className="justify-right mt-2 rounded-md border border-transparent bg-indigo-600 py-2 px-1 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-"
            onClick={() => {
              fetchPreviousPresc(programId);
              setPrevDataModal((prev) => ({
                ...prev,
                state: true,
              }));
            }}
          >
            Previous Recommendation
          </button>
        </div>
        <form onSubmit={(e) => handleSubmitGoldenRules(e)}>
          <div className=" border-t h-0 mt-6 flex items-center justify-center border-gray-700">
            <span className="relative top-0 bg-white px-2 py-2 text-lg font-semibold text-gray-700 ">
              6 Golden Rule
            </span>
          </div>
          <div className=" h-max mb-4">
            <h3 className="text-gray-600 mt-6 mb-4">6 Golden Rules :</h3>
            <div className="ml-4 space-y-2 mt-2">
              <div className="relative flex items-start">
                <div className="flex h-5 items-center">
                  {console.log(
                    rules,
                    rules[1]?.length,
                    "SCREENS - NO EXPOSURE TO ELECTRONIC MEDIA/ ANIMATION / VIDEO OR MOBILE GAMES/ MOBILE PHONES/ ELECTRONIC GADGETS."
                      .length
                  )}
                  <input
                    id="comments1"
                    aria-describedby="comments-description"
                    name="comments1"
                    onChange={(e) => {
                      handOnChange(e);
                    }}
                    checked={rules.includes(
                      "SCREENS - NO EXPOSURE TO ELECTRONIC MEDIA/ ANIMATION / VIDEO OR MOBILE GAMES/ MOBILE PHONES/ ELECTRONIC GADGETS."
                    )}
                    value="SCREENS - NO EXPOSURE TO ELECTRONIC MEDIA/ ANIMATION / VIDEO OR MOBILE GAMES/ MOBILE PHONES/ ELECTRONIC GADGETS."
                    type="checkbox"
                    className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                  />
                </div>

                <div className="ml-3 text-sm">
                  <label
                    htmlFor="comments1"
                    className="text-sm font-semibold text-gray-500"
                  >
                    {capitalizeFirstLetter(
                      "SCREENS - NO EXPOSURE TO ELECTRONIC MEDIA/ ANIMATION / VIDEO OR MOBILE GAMES/ MOBILE PHONES/ ELECTRONIC GADGETS."
                    )}
                  </label>
                </div>
              </div>
              <div className="relative flex items-start">
                <div className="flex h-5 items-center">
                  <input
                    id="comments2"
                    aria-describedby="comments-description"
                    name="comments2"
                    type="checkbox"
                    checked={rules.includes(
                      "DIET-NO FOOD WITH PRESERVATIVES / NO CHOCOLATES/ PACKAGED FOOD/ PACKAGED DRINKS"
                    )}
                    onChange={(e) => {
                      handOnChange(e);
                    }}
                    value="DIET-NO FOOD WITH PRESERVATIVES / NO CHOCOLATES/ PACKAGED FOOD/ PACKAGED DRINKS"
                    className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                  />
                </div>
                <div className="ml-3 text-sm">
                  <label
                    htmlFor="comments2"
                    className="text-sm font-semibold text-gray-500"
                  >
                    {capitalizeFirstLetter(
                      "DIET-NO FOOD WITH PRESERVATIVES / NO CHOCOLATES/ PACKAGED FOOD/ PACKAGED DRINKS"
                    )}
                  </label>
                </div>
              </div>
              <div className="relative flex items-start">
                <div className="flex h-5 items-center">
                  <input
                    id="comments3"
                    aria-describedby="comments-description"
                    name="comments3"
                    checked={rules.includes(
                      "PLAY- INCREASE PHYSICAL ACTIVITY/ OUTDOOR GAMES/ UNSTRUCTURED PLAY FOR 2 HOURS DAILY IN THE EVENING."
                    )}
                    onChange={(e) => {
                      handOnChange(e);
                    }}
                    value="PLAY- INCREASE PHYSICAL ACTIVITY/ OUTDOOR GAMES/ UNSTRUCTURED PLAY FOR 2 HOURS DAILY IN THE EVENING."
                    type="checkbox"
                    className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                  />
                </div>
                <div className="ml-3 text-sm">
                  <label
                    htmlFor="comments3"
                    className="text-sm font-semibold text-gray-500"
                  >
                    {capitalizeFirstLetter(
                      "PLAY- INCREASE PHYSICAL ACTIVITY/ OUTDOOR GAMES/UNSTRUCTURED PLAY FOR 2 HOURS DAILY IN THE EVENING."
                    )}
                  </label>
                </div>
              </div>
              <div className="relative flex items-start">
                <div className="flex h-5 items-center">
                  <input
                    id="comments4"
                    aria-describedby="comments-description"
                    name="comments4"
                    checked={rules.includes(
                      "SLEEP- NIGHT SLEEP AT A STRETCH FOR 10-12 HOURS DAILY (DEPENDING ON THE CHILD'S AGE). NO DAYTIME NAPS."
                    )}
                    onChange={(e) => {
                      handOnChange(e);
                    }}
                    value="SLEEP- NIGHT SLEEP AT A STRETCH FOR 10-12 HOURS DAILY (DEPENDING ON THE CHILD'S AGE). NO DAYTIME NAPS."
                    type="checkbox"
                    className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                  />
                </div>
                <div className="ml-3">
                  <label
                    htmlFor="comments4"
                    className="text-sm font-semibold text-gray-500"
                  >
                    {capitalizeFirstLetter(
                      "SLEEP- NIGHT SLEEP AT A STRETCH FOR 10-12 HOURS DAILY (DEPENDING ON THE CHILD'S AGE). NO DAYTIME NAPS."
                    )}
                  </label>
                </div>
              </div>
              <div className="relative flex items-start">
                <div className="flex h-5 items-center">
                  <input
                    id="comments5"
                    aria-describedby="comments-description"
                    name="comments5"
                    checked={rules.includes(
                      "ACADEMICS- NO ACADEMICS AFTER 6 PM"
                    )}
                    onChange={(e) => {
                      handOnChange(e);
                    }}
                    value="ACADEMICS- NO ACADEMICS AFTER 6 PM"
                    type="checkbox"
                    className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                  />
                </div>
                <div className="ml-3 text-sm">
                  <label
                    htmlFor="comments5"
                    className="text-sm font-semibold text-gray-500"
                  >
                    {capitalizeFirstLetter(
                      "ACADEMICS- NO ACADEMICS AFTER 6 PM"
                    )}
                  </label>
                </div>
              </div>
              <div className="relative flex items-start">
                <div className="flex h-5 items-center">
                  <input
                    id="comments6"
                    aria-describedby="comments-description"
                    name="comments6"
                    checked={rules.includes(
                      "DAILY- MAINTAIN A RECORD OF CHILD'S DAILY IMPROVEMENTS AND CONCERNS"
                    )}
                    onChange={(e) => {
                      handOnChange(e);
                    }}
                    value="DAILY- MAINTAIN A RECORD OF CHILD'S DAILY IMPROVEMENTS AND CONCERNS"
                    type="checkbox"
                    className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                  />
                </div>
                <div className="ml-3 text-sm">
                  <label
                    htmlFor="comments6"
                    className="text-sm font-semibold text-gray-500"
                  >
                    {capitalizeFirstLetter(
                      "DAILY- MAINTAIN A RECORD OF CHILD'S DAILY IMPROVEMENTS AND CONCERNS"
                    )}
                  </label>
                </div>
              </div>
              <div className="w-full flex justify-end h-max mt-3 items-center ">
                {locked === false ? (
                  user.role === "CLINICAL_ADMIN" ? null : (
                    <button
                      type="SUBMIT"
                      className="mt-4 text-center py-1 px-5 mr-8 left-0 rounded-full border text-white bg-indigo-600 border-indigo-600 hover:bg-indigo-700"
                    >
                      Submit
                    </button>
                  )
                ) : null}
              </div>
            </div>
            <hr className="mt-4 mb-4" />
          </div>
        </form>
      </div>
      <div className="mt-1  gap-1 w-[97%]  mx-auto grid grid-cols-9 sm:col-span-2 sm:mt-0">
        {loading ? (
          <FallingLinesLoader />
        ) : (
          filteredDepartments.map((item, index) => (
            <div className="w-[95%] md:w-[94%]" key={index + 1}>
              <label
                htmlFor="location"
                className="block text-sm font-medium text-gray-700"
              >
                {item.department_name}
              </label>
              <select
                id="location"
                name="location"
                className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                onChange={(e) => handleFilterChange(item, e.target.value)}
              >
                <option>Select</option>
                {item.department_frequency_options &&
                  item.department_frequency_options.map((opt, index) => {
                    // const fopt = opt : opt



                    return (
                      (
                        <option
                          key={index + 1}
                          selected={item.selected_option === opt}
                          value={opt}
                        >
                          {opt?.includes("_") ? opt.split("_").join(" ")?.replace("THREE", "3") : opt}
                        </option>
                      )
                    )
                  })}
              </select>
            </div>
          ))
        )}

        <div className="flex w-max items-end justify-around">
          <button
            type="reset"
            className="inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-[11px] px-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-"
            onClick={() => reset()}
          >
            <VscDebugRestart className="text-white" />
          </button>
          {user.role !== "CLINICAL_ADMIN" ? (
            <button
              type="button"
              className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-1 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-"
              onClick={sendData}
            >
              Search
            </button>
          ) : (
            ""
          )}
        </div>
        {/* )} */}
      </div>
      {/* </div> */}

      {res && res ? (
        res.map((element) => {
          console.log({ element });
          return (
            <div className="bg-white border-solid border ml-2 mt-3 mb-5 w-fit border-gray-200  h-auto rounded-md">
              <div className="block text-lg mb-3 font-bold border-b border-gray-200 bg-gray-50 px-4 py-4 sm:px-6">
                <h1 className="hover:underline text-gray-900 text-lg font-medium leading-6">
                  Recommendation
                </h1>
              </div>
              <div className="text-sm text-gray-500">
                <div className="px-4 py-5 sm:px-6">
                  <h4 className="px-2">Name : {element?.program_name}</h4>
                  <div className="flex flex-row w-fi">
                    {element.sessions.map((e) => {
                      return (
                        <div className="px-2">
                          <p>
                            <b>Department Name: </b>
                            {e.department_name}
                          </p>
                          {element?.program_name === "E-nable" ? (
                            <p>
                              Total Sessions: 9 E-nable calls, 3 OPD's, 1
                              Parental webinar
                            </p>
                          ) : (
                            <p>Total Sessions: {e.total_sessions}</p>
                          )}
                          {/* <p>Total Session: {e.total_sessions}</p> */}
                        </div>
                      );
                    })}
                  </div>
                  {user.role !== "CLINICAL_ADMIN" ? (
                    <button
                      className="ml-1 mt-3 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-"
                      onClick={() => sendRecommendation(element.program_id)}
                    >
                      Recommend Program
                    </button>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          );
        })
      ) : (
        <div className="bg-white border-solid border ml-2 mt-3 mb-5 w-2/6 border-gray-200  h-48 rounded-md">
          <div className="block text-lg mb-3 font-bold border-b border-gray-200 bg-gray-50 px-4 py-4 sm:px-6">
            <h1 className="hover:underline text-gray-900 text-lg font-medium leading-6">
              Recommendation
            </h1>
          </div>
          <div className="text-sm text-gray-500">Select filter</div>
        </div>
      )}
      {suggestedPrograms.data.length > 0 && (
        <div className="border rounded-md border-gray-200 mt-4 p-4 w-max">
          {suggestedPrograms.loading ? (
            <FallingLinesLoader />
          ) : (
            suggestedPrograms.data.length > 0 &&
            suggestedPrograms.data.map((item, idx) =>
              item.map((item, idx) => (
                <div className="bg-white rounded-lg p-6 relative">
                  <h2 className="text-lg text-left font-medium mb-4">
                    Program Name : {item.program_name}
                  </h2>
                  {item.sessions.map((department, idx) => (
                    <div key={idx} className="mb-2">
                      <p className="text-gray-600 text-left font-medium">
                        Department: {department?.department_name}
                      </p>
                      <p className="text-gray-600 text-left">
                        Total session: {department.total_sessions} sessions
                      </p>
                    </div>
                  ))}
                  <TrashIcon
                    onClick={async () => {
                      //confirm box to delete
                      //if yes
                      //delete
                      //else
                      //do nothing
                      const confirm = window.confirm(
                        "Are you sure? you want to remove this program"
                      );
                      if (confirm) {
                        app_api
                          .delete(
                            `/api/program/recommendation/remove/${item.recommendation_id}`
                          )
                          .then((res) => {
                            if (res.status) {
                              fetchSuggestedPrograms();
                              toast.success("Program removed successfully");
                            }
                          })
                          .catch((err) => {
                            toast.error(
                              "This program might be in used in Enrollment"
                            );
                          })
                          .finally(() => {
                            fetchSuggestedPrograms();
                          });
                      }
                    }}
                    className="w-6 h-6 text-red-600 absolute bottom-0 right-0 cursor-pointer"
                  />
                </div>
              ))
            )
          )}
        </div>
      )}
      <div className="w-full ml-2 mt-2 mb-2">
        <label htmlFor="SuggestionBox">
          <h1 className="text-lg font-bold">Suggestion</h1>
        </label>
        <form
          onSubmit={handleSubmit}
          className="flex-col items-center justify-start"
        >
          <textarea
            className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-indigo-500 sm:max-w-xs sm:text-sm"
            type="text"
            name="comment"
            value={values.comment}
            onChange={handleChange}
            autoComplete="off"
            id="address"
            rows={6}
            placeholder="Suggestion"
          />
          {user.role !== "CLINICAL_ADMIN" ? (
            <button
              type="submit"
              className="ml-1 mt-3 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-"
            >
              Add Suggestion
            </button>
          ) : (
            ""
          )}
        </form>
      </div>
      <hr />
    </div>
  );
};
