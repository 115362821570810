import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import { programSchema } from "../../../schemas";
import { ArrowUturnLeftIcon } from "@heroicons/react/20/solid";
import { useDispatch, useSelector } from "react-redux";
import {
  addProgram,
  getProgramWithId,
  updateProgram,
} from "../../../redux/ProgramMaster/actions";
import { getAllDepartments } from "../../../redux/department/actions";
import { FallingLinesLoader } from "../../../components/spinners/Spinner";
import Breadcrumb from "../../../components/Breadcrumb";
const AddProgram = () => {
  const pages = [{ title: "Program", href: "/program-master" }];

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { id: programId } = useParams();
  const { loading, ProgramData } = useSelector((state) => state.programMaster);
  const { list: departmentList } = useSelector((state) => state.department);
  const initialValues = {
    name: "",
    program_department_master: [],
  };

  const [formState, setFormState] = useState(initialValues);
  const [inputList, setInputList] = useState([]);
  const handleAddClick = () => {
    setInputList([
      ...inputList,
      { department_id: "", frequency: "", frequency_type: "", display_name: "" },
    ]);
  };
  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    console.log(e.target.name, e.target.value, "e.target");
    let list = [...inputList];
    if (name === "frequency_type" || name === "display_name") {
      list[index][name] = value;
    }
    else {
      list[index][name] = parseInt(value);
    }
    console.log({ index, name });

    console.log({ list });
    setInputList(list);
  };

  const handleRemoveClick = (index) => {
    let list = inputList;
    const finalList = inputList.filter((ele, i) => i !== index);
    console.log(finalList, "finalList");
    // list.splice(index, 0);
    setInputList([...finalList]);
  };
  useEffect(() => {
    document.title = "New Horizon | Add-Program";
    dispatch(getAllDepartments());
    if (programId) dispatch(getProgramWithId(programId));
  }, [programId]);

  useEffect(() => {
    if (programId && ProgramData) {
      const newFormState = {
        name: ProgramData.name,
        program_department_master: ProgramData.programDepartmentMaster,
      };
      setInputList(ProgramData.programDepartmentMaster);
      console.log(ProgramData.programDepartmentMaster);
      setFormState(newFormState);
    }
  }, [programId, ProgramData]);

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      enableReinitialize: true,
      initialValues: formState,
      validationSchema: programSchema,
      onSubmit: (values, action) => {
        // TODO: Optimize code here
        const body = {
          name: values.name,
          program_department_master: inputList,
        };
        if (programId) dispatch(updateProgram(programId, body));
        else dispatch(addProgram(body));
        action.resetForm();
        navigate("/program-master");
      },
    });
  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <Breadcrumb pages={pages} />
      <div>
        <button
          type="button"
          onClick={() => navigate(-1)}
          className="inline-flex items-center rounded-md border mb-6 border-transparent bg-indigo-100 px-3 py-2 text-sm font-medium leading-4 text-indigo-700 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
        >
          <span className="w-5 mr-2">
            <ArrowUturnLeftIcon size={18} />
          </span>{" "}
          Back
        </button>
        <div className="sm:flex-auto">
          <h1 className="text-xl font-semibold text-gray-900">
            {programId ? "Update Program" : "Add Program"}
          </h1>
        </div>
      </div>
      {loading ? (
        <FallingLinesLoader />
      ) : (
        <form
          onSubmit={handleSubmit}
          className="space-y-8 divide-y divide-gray-200"
        >
          <div className="space-y-6 sm:space-y-5">
            <div className="space-y-6 sm:space-y-5 sm:pt-5">
              <div className="space-y-6 sm:space-y-5">
                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                  <label
                    htmlFor="name"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Name
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <input
                      type="text"
                      name="name"
                      placeholder="Name"
                      id="name"
                      autoComplete="off"
                      value={values.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                    />
                    {errors.name && touched.name ? (
                      <p className="text-red-700">{errors.name}</p>
                    ) : null}
                  </div>
                </div>
                {inputList.map((x, index) => (
                  <div
                    key={index}
                    className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5"
                  >
                    <select
                      id="department_id"
                      name="department_id"
                      value={x.department_id}
                      onChange={(e) => handleInputChange(e, index)}
                      onBlur={handleBlur}
                      className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-indigo-500 sm:max-w-xs sm:text-sm"
                    >
                      <option value="all">Select a Department</option>

                      {departmentList.map((d, idx) => (
                        <option key={idx} value={d.id}>
                          {d.name}
                        </option>
                      ))}
                    </select>
                    <div className="mt-1 w-full flex sm:col-span-2 sm:mt-0">
                      <input
                        type="number"
                        name="frequency"
                        id="frequency"
                        value={x.frequency}
                        placeholder="Frequency"
                        autoComplete="off"
                        onChange={(e) => handleInputChange(e, index)}
                        onBlur={handleBlur}
                        className="block w-full mr-8 max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                      />
                      {/* {errors.cost && touched.cost ? (
                          <p className="text-red-700">{errors.cost}</p>
                        ) : null} */}

                      {/* {inputList.length > 1 && ( */}
                      <select
                        id="frequency_type"
                        name="frequency_type"
                        value={x.frequency_type}
                        onChange={(e) => handleInputChange(e, index)}
                        onBlur={handleBlur}
                        className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-indigo-500 sm:max-w-xs sm:text-sm"
                      >
                        <option>Select a frequency type</option>
                        <option value="WEEK">Week</option>
                        <option value="MONTH">Month</option>
                        <option value="DAYS">Days</option>
                        <option value="IN_THREE_MONTHS">In 3 months</option>
                      </select>

                      <input
                        type="text"
                        name="display_name"
                        id="display_name"
                        value={x.display_name}
                        placeholder="Display Name"
                        autoComplete="off"
                        onChange={(e) => handleInputChange(e, index)}
                        onBlur={handleBlur}
                        className="ml-2 block w-full mr-8 max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                      />
                      <button
                        type="button"
                        className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-"
                        onClick={() => handleRemoveClick(index)}
                      >
                        Remove
                      </button>
                      {/* )} */}
                    </div>
                  </div>
                ))}
                <button
                  type="button"
                  className="w-1/3 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-"
                  onClick={handleAddClick}
                >
                  Add more
                </button>
              </div>
            </div>
          </div>

          <div className="pt-5">
            <div className="flex justify-end">
              <button
                type="submit"
                className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              >
                Submit
              </button>
            </div>
          </div>
        </form>
      )}
    </div>
  );
};

export default AddProgram;
