import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "../../../components/Pagination";
import { FallingLinesLoader } from "../../../components/spinners/Spinner";
import Breadcrumb from "../../../components/Breadcrumb";
import ConfirmationModal from "../../../components/ConfirmationModal";
import {
  deleteMedicineName,
  getAllMedicineNames,
} from "../../../redux/MedicineNameMaster/actions";
import Table from "../../../components/tables/table";
import { medicineName_master } from "../../../components/tables/tableheader";

const MedicineName = () => {
  const pages = [{ title: "Medicine Name", href: "/medicine-name-master" }];
  const [confirmationModal, setConfirmationModal] = useState({
    status: false,
    id: null,
  });
  const dispatch = useDispatch();
  const { list: nameList, loading } = useSelector(
    (state) => state.medicineName
  );

  useEffect(() => {
    dispatch(getAllMedicineNames());
    document.title = "New Horizon | Medicine Name Master";
  }, []);

  const onDeleteOpen = (id) => {
    setConfirmationModal({ id, status: true });
  };

  const onDeleteMedicineName = (id) => {
    dispatch(deleteMedicineName(id));
    setConfirmationModal({ id: null, status: false });
  };
  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <ConfirmationModal
        open={confirmationModal.status}
        setOpen={setConfirmationModal}
        onDelete={() => onDeleteMedicineName(confirmationModal.id)}
      />
      <Breadcrumb pages={pages} />
      <div className="sm:flex sm:items-center mb-5">
        <div className="sm:flex-auto">
          <h1 className="text-xl font-semibold text-gray-900">
            Medicine Name Master
          </h1>
        </div>
        <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
          <Link
            to="/add-medicine-name"
            type="button"
            className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
          >
            Add Medicine name
          </Link>
        </div>
      </div>
      {/* {loading ? ( */}
      {/* <FallingLinesLoader /> */}
      {/* ) : ( */}
      <Table
        columns={medicineName_master({ onDeleteOpen })}
        data={nameList}
        // onEditClose={cleanModalData}
      />
      {/* )} */}
    </div>
  );
};

export default MedicineName;
