export const GET_ALL_PAYMENT_BEGIN = "GET_ALL_PAYMENT_BEGIN"
export const GET_ALL_PAYMENT_SUCCESS = "GET_ALL_PAYMENT_SUCCESS"
export const GET_ALL_PAYMENT_ERROR = "GET_ALL_PAYMENT_ERROR"

export const SEND_PAYMENT_LINK_BEGIN = "SEND_PAYMENT_LINK_BEGIN"
export const SEND_PAYMENT_LINK_SUCCESS = "SEND_PAYMENT_LINK_SUCCESS"
export const SEND_PAYMENT_LINK_ERROR = "SEND_PAYMENT_LINK_ERROR"

export const MARK_PAYMENT_PAID_BEGIN = "MARK_PAYMENT_PAID_BEGIN"
export const MARK_PAYMENT_PAID_SUCCESS = "MARK_PAYMENT_PAID_SUCCESS"
export const MARK_PAYMENT_PAID_ERROR = "MARK_PAYMENT_PAID_ERROR"

export const SEND_INVOICE_LINK_BEGIN = "SEND_INVOICE_LINK_BEGIN"
export const SEND_INVOICE_LINK_SUCCESS = "SEND_INVOICE_LINK_SUCCESS"
export const SEND_INVOICE_LINK_ERROR = "SEND_INVOICE_LINK_ERROR"