/* eslint-disable */
import axios from "axios";
import { getHeaders } from "../auth/actions";
import { toast } from "react-hot-toast";
import {
  GET_ALL_DEPARTMENT_FILTER_BEGIN,
  GET_ALL_DEPARTMENT_FILTER_SUCCESS,
  GET_ALL_DEPARTMENT_FILTER_ERROR,
  RECOMMEND_PROGRAM_BEGIN,
  RECOMMEND_PROGRAM_SUCCESS,
  RECOMMEND_PROGRAM_ERROR,
} from "./constants";

const API = process.env.REACT_APP_API_URL;

export const getAllDepartmentFilter = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_ALL_DEPARTMENT_FILTER_BEGIN,
    });
    const headers = dispatch(getHeaders());
    const { data } = await axios.get(
      `${API}/api/program/recommendation/department-options`,
      { headers }
    );
    console.log(data.data);

    dispatch({
      type: GET_ALL_DEPARTMENT_FILTER_SUCCESS,
      data: data.data,
    });
  } catch (error) {
    const msg = error.response.data.message;
    toast.error(msg);
    console.log(error);
    dispatch({
      type: GET_ALL_DEPARTMENT_FILTER_ERROR,
      error: msg,
    });
  }
};

export const getQueryDepartmentData = (query) => async (dispatch) => {
  try {
    // dispatch({
    //   type: GET_ALL_DEPARTMENT_FILTER_BEGIN,
    // });
    const headers = dispatch(getHeaders());
    const { data } = await axios.post(
      `${API}/api/program/recommendation/department-options/search`,
      query,
      { headers }
    );
    console.log(data.data);

    dispatch({
      type: GET_ALL_DEPARTMENT_FILTER_SUCCESS,
      data: data.data,
    });
  } catch (error) {
    const msg = error.response.data.message;
    toast.error(msg);
    console.log(error);
    dispatch({
      type: GET_ALL_DEPARTMENT_FILTER_ERROR,
      error: msg,
    });
  }
};

export const sendRecommendationProgram = (body) => async (dispatch) => {
  try {
    dispatch({
      type: RECOMMEND_PROGRAM_BEGIN,
    });
    const headers = dispatch(getHeaders());
    const { data } = await axios.post(
      `${API}/api/program/patient-recommendation/recommend-program`,
      body,
      { headers }
    );
    toast.success("Program Recommended successfully!");
    dispatch({
      type: RECOMMEND_PROGRAM_SUCCESS,
      data: data.data,
    });
  } catch (error) {
    console.log(error.response);
    const msg = error.response.data.message;
    toast.error(msg);
    dispatch({
      type: RECOMMEND_PROGRAM_ERROR,
      error: msg,
    });
  }
};
