import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Pagination from "../../../components/Pagination";
import { getAllUsers } from "../../../redux/auth/actions";
import { useDispatch, useSelector } from "react-redux";
import { capitalise } from "../../../utilities/utilities";
import { FallingLinesLoader } from "../../../components/spinners/Spinner";
import Breadcrumb from "../../../components/Breadcrumb";

import Table from "../../../components/tables/table";
import { user_columns } from "../../../components/tables/tableheader";

const Users = () => {
  const pages = [{ title: "Users", href: "/users" }];
  const dispatch = useDispatch();
  const { loading, userList } = useSelector((state) => state.auth);

  useEffect(() => {
    document.title = "New Horizon | Users";
    dispatch(getAllUsers());
  }, []);

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <Breadcrumb pages={pages} />{" "}
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-xl font-semibold text-gray-900"> Users </h1>{" "}
          <p className="mt-2 text-sm text-gray-700">
            A list of all the users in your account including their name, title,
            email and role.{" "}
          </p>{" "}
        </div>{" "}
        <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
          <Link
            to="/add-user"
            type="button"
            className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
          >
            Add User{" "}
          </Link>{" "}
        </div>{" "}
      </div>{" "}
      {loading ? (
        <FallingLinesLoader />
      ) : (
        <Table columns={user_columns()} data={userList} />
      )}{" "}
    </div>
  );
};

export default Users;
