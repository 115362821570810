import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

import { FallingLinesLoader } from "../../../components/spinners/Spinner";
import Table from "../../../components/tables/table";

import { useDispatch } from "react-redux";
import { CountDown_colums } from "../../../components/tables/tableheader";
import { getHeaders } from "../../../redux/auth/actions";
import axios from "axios";
import { toast } from "react-hot-toast";
import moment from "moment";
const initialModalState = {
  type: "",
  state: false,
  index: null,
  edit_id: "",
  data: {
    name: "",
    date: "",
  },
};
const APi = process.env.REACT_APP_API_URL;
function CountDown() {
  const [loading, setLoading] = useState(false);
  const [packageList, setPackageList] = useState(false);
  const [controlList, setControlList] = useState([]);
  const [modal, setModal] = useState(initialModalState);
  const [query, setQuery] = useState(200);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    // getAllStorageLocations();
    getAllEnrolment(200);
    document.title = "New Horizon | OPD CountDown";
  }, []);

  const [labs, setLabs] = useState({
    loading: false,
    data: [],
  });
  const [confirmationModal, setConfirmationModal] = useState({
    status: false,
    id: null,
  });
  const [enrolment, setEnrolment] = useState({
    loading: false,
    data: [],
    orgData: [],
  });
  const getCountDown = (countdown_date) => {
    const countdown =
      30 + Number(moment(countdown_date).diff(moment().toISOString(), "days"));
    const today = moment().format("YYYY-MM-DD");
    const countdown_Date = moment(countdown_date).format("YYYY-MM-DD");

    if (countdown_date) {
      if (today === countdown_Date || today > countdown_Date) {
        return countdown;
      } else {
        return 30;
      }
    } else {
      return "N/A";
    }
  };
  async function getAllEnrolment(limit) {
    setEnrolment({ ...enrolment, loading: true });
    const headers = dispatch(getHeaders());
    try {
      const res = await axios.get(
        `${APi}/api/enrollment/get-all/countdown?limit=${limit}`,
        {
          headers: headers,
        }
      );
      const { data } = res;
      if (data) {
        setEnrolment({
          ...enrolment,
          data: data.data.map((item) => ({
            ...item,
          })),
          loading: false,
          orgData: data.data.map((item) => ({
            ...item,
          })),
        });
      }
    } catch (error) {
      toast.error(error.response.data.message);
    }
  }

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="flex items-center justify-between">
        <h1 className="text-xl font-semibold text-gray-900">OPD Countdown</h1>
        <div className="mt-1 sm:col-span-2 sm:mt-0">
          <select
            className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
            onChange={(e) => {
              const value = Number(e.target.value);
              console.log(value);
              if (!value) {
                setEnrolment((prev) => ({
                  ...prev,
                  data: prev.orgData,
                }));
              } else {
                setEnrolment((prev) => ({
                  ...prev,
                  data: prev.orgData.filter(
                    (item) => Number(item.countdown) <= value
                  ),
                }));
              }
            }}
          >
            <option value={null}> Filter by </option>
            <option value={10}> Less than 10 </option>
            <option value={5}> Less than 5 </option>
            <option value={1}> Less than equal 0 </option>
          </select>
        </div>
      </div>
      <div className="mt-4 flex"></div>
      {enrolment.loading ? (
        <FallingLinesLoader />
      ) : (
        <Table
          columns={CountDown_colums({ setModal })}
          data={enrolment.data}
          fetch={async (e) => {
            setQuery((prev) => e);

            await getAllEnrolment(e);
          }}
        />
      )}
    </div>
  );
}
export default CountDown;
