import { useState, useEffect } from 'react';

export default function EmailInput(props) {
  let data = props.config;
  let [visibility, set_visibility] = useState(true);

  useEffect(() => {
    if (data.child) {
      let foi = Object.keys(props.all_fields).filter((field) => {
        if (props.all_fields[field].label.trim() == data.parent?.label.trim()) {
          return props.all_fields[field];
        }
      });
      if (
        props.current_data[props.all_fields[foi]?.ref_id] == data.parent?.option
      ) {
        set_visibility(true);
      } else {
        if (props.current_data[data.ref_id] != null){
          props.record_change(null, data.ref_id);
        }
        set_visibility(false);
      }
    }
  }, [props.current_data]);
  return (
    <>
      {visibility ? (
        <div className="border-b pl-5 pb-5">
          <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4  sm:border-gray-200 sm:pt-5">
            <label
              htmlFor="email"
              className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
            >
              {data.label}
            </label>
            <div className="mt-1 sm:col-span-2 sm:mt-0">
              <input
              value={props.current_data[data.ref_id]?props.current_data[data.ref_id]:''}
                placeholder={data.placeholder}
                minLength={data.minlength}
                maxLength={data.maxlength}
                required={data.required}
                onChange={(e) => {
                  props.record_change(e.target.value, data.ref_id);
                }}
                type="email"
                name="email"
                id={data.ref_id}
                className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
              />
            </div>
          </div>{' '}
        </div>
      ) : (
        ''
      )}
      {/* <div className="mt-5 flex justify-left">
        <div className="mb-3 xl:w-96">
          <label
            for="email"
            className="font-bold form-label inline-block mb-2 text-gray-700"
          >
            {data.label}
          </label>
          <input
            type="email"
            placeholder={data.placeholder}
            className="
        form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none
      "
            id="email"
          />
        </div>
      </div> */}
    </>
  );
}
