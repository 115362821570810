export const GET_ALL_PATIENTS_FORM_BEGIN = "GET_ALL_PATIENTS_FORM_BEGIN";
export const GET_ALL_PATIENTS_FORM_SUCCESS = "GET_ALL_PATIENTS_FORM_SUCCESS";
export const GET_ALL_PATIENTS_FORM_ERROR = "GET_ALL_PATIENTS_FORM_ERROR";

export const ADD_PATIENTS_FORM_BEGIN = "ADD_PATIENTS_FORM_BEGIN";
export const ADD_PATIENTS_FORM_SUCCESS = "ADD_PATIENTS_FORM_SUCCESS";
export const ADD_PATIENTS_FORM_ERROR = "ADD_PATIENTS_FORM_ERROR";

export const GET_PATIENTS_FORM_BEGIN = "GET_PATIENTS_FORM_BEGIN";
export const GET_PATIENTS_FORM_SUCCESS = "GET_PATIENTS_FORM_SUCCESS";
export const GET_PATIENTS_FORM_ERROR = "GET_PATIENTS_FORM_ERROR";

export const UPDATE_PATIENTS_FORM_BEGIN = "UPDATE_PATIENTS_FORM_BEGIN";
export const UPDATE_PATIENTS_FORM_SUCCESS = "UPDATE_PATIENTS_FORM_SUCCESS";
export const UPDATE_PATIENTS_FORM_ERROR = "UPDATE_PATIENTS_FORM_ERROR";

export const GET_ALL_DOCS_BEGIN = "GET_ALL_DOCS_BEGIN";
export const GET_ALL_DOCS_SUCCESS = "GET_ALL_DOCS_SUCCESS";
export const GET_ALL_DOCS_ERROR = "GET_ALL_DOCS_ERROR";
