import { useState, useEffect } from 'react';

export default function Password_Input(props) {
  let data = props.config;
  let [visibility, set_visibility] = useState(true);

  useEffect(() => {
    if (data.child) {
      let foi = Object.keys(props.all_fields).filter((field) => {
        if (props.all_fields[field].label.trim() == data.parent?.label.trim()) {
          return props.all_fields[field];
        }
      });
      if (
        props.current_data[props.all_fields[foi]?.ref_id] == data.parent?.option
      ) {
        set_visibility(true);
      } else {
        if (props.current_data[data.ref_id] != null || data.defaultValue != null){
          props.record_change(null, data.ref_id);
          data.defaultValue=null;
        }
        set_visibility(false);
      }
    }
  }, [props.current_data]);
  return (
    <>
      {visibility ? (
        <div className="border-b pl-5 pb-5">
          <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4  sm:border-gray-200 ">
            <label
              htmlFor="email"
              className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
            >
              {data.label}
            </label>
            <div className="mt-1 sm:col-span-2 sm:mt-0">
              <input
                placeholder={data.placeholder}
                value={props.current_data[data.ref_id]?props.current_data[data.ref_id]:''}
                minLength={data.minlength}
                maxLength={data.maxlength}
                required={data.required}
                onChange={(e) => {
                  props.record_change(e.target.value, data.ref_id);
                }}
                type="password"
                name="password"
                id={data.ref_id}
                className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
              />
            </div>{' '}
          </div>
        </div>
      ) : (
        ''
      )}
    </>
  );
}
