import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import { ArrowUturnLeftIcon } from "@heroicons/react/20/solid";
import { useDispatch, useSelector } from "react-redux";
import { FallingLinesLoader } from "../../../components/spinners/Spinner";
import Breadcrumb from "../../../components/Breadcrumb";
import axios from "axios";
import {
  addMedicineMaster,
  updateMedicineMaster,
  getMedicineMasterWithId,
} from "../../../redux/medicineMaster/actions";
import { medicineMasterSchema } from "../../../schemas";
const AddMedicine = () => {
  const pages = [{ title: "Location", href: "/locations" }];
  const [options, setOptions] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id: medicineId } = useParams();
  const API = process.env.REACT_APP_API_URL;
  const initialValues = {
    medicine_name_id: "",
    formulation_id: "",
    strength_id: "",
    route_of_administration_id: "",
  };
  const optList = async () => {
    await axios.get(`${API}/api/medicine/master/info/all`).then((res) => {
      const data = res.data;
      console.log(data.data.route_of_administration);
      setOptions(data.data);
    });
  };
  const [formState, setFormState] = useState(initialValues);
  const { loading, medicineMasterData } = useSelector(
    (state) => state.medicineMaster
  );
  useEffect(() => {
    optList();
    document.title = "New Horizon | Add-Medicine";
    if (medicineId) dispatch(getMedicineMasterWithId(medicineId));

  }, [medicineId]);

  useEffect(() => {
    if (medicineId && medicineMasterData) {
      const newFormState = {
        medicine_name_id: medicineMasterData.medicine_name_id,
        formulation_id: medicineMasterData.formulation_id,
        strength_id: medicineMasterData.strength_id,
        route_of_administration_id: medicineMasterData.route_of_administration_id,
      };
      setFormState(newFormState);
    }
  }, [medicineId ,medicineMasterData]);


  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      enableReinitialize: true,
      initialValues: formState,
        validationSchema: medicineMasterSchema,
      onSubmit: (values, action) => {
        // TODO: Optimize code here
        const body = {
          medicine_name_id: parseInt(values.medicine_name_id),
          formulation_id: parseInt(values.formulation_id),
          strength_id: parseInt(values.strength_id),
          route_of_administration_id: parseInt(
            values.route_of_administration_id
          ),
        };
        if (medicineId) dispatch(updateMedicineMaster(medicineId, body));
        else dispatch(addMedicineMaster(body));
        action.resetForm();
        navigate(-1);
      },
    });
  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <Breadcrumb pages={pages} />
      <div>
        <button
          type="button"
          onClick={() => navigate(-1)}
          className="inline-flex items-center rounded-md border mb-6 border-transparent bg-indigo-100 px-3 py-2 text-sm font-medium leading-4 text-indigo-700 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
        >
          <span className="w-5 mr-2">
            <ArrowUturnLeftIcon size={18} />
          </span>{" "}
          Back
        </button>
        <div className="sm:flex-auto">
          <h1 className="text-xl font-semibold text-gray-900">
            {medicineId ? "Update Medicine" : "Add Medicine"}
          </h1>
        </div>
      </div>
      {/* {loading ? ( */}
      {/* <FallingLinesLoader /> */}
      {/* ) : ( */}
      <form
        onSubmit={handleSubmit}
        className="space-y-8 divide-y divide-gray-200"
      >
        <div className="space-y-6 sm:space-y-5">
          <div className="space-y-6 sm:space-y-5 sm:pt-5">
            <div className="space-y-6 sm:space-y-5">
              <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                <label
                  htmlFor="location_name"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Medicine name
                </label>
                <div className="mt-1 sm:col-span-2 sm:mt-0">
                  <select
                    id="medicine_name_id"
                    name="medicine_name_id"
                    value={values.medicine_name_id}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-indigo-500 sm:max-w-xs sm:text-sm"
                  >
                    <option value="all">Select a Name</option>

                    {options &&
                      options.medicine_names.map((opt, idx) => (
                        <option key={idx} value={opt.id}>
                          {opt.name}
                        </option>
                      ))}
                  </select>
                  {errors.medicine_name_id && touched.medicine_name_id ? (
                    <p className="text-red-700">{errors.medicine_name_id}</p>
                  ) : null}
                </div>
              </div>
              <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                <label
                  htmlFor="location_name"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Strength
                </label>
                <div className="mt-1 sm:col-span-2 sm:mt-0">
                  <select
                    id="strength_id"
                    name="strength_id"
                    value={values.strength_id}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-indigo-500 sm:max-w-xs sm:text-sm"
                  >
                    <option value="all">Select the Strength</option>

                    {options &&
                      options.strength.map((opt, idx) => (
                        <option key={idx} value={opt.id}>
                          {opt.strength}
                        </option>
                      ))}
                  </select>
                  {errors.strength_id && touched.strength_id ? (
                    <p className="text-red-700">{errors.strength_id}</p>
                  ) : null}
                </div>
              </div>
              <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                <label
                  htmlFor="location_name"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Formulation
                </label>
                <div className="mt-1 sm:col-span-2 sm:mt-0">
                  <select
                    id="formulation_id"
                    name="formulation_id"
                    value={values.formulation_id}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-indigo-500 sm:max-w-xs sm:text-sm"
                  >
                    <option value="all">Select a formulation</option>

                    {options &&
                      options.formulation.map((d, idx) => (
                        <option key={idx} value={d.id}>
                          {d.formulation}
                        </option>
                      ))}
                  </select>
                  {errors.formulation_id && touched.formulation_id ? (
                    <p className="text-red-700">{errors.formulation_id}</p>
                  ) : null}
                </div>
              </div>
              <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                <label
                  htmlFor="location_name"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Route of Administration
                </label>
                <div className="mt-1 sm:col-span-2 sm:mt-0">
                  <select
                    id="route_of_administration_id"
                    name="route_of_administration_id"
                    value={values.route_of_administration_id}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-indigo-500 sm:max-w-xs sm:text-sm"
                  >
                    <option value="all">
                      Select a Route of administration
                    </option>

                    {options &&
                      options.route_of_administration.map((d, idx) => (
                        <option key={idx} value={d.id}>
                          {d.route_of_administration}
                        </option>
                      ))}
                  </select>
                  {errors.route_of_administration_id &&
                  touched.route_of_administration_id ? (
                    <p className="text-red-700">
                      {errors.route_of_administration_id}
                    </p>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="pt-5">
          <div className="flex justify-end">
            <button
              type="submit"
              className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            >
              Submit
            </button>
          </div>
        </div>
      </form>
      {/* )} */}
    </div>
  );
};

export default AddMedicine;
