/* eslint-disable */
import axios from "axios";
import { getHeaders } from "../auth/actions";
import { toast } from "react-toast"
import {
  GET_ALL_DOCTORS_BEGIN,
  GET_ALL_DOCTORS_SUCCESS,
  GET_ALL_DOCTORS_ERROR,
  ADD_DOCTOR_BEGIN,
  ADD_DOCTOR_SUCCESS,
  ADD_DOCTOR_ERROR,
  GET_DOCTOR_BEGIN,
  GET_DOCTOR_SUCCESS,
  GET_DOCTOR_ERROR,
  UPDATE_DOCTOR_BEGIN,
  UPDATE_DOCTOR_SUCCESS,
  UPDATE_DOCTOR_ERROR,
  DELETE_DOCTOR_BEGIN,
  DELETE_DOCTOR_SUCCESS,
  DELETE_DOCTOR_ERROR,
} from "./constants";

const API = process.env.REACT_APP_API_URL;

export const getAllDoctors = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_ALL_DOCTORS_BEGIN,
    });
    const headers = dispatch(getHeaders());
    const { data } = await axios.get(`${API}/api/doctor-master`, { headers });
    console.log(data.data);

    dispatch({
      type: GET_ALL_DOCTORS_SUCCESS,
      data: data.data,
    });
  } catch (error) {
    const msg = error.response.data.message;
    toast.error(msg)
    dispatch({
      type: GET_ALL_DOCTORS_ERROR,
      error: msg,
    });
  }
};

export const addDoctor = (body) => async (dispatch) => {
  try {
    dispatch({
      type: ADD_DOCTOR_BEGIN,
    });
    const headers = dispatch(getHeaders());
    const { data } = await axios.post(`${API}/api/doctor-master`, body, { headers });
    console.log(data.data);
    toast.success("Added doctor successfully!")
    dispatch({
      type: ADD_DOCTOR_SUCCESS,
      data: data.data,
    });
  } catch (error) {
    const msg = error.response.data.message;
    toast.error(msg)
    dispatch({
      type: ADD_DOCTOR_ERROR,
      error: msg,
    });
  }
};

export const getDoctorWithId = (id) => async (dispatch) => {
  try {
    dispatch({
      type: GET_DOCTOR_BEGIN,
    });
    const headers = dispatch(getHeaders());
    const { data } = await axios.get(`${API}/api/doctor-master/${id}`, { headers });
    console.log(data.data);

    dispatch({
      type: GET_DOCTOR_SUCCESS,
      data: data.data,
    });
  } catch (error) {
    const msg = error.response.data.message;
    toast.error(msg)
    dispatch({
      type: GET_DOCTOR_ERROR,
      error: msg,
    });
  }
};

export const updateDoctor = (id, body) => async (dispatch) => {
  try {
    dispatch({
      type: UPDATE_DOCTOR_BEGIN,
    });
    const headers = dispatch(getHeaders());
    const { data } = await axios.put(`${API}/api/doctor-master/${id}`, body, {
      headers,
    });
    console.log(data.data);
    toast.success("Updated doctor successfully!")
    dispatch({
      type: UPDATE_DOCTOR_SUCCESS,
      data: data.data,
    });
  } catch (error) {
    const msg = error.response.data.message;
    toast.error(msg)
    dispatch({
      type: UPDATE_DOCTOR_ERROR,
      error: msg,
    });
  }
};

export const deleteDoctor = (id, body) => async (dispatch) => {
  try {
    dispatch({
      type: DELETE_DOCTOR_BEGIN,
    });
    const headers = dispatch(getHeaders());
    const { data } = await axios.delete(`${API}/api/doctor-master/${id}`, {
      headers,
    });
    console.log(data.data);
    toast.success("Deleted doctor successfully!")
    dispatch({
      type: DELETE_DOCTOR_SUCCESS,
      data: data.data,
    });
  } catch (error) {
    const msg = error.response.data.message;
    toast.error(msg)
    dispatch({
      type: DELETE_DOCTOR_ERROR,
      error: msg,
    });
  }
};
