import React, { useEffect } from "react";
import { Line } from "react-chartjs-2";
import { useState } from "react";
import Chart from "chart.js/auto";
import {
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { ConnectingAirportsOutlined, Height } from "@mui/icons-material";
import axios from "axios";
import moment from "moment";
Chart.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);
const api = process.env.REACT_APP_API_URL;

function TotalPatientSession({ label_chart,cdata, selected, mydataset}) {
  console.log(cdata,'chartdata');
    const Data = [
        {
          id: 1,
          month: "Jan",
          weight: 44,
          height: 130,
          bmi: 28,
          head_curc: 60,
          chest_curcum: 120,
          mid_air: 25,
        },
        {
          id: 1,
          month: "Feb",
          weight: 55,
          height: 135,
          bmi: 24,
          head_curc: 66,
          chest_curcum: 130,
          mid_air: 28,
        },
        {
          id: 1,
          month: "Mar",
          weight: 60,
          height: 140,
          bmi: 22,
          head_curc: 70,
          chest_curcum: 140,
          mid_air: 32,
        },
        {
          id: 1,
          month: "Apr",
          weight: 65,
          height: 145,
          bmi: 21,
          head_curc: 79,
          chest_curcum: 150,
          mid_air: 35,
        },
        {
          id: 1,
          month: "May",
          weight: 70,
          height: 144,
          bmi: 26,
          head_curc: 80,
          chest_curcum: 139,
          mid_air: 40,
        },
      ];



  const [chartData, setChartData] = useState({
    labels: Object.values(cdata).map(item => item.date),

    datasets: [
      {
        label: "Patient",
        data: Object.values(cdata).map((item) => item.patient),
        borderColor: "rgb(255, 99, 132)",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
        borderWidth: 2,
      },
      {
        label: "Session",
        data: Object.values(cdata).map((item) => item.session),
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
        borderWidth: 2,
      }
    ],
  });

  useEffect(()=>{
    setChartData({
      labels: Object.keys(cdata),

      datasets: [
        {
          label: "Patient",
          data: Object.values(cdata).map((item) => item.patient),
          borderColor: "rgb(255, 99, 132)",
          backgroundColor: "rgba(255, 99, 132, 0.5)",
          borderWidth: 2,
        },
        {
          label: "Session",
          data: Object.values(cdata).map((item) => item.session),
          borderColor: "rgb(53, 162, 235)",
          backgroundColor: "rgba(53, 162, 235, 0.5)",
          borderWidth: 2,
        }
      ],
    });
  }, [cdata])

  return (
    <>
      <div className="grid gap-4 grid-cols-2 items-center justify-center">
        <div
          className="chart-container"
          style={{ height: "20rem", width: "33rem" }}
        >
          <Line
            data={chartData}
            options={{
              maintainAspectRatio: false,

              scales: {
                y: {
                  min: 0,
                  ticks: {
                    stepSize: 5,
                  },
                },
              },
              plugins: {
                title: {
                  display: true,
                  text: label_chart
                },
                legend: {
                  display: true,
                },
              },
            }}
          />
        </div>
      </div>
    </>
  );
}
export default TotalPatientSession;
