import {
  GET_ALL_EXCEPTION_BEGIN,
  GET_ALL_EXCEPTION_SUCCESS,
  GET_ALL_EXCEPTION_ERROR,
  ADD_EXCEPTION_BEGIN,
  ADD_EXCEPTION_SUCCESS,
  ADD_EXCEPTION_ERROR,
  // GET_EXCEPTION_BEGIN,
  // GET_EXCEPTION_ERROR,
  // GET_EXCEPTION_SUCCESS,
  // UPDATE_EXCEPTION_BEGIN,
  // UPDATE_EXCEPTION_SUCCESS,
  // UPDATE_EXCEPTION_ERROR,
  DELETE_EXCEPTION_BEGIN,
  DELETE_EXCEPTION_SUCCESS,
  DELETE_EXCEPTION_ERROR,
} from "./constants";

const initState = {
  list: [],
  loading: false,
  error: null,
};

const ExceptionReducer = (state = initState, action) => {
  const { type, data, error } = action;
  switch (type) {
    case GET_ALL_EXCEPTION_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case GET_ALL_EXCEPTION_SUCCESS:
      return {
        ...state,
        list: data,
        loading: false,
      };
    case GET_ALL_EXCEPTION_ERROR:
      return {
        ...state,
        error,
        loading: false,
      };
    case ADD_EXCEPTION_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case ADD_EXCEPTION_SUCCESS:
      return {
        ...state,
        loading: false,
        list: [...state.list, data],
      };
    case ADD_EXCEPTION_ERROR:
      return {
        ...state,
        error,
        loading: false,
      };
    case DELETE_EXCEPTION_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case DELETE_EXCEPTION_SUCCESS:
      return {
        ...state,
        loading: false,
        list: state.list.filter((ele) => ele.id !== data.id),
      };
    case DELETE_EXCEPTION_ERROR:
      return {
        ...state,
        error,
        loading: false,
      };
    default:
      return state;
  }
};

export default ExceptionReducer;
