import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteLocation,
  getAllLocations,
} from "../../../redux/location/actions";
import Pagination from "../../../components/Pagination";
import { FallingLinesLoader } from "../../../components/spinners/Spinner";
import Breadcrumb from "../../../components/Breadcrumb";
import ConfirmationModal from "../../../components/ConfirmationModal";

import Table from "../../../components/tables/table";
import {
  goallogs_columns,
  location_columns,
} from "../../../components/tables/tableheader";
import axios from "axios";
import Modal from "../../../components/Modal";

const GetAllGoalLogs = () => {
  const [modal, setModal] = useState({
    state: false,
    id: null,
  });
  const [confirmationModal, setConfirmationModal] = useState({
    status: false,
    id: null,
  });
  const dispatch = useDispatch();
  const [logs, SetLogs] = useState({
    loading: true,
    data: [],
  });

  const renderModal = () => {
    const { state, id } = modal;
    const _index = logs.data.find((f) => Number(f.id) === Number(id));

    return (
      <Modal
        size="max-w-4xl"
        open={state}
        title="Goal Logs"
        setOpen={() => setModal((prev) => ({ state: false, id: null }))}
      >
        <div className="flex flex-row gap-4">
          <div>
            <span className="text-xl font-semibold">Updated Data</span>
            <div className="grid grid-cols-1 gap-4">
              {_index?.new_updated_data?.goals?.map((item) => (
                <div className="p-4 bg-white rounded-lg shadow-md border-t border-gray-200">
                  <div className="flex items-center mb-2 border-b border-gray-300 pb-2">
                    <h1 className="text-xl font-bold mr-4 text-gray-900 text-left">
                      Department:
                    </h1>
                    <p className="text-md font-semibold text-gray-900 text-left">
                      {item?.field_name}
                    </p>
                  </div>
                  <div className="flex items-start mb-2 border-b border-gray-300 pb-2">
                    <h1 className="text-xl font-bold mr-4 text-gray-900 text-left">
                      Goals:
                    </h1>
                    <div className="text-md font-semibold text-gray-900 text-left">
                      {[...new Set(item.values.split(","))]
                        .filter((elem) => elem.length > 1)
                        .filter((e) => e !== "")
                        .map((item) => (
                          <p key={item}>&#x2022; {item}</p>
                        ))}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div>
            <span className="text-xl font-semibold">Old Data</span>
            <div className="grid grid-cols-1 gap-4">
              {_index?.req_body_data?.goals?.map((item) => (
                <div className="p-4 bg-white rounded-lg shadow-md border-t border-gray-200">
                  <div className="flex items-center mb-2 border-b border-gray-300 pb-2">
                    <h1 className="text-xl font-bold mr-4 text-gray-900 text-left">
                      Department:
                    </h1>
                    <p className="text-md font-semibold text-gray-900 text-left">
                      {item?.field_name}
                    </p>
                  </div>
                  <div className="flex items-start mb-2 border-b border-gray-300 pb-2">
                    <h1 className="text-xl font-bold mr-4 text-gray-900 text-left">
                      Goals:
                    </h1>
                    <div className="text-md font-semibold text-gray-900 text-left">
                      {[...new Set(item.values.split(","))]
                        .filter((elem) => elem.length > 1)
                        .filter((e) => e !== "")
                        .map((item) => (
                          <p key={item}>&#x2022; {item}</p>
                        ))}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </Modal>
    );
  };

  useEffect(() => {
    document.title = "New Horizon | Goal Logs";
    (async () => {
      const res = await axios.put(
        `${process.env.REACT_APP_API_URL}/api/patient-form/get-all-goal-logs`,
        {}
      );

      SetLogs((prev) => ({
        loading: false,
        data: res.data.data,
      }));
    })();
  }, []);

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      {renderModal()}
      <div className="sm:flex sm:items-center mb-5">
        <div className="sm:flex-auto">
          <h1 className="text-xl font-semibold text-gray-900">Goal Logs</h1>
        </div>
        <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none"></div>
      </div>
      {logs.loading ? (
        <FallingLinesLoader />
      ) : (
        <Table columns={goallogs_columns({ setModal })} data={logs.data} />
      )}
    </div>
  );
};

export default GetAllGoalLogs;
