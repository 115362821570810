/* eslint-disable */
import {
  GET_ALL_THERAPIST_BEGIN,
  GET_ALL_THERAPIST_SUCCESS,
  GET_ALL_THERAPIST_ERROR,
  ADD_THERAPIST_BEGIN,
  ADD_THERAPIST_SUCCESS,
  ADD_THERAPIST_ERROR,
  GET_THERAPIST_BEGIN,
  GET_THERAPIST_ERROR,
  GET_THERAPIST_SUCCESS,
  UPDATE_THERAPIST_BEGIN,
  UPDATE_THERAPIST_SUCCESS,
  UPDATE_THERAPIST_ERROR,
  DELETE_THERAPIST_BEGIN,
  DELETE_THERAPIST_SUCCESS,
  DELETE_THERAPIST_ERROR,
  CLEAR_THERAPISTS,
} from "./constants";

const initState = {
  list: [],
  loading: false,
  error: null,
};

const TherapistReducer = (state = initState, action) => {
  const { type, data, error } = action;
  switch (type) {
    case GET_ALL_THERAPIST_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case GET_ALL_THERAPIST_SUCCESS:
      return {
        ...state,
        list: data,
        loading: false,
      };
    case GET_ALL_THERAPIST_ERROR:
      return {
        ...state,
        error,
        loading: false,
      };
    case ADD_THERAPIST_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case ADD_THERAPIST_SUCCESS:
      return {
        ...state,
        loading: false,
        list: [...state.list, data],
      };
    case ADD_THERAPIST_ERROR:
      return {
        ...state,
        error,
        loading: false,
      };
    case GET_THERAPIST_BEGIN:
      return {
        ...state,
        loading: true,
        TherapistData: "",
      };
    case GET_THERAPIST_SUCCESS:
      return {
        ...state,
        loading: false,
        TherapistData: data,
      };
    case GET_THERAPIST_ERROR:
      return {
        ...state,
        error,
        loading: false,
      };
    case UPDATE_THERAPIST_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_THERAPIST_SUCCESS:
      return {
        ...state,
        loading: false,
        list: state.list.map((ele) => {
          if (ele.id === data.id) {
            return { ...data };
          } else return ele;
        }),
      };
    case UPDATE_THERAPIST_ERROR:
      return {
        ...state,
        error,
        loading: false,
      };
    case DELETE_THERAPIST_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case DELETE_THERAPIST_SUCCESS:
      return {
        ...state,
        loading: false,
        list: state.list.filter((ele) => ele.id !== data.id),
      };
    case DELETE_THERAPIST_ERROR:
      return {
        ...state,
        error,
        loading: false,
      };
    case CLEAR_THERAPISTS:
      return { ...state, list: [], error: null, loading: false };
    default:
      return state;
  }
};

export default TherapistReducer;
