import React, { useEffect, useState } from "react";
import Table from "../../../components/tables/table";
import {
  TherapistAppointmentLogsColumn,
  opdReport_columns,
} from "../../../components/tables/tableheader";
import { useDispatch, useSelector } from "react-redux";
import { FallingLinesLoader } from "../../../components/spinners/Spinner";
import { getOpdAppointmentWithId } from "../../../redux/opdreport/action";
import { getAllDoctors } from "../../../redux/doctor/actions";
import moment from "moment";
import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useFormik } from "formik";
import ConfirmationModal from "../../../components/ConfirmationModal";
import { async } from "q";
import axios from "axios";
import { toast } from "react-hot-toast";
import { getHeaders } from "../../../redux/auth/actions";
import { getAllTherapists } from "../../../redux/therapist/therpist/actions";

const TherapistAppointmentLogs = () => {
  const [selected, setselected] = useState("all");
  const today = moment().format("YYYY-MM-DD");
  const startOfMonth = moment().startOf("month").format("YYYY-MM-DD");
  const endOfMonth = moment().endOf("month").format("YYYY-MM-DD");

  const [startDate, setStartDate] = useState(startOfMonth);
  const [endDate, setEndDate] = useState(endOfMonth);
  const dispatch = useDispatch();
  const { loading, list: therapistList } = useSelector(
    (state) => state.Therapist
  );

  const [therapist, setTherapist] = useState({
    data: [],
    loading: true,
  });

  const [confirmationModal, setConfirmationModal] = useState({
    status: false,
    id: null,
    body: {},
  });
  const [rconfirmationModal, setrConfirmationModal] = useState({
    status: false,
    id: null,
    body: {},
  });

  const onReuse = async (body) => {
    const headers = await dispatch(getHeaders());
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/plan-builder/reuse-plan`,
        body,
        {
          headers: headers,
        }
      );
      const { data, status } = res;
      if (data) {
        fetchAllTherapistLogs();
        toast.success("Plan Reused Successfully");
        setConfirmationModal({ id: null, status: false, body: {} });
      }
    } catch (error) {}
  };
  const onRquisit = async (body) => {
    const headers = await dispatch(getHeaders());
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/plan-builder/requisition-plan`,
        body,
        {
          headers: headers,
        }
      );
      const { data, status } = res;
      if (data) {
        fetchAllTherapistLogs();
        toast.success(data.message);
        setrConfirmationModal({ id: null, status: false, body: {} });
      }
    } catch (error) {}
  };

  const fetchAllTherapistLogs = (start,end, therapist_id) => {
    const headers = dispatch(getHeaders());
    let url = `${process.env.REACT_APP_API_URL}/api/therapist/appointment/logs?`;
    if (end && start) {
      url = `${url}&start=${start}&end=${end}&`;
    }
    if (therapist_id) {
      url = `${url}&therapist_id=${therapist_id}&`;
    }
    try {
      axios
        .get(`${url}`, {
          headers,
        })
        .then((response) => {
          setTherapist({
            data: response.data.data,
            loading: false,
          });
        })
        .catch((e) => toast.error(e?.response?.data?.message));
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  };

  useEffect(() => {
    fetchAllTherapistLogs(startDate, endDate);
    dispatch(getAllTherapists());
  }, [
    startDate,
    endDate
  ]);

  const { user } = useSelector((state) => state.auth);

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center mb-5">
        <div className="sm:flex-auto">
          <h1 className="text-xl font-semibold text-gray-900">
            Therapist Appointments
          </h1>
        </div>
        <div className="sm:grid sm:grid-cols-2    w-max sm:items-start sm:gap-4  sm:border-gray-200 sm:pt-5">
          <div
            className={`mt-1 flex items-center justify-right ${
              user.role !== "DOCTOR" && "grid-cols-2"
            } sm:col-span-2 sm:mt-0`}
          >
          <span
            className="flex flex-col w-max mr-2"
          >
              <label className="text-sm">Start Date</label>
          <input
              type="date"
              name="date"
              value={startDate}
              onChange={(e) => {
                setStartDate(e.target.value);
                fetchAllTherapistLogs(e.target.value, endDate,selected);
              }}
              placeholder="select date"
              className="mr-2 block w-[90%] max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-indigo-500 sm:max-w-xs sm:text-sm"
            />
          </span>
           <span className="flex flex-col w-max mr-2">
              <label  className="text-sm">End Date</label>
              <input
              type="date"
              name="date"
              value={endDate}
              onChange={(e) => {
                setEndDate(e.target.value);
                fetchAllTherapistLogs(startDate,e.target.value, selected);
              }}
              placeholder="select date"
              className="mr-2 block w-[90%] max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-indigo-500 sm:max-w-xs sm:text-sm"
            />
           </span>
            {user.role === "THERAPIST" ? null : (
              <select
                id="type"
                name="type"
                defaultValue={selected}
                onChange={(e) => {
                  setselected(e.target.value);
                }}
                // value={(e)=>setselected(e.target.value)}
                className="block w-full max-w-lg mt-5 rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-indigo-500 sm:max-w-xs sm:text-sm"
              >
                <option value="all">Select a Therapist</option>

                {therapistList?.map((d, idx) => (
                  <option key={idx} value={d.id}>
                    {d?.first_name + " " + d?.last_name}
                  </option>
                ))}
              </select>
            )}
            {/* {errors.type && touched.type ? (
                    <p className="text-red-700">{errors.type}</p>
                  ) : null} */}
          </div>
        </div>
      </div>
      {therapist.loading ? (
        <FallingLinesLoader />
      ) : (
        <Table
          columns={TherapistAppointmentLogsColumn({
            setConfirmationModal,
            setrConfirmationModal,
          })}
          data={therapist.data}
        />
      )}
    </div>
  );
};

export default TherapistAppointmentLogs;

export function SendLinkModal({
  showModal,
  setShowModal,
  disableBtn,
  meetLink,
  id,
}) {
  const [selected, setselected] = useState("");
  const [confirmationModal, setConfirmationModal] = useState({
    status: false,
    id: null,
  });
  const onSend = async () => {
    //TODO: Send link to patient
    try {
      const { data } =
        await axios.get(`${process.env.REACT_APP_API_URL}/api/appointment-payment/send-sms/${id}/${selected}
      `);
      toast.success(data.data);
    } catch (error) {
      console.log(error);
    }
    console.log(confirmationModal.id);
    setShowModal(false);
  };
  // console.log("meetLinkId"  + id)
  const initialValues = {};
  const sendPaymentLinkConfirmation = () => {
    setConfirmationModal({ status: true });
  };

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: initialValues,
      onSubmit: (values, action) => {
        //TODO: Optimize code here

        action.resetForm();
      },
    });
  // console.log(errors)

  return (
    <Transition.Root show={showModal} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setShowModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                <div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900"
                    >
                      Send Link to?
                    </Dialog.Title>
                    <div className="mt-2 w-full flex items-center justify-center">
                      <ConfirmationModal
                        open={confirmationModal.status}
                        setOpen={setConfirmationModal}
                        title=" "
                        onSend={onSend}
                        confirmationButtonText="Send"
                        confirmationButtonColor="indigo"
                        description="Do you want to send the payment link?"
                      />
                      <form className="w-[80%]" onSubmit={handleSubmit}>
                        <div className="mt-2 flex items-center justify-between">
                          <select
                            id="form_type"
                            name="form_type"
                            value={values.form_type}
                            onChange={(e) => {
                              setselected(e.target.value);
                              handleChange(e);
                            }}
                            onBlur={handleBlur}
                            className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-indigo-500 sm:max-w-xs sm:text-sm"
                          >
                            <option>Select the person</option>
                            <option value={"patient"}>Patient</option>
                            <option value={"doctor"}>Doctor</option>
                          </select>
                        </div>
                        <div className="mt-2 flex items-center justify-between">
                          <textarea
                            id="meeting-link"
                            name="meet_link"
                            rows={4}
                            cols={3}
                            disabled
                            value={meetLink}
                            className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-indigo-500 sm:max-w-xs sm:text-sm"
                          />
                        </div>

                        <div>
                          <div className="mt-5 sm:mt-6">
                            <button
                              type="submit"
                              disabled={disableBtn}
                              className="inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm"
                              onClick={sendPaymentLinkConfirmation}
                            >
                              Send
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
