import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  PDFViewer,
  PDFDownloadLink,
  Link,
} from "@react-pdf/renderer";
import logo from "../../../assets/pdfLogo.jpg";
import { ArrowUturnLeftIcon } from "@heroicons/react/20/solid";
import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
import { FallingLinesLoader } from "../../../components/spinners/Spinner";
import moment from "moment";
import {
  capitalizeFirstLetter,
  removeLeadingComma,
} from "../../../utilities/utilities";
const ReportPdf = () => {
  const navigate = useNavigate();
  const { id: reportId } = useParams();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const fetchReport = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/form/form-builder/reporting/${reportId}`
      );
      const data = response.data.data;
      const demographic = data?.form_data?.find((item) =>
        item.form_group_name.includes("Demographic")
      );
      const checkCases = ["First name", "Middle Name", "Gender", "Age"];
      let check = 0;
      checkCases.forEach((item) => {
        if (
          demographic &&
          demographic.form_field_data.find(
            (field) =>
              field.form_field_name.trim().toLocaleLowerCase() ===
                item.trim().toLocaleLowerCase() &&
              field.form_field_value !== null
          )
        ) {
          check++;
        }
      });
      if (check !== checkCases.length) {
        setData(data);
      } else {
        setData(data);
      }
      // setData(response.data.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchReport();
  }, [reportId]);
  if (loading) return <FallingLinesLoader />;
  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div>
        <button
          type="button"
          onClick={() => navigate(-1)}
          className="inline-flex items-center rounded-md border mb-6 border-transparent bg-indigo-100 px-3 py-2 text-sm font-medium leading-4 text-indigo-700 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
        >
          <span className="w-5 mr-2">
            <ArrowUturnLeftIcon size={18} />
          </span>{" "}
          Back
        </button>
        <div className="sm:flex-auto border-b pb-5">
          <h1 className="text-lg font-medium leading-6 text-gray-900">
            {" "}
            {reportId ? "Report" : "Report Not Found"}
          </h1>
          <p className="mt-1 max-w-2xl text-sm text-gray-500">
            This information will be displayed publicly so be careful what you
            share.
          </p>
        </div>
      </div>
      <div className=" flex-1 items-center justify-center h-max ">
        {loading === true ? (
          <FallingLinesLoader />
        ) : (
          <div className="">
            <PDFViewer height={1000} width={800} showToolbar={false}>
              <PdfFile res={data} />
            </PDFViewer>
          </div>
        )}
      </div>
      <div className="flex-1 flex items-center justify-center w-full h-max">
        <button className="inline-flex items-center rounded-md border mt-6 border-transparent bg-indigo-100 px-3 py-2 text-sm font-medium leading-4 text-indigo-700 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
          <PDFDownloadLink
            document={<PdfFile res={data} />}
            fileName="report.pdf"
          >
            {({ blob, url, loading, error }) =>
              loading ? "Generateing..." : "Print"
            }
          </PDFDownloadLink>
        </button>
      </div>
    </div>
  );
};

const PdfFile = ({ res }) => {
  const PDetails = res.patient;
  const updatedFormData = res.form_data.map((formGroup, index) => {
    const filteredFormFields = formGroup.form_field_data.filter(
      (formField, i) =>
        !(
          formField.form_field_type === "heading" &&
          formGroup.form_field_data[i + 1]?.form_field_value === null
        ) &&
        !(
          formField.form_field_type !== "heading" &&
          formField.form_field_value === null
        )
    );
    return {
      ...formGroup,
      form_field_data: filteredFormFields,
    };
  });

  function convertToArr(string) {
    const goldenRules = [...new Set(string.split(","))].filter(
      (elem) => elem.length > 1
    );
    return goldenRules;
  }

  function formatDate(dateStr) {
    const date = moment(dateStr, ["YYYY-MM-DD", "DD-MM-YYYY"]);
    return date.format("DD/MM/YYYY");
  }

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.titleHead}>
          <Image src={logo} style={styles.logo} />
        </View>
        <View style={styles.header}>
          <View>
            <View
              style={{
                display: "flex",
                gap: 4,
                marginBottom: 5,
                flexDirection: "row",
              }}
            >
              <Text
                style={{
                  fontWeight: "extrabold",
                }}
              >
                Patient :
              </Text>
              <Text
                style={{
                  fontWeight: "normal",
                }}
              >{` ${PDetails.firstName ? PDetails.firstName : " "} ${
                PDetails.lastName ? PDetails.lastName : " "
              }`}</Text>
            </View>
            <View
              style={{
                display: "flex",
                gap: 4,
                marginBottom: 5,
                flexDirection: "row",
              }}
            >
              <Text
                style={{
                  fontWeight: "extrabold",
                }}
              >
                Patient ID :{" "}
              </Text>
              <Text
                style={{
                  fontWeight: "normal",
                }}
              >
                {PDetails.patient_id ? PDetails.patient_id : "-"}
              </Text>
            </View>
            <View style={styles.headerDiv}>
              <Text
                style={{
                  fontWeight: "extrabold",
                }}
              >
                Referral DR. :{" "}
              </Text>
              <Text style={styles.title}>
                {PDetails.referral ? PDetails.referral.name : "-"}
              </Text>
            </View>
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                marginBottom: 5,
              }}
            >
              <Text style={styles.title}>DOB : </Text>
              <Text>{moment(PDetails.dob).format("DD/MM/YYYY")}</Text>
            </View>
          </View>

          <View>
            <View style={styles.headerDiv}>
              <Text style={styles.title}>Visit ID : </Text>
              <Text style={styles.title}>-</Text>
            </View>
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                marginBottom: 5,
              }}
            >
              <Text>Sex : </Text>
              <Text style={styles.title}>
                {PDetails.gender === "M"
                  ? `Male`
                  : PDetails.gender === "F"
                  ? "Female"
                  : "-"}
              </Text>
            </View>
            <View
              style={{
                display: "flex",
                gap: 4,
                marginBottom: 5,
                flexDirection: "row",
              }}
            >
              <Text style={styles.title}>Date : </Text>
              <Text style={styles.title}>
                {res?.date
                  ? moment(res?.date).utcOffset(0).format("DD/MM/YYYY")
                  : "-"}
              </Text>
            </View>
            <View style={styles.headerDiv}>
              <Text style={styles.title}>Age : </Text>
              <Text style={styles.title}>{PDetails.age}</Text>
            </View>
          </View>
        </View>
        <View style={styles.HeroHeading}>
          <Text>Patient Vital(s)</Text>
        </View>
        {/*Header ends */}
        {/* Body */}
        {updatedFormData.map((item, index) => {
          return (
            <View key={index}>
              <View style={styles.headerSecond}>
                <Text
                  style={{
                    fontWeight: "bold",
                    fontSize: "14",
                  }}
                >
                  {item.form_group_name}
                </Text>
              </View>

              {item.form_group_name === "Current Concerns"
                ? item.form_field_data.map((item, index) => {
                    return (
                      <View key={index} style={styles.row}>
                        <Text
                          wrap={false}
                          style={{
                            fontWeight: `${
                              item?.form_field_type === "heading" ? "bold" : ""
                            }`,
                            fontSize: `${
                              item?.form_field_type === "heading" ? "14" : "11"
                            }`,
                            flexBasis: "50%",
                            marginRight: 16,
                          }}
                        >
                          {item.form_field_name}
                        </Text>
                        <Text
                          style={{
                            flexBasis: "50%",
                            fontSize: 11,
                            // color: "#666", //? Adding Gray shade on Current Concerns
                            textAlign: "left",
                          }}
                        >
                          {item.form_field_type == "date"
                            ? moment(item.form_field_value).format("DD/MM/YYYY")
                            : removeLeadingComma(item.form_field_value)}
                        </Text>
                      </View>
                    );
                  })
                : item.form_field_data.map((item, index) => {
                    return (
                      <View key={index}>
                        <View style={styles.pageContainer}>
                          {item?.form_field_type == "checkbox" ? (
                            <View>
                              {" "}
                              <Text
                                style={{
                                  marginBottom: "5",
                                  fontSize: "15",
                                }}
                              >
                                {item?.form_field_name} :
                              </Text>
                              {convertToArr(item?.form_field_value).map(
                                (item, index) => {
                                  return (
                                    <View key={index} style={styles.dataDiv}>
                                      <Text
                                        key={index}
                                        style={{
                                          fontWeight: "normal",
                                          fontSize: "11",
                                          marginBottom: "5",
                                          marginLeft: "10",
                                          display: "flex",
                                          flexWrap: "wrap",
                                        }}
                                      >
                                        {"\u2022"} {item}
                                      </Text>
                                    </View>
                                  );
                                }
                              )}
                            </View>
                          ) : (
                            <View wrap={false} style={styles.dataDiv}>
                              <Text
                                style={{
                                  fontWeight: `${
                                    item?.form_field_type === "heading"
                                      ? "bold"
                                      : ""
                                  }`,
                                  fontSize: `${
                                    item?.form_field_type === "heading"
                                      ? "14"
                                      : "11"
                                  }`,
                                  width:
                                    item?.form_field_type === "heading"
                                      ? "100%"
                                      : "50%",
                                  display: "flex",
                                  flexWrap: "wrap",
                                  marginRight: "20",
                                }}
                              >
                                {item?.form_field_name}
                              </Text>
                              <Text
                                style={{
                                  fontWeight: "normal",
                                  display: "flex",
                                  flexWrap: "wrap",
                                  width: "50%",
                                  marginLeft: "10",
                                  textAlign: "left",
                                }}
                              >
                                {/* {item?.form_field_type == "date"
                                  ? moment(item?.form_field_value).format(
                                      "DD/MM/YYYY"
                                    )
                                  ? item?.form_field_name=="Reports" ?
                                  (
                                    <Link
                                    ref={item?.form_field_value}>
                                    check report
                                    </Link>
                                  ): item?.form_field_value} */}

                                {item?.form_field_type == "date" ? (
                                  moment(item?.form_field_value).format(
                                    "DD/MM/YYYY"
                                  )
                                ) : item?.form_field_name == "Reports" ? (
                                  <Link src={item?.form_field_value}>
                                    check report
                                  </Link>
                                ) : (
                                  item?.form_field_value
                                )}
                              </Text>
                            </View>
                          )}
                        </View>
                      </View>
                    );
                  })}
            </View>
          );
        })}
        {/* Body ends */}
        <View
          style={{
            flexDirection: "column",
            display: "flex",
            marginTop: "15",
            marginHorizontal: "10",
          }}
        >
          <Text
            style={{
              marginBottom: "7",
            }}
          >
            {res?.golden_rules_data !== null ? "Golden Rules" : ""}
          </Text>
          <View
            style={{
              flexDirection: "column",
              display: "flex",
              gap: 5,
              justifyContent: "start",
            }}
          >
            {res?.golden_rules_data &&
              convertToArr(
                removeLeadingComma(res?.golden_rules_data?.golden_rules)
              )?.map((item, index) => {
                return (
                  <Text
                    key={index}
                    style={{
                      fontSize: "11",
                      marginBottom: "10",
                      marginLeft: "10",
                      textAlign: "left",
                      lineHeight: 1,
                    }}
                  >
                    {"\u2022"} {capitalizeFirstLetter(item)}
                  </Text>
                );
              })}
          </View>
        </View>
        {res?.recommendation_comment?.comments && (
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              marginTop: "8",
              marginHorizontal: "10",
            }}
          >
            <Text
              style={{
                fontWeight: "bold",
                fontSize: "16",
                marginRight: "10",
              }}
            >
              Suggestion :{" "}
            </Text>
            <Text
              style={{
                fontWeight: "normal",
                fontSize: "11",
                marginRight: "10",
              }}
            >
              {res?.recommendation_comment?.comments}
            </Text>
          </View>
        )}
        <View>
          <View
            style={{
              flexDirection: "column",
              display: "flex",
              marginHorizontal: "20",
            }}
          >
            <View wrap={false}>
              {res.recommedations.length > 0 && (
                <View
                  style={{
                    flexDirection: "column",
                    border: "1px solid gray",
                    marginTop: "10",
                    borderRadius: "5",
                    padding: "10",
                  }}
                >
                  <View
                    style={{
                      width: "100%",
                    }}
                  >
                    <Text
                      style={{
                        fontSize: "16",
                      }}
                    >
                      Recommendation
                    </Text>
                  </View>

                  {res.recommedations.length > 0 && (
                    <View>
                      {res?.recommedations?.map((item, index) => {
                        return (
                          <View
                            key={index}
                            style={{
                              boarder: "1px solid gray",
                            }}
                          >
                            <Text
                              style={{
                                marginTop: "10",
                                fontSize: "11",
                              }}
                            >
                              Name: {item.program_name}
                            </Text>
                            <View
                              style={{
                                marginTop: "4",
                              }}
                            >
                              {item.sessions.map((item, index) => (
                                <View
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "flex-start",
                                    gap: "3",
                                  }}
                                >
                                  <Text
                                    style={{
                                      marginTop: "4",
                                      fontSize: "11",
                                      width: "40%",
                                    }}
                                  >
                                    Department Name: {item.department_name}
                                  </Text>
                                  {item.department_name === "E-nable" ? (
                                    <Text
                                      style={{
                                        marginTop: "4",
                                        fontSize: "11",
                                        width: "50%",
                                      }}
                                    >
                                      Total Sessions: 9 E-nable calls, 3 OPD's,
                                      1 Parental webinar
                                    </Text>
                                  ) : (
                                    <Text
                                      style={{
                                        marginTop: "4",
                                        fontSize: "11",
                                        width: "50%",
                                      }}
                                    >
                                      Total Session: {item.total_sessions}
                                    </Text>
                                  )}
                                </View>
                              ))}
                            </View>
                          </View>
                        );
                      })}
                    </View>
                  )}
                </View>
              )}
            </View>
            <View wrap={false}>
              {res.prescription_data.length > 0 && (
                <View
                  style={{
                    flexDirection: "column",
                    marginTop: "15",
                    padding: "10",
                  }}
                >
                  <View
                    style={{
                      width: "100%",
                    }}
                  >
                    <Text
                      style={{
                        fontSize: "16",
                      }}
                    >
                      Prescription
                    </Text>
                  </View>
                </View>
              )}
              {res.prescription_data.length > 0 && (
                <TableDocument tableheaderArray={tableheaderArray} res={res} />
              )}
            </View>
          </View>
        </View>
        {res.recommedations.length > 0 ||
        res?.recommendation_comment?.comments ? (
          <View
            style={{
              flexDirection: "column",
              display: "flex",
              marginTop: "15",
              marginHorizontal: "12",
            }}
          >
            <Text
              style={{
                marginBottom: "7",
                fontSize: "11",
              }}
            >
              1. DP consultation once a month
            </Text>
            <Text
              style={{
                marginBottom: "7",
                fontSize: "11",
              }}
            >
              2. CH feedback once a month
            </Text>
            <Text
              style={{
                marginBottom: "7",
                fontSize: "11",
              }}
            >
              3. Online Monthly Parent Webinar
            </Text>
            <Text
              style={{
                marginBottom: "7",
                fontSize: "11",
              }}
            >
              4. Quarterly Progress Report
            </Text>
          </View>
        ) : null}

        {/* bottom part */}
        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
        />
      </Page>
    </Document>
  );
};

const tableheaderArray = [
  "Medicine Name",
  "Strength",
  "Formulation",
  "Route of Administration",
  "Frequency",
  "Time",
  "Duration",
  "Start Date",
  "Instructions",
];
const TableDocument = ({ tableheaderArray, res }) => {
  const createTableHeader = () => {
    return (
      <View wrap={false} style={tableRowStyle} fixed>
        {res.prescription_data.length > 0 &&
          tableheaderArray.map((item, index) => {
            return (
              <View style={tableColHeaderStyle} key={index}>
                <Text style={tableCellHeaderStyle}>{item}</Text>
              </View>
            );
          })}
      </View>
    );
  };

  const CreateTableRow = ({ item }) => {
    return (
      <View style={tableRowStyle}>
        <View style={firstTableColStyle}>
          <Text style={tableCellStyle}>
            {addWhitespace(item.medicine_name)}
          </Text>
        </View>

        <View style={tableColStyle}>
          <Text style={tableCellStyle}>{item.strength}</Text>
        </View>
        <View style={tableColStyle}>
          <Text style={tableCellStyle}>{item.formulation}</Text>
        </View>

        <View style={tableColStyle}>
          <Text style={tableCellStyle}>{item.route_of_administration}</Text>
        </View>

        <View style={tableColStyle}>
          <Text style={tableCellStyle}>{item.frequency}</Text>
        </View>

        <View style={tableColStyle}>
          <Text style={tableCellStyle}>{item.time}</Text>
        </View>
        <View style={tableColStyle}>
          <Text style={tableCellStyle}>{item.duration}</Text>
        </View>
        <View style={tableColStyle}>
          <Text style={tableCellStyle}>
            {moment(item.start_date).format("DD/MM/YYYY")}
          </Text>
        </View>
        <View style={tableColStyle}>
          <Text style={tableCellStyle}>{item.instruction}</Text>
        </View>
      </View>
    );
  };

  return (
    <View style={tableStyle}>
      {createTableHeader()}
      {/* {createTableRow()} */}

      {res.prescription_data.length > 0 &&
        res?.prescription_data?.map((item, index) => {
          return <CreateTableRow key={index} item={item} />;
        })}
    </View>
  );
};

const pageStyle = {
  paddingTop: 16,
  paddingHorizontal: 40,
  paddingBottom: 30,
};

const tableStyle = {
  display: "table",
  width: "auto",
};

const tableRowStyle = {
  flexDirection: "row",
};

const firstTableColHeaderStyle = {
  width: "20%",
  borderStyle: "solid",
  borderColor: "#000",
  borderBottomColor: "#000",
  borderWidth: 1,
  backgroundColor: "#bdbdbd",
};

const tableColHeaderStyle = {
  width: "20%",
  borderStyle: "solid",
  borderColor: "#000",
  borderBottomColor: "#000",
  borderWidth: 1,
  borderLeftWidth: 0,
  backgroundColor: "#bdbdbd",
};

const firstTableColStyle = {
  width: "20%",
  borderStyle: "solid",
  borderColor: "#000",
  borderWidth: 1,
  borderTopWidth: 0,
};

const tableColStyle = {
  width: "20%",
  borderStyle: "solid",
  borderColor: "#000",
  borderWidth: 1,
  borderLeftWidth: 0,
  borderTopWidth: 0,
};

const tableCellHeaderStyle = {
  textAlign: "center",
  margin: 4,
  fontSize: 11,
  fontWeight: "bold",
  height: "auto",
};

function addWhitespace(word) {
  if (word.length > 10) {
    let middleIndex = Math.floor(word.length / 2);
    if (word.length % 2 === 0) {
      // if word length is even, add whitespace at the middle
      return word.slice(0, middleIndex) + " - " + word.slice(middleIndex);
    } else {
      // if word length is odd, add whitespace after the middle
      return (
        word.slice(0, middleIndex + 1) + " - " + word.slice(middleIndex + 1)
      );
    }
  } else {
    return word;
  }
}

const tableCellStyle = {
  textAlign: "center",
  margin: 5,
  fontSize: 10,
  whiteSpace: "break-word",
  wordWrap: "pre-wrap",
};

const styles = StyleSheet.create({
  logo: {
    position: "absolute",
    right: 0,
    width: 150,
    height: 60,
  },
  titleHead: {
    position: "relative",
    height: 80,
  },

  header: {
    fontSize: 11,
    marginTop: 0,
    marginBottom: 20,
    marginHorizontal: 20,
    fontWeight: "bold",
    display: "flex",
    flexDirection: "row",
    gap: 20,
    // justifyContent: "space-between",
  },
  headerDiv: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 5,
    marginRight: 10,
  },
  pageNumber: {
    position: "absolute",
    fontSize: 11,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  pageContainer: {
    marginHorizontal: 20,
    marginBottom: 7,
  },
  bellowHeader: {
    margin: 12,
    fontSize: 12,
    textAlign: "justify",
    fontFamily: "Times-Roman",
  },
  HeroHeading: {
    fontSize: 12,
    textAlign: "left",
    fontFamily: "Times-Roman",
    marginHorizontal: 20,
    borderTop: "1px solid #000",
    borderBottom: "1px solid #000",
  },
  page: {
    marginTop: 15,
    paddingBottom: 55,
    paddingTop: 30,
    paddingHorizontal: 15,
  },
  headerSecond: {
    fontSize: 16,
    textAlign: "left",
    fontWeight: "900",
    marginHorizontal: 20,
    marginBottom: 10,
    marginTop: 20,
  },
  dataDiv: {
    display: "flex",
    flexDirection: "row",
    marginRight: 8,
    marginBottom: 2,
    fontSize: 11,
    fontWeight: "normal",
  },
  bold: {
    fontWeight: "bold",
    fontSize: 14,
    marginBottom: 10,
  },
  text: {
    fontSize: 8,
    fontWeight: "normal",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingHorizontal: 10,
    paddingVertical: 8,
    borderBottomWidth: 1,
    borderBottomColor: "#ccc",
    marginHorizontal: 18,
  },
});

export default ReportPdf;
