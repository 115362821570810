import React, { useState } from "react";
import Breadcrumb from "../../../components/Breadcrumb";
import { VscDebugRestart } from "react-icons/vsc";
import { useDispatch } from "react-redux";
import {
  getAllDepartmentFilter,
  getQueryDepartmentData,
} from "../../../redux/patientAccordian/action";
import { useEffect } from "react";
import { FallingLinesLoader } from "../../../components/spinners/Spinner";
import { useSelector } from "react-redux";
const Recommendation = () => {
  const pages = [{ title: "Recommendation", href: "/recommendation" }];

  const [selected_filters, setSelectedFilters] = useState([]);

  const dispatch = useDispatch();
  const { loading, list: recommendationList } = useSelector(
    (state) => state.departmentFilter
  );

  useEffect(() => {
    document.title = "New Horizon | Recommendation";
    dispatch(getAllDepartmentFilter());
  }, []);

  // create a reset function to reset all the dropdowns
  const reset = () => {
    dispatch(getAllDepartmentFilter());
  };

  const handleFilterChange = (new_filter, selected_option) => {
    console.log({ new_filter, selected_option });

    if (selected_filters.length) {
      const is_department_present = selected_filters.find(
        (e) => e.department_id === new_filter.department_id
      );

      if (is_department_present) {
        is_department_present.selected_option = selected_option;
      } else {
        new_filter.selected_option = selected_option;
        setSelectedFilters([...selected_filters, new_filter]);
      }
    } else {
      new_filter.selected_option = selected_option;
      setSelectedFilters([new_filter]);
    }

    let filters = selected_filters.length ? [ ...selected_filters, new_filter ] : [ new_filter ];

    console.log({ selected_filters });
    dispatch(getQueryDepartmentData(filters));
  };

  return (
    <div>
      <Breadcrumb pages={pages} />
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-xl font-semibold text-gray-900">
            Recommendation
          </h1>
        </div>
      </div>
      <div>
        <div className="mt-4 ">
          <div className="mt-1 w-full grid grid-cols-9 sm:col-span-2 sm:mt-0">
            {/* <div
                    // key={index}
                    className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5"
                  > */}
            {loading ? (
              <FallingLinesLoader />
            ) : (
              recommendationList.map((item, index) => (
                <div className="mr-4" key={index + 1}>
                  <label
                    htmlFor="location"
                    className="block text-sm font-medium text-gray-700"
                  >
                    {item.department_name}
                  </label>
                  <select
                    id="location"
                    name="location"
                    className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    onChange={(e) => handleFilterChange(item, e.target.value)}
                  >
                    <option>Select</option>
                    {item.department_frequency_options.length &&
                      item.department_frequency_options.map((opt, index) => (
                        <option key={index + 1} selected={item.selected_option === opt}>{opt}</option>
                      ))}
                  </select>
                </div>
              ))
            )}

            <div className="flex items-end justify-around">
              <button
                type="reset"
                className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-[11px] px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-"
                onClick={() => reset()}
              >
                <VscDebugRestart className="text-white" />
              </button>
              <button
                type="button"
                className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-"
                // onClick={() => handleRemoveClick(index)}
              >
                Submit
              </button>
            </div>
            {/* )} */}
          </div>
          {/* </div> */}
        </div>
      </div>
    </div>
  );
};

export default Recommendation;
