import React, { useEffect, useState } from "react";
import Table from "../../../components/tables/table";
import { opdReport_columns } from "../../../components/tables/tableheader";
import { useDispatch, useSelector } from "react-redux";
import { FallingLinesLoader } from "../../../components/spinners/Spinner";
import { getOpdAppointmentWithId } from "../../../redux/opdreport/action";
import { getAllDoctors } from "../../../redux/doctor/actions";
import moment from "moment";
import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useFormik } from "formik";
import ConfirmationModal from "../../../components/ConfirmationModal";
import { async } from "q";
import axios from "axios";
import { toast } from "react-hot-toast";

const Opdreport = () => {
  const [selected, setselected] = useState("all");
  const today = moment().format("YYYY-MM-DD");
  const [reload, setReload] = useState(true);
  const [date, setDate] = useState(today);
  const [showModal, setShowModal] = useState(false);
  const [disableBtn, setDisableBtn] = useState(true);
  const [meetLink, setMeetLink] = useState("");
  const [id, setId] = useState("");
  const dispatch = useDispatch();
  const { loading, list: doctor } = useSelector((state) => state.doctor);
  const { list: opdReport, loading: Rloading } = useSelector(
    (state) => state.opdAppointment
  );
  const [data, setData] = useState(opdReport);

  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    document.title = "New Horizon | OPD report";
    dispatch(getAllDoctors());
    var today = moment().format("YYYY-MM-DD");
    dispatch(getOpdAppointmentWithId(selected, 100, today));
    // console.log(date);
  }, [selected]);

  useEffect(() => {
    setData(opdReport);
  }, [opdReport]);
  useEffect(() => {}, [opdReport]);

  const handleChangeDate = (date) => {
    if (date !== "") {
      const newArr = opdReport.filter((item) => {
        var newDate = moment(item.date).format("YYYY-MM-DD");
        return newDate === date;
      });
      // console.log(newArr);
      console.log(newArr);
      setData(newArr);
      if (newArr.length === 0) toast.error("No data found on this date");
    } else {
      setData(opdReport);
    }
  };

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <SendLinkModal
        id={id}
        meetLink={meetLink}
        disableBtn={disableBtn}
        setMeetLink={setMeetLink}
        showModal={showModal}
        setShowModal={setShowModal}
      />
      <div className="sm:flex sm:items-center mb-5">
        <div className="sm:flex-auto">
          <h1 className="text-xl font-semibold text-gray-900">OPD Report</h1>
        </div>
        <div className="sm:grid sm:grid-cols-2    w-max sm:items-start sm:gap-4  sm:border-gray-200 sm:pt-5">
          <div
            className={`mt-1 flex items-center justify-right ${
              user.role !== "DOCTOR" && "grid-cols-2"
            } sm:col-span-2 sm:mt-0`}
          >
            <input
              type="date"
              name="date"
              value={date}
              onChange={(e) => {
                setDate(e.target.value);
                dispatch(
                  getOpdAppointmentWithId(selected, 100, e.target.value)
                );
              }}
              placeholder="select date"
              className="mr-2 block w-[90%] max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-indigo-500 sm:max-w-xs sm:text-sm"
            />
            {user.role === "DOCTOR" ? null : (
              <select
                id="type"
                name="type"
                defaultValue={selected}
                onChange={(e) => {
                  setselected(e.target.value);
                }}
                // value={(e)=>setselected(e.target.value)}
                className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-indigo-500 sm:max-w-xs sm:text-sm"
              >
                <option value="all">Select a Doctor</option>

                {doctor.map((d, idx) => (
                  <option key={idx} value={d.id}>
                    {"Dr. " + d.first_name + " " + d.last_name}
                  </option>
                ))}
              </select>
            )}
            {/* {errors.type && touched.type ? (
                    <p className="text-red-700">{errors.type}</p>
                  ) : null} */}
          </div>
        </div>
      </div>
      {Rloading ? (
        <FallingLinesLoader />
      ) : (
        <Table
          columns={opdReport_columns({
            setShowModal,
            setDisableBtn,
            setMeetLink,
            setId,
          })}
          data={[...data].sort((a, b) => {
            const timeA = moment(a.start_time, "hh:mm A");
            const timeB = moment(b.start_time, "hh:mm A");
            return timeA.diff(timeB);
          })}
          fetch={async (d) => {
            if (selected) {
              await dispatch(getOpdAppointmentWithId(selected, d));
            } else {
              await dispatch(getOpdAppointmentWithId("all", d));
            }
          }}
        />
      )}
    </div>
  );
};

export default Opdreport;

export function SendLinkModal({
  showModal,
  setShowModal,
  disableBtn,
  meetLink,
  id,
}) {
  const [selected, setselected] = useState("");
  const [confirmationModal, setConfirmationModal] = useState({
    status: false,
    id: null,
  });
  const onSend = async () => {
    //TODO: Send link to patient
    try {
      const { data } =
        await axios.get(`${process.env.REACT_APP_API_URL}/api/appointment-payment/send-sms/${id}/${selected}
      `);
      toast.success(data.data);
    } catch (error) {
      console.log(error);
    }
    console.log(confirmationModal.id);
    setShowModal(false);
  };
  // console.log("meetLinkId"  + id)
  const initialValues = {};
  const sendPaymentLinkConfirmation = () => {
    setConfirmationModal({ status: true });
  };

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: initialValues,
      onSubmit: (values, action) => {
        //TODO: Optimize code here

        action.resetForm();
      },
    });
  // console.log(errors)

  return (
    <Transition.Root show={showModal} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setShowModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                <div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900"
                    >
                      Send Link to?
                    </Dialog.Title>
                    <div className="mt-2 w-full flex items-center justify-center">
                      <ConfirmationModal
                        open={confirmationModal.status}
                        setOpen={setConfirmationModal}
                        title=" "
                        onSend={onSend}
                        confirmationButtonText="Send"
                        confirmationButtonColor="indigo"
                        description="Do you want to send the payment link?"
                      />
                      <form className="w-[80%]" onSubmit={handleSubmit}>
                        <div className="mt-2 flex items-center justify-between">
                          <select
                            id="form_type"
                            name="form_type"
                            value={values.form_type}
                            onChange={(e) => {
                              setselected(e.target.value);
                              handleChange(e);
                            }}
                            onBlur={handleBlur}
                            className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-indigo-500 sm:max-w-xs sm:text-sm"
                          >
                            <option>Select the person</option>
                            <option value={"patient"}>Patient</option>
                            <option value={"doctor"}>Doctor</option>
                          </select>
                        </div>
                        <div className="mt-2 flex items-center justify-between">
                          <textarea
                            id="meeting-link"
                            name="meet_link"
                            rows={4}
                            cols={3}
                            disabled
                            value={meetLink}
                            className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-indigo-500 sm:max-w-xs sm:text-sm"
                          />
                        </div>

                        <div>
                          <div className="mt-5 sm:mt-6">
                            <button
                              type="submit"
                              disabled={disableBtn}
                              className="inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm"
                              onClick={sendPaymentLinkConfirmation}
                            >
                              Send
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
