import {
  GET_ALL_FORMS_BEGIN,
  GET_ALL_FORMS_SUCCESS,
  GET_ALL_FORMS_ERROR,
  GET_ALL_FORMS_GROUP_BEGIN,
  GET_ALL_FORMS_GROUP_SUCCESS,
  GET_ALL_FORMS_GROUP_ERROR,
  } from "./constants";
  
  const initState = {
    list: [],
    loading: false,
    error: null,
  };
  
  const FormsReducer = (state = initState, action) => {
      const { type, data, error } = action;
    switch (type) {
      case GET_ALL_FORMS_BEGIN:
          return {
            ...state,
            loading: true,
          };
        case GET_ALL_FORMS_SUCCESS:
          return {
            ...state,
            list: data,
            loading: false,
          };
        case GET_ALL_FORMS_ERROR:
          return {
            ...state,
            error,
            loading: false,
          };
      case GET_ALL_FORMS_GROUP_BEGIN:
          return {
            ...state,
            loading: true,
          };
        case GET_ALL_FORMS_GROUP_SUCCESS:
          return {
            ...state,
            list: data,
            loading: false,
          };
        case GET_ALL_FORMS_GROUP_ERROR:
          return {
            ...state,
            error,
            loading: false,
          };
      default:
          return state
    }
  };
  
  export default FormsReducer;
  