import { ArrowUturnLeftIcon } from "@heroicons/react/20/solid";
import { useFormik } from "formik";
import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Breadcrumb from "../../../components/Breadcrumb";
import { FallingLinesLoader } from "../../../components/spinners/Spinner";
import {
  addMchat,
  getMchatWithId,
  updateMchat,
} from "../../../redux/Amcat/action";

const Addamcat = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const pages = [{ title: "Amcat Master", href: "/amcat" }];
  const { id: mchatId } = useParams();
  const initialValues = {
    question: "",
    point_for: "",
  };
  const { loading, MCHATData } = useSelector((state) => state.Mchat);
  useEffect(() => {
    document.title = "New Horizon | Add-Location";
    if (mchatId) dispatch(getMchatWithId(mchatId));
  }, [mchatId]);

  useEffect(() => {
    if (mchatId && MCHATData) {
      const newFormState = {
        question: MCHATData.question,
        point_for: MCHATData.point_for,
      };
      setFormState(newFormState);
    }
  }, [mchatId, MCHATData]);

  const [formState, setFormState] = useState(initialValues);
  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      enableReinitialize: true,
      initialValues: formState,
      onSubmit: (values, action) => {
        // TODO: Optimize code here
        const body = {
          question: values.question,
          point_for: values.point_for,
        };

        if (mchatId) dispatch(updateMchat(mchatId, body));
        else dispatch(addMchat(body));
        action.resetForm();
        navigate("/mchat");
      },
    });
  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <Breadcrumb pages={pages} />
      <div>
        <button
          type="button"
          onClick={() => navigate(-1)}
          className="inline-flex items-center rounded-md border mb-6 border-transparent bg-indigo-100 px-3 py-2 text-sm font-medium leading-4 text-indigo-700 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
        >
          <span className="w-5 mr-2">
            <ArrowUturnLeftIcon size={18} />
          </span>{" "}
          Back
        </button>
        <div className="sm:flex-auto">
          <h1 className="text-xl font-semibold text-gray-900">
            {mchatId ? "Update Mchat" : "Add Mchat"}
          </h1>
        </div>
      </div>
      {loading ? (
        <FallingLinesLoader />
      ) : ( 
      <form
        onSubmit={handleSubmit}
        className="space-y-8 divide-y divide-gray-200"
      >
        <div className="space-y-6 sm:space-y-5">
          <div className="space-y-6 sm:space-y-5 sm:pt-5">
            <div className="space-y-6 sm:space-y-5">
              <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                <label
                  htmlFor="question"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Question
                </label>
                <div className="mt-1 sm:col-span-2 sm:mt-0">
                  <textarea
                    type="text"
                    name="question"
                    id="qustion"
                    placeholder="Enter Question"
                    autoComplete="off"
                    value={values.question}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                  />
                  {errors.question && touched.question ? (
                    <p className="text-red-700">{errors.question}</p>
                  ) : null}
                </div>
              </div>

              <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                <label
                  htmlFor="points"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Points
                </label>
                <div className="mt-1 sm:col-span-2 sm:mt-0">
                  <select
                    id="point"
                    name="point_for"
                    autoComplete="off"
                    value={values.point_for}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                  >
                    <option>Select a Point</option>
                    <option value="YES">Yes</option>
                    <option value="NO">No</option>
                  </select>
                  {errors.points && touched.points ? (
                    <p className="text-red-700">{errors.points}</p>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="pt-5">
          <div className="flex justify-end">
            <button
              type="submit"
              className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            >
              Submit
            </button>
          </div>
        </div>
      </form>
      )}
    </div>
  );
};

export default Addamcat;
