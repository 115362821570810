import axios from "axios";
import { useState, useEffect } from "react";
import { Form } from "react-router-dom";

export default function File_Input(props) {
  console.log("File_Input", props);
  let data = props.config;
  const ref_id = data?.ref_id;
  const val = props?.current_data?.[ref_id];
  let fielsinput = {
    image: "image/*",
    video: "video/*",
    media: "image/*,audio/*,video/*",
    all: "*",
    excel:
      ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel",
    pdf: ".pdf",
  };
  let [visibility, set_visibility] = useState(true);
  useEffect(() => {
    if (data.child) {
      let foi = Object.keys(props.all_fields).filter((field) => {
        if (props.all_fields[field].label.trim() == data.parent?.label.trim()) {
          return props.all_fields[field];
        }
      });
      if (
        props.current_data[props.all_fields[foi]?.ref_id] == data.parent?.option
      ) {
        set_visibility(true);
      } else {
        if (
          props.current_data[data.ref_id] != null ||
          data.defaultValue != null
        ) {
          props.record_change(null, data.ref_id);
          data.defaultValue = null;
        }
        set_visibility(false);
      }
    }
  }, [props.current_data]);
  return (
    <>
      {visibility ? (
        <div className="border-b pl-5 pb-5">
          <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4  sm:border-gray-200 ">
            <label
              htmlFor="first-name"
              className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
            >
              {data.label}
            </label>
            <div className="mt-1 sm:col-span-2 sm:mt-0 ">
              <input
                type="file"
                required={data.required}
                accept={fielsinput[data.file_Type]}
                onChange={async (e) => {
                  const formData = new FormData();
                  formData.append("file", e.target.files[0]);
                  const res = await axios.post(
                    `${process.env.REACT_APP_API_URL}/api/form/form-builder/upload/local`,
                    formData
                  );
                  const link = res.data.data.split("uploads")[1];
                  const finalLink = `${process.env.REACT_APP_API_URL}/uploads${link}`;
                  props.record_change(finalLink, data.ref_id);
                }}
                class="block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-blue-50 file:text-blue-700 hover:file:bg-blue-100"
              />
              {val && val.split("/")[val.split("/").length - 1]}
            </div>{" "}
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
}
