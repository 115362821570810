import React from 'react'
import Breadcrumb from "../../../components/Breadcrumb";
import { Link } from "react-router-dom";
import Table from '../../../components/tables/table';
import { prescription_columns } from '../../../components/tables/tableheader';

const people =[
  {
    patientName: "Nitesh verma",
    doctorName: "Ajay chauhan",
    patientAge: 24,
    referBy: "ravi singh",
    hospital: "hospital 1"
  },
  {
    patientName: "ajay verma",
    doctorName: "Sudhir chauhan",
    patientAge: 24,
    referBy: "Sanath shetty",
    hospital: "hospital 2"
  },
  {
    patientName: "Priyanshu verma",
    doctorName: "Nitesh chauhan",
    patientAge: 24,
    referBy: "Ajay singh",
    hospital: "hospital 3"
  },
  {
    patientName: "Sanat Shetty",
    doctorName: "Ajay chauhan",
    patientAge: 24,
    referBy: "Priyanshu singh",
    hospital: "hospital 4"
  },
  {
    patientName: "Priyanshu verma",
    doctorName: "Sudhir chauhan",
    patientAge: 24,
    referBy: "sanjay singh",
    hospital: "hospital 5"
  }
]


function Prescription() {
  const pages = [{ title: "Prescription", href: "/prescription" }];

  return (
    <div>
      <Breadcrumb pages={pages} />
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-xl font-semibold text-gray-900">Prescriptions</h1>
          <p className="mt-2 text-sm text-gray-700">
            Follow the doctor Suggestion and take medicine time to time.
          </p>
        </div>
        <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
          <input
            type="text"
            className="inline-flex mr-2 items-center justify-center rounded-md border border-transparent border-gray-400  px-4 py-2 text-sm font-medium shadow-sm  focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
            placeholder="Search"
          />
          <Link
            to="/add-prescription"
            type="button"
            className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
          >
            Add Prescription
          </Link>
        </div>
      </div>
      {/* <div className="mt-8 flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                      Patient Name
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Doctor Name
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Patient Age
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Refer By
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Hospital
                    </th>
                    <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                      Action
                      <span className="sr-only">Edit</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {people.map((person) => (
                    <tr key={person.email}>
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                        {person.patientName}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{person.doctorName}</td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{person.patientAge}</td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{person.referBy}</td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{person.hospital}</td>
                      <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                        <a href="#" className="text-indigo-600 hover:text-indigo-900">
                          Edit<span className="sr-only"></span>
                        </a>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div> */}
      <Table
            columns={prescription_columns()}
            data={people}
            // onEditClose={cleanModalData}
          />
    </div>
  )
}

export default Prescription