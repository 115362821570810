export const GET_ALL_AGE_GROUP_BEGIN = "GET_ALL_AGE_GROUP_BEGIN"
export const GET_ALL_AGE_GROUP_SUCCESS = "GET_ALL_AGE_GROUP_SUCCESS"
export const GET_ALL_AGE_GROUP_ERROR = "GET_ALL_AGE_GROUP_ERROR"

export const ADD_AGE_GROUP_BEGIN = "ADD_AGE_GROUP_BEGIN"
export const ADD_AGE_GROUP_SUCCESS = "ADD_AGE_GROUP_SUCCESS"
export const ADD_AGE_GROUP_ERROR = "ADD_AGE_GROUP_ERROR"

export const GET_AGE_GROUP_BEGIN = "GET_AGE_GROUP_BEGIN"
export const GET_AGE_GROUP_SUCCESS = "GET_AGE_GROUP_SUCCESS"
export const GET_AGE_GROUP_ERROR = "GET_AGE_GROUP_ERROR"

export const UPDATE_AGE_GROUP_BEGIN = "UPDATE_AGE_GROUP_BEGIN"
export const UPDATE_AGE_GROUP_SUCCESS = "UPDATE_AGE_GROUP_SUCCESS"
export const UPDATE_AGE_GROUP_ERROR = "UPDATE_AGE_GROUP_ERROR"

export const DELETE_AGE_GROUP_BEGIN = "DELETE_AGE_GROUP_BEGIN"
export const DELETE_AGE_GROUP_SUCCESS = "DELETE_AGE_GROUP_SUCCESS"
export const DELETE_AGE_GROUP_ERROR = "DELETE_AGE_GROUP_ERROR"