import { React, useState } from 'react'
import Breadcrumb from "../../../components/Breadcrumb";
import { Link, useNavigate } from "react-router-dom";
import { ArrowUturnLeftIcon } from '@heroicons/react/24/solid';
import Table from '../../../components/tables/table';
import { patient_invoice_columns } from '../../../components/tables/tableheader';

const patient = [
    {
        doctor: "Ajay Chauhan",
        date: "22/12/2022",
        phone: "7235842003",
        total: 2000,
        balance: 500,
    },
    {
        doctor: "Priyanshu Singh",
        date: "12/12/2022",
        phone: "98765432345",
        total: 5000,
        balance: 700,
    },
    {
        doctor: "Sudhir Kushwaha",
        date: "08/12/2022",
        phone: "56789876543",
        total: 6500,
        balance: 800,
    },
    {
        doctor: "Nitesh Verma",
        date: "05/12/2022",
        phone: "7235842003",
        total: 2800,
        balance: 800,
    }
]

const Invoices = () => {
    const pages = [{ title: "Invoices", href: "/invoices" }];
    const [search, setsearch] = useState("");
    const navigate = useNavigate();


    return (
        <div>
            <Breadcrumb pages={pages} />
            <div className="px-4 sm:px-6 lg:px-8">
                <div className="sm:flex-auto">
                    <button
                        type="button"
                        onClick={() => navigate(-1)}
                        className="inline-flex items-center rounded-md border mb-6 border-transparent bg-indigo-100 px-3 py-2 text-sm font-medium leading-4 text-indigo-700 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    >
                        <span className="w-5 mr-2"><ArrowUturnLeftIcon size={18} /></span> Back
                    </button>
                </div>
                <div className="sm:flex sm:items-center">
                    <div>
                        <h1 className="text-xl font-semibold text-gray-900">Invoices</h1>
                        <p className="mt-1 max-w-2xl text-sm text-gray-500">
                            A list of all the patient Invoices in your account including their name,
                            title, email and role.
                        </p>
                    </div>
                    <div className="mt-4 sm:mt-0 sm:flex-none ml-auto">
                        <input
                            type="text"
                            onChange={(e) => setsearch(e.target.value.toLocaleLowerCase())}
                            className="inline-flex mr-2 items-center justify-center rounded-md border border-transparent border-gray-400  px-4 py-2 text-sm font-medium shadow-sm  focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
                            placeholder="Search"
                        />
                        <Link
                            to="/add-invoice"
                            type="button"
                            className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
                        >
                            Add Invoice
                        </Link>
                    </div>
                </div>
                <Table
                    columns={patient_invoice_columns()}
                    data={patient}
                // onEditClose={cleanModalData}
                />
            </div>
        </div>
    )
}

export default Invoices