import axios from 'axios'
import { getHeaders } from '../auth/actions'
import { toast } from 'react-toast'
import {
    GET_ALL_STRENGTH_BEGIN,
    GET_ALL_STRENGTH_SUCCESS,
    GET_ALL_STRENGTH_ERROR,
    ADD_STRENGTH_BEGIN,
    ADD_STRENGTH_SUCCESS,
    ADD_STRENGTH_ERROR,
    GET_STRENGTH_BEGIN,
    GET_STRENGTH_ERROR,
    GET_STRENGTH_SUCCESS,
    UPDATE_STRENGTH_BEGIN,
    UPDATE_STRENGTH_SUCCESS,
    UPDATE_STRENGTH_ERROR,
    DELETE_STRENGTH_BEGIN,
    DELETE_STRENGTH_SUCCESS,
    DELETE_STRENGTH_ERROR,
  } from "./constants";
  
const API = process.env.REACT_APP_API_URL

export const getAllStrengths = () => async(dispatch) => {
  try {
    dispatch({
      type: GET_ALL_STRENGTH_BEGIN
    })
    const headers = dispatch(getHeaders());
    const {data} = await axios.get(`${API}/api/medicine/strength-master`, { headers })
    console.log(data.data)

    dispatch({
      type: GET_ALL_STRENGTH_SUCCESS, 
      data: data.data
    })
  }
  catch (error) {
    const msg = error.response.data.message;
    toast.error(msg)
    console.log(error)
    dispatch({
      type: GET_ALL_STRENGTH_ERROR,
      error: msg
    })
  }
}

export const addStrength = (body) => async(dispatch) => {
    try {
        dispatch({
          type: ADD_STRENGTH_BEGIN
        })
        const headers = dispatch(getHeaders());
        const {data} = await axios.post(`${API}/api/medicine/strength-master`, body, { headers })
        console.log(data.data)
        toast.success("Strength added successfully!")
        dispatch({
          type: ADD_STRENGTH_SUCCESS, 
          data: data.data
        })
      }
      catch (error) {
        console.log(error.response)
        const msg = error.response.data.message;
        toast.error(msg)
        dispatch({
          type: ADD_STRENGTH_ERROR,
          error: msg
        })
      }
}

export const getStrengthWithId = (id) => async(dispatch) => {
  try {
    dispatch({
      type: GET_STRENGTH_BEGIN
    })
    const headers = dispatch(getHeaders());
    const {data} = await axios.get(`${API}/api/medicine/strength-master/${id}`, { headers })
    console.log(data.data)
    dispatch({
      type: GET_STRENGTH_SUCCESS, 
      data: data.data
    })
  }
  catch (error) {
    console.log(error.response)
    const msg = error.response.data.message;
    toast.error(msg)
    dispatch({
      type: GET_STRENGTH_ERROR,
      error: msg
    })
  }
}

export const updateStrength = (id, body) => async(dispatch) => {
  try {
    dispatch({
      type: UPDATE_STRENGTH_BEGIN
    })
    const headers = dispatch(getHeaders());
    const {data} = await axios.put(`${API}/api/medicine/strength-master/${id}`, body, { headers })
    console.log(data.data)
    toast.success("Strength updated successfully!")
    dispatch({
      type: UPDATE_STRENGTH_SUCCESS, 
      data: data.data
    })
  }
  catch (error) {
    const msg = error.response.data.message;
    toast.error(msg)
    dispatch({
      type: UPDATE_STRENGTH_ERROR,
      error: msg
    })
  }
}

export const deleteStrength = (id) => async(dispatch) => {
  try {
    dispatch({
      type: DELETE_STRENGTH_BEGIN
    })
    const headers = dispatch(getHeaders());
    const {data} = await axios.delete(`${API}/api/medicine/strength-master/${id}`, { headers })
    toast.success("Strength deleted successfully!")
    dispatch({
      type: DELETE_STRENGTH_SUCCESS, 
      data: data.data
    })
  }
  catch (error) {
    const msg = error.response.data.message;
    toast.error(msg)
    dispatch({
      type: DELETE_STRENGTH_ERROR,
      error: msg
    })
  }
}