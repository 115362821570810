import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "../../../components/Pagination";
import { getAllForms } from "../../../redux/forms/action";
import { FallingLinesLoader } from "../../../components/spinners/Spinner";
import Breadcrumb from "../../../components/Breadcrumb";
import { forms_columns } from "../../../components/tables/tableheader";
import Table from "../../../components/tables/table";

const FormList = () => {
  // const pages = [{ title: "Doctor", href: "/doctors" }]
  const dispatch = useDispatch();
  const { loading, list: formList } = useSelector((state) => state.forms);

  useEffect(() => {
    document.title = "New Horizon | Forms-Master";
    dispatch(getAllForms());
  }, []);

  const index = () => {
    formList.map((form, index) => {});
  };

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-xl font-semibold text-gray-900">Forms</h1>
        </div>
        <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none"></div>
      </div>

      {loading ? (
        <FallingLinesLoader />
      ) : (
        <Table data={formList} columns={forms_columns()} />
      )}
    </div>
  );
};

export default FormList;
