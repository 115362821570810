import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import { ArrowUturnLeftIcon } from "@heroicons/react/20/solid";
import Breadcrumb from "../../../../components/Breadcrumb";
import { FallingLinesLoader } from "../../../../components/spinners/Spinner";
import moment from "moment";
import * as Yup from "yup";
import axios from "axios";
import { toast } from "react-hot-toast";
import { getHeaders } from "../../../../redux/auth/actions";
import { useDispatch } from "react-redux";

const api = process.env.REACT_APP_API_URL;
const AddFollowUp = () => {
  const navigate = useNavigate();
  const pages = [{ title: "Add Follow Up" }];
  const { id } = useParams();
  // const loading = false;
  const dispatch = useDispatch();

  // console.log("id" + locationId + "data" + locationData)

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: {
        patient_recommendation_id: id,
        comment: "",
        date: "",
        time: "",
      },
      validationSchema: Yup.object({
        comment: Yup.string().optional(),
        date: Yup.string().required("Date is required"),
        time: Yup.string().required("Time is required"),
      }),
      onSubmit: (values, action) => {
        const body = {
          patient_recommendation_id: Number(values.patient_recommendation_id),
          comment: values.comment,
          date: moment(values.date).format(),
          time: moment(values.time, "HH:mm").format(),
        };

        console.log(body);
        createFollowUp(body, action);
      },
    });

  async function createFollowUp(body, action) {
    const headers = await dispatch(getHeaders());
    try {
      const res = await axios.post(
        `${api}/api/program/patient-recommendation/follow-up-logs/add`,
        body,
        {
          headers: headers,
        }
      );
      const { data, status } = res;
      if (data) {
        toast.success("Follow Up Added Successfully");
        // navigate(-1);
        action.resetForm();
      }
    } catch (error) {
      toast.error(error.response.data.message);
    }
  }

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <Breadcrumb pages={pages} />
      <div>
        <button
          type="button"
          onClick={() => navigate(-1)}
          className="inline-flex items-center rounded-md border mb-6 border-transparent bg-indigo-100 px-3 py-2 text-sm font-medium leading-4 text-indigo-700 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
        >
          <span className="w-5 mr-2">
            <ArrowUturnLeftIcon size={18} />
          </span>{" "}
          Back
        </button>
        <div className="sm:flex-auto">
          <h1 className="text-xl font-semibold text-gray-900">Add Follow Up</h1>
        </div>
      </div>

      <form
        onSubmit={handleSubmit}
        className="space-y-8 divide-y divide-gray-200"
      >
        <div className="space-y-6 sm:space-y-5">
          <div className="space-y-6 sm:space-y-5 sm:pt-5">
            <div className="space-y-6 sm:space-y-5">
              <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                <label
                  htmlFor="location_name"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Date
                </label>
                <div className="mt-1 sm:col-span-2 sm:mt-0">
                  <input
                    type="date"
                    name="date"
                    id="date"
                    autoComplete="off"
                    value={values.date}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                  />
                  {errors.date && touched.date ? (
                    <p className="text-red-700">{errors.date}</p>
                  ) : null}
                </div>
              </div>

              <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                <label
                  htmlFor="location-code"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Time
                </label>
                <div className="mt-1 sm:col-span-2 sm:mt-0">
                  <input
                    type="time"
                    name="time"
                    id="location-code"
                    autoComplete="off"
                    value={values.time}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                  />
                  {errors.time && touched.time ? (
                    <p className="text-red-700">{errors.time}</p>
                  ) : null}
                </div>
              </div>
              <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                <label
                  htmlFor="location-code"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Comment
                </label>
                <div className="mt-1 sm:col-span-2 sm:mt-0">
                  <textarea
                    type="number"
                    name="comment"
                    id="location-code"
                    autoComplete="off"
                    placeholder="Enter Comment"
                    value={values.comment}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                  />
                  {/* {errors.location_code && touched.location_code ? (
                      <p className="text-red-700">{errors.location_code}</p>
                    ) : null} */}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="pt-5">
          <div className="flex justify-end">
            <button
              type="submit"
              className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            >
              Submit
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AddFollowUp;
