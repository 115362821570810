import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import { locationSchema } from "../../../schemas";
import { ArrowUturnLeftIcon } from "@heroicons/react/20/solid";
import { useDispatch, useSelector } from "react-redux";
import {
  addRouteOfAdministration,
  getRouteOfAdministrationWithId,
  updateRouteOfAdministration,
} from "../../../redux/Route of adminstration/actions";
import { FallingLinesLoader } from "../../../components/spinners/Spinner";
import Breadcrumb from "../../../components/Breadcrumb";
import axios from "axios";
const API = process.env.REACT_APP_API_URL;
const AddRouteOfAdministration = () => {
  const pages = [
    {
      title: "Route Of Administration",
      href: "/route-of-administration-master",
    },
  ];
  const [options, setOptions] = useState("")
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id: RouteOfAdministrationId } = useParams();
  const { loading, RouteOfAdministrationData } = useSelector(
    (state) => state.RouteOfAdministration
  );

  const initialValues = {
    route_of_administration: "",
  };

  const [formState, setFormState] = useState(initialValues);
  const optList = async () => {
    await axios
      .get(`${API}/api/medicine/master/info/all`)
      .then((res) => {
      const  data = res.data;
        console.log(data.data.route_of_administration);
        setOptions(data.data.route_of_administration);
      });
  };
  useEffect(() => {
    optList();
    document.title = "New Horizon | Add-Location";
    if (RouteOfAdministrationId)
      dispatch(getRouteOfAdministrationWithId(RouteOfAdministrationId));
  }, [RouteOfAdministrationId]);

  useEffect(() => {
    if (RouteOfAdministrationId && RouteOfAdministrationData) {
      const newFormState = {
        route_of_administration:
          RouteOfAdministrationData.route_of_administration,
      };
      setFormState(newFormState);
    }
  }, [RouteOfAdministrationId, RouteOfAdministrationData]);

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      enableReinitialize: true,
      initialValues: formState,
      onSubmit: (values, action) => {
        // TODO: Optimize code here
        const body = {
          route_of_administration: values.route_of_administration,
        };
        if (RouteOfAdministrationId)
          dispatch(updateRouteOfAdministration(RouteOfAdministrationId, body));
        else dispatch(addRouteOfAdministration(body));
        action.resetForm();
        navigate("/route-of-administration-master");
      },
    });
  // console.log(errors);
  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <Breadcrumb pages={pages} />
      <div>
        <button
          type="button"
          onClick={() => navigate(-1)}
          className="inline-flex items-center rounded-md border mb-6 border-transparent bg-indigo-100 px-3 py-2 text-sm font-medium leading-4 text-indigo-700 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
        >
          <span className="w-5 mr-2">
            <ArrowUturnLeftIcon size={18} />
          </span>{" "}
          Back
        </button>
        <div className="sm:flex-auto">
          <h1 className="text-xl font-semibold text-gray-900">
            {RouteOfAdministrationId
              ? "Update Route Of Administration"
              : "Add Route Of Administration"}
          </h1>
        </div>
      </div>
      {loading ? (
        <FallingLinesLoader />
      ) : (
        <form
          onSubmit={handleSubmit}
          className="space-y-8 divide-y divide-gray-200"
        >
          <div className="space-y-6 sm:space-y-5">
            <div className="space-y-6 sm:space-y-5 sm:pt-5">
              <div className="space-y-6 sm:space-y-5">
                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                  <label
                    htmlFor="route_of_administration"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Route Of Administration
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                  <input
                    type="text"
                    name="route_of_administration"
                    id="route_of_administration"
                    autoComplete="off"
                    onChange={handleChange}
                    placeholder="Route Of Administration"
                    onBlur={handleBlur}
                    value={values.route_of_administration}
                    className="block w-1/2 min-w-0 flex-1 rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  />
                    {errors.strength && touched.strength ? (
                      <p className="text-red-700">{errors.strength}</p>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="pt-5">
            <div className="flex justify-end">
              <button
                type="submit"
                className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              >
                Submit
              </button>
            </div>
          </div>
        </form>
      )}
    </div>
  );
};

export default AddRouteOfAdministration;
