import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import { ProgramSessionSchema } from "../../../schemas";
import { ArrowUturnLeftIcon } from "@heroicons/react/20/solid";
import { useDispatch, useSelector } from "react-redux";
import { addProgramSession, getProgramSessionWithId, updateProgramSession} from "../../../redux/ProgramSessionMaster/actions";
import { getAllPrograms } from "../../../redux/ProgramMaster/actions";
import { getAllDepartments } from "../../../redux/department/actions";

import { FallingLinesLoader } from "../../../components/spinners/Spinner";
import Breadcrumb from "../../../components/Breadcrumb";
const AddProgramSessions = () => {
  const pages = [{ title: "Program Session", href: "/Program-session" }];

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id: programSessionId } = useParams();
  const { loading, programSessionData } = useSelector((state) => state.programSession);
  const { list: programMasterList } = useSelector((state) => state.programMaster);
  const { list: departmentList } = useSelector((state) => state.department);

  const initialValues = {
    program_id: "",
    department_id: "",
    frequency: "",
    frequency_type: "",

    
  };

  const [formState, setFormState] = useState(initialValues);

  useEffect(() => {
    document.title = "New Horizon | Add-Program-Session";
    dispatch(getAllPrograms());
    dispatch(getAllDepartments());
    if (programSessionId) dispatch(getProgramSessionWithId(programSessionId));
  }, [programSessionId]);

  useEffect(() => {
    if (programSessionId && programSessionData) {
      const newFormState = {
        program_id: parseInt(programSessionData.program_id),
        department_id: parseInt(programSessionData.department_id),
        frequency: parseInt(programSessionData.frequency),
        frequency_type:programSessionData.frequency_type
      };
      setFormState(newFormState);
    }
  }, [programSessionId, programSessionData]);

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      enableReinitialize: true,
      initialValues: formState,
      validationSchema: ProgramSessionSchema,
      onSubmit: (values, action) => {
        // TODO: Optimize code here
        const body = {
          program_id: parseInt(values.program_id),
          department_id: parseInt(values.department_id),
          frequency: parseInt(values.frequency),
          frequency_type: values.frequency_type,
        };
        if (programSessionId) dispatch(updateProgramSession(programSessionId, body));
        else dispatch(addProgramSession(body));
        action.resetForm();
        navigate("/program-session");
      },
    });
  return (
    <div className="px-4 sm:px-6 lg:px-8">
    <Breadcrumb pages={pages} />
    <div>
      <button
        type="button"
        onClick={() => navigate(-1)}
        className="inline-flex items-center rounded-md border mb-6 border-transparent bg-indigo-100 px-3 py-2 text-sm font-medium leading-4 text-indigo-700 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
      >
        <span className="w-5 mr-2">
          <ArrowUturnLeftIcon size={18} />
        </span>{" "}
        Back
      </button>
      <div className="sm:flex-auto">
        <h1 className="text-xl font-semibold text-gray-900">
          {programSessionId ? "Update Program Session" : "Add Program Session"}
        </h1>
      </div>
    </div>
    {loading ? (
      <FallingLinesLoader />
    ) : (
      <form
        onSubmit={handleSubmit}
        className="space-y-8 divide-y divide-gray-200"
      >
        <div className="space-y-6 sm:space-y-5">
          <div className="space-y-6 sm:space-y-5 sm:pt-5">
            <div className="space-y-6 sm:space-y-5">
              
              <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                <label
                  htmlFor="program_id"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Program
                </label>
                <div className="mt-1 sm:col-span-2 sm:mt-0">
                  <select
                    id="program_id"
                    name="program_id"
                    autoComplete="off"
                    value={values.program_id}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                  >
                    <option className="text-gray-700" value="">
                      Select a Program
                    </option>

                    {programMasterList.map((d, idx) => (
                      <option key={idx} value={d.id}>
                        {d.name}
                      </option>
                    ))}
                  </select>
                  {errors.program_id && touched.program_id ? (
                    <p className="text-red-700">{errors.program_id}</p>
                  ) : null}
                </div>
              </div>
              <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                <label
                  htmlFor="department_id"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Department 
                </label>
                <div className="mt-1 sm:col-span-2 sm:mt-0">
                  <select
                    id="department_id"
                    name="department_id"
                    autoComplete="off"
                    value={values.department_id}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                  >
                    <option className="text-gray-700" value="">
                      Select a Department
                    </option>

                    {departmentList.map((d, idx) => (
                      <option key={idx} value={d.id}>
                        {d.name}
                      </option>
                    ))}
                  </select>
                  {errors.department_id && touched.department_id ? (
                    <p className="text-red-700">{errors.department_id}</p>
                  ) : null}
                </div>
              </div>
              <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                  <label
                    htmlFor="frequency"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Frequency
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <input
                      type="number"
                      name="frequency"
                      id="frequency"
                      autoComplete="off"
                      value={values.frequency}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                    />
                    {errors.frequency && touched.frequency ? (
                      <p className="text-red-700">{errors.frequency}</p>
                    ) : null}
                  </div>
                </div>
              <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                <label
                  htmlFor="frequency_type"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Frequency Type
                </label>
                <div className="mt-1 sm:col-span-2 sm:mt-0">
                  <select
                    id="frequency_type"
                    name="frequency_type"
                    autoComplete="off"
                    value={values.frequency_type}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                  >
                    <option className="text-gray-700" value="">
                      Select a Frequency Type
                    </option>
                    <option className="text-gray-700" value="WEEK">
                      Weak
                    </option>

                    {/* {ageGroupList.map((d, idx) => (
                      <option key={idx} value={d.id}>
                        {d.name}
                      </option>
                    ))} */}
                  </select>
                  {errors.frequency_type && touched.frequency_type ? (
                    <p className="text-red-700">{errors.frequency_type}</p>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="pt-5">
          <div className="flex justify-end">
            <button
              type="submit"
              className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            >
              Submit
            </button>
          </div>
        </div>
      </form>
    )}
  </div>
  )
}

export default AddProgramSessions