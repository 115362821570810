import { Link, useNavigate, useParams } from "react-router-dom";
import Breadcrumb from "../../../components/Breadcrumb";
import { ArrowUturnLeftIcon } from "@heroicons/react/20/solid";
import { FaHospitalAlt, FaUserAlt } from "react-icons/fa";
import { HiClock } from "react-icons/hi";
import { BsFillTelephoneFill } from "react-icons/bs";
import { RiEarthFill } from "react-icons/ri";
import { FallingLinesLoader } from "../../../components/spinners/Spinner";
import Table from "../../../components/tables/table";
import {
  improvment_colums,
  last_current_concer_improvements_column,
  patient_view_evaluation_colums,
} from "../../../components/tables/tableheader";
import ConfirmationModal from "../../../components/ConfirmationModal";
import { useEffect, useState } from "react";
import { getHeaders } from "../../../redux/auth/actions";
import axios from "axios";
import moment from "moment";
import { toast } from "react-hot-toast";
import { useDispatch } from "react-redux";
import { Formik, useFormik } from "formik";
import * as Yup from "yup";
import Modal from "../../../components/Modal";
import Select from "react-select";
const API = process.env.REACT_APP_API_URL;

function PatientView() {
  const initialModalState = {
    type: "",
    state: false,
    index: null,
    edit_id: "",
    data: {
      purchase_order_id: "",
      storage_location_id: "",
      quantity: "",
      item_id: "",
    },
  };
  const pages = [{ title: "View Patient", href: "/view-patient" }];
  const [patientData, setpatientData] = useState({
    loading: true,
    details: {},
  });
  const [commentData, setCommentData] = useState({
    loading: true,
    details: {},
    newComment: "",
  });

  const [improvementsData, setImprovementsData] = useState({
    loading: false,
    data: [],
  });

  const { id } = useParams();
  const navigate = useNavigate();
  const [confirmationModal, setConfirmationModal] = useState({
    status: false,
  });
  const [ResolvedConfirmationModal, setResolvedConfirmationModal] = useState({
    status: false,
    id: null,
  });
  async function markResolved(id) {
    const headers = await dispatch(getHeaders());
    try {
      const response = await axios.put(
        `${API}/api/red-flags/rf-comment-resolve/${id}`,
        {},
        {
          headers,
        }
      );
      const { data } = response;
      toast.success(data.message);
      setCommentData((prevState) => ({
        ...prevState,
        details: prevState.details.map((item) => {
          if (item.id === id) {
            item.status = "RESOLVED";
          }
          return item;
        }),
      }));
      setResolvedConfirmationModal((prevState) => ({
        ...prevState,
        status: false,
        id: null,
      }));
    } catch (error) {
      toast.error(error.message);
    }
  }
  const dispatch = useDispatch();
  useEffect(() => {
    getPatientData();
    getCommentData();
    getImprovementsData();
    document.title = "New Horizon | View Patient";
  }, [id]);
  async function getImprovementsData() {
    setImprovementsData({
      ...improvementsData,
      loading: true,
    });
    const headers = await dispatch(getHeaders());
    try {
      const response = await axios.get(
        `${API}/api/form/form-builder/last-current-concern/${id}`,
        {
          headers,
        }
      );
      const { data } = response;
      setTimeout(() => {
        setImprovementsData((prevState) => ({
          ...prevState,
          loading: false,
          data: data.data,
        }));
      }, 2000);
    } catch (error) {
      setImprovementsData((prevState) => ({
        ...prevState,
        loading: false,
      }));
    } finally {
      setTimeout(() => {
        setpatientData((prevState) => ({ ...prevState, loading: false }));
      }, 2000);
    }
  }
  async function getPatientData() {
    setpatientData({
      loading: true,
    });
    const headers = await dispatch(getHeaders());
    try {
      const response = await axios.get(`${API}/api/enrollment/view/${id}`, {
        headers,
      });
      const { data } = response;
      setpatientData((prevState) => ({
        ...prevState,
        details: data.data,
      }));
    } catch (error) {
      toast.error(error.message);
    } finally {
      setpatientData((prevState) => ({ ...prevState, loading: false }));
    }
  }
  async function getCommentData() {
    setCommentData({
      loading: true,
    });
    const headers = await dispatch(getHeaders());
    try {
      const response = await axios.get(
        `${API}/api/red-flags/rf-comment/${id}`,
        {
          headers,
        }
      );
      const { data } = response;
      setCommentData((prevState) => ({
        ...prevState,
        details: data.data,
      }));
    } catch (error) {
      toast.error(error.message);
    } finally {
      setCommentData((prevState) => ({ ...prevState, loading: false }));
    }
  }
  const LoggedInUser = JSON.parse(localStorage.getItem("newhorizon_user"));
  async function addComment(action, comment, isRedFlag) {
    const headers = await dispatch(getHeaders());
    if (commentData.newComment === "") {
      toast.error("Comment cannot be empty");
      return;
    } else {
      try {
        const response = await axios.post(
          `${API}/api/red-flags/add-red-flag-comment`,
          {
            enrollment_id: Number(id),
            commented_by_user_id: Number(LoggedInUser.id),
            comment: comment,
          },
          { headers }
        );
        const { data } = response;
        toast.success(data.message);
        action.resetForm();
        if (data.data) {
          if (isRedFlag === "redFlag") {
            setAddRedFlagModal((prevState) => ({
              ...prevState,
              state: true,
              edit_id: data.data.id,
            }));
          } else {
            setCommentData((prevState) => ({
              ...prevState,
              details: [...prevState.details, data.data],
            }));
          }
        }
      } catch (error) {
        toast.error(error.message);
      }
    }
  }
  function stringToArray(str) {
    if (str.startsWith(",")) {
      str = str.substr(1);
    }
    if (str.includes(",")) {
      return str.split(",");
    } else {
      return [str];
    }
  }
  const [redFlagModal, setAddRedFlagModal] = useState(initialModalState);
  const roles = [
    { title: "Doctor", value: "DOCTOR" },
    { title: "Super Admin", value: "SUPER_ADMIN" },
    { title: "Non clinical admin", value: "CLINICAL_ASSISTANT" },
    { title: "Reception Manager", value: "RECEPTION_MANAGER" },
    { title: "Clinical Head", value: "CLINICAL_HEAD" },
    { title: "Clinical Admin", value: "CLINICAL_ADMIN" },
  ];
  const addRedFlagModal = () => {
    const { type, state, data, edit_id } = redFlagModal;

    return (
      <Modal
        title={`Add Red Flag`}
        size="max-w-sm"
        open={state}
        setOpen={() =>
          setAddRedFlagModal((prev) => ({ ...prev, state: false, edit_id: "" }))
        }
      >
        <div className="mt-2 w-full flex items-center justify-center">
          <Formik
            initialValues={{
              target_users_type: [],
            }}
            enableReinitialize={true}
            onSubmit={async (values, action, e) => {
              const headers = await dispatch(getHeaders());
              try {
                const response = await axios.put(
                  `${API}/api/red-flags/rf-comment-mark/${edit_id}`,
                  {
                    target_users_type: values.target_users_type,
                    enrollment_id: Number(id),
                    generatedBy: JSON.parse(
                      localStorage.getItem("newhorizon_user")
                    ).id,
                  },
                  { headers }
                );
                const { data } = response;
                toast.success(data.message);
                action.resetForm();
                setAddRedFlagModal((prev) => ({
                  ...prev,
                  state: false,
                  edit_id: "",
                }));
                getCommentData();
              } catch (error) {
                toast.error(error.message);
              }
            }}
          >
            {(props) => (
              <form
                onSubmit={props.handleSubmit}
                className="flex flex-col w-full"
              >
                <div className="grid grid-cols-1 gap-4">
                  {roles?.map((item, idx) => (
                    <div
                      key={idx}
                      className="p-4 bg-white rounded-lg shadow-md border-t border-gray-200"
                    >
                      <div className="flex items-center  pb-2">
                        <input
                          type="checkbox"
                          name="target_users_type"
                          value={item.value}
                          checked={props.values.target_users_type.includes(
                            item.value
                          )}
                          onChange={(event) => {
                            if (event.target.checked) {
                              props.setFieldValue("target_users_type", [
                                ...props.values.target_users_type,
                                item.value,
                              ]);
                            } else {
                              props.setFieldValue(
                                "target_users_type",
                                props.values.target_users_type.filter(
                                  (i) => i !== item.value
                                )
                              );
                            }
                          }}
                          id={item.title}
                          className="mr-2"
                        />

                        <label htmlFor={item.title}>
                          <h1 className="text-md font-semibold text-gray-900 text-left">
                            {item?.title}
                          </h1>
                        </label>
                      </div>

                      {/* please create a div and add a close button */}
                    </div>
                  ))}
                </div>

                <button
                  type="submit"
                  className="inline-flex mt-2 w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm"
                >
                  Add Red Flag
                </button>
              </form>
            )}
          </Formik>
        </div>
      </Modal>
    );
  };
  const {
    values,
    setValues,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: {
      comment: "",
      submitButton: "",
    },
    validationSchema: Yup.object({
      comment: Yup.string().required("Comment is required"),
    }),
    onSubmit: (values, action) => {
      //TODO: Optimize code here
      addComment(action, values.comment, values.submitButton);
    },
  });
  console.log(values);
  return patientData.loading ? (
    <FallingLinesLoader />
  ) : (
    <div className=" ">
      <Breadcrumb pages={pages} />
      {addRedFlagModal()}
      <ConfirmationModal
        open={confirmationModal.status}
        setOpen={setConfirmationModal}
        description="This comment will be shared with the patient."
        confirmationButtonText="Share"
        confirmationButtonColor="green"
      />
      <ConfirmationModal
        open={ResolvedConfirmationModal.status}
        setOpen={setResolvedConfirmationModal}
        description="You are about to mark this comment as resolved."
        confirmationButtonText="Resolve"
        onDelete={() => {
          markResolved(ResolvedConfirmationModal.id);
        }}
        confirmationButtonColor="indigo"
      />
      <div className="w-full">
        <button
          type="button"
          onClick={() => navigate(-1)}
          className="inline-flex items-center rounded-md border mb-6 border-transparent bg-indigo-100 px-3 py-2 text-sm font-medium leading-4 text-indigo-700 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
        >
          <span className="w-5 mr-2">
            <ArrowUturnLeftIcon size={18} />
          </span>{" "}
          Back
        </button>
      </div>
      <div className="sm:flex-auto mb-5">
        <h1 className="text-lg font-medium leading-6 text-gray-900">
          View Patient
        </h1>
        <p className="mt-1 max-w-2xl text-sm text-gray-500">
          This information will be displayed publicly so be careful what you
          share.
        </p>
      </div>
      {/* patient details start */}
      <div className="flex flex-row gap-5 mt-5 w-full">
        <div className=" border-solid border w-full flex  border-gray-200 bg-gray-50 h-auto pb-4  rounded-md">
          <div className="flex-1">
            <Link
              to="/petient-details"
              className="block text-lg font-bold  px-4 py-4 sm:px-6"
            >
              <h1 className="hover:underline text-gray-900 text-lg  font-medium leading-6">
                {" "}
                {patientData?.details?.patient?.firstName +
                  " " +
                  patientData?.details?.patient?.lastName +
                  "-" +
                  patientData?.details?.patient?.patient_id}
              </h1>
              <span className="font-bold text-gray-700 text-sm">
                Date OF Birth:{" "}
                <span className="text-gray-500 text-sm">
                  {moment(patientData?.details?.patient?.dob).format(
                    "DD/MM/YYYY"
                  )}
                </span>
              </span>
            </Link>
            <div className="text-sm  text-gray-500">
              <div className="px-4 py-1 space-y-1 sm:px-6">
                <p className="text-sm flex items-center gap-2">
                  {" "}
                  <HiClock /> Age:{" "}
                  {patientData?.details?.patient?.age.includes(
                    "days" || "months"
                  )
                    ? patientData?.details?.patient?.age
                    : `${patientData?.details?.patient?.age} years`}
                </p>
                <p className="text-sm flex items-center gap-2">
                  {" "}
                  <BsFillTelephoneFill /> Grade: 10th
                </p>
                <p className="text-sm flex items-center gap-2">
                  {" "}
                  <RiEarthFill /> Board: CBSE
                </p>
                <p className="text-sm flex items-center gap-2">
                  {" "}
                  <RiEarthFill /> Diagnosis: ADHD
                </p>
                <p className="text-sm flex items-center gap-2">
                  {" "}
                  <RiEarthFill /> Referral:{" "}
                  {patientData?.details?.patient?.referral
                    ? patientData?.details?.patient?.referral.name
                    : `N/A`}
                </p>
              </div>
            </div>
          </div>

          <div className="w-[30%] flex items-center justify-center">
            <FaUserAlt size={100} className="text-gray-400" />
          </div>
        </div>
      </div>
      <div className="w-full mt-4 flex items-center justify-between p-4 sm:mt-2 md:mt-2">
        <h3 className="text-sm text-gray-700 font-semibold">
          Last OPD Date :{" "}
          <span className="text-gray-500">
            {patientData?.details?.last_opd_date}
          </span>{" "}
        </h3>
        <Link
          to={`/patient-view/add-plan/${id}`}
          className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          Add Plan
        </Link>
      </div>
      <div className="mt-2 p-4">
        <h1 className=" text-gray-900 text-lg font-medium leading-6">
          Recommendation
        </h1>
        <div className="bg-white border-solid border ml-2 mt-3 mb-5 w-fit border-gray-200  h-auto rounded-md">
          {patientData?.details?.recommendations?.length === 0 ? (
            <div className="flex flex-col items-center py-4 px-4 justify-center">
              <h1 className="text-gray-500 text-lg font-medium leading-6">
                No recommended programs yet or please update location id of
                patient to show recommended programs
              </h1>
            </div>
          ) : (
            <div className="text-sm text-gray-500">
              {patientData?.details?.recommendations?.map((element) => (
                <div className="px-4 py-5 sm:px-6">
                  <h4 className="px-2">Name : {element?.program_name}</h4>
                  <div className="flex flex-row w-fi">
                    {element.details.map((e) => {
                      return (
                        <div className="px-2">
                          <p>Department Name: {e.department_name}</p>
                          <p>Total Session: {e.total_sessions}</p>
                          <p>Total Cost: - {e.department_location_cost} </p>
                        </div>
                      );
                    })}
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
        <div className="mt-2 ">
          <h1 className="hover:underline text-gray-900 text-lg font-medium leading-6">
            Evaluations Recommended
          </h1>
          {Object.keys(patientData?.details?.evaluation_details).length ===
          0 ? (
            <div className="flex flex-col items-center py-4 px-4 justify-center">
              <h1 className="text-gray-500 text-lg font-medium leading-6">
                No Evaluations yet
              </h1>
            </div>
          ) : (
            <div>
              {Object.keys(patientData?.details?.evaluation_details).map(
                (key) => {
                  const val = patientData?.details?.evaluation_details[key];
                  return (
                    val && (
                      <div className="px-4 mt-2 sm:px-6 lg:px-8">
                        <p className="text-sm font-semibold mt-2 mb-4 text-gray-500">
                          {key}
                        </p>
                        <div className="flex items-center mt-1  justify-start">
                          <div className=" w-auto  grid grid-cols-2  justify-end">
                            <div className="w-auto ml-6 mr-1 mb-3">
                              {stringToArray(val).map((e) => {
                                return (
                                  <li className="ml-2 text-sm font-medium text-gray-500 ">
                                    {e}
                                  </li>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  );
                }
              )}
            </div>
          )}

          <div className="mt-2">
            <h1 className="hover:underline text-gray-900 text-lg font-medium leading-6">
              Evaluations
            </h1>
            <div className="w-full">
              {" "}
              <Table
                data={patientData?.details?.evaluation_appointment}
                columns={patient_view_evaluation_colums()}
              />
            </div>
          </div>

          <div className="mt-4">
            <h1 className="hover:underline text-gray-900 text-lg font-medium leading-6">
              Improvements
            </h1>
            <div className="py-1">
              {improvementsData.loading ? (
                <FallingLinesLoader />
              ) : (
                <Table
                  columns={last_current_concer_improvements_column()}
                  data={improvementsData.data}
                />
              )}
            </div>
          </div>

          <div className="mt-4">
            <h1 className="hover:underline text-gray-900 text-lg font-medium leading-6">
              Red Flag Comments
            </h1>
            <div className="py-1">
              {commentData.loading ? (
                <FallingLinesLoader />
              ) : (
                <Table
                  columns={improvment_colums({
                    setAddRedFlagModal,
                    setResolvedConfirmationModal,
                  })}
                  data={commentData.details}
                />
              )}
            </div>
          </div>

          <form onSubmit={handleSubmit}>
            <div className="mt-4">
              <h1 className="hover:underline text-gray-900 text-lg font-medium leading-6">
                Comments
              </h1>
              <div className="py-1">
                <div className="flex flex-col mt-2">
                  <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                    Comments
                  </label>
                  <textarea
                    name="comment"
                    rows={3}
                    onChange={handleChange}
                    value={values.comment}
                    className="w-full mt-2 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                    placeholder="Comments"
                  />
                </div>
              </div>
            </div>
            <div className="w-full flex items-end justify-end">
              <button
                type="submit"
                name="submitButton"
                onClick={() => {
                  setValues({ ...values, submitButton: "redFlag" });
                }}
                value="redFlag"
                className="mt-4 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 mr-2"
              >
                Save and RedFlag
              </button>
              <button
                type="submit"
                name="submitButton"
                value="regular"
                className="mt-4 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default PatientView;
