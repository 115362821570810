import { Formik, useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { availabilitySchema } from "../../../../../schemas";
import { Link, useNavigate, useParams } from "react-router-dom";
import { RxCross2 } from "react-icons/rx";
import { GrCheckmark } from "react-icons/gr";
import {
  addAvailability,
  getAvailability,
  updateAvailability,
} from "../../../../../redux/availability/actions";
import { FallingLinesLoader } from "../../../../../components/spinners/Spinner";
import Override from "../Override";
import {
  getAllExceptions,
  deleteException,
} from "../../../../../redux/exception/actions";
import moment from "moment";
import Breadcrumb from "../../../../../components/Breadcrumb";
import ConfirmationModal from "../../../../../components/ConfirmationModal";
import TimePicker from "rc-time-picker";
import { Repeat as LoopRounded } from "@mui/icons-material";
import { getScheduleWithId } from "../../../../../redux/schedule/actions";

const AddAvailability = () => {
  const [confirmationModal, setConfirmationModal] = useState({
    status: false,
    id: null,
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    availability: { loading: availabilityLoading, availabilityData },
    exception: { loading: exceptionLoading, list: exceptionList },
    schedule: { loading: scheduleLoading, scheduleData },
  } = useSelector((state) => state);

  const initialValues = {};

  

  const [showModal, setShowModal] = useState(false);
  const [formState, setFormState] = useState(initialValues);
  const { id: doctorId, scheduleId } = useParams();
  const [updateForm, setUpdateForm] = useState(false);

  useEffect(() => {
    
    if (availabilityData && availabilityData.length) {
      const val =
        availabilityData[0].schedule_master_id === parseInt(scheduleId);
      setUpdateForm(val);
      console.log(val, "UPDATE DATA");
      console.log(availabilityData[0].schedule_master_id, scheduleId);
    }
  }, [scheduleId, availabilityData]);
  const pages = [
    { title: "Doctor", href: "/doctors" },
    { title: "Schedule", href: `/doctors/${doctorId}/schedule` },
    { title: "Availability" },
  ];

  useEffect(() => {
    document.title = "New Horizon | Add-Availability";
    if (scheduleId) {
      dispatch(getAvailability(scheduleId));
      dispatch(getAllExceptions(scheduleId));
    }
    dispatch(getScheduleWithId(scheduleId))
  }, [scheduleId]);

  const handleOverrideDelete = (exceptionId) => {
    dispatch(deleteException(exceptionId));
    setConfirmationModal({ ...confirmationModal, status: false });
  };

  useEffect(() => {
    if (updateForm) {
      console.log("Fill data", availabilityData, scheduleId);
      const newFormState = {};
      if (availabilityData) {
        availabilityData.forEach((ele) => {
          const day = ele.weekday_name.toLowerCase();
          const prefix = day.substring(0, 3);
          newFormState[day] = true;
          newFormState[`${prefix}_start`] = ele.from_time;
          newFormState[`${prefix}_end`] = ele.to_time;
          newFormState[`${prefix}_week`] = ele.week || 1;
          newFormState[`${prefix}_skip_week`] = ele.skip_week || 0;
        });
      }
      setFormState(newFormState);
      console.log(newFormState);
    }
  }, [updateForm, availabilityData, scheduleId]);

  const weekdays = [
    "sunday",
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
  ];
  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      enableReinitialize: true,
      initialValues: formState,
      validationSchema: availabilitySchema,
      validateOnChange: false,
      onSubmit: (values, action) => {
        console.log(values);
        const body = [];
        weekdays.forEach((day) => {
          if (values[day]) {
            const prefix = day.substring(0, 3);
            body.push({
              weekday_name: day.toUpperCase(),
              from_time: values[`${prefix}_start`],
              to_time: values[`${prefix}_end`],
              week: values[`${prefix}_week`] || 1,
              skip_week: values[`${prefix}_skip_week`] || 0,
            });
          }
        });
        console.log(body);
        if (!availabilityData) dispatch(addAvailability(scheduleId, body));
        else dispatch(updateAvailability(scheduleId, body));
        action.resetForm();
      },
    });
  console.log(errors);

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <Breadcrumb pages={pages} />
      <div className="sm:flex sm:items-center">
        <div className="">
          <h1 className="text-sm mb-3 font-semibold text-gray-900">
            {` Dr. ${scheduleData?.name}`}
          </h1>
          <h1 className="text-xl font-semibold text-gray-900">
            {updateForm ? "Update Availability" : "Add Availability"}
          </h1>
        </div>
      </div>
      <div>
        <div className="flex">
          <div className="w-[60%]">
            {availabilityLoading ? (
              <FallingLinesLoader />
            ) : (
              <form
                className="space-y-8 divide-y  divide-gray-200"
                onSubmit={handleSubmit}
              >
                <div className="flex flex-row">
                  <div className="space-y-6 sm:space-y-5 1 w-full">
                    <div className="space-y-6 pt-8 sm:space-y-5  sm:pt-10">
                      {/* start */}
                      <div className="flex items-center justify-between">
                        <input
                          type="checkbox"
                          onChange={handleChange}
                          value={values.sunday}
                          checked={values.sunday}
                          autoComplete="off"
                          name="sunday"
                          className="text-left mr-3"
                        />

                        <label
                          htmlFor="sun"
                          className="ext-sm font-medium mr-9 text-gray-700 sm:mt-px mr-4"
                        >
                          Su
                        </label>
                        <div className="flex w-5/6 items-center">
                          <input
                            className=" mr-2 w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-indigo-500 sm:max-w-xs sm:text-sm"
                            type="time"
                            name="sun_start"
                            id="sun-start"
                            autoComplete="off"
                            value={values.sun_start}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {/* <TimePicker /> */}
                          {/* <TimePicker  /> */}

                          <span className="mr-2">-</span>
                          <input
                            className="mr-2 w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-indigo-500 sm:max-w-xs sm:text-sm"
                            name="sun_end"
                            type="time"
                            id="sun_end"
                            autoComplete="off"
                            value={values.sun_end}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <span className="mr-2">
                            <LoopRounded fontSize="5px" />
                          </span>
                          <input
                            className="mr-2 w-[75%] max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-indigo-500 sm:max-w-xs sm:text-sm"
                            name="sun_week"
                            type="number"
                            placeholder="Week"
                            min="0"
                            id="sun_week"
                            value={values.sun_week}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <span className="mr-2">-</span>
                          <input
                            className="mr-2 w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-indigo-500 sm:max-w-xs sm:text-sm"
                            name="sun_skip_week"
                            type="number"
                            placeholder="Skip Week"
                            min="0"
                            id="sun_skip_week"
                            value={values.sun_skip_week}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </div>
                      </div>
                      <div className="ml-20 grid grid-cols-4">
                        <p>
                          {errors.sun_start ? (
                            <p className="text-xs text-red-700">
                              {errors.sun_start}
                            </p>
                          ) : null}
                        </p>
                        <p>
                          {errors.sun_end ? (
                            <p className="text-xs text-red-700">
                              {errors.sun_end}
                            </p>
                          ) : null}
                        </p>
                        <p>
                          {errors.sun_week && (
                            <p className="text-xs text-red-700">
                              {errors.sun_week}
                            </p>
                          )}
                        </p>
                        <p>
                          {errors.sun_skip_week && (
                            <p className="text-xs text-red-700">
                              {errors.sun_skip_week}
                            </p>
                          )}
                        </p>
                      </div>

                      {/* end */}
                      {/* start */}
                      <div className="flex items-center justify-between">
                        <input
                          type="checkbox"
                          onChange={handleChange}
                          value={values.monday}
                          checked={values.monday}
                          autoComplete="off"
                          name="monday"
                          className="mr-3"
                        />
                        <label
                          htmlFor="mon"
                          className="text-sm font-medium mr-[38px] text-gray-700 sm:mt-px "
                        >
                          M
                        </label>
                        <div className="flex w-5/6 items-center">
                          <input
                            className="mr-2 w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-indigo-500 sm:max-w-xs sm:text-sm"
                            type="time"
                            name="mon_start"
                            id="mon_start"
                            autoComplete="off"
                            value={values.mon_start}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <span className="mr-2">-</span>
                          <input
                            className="mr-2 w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-indigo-500 sm:max-w-xs sm:text-sm"
                            name="mon_end"
                            type="time"
                            id="mon_end"
                            autoComplete="off"
                            value={values.mon_end}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <span className="mr-2">
                            <LoopRounded fontSize="5px" />
                          </span>
                          <input
                            className="mr-2 w-[75%] max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-indigo-500 sm:max-w-xs sm:text-sm"
                            name="mon_week"
                            type="number"
                            min="0"
                            placeholder="Week"
                            id="mon_week"
                            value={values.mon_week}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <span className="mr-2">-</span>
                          <input
                            className="mr-2 w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-indigo-500 sm:max-w-xs sm:text-sm"
                            name="mon_skip_week"
                            type="number"
                            placeholder="Skip Week"
                            min="0"
                            id="mon_skip_week"
                            value={values.mon_skip_week}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </div>
                      </div>
                      <div className="ml-20 grid grid-cols-4">
                        <p>
                          {errors.mon_start ? (
                            <p className="text-xs text-red-700">
                              {errors.mon_start}
                            </p>
                          ) : null}
                        </p>
                        <p>
                          {errors.mon_end ? (
                            <p className="text-xs text-red-700">
                              {errors.mon_end}
                            </p>
                          ) : null}
                        </p>
                        <p>
                          {errors.mon_week && (
                            <p className="text-xs text-red-700">
                              {errors.mon_week}
                            </p>
                          )}
                        </p>
                        <p>
                          {errors.mon_skip_week && (
                            <p className="text-xs text-red-700">
                              {errors.mon_skip_week}
                            </p>
                          )}
                        </p>
                      </div>
                      {/* end */}
                      {/* start */}
                      <div className="flex items-center justify-between">
                        <input
                          type="checkbox"
                          name="tuesday"
                          checked={values.tuesday}
                          autoComplete="off"
                          value={values.tuesday}
                          onChange={handleChange}
                          id="tuesday"
                          className="mr-3"
                        />
                        <label
                          htmlFor="tue"
                          className="text-sm font-medium mr-[39px] text-gray-700 sm:mt-px "
                        >
                          Tu
                        </label>
                        <div className="flex w-5/6 items-center">
                          <input
                            className=" mr-2 w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-indigo-500 sm:max-w-xs sm:text-sm"
                            type="time"
                            name="tue_start"
                            id="tue_start"
                            autoComplete="off"
                            value={values.tue_start}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <span className="mr-2">-</span>
                          <input
                            className="mr-2 w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-indigo-500 sm:max-w-xs sm:text-sm"
                            name="tue_end"
                            type="time"
                            id="tue-end"
                            autoComplete="off"
                            value={values.tue_end}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <span className="mr-2">
                            <LoopRounded fontSize="5px" />
                          </span>
                          <input
                            className="mr-2 w-[75%] max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-indigo-500 sm:max-w-xs sm:text-sm"
                            name="tue_week"
                            type="number"
                            min="0"
                            placeholder="Week"
                            id="tue_week"
                            value={values.tue_week}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <span className="mr-2">-</span>
                          <input
                            className="mr-2 w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-indigo-500 sm:max-w-xs sm:text-sm"
                            name="tue_skip_week"
                            type="number"
                            placeholder="Skip Week"
                            min="0"
                            id="tue_skip_week"
                            value={values.tue_skip_week}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </div>
                      </div>
                      <div className="ml-20 grid grid-cols-4">
                        <p>
                          {errors.tue_start ? (
                            <p className="text-xs text-red-700">
                              {errors.tue_start}
                            </p>
                          ) : null}
                        </p>
                        <p>
                          {errors.tue_end ? (
                            <p className="text-xs text-red-700">
                              {errors.tue_end}
                            </p>
                          ) : null}
                        </p>
                        <p>
                          {errors.tue_week && (
                            <p className="text-xs text-red-700">
                              {errors.tue_week}
                            </p>
                          )}
                        </p>
                        <p>
                          {errors.tue_skip_week && (
                            <p className="text-xs text-red-700">
                              {errors.tue_skip_week}
                            </p>
                          )}
                        </p>
                      </div>
                      {/* end */}
                      {/* start */}
                      <div className="flex items-center justify-between">
                        <input
                          type="checkbox"
                          name="wednesday"
                          onChange={handleChange}
                          value={values.wednesday}
                          checked={values.wednesday}
                          id="wednesday"
                          className="mr-3"
                        />
                        <label
                          htmlFor="wed"
                          className="text-sm font-medium mr-[39px] text-gray-700 sm:mt-px "
                        >
                          W
                        </label>
                        <div className="flex w-5/6 items-center">
                          <input
                            className=" mr-2 w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-indigo-500 sm:max-w-xs sm:text-sm"
                            type="time"
                            name="wed_start"
                            id="wed_start"
                            autoComplete="off"
                            value={values.wed_start}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <span className="mr-2">-</span>
                          <input
                            className="mr-2 w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-indigo-500 sm:max-w-xs sm:text-sm"
                            name="wed_end"
                            type="time"
                            id="wed_end"
                            autoComplete="off"
                            value={values.wed_end}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <span className="mr-2">
                            <LoopRounded fontSize="5px" />
                          </span>
                          <input
                            className="mr-2 w-[75%]  max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-indigo-500 sm:max-w-xs sm:text-sm"
                            name="wed_week"
                            type="number"
                            min="0"
                            placeholder="Week"
                            id="wed_week"
                            value={values.wed_week}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <span className="mr-2">-</span>
                          <input
                            className="mr-2 w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-indigo-500 sm:max-w-xs sm:text-sm"
                            name="wed_skip_week"
                            type="number"
                            min="0"
                            placeholder="Skip Week"
                            id="wed_skip_week"
                            value={values.wed_skip_week}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </div>
                      </div>
                      <div className="ml-20 grid grid-cols-4">
                        <p>
                          {errors.wed_start ? (
                            <p className="text-xs text-red-700">
                              {errors.wed_start}
                            </p>
                          ) : null}
                        </p>
                        <p>
                          {errors.wed_end ? (
                            <p className="text-xs text-red-700">
                              {errors.wed_end}
                            </p>
                          ) : null}
                        </p>
                        <p>
                          {errors.wed_week && (
                            <p className="text-xs text-red-700">
                              {errors.wed_week}
                            </p>
                          )}
                        </p>
                        <p>
                          {errors.wed_skip_week && (
                            <p className="text-xs text-red-700">
                              {errors.wed_skip_week}
                            </p>
                          )}
                        </p>
                      </div>
                      {/* end */}
                      {/* start */}
                      <div className="flex items-center justify-between">
                        <input
                          type="checkbox"
                          name="thursday"
                          onChange={handleChange}
                          value={values.thursday}
                          checked={values.thursday}
                          id="thursday"
                          className="mr-3"
                        />
                        <label
                          htmlFor="thu"
                          className="text-sm font-medium mr-[39px] text-gray-700 sm:mt-px "
                        >
                          Th
                        </label>
                        <div className="flex w-5/6 items-center just">
                          <input
                            className=" mr-2 w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-indigo-500 sm:max-w-xs sm:text-sm"
                            type="time"
                            name="thu_start"
                            id="thu_start"
                            autoComplete="off"
                            value={values.thu_start}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <span className="mr-2">-</span>
                          <input
                            className="mr-2 w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-indigo-500 sm:max-w-xs sm:text-sm"
                            name="thu_end"
                            type="time"
                            id="thu_end"
                            autoComplete="off"
                            value={values.thu_end}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <span className="mr-2">
                            <LoopRounded fontSize="5px" />
                          </span>
                          <input
                            className="mr-2 w-[75%] max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-indigo-500 sm:max-w-xs sm:text-sm"
                            name="thu_week"
                            type="number"
                            placeholder="Week"
                            min="0"
                            id="thu_week"
                            value={values.thu_week}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <span className="mr-2">-</span>
                          <input
                            className="mr-2 w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-indigo-500 sm:max-w-xs sm:text-sm"
                            name="thu_skip_week"
                            type="number"
                            placeholder="Skip Week"
                            min="0"
                            id="thu_skip_week"
                            value={values.thu_skip_week}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </div>
                      </div>
                      <div className="ml-20 grid grid-cols-4">
                        <p>
                          {errors.thu_start ? (
                            <p className="text-xs text-red-700">
                              {errors.thu_start}
                            </p>
                          ) : null}
                        </p>
                        <p>
                          {errors.thu_end ? (
                            <p className="text-xs text-red-700">
                              {errors.thu_end}
                            </p>
                          ) : null}
                        </p>
                        <p>
                          {errors.thu_week && (
                            <p className="text-xs text-red-700">
                              {errors.thu_week}
                            </p>
                          )}
                        </p>
                        <p>
                          {errors.thu_skip_week && (
                            <p className="text-xs text-red-700">
                              {errors.thu_skip_week}
                            </p>
                          )}
                        </p>
                      </div>
                      {/* end */}
                      {/* start */}
                      <div className="flex items-center justify-between">
                        <input
                          type="checkbox"
                          name="friday"
                          onChange={handleChange}
                          checked={values.friday}
                          value={values.friday}
                          id="friday"
                          className="mr-3"
                        />
                        <label
                          htmlFor="fri"
                          className="text-sm font-medium mr-[39px] text-gray-700 sm:mt-px "
                        >
                          F
                        </label>
                        <div className="flex w-5/6 items-center">
                          <input
                            className="mr-2 w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-indigo-500 sm:max-w-xs sm:text-sm"
                            type="time"
                            name="fri_start"
                            id="fri_start"
                            autoComplete="off"
                            value={values.fri_start}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <span className="mr-2">-</span>
                          <input
                            className="mr-2 w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-indigo-500 sm:max-w-xs sm:text-sm"
                            name="fri_end"
                            type="time"
                            id="fri_end"
                            autoComplete="off"
                            value={values.fri_end}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <span className="mr-2">
                            <LoopRounded fontSize="5px" />
                          </span>
                          <input
                            className="mr-2 w-[75%] max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-indigo-500 sm:max-w-xs sm:text-sm"
                            name="fri_week"
                            type="number"
                            min="0"
                            placeholder="Week"
                            id="fri_week"
                            value={values.fri_week}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <span className="mr-2">-</span>
                          <input
                            className="mr-2 w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-indigo-500 sm:max-w-xs sm:text-sm"
                            name="fri_skip_week"
                            type="number"
                            placeholder="Skip Week"
                            id="fri_skip_week"
                            value={values.fri_skip_week}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </div>
                      </div>
                      <div className="ml-20 grid grid-cols-4">
                        <p>
                          {errors.fri_start ? (
                            <p className="text-xs text-red-700">
                              {errors.fri_start}
                            </p>
                          ) : null}
                        </p>
                        <p>
                          {errors.fri_end ? (
                            <p className="text-xs text-red-700">
                              {errors.fri_end}
                            </p>
                          ) : null}
                        </p>
                        <p>
                          {errors.fri_week && (
                            <p className="text-xs text-red-700">
                              {errors.fri_week}
                            </p>
                          )}
                        </p>
                        <p>
                          {errors.fri_skip_week && (
                            <p className="text-xs text-red-700">
                              {errors.fri_skip_week}
                            </p>
                          )}
                        </p>
                      </div>
                      {/* end */}
                      {/* start */}
                      <div className="flex items-center justify-between">
                        <input
                          type="checkbox"
                          name="saturday"
                          onChange={handleChange}
                          value={values.saturday}
                          checked={values.saturday}
                          id="saturday"
                          className="mr-3"
                        />
                        <label
                          htmlFor="sat"
                          className="text-sm font-medium mr-[39px] text-gray-700 sm:mt-px "
                        >
                          Sa
                        </label>
                        <div className="flex w-5/6 items-center">
                          <input
                            className=" mr-2 w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-indigo-500 sm:max-w-xs sm:text-sm"
                            type="time"
                            name="sat_start"
                            id="sat_start"
                            autoComplete="off"
                            value={values.sat_start}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <span className="mr-2">-</span>
                          <input
                            className="mr-2 w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-indigo-500 sm:max-w-xs sm:text-sm"
                            name="sat_end"
                            type="time"
                            id="sat_end"
                            autoComplete="off"
                            value={values.sat_end}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <span className="mr-2">
                            <LoopRounded fontSize="5px" />
                          </span>
                          <input
                            className="mr-2 w-[75%] max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-indigo-500 sm:max-w-xs sm:text-sm"
                            name="sat_week"
                            placeholder="Week"
                            type="number"
                            min="0"
                            id="sat_week"
                            value={values.sat_week}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <span className="mr-2">-</span>
                          <input
                            className="mr-2 w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-indigo-500 sm:max-w-xs sm:text-sm"
                            name="sat_skip_week"
                            placeholder="Skip Week"
                            type="number"
                            id="sat_skip_week"
                            value={values.sat_skip_week}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </div>
                      </div>

                      <div className="ml-20 grid grid-cols-4">
                        <p>
                          {errors.sat_start ? (
                            <p className="text-xs text-red-700">
                              {errors.sat_start}
                            </p>
                          ) : null}
                        </p>
                        <p>
                          {errors.sat_end ? (
                            <p className="text-xs text-red-700">
                              {errors.sat_end}
                            </p>
                          ) : null}
                        </p>
                        <p>
                          {errors.sat_week && (
                            <p className="text-xs text-red-700">
                              {errors.sat_week}
                            </p>
                          )}
                        </p>
                        <p>
                          {errors.sat_skip_week && (
                            <p className="text-xs text-red-700">
                              {errors.sat_skip_week}
                            </p>
                          )}
                        </p>
                      </div>
                      {/* end */}
                    </div>
                  </div>
                </div>

                <div className="pt-5">
                  <div className="flex justify-end">
                    <button
                      type="submit"
                      className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </form>
            )}
          </div>
          <div className="w-[50%]">
            <h2 className="font-bold text-lg  ">Add Date Override</h2>
            <div>
              <p className="mt-4 text-sm font-medium  text-gray-900">
                Add dates when your availability changes from your weekly hours
              </p>
              <button
                className="mt-4 text-center w-full rounded-md border p-1 text-white bg-indigo-600 border-indigo-600 hover:bg-indigo-700"
                onClick={() => setShowModal(true)}
              >
                Add Date Override
              </button>
            </div>
            <div className="mt-8 rounded-lg">
              <ConfirmationModal
                open={confirmationModal.status}
                setOpen={setConfirmationModal}
                onDelete={() => handleOverrideDelete(confirmationModal.id)}
              />
              {exceptionLoading ? (
                <FallingLinesLoader />
              ) : (
                <table className="min-w-full divide-y divide-gray-300">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                      >
                        Date
                      </th>
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                      >
                        From Time
                      </th>
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                      >
                        To Time
                      </th>
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                      >
                        Available
                      </th>

                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                      >
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {exceptionList.map((exception) => {
                      return (
                        <tr>
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                            {moment(exception.date).format("DD-MM-yy")}
                          </td>
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                            {moment(exception.from_time, "HH:mm").format(
                              "hh:mm A"
                            )}
                          </td>
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                            {moment(exception.to_time, "HH:mm").format(
                              "hh:mm A"
                            )}
                          </td>
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-center  sm:pl-6">
                            {exception && exception.available === true ? (
                              <GrCheckmark className="text-green-800" />
                            ) : (
                              <RxCross2 className=" text-red-800" />
                            )}
                          </td>
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                            <span
                              className="text-red-600 ml-2 hover:text-red-900 cursor-pointer"
                              onClick={() =>
                                setConfirmationModal({
                                  id: exception.id,
                                  status: true,
                                })
                              }
                            >
                              Delete
                            </span>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              )}
            </div>
          </div>
          <Override
            current_data={exceptionList}
            showModal={showModal}
            setShowModal={setShowModal}
            scheduleId={scheduleId}
          />
        </div>
      </div>
    </div>
  );
};

export default AddAvailability;
