import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "../../../components/Pagination";
import { FallingLinesLoader } from "../../../components/spinners/Spinner";
import Breadcrumb from "../../../components/Breadcrumb";
import ConfirmationModal from "../../../components/ConfirmationModal";
import {
  deleteRouteOfAdministration,
  getAllRouteOfAdministrations,
} from "../../../redux/Route of adminstration/actions";
import Table from "../../../components/tables/table";
import {
    routeOfAdministration_master,
  Strength_master,
} from "../../../components/tables/tableheader";

const RouteOfAdministration = () => {
  const pages = [{ title: "Medicine", href: "/medicine-master" }];
  const [confirmationModal, setConfirmationModal] = useState({
    status: false,
    id: null,
  });
  const dispatch = useDispatch();
  const { list: RouteOfAdministrationList, loading } = useSelector(
    (state) => state.RouteOfAdministration
  );

  useEffect(() => {
    dispatch(getAllRouteOfAdministrations());
    document.title = "New Horizon | Route Of Administration Master";
  }, []);

  const onDeleteOpen = (id) => {
    setConfirmationModal({ id, status: true });
  };

  const onDeleteStrength = (id) => {
    dispatch(deleteRouteOfAdministration(id));
    setConfirmationModal({ id: null, status: false });
  };
  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <ConfirmationModal
        open={confirmationModal.status}
        setOpen={setConfirmationModal}
        onDelete={() => onDeleteStrength(confirmationModal.id)}
      />
      <Breadcrumb pages={pages} />
      <div className="sm:flex sm:items-center mb-5">
        <div className="sm:flex-auto">
          <h1 className="text-xl font-semibold text-gray-900">
Route of Administration
          </h1>
        </div>
        <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
          <Link
            to="/add-route-of-administration"
            type="button"
            className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
          >
            Add Route of Administration
          </Link>
        </div>
      </div>
      {/* {loading ? ( */}
      {/* <FallingLinesLoader /> */}
      {/* ) : ( */}
      <Table
        columns={routeOfAdministration_master({ onDeleteOpen })}
        data={RouteOfAdministrationList}
        // onEditClose={cleanModalData}
      />
      {/* )} */}
    </div>
  );
};

export default RouteOfAdministration;
