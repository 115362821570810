import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import Modal from "../../../components/Modal";
import Breadcrumb from "../../../components/Breadcrumb";
import { FallingLinesLoader } from "../../../components/spinners/Spinner";
import Table from "../../../components/tables/table";
import { toast } from "react-hot-toast";
import ConfirmationModal from "../../../components/ConfirmationModal";
import Button from "../../../components/Button";
import InputBox from "../../../components/InputBox";
import axios from "axios";
import { getHeaders } from "../../../redux/auth/actions";
import { useDispatch } from "react-redux";
import {
  therapist_appointment_log_colums,
  therapist_appointment_log_columss,
} from "../../../components/tables/tableheader";
import moment from "moment";
// const initialModalState = {
//   type: "",
//   state: false,
//   index: null,
//   edit_id: "",
//   data: {
//     name: "",
//     date: "",
//   },
// };
const api = process.env.REACT_APP_API_URL;
function AppointmentLogs() {
  const pages = [{ title: "Appointment Logs", href: "/appointment-logs" }];
  const [loading, setLoading] = useState(false);
  //   const [modal, setModal] = useState(initialModalState);

  const [query, setQuery] = useState(200);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    getAllAppointments(200);
    // getAllStorageLocations();
    document.title = "New Horizon | Appointment Logs";
  }, []);

  const [labs, setLabs] = useState({
    loading: false,
    data: [],
  });
  const [confirmationModal, setConfirmationModal] = useState({
    status: false,
    id: null,
    presetnt: false,
  });
  const [appointmentList, setAppointmentList] = useState({
    loading: false,
    data: [],
    FilterData: [],
  });

  const getAllAppointments = async (limit) => {
    const headers = await dispatch(getHeaders());
    setAppointmentList((prev) => ({ ...prev, loading: true }));
    try {
      const res = await axios.get(
        `${api}/api/evaluation-appointment?limit=${limit}`,
        {
          headers: headers,
        }
      );
      const { data, status } = res;
      if (status === 200) {
        setAppointmentList((prev) => ({
          ...prev,
          data: data.data,
          FilterData: data.data,
          loading: false,
        }));
      }
      console.log(res);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const [date, setDate] = useState({
    from: "",
    to: "",
  });

  function filterArrayByDate() {
    if (date.from && date.to) {
      const filteredArray = [];
      const fromDate = new Date(date.from + "T00:00:00.000Z");
      const toDate = new Date(date.to + "T23:59:59.999Z");

      for (const element of appointmentList.data) {
        const date = new Date(element.date);

        if (date >= fromDate && date <= toDate) {
          filteredArray.push(element);
        }
      }
      setAppointmentList((prev) => ({
        ...prev,
        FilterData: filteredArray,
      }));
      console.log(appointmentList.FilterData);
    } else {
      setAppointmentList((prev) => ({
        ...prev,
        FilterData: appointmentList.data,
      }));
    }
  }

  useEffect(() => {
    console.log(date);
    if (date.from && date.to) filterArrayByDate();
  }, [date]);
  async function markPresent(id) {
    const headers = dispatch(getHeaders());
    try {
      const res = await axios.put(
        `${api}/api/evaluation-appointment/mark-present/${id}`,
        {},
        {
          headers: headers,
        }
      );
      const { data } = res;
      toast.success(data.message);
      setConfirmationModal((prev) => ({
        ...prev,
        status: false,
      }));
      getAllAppointments();
    } catch (error) {
      toast.error(error.response.data.message);
    }
  }
  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <ConfirmationModal
        title="Mark as Present"
        message="Are you sure you want to mark this enrolment as present?"
        open={confirmationModal.status}
        setOpen={setConfirmationModal}
        confirmationButtonText="Present"
        confirmationButtonColor="indigo"
        onDelete={() => markPresent(confirmationModal.id)}
      />
      <div className="sm:flex sm:items-center mb-5">
        <div className="sm:flex-auto">
          <Breadcrumb pages={pages} />
          <h1 className="text-xl font-semibold text-gray-900">
            Evaluation Logs
          </h1>
        </div>
        <div className="sm:grid sm:grid-cols-2    w-max sm:items-start sm:gap-4  sm:border-gray-200 sm:pt-5">
          <div
            className={`mt-1 flex items-center justify-right  grid-cols-2
           sm:col-span-2 sm:mt-0`}
          >
            <label className="">From: </label>
            <input
              type="date"
              name="fromDate"
              value={date.from}
              onChange={(e) => {
                setDate({
                  ...date,
                  from: e.target.value,
                });
                filterArrayByDate();
              }}
              placeholder="select date"
              className="mr-2 ml-2 block w-[90%] max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-indigo-500 sm:max-w-xs sm:text-sm"
            />
            <label className="">To: </label>
            <input
              type="date"
              name="toDate"
              value={date.to}
              onChange={(e) => {
                setDate({
                  ...date,
                  to: e.target.value,
                });
                filterArrayByDate();
              }}
              placeholder="select date"
              className="mr-2 ml-2 block w-[90%] max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-indigo-500 sm:max-w-xs sm:text-sm"
            />
          </div>
        </div>
      </div>
      {appointmentList.loading ? (
        <FallingLinesLoader />
      ) : (
        <Table
          columns={therapist_appointment_log_columss({ setConfirmationModal })}
          data={appointmentList.FilterData}
          fetch={async (w) => {
            setQuery((prev) => w);

            await getAllAppointments(w);
          }}
        />
      )}
    </div>
  );
}
export default AppointmentLogs;
