import React, { useEffect, useState } from "react";
import Table from "../../../components/tables/table";
import { CancleopdReport_columns } from "../../../components/tables/tableheader";
import { useDispatch, useSelector } from "react-redux";
import { FallingLinesLoader } from "../../../components/spinners/Spinner";
import { getOpdAppointmentWithId } from "../../../redux/opdreport/action";
import { getAllDoctors } from "../../../redux/doctor/actions";
import { getCancelledOpdAppointment } from "../../../redux/opdreport/action";
import moment from "moment";
import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Formik } from "formik";
import { useFormik } from "formik";
import ConfirmationModal from "../../../components/ConfirmationModal";
import { async } from "q";
import axios from "axios";
import { toast } from "react-hot-toast";
import Modal from "../../../components/Modal";
import * as Yup from "yup";
import { getHeaders } from "../../../redux/auth/actions";
const initialModalState = {
  id: "",
  state: false,
};
const initModalState = {
  id: "",
  state: false,
  title: "Add Comment",
  button: "Add",
  data: {
    cancel_comment: "",
  },
};
const api = process.env.REACT_APP_API_URL;
const CancelOPD = () => {
  const [selected, setselected] = useState("all");
  const [date, setDate] = useState("");
  const [query, setQuery] = useState(200);
  const [adj, setAdj] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [disableBtn, setDisableBtn] = useState(true);
  const [meetLink, setMeetLink] = useState("");
  const [id, setId] = useState("");
  const dispatch = useDispatch();
  const { loading, list: doctor } = useSelector((state) => state.doctor);
  const { cancelledLoading, cancelledList: opdReport } = useSelector(
    (state) => state.opdAppointment
  );
  const [data, setData] = useState(opdReport);

  const { user } = useSelector((state) => state.auth);
  const [modalData, setModalData] = useState(initialModalState);
  useEffect(() => {
    document.title = "New Horizon | OPD report";
    dispatch(getAllDoctors());
    dispatch(getCancelledOpdAppointment(query));
    // console.log(date);
  }, [selected]);

  useEffect(() => {
    setData(opdReport);
  }, [opdReport]);

  if (date !== "") {
    const newArr = opdReport.filter((item) => {
      var newDate = moment(item.date).format("YYYY-MM-DD");
      return newDate === date;
    });
    // console.log(newArr);
    console.log(newArr);
    setData(newArr);
  }

  const handleChangeDate = (date) => {
    if (date !== "") {
      const newArr = opdReport.filter((item) => {
        var newDate = moment(item.date).format("YYYY-MM-DD");
        return newDate === date;
      });
      // console.log(newArr);
      console.log(newArr);
      setData(newArr);
    }
  };
  const handleChangeadj = (date) => {
    if (date !== "" && date === "adjusted_on") {
      const newArr = opdReport.filter((item) => {
        return item.adjusted_on;
      });
      setData(newArr);
    } else if (date !== "" && date === "none_Adj") {
      const newArr = opdReport.filter((item) => {
        return item.adjusted_on === null;
      });
      setData(newArr);
    } else {
      setData(opdReport);
    }
  };
  const [CommentModal, setCommentModal] = useState(initModalState);

  async function addComment(value) {
    const headers = dispatch(getHeaders());
    const body = {
      cancel_comment: value.cancel_comment,
    };
    const res = await axios.put(
      `${api}/api/appointment/update/comment/${CommentModal.id}`,
      body,
      { headers }
    );
    if (res.data) {
      toast.success("Successfully comment added");
      dispatch(getCancelledOpdAppointment());
      setCommentModal(initModalState);
    }
  }
  const addCommentModal = () => {
    const { state, id, title, button } = CommentModal;
    return (
      <Modal
        size="max-w-sm"
        open={state}
        title={title}
        setOpen={() =>
          setCommentModal((prev) => ({ ...prev, state: false, id: "" }))
        }
      >
        <div className="mt-2 w-full flex items-center justify-center">
          <Formik
            initialValues={CommentModal.data}
            validationSchema={Yup.object({
              cancel_comment: Yup.string().required("Required"),
            })}
            onSubmit={async (values) => {
              addComment(values);
            }}
          >
            {(formik) => (
              <form className="w-[80%]" onSubmit={formik.handleSubmit}>
                <div className="mt-2 flex items-center justify-between">
                  <textarea
                    id="cancel_comment"
                    name="cancel_comment"
                    value={formik.values.cancel_comment}
                    placeholder="Add Comment"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-indigo-500 sm:max-w-xs sm:text-sm"
                  />
                </div>

                <div>
                  <div className="mt-5 sm:mt-6">
                    <button
                      type="submit"
                      className="inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm"
                    >
                      {button}
                    </button>
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </Modal>
    );
  };
  const adjModal = () => {
    const { state, id } = modalData;
    const initialValues = {
      form_date: "",
    };

    return (
      <Modal
        size="max-w-sm"
        open={state}
        title={`Adjust`}
        setOpen={() =>
          setModalData((prev) => ({ ...prev, state: false, id: "" }))
        }
      >
        <div className="mt-2 w-full flex items-center justify-center">
          <Formik
            initialValues={initialValues}
            onSubmit={async (values) => {
              const body = {
                adjustment_date: values.form_date + "T00:00:00.000Z",
              };
              const res = await axios.put(
                `${api}/api/red-flags/mark-adjustment/${modalData.id}`,
                body
              );
              if (res.status === 200) {
                toast.success("Successfully adjustment done");
                dispatch(getCancelledOpdAppointment());
                setModalData((prev) => ({ ...prev, state: false, id: "" }));
              }
            }}
          >
            {(formik) => (
              <form className="w-[80%]" onSubmit={formik.handleSubmit}>
                <div className="mt-2 flex items-center justify-between">
                  <input
                    type="date"
                    id="form_date"
                    name="form_date"
                    value={formik.values.form_date}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-indigo-500 sm:max-w-xs sm:text-sm"
                  />
                </div>

                <div>
                  <div className="mt-5 sm:mt-6">
                    <button
                      type="submit"
                      className="inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm"
                    >
                      Save
                    </button>
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </Modal>
    );
  };
  return (
    <div className="px-4 sm:px-6 lg:px-8">
      {adjModal()}
      {addCommentModal()}
      <SendLinkModal
        id={id}
        meetLink={meetLink}
        disableBtn={disableBtn}
        setMeetLink={setMeetLink}
        showModal={showModal}
        setShowModal={setShowModal}
      />
      <div className="sm:flex sm:items-center mb-5">
        <div className="sm:flex-auto">
          <h1 className="text-xl font-semibold text-gray-900">
            Canceled OPD Report
          </h1>
        </div>
        <div className="sm:grid sm:grid-cols-2    w-max sm:items-start sm:gap-4  sm:border-gray-200 sm:pt-5">
          <div
            className={`mt-1 flex items-center justify-right ${
              user.role !== "DOCTOR" && "grid-cols-2"
            } sm:col-span-2 sm:mt-0`}
          >
            <input
              type="date"
              name="date"
              // value={(e) => setDate(e.target.value)}
              onChange={(e) => handleChangeDate(e.target.value)}
              placeholder="select date"
              className="mr-2 block w-[90%] max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-indigo-500 sm:max-w-xs sm:text-sm"
            />
            {user.role === "DOCTOR" ? null : (
              <select
                id="type"
                name="type"
                defaultValue={selected}
                onChange={(e) => {
                  setselected(e.target.value);
                }}
                // value={(e)=>setselected(e.target.value)}
                className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-indigo-500 sm:max-w-xs sm:text-sm"
              >
                <option value="all">Select a Doctor</option>

                {doctor.map((d, idx) => (
                  <option key={idx} value={d.id}>
                    {"Dr. " + d.first_name + " " + d.last_name}
                  </option>
                ))}
              </select>
            )}
            <select
              id="type"
              name="type"
              defaultValue={selected}
              onChange={(e) => {
                handleChangeadj(e.target.value);
              }}
              // value={(e)=>setselected(e.target.value)}
              className="block w-max max-w-max rounded-md border-[1px] ml-2 p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-indigo-500 sm:max-w-xs sm:text-sm"
            >
              <option value="">All</option>
              <option value="adjusted_on">Adjusted</option>
              <option value="none_Adj">Not Adjusted</option>
            </select>
          </div>
        </div>
      </div>
      {loading || cancelledLoading ? (
        <FallingLinesLoader />
      ) : (
        <Table
          columns={CancleopdReport_columns({ setModalData, setCommentModal })}
          data={data}
          fetch={async (d) => {
            await setQuery(d);
            await dispatch(getCancelledOpdAppointment(d));
          }}
        />
      )}
    </div>
  );
};

export default CancelOPD;

export function SendLinkModal({
  showModal,
  setShowModal,
  disableBtn,
  meetLink,
  id,
}) {
  const [confirmationModal, setConfirmationModal] = useState({
    status: false,
    id: null,
  });
  const onSend = async () => {
    //TODO: Send link to patient
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/appointment-payment/send-sms/${id}`
      );
      toast.success(data.data);
    } catch (error) {
      console.log(error);
    }
    console.log(confirmationModal.id);
    setShowModal(false);
  };
  // console.log("meetLinkId"  + id)
  const initialValues = {};
  const sendPaymentLinkConfirmation = () => {
    setConfirmationModal({ status: true });
  };

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: initialValues,
      onSubmit: (values, action) => {
        //TODO: Optimize code here

        action.resetForm();
      },
    });
  // console.log(errors)

  return (
    <Transition.Root show={showModal} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setShowModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                <div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900"
                    >
                      Send Link to?
                    </Dialog.Title>
                    <div className="mt-2 w-full flex items-center justify-center">
                      <ConfirmationModal
                        open={confirmationModal.status}
                        setOpen={setConfirmationModal}
                        title=" "
                        onSend={onSend}
                        confirmationButtonText="Send"
                        confirmationButtonColor="indigo"
                        description="Do you want to send the payment link?"
                      />
                      <form className="w-[80%]" onSubmit={handleSubmit}>
                        <div className="mt-2 flex items-center justify-between">
                          <select
                            id="form_type"
                            name="form_type"
                            value={values.form_type}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-indigo-500 sm:max-w-xs sm:text-sm"
                          >
                            <option>Select the person</option>
                            <option value={"PATIENT"}>Patient</option>
                            <option value={"DOCTOR"}>Doctor</option>
                          </select>
                        </div>
                        <div className="mt-2 flex items-center justify-between">
                          <input
                            id="meeting-link"
                            name="meet_link"
                            disabled
                            value={meetLink}
                            className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-indigo-500 sm:max-w-xs sm:text-sm"
                          />
                        </div>

                        <div>
                          <div className="mt-5 sm:mt-6">
                            <button
                              type="submit"
                              disabled={disableBtn}
                              className="inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm"
                              onClick={sendPaymentLinkConfirmation}
                            >
                              Send
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
