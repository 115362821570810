export const GET_ALL_DOMAIN_BEGIN = "GET_ALL_DOMAIN_BEGIN"
export const GET_ALL_DOMAIN_SUCCESS = "GET_ALL_DOMAIN_SUCCESS"
export const GET_ALL_DOMAIN_ERROR = "GET_ALL_DOMAIN_ERROR"

export const ADD_DOMAIN_BEGIN = "ADD_DOMAIN_BEGIN"
export const ADD_DOMAIN_SUCCESS = "ADD_DOMAIN_SUCCESS"
export const ADD_DOMAIN_ERROR = "ADD_DOMAIN_ERROR"

export const GET_DOMAIN_BEGIN = "GET_DOMAIN_BEGIN"
export const GET_DOMAIN_SUCCESS = "GET_DOMAIN_SUCCESS"
export const GET_DOMAIN_ERROR = "GET_DOMAIN_ERROR"

export const UPDATE_DOMAIN_BEGIN = "UPDATE_DOMAIN_BEGIN"
export const UPDATE_DOMAIN_SUCCESS = "UPDATE_DOMAIN_SUCCESS"
export const UPDATE_DOMAIN_ERROR = "UPDATE_DOMAIN_ERROR"

export const DELETE_DOMAIN_BEGIN = "DELETE_DOMAIN_BEGIN"
export const DELETE_DOMAIN_SUCCESS = "DELETE_DOMAIN_SUCCESS"
export const DELETE_DOMAIN_ERROR = "DELETE_DOMAIN_ERROR"