import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FallingLinesLoader } from "../../../components/spinners/Spinner";
import Breadcrumb from "../../../components/Breadcrumb";
import { getAllTherapists, deleteTherapist } from "../../../redux/therapist/therpist/actions";
import Table from "../../../components/tables/table";
import { therapist_colums } from "../../../components/tables/tableheader";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import ConfirmationModal from "../../../components/ConfirmationModal";

const Report = () => {
  const dispatch = useDispatch();
  const pages = [{ title: "Therapist", href: "/therapist" }];
  const [confirmationModal, setConfirmationModal] = useState({
    status: false,
    id: null,
  });
  const { loading,  list: therapistList } = useSelector((state) => state.Therapist);

  useEffect(() => {
    document.title = "New Horizon | Therapist";
    dispatch(getAllTherapists());
  }, []);
  const onDeleteOpen = (id) => {
    setConfirmationModal({ id, status: true });
  };

  const onDeleteDoctor = (id) => {
    dispatch(deleteTherapist(id));
    setConfirmationModal({ id: null, status: false });
  };
  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <Breadcrumb pages={pages} />
      <ConfirmationModal
        open={confirmationModal.status}
        setOpen={setConfirmationModal}
        onDelete={() => onDeleteDoctor(confirmationModal.id)}
      />
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-xl font-semibold text-gray-900">Therapist</h1>
          <p className="mt-2 text-sm text-gray-700">
            A list of all the therapist in your account including their name,
            title, email and role.
          </p>
        </div>
        <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
          <Link
            to="/add-therapist"
            type="button"
            className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
          >
            Add Therapist
          </Link>
        </div>
      </div>
      {loading ? (
        <FallingLinesLoader />
      ) : (
        <Table columns={therapist_colums({onDeleteOpen})} data={therapistList} />
      )}
    </div>
  );
};

export default Report;
