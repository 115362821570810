import React from 'react'
import { useState } from 'react'
import { Switch } from '@headlessui/react'
import Breadcrumb from "../../../components/Breadcrumb";

function ThemesMode(...classes) {
  return classes.filter(Boolean).join(' ')
}
function GlobalSettings() {
  const pages = [{ title: "Global Settings", href: "/global-setting" }];
  const [enabled, setEnabled] = useState(false)
  return (
    <div>
      <Breadcrumb pages={pages} />
      <div className='flex gap-5'>
        <div className="border border-gray-200 bg-white px-4 py-5 sm:px-6 rounded-md w-1/2">
          <h3 className="text-lg font-medium leading-6 text-gray-900">Themes Settings</h3>
          <p className="mt-1 text-sm text-gray-500">
            To Change the theme mode light to dark and dark to light with toggle.
          </p>
          <div className='mt-5 flex'>
            <h1>Dark Mode</h1>
            <div className='ml-auto'>
              <Switch
                checked={enabled}
                onChange={setEnabled}
                className={ThemesMode(
                  enabled ? 'bg-indigo-600' : 'bg-gray-200',
                  'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
                )}
              >
                <span className="sr-only">Use setting</span>
                <span
                  className={ThemesMode(
                    enabled ? 'translate-x-5' : 'translate-x-0',
                    'pointer-events-none relative inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                  )}
                >
                  <span
                    className={ThemesMode(
                      enabled ? 'opacity-0 ease-out duration-100' : 'opacity-100 ease-in duration-200',
                      'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity'
                    )}
                    aria-hidden="true"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="h-3 w-3 text-gray-400">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M12 3v2.25m6.364.386l-1.591 1.591M21 12h-2.25m-.386 6.364l-1.591-1.591M12 18.75V21m-4.773-4.227l-1.591 1.591M5.25 12H3m4.227-4.773L5.636 5.636M15.75 12a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0z" />
                    </svg>
                  </span>
                  <span
                    className={ThemesMode(
                      enabled ? 'opacity-100 ease-in duration-200' : 'opacity-0 ease-out duration-100',
                      'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity'
                    )}
                    aria-hidden="true"
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="h-3 w-3 text-indigo-600" >
                      <path stroke-linecap="round" stroke-linejoin="round" d="M9.813 15.904L9 18.75l-.813-2.846a4.5 4.5 0 00-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 003.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 003.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 00-3.09 3.09zM18.259 8.715L18 9.75l-.259-1.035a3.375 3.375 0 00-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 002.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 002.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 00-2.456 2.456zM16.894 20.567L16.5 21.75l-.394-1.183a2.25 2.25 0 00-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 001.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 001.423 1.423l1.183.394-1.183.394a2.25 2.25 0 00-1.423 1.423z" />
                    </svg>
                  </span>
                </span>
              </Switch>
            </div>
          </div>
        </div>
        <div className="border border-gray-200 bg-white px-4 py-5 sm:px-6 rounded-md w-1/2">
          <h3 className="text-lg font-medium leading-6 text-gray-900">Support Email</h3>
          <p className="mt-1 text-sm text-gray-500">
            Support and Communications with us by Email and please Email and we connect with you as soon as.
          </p>
          <div className='mt-5 flex items-center'>
            <h1>Email</h1>
            <div className='ml-auto text-sm text-gray-500'>
              <div className="mt-1">
                <input
                  type="email"
                  name="email"
                  id="email"
                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  placeholder="newhorizon@example.com"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='flex gap-5 mt-5'>
        <div className="border border-gray-200 bg-white px-4 py-5 sm:px-6 rounded-md w-1/2">
          <h3 className="text-lg font-medium leading-6 text-gray-900">Contact Number</h3>
          <p className="mt-1 text-sm text-gray-500">
            Lorem ipsum, dolor sit amet consectetur adipisicing elit. Dolorum minima, nihil ipsum eos error distinctio.
          </p>
          <div className='mt-5 flex items-center'>
            <h1>Phone</h1>
            <div className='ml-auto'>
                <input
                  type="text"
                  name="phone"
                  id="phone"
                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  placeholder="9876543210"
                />
            </div>
          </div>
        </div>
        <div className="border border-gray-200 bg-white px-4 py-5 sm:px-6 rounded-md w-1/2">
          <h3 className="text-lg font-medium leading-6 text-gray-900">About Us</h3>
          <p className="mt-1 text-sm text-gray-500">
            Lorem ipsum dolor sit, amet consectetur adipisicing elit. Voluptatem vitae ut esse a quod illo laudantium, accusamus debitis. Molestias id enim, optio sed reprehenderit, maiores beatae laudantium minus officiis saepe nam unde alias delectus dicta, excepturi aliquid explicabo fugit porro.
          </p>
          {/* <div className='mt-5 flex items-center'>
            <h1>Email</h1>
            <div className='ml-auto text-sm text-gray-500'>
              newhorizon@gmail.com
            </div>
          </div> */}
        </div>
      </div>
      <div className="pt-5">
        <div className="flex justify-end">
          <button
            type="button"
            className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          >
            Cancel
          </button>
          <button
            type="submit"
            className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  )
}

export default GlobalSettings