import React from 'react';
import { IoWarning } from 'react-icons/io5';

function Confirm_model(props) {
  return (
    <>
      <div className="w-screen z-50 h-screen bg-black bg-opacity-60 fixed top-0 right-0 flex justify-center items-center">
        <div className="bg-white p-5 rounded-md shadow-md">
          <h1 className="font-bold text-center text-lg my-2">{props.header}</h1>
          <p>{props.para}</p>
          <div className="flex justify-between mt-5">
            <button
              className="focus:outline-none text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900"
              onClick={props.setPopUp}
            >
              No, Cancel
            </button>
            <button
              className="focus:outline-none text-white bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:ring-purple-300 font-medium rounded-lg text-sm px-5 py-2.5 mb-2 dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-900"
              onClick={props.action}
            >
              Yes, Confirm
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default Confirm_model;
