import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { FallingLinesLoader } from "../../../components/spinners/Spinner";
import { getHeaders } from "../../../redux/auth/actions";
import Table from "../../../components/tables/table";
import axios from "axios";
import { toast } from "react-hot-toast";
import moment from "moment";
const SessionsPublic = () => {
  const { id: p_id } = useParams();
  const Api = process.env.REACT_APP_API_URL;
  const pages = [{ title: "Appointments", href: "/therapist" }];
  const [loading, setloading] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState({
    status: false,
    id: null,
  });
  const [appoitments, setappoitments] = useState({
    data: [],
    filteredData: [],
  });
  const dispatch = useDispatch();
  const { list: therapistList } = useSelector((state) => state.Therapist);

  useEffect(() => {
    document.title = "New Horizon | Session Logs";
    getallappoitments(p_id);
  }, []);
  const [searcher, setSearcher] = useState({
    date: "",
    therapist_id: "",
    department_id: "",
  });
  const getallappoitments = async () => {
    const headers = dispatch(getHeaders());
    try {
      setloading(true);
      const res = await axios.get(
        `${Api}/api/therapist/appointment/appointment-session/${p_id}`,
        {
          headers: headers,
        }
      );
      const { data, status } = res;
      if (status === 200) {
        setappoitments({
          data: data.data,
          filteredData: data.data,
        });
      }
    } catch (error) {
    } finally {
      setloading(false);
    }
  };

  const onDeleteOpen = (id) => {
    setConfirmationModal({ id, status: true });
  };

  const onDeleteLocation = (id) => {
    setConfirmationModal({ id: null, status: false });
  };
  async function markPresent(id) {
    const headers = dispatch(getHeaders());
    try {
      const res = await axios.put(
        `${Api}/api/therapist/appointment/mark-present/${id}`,
        {},
        {
          headers: headers,
        }
      );
      const { data } = res;
      toast.success(data.message);
      getallappoitments();
      setConfirmationModal((prev) => ({
        ...prev,
        status: false,
      }));
    } catch (error) {
      toast.error(error.response.data.message);
    }
  }
  const { list: departmentList } = useSelector((state) => state.department);
  //   useEffect(() => {
  //     const { date, therapist_id, department_id } = searcher;

  //     // Check if any filters are active
  //     if (date || therapist_id || department_id) {
  //       const filteredData = appoitments.data.filter((item) => {
  //         if (date && therapist_id && department_id) {
  //           return (
  //             item.date === date + "T00:00:00.000Z" &&
  //             item.therapist_id === Number(therapist_id) &&
  //             item.department_id === Number(department_id)
  //           );
  //         }
  //         if (date && therapist_id) {
  //           return (
  //             item.date === date + "T00:00:00.000Z" &&
  //             item.therapist_id === Number(therapist_id)
  //           );
  //         }
  //         if (date && department_id) {
  //           return (
  //             item.date === date + "T00:00:00.000Z" &&
  //             item.department_id === Number(department_id)
  //           );
  //         }
  //         if (therapist_id && department_id) {
  //           return (
  //             item.therapist_id === Number(therapist_id) &&
  //             item.department_id === Number(department_id)
  //           );
  //         }
  //         if (date) {
  //           return item.date === date + "T00:00:00.000Z";
  //         }
  //         if (therapist_id) {
  //           return item.therapist_id === Number(therapist_id);
  //         }
  //         if (department_id) {
  //           return item.department_id === Number(department_id);
  //         }
  //       });

  //       setappoitments((prev) => ({
  //         ...prev,
  //         filteredData,
  //       }));
  //     }
  //     // Check if all filters are cleared
  //     else if (
  //       date === undefined &&
  //       therapist_id === undefined &&
  //       department_id === undefined
  //     ) {
  //       setappoitments((prev) => ({
  //         ...prev,
  //         filteredData: prev.data,
  //       }));
  //     }
  //   }, [searcher]);

  const sessions_colums = ({ setConfirmationModal }) => [
    {
      Header: "Id",
      accessor: "id",
    },
    {
      Header: "Therapist Name",
      accessor: (e) =>
        e.TherapistMaster.first_name + " " + e.TherapistMaster.last_name,
      Cell: (cell) => {
        return (
          <p className=" text-gray-500 text-sm ">
            {cell && cell.row.original.TherapistMaster.first_name}{" "}
            {cell && cell.row.original.TherapistMaster.last_name}
          </p>
        );
      },
    },
    {
      Header: "department",
      accessor: (e) => e.department.name,
    },
    {
      Header: "date",
      accessor: (e) => moment(e.date).format("DD-MM-YYYY"),
    },
    {
      Header: "Day",
      // accessor: (e) => moment(e.date).format("dddd"),
      accessor: (e) => moment(e.date).format("dddd"),
    },
    {
      Header: "start time",
      accessor: (e) => moment.utc(e.start_time).utcOffset(0).format("h:mm A"),
    },
    {
      Header: "End time",
      accessor: (e) => moment.utc(e.end_time).utcOffset(0).format("h:mm A"),
    },
    // {
    //   Header: "Cost",
    //   accessor: "cost",
    // },
    // {
    //   Header: "attendance",
    //   accessor: (e) => e?.attendance,
    //   Cell: (cell) => {
    //     return (
    //       <p className="text-gray-500 text-sm">
    //         {cell.row.original.attendance == "ABSENT" ? (
    //           <span className="inline-flex px-2 text-xs leading-5 text-cyan-600 bg-cyan-100 rounded-full">
    //             <button
    //               className="text-cyan-900 "
    //               size="default"
    //               variant="outlined"
    //               onClick={() => {
    //                 setConfirmationModal((prev) => ({
    //                   ...prev,
    //                   status: true,
    //                   id: cell.row.original.id,
    //                   presetnt: true,
    //                 }));
    //               }}
    //             >
    //               Mark Present<span className="sr-only">, </span>
    //             </button>
    //           </span>
    //         ) : (
    //           "Present"
    //         )}
    //       </p>
    //     );
    //   },
    // },
  ];
  return (
    <div className="px-4 sm:px-6 lg:px-8">
      {/* <ConfirmationModal
        title="Mark as Present"
        message="Are you sure you want to mark this enrolment as present?"
        open={confirmationModal.status}
        setOpen={setConfirmationModal}
        confirmationButtonText="Present"
        confirmationButtonColor="indigo"
        onDelete={() => markPresent(confirmationModal.id)}
      /> */}
      {/* <div className="sm:flex sm:items-center mb-5">
        <div className="sm:flex-auto">
          <h1 className="text-xl font-semibold text-gray-900">Sessions</h1>
        </div>
        <div className="sm:grid sm:grid-cols-2    w-max sm:items-start sm:gap-4  sm:border-gray-200 sm:pt-5">
          <div
            className={`mt-1 flex items-center justify-right  grid-cols-2
           sm:col-span-2 sm:mt-0`}
          >
            <input
              type="date"
              name="fromDate"
              id="fromDate"
              value={searcher.date}
              onChange={(e) =>
                setSearcher({ ...searcher, date: e.target.value })
              }
              placeholder="select date"
              className="mr-2 ml-2 block w-[90%] max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-indigo-500 sm:max-w-xs sm:text-sm"
            />

            <select
              id="therapist"
              name="therapist"
              value={Number(searcher.therapist_id)}
              onChange={(e) =>
                setSearcher({ ...searcher, therapist_id: e.target.value })
              }
              autoComplete="therapist"
              className="mr-2 ml-2 block w-[90%] max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-indigo-500 sm:max-w-xs sm:text-sm"
            >
              <option>Therapist</option>

              {therapistList.map((therapist) => (
                <option key={therapist.id} value={therapist.id}>
                  {therapist.first_name + " " + therapist.last_name}
                </option>
              ))}
            </select>
            <select
              id="department"
              name="department"
              value={Number(searcher.department_id)}
              onChange={(e) =>
                setSearcher({ ...searcher, department_id: e.target.value })
              }
              autoComplete="therapist"
              className="mr-2 ml-2 block w-[90%] max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-indigo-500 sm:max-w-xs sm:text-sm"
            >
              <option>Department</option>
              {departmentList.map((department) => (
                <option key={department.id} value={department.id}>
                  {department.name}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div> */}
      {loading ? (
        <FallingLinesLoader />
      ) : (
        <Table
          columns={sessions_colums({
            onDeleteOpen,
            setConfirmationModal,
          })}
          data={appoitments.filteredData}
        />
      )}
    </div>
  );
};

export default SessionsPublic;
