export const GET_ALL_STRENGTH_BEGIN = "GET_ALL_STRENGTH_BEGIN"
export const GET_ALL_STRENGTH_SUCCESS = "GET_ALL_STRENGTH_SUCCESS"
export const GET_ALL_STRENGTH_ERROR = "GET_ALL_STRENGTH_ERROR"

export const ADD_STRENGTH_BEGIN = "ADD_STRENGTH_BEGIN"
export const ADD_STRENGTH_SUCCESS = "ADD_STRENGTH_SUCCESS"
export const ADD_STRENGTH_ERROR = "ADD_STRENGTH_ERROR"

export const GET_STRENGTH_BEGIN = "GET_STRENGTH_BEGIN"
export const GET_STRENGTH_SUCCESS = "GET_STRENGTH_SUCCESS"
export const GET_STRENGTH_ERROR = "GET_STRENGTH_ERROR"

export const UPDATE_STRENGTH_BEGIN = "UPDATE_STRENGTH_BEGIN"
export const UPDATE_STRENGTH_SUCCESS = "UPDATE_STRENGTH_SUCCESS"
export const UPDATE_STRENGTH_ERROR = "UPDATE_STRENGTH_ERROR"

export const DELETE_STRENGTH_BEGIN = "DELETE_STRENGTH_BEGIN"
export const DELETE_STRENGTH_SUCCESS = "DELETE_STRENGTH_SUCCESS"
export const DELETE_STRENGTH_ERROR = "DELETE_STRENGTH_ERROR"