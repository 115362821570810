import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import Pagination from "../../../../components/Pagination";
import { FallingLinesLoader } from "../../../../components/spinners/Spinner";
import { deleteSchedule, getAllSchedule } from "../../../../redux/schedule/actions";
import { capitalise } from "../../../../utilities/utilities";
import Breadcrumb from "../../../../components/Breadcrumb";
import ConfirmationModal from "../../../../components/ConfirmationModal";

import Table from "../../../../components/tables/table";
import { schedule_columns } from "../../../../components/tables/tableheader";

const ScheduleList = () => {
  const pages = [{ title: "Doctor", href: "/doctors" }, { title: "Schedule" }];

  const dispatch = useDispatch();
  const { loading, list: scheduleList } = useSelector(
    (state) => state.schedule
  );
  const [search, setsearch] = useState("");
  const [currentpage, setcurrentpage] = useState(1);
  const [postsPerPage, setpostsPerPage] = useState(10);
  const [confirmationModal, setConfirmationModal] = useState({
    status: false,
    id: null,
  });
  const { id: doctorId } = useParams();

  useEffect(() => {
    document.title = "New Horizon | Schedule";
    if (doctorId) dispatch(getAllSchedule(doctorId));
  }, [doctorId]);

  const onDeleteOpen = (id) => {
    setConfirmationModal({ id, status: true });
  };

  const handleDelete = (id) => {
    dispatch(deleteSchedule(id));
    setConfirmationModal({ id: null, status: false });
  };

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <ConfirmationModal
        open={confirmationModal.status}
        setOpen={setConfirmationModal}
        onDelete={() => handleDelete(confirmationModal.id)}
      />
      <Breadcrumb pages={pages} />
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-xl font-semibold text-gray-900">Schedule</h1>
          <p className="mt-2 text-sm text-gray-700">
            A list of all the schedule of the doctor
          </p>
        </div>
        <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
          <Link
            to="add"
            type="button"
            className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
          >
            Add Schedule
          </Link>
        </div>
      </div>
      {loading ? (
        <FallingLinesLoader />
      ) : (
        <Table columns={schedule_columns({ onDeleteOpen })} data={scheduleList} />
      )}
    </div>
  );
};

export default ScheduleList;
