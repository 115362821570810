import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { FallingLinesLoader } from "../../../components/spinners/Spinner";
import { getAllLocations } from "../../../redux/location/actions";
import { ArrowUturnLeftIcon } from "@heroicons/react/20/solid";
import {
  addTherapist,
  getTherapistWithId,
  updateTherapist,
} from "../../../redux/therapist/therpist/actions";
import Breadcrumb from "../../../components/Breadcrumb";
import { MultiSelect } from "react-multi-select-component";
import { getAllDepartments } from "../../../redux/department/actions";
import { TherapistSchema } from "../../../schemas";

const AddTherapist = () => {
  const pages = [{ title: "Therapist", href: "/therapist" }];
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id: therapistId } = useParams();
  const [selected, setSelected] = useState([]);
  const [submitted, setSubmitted] = useState(false);
  const { loading, list: departmentList } = useSelector(
    (state) => state.department
  );

  const { list: locationList } = useSelector((state) => state.location);
  const { TherapistData } = useSelector((state) => state.Therapist);

  const initialValues = {
    first_name: "",
    last_name: "",
    email: "",
    phone_number: "",
    password: "",
    Confirm_Password: "",
  };
  const [formState, setFormState] = useState(initialValues);
  // for maping departement to multi select
  function transformData(departmentList) {
    return departmentList.map((item) => ({ label: item.name, value: item.id }));
  }

  // for putting data comming from backend to multi select
  function rtransformData(TherapistData) {
    return TherapistData.Therapist_department.map((item) => ({
      label: item.department.name,
      value: item.department.id,
    }));
  }

  const transformedData = transformData(departmentList);

  useEffect(() => {
    if (therapistId) dispatch(getTherapistWithId(therapistId));

    if (!locationList.length) {
      dispatch(getAllLocations());
    }
  }, [therapistId, locationList]);

  useEffect(() => {
    document.title = "New Horizon | Add-Therapist";
    dispatch(getAllDepartments());
    setSubmitted(false);
  }, []);
  useEffect(() => {
    if (therapistId && TherapistData) {
      setSelected(rtransformData(TherapistData));

      const newFormState = {
        first_name: TherapistData.first_name,
        last_name: TherapistData.last_name,
        email: TherapistData.email,
        phone_number: TherapistData.phone_number,
        password: "update",
        Confirm_Password: "update",
      };
      setFormState(newFormState);
    }
  }, [therapistId, TherapistData]);

  const { values, errors, handleBlur, touched, handleChange, handleSubmit } =
    useFormik({
      enableReinitialize: true,
      initialValues: formState,
      validationSchema: TherapistSchema,
      onSubmit: (values, action) => {
        const body = {
          ...values,
          department_id: [...selected.map((item) => item.value)],
          role: "THERAPIST",
        };
        delete body.Confirm_Password;
        delete body.department;
        if (therapistId) {
          delete body.password;
          delete body.role;
          dispatch(updateTherapist(therapistId, body));
        } else {
          dispatch(addTherapist(body));
        }
        action.resetForm();
        navigate("/therapist");
        console.log(body.department_id);
      },
    });
  return (
    <div className="px-4 sm:px-6 lg:px-8  ">
      <Breadcrumb pages={pages} />
      <button
        type="button"
        onClick={() => navigate(-1)}
        className="inline-flex mb-3 justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
      >
        <span className="w-5 mr-2">
          <ArrowUturnLeftIcon size={18} />
        </span>{" "}
        Back
      </button>
      <div className="space-y-6 sm:space-y-5">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-lg font-medium leading-6 text-gray-900">
              {therapistId ? "Update Therapist" : "Add Therapist"}
            </h1>
            <p className="mt-1 max-w-2xl text-sm text-gray-500">
              This information will be displayed publicly so be careful what you
              share.
            </p>
          </div>
        </div>
        {loading ? (
          <FallingLinesLoader />
        ) : (
          <form
            onSubmit={handleSubmit}
            className="space-y-6 divide-y divide-gray-200"
          >
            <div className="space-y-6 sm:space-y-5">
              <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                <label
                  htmlFor="firstName"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  First Name
                </label>
                <div className="mt-1 sm:col-span-2 sm:mt-0">
                  <input
                    className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                    type="text"
                    name="first_name"
                    id="firstName"
                    placeholder="First Name"
                    value={values.first_name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.first_name && touched.first_name ? (
                    <p className="text-red-600 text-sm">{errors.first_name}</p>
                  ) : null}
                </div>
              </div>
              <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                <label
                  htmlFor="lastName"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Last name
                </label>
                <div className="mt-1 sm:col-span-2 sm:mt-0">
                  <input
                    className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                    type="text"
                    name="last_name"
                    placeholder="Last Name"
                    id="lastName"
                    value={values.last_name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.last_name && touched.last_name ? (
                    <p className="text-red-600 text-sm">{errors.last_name}</p>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Email Address
              </label>
              <div className="mt-1 sm:col-span-2 sm:mt-0">
                <input
                  className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                  type="email"
                  placeholder="Email Address"
                  name="email"
                  id="email"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.email && touched.email ? (
                  <p className="text-red-600 text-sm">{errors.email}</p>
                ) : null}
              </div>
            </div>
            <div className="space-y-6  sm:space-y-5">
              <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                <label
                  htmlFor="role"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Department
                </label>
                <div className="mt-1 sm:col-span-2 sm:mt-0  ">
                  <MultiSelect
                    options={transformedData}
                    value={selected}
                    onChange={setSelected}
                    labelledBy="Select"
                    className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                  />
                  {submitted === true && selected.length < 1 && (
                    <p className="text-red-600 text-sm">
                      Please select the department
                    </p>
                  )}
                </div>
              </div>
            </div>
            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                htmlFor="phone_number"
                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                phone Number
              </label>
              <div className="mt-1 sm:col-span-2 sm:mt-0">
                <input
                  className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                  type="text"
                  placeholder="phone number"
                  name="phone_number"
                  id="phone_number"
                  value={values.phone_number}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.phone_number && touched.phone_number ? (
                  <p className="text-red-600 text-sm">{errors.phone_number}</p>
                ) : null}
              </div>
            </div>

            {!therapistId && (
              <div className="space-y-6 sm:space-y-5">
                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                  <label
                    htmlFor="password"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Password
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <input
                      placeholder="Password"
                      className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                      type="Password"
                      name="password"
                      id="phone"
                      value={values.password}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.password && touched.password ? (
                      <p className="text-red-600 text-sm">{errors.password}</p>
                    ) : null}
                  </div>
                </div>
                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                  <label
                    htmlFor="confirm_password"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Confirm Password
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <input
                      className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                      type="password"
                      placeholder="Confirm Password"
                      name="Confirm_Password"
                      id="Confirm_Password"
                      autoComplete="off"
                      value={values.Confirm_Password}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />

                    {touched.Confirm_Password && (
                      <p className="text-red-600 text-sm">
                        {errors.Confirm_Password}
                      </p>
                    )}
                  </div>
                </div>
              </div>
            )}

            <div className="pt-5">
              <div className="flex justify-end">
                <button
                  onClick={() => {
                    setSubmitted(true);
                  }}
                  type="submit"
                  className="ml-3 inline-flex  justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                >
                  Submit
                </button>
              </div>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default AddTherapist;
