export const GET_ALL_MEDICINE_MASTER_BEGIN = "GET_ALL_MEDICINE_MASTER_BEGIN"
export const GET_ALL_MEDICINE_MASTER_SUCCESS = "GET_ALL_MEDICINE_MASTER_SUCCESS"
export const GET_ALL_MEDICINE_MASTER_ERROR = "GET_ALL_MEDICINE_MASTER_ERROR"

export const ADD_MEDICINE_MASTER_BEGIN = "ADD_MEDICINE_MASTER_BEGIN"
export const ADD_MEDICINE_MASTER_SUCCESS = "ADD_MEDICINE_MASTER_SUCCESS"
export const ADD_MEDICINE_MASTER_ERROR = "ADD_MEDICINE_MASTER_ERROR"

export const GET_MEDICINE_MASTER_BEGIN = "GET_MEDICINE_MASTER_BEGIN"
export const GET_MEDICINE_MASTER_SUCCESS = "GET_MEDICINE_MASTER_SUCCESS"
export const GET_MEDICINE_MASTER_ERROR = "GET_MEDICINE_MASTER_ERROR"

export const UPDATE_MEDICINE_MASTER_BEGIN = "UPDATE_MEDICINE_MASTER_BEGIN"
export const UPDATE_MEDICINE_MASTER_SUCCESS = "UPDATE_MEDICINE_MASTER_SUCCESS"
export const UPDATE_MEDICINE_MASTER_ERROR = "UPDATE_MEDICINE_MASTER_ERROR"

export const DELETE_MEDICINE_MASTER_BEGIN = "DELETE_MEDICINE_MASTER_BEGIN"
export const DELETE_MEDICINE_MASTER_SUCCESS = "DELETE_MEDICINE_MASTER_SUCCESS"
export const DELETE_MEDICINE_MASTER_ERROR = "DELETE_MEDICINE_MASTER_ERROR"