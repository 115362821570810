export const GET_ALL_ROUTE_OF_ADMINISTRATION_BEGIN =
  "GET_ALL_ROUTE_OF_ADMINISTRATION_BEGIN";
export const GET_ALL_ROUTE_OF_ADMINISTRATION_SUCCESS =
  "GET_ALL_ROUTE_OF_ADMINISTRATION_SUCCESS";
export const GET_ALL_ROUTE_OF_ADMINISTRATION_ERROR =
  "GET_ALL_ROUTE_OF_ADMINISTRATION_ERROR";

export const ADD_ROUTE_OF_ADMINISTRATION_BEGIN =
  "ADD_ROUTE_OF_ADMINISTRATION_BEGIN";
export const ADD_ROUTE_OF_ADMINISTRATION_SUCCESS =
  "ADD_ROUTE_OF_ADMINISTRATION_SUCCESS";
export const ADD_ROUTE_OF_ADMINISTRATION_ERROR =
  "ADD_ROUTE_OF_ADMINISTRATION_ERROR";

export const GET_ROUTE_OF_ADMINISTRATION_BEGIN =
  "GET_ROUTE_OF_ADMINISTRATION_BEGIN";
export const GET_ROUTE_OF_ADMINISTRATION_SUCCESS =
  "GET_ROUTE_OF_ADMINISTRATION_SUCCESS";
export const GET_ROUTE_OF_ADMINISTRATION_ERROR =
  "GET_ROUTE_OF_ADMINISTRATION_ERROR";

export const UPDATE_ROUTE_OF_ADMINISTRATION_BEGIN =
  "UPDATE_ROUTE_OF_ADMINISTRATION_BEGIN";
export const UPDATE_ROUTE_OF_ADMINISTRATION_SUCCESS =
  "UPDATE_ROUTE_OF_ADMINISTRATION_SUCCESS";
export const UPDATE_ROUTE_OF_ADMINISTRATION_ERROR =
  "UPDATE_ROUTE_OF_ADMINISTRATION_ERROR";

export const DELETE_ROUTE_OF_ADMINISTRATION_BEGIN =
  "DELETE_ROUTE_OF_ADMINISTRATION_BEGIN";
export const DELETE_ROUTE_OF_ADMINISTRATION_SUCCESS =
  "DELETE_ROUTE_OF_ADMINISTRATION_SUCCESS";
export const DELETE_ROUTE_OF_ADMINISTRATION_ERROR =
  "DELETE_ROUTE_OF_ADMINISTRATION_ERROR";
