export const GET_ALL_DEPARTMENT_BEGIN = "GET_ALL_DEPARTMENT_BEGIN"
export const GET_ALL_DEPARTMENT_SUCCESS = "GET_ALL_DEPARTMENT_SUCCESS"
export const GET_ALL_DEPARTMENT_ERROR = "GET_ALL_DEPARTMENT_ERROR"

export const ADD_DEPARTMENT_BEGIN = "ADD_DEPARTMENT_BEGIN"
export const ADD_DEPARTMENT_SUCCESS = "ADD_DEPARTMENT_SUCCESS"
export const ADD_DEPARTMENT_ERROR = "ADD_DEPARTMENT_ERROR"

export const GET_DEPARTMENT_BEGIN = "GET_DEPARTMENT_BEGIN"
export const GET_DEPARTMENT_SUCCESS = "GET_DEPARTMENT_SUCCESS"
export const GET_DEPARTMENT_ERROR = "GET_DEPARTMENT_ERROR"

export const UPDATE_DEPARTMENT_BEGIN = "UPDATE_DEPARTMENT_BEGIN"
export const UPDATE_DEPARTMENT_SUCCESS = "UPDATE_DEPARTMENT_SUCCESS"
export const UPDATE_DEPARTMENT_ERROR = "UPDATE_DEPARTMENT_ERROR"

export const DELETE_DEPARTMENT_BEGIN = "DELETE_DEPARTMENT_BEGIN"
export const DELETE_DEPARTMENT_SUCCESS = "DELETE_DEPARTMENT_SUCCESS"
export const DELETE_DEPARTMENT_ERROR = "DELETE_DEPARTMENT_ERROR"