import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import Modal from "../../../components/Modal";
import Breadcrumb from "../../../components/Breadcrumb";
import { FallingLinesLoader } from "../../../components/spinners/Spinner";
import Table from "../../../components/tables/table";
import { toast } from "react-hot-toast";
import ConfirmationModal from "../../../components/ConfirmationModal";
import Button from "../../../components/Button";
import InputBox from "../../../components/InputBox";
import axios from "axios";
import { getHeaders } from "../../../redux/auth/actions";
import { useDispatch } from "react-redux";
import { module_colomn } from "../../../components/tables/tableheader";
import moment from "moment";
const initialModalState = {
  type: "",
  state: false,
  index: null,
  edit_id: "",
  data: {
    name: "",
  },
};
const api = process.env.REACT_APP_API_URL;
function ModuleMaster() {
  const pages = [{ title: "Module Master", href: "/modules" }];
  const [modal, setModal] = useState(initialModalState);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    getAllModules();
    // getAllStorageLocations();
    document.title = "New Horizon | Modules Master";
  }, []);
  useEffect(() => {
    if (modal.edit_id !== "") {
      getModuleById(modal.edit_id);
    }
  }, [modal.edit_id]);
  const [labs, setLabs] = useState({
    loading: false,
    data: [],
  });
  const [confirmationModal, setConfirmationModal] = useState({
    status: false,
    id: null,
  });
  const [moduleList, setModuleList] = useState({
    loading: false,
    data: [],
  });

  const getAllModules = async () => {
    const headers = await dispatch(getHeaders());
    setModuleList((prev) => ({ ...prev, loading: true }));
    try {
      const res = await axios.get(`${api}/api/module-master`, {
        headers: headers,
      });
      const { data, status } = res;
      if (status === 200) {
        setModuleList((prev) => ({
          ...prev,
          data: data.data,
          loading: false,
        }));
      }
      console.log(res);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const createModule = async (body) => {
    try {
      const headers = await dispatch(getHeaders());
      const res = await axios.post(`${api}/api/module-master`, body, {
        headers: headers,
      });
      const { data, status } = res;
      if (status === 201) {
        getAllModules();
        toast.success("Module Created Successfully");
        setModal((prev) => ({
          ...prev,
          state: false,
          data: {},
          edit_id: "",
          type: "",
        }));
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const getModuleById = async (id) => {
    try {
      const headers = await dispatch(getHeaders());
      const res = await axios.get(`${api}/api/module-master/${id}`, {
        headers: headers,
      });
      const { data, status } = res;
      if (status === 200) {
        setModal((prev) => ({
          ...prev,
          type: "edit",
          data: {
            name: data.data.name,
          },
        }));
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const updateModule = async (id, body) => {
    try {
      const headers = await dispatch(getHeaders());
      const res = await axios.put(`${api}/api/module-master/${id}`, body, {
        headers: headers,
      });
      const { data, status } = res;
      if (status === 200) {
        getAllModules();
        toast.success("Module Updated Successfully");
        setModal((prev) => ({
          ...prev,
          state: false,
          data: {},
          edit_id: "",
          type: "",
        }));
      }
    } catch (error) {}
  };
  const onDeleteOpen = (id) => {
    setConfirmationModal({ id: id, status: true });
  };

  const onDeleteModule = async (id) => {
    const headers = await dispatch(getHeaders());
    try {
      const res = await axios.delete(`${api}/api/module-master/${id}`, {
        headers: headers,
      });
      const { data, status } = res;
      if (status === 200) {
        getAllModules();
        toast.success("Module Deleted Successfully");
        setConfirmationModal({ id: null, status: false });
      }
    } catch (error) {}
  };
  const renderModal = () => {
    const { type, state, data, edit_id } = modal;

    return (
      <Formik
        initialValues={data}
        validationSchema={Yup.object({
          name: Yup.string().required("Please enter name"),
        })}
        enableReinitialize={true}
        onSubmit={(values, action) => {
          const body = {
            name: values.name,
          };
          if (modal.type === "edit") {
            updateModule(modal.edit_id, body);
          } else {
            createModule(body);
            action.resetForm();
          }
          action.setSubmitting(false);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <Modal
            title={`${modal.type === "edit" ? "Update" : "Add"} Module`}
            open={state}
            setOpen={() =>
              setModal((prev) => ({
                ...prev,
                state: false,
                data: {},
                edit_id: "",
                type: "",
              }))
            }
          >
            <form onSubmit={handleSubmit}>
              <div className="text-left mt-4">
                <div className="mt-4"></div>
                <InputBox
                  name="name"
                  label="Name"
                  type="text"
                  value={values.name}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  required={true}
                  placeholder="Enter the Name"
                />
                {touched.name && (
                  <p className="text-red-700 error_msg">{errors.name}</p>
                )}
              </div>

              <div className="mt-4"></div>
              <Button disabled={isSubmitting} type="submit">
                {modal.type === "edit" ? "Update" : "Add"} Module
              </Button>
            </form>
          </Modal>
        )}
      </Formik>
    );
  };

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <Breadcrumb pages={pages} />
      {renderModal()}
      <ConfirmationModal
        open={confirmationModal.status}
        setOpen={setConfirmationModal}
        onDelete={() => onDeleteModule(confirmationModal.id)}
      />
      <div className="">
        <h1 className="text-xl font-semibold text-gray-900">Module</h1>
      </div>
      <div className="mt-4 flex">
        <button
          type="button"
          onClick={() =>
            setModal((prev) => ({
              ...prev,
              data: {},
              type: "",
              edit_id: "",
              state: true,
            }))
          }
          className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 ml-auto"
        >
          Add
        </button>
      </div>
      {moduleList.loading ? (
        <FallingLinesLoader />
      ) : (
        <Table
          columns={module_colomn({ setModal, onDeleteOpen })}
          data={moduleList.data}
        />
      )}
    </div>
  );
}
export default ModuleMaster;
