import React, { useEffect, useState } from "react";
import Table from "../../../../components/tables/table";
import { consolidate_Report_columns } from "../../../../components/tables/tableheader";
import { useDispatch, useSelector } from "react-redux";
import { FallingLinesLoader } from "../../../../components/spinners/Spinner";
import { getOpdAppointmentWithId } from "../../../../redux/opdreport/action";
import { getAllDoctors } from "../../../../redux/doctor/actions";
import moment from "moment";
import { async } from "q";
import axios from "axios";
import { toast } from "react-hot-toast";
import Breadcrumb from "../../../../components/Breadcrumb";
import { getHeaders } from "../../../../redux/auth/actions";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const ConsoliateReport = () => {
  const [selected, setselected] = useState("all");
  const [date, setDate] = useState("");
  const dispatch = useDispatch();
  const { loading, list: doctor } = useSelector((state) => state.doctor);
  const { list: opdReport } = useSelector((state) => state.opdAppointment);
  const [data, setData] = useState(opdReport);
  const [currentTab, setcurrentTab] = useState("Online");
  const [datefilter, set_date_filter] = useState({
    from: moment().subtract(3, "month").format("YYYY-MM-DD"),
    to: moment().add(1, "year").format("YYYY-MM-DD"),
    doctor_id: null,
  });

  const API = process.env.REACT_APP_API_URL;
  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    document.title = "New Horizon | OPD report";
    dispatch(getOpdAppointmentWithId(selected));
  }, [selected]);

  const get_report_data = async (
    from = moment().subtract(3, "month").format("YYYY-MM-DD"),
    to = moment().add(1, "year").format("YYYY-MM-DD"),
    doctor_id = null
  ) => {
    // const headers = dispatch(getHeaders());
    // const response = await axios.post(`${API}/api/reporting/opd`,{from,to,doctor_id}, {
    //   headers,
    // });
    // //patient data
    // const { data } = response;
    let apicall = [
      {
        id: 1,
        opd_date: "30.3.2023",
        child_name: "Harsh Kumar Verma",
        consultation_type: "New",
        residence_area: "Goregoan",
        gender: "Male",
        date_of_birth: "3.9.2021",
        school: "Sacred Municipality School",
        referred_by: "Dr Hilla",
        contact_no: 1234569691,
        email_id: "harsh@onepercentstartups.com",
        eval_reval: "NA",
        eval_re_booked: "DQ DIP2 CIP GARS ASD (DSM)",
        nhdp_recom: "NA",
        nhdp_booked: "Booked",
        comment: "No Comment Please",
      },
      {
        id: 2,
        opd_date: "30.3.2023",
        child_name: "Kedar Babu",
        consultation_type: "Follow Up",
        residence_area: "Ambernath",
        gender: "Male",
        date_of_birth: "3.9.1998",
        school: "Xaviers School",
        referred_by: "Dr Harsh",
        contact_no: 123459233891,
        email_id: "kedar@onepercentstartups.com",
        eval_reval: "Eval",
        eval_re_booked: "NA",
        nhdp_recom: "Enable",
        nhdp_booked: "Not Booked",
        comment: "",
      },
    ];
    let tabdata = [];
    Object.values(apicall).map((element) => {
      tabdata.push({
        id: element.id,
        opd_date: element.opd_date,
        child_name: element.child_name,
        consultation_type: element.consultation_type,
        residence_area: element.residence_area,
        gender: element.gender,
        date_of_birth: element.date_of_birth,
        school: element.school,
        referred_by: element.referred_by,
        contact_no: element.contact_no,
        email_id: element.email_id,
        eval_reval: element.eval_reval,
        eval_re_booked: element.eval_re_booked,
        nhdp_recom: element.nhdp_recom,
        nhdp_booked: element.nhdp_booked,
        comment: element.comment,
      });
    });

    console.log(tabdata);
    setData(tabdata);
  };

  useEffect(() => {
    get_report_data();
  }, []);

  useEffect(() => {
    console.log(
      datefilter,
      (datefilter.from && datefilter.to) || datefilter.doctor_id
    );
    if ((datefilter.from && datefilter.to) || datefilter.doctor_id) {
      get_report_data(datefilter.from, datefilter.to, datefilter.doctor_id);
    }
  }, [datefilter]);

  const pages = [{ title: "Consolidate Report", href: "/consolidate-report" }];

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center mb-5">
        <div className="sm:flex-auto">
          <Breadcrumb pages={pages} />
          <h1 className="text-xl font-semibold text-gray-900">
            Consolidate Report
          </h1>
        </div>
        <div className="sm:grid sm:grid-cols-2    w-max sm:items-start sm:gap-4  sm:border-gray-200 sm:pt-5">
          <div
            className={`mt-1 flex items-center justify-right ${
              user.role !== "DOCTOR" && "grid-cols-2"
            } sm:col-span-2 sm:mt-0`}
          >
            <label>From: </label>
            <input
              type="date"
              name="date"
              // value={(e) => setDate(e.target.value)}
              onChange={(e) =>
                set_date_filter({ ...datefilter, from: e.target.value })
              }
              placeholder="select date"
              className="mr-2 ml-2 block w-[90%] max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-indigo-500 sm:max-w-xs sm:text-sm"
            />
            <label>To: </label>
            <input
              type="date"
              name="date"
              // value={(e) => setDate(e.target.value)}
              onChange={(e) =>
                set_date_filter({ ...datefilter, to: e.target.value })
              }
              placeholder="select date"
              className="mr-2 ml-2 block w-[90%] max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-indigo-500 sm:max-w-xs sm:text-sm"
            />
            {user.role === "DOCTOR" ? null : (
              <select
                id="type"
                name="type"
                defaultValue={selected}
                onChange={(e) => {
                  set_date_filter({ ...datefilter, doctor_id: e.target.value });
                }}
                // value={(e)=>setselected(e.target.value)}
                className="block ml-1 w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-indigo-500 sm:max-w-xs sm:text-sm"
              >
                <option value="">Select a Doctor</option>

                {doctor.map((d, idx) => (
                  <option key={idx} value={d.id}>
                    {"Dr. " + d.first_name + " " + d.last_name}
                  </option>
                ))}
              </select>
            )}
            {/* {errors.type && touched.type ? (
                    <p className="text-red-700">{errors.type}</p>
                  ) : null} */}
          </div>
        </div>
      </div>
      {loading ? (
        <FallingLinesLoader />
      ) : (
        data && (
          <>
            <Table columns={consolidate_Report_columns({})} data={data} />
          </>
        )
      )}
    </div>
  );
};

export default ConsoliateReport;
