/* eslint-disable */
import {
  ADD_AVAILABILITY_BEGIN,
  ADD_AVAILABILITY_SUCCESS,
  ADD_AVAILABILITY_ERROR,
  GET_AVAILABILITY_BEGIN,
  GET_AVAILABILITY_ERROR,
  GET_AVAILABILITY_SUCCESS,
  UPDATE_AVAILABILITY_BEGIN,
  UPDATE_AVAILABILITY_SUCCESS,
  UPDATE_AVAILABILITY_ERROR,
  DELETE_AVAILABILITY_BEGIN,
  DELETE_AVAILABILITY_SUCCESS,
  DELETE_AVAILABILITY_ERROR,
} from "./constants";

const initState = {
  loading: false,
  error: null,
};

const availabilityReducer = (state = initState, action) => {
  const { type, data, error } = action;
  switch (type) {
    case ADD_AVAILABILITY_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case ADD_AVAILABILITY_SUCCESS:
      return {
        ...state,
        loading: false,
        list: [...state.list, data],
      };
    case ADD_AVAILABILITY_ERROR:
      return {
        ...state,
        error,
        loading: false,
      };
    case GET_AVAILABILITY_BEGIN:
      return {
        ...state,
        loading: true,
        availabilityData: "",
      };
    case GET_AVAILABILITY_SUCCESS:
      return {
        ...state,
        loading: false,
        availabilityData: data,
      };
    case GET_AVAILABILITY_ERROR:
      return {
        ...state,
        error,
        loading: false,
      };
    case UPDATE_AVAILABILITY_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_AVAILABILITY_SUCCESS:
      return {
        ...state,
        loading: false,
        availabilityData: data
      };
    case UPDATE_AVAILABILITY_ERROR:
      return {
        ...state,
        error,
        loading: false,
      };
    case DELETE_AVAILABILITY_BEGIN:  //TODO: confirm about delete
      return {
        ...state,
        loading: true,
      };
    case DELETE_AVAILABILITY_SUCCESS:
      return {
        ...state,
        loading: false,
        list: state.list.filter((ele) => ele.id !== data.id),
      };
    case DELETE_AVAILABILITY_ERROR:
      return {
        ...state,
        error,
        loading: false,
      };
    default:
      return state;
  }
};

export default availabilityReducer;
