import { useState, useEffect } from 'react';

export default function Radio_input(props) {
  let data = props.config;
  let [visibility, set_visibility] = useState(true);

  useEffect(() => {
    if (data.child) {
      let foi = Object.keys(props.all_fields).filter((field) => {
        if (props.all_fields[field].label.trim() == data.parent?.label.trim()) {
          return props.all_fields[field];
        }
      });
      if (
        props.current_data[props.all_fields[foi]?.ref_id] == data.parent?.option
      ) {
        set_visibility(true);
      } else {
        if (props.current_data[data.ref_id] != null || data.defaultValue != null){
          props.record_change(null, data.ref_id);
          data.defaultValue=null;
        }
        set_visibility(false);
      }
    }
  }, [props.current_data]);
  return (
    <>
      {visibility ? (
        <div className="border-b pl-5 pb-5">
          <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-gray-200 ">
            <div>
              <div
                className="text-base font-medium text-gray-900 sm:text-sm sm:text-gray-700"
                id="label-email"
              >
                {data.label}{' '}
              </div>
            </div>
            <div className="mt-4 sm:col-span-2 sm:mt-0">
              <div className="max-w-lg space-y-4">
                {Object.keys(data.options).map((val) => {
                  return (
                    <div className="flex items-center">
                      <input
                        name="radio"
                        type="radio"
                        defaultChecked={data.defaultValue}
                        onChange={(e) => {
                          props.record_change(e.target.value, data.ref_id);
                        }}
                        value={data.options[val]}
                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                      />

                      <label className="ml-3 block text-sm font-medium text-gray-700">
                        {data.options[val]}
                      </label>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>{' '}
        </div>
      ) : (
        ''
      )}

      {/*     
      <div className="mt-5 flex justify-left">
        <div className="mb-3 xl:w-96">
          <label
            for="exampleFormControlInput1"
            className="font-bold form-label inline-block mb-2 text-gray-700"
          >
            {data.label}
          </label>
          {Object.keys(data.options).map((val) => {
            {
              console.log(data.options[val]);
            }
            return (
              <div class="flex mt-2 items-center pl-4 rounded border border-gray-200 dark:border-gray-700">
                <input
                  id="bordered-radio-1"
                  type="radio"
                  value={data.options[val]}
                  name="bordered-radio"
                  class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                />
                <label
                  for="bordered-radio-1"
                  class="py-4 ml-2 w-full text-sm px-5 font-medium text-black-900"
                >
                  {data.options[val]}
                </label>
              </div>
            );
          })}
        </div>
      </div> */}
    </>
  );
}
