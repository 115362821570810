import {
    GET_ALL_MEDICINE_BEGIN,
    GET_ALL_MEDICINE_SUCCESS,
    GET_ALL_MEDICINE_ERROR,
    ADD_MEDICINE_BEGIN,
    ADD_MEDICINE_SUCCESS,
    ADD_MEDICINE_ERROR,
    GET_MEDICINE_BEGIN,
    GET_MEDICINE_ERROR,
    GET_MEDICINE_SUCCESS,
    UPDATE_MEDICINE_BEGIN,
    UPDATE_MEDICINE_SUCCESS,
    UPDATE_MEDICINE_ERROR,
    DELETE_MEDICINE_BEGIN,
    DELETE_MEDICINE_SUCCESS,
    DELETE_MEDICINE_ERROR,
  } from "./constants";
  
  const initState = {
    list: [],
    loading: false,
    error: null,
  };
  
  const MedicineReducer = (state = initState, action) => {
    const { type, data, error } = action;
    switch (type) {
      case GET_ALL_MEDICINE_BEGIN:
        return {
          ...state,
          loading: true,
        };
      case GET_ALL_MEDICINE_SUCCESS:
        return {
          ...state,
          list: data,
          loading: false,
        };
      case GET_ALL_MEDICINE_ERROR:
        return {
          ...state,
          error,
          loading: false,
        };
      case ADD_MEDICINE_BEGIN:
        return {
          ...state,
          loading: true,
        };
      case ADD_MEDICINE_SUCCESS:
        return {
          ...state,
          loading: false,
          list: [...state.list, data],
        };
      case ADD_MEDICINE_ERROR:
        return {
          ...state,
          error,
          loading: false,
        };
      case GET_MEDICINE_BEGIN:
        return {
          ...state,
          loading: true,
          MedicineData: "",
        };
      case GET_MEDICINE_SUCCESS:
        return {
          ...state,
          loading: false,
          MedicineData: data,
        };
      case GET_MEDICINE_ERROR:
        return {
          ...state,
          error,
          loading: false,
        };
      case UPDATE_MEDICINE_BEGIN:
        return {
          ...state,
          loading: true,
        };
      case UPDATE_MEDICINE_SUCCESS:
        return {
          ...state,
          loading: false,
          list: state.list.map((ele) => {
            if (ele.id === data.id) {
              return { ...data };
            } else return ele;
          }),
        };
      case UPDATE_MEDICINE_ERROR:
        return {
          ...state,
          error,
          loading: false,
        };
      case DELETE_MEDICINE_BEGIN:
        return {
          ...state,
          loading: true,
        };
      case DELETE_MEDICINE_SUCCESS:
        return {
          ...state,
          loading: false,
          list: state.list.filter((ele) => ele.id !== data.id),
        };
      case DELETE_MEDICINE_ERROR:
        return {
          ...state,
          error,
          loading: false,
        };
      default:
        return state;
    }
  };
  
  export default MedicineReducer;
  