import React, { useEffect, useState } from "react";
import Table from '../../../components/tables/table';
import {  program_session_columns } from '../../../components/tables/tableheader';
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { FallingLinesLoader } from "../../../components/spinners/Spinner";
import Breadcrumb from "../../../components/Breadcrumb";
import ConfirmationModal from "../../../components/ConfirmationModal";
import { getAllProgramSessions, deleteProgramSession } from "../../../redux/ProgramSessionMaster/actions";

const ProgramSession = () => {
  const pages = [{ title: "Program Session", href: "/program-session" }];
  const [confirmationModal, setConfirmationModal] = useState({
    status: false,
    id: null,
  });

  const dispatch = useDispatch();
  const { loading, list: sessionList } = useSelector(
    (state) => state.programSession
  );

  useEffect(() => {
    document.title = "New Horizon | Program Session Master";
    dispatch(getAllProgramSessions());
  }, []);
  const onDeleteOpen = (id) => {
    setConfirmationModal({ id, status: true });
  };

  const onDeleteProgramSession = (id) => {
    dispatch(deleteProgramSession(id));
    setConfirmationModal({ id:null, status: false });
  };
  return (
    <div>
    <Breadcrumb pages={pages} />
    <ConfirmationModal
      open={confirmationModal.status}
      setOpen={setConfirmationModal}
      onDelete={() => onDeleteProgramSession(confirmationModal.id)}
    />
    <div className="sm:flex sm:items-center">
      <div className="sm:flex-auto">
        <h1 className="text-xl font-semibold text-gray-900">Program Session Master</h1>
        
      </div>
      <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
      
        <Link
          to="/add-program-session"
          type="button"
          className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
        >
          Add Program Session
        </Link>
      </div>
    </div>
    {loading ? (
      <FallingLinesLoader />
    ) : (
    <Table
          columns={program_session_columns({ onDeleteOpen })}
          data={sessionList}
        />
        )} 
  </div>
  )
}

export default ProgramSession
