/* eslint-disable */
import axios from 'axios'
import { getHeaders } from '../auth/actions'
import { toast } from 'react-toast'
import { 
    GET_SHOW_AVAILABILITY_BEGIN,
    GET_SHOW_AVAILABILITY_ERROR,
    GET_SHOW_AVAILABILITY_SUCCESS,
      
 } from './constants'

const API = process.env.REACT_APP_API_URL
export const showAvailabilityId = (id) => async(dispatch) => {
    try {
      dispatch({
        type: GET_SHOW_AVAILABILITY_BEGIN
      })
      const headers = dispatch(getHeaders());
      const {data} = await axios.get(`${API}/api/schedule-master/availibility/doctor/${id}`, { headers })
      console.log(data.data)
      dispatch({
        type: GET_SHOW_AVAILABILITY_SUCCESS, 
        data: data.data
      })
    }
    catch (error) {
      console.log(error.response)
      const msg = error.response.data.message;
      toast.error(msg)
      dispatch({
        type: GET_SHOW_AVAILABILITY_ERROR,
        error: msg
      })
    }
  }