/* eslint-disable */
import {
  GET_ALL_PATIENTS_BEGIN,
  GET_ALL_PATIENTS_SUCCESS,
  GET_ALL_PATIENTS_ERROR,
  ADD_PATIENT_BEGIN,
  ADD_PATIENT_SUCCESS,
  ADD_PATIENT_ERROR,
  GET_PATIENT_BEGIN,
  GET_PATIENT_ERROR,
  GET_PATIENT_SUCCESS,
  UPDATE_PATIENT_BEGIN,
  UPDATE_PATIENT_SUCCESS,
  UPDATE_PATIENT_ERROR,
  DELETE_PATIENT_BEGIN,
  DELETE_PATIENT_SUCCESS,
  DELETE_PATIENT_ERROR,
} from "./constants";

const initState = {
  list: [],
  loading: false,
  error: null,
};

const doctorReducer = (state = initState, action) => {
  const { type, data, error } = action;
  switch (type) {
    case GET_ALL_PATIENTS_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case GET_ALL_PATIENTS_SUCCESS:
      return {
        ...state,
        list: data,
        loading: false,
      };
    case GET_ALL_PATIENTS_ERROR:
      return {
        ...state,
        error,
        loading: false,
      };
    case ADD_PATIENT_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case ADD_PATIENT_SUCCESS:
      return {
        ...state,
        loading: false,
        list: [...state.list, data],
      };
    case ADD_PATIENT_ERROR:
      return {
        ...state,
        error,
        loading: false,
      };
    case GET_PATIENT_BEGIN:
      return {
        ...state,
        loading: true,
        patientData: "",
      };
    case GET_PATIENT_SUCCESS:
      return {
        ...state,
        loading: false,
        patientData: data,
      };
    case GET_PATIENT_ERROR:
      return {
        ...state,
        error,
        loading: false,
      };
    case UPDATE_PATIENT_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_PATIENT_SUCCESS:
      return {
        ...state,
        loading: false,
        list: state.list.map((ele) => {
          if (ele.id === data.id) {
            return { ...data };
          } else return ele;
        }),
      };
    case UPDATE_PATIENT_ERROR:
      return {
        ...state,
        error,
        loading: false,
      };
    case DELETE_PATIENT_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case DELETE_PATIENT_SUCCESS:
      return {
        ...state,
        loading: false,
        list: state.list.filter((ele) => ele.id !== data.id),
      };
    case DELETE_PATIENT_ERROR:
      return {
        ...state,
        error,
        loading: false,
      };
    default:
      return state;
  }
};

export default doctorReducer;
