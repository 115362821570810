import {
    GET_SHOW_AVAILABILITY_BEGIN,
  GET_SHOW_AVAILABILITY_ERROR,
  GET_SHOW_AVAILABILITY_SUCCESS,
    
  } from "./constants";
  
  const initState = {
    list: [],
    loading: false,
    error: null,
  };
  
  const showThAvailabilityReducer = (state = initState, action) => {
    const { type, data, error } = action;
    switch (type) {
        case GET_SHOW_AVAILABILITY_BEGIN:
            return {
                ...state,
                loading: true,
            };
          case GET_SHOW_AVAILABILITY_SUCCESS:
            return {
                ...state,
                list: data,
                loading: false,
            };
          case GET_SHOW_AVAILABILITY_ERROR:
            return {
                ...state,
                error,
                loading: false,
            };
        default:
            return state;
        }
      };
      
      export default showThAvailabilityReducer;
      