import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { show_availability_columns } from "../../../../../components/tables/tableheader";
import Table from "../../../../../components/tables/table";
import { FallingLinesLoader } from "../../../../../components/spinners/Spinner";
import { showAvailabilityId } from "../../../../../redux/ShowAvailability/actions";
const ShowAvailability = () => {
    const { id: doctorId } = useParams();
    const dispatch = useDispatch();
    const { loading, list: AvailList } = useSelector(
      (state) => state.showAvailability
    );
    useEffect(() => {
        document.title = "New Horizon | Locations";
        dispatch(showAvailabilityId(doctorId));
      }, [doctorId]);
  return (
    <div className="px-4 sm:px-6 lg:px-8">
   
    {/* <Breadcrumb pages={pages} /> */}
    <div className="sm:flex sm:items-center mb-5">
      {/* <div className="sm:flex-auto">
        <h1 className="text-xl font-semibold text-gray-900">Locations</h1>
      </div> */}
      <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
       
      </div>
    </div>
      {loading ? (
        <FallingLinesLoader />
      ) : (
        <Table
        columns={ show_availability_columns()}
        data={AvailList}
        // onEditClose={cleanModalData}
      />
      )}
    </div>
  )
}

export default ShowAvailability
