import { Formik } from "formik";
import React, { useState } from "react";
import RcCalendar from "react-calendar";
import moment from "moment";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import axios from "axios";
import { getHeaders } from "../../../redux/auth/actions";
import { toast } from "react-hot-toast";
import Select from "react-select";
import { useEffect } from "react";
import { getAllLocations } from "../../../redux/location/actions";
import { getAllDepartments } from "../../../redux/department/actions";
import { getAllTherapists } from "../../../redux/therapist/therpist/actions";
import * as Yup from "yup";
import Modal from "../../../components/Modal";
import Table from "../../../components/tables/table";
import { therapist_appointment_log_colums } from "../../../components/tables/tableheader";
import { FallingLinesLoader } from "../../../components/spinners/Spinner";
import { AccordionData } from "../Patients/accordian";
import { MinusCircleIcon, PlusIcon } from "@heroicons/react/24/outline";
import app_api from "../../../utilities/api";
import { useAsyncDebounce } from "react-table";
const api = process.env.REACT_APP_API_URL;
const initialModalState = {
  type: "",
  state: false,
  index: null,
  edit_id: "",
  data: {
    name: "",
    date: "",
  },
};
const ByEv = () => {
  // States an Variables
  const { loading, list: locationList } = useSelector(
    (state) => state.location
  );
  const { loading: dloading, list: departmentList } = useSelector(
    (state) => state.department
  );
  const { loading: tloading, list: therapistList } = useSelector(
    (state) => state.Therapist
  );
  const { list: patientsList, loading: ptloading } = useSelector(
    (state) => state.patient
  );
  const [evMaster, setEvMaster] = useState({
    loading: true,
    data: [],
  });
  const [cost, setCost] = useState({
    loading: true,
    cost: 0,
    time: 0,
  });
  const [getCostData, setGetCostData] = useState({
    evaluation_master_id: null,
    department_id: null,
    location_id: null,
    therapist: null,
    date: null,
    time_split_duration: cost.time,
  });
  const initialState = {
    patient_id: "",
    patient: "",

    evaluations: [
      {
        location_id: "",
        department_id: "",
        therapist_id: "",
        therapist: "",
        session_type: "",
        evaluation_id: "",
        date: "",
        price: "",
        time_split_duration: "",
        time: "",
      },
    ],
    payment_type: "",
    advance_payment_amount: "",
  };

  const [allPatients, setAllPatients] = useState({
    data: [],
    loading: true,
    error: null,
    query: "",
  });

  useEffect(() => {
    app_api.get(`/api/patient/search?limit=20${allPatients.query ? `&query=${allPatients.query}` : ""}`).then((res) => {
      setAllPatients(prev => ({ ...prev, data: res.data.data, loading: false }));
    });
  }, [allPatients.query]);

  const onFilterChange = useAsyncDebounce((value) => {
    setAllPatients(prev => ({ ...prev, query: value }));
  }, 200);

  const [formState, setFormState] = useState(initialState);
  const [dates, setDates] = useState({
    loading: false,
  });
  const [timeSlots, setTimeSlots] = useState({
    loading: false,
  });
  // Methods and Handlers
  const getAllEvMasters = async (patient_id) => {
    const headers = await dispatch(getHeaders());
    try {
      const res = await axios.get(
        `${api}/api/evaluation-appointment/recommended-evaluation/${patient_id}`,
        {
          headers: headers,
        }
      );
      const { data, status } = res;
      if (status === 200) {
        setEvMaster((prev) => ({
          ...prev,
          data: data.data.length ? data.data?.[0] : [],
          loading: false,
        }));
      } else {
        setEvMaster((prev) => ({
          ...prev,
          loading: false,
        }));
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const getCost = async (value) => {
    const headers = await dispatch(getHeaders());
    if (
      value.evaluation_master_id &&
      value.department_id &&
      value.location_id
    ) {
      try {
        const res = await axios.post(
          `${api}/api/evaluation-appointment/get-cost`,
          {
            evaluation_master_id: Number(value.evaluation_master_id),
            department_id: Number(value.department_id),
            location_id: Number(value.location_id),
          },
          {
            headers: headers,
          }
        );
        return {
          time: res.data.data.time,
          cost: res.data.data.cost,
        };
      } catch (error) {
        toast.error(error.response.data.message);
        return null;
      }
    }
  };

  const getAllTimeSlots = async (payload, index) => {
    const headers = await dispatch(getHeaders());
    try {
      const res = await axios.post(
        `${api}/api/evaluation-appointment/search/date/time-slots`,
        {
          therapist_id: Number(payload.therapist_id),
          date: moment(payload.date).format("YYYY-MM-DD"),
          time_split_duration: Number(payload.time_split_duration),
        },
        {
          headers: headers,
        }
      );
      const { data, status } = res;
      if (status === 200) {
        setTimeSlots((prev) => ({
          ...prev,
          [index]: data.data,
          loading: false,
        }));
      }
    } catch (error) {
      toast.error(error.message);
    }
  };
  const getAllDates = async (id, index) => {
    const headers = await dispatch(getHeaders());
    setDates((prev) => ({ ...prev, loading: true }));
    try {
      const res = await axios.get(
        `${api}/api/evaluation-appointment/search/dates/${id}`,
        {
          headers: headers,
        }
      );
      const { data, status } = res;
      if (status === 200) {
        setDates((prev) => ({
          ...prev,
          [index]: data.data,
          loading: false,
        }));
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const AddAppointment = async (payload) => {
    const headers = await dispatch(getHeaders());
    console.log(payload);
    try {
      const res = await axios.post(
        `${api}/api/evaluation-appointment`,
        payload,
        {
          headers: headers,
        }
      );
      const { data, status } = res;
      if (data) {
        toast.success("Appointment Booked Successfully");
        window.location.reload();
        setFormState(initialState);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };
  //Hooks
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllLocations());
    dispatch(getAllDepartments());
    dispatch(getAllTherapists());
  }, []);

  useEffect(() => {
    if (
      getCostData.evaluation_master_id &&
      getCostData.department_id &&
      getCostData.location_id
    ) {
      getCost();
    }
  }, [
    getCostData.department_id,
    getCostData.location_id,
    getCostData.evaluation_master_id,
  ]);
  // useEffect(() => {
  //   if (getCostData.department_id) {
  //     getAllEvMasters(getCostData.department_id);
  //   }
  // }, [getCostData.department_id]);
  const [modal, setModal] = useState(initialModalState);

  const RenderEvaluation = () => {
    const { type, state, edit_id } = modal;
    const [data, setData] = useState({
      loading: true,
      data: [],
    });
    useEffect(() => {
      if (edit_id) {
        getAllEvMasters();
      }
    }, [edit_id]);
    const getAllEvMasters = async () => {
      const headers = await dispatch(getHeaders());
      setData((prev) => ({ ...prev, loading: true }));
      try {
        const res = await axios.get(
          `${api}/api/evaluation-appointment/evaluation-patient/${edit_id}`,
          {
            headers: headers,
          }
        );
        const { data, status } = res;
        if (status === 200) {
          setData((prev) => ({
            ...prev,
            data: data.data,
            loading: false,
          }));
        }
      } catch (error) {
        toast.error(error.message);
      }
    };
    return (
      <Modal
        open={state}
        title="Evaluation"
        setOpen={() =>
          setModal((prev) => ({
            ...prev,
            state: false,
            data: {},
            edit_id: "",
            type: "",
          }))
        }
        size="max-w-5xl"
      >
        {data.loading ? (
          <FallingLinesLoader />
        ) : (
          <Table
            columns={therapist_appointment_log_colums()}
            data={data.data}
          />
        )}
      </Modal>
    );
  };
  const [open, setOpen] = useState();
  return (
    <div>
      <RenderEvaluation />
      <Formik
        initialValues={formState}
        enableReinitialize={true}
        // validationSchema={Yup.object({
        //   location_id: Yup.string().required("Please Select a Location"),
        //   department_id: Yup.string().required("Please Select a Department"),
        //   therapist_id: Yup.string().required("Please Select a Therapist"),
        //   evaluation_id: Yup.string().required("Please Select a Evaluation"),
        //   patient_id: Yup.string().required("Please Select a Patient"),
        //   time: Yup.string().required("Please Select a Time"),
        // })}
        onSubmit={(values, { setSubmitting }, actions) => {
          // console.log(
          //   moment(values.time.split(",")[0], "HH:mm")
          //     .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")
          //     .toString()
          // );
          const payload = {
            patient_id: Number(values.patient_id),
            evaluations: values.evaluations.map((ev) => ({
              location_id: Number(ev.location_id),
              department_id: Number(ev.department_id),
              evaluation_id: Number(ev.evaluation_id),
              therapist_id: Number(ev.therapist_id),
              date: moment(ev.date).format(),
              start_time: moment(ev.time.split(",")[0], "HH:mm")
                .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")
                .toString(),
              end_time: moment(ev.time.split(",")[1], "HH:mm")
                .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")
                .toString(),

              price: Number(ev.price),
              status: "TENTATIVE",
            })),
            advance_payment_amount: Number(
              values.advance_payment_amount || null
            ),

            payment_type:
              values.payment_type !== "FULL"
                ? values.advance_payment_amount ===
                  values.evaluations.reduce((acc, ev) => acc + ev.price, 0)
                  ? "FULL"
                  : "ADVANCE"
                : "FULL",
            total_amount: values.evaluations.reduce(
              (acc, ev) => acc + ev.price,
              0
            ),
          };

          AddAppointment(payload);
          setSubmitting(false);
          actions.resetForm();
        }}
      >
        {({
          values,
          setValues,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => {
          return (
            <form
              onSubmit={handleSubmit}
              className="space-y-8 divide-y divide-gray-200"
            >
              <div className="space-y-6 sm:space-y-5">
                {RenderEvaluation()}
                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                  <label
                    htmlFor="typeOfAppointment"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Patient
                  </label>
                  <div className="mt-1 flex items-center  gap-3 sm:col-span-2 sm:mt-0">
                    <Select
                      options={allPatients.data.map((p) => ({
                        value: p.id,
                        label: `${p?.firstName.trim()} ${p?.lastName.trim()} - ${p?.patient_id
                          }`,
                      }))}
                      onInputChange={(e) => { onFilterChange(e) }}
                      value={values.patient}
                      onChange={(e) => {
                        const patient = patientsList.find(
                          (p) => p.id === Number(e.value)
                        );

                        setValues({
                          ...values,
                          patient: e,
                          patient_id: Number(e.value),
                          location_id: patient?.location_id,
                        });
                        setGetCostData((prev) => ({
                          ...prev,
                          location_id: patient?.location_id,
                        }));
                        getAllEvMasters(e.value);
                      }}
                      className="block w-full max-w-lg rounded-md border-[1px] disabled:opacity-5 border-gray-300 shadow-sm focus:border-[1px] focus:border-indigo-500 sm:max-w-xs sm:text-sm"
                      classNamePrefix="select"
                    />
                    <button
                      type="button"
                      onClick={() => {
                        setModal((prev) => ({
                          ...prev,
                          state: true,
                          edit_id: values.patient_id,
                        }));
                      }}
                      disabled={!values.patient_id}
                      className="items-center rounded-full border border-transparent bg-indigo-600 px-3 py-2 text-sm font-medium leading-4 text-white hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    >
                      i
                    </button>
                  </div>
                </div>
                {values.evaluations.map((eva, index) => (
                  <div
                    key={index}
                    className="flex w-full flex-row items-center gap-3"
                  >
                    <AccordionData
                      key={index}
                      name={`Evaluation ${index + 1}`}
                      label={
                        eva.department_id &&
                        eva.evaluation_id &&
                        `${departmentList.find((d) => d.id === eva.department_id)
                          ?.name
                        } - ${evMaster?.data?.find(
                          (e) => e.evaluation_master_id === eva.evaluation_id
                        )?.EvaluationMaster?.name
                        }`
                      }
                      open={open}
                      setOpen={setOpen}
                      title={
                        <h1 className="text-center text-sm font-semibold text-gray-500">
                          Evaluation {index + 1}
                        </h1>
                      }
                      content={
                        <div className="p-4 flex flex-col gap-2">
                          <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                            <label
                              htmlFor="typeOfAppointment"
                              className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                            >
                              Session Type
                            </label>
                            <div className="mt-1 sm:col-span-2 sm:mt-0">
                              <select
                                id="session_type"
                                name="session_type"
                                disabled
                                // value={eva.session_type}
                                value={"ONLINE"}
                                onChange={(e) => {
                                  setValues((prev) => ({
                                    ...prev,
                                    evaluations: prev.evaluations.map((ev, i) =>
                                      i === index
                                        ? {
                                          ...ev,
                                          session_type: e.target.value,
                                        }
                                        : ev
                                    ),
                                  }));
                                }}
                                className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-indigo-500 sm:max-w-xs sm:text-sm"
                              >
                                <option value="">Select a Session type</option>
                                <option value="ONLINE">ONLINE</option>
                                <option value="OFFLINE">OFFLINE</option>
                              </select>
                              {errors.department_id && touched.department_id ? (
                                <p className="text-red-600 text-sm">
                                  {errors.department_id}
                                </p>
                              ) : null}
                            </div>
                          </div>
                          <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                            <label
                              htmlFor="typeOfAppointment"
                              className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                            >
                              Location
                            </label>
                            <div className="mt-1 sm:col-span-2 sm:mt-0">
                              <select
                                id="location_id"
                                name="location_id"
                                value={eva.location_id}
                                onChange={async (e) => {
                                  setValues((prev) => ({
                                    ...prev,
                                    evaluations: prev.evaluations.map((ev, i) =>
                                      i === index
                                        ? {
                                          ...ev,
                                          location_id: Number(e.target.value),
                                          department_id: "",
                                          evaluation_id: "",
                                          price: "",
                                        }
                                        : ev
                                    ),
                                  }));
                                }}
                                className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-indigo-500 sm:max-w-xs sm:text-sm"
                              >
                                <option value="">Select a Location</option>
                                {locationList
                                  .filter((loc) => loc.name.trim() !== "E-nable")
                                  .map((sin) => (
                                    <option value={sin.id}>{sin.name}</option>
                                  ))}
                              </select>
                              {errors.location_id && touched.location_id ? (
                                <p className="text-red-600 text-sm">
                                  {errors.location_id}
                                </p>
                              ) : null}
                            </div>
                          </div>
                          <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                            <label
                              htmlFor="typeOfAppointment"
                              className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                            >
                              Department
                            </label>
                            <div className="mt-1 sm:col-span-2 sm:mt-0">
                              <select
                                id="department_id"
                                name="department_id"
                                value={eva.department_id}
                                onChange={(e) => {
                                  setValues((prev) => ({
                                    ...prev,
                                    evaluations: prev.evaluations.map((ev, i) =>
                                      i === index
                                        ? {
                                          ...ev,
                                          department_id: Number(
                                            e.target.value
                                          ),
                                          evaluation_id: "",
                                          price: "",
                                        }
                                        : ev
                                    ),
                                  }));
                                }}
                                className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-indigo-500 sm:max-w-xs sm:text-sm"
                              >
                                <option value="">Select a Department</option>

                                {departmentList
                                  .filter((loc) => loc.name.trim() !== "E-nable")
                                  .map((sin) => (
                                    <option value={sin.id}>{sin.name}</option>
                                  ))}
                              </select>
                              {errors.department_id && touched.department_id ? (
                                <p className="text-red-600 text-sm">
                                  {errors.department_id}
                                </p>
                              ) : null}
                            </div>
                          </div>
                          <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                            <label
                              htmlFor="typeOfAppointment"
                              className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                            >
                              Evaluation
                            </label>
                            <div className="mt-1 sm:col-span-2 sm:mt-0">
                              <select
                                id="evaluation_id"
                                name="evaluation_id"
                                value={eva.evaluation_id}
                                onChange={async (e) => {
                                  setValues((prev) => ({
                                    ...prev,
                                    evaluations: prev.evaluations.map((ev, i) =>
                                      i === index
                                        ? {
                                          ...ev,
                                          evaluation_id: Number(
                                            e.target.value
                                          ),
                                        }
                                        : ev
                                    ),
                                  }));

                                  const { cost, time } = await getCost({
                                    evaluation_master_id: Number(
                                      e.target.value
                                    ),
                                    department_id: eva.department_id,
                                    location_id: eva.location_id,
                                  });

                                  if (cost && time) {
                                    setValues((prev) => ({
                                      ...prev,
                                      evaluations: prev.evaluations.map(
                                        (ev, i) =>
                                          i === index
                                            ? {
                                              ...ev,
                                              price: cost,
                                              time_split_duration: time,
                                            }
                                            : ev
                                      ),
                                    }));
                                  }
                                }}
                                onBlur={handleBlur}
                                className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-indigo-500 sm:max-w-xs sm:text-sm"
                              >
                                <option value="">Select a Evaluation</option>
                                {evMaster?.data &&
                                  evMaster?.data
                                    .filter((item) =>
                                      item.EvaluationDepartmentMaster.some(
                                        (subItem) =>
                                          subItem.department_id ===
                                          eva.department_id
                                      )
                                    )
                                    ?.map((sin) => (
                                      <option value={sin.id}>
                                        {sin?.name}
                                      </option>
                                    ))}
                              </select>
                            </div>
                          </div>
                          <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                            <label
                              htmlFor="typeOfAppointment"
                              className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                            >
                              Therapist
                            </label>
                            <div className="mt-1 sm:col-span-2 sm:mt-0">
                              <Select
                                options={therapistList
                                  .filter((a) => {
                                    if (
                                      values.evaluations?.[index]
                                        .department_id !== 0
                                    ) {
                                      return a.Therapist_department.some(
                                        (department) =>
                                          Number(department.department_id) ===
                                          Number(
                                            values.evaluations?.[index]
                                              .department_id
                                          )
                                      );
                                    }
                                    return true;
                                  })
                                  .map((p) => ({
                                    value: p.id,
                                    label: `${p.first_name} ${p.last_name}`,
                                  }))}
                                value={eva.therapist}
                                onChange={(e) => {
                                  setValues((prev) => ({
                                    ...prev,
                                    evaluations: prev.evaluations.map((ev, i) =>
                                      i === index
                                        ? {
                                          ...ev,
                                          therapist_id: Number(e.value),
                                          therapist: e,
                                        }
                                        : ev
                                    ),
                                  }));
                                  getAllDates(e.value, index);
                                }}
                                className="block w-full max-w-lg rounded-md border-[1px] disabled:opacity-5 border-gray-300 shadow-sm focus:border-[1px] focus:border-indigo-500 sm:max-w-xs sm:text-sm"
                                classNamePrefix="select"
                              />
                              {errors.therapist_id && touched.therapist_id ? (
                                <p className="text-red-600 text-sm">
                                  {errors.therapist_id}
                                </p>
                              ) : null}
                            </div>
                          </div>

                          <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 mb-5  sm:pt-5">
                            <label
                              htmlFor="dob"
                              className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                            >
                              Date
                            </label>
                            <div className="mt-1 w-full">
                              <RcCalendar
                                name="date"
                                className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-indigo-500 sm:max-w-xs sm:text-sm"
                                minDetail="month"
                                tileDisabled={({
                                  activeStartDate,
                                  date,
                                  view,
                                }) => {
                                  if (dates?.[index]) {
                                    if (values.book_type) return false;
                                    return !dates?.[index].includes(
                                      moment(date)
                                        .format("YYYY-MM-DD")
                                        .toString()
                                    );
                                  } else {
                                    return ![].includes(
                                      moment(date)
                                        .format("YYYY-MM-DD")
                                        .toString()
                                    );
                                  }
                                }}
                                value={eva.date}
                                disabled={!dates?.[index]}
                                onChange={(value, e) => {
                                  setValues((prev) => ({
                                    ...prev,
                                    evaluations: prev.evaluations.map((ev, i) =>
                                      i === index
                                        ? {
                                          ...ev,
                                          date: value,
                                          time: "",
                                        }
                                        : ev
                                    ),
                                  }));

                                  getAllTimeSlots(
                                    {
                                      therapist_id: eva.therapist_id,
                                      date: value,
                                      time_split_duration: 45,
                                    },
                                    index
                                  );
                                }}
                              />
                            </div>
                          </div>
                          <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                            <label
                              htmlFor="typeOfAppointment"
                              className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                            >
                              Time
                            </label>
                            <div className="mt-1 sm:col-span-2 sm:mt-0">
                              <select
                                id="time"
                                name="time"
                                value={eva.time}
                                onChange={(e) => {
                                  setValues((prev) => ({
                                    ...prev,
                                    evaluations: prev.evaluations.map((ev, i) =>
                                      i === index
                                        ? {
                                          ...ev,
                                          time: e.target.value,
                                        }
                                        : ev
                                    ),
                                  }));
                                }}
                                onBlur={handleBlur}
                                className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-indigo-500 sm:max-w-xs sm:text-sm"
                              >
                                <option value="">Select a Time</option>

                                {timeSlots?.[index] &&
                                  timeSlots?.[index]?.map((sin) => (
                                    <option
                                      value={`${sin.start_time},${sin.end_time}`}
                                    >
                                      {sin.start_time + " - " + sin.end_time}
                                    </option>
                                  ))}
                              </select>
                              {errors.time && touched.time ? (
                                <p className="text-red-600 text-sm">
                                  {errors.time}
                                </p>
                              ) : null}
                            </div>
                          </div>
                          <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                            <label
                              htmlFor="typeOfAppointment"
                              className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                            >
                              Sub Total
                            </label>
                            <div className="mt-1 sm:col-span-2 sm:mt-0">
                              <input
                                id="typeOfAppointment"
                                name="typeOfAppointment"
                                value={eva.price}
                                disabled
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-indigo-500 sm:max-w-xs sm:text-sm"
                              />
                            </div>
                          </div>
                        </div>
                      }
                    />
                    <button
                      type="button"
                      onClick={() => {
                        setValues({
                          ...values,
                          evaluations: values.evaluations.filter(
                            (ev, i) => i !== index
                          ),
                        });
                      }}
                      className="
                      items-center
                      rounded-full
                      "
                    >
                      <MinusCircleIcon className="h-8 w-8 text-red-600" />
                    </button>
                  </div>
                ))}
                <div className="flex items-center justify-center w-full">
                  <button
                    type="button"
                    onClick={() => {
                      setValues({
                        ...values,
                        evaluations: [
                          ...values.evaluations,
                          {
                            location_id: "",
                            department_id: "",
                            therapist_id: "",
                            therapist: "",
                            evaluation_id: "",
                            date: "",
                            price: "",
                            time_split_duration: "",
                            time: "",
                            payment_type: "",
                          },
                        ],
                      });
                    }}
                    className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-indigo-600 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  >
                    <PlusIcon className="h-6 w-6 text-indigo-600" />
                  </button>
                </div>
              </div>
              <div className="sm:col-span-3 mt-8">
                <label className="block text-sm font-medium text-gray-700">
                  Type of payment
                </label>
                <div className="flex items-center">
                  <input
                    id="full-payment-type"
                    name="payment_type"
                    value="FULL"
                    type="radio"
                    onChange={(e) => {
                      setValues((prev) => ({
                        ...prev,
                        payment_type: e.target.value,
                      }));
                    }}
                    onBlur={handleBlur}
                    checked={values.payment_type === "FULL"}
                    className="my-3 h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                  />
                  <label
                    htmlFor="full-payment-type"
                    className="ml-3 my-3 block text-sm font-medium text-gray-700"
                  >
                    Full
                  </label>
                </div>
                <div className="flex items-center">
                  <input
                    id="advanced-payment-type"
                    name="payment_type"
                    value="ADVANCE"
                    type="radio"
                    onChange={(e) => {
                      setValues((prev) => ({
                        ...prev,
                        payment_type: e.target.value,
                      }));
                    }}
                    onBlur={handleBlur}
                    checked={values.payment_type === "ADVANCE"}
                    className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                  />
                  <label
                    htmlFor="advanced-payment-type"
                    className="ml-3 block text-sm font-medium text-gray-700"
                  >
                    Advance
                  </label>
                </div>
              </div>
              {values.payment_type === "ADVANCE" && (
                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                  <label
                    htmlFor="advance_payment_amount"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Advanced payment cost
                  </label>
                  <div className="mt-1">
                    <input
                      type="number"
                      name="advance_payment_amount"
                      value={values.advance_payment_amount}
                      max={
                        (values.evaluations.reduce(
                          (acc, ev) => acc + ev.price,
                          0
                        ) || 1) - 1
                      }
                      onChange={(e) => {
                        setValues((prev) => ({
                          ...prev,
                          advance_payment_amount: Number(e.target.value),
                        }));
                      }}
                      autoComplete="off"
                      onBlur={handleBlur}
                      className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-indigo-500 sm:max-w-xs sm:text-sm"
                    />
                  </div>
                </div>
              )}
              <div className="pt-5">
                <p>
                  Total cost (
                  {values.evaluations.length &&
                    values.evaluations?.map((ev, index) => {
                      const department =
                        departmentList?.find((d) => d.id === ev.department_id)
                          ?.name || "";
                      const evaluation =
                        evMaster?.[index]?.find(
                          (e) => e.evaluation_master_id === ev.evaluation_id
                        )?.EvaluationMaster?.name || "";

                      return `${department} - ${evaluation} ${values.evaluations.length - 1 === index ? "" : "+ "
                        }`;
                    })}
                  ) :
                  {"₹" +
                    values.evaluations.reduce((acc, ev) => acc + ev.price, 0)}
                </p>
                <div className="flex justify-end">
                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  >
                    Book
                  </button>
                </div>
              </div>
            </form>
          );
        }}
      </Formik>
    </div>
  );
};

export default ByEv;
