/* eslint-disable */
import {
  GET_ALL_DOCTORS_BEGIN,
  GET_ALL_DOCTORS_SUCCESS,
  GET_ALL_DOCTORS_ERROR,
  ADD_DOCTOR_BEGIN,
  ADD_DOCTOR_SUCCESS,
  ADD_DOCTOR_ERROR,
  GET_DOCTOR_BEGIN,
  GET_DOCTOR_ERROR,
  GET_DOCTOR_SUCCESS,
  UPDATE_DOCTOR_BEGIN,
  UPDATE_DOCTOR_SUCCESS,
  UPDATE_DOCTOR_ERROR,
  DELETE_DOCTOR_BEGIN,
  DELETE_DOCTOR_SUCCESS,
  DELETE_DOCTOR_ERROR,
} from "./constants";

const initState = {
  list: [],
  loading: false,
  error: null,
};

const doctorReducer = (state = initState, action) => {
  const { type, data, error } = action;
  switch (type) {
    case GET_ALL_DOCTORS_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case GET_ALL_DOCTORS_SUCCESS:
      return {
        ...state,
        list: data,
        loading: false,
      };
    case GET_ALL_DOCTORS_ERROR:
      return {
        ...state,
        error,
        loading: false,
      };
    case ADD_DOCTOR_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case ADD_DOCTOR_SUCCESS:
      return {
        ...state,
        loading:false,
        list: [...state.list, data],
      };
    case ADD_DOCTOR_ERROR:
      return {
        ...state,
        error,
        loading: false,
      };
    case GET_DOCTOR_BEGIN:
      return {
        ...state,
        loading: true,
        doctorData: "",
      };
    case GET_DOCTOR_SUCCESS:
      return {
        ...state,
        loading: false,
        doctorData: data,
      };
    case GET_DOCTOR_ERROR:
      return {
        ...state,
        error,
        loading: false,
      };
    case UPDATE_DOCTOR_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_DOCTOR_SUCCESS:
      return {
        ...state,
        loading: false,
        list: state.list.map((ele) => {
          if (ele.id === data.id) {
            return { ...data };
          } else return ele;
        }),
      };
    case UPDATE_DOCTOR_ERROR:
      return {
        ...state,
        error,
        loading: false,
      };
    case DELETE_DOCTOR_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case DELETE_DOCTOR_SUCCESS:
      return {
        ...state,
        loading: false,
        list: state.list.filter((ele) => ele.id !== data.id),
      };
    case DELETE_DOCTOR_ERROR:
      return {
        ...state,
        error,
        loading: false,
      };
    default:
      return state;
  }
};

export default doctorReducer;
