export const GET_ALL_PATIENT_RECOMMANDATION_BEGIN =
  "GET_ALL_PATIENT_RECOMMANDATION_BEGIN";
export const GET_ALL_PATIENT_RECOMMANDATION_SUCCESS =
  "GET_ALL_PATIENT_RECOMMANDATION_SUCCESS";
export const GET_ALL_PATIENT_RECOMMANDATION_ERROR =
  "GET_ALL_PATIENT_RECOMMANDATION_ERROR";

export const GET__PATIENT_RECOMMANDATION_WITH_ID_BEGIN =
  "GET__PATIENT_RECOMMANDATION_WITH_ID_BEGIN";
export const GET__PATIENT_RECOMMANDATION_WITH_ID_SUCCESS =
  "GET_ALL_PATIENT_RECOMMANDATION_SUCCESS";
export const GET__PATIENT_RECOMMANDATION_WITH_ID_ERROR =
  "GET__PATIENT_RECOMMANDATION_WITH_ID_ERROR";
export const CLEAR_PATIENT_RECOMMENDATION = "CLEAR_PATIENT_RECOMMENDATION";

export const GET_PATIENT_RECOMMENDATION_DEPARTMENTS_BEGIN =
  "GET_PATIENT_RECOMMENDATION_DEPARTMENTS_BEGIN";
export const GET_PATIENT_RECOMMENDATION_DEPARTMENTS_SUCCESS =
  "GET_PATIENT_RECOMMENDATION_DEPARTMENTS_SUCCESS";
export const GET_PATIENT_RECOMMENDATION_DEPARTMENTS_ERROR =
  "GET_PATIENT_RECOMMENDATION_DEPARTMENTS_ERROR";

export const CLEAR_PATIENT_RECOMMENDATION_DEPARTMENTS =
  "CLEAR_PATIENT_RECOMMENDATION_DEPARTMENTS";
export const CHANGE_ACTIVE_DEPARTMENT = "CHANGE_ACTIVE_DEPARTMENT";

// export const TOGGEL_FOLLOW_UP_BEGIN = "TOGGEL_FOLLOW_UP_BEGIN";
// export const TOGGEL_FOLLOW_UP_SUCCESS = "TOGGEL_FOLLOW_UP_SUCCESS";
// export const TOGGEL_FOLLOW_UP_ERROR = "TOGGEL_FOLLOW_UP_ERROR";
