export const GET_ALL_FORMULATION_BEGIN = "GET_ALL_FORMULATION_BEGIN"
export const GET_ALL_FORMULATION_SUCCESS = "GET_ALL_FORMULATION_SUCCESS"
export const GET_ALL_FORMULATION_ERROR = "GET_ALL_FORMULATION_ERROR"

export const ADD_FORMULATION_BEGIN = "ADD_FORMULATION_BEGIN"
export const ADD_FORMULATION_SUCCESS = "ADD_FORMULATION_SUCCESS"
export const ADD_FORMULATION_ERROR = "ADD_FORMULATION_ERROR"

export const GET_FORMULATION_BEGIN = "GET_FORMULATION_BEGIN"
export const GET_FORMULATION_SUCCESS = "GET_FORMULATION_SUCCESS"
export const GET_FORMULATION_ERROR = "GET_FORMULATION_ERROR"

export const UPDATE_FORMULATION_BEGIN = "UPDATE_FORMULATION_BEGIN"
export const UPDATE_FORMULATION_SUCCESS = "UPDATE_FORMULATION_SUCCESS"
export const UPDATE_FORMULATION_ERROR = "UPDATE_FORMULATION_ERROR"

export const DELETE_FORMULATION_BEGIN = "DELETE_FORMULATION_BEGIN"
export const DELETE_FORMULATION_SUCCESS = "DELETE_FORMULATION_SUCCESS"
export const DELETE_FORMULATION_ERROR = "DELETE_FORMULATION_ERROR"