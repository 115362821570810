import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { deleteLocation } from "../../../redux/location/actions";
import Pagination from "../../../components/Pagination";
import { FallingLinesLoader } from "../../../components/spinners/Spinner";
import Breadcrumb from "../../../components/Breadcrumb";
import ConfirmationModal from "../../../components/ConfirmationModal";

import Table from "../../../components/tables/table";
import {
  collection_data_colmn,
  therapist_enrollment_colums,
} from "../../../components/tables/tableheader";
import { getHeaders } from "../../../redux/auth/actions";
import axios from "axios";
import { toast } from "react-hot-toast";
import Modal from "../../../components/Modal";
const APi = process.env.REACT_APP_API_URL;
const TherapistEnrolment = () => {
  const [query, setQuery] = useState(200);
  const pages = [{ title: "Enrolment", href: "/therapist-enrolment" }];
  const [confirmationModal, setConfirmationModal] = useState({
    status: false,
    data: {},
    presetnt: false,
  });
  const [dconfirmationModal, setConfirmationModald] = useState({
    status: false,
    data: {},
  });
  const [enrolment, setEnrolment] = useState({
    loading: false,
    data: [],
  });
  const dispatch = useDispatch();

  useEffect(() => {
    getAllEnrolment(200);
    document.title = "New Horizon | Therapist Enrolment";
  }, []);

  const onMarkCompletedOpen = (data) => {
    setConfirmationModal({ data, status: true });
  };
  const onMarkDropoutOpen = (data) => {
    setConfirmationModald({ data, status: true });
  };

  async function onCompleted(payload) {
    const headers = dispatch(getHeaders());
    try {
      const res = await axios.put(
        `${APi}/api/enrollment/patient/enrollment-dropout/${payload.id}`,
        payload.body,
        {
          headers: headers,
        }
      );
      const { data } = res;
      if (data) {
        toast.success(data.message);
        setConfirmationModal({ data: {}, status: false });
        setConfirmationModald({ data: {}, status: false });
        getAllEnrolment(query || 200);
      }
    } catch (error) {
      toast.error(error.response.data.message);
    }
  }

  async function getAllEnrolment(limit) {
    setEnrolment({ ...enrolment, loading: true });
    const headers = dispatch(getHeaders());
    try {
      const res = await axios.get(`${APi}/api/enrollment?limit=${limit}`, {
        headers: headers,
      });
      const { data } = res;
      if (data) {
        setEnrolment({ ...enrolment, data: data.data, loading: false });
      }
    } catch (error) {
      toast.error(error.response.data.message);
    }
  }
  const initModa = { state: false, id: null };
  const [modal, setModal] = useState(initModa);
  const renderModal = () => {
    return (
      <Modal
        title="Collection Data"
        size="max-w-3xl"
        open={modal.state}
        setOpen={() => setModal(initModa)}
      >
        {enrolment.data.find((item) => item.id === modal.id)?.appointment
          ?.session_department_data ? (
          <div>
            {enrolment.data
              .find((item) => item.id === modal.id)
              ?.appointment?.session_department_data.main_data_with_department.map(
                (item, index) => (
                  <div
                    key={index}
                    className="flex justify-start items-start flex-col mb-5"
                  >
                    <h1>
                      Start Date:
                      <span className="text-sm">{item.start_date}</span>
                    </h1>
                    <h1>
                      End Date:
                      <span className="text-sm">{item.end_date}</span>
                    </h1>
                    <h1>
                      Department:
                      <span className="text-sm">{item.department_name}</span>
                    </h1>
                    <h1>
                      Discount Amount:
                      <span className="text-sm">{item.discount_amount}</span>
                    </h1>
                    <h1>
                      Total Payable Cost:
                      <span className="text-sm">{item.total_payable_cost}</span>
                    </h1>
                    <h1>
                      Current Installment:
                      <span className="text-sm">
                        {item.current_installment}
                      </span>
                    </h1>
                    <h1>
                      current sesion month:
                      <span className="text-sm">
                        {item.current_sesion_month}
                      </span>
                    </h1>
                    <h1>
                      Total Booked Session:
                      <span className="text-sm">
                        {item.total_booked_session}
                      </span>
                    </h1>
                    <h1>
                      Recieved Amount First:
                      <span className="text-sm">
                        {item.recieved_amount_first}
                      </span>
                    </h1>
                    <h1>
                      Total Remaining Amount:
                      <span className="text-sm">
                        {item.total_remaning_amount}
                      </span>
                    </h1>
                    <h1>
                      Recieved Amount Second:
                      <span className="text-sm">
                        {item.recieved_amount_second}
                      </span>
                    </h1>
                    <h1>
                      Total Required Sessions:
                      <span className="text-sm">
                        {item.total_required_sessions}
                      </span>
                    </h1>
                    <h1>
                      Total Required Sessions:
                      <span className="text-sm">
                        {item.department_location_cost}
                      </span>
                    </h1>
                    <div className="w-full h-full">
                      <Table
                        data={item.final_month_wise_session_data}
                        columns={collection_data_colmn()}
                      />
                    </div>
                  </div>
                )
              )}
          </div>
        ) : (
          <div className="flex justify-center items-center">
            <p className="text-gray-500">No data found</p>
          </div>
        )}
      </Modal>
    );
  };

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      {renderModal()}
      <ConfirmationModal
        title="Mark as Completed"
        message="Are you sure you want to mark this enrolment as completed?"
        open={confirmationModal.status}
        setOpen={setConfirmationModal}
        confirmationButtonText="Completed"
        confirmationButtonColor="indigo"
        onDelete={() => onCompleted(confirmationModal.data)}
      />

      <ConfirmationModal
        title="Mark as dropout"
        message="Are you sure you want to mark this enrolment as dropout?"
        open={dconfirmationModal.status}
        setOpen={setConfirmationModald}
        confirmationButtonText="Dropout"
        confirmationButtonColor="red"
        onDelete={() => onCompleted(dconfirmationModal.data)}
      />
      <Breadcrumb pages={pages} />
      <div className="sm:flex sm:items-center mb-5">
        <div className="sm:flex-auto">
          <h1 className="text-xl font-semibold text-gray-900">
            Therapist Enrolment
          </h1>
        </div>
      </div>
      {enrolment.loading ? (
        <FallingLinesLoader />
      ) : (
        <Table
          columns={therapist_enrollment_colums({
            onMarkCompletedOpen,
            onMarkDropoutOpen,
            setConfirmationModal,
            setModal,
          })}
          data={enrolment.data}
          // onEditClose={cleanModalData}
          fetch={async (e) => {
            setQuery((prev) => prev + 200);

            await getAllEnrolment(query);
          }}
        />
      )}
    </div>
  );
};

export default TherapistEnrolment;
