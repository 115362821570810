import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";

const PDFfile = () => {
  return (
    <Document>
       <Page style={styles.body}>
        <View style={styles.logoSection}>{/* LOGO */}</View>
        <View style={styles.header}>
          {/* 
            Patient Details Header
            */}
          <View>
            <View style={styles.headerDiv}>
              <Text style={styles.title}>Patient :</Text>
              <Text>Krishna Soni</Text>
            </View>
            <View style={styles.headerDiv}>
              <Text style={styles.title}>Patient ID : </Text>
              <Text>MU-A01-AAA5860</Text>
            </View>
            <View style={styles.headerDiv}>
              <Text style={styles.title}>Referral DR. : </Text>
              <Text>MU-A01-AAA5860</Text>
            </View>
            <View style={styles.headerDiv}>
              <Text style={styles.title}>DOB : </Text>
              <Text>22-12-2020</Text>
            </View>
          </View>
          <View>
            <View style={styles.headerDiv}>
              <Text style={styles.title}>Age : </Text>
            </View>
            <View style={styles.headerDiv}>
              <Text style={styles.title}>Age : </Text>
            </View>
          </View>
          <View>
            <View style={styles.headerDiv}>
              <Text style={styles.title}>Visit ID : </Text>
            </View>
            <View style={styles.headerDiv}>
              <Text style={styles.title}>Date : </Text>
            </View>
          </View>
        </View>
        <View>PATIENT VITAL(S)</View>
        {/* Personal information */}
        <View style={styles.bellowHeader}>
          <View>
            <Text>Personal Information</Text>
          </View>
          <View>
            <Text>ANTHROPOMETRY</Text>
            <View>
              <Text>Weight: </Text>
              <Text>11 KG </Text>
            </View>
          </View>
          {/* informant details */}
          <View>
            <Text>INFORMANT DEMOGRAPHIC</Text>
            <View>
              <Text>Mother</Text> {/* sub heading */}
              <Text>Age (Present): 24 yrs</Text>
              <Text>Age At The Time Of: 24 yrs</Text>
              <Text>Occupation: Home maker</Text>
              <Text>Education: B.com</Text>
              <Text>Father</Text> {/* sub heading */}
              <Text>Age (Present): 24 yrs</Text>
              <Text>Age At The Time Of: 24 yrs</Text>
              <Text>Occupation: Printing Business</Text>
              <Text>Education: BCA</Text>
              <Text>Nuclear Family/Joint Family: Joint Family</Text>
              <Text>No. Of Family Members: 5</Text>
              <Text>Siblings</Text> {/* sub heading */}
              <Text>Consanguineous Marriage No</Text>
            </View>
          </View>
          {/* case history */}
          <View>
            <Text>CASE HISTORY</Text> {/* Heading */}
            <View>
              <Text>PRE-NATAL HISTORY</Text> {/* sub heading */}
              <Text>Conception Natural</Text>
              <Text>MATERNAL MEDICAL HISTORY</Text> {/* sub heading */}
              <Text>
                Others (PLEASE Mother spoke at 2 yrs of age - meaningful words
                SPECIFY):
              </Text>
              <Text>BIRTH HISTORY</Text> {/* sub heading */}
              <Text>Gestational Age In Weeks (If Known): 36 Weeks</Text>
              <Text>Mode Of Delivery</Text> {/* sub heading */}
              <Text>Birth Details</Text> {/* sub heading */}
              <Text>Birth Cry Cry Immediately After Birth</Text>
              <Text>Birth Weight (In Kilograms) 2.6kg </Text>
              <Text>Neonatal Complications None</Text>
            </View>
          </View>
          {/* DEVELOPMENTAL MILESTONES */}
          <View>
            <Text>DEVELOPMENTAL MILESTONES</Text> {/* Heading */}
            <Text>GROWS MOTOR SKILLS</Text>
            <Text>Head holding (3-4 months)</Text>
            <View style={styles.table}>
              <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>Age of Achievement</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>Age Appropriate/ Delayed</Text>
                </View>
              </View>
              <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>3-4 months</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>Age-Appropriate</Text>
                </View>
              </View>
            </View>
            <Text>Independent Sitting (6-8 Months)</Text>
            <View style={styles.table}>
              <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>Age of Achievement</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>Age Appropriate/ Delayed</Text>
                </View>
              </View>
              <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>3-4 months</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>Age-Appropriate</Text>
                </View>
              </View>
            </View>
            <Text>Independent Standing (10-12 Months)</Text>
            <View style={styles.table}>
              <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>Age of Achievement</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>Age Appropriate/ Delayed</Text>
                </View>
              </View>
              <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>3-4 months</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>Age-Appropriate</Text>
                </View>
              </View>
            </View>
            <Text>Independent Walking (12-14 Months)</Text>
            <View style={styles.table}>
              <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>Age of Achievement</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>Age Appropriate/ Delayed</Text>
                </View>
              </View>
              <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>3-4 months</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>Age-Appropriate</Text>
                </View>
              </View>
            </View>
            <Text>FINE MOTOR SKILLS</Text>
            <Text>Reaches For Object (4-5 Months)</Text>
            <View style={styles.table}>
              <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>Age of Achievement</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>Age Appropriate/ Delayed</Text>
                </View>
              </View>
              <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>3-4 months</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>Age-Appropriate</Text>
                </View>
              </View>
            </View>
            <Text>Transfer Of Objects (6-7 Months)</Text>
            <View style={styles.table}>
              <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>Age of Achievement</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>Age Appropriate/ Delayed</Text>
                </View>
              </View>
              <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>3-4 months</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>Age-Appropriate</Text>
                </View>
              </View>
            </View>
            <Text>Pincer Grasp (9 - 11 Months)</Text>
            <View style={styles.table}>
              <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>Age of Achievement</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>Age Appropriate/ Delayed</Text>
                </View>
              </View>
              <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>3-4 months</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>Age-Appropriate</Text>
                </View>
              </View>
            </View>
            <Text>SOCIAL COMMUNICATION</Text>
            <Text>Social Smile ( 1.5-2 Months)</Text>
            <View style={styles.table}>
              <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>Age of Achievement</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>Age Appropriate/ Delayed</Text>
                </View>
              </View>
              <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>3-4 months</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>Age-Appropriate</Text>
                </View>
              </View>
            </View>
            <Text>Cooing (4-5 Months)</Text>
            <View style={styles.table}>
              <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>Age of Achievement</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>Age Appropriate/ Delayed</Text>
                </View>
              </View>
              <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>3-4 months</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>Age-Appropriate</Text>
                </View>
              </View>
            </View>
            <Text>Meaningful Words (12-14 Months)</Text>
            <View style={styles.table}>
              <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>Age of Achievement</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>Age Appropriate/ Delayed</Text>
                </View>
              </View>
              <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>3-4 months</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>Age-Appropriate</Text>
                </View>
              </View>
            </View>
            <Text>Complete Sentences (2 Years - 2 Years 6 Months)</Text>
            <View style={styles.table}>
              <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>Age of Achievement</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>Age Appropriate/ Delayed</Text>
                </View>
              </View>
              <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>3-4 months</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>Age-Appropriate</Text>
                </View>
              </View>
            </View>
            <View>
              <Text>ACADEMIC HISTORY</Text>
              <View>
                <Text>School Enrolled Yes</Text>
                <Text>Area Daltonganj - Jharkhand</Text>
                <Text>Grade Daltonganj - Jharkhand</Text>
                <Text>Current academic Year Daltonganj - Jharkhand</Text>
              </View>
            </View>
          </View>
        </View>
        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
        />
      </Page>
    </Document>
  );
};

const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  title: {
    fontSize: 24,
    textAlign: "center",
  },
  text: {
    margin: 12,
    fontSize: 14,
    textAlign: "justify",
    fontFamily: "Times-Roman",
  },
  image: {
    marginVertical: 15,
    marginHorizontal: 100,
  },
  header: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: "center",
    color: "grey",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },

  bellowHeader: {
    margin: 12,
    fontSize: 14,
    textAlign: "justify",
    fontFamily: "Times-Roman",
  },
  table: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row",
  },
  tableCol: {
    width: "25%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCell: {
    margin: 5,
    marginTop: 5,
    fontSize: 10,
  },
  logoSection: {
    height: 80,
    width: 100,
  },
  header: {},
  headerDiv: {},
  title: {},
  page: {
    marginTop: 20,
    marginLeft: 20,
    marginRight: 20,
    marginBottom: 40,
  },
  section: {},
});
export default PDFfile;
