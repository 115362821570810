import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Pagination from "../../../components/Pagination";
import { FallingLinesLoader } from "../../../components/spinners/Spinner";
import Breadcrumb from "../../../components/Breadcrumb";
import ConfirmationModal from "../../../components/ConfirmationModal";
import { getHeaders } from "../../../redux/auth/actions";
import RcCalendar from "react-calendar";
import moment from "moment";
import Table from "../../../components/tables/table";
import {
  location_columns,
  therapist_logs_colums,
} from "../../../components/tables/tableheader";
import axios from "axios";
import Modal from "../../../components/Modal";
import { Formik } from "formik";
import { getAllLocations } from "../../../redux/location/actions";
import { getAllTherapists } from "../../../redux/therapist/therpist/actions";
import toast from "react-hot-toast";
import Select from "react-select";
import PhoneInput from "react-phone-input-2";
const TherapistLogs = () => {
  const Api = process.env.REACT_APP_API_URL;
  const api = process.env.REACT_APP_API_URL;
  const pages = [{ title: "Appointments", href: "/therapist" }];
  const [loading, setloading] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState({
    status: false,
    id: null,
  });
  const [appoitments, setappoitments] = useState([]);
  const dispatch = useDispatch();
  const [query, setQuery] = useState(200);
  useEffect(() => {
    document.title = "New Horizon | Therapist Logs";
    getallappoitments(200);
  }, []);

  const getallappoitments = async (query) => {
    const headers = dispatch(getHeaders());
    try {
      setloading(true);
      const res = await axios.get(
        `${Api}/api/therapist/appointment?limit=${query}`,
        {
          headers: headers,
        }
      );
      const { data, status } = res;
      if (status === 200) {
        setappoitments(data.data);
      }
    } catch (error) {
    } finally {
      setloading(false);
    }
  };

  const onDeleteOpen = (id) => {
    setConfirmationModal({ id, status: true });
  };

  const onDeleteLocation = (id) => {
    setConfirmationModal({ id: null, status: false });
  };
  const initialModalState = {
    type: "",
    state: false,
    index: null,
    edit_id: "",
    data: null,
  };
  const [modal, setModal] = useState(initialModalState);
  const { list: locationList } = useSelector((state) => state.location);
  const [cost, setCost] = useState({
    loading: true,
    cost: 0,
    time: 0,
  });
  const [getCostData, setGetCostData] = useState({
    evaluation_master_id: null,
    department_id: null,
    location_id: null,
    therapist: null,
    date: null,
    time_split_duration: cost.time,
  });
  useEffect(() => {
    dispatch(getAllLocations());
    dispatch(getAllTherapists());
  }, []);
  const { loading: tloading, list: therapistList } = useSelector(
    (state) => state.Therapist
  );
  const [dates, setDates] = useState({
    loading: false,
    data: [],
  });
  const getAllDates = async (id) => {
    const headers = await dispatch(getHeaders());
    setDates((prev) => ({ ...prev, loading: true }));
    try {
      const res = await axios.get(
        `${api}/api/evaluation-appointment/search/dates/${id}`,
        {
          headers: headers,
        }
      );
      const { data, status } = res;
      if (status === 200) {
        setDates((prev) => ({
          ...prev,
          data: data.data,
          loading: false,
        }));
      }
    } catch (error) {
      toast.error(error.message);
    }
  };
  const [evMaster, setEvMaster] = useState({
    loading: true,
    data: [],
  });

  const [timeSlots, setTimeSlots] = useState({
    loading: false,
    data: [],
  });
  const getAllEvMasters = async (id) => {
    const headers = await dispatch(getHeaders());
    setEvMaster((prev) => ({ ...prev, loading: true }));
    try {
      const res = await axios.get(
        `${api}/api/evaluation-appointment/evaluation-department/${id}`,
        {
          headers: headers,
        }
      );
      const { data, status } = res;
      if (status === 200) {
        setEvMaster((prev) => ({
          ...prev,
          data: data.data,
          loading: false,
        }));
      }
    } catch (error) {
      toast.error(error.message);
    }
  };
  const getAllTimeSlots = async (payload) => {
    const headers = await dispatch(getHeaders());
    setTimeSlots((prev) => ({ ...prev, loading: true }));
    try {
      const res = await axios.post(
        `${api}/api/evaluation-appointment/search/date/time-slots`,
        {
          therapist_id: Number(payload.therapist_id),
          date: moment(payload.date).format("YYYY-MM-DD"),
          time_split_duration: Number(payload.time_split_duration),
        },
        {
          headers: headers,
        }
      );
      const { data, status } = res;
      if (status === 200) {
        setTimeSlots((prev) => ({
          ...prev,
          data: data.data,
          loading: false,
        }));
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const getCost = async () => {
    const headers = await dispatch(getHeaders());
    setCost((prev) => ({ ...prev, loading: true }));
    try {
      const res = await axios.post(
        `${api}/api/evaluation-appointment/get-cost`,
        {
          evaluation_master_id: Number(getCostData.evaluation_master_id),
          department_id: Number(getCostData.department_id),
          location_id: Number(getCostData.location_id),
        },
        {
          headers: headers,
        }
      );
      const { data, status } = res;
      if (status === 200) {
        setCost((prev) => ({
          ...prev,
          cost: data.data.cost,
          time: data.data.time,

          loading: false,
        }));
      }
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  useEffect(() => {
    if (getCostData.department_id) {
      getAllEvMasters(getCostData.department_id);
    }
  }, [getCostData.department_id]);

  useEffect(() => {
    if (
      getCostData.evaluation_master_id &&
      getCostData.department_id &&
      getCostData.location_id
    ) {
      getCost();
    }
  }, [
    getCostData.department_id,
    getCostData.location_id,
    getCostData.evaluation_master_id,
  ]);
  function renderModal() {
    return (
      <Modal
        size="max-w-5xl"
        open={modal.state}
        setOpen={() =>
          setModal((prev) => ({
            ...prev,
            state: false,
          }))
        }
        title={`${modal?.data?.patient?.firstName} ${modal?.data?.patient?.lastName}`}
      >
        <Formik
          initialValues={{
            therapist_appointment_id: modal?.data?.id,
            department_id: 0,
            location_id: modal?.data?.patient?.location_id,
          }}
          onSubmit={async (values) => {
            const payload = {
              therapist_appointment_id: Number(values.therapist_appointment_id),
              department_id: Number(values.department_id),
              therapist_id: Number(values.therapist_id),
              location_id: Number(values.location_id),
              start_time: values.time.split("-")[0],
              end_time: values.time.split("-")[1],
              date: moment(values.date).format("YYYY-MM-DD"),
              cost: 600,
            };

            const headers = await dispatch(getHeaders());
            try {
              const res = await axios.post(
                `${api}/api/therapist/appointment/create-session-manual`,
                payload,
                {
                  headers: headers,
                }
              );
              const { data, status } = res;
              if (status === 200) {
                toast.success(data.message);
                getallappoitments();
                setModal((prev) => ({
                  ...prev,
                  state: false,
                }));
              }
            } catch (error) {
              toast.error(error.response.data.message);
            }
          }}
        >
          {(f) => (
            <form onSubmit={f.handleSubmit}>
              <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                <label
                  htmlFor="typeOfAppointment"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Department
                </label>
                <div className="mt-1 sm:col-span-2 sm:mt-0">
                  <select
                    required
                    id="department_id"
                    name="department_id"
                    value={f.values.department_id}
                    onChange={f.handleChange}
                    onBlur={f.handleBlur}
                    className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-indigo-500 sm:max-w-xs sm:text-sm"
                  >
                    <option value="">Select a Departments</option>
                    {modal?.data?.department_session_details?.map((d) => (
                      <option key={d.department_id} value={d.department_id}>
                        {d.department_name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                <label
                  htmlFor="typeOfAppointment"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Therapist
                </label>
                <div className="mt-1 sm:col-span-2 sm:mt-0">
                  <Select
                    options={therapistList
                      .filter((a) => {
                        if (f.values.department_id !== 0) {
                          return a.Therapist_department.some(
                            (department) =>
                              Number(department.department_id) ===
                              Number(f.values.department_id)
                          );
                        }
                        return true;
                      })
                      .map((p) => ({
                        value: p.id,
                        label: `${p.first_name} ${p.last_name}`,
                      }))}
                    value={f.values.therapist}
                    onChange={(e) => {
                      f.setValues({
                        ...f.values,
                        therapist: e,
                        therapist_id: Number(e.value),
                      });
                      getAllDates(e.value);
                    }}
                    className="block w-full max-w-lg rounded-md border-[1px] disabled:opacity-5 border-gray-300 shadow-sm focus:border-[1px] focus:border-indigo-500 sm:max-w-xs sm:text-sm"
                    classNamePrefix="select"
                  />
                </div>
              </div>
              <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                <label
                  htmlFor="typeOfAppointment"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Location
                </label>
                <div className="mt-1 sm:col-span-2 sm:mt-0">
                  <select
                    required
                    id="location_id"
                    name="location_id"
                    value={f.values.location_id}
                    onChange={f.handleChange}
                    onBlur={f.handleBlur}
                    className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-indigo-500 sm:max-w-xs sm:text-sm"
                  >
                    <option value="">Select a Location</option>
                    {locationList?.map((d) => (
                      <option key={d.id} value={d.id}>
                        {d.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 mb-5  sm:pt-5">
                <label
                  htmlFor="dob"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Date
                </label>
                <div className="mt-1 w-full">
                  <RcCalendar
                    name="date"
                    className=" w-full rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-indigo-500  sm:text-sm"
                    minDetail="month"
                    tileDisabled={({ activeStartDate, date, view }) => {
                      if (f.values.book_type) return false;
                      return !dates.data.includes(
                        moment(date).format("YYYY-MM-DD").toString()
                      );
                    }}
                    onChange={(value, e) => {
                      f.setValues({
                        ...f.values,
                        date: value,
                      });
                      setGetCostData({
                        ...getCostData,
                        date: value,
                      });
                      getAllTimeSlots({
                        therapist_id: f.values.therapist_id,
                        date: value,
                        time_split_duration: cost.time,
                      });
                    }}
                  />
                </div>
              </div>
              <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                <label
                  htmlFor="typeOfAppointment"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Time
                </label>
                <div className="mt-1 sm:col-span-2 sm:mt-0">
                  <select
                    id="time"
                    name="time"
                    value={f.values.time}
                    onChange={f.handleChange}
                    onBlur={f.handleBlur}
                    className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-indigo-500 sm:max-w-xs sm:text-sm"
                  >
                    <option value="">Select a Time</option>

                    {timeSlots.data.map((sin) => (
                      <option value={sin.start_time + "-" + sin.end_time}>
                        {sin.start_time + " - " + sin.end_time}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                <label
                  htmlFor="typeOfAppointment"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Price
                </label>
                <div className="mt-1 sm:col-span-2 sm:mt-0">
                  <input
                    id="typeOfAppointment"
                    name="typeOfAppointment"
                    value={cost.cost}
                    disabled
                    onChange={f.handleChange}
                    onBlur={f.handleBlur}
                    className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-indigo-500 sm:max-w-xs sm:text-sm"
                  />
                  {/* {errors.typeOfAppointment && touched.typeOfAppointment ? (
                      <p className="text-red-600 text-sm">
                        {errors.typeOfAppointment}
                      </p>
                    ) : null} */}
                </div>
              </div>
              <div className={`mt-4  w-full flex items-center justify-end`}>
                <button
                  type="submit"
                  className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
                >
                  Book
                </button>
              </div>
            </form>
          )}
        </Formik>
      </Modal>
    );
  }
  const ssinitialModalState = {
    type: "",
    title: "",
    state: false,
    index: "",
    edit_id: "",
  };
  const [smsModal, setSmsModal] = useState(ssinitialModalState);
  const smsRenderModal = () => {
    const { type, state, data, edit_id, index, title } = smsModal;
    return (
      <Modal
        // size="max-w-2xl"
        title={title}
        open={state}
        setOpen={() =>
          setSmsModal((prev) => ({ ...prev, state: false, edit_id: "" }))
        }
      >
        <Formik
          initialValues={{
            phone_number: edit_id.length === 10 ? "+91" + edit_id : edit_id,
          }}
          enableReinitialize={true}
          onSubmit={async (values) => {
            //TODO: Add optimize code here
            try {
              const headers = dispatch(getHeaders());
              const { data } = await axios.post(
                `${api}/api/therapist/appointment/send-session/timetable-list/${index}`,
                {
                  phone_number: "+" + values.phone_number,
                },
                { headers }
              );

              setSmsModal((prev) => ({ ...prev, state: false, edit_id: "" }));
              toast.success("List sent successfully!");
            } catch (error) {
              const msg = error.response.data.message;
              toast.error(msg);
            }
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            setValues,
            handleSubmit,
          }) => (
            <div className="flex w-max gap-2 Z-[9999]">
              <form className="flex gap-2" onSubmit={handleSubmit}>
                <div>
                  <PhoneInput
                    country={"in"}
                    value={values.phone_number}
                    onChange={(phone) =>
                      setValues({ ...values, phone_number: phone })
                    }
                    inputProps={{
                      name: "phone",
                      required: true,
                      autoFocus: true,
                    }}
                  />
                </div>
                <button
                  className="inline-flex items-center rounded-md border mb-6 border-transparent bg-indigo-100 px-3 py-2 text-sm font-medium leading-4 text-indigo-700 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  type="submit"
                >
                  send session list
                </button>
              </form>
            </div>
          )}
        </Formik>
      </Modal>
    );
  };
  return (
    <div className="px-4 sm:px-6 lg:px-8">
      {renderModal()}
      {smsRenderModal()}
      <ConfirmationModal
        open={confirmationModal.status}
        setOpen={setConfirmationModal}
        onDelete={() => onDeleteLocation(confirmationModal.id)}
      />
      <Breadcrumb pages={pages} />
      <div className="sm:flex sm:items-center mb-5">
        <div className="sm:flex-auto">
          <h1 className="text-xl font-semibold text-gray-900">Appointments</h1>
        </div>
        <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none"></div>
      </div>
      {loading ? (
        <FallingLinesLoader />
      ) : (
        <Table
          columns={therapist_logs_colums({
            onDeleteOpen,
            setModal,
            setSmsModal,
          })}
          data={appoitments}
          fetch={async (d) => {
            setQuery((prev) => d);
            await getallappoitments(d);
          }}
        />
      )}
    </div>
  );
};

export default TherapistLogs;
