import { React, useState } from "react";
import {
  ArrowUturnLeftIcon,
  BellIcon,
  BellAlertIcon,
  BellSlashIcon,
  ChevronDoubleLeftIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronDoubleRightIcon,
} from "@heroicons/react/24/outline";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Breadcrumb from "../../../components/Breadcrumb";
import { useDispatch } from "react-redux";
import { getHeaders } from "../../../redux/auth/actions";
import axios from "axios";
import { useEffect } from "react";
import { FallingLinesLoader } from "../../../components/spinners/Spinner";
import moment from "moment/moment";
import {
  Button,
  PageButton,
} from "../../../components/tables/paginationButtons";
import { set } from "react-hook-form";

function NotificationList() {
  const [notify, setNotify] = useState({
    loading: true,
    data: [],
  });
  const [pageRange, setPageRange] = useState({
    start: 0,
    end: 50,
  });
  const gotoPage = (page) => {
    const count = Math.ceil(
      Math.ceil(notify.data?.fNotifications?.length / 50)
    );
    if (page === 0) {
      setPageRange({ start: 0, end: 50 });
      setcurrentpage(1);
    } else if (page === count - 1) {
      setPageRange({
        start: page * 50,
        end: notify.data?.fNotifications?.length,
      });
      setcurrentpage(page + 1);
    } else {
      setPageRange({ start: page * 50, end: page * 50 + 50 });
      setcurrentpage(page + 1);
    }
  };
  const [currentPage, setcurrentpage] = useState(1);

  const [tabs, setTabs] = useState([
    {
      name: "All",
      href: "all",
      count: notify?.data?.fNotifications
        ? notify?.data?.fNotifications?.length
        : "0",
      current: true,
    },
    {
      name: "Read",
      href: "read",
      count: notify?.data?.readed_notifications
        ? notify?.data?.readed_notifications?.length
        : "0",
      icon: BellIcon,
      current: false,
    },
    {
      name: "Unread",
      href: "unread",
      count: notify?.data?.unread_notifications
        ? notify?.data?.unread_notifications?.length
        : "0",
      icon: BellSlashIcon,
      current: false,
    },
  ]);
  const location = useLocation();
  const [getTabs, setActiveTab] = useState(tabs);

  const dispach = useDispatch();
  const getAllLocations = async (limit) => {
    let url = `${process.env.REACT_APP_API_URL}/api/notification`;
    if (limit) {
      url = `${process.env.REACT_APP_API_URL}/api/notification?limit=${limit}`;
    }
    const headers = await dispach(getHeaders());
    try {
      const response = await axios.get(`${url}`, {
        headers: headers,
      });
      setNotify((prev) => ({
        ...prev,
        loading: false,
        data: response.data.data,
      }));
    } catch (error) {}
  };
  useEffect(() => {
    getAllLocations();
  }, []);
  const pages = [{ title: "Notifications", href: "/notifications" }];
  const navigate = useNavigate();
  const [isRead, setIsRead] = useState(false);

  const [getTabsIndex, setActiveTabIndex] = useState(0);

  function notificationChange(...notificationChangeclass) {
    return notificationChangeclass.filter(Boolean).join(" ");
  }

  const activeTabStatus = (tab, index) => {
    setActiveTabIndex(index);
    setActiveTab(
      tabs.map((item) =>
        item.name === tab.name
          ? { ...item, current: true }
          : { ...item, current: false }
      )
    );
  };

  const handleMarkAsRead = async (id) => {
    const headers = await dispach(getHeaders());
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/api/notification/${id}`,
        {},
        {
          headers: headers,
        }
      );

      const _notity = notify.data.unread_notifications.filter(
        (item) => item.id !== id
      );
      setNotify((prev) => ({
        ...prev,
        data: {
          ...prev.data,
          unread_notifications: _notity,
          readed_notifications: [
            ...prev.data.readed_notifications,
            notify.data.unread_notifications.find((item) => item.id === id),
          ],
        },
      }));
    } catch (error) {}
  };
  const [date, setDate] = useState("");
  const [query, setQuery] = useState("");

  const previousPage = () => {
    const start = pageRange.start - 50;
    const end = pageRange.end - 50;
    setPageRange({ start, end });
    setcurrentpage(currentPage - 1);
  };
  const nextPage = () => {
    const start = pageRange.start + 50;
    const end = pageRange.end + 50;
    setPageRange({ start, end });
    setcurrentpage(currentPage + 1);
  };
  return notify.loading ? (
    <FallingLinesLoader />
  ) : (
    <div>
      <div>
        <Breadcrumb pages={pages} />
        <div className="flex items-center justify-between">
          <button
            type="button"
            onClick={() => navigate(-1)}
            className="inline-flex mb-3 justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          >
            <span className="w-5 mr-2">
              <ArrowUturnLeftIcon size={18} />
            </span>{" "}
            Back
          </button>
          <div className="flex flex-row justify-end items-center gap-4">
            {" "}
            <input
              type="text"
              value={query}
              placeholder="Search Notifications"
              onChange={(e) => {
                setQuery(e.target.value);
              }}
              className="rounded-md"
            />
            <input
              type="date"
              value={date}
              onChange={(e) => {
                setDate(e.target.value);
              }}
              className="rounded-md"
            />
          </div>
        </div>
      </div>
      <div>
        <div className="sm:hidden">
          <label htmlFor="tabs" className="sr-only">
            Select a tab
          </label>
          {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
          <select
            id="tabs"
            name="tabs"
            className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
            defaultValue={tabs.find((tab) => tab.current).name}
          >
            {tabs.map((tab, key) => (
              <option key={key}>{tab.name}</option>
            ))}
          </select>
        </div>
        <div className="hidden sm:block">
          <div className="border-b border-gray-200">
            <nav className="-mb-px flex space-x-8" aria-label="Tabs">
              {getTabs?.map((tab, index) => (
                <Link
                  onClick={() => activeTabStatus(tab, index)}
                  key={index}
                  className={notificationChange(
                    tab.current
                      ? "border-indigo-500 text-indigo-600"
                      : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200",
                    "whitespace-nowrap flex py-4 px-1 border-b-2 font-medium text-sm"
                  )}
                  aria-current={tab.current ? "page" : undefined}
                >
                  {tab.icon ? (
                    <div>
                      <tab.icon
                        className={notificationChange(
                          tab.current
                            ? "text-indigo-500"
                            : "text-gray-400 group-hover:text-gray-500",
                          "-ml-0.5 mr-2 h-5 w-5"
                        )}
                        aria-hidden="true"
                      />
                    </div>
                  ) : null}

                  {tab.name}
                  {tab.name === "All" ? (
                    <span
                      className={notificationChange(
                        tab.current
                          ? "bg-indigo-100 text-indigo-600"
                          : "bg-gray-100 text-gray-900",
                        "hidden ml-3 py-0.5 px-2.5 rounded-full text-xs font-medium md:inline-block"
                      )}
                    >
                      {notify?.data?.fNotifications?.length}
                    </span>
                  ) : tab.name === "Read" ? (
                    <span
                      className={notificationChange(
                        tab.current
                          ? "bg-indigo-100 text-indigo-600"
                          : "bg-gray-100 text-gray-900",
                        "hidden ml-3 py-0.5 px-2.5 rounded-full text-xs font-medium md:inline-block"
                      )}
                    >
                      {notify?.data?.readed_notifications?.length}
                    </span>
                  ) : tab.name === "Unread" ? (
                    <span
                      className={notificationChange(
                        tab.current
                          ? "bg-indigo-100 text-indigo-600"
                          : "bg-gray-100 text-gray-900",
                        "hidden ml-3 py-0.5 px-2.5 rounded-full text-xs font-medium md:inline-block"
                      )}
                    >
                      {notify?.data?.unread_notifications?.length}
                    </span>
                  ) : null}
                </Link>
              ))}
            </nav>
          </div>
        </div>
      </div>

      {getTabs?.map((tab, index) => (
        <div
          className={`${getTabsIndex == index ? "mt-6 flow-root " : "hidden"}`}
        >
          <ul role="list" className="-my-5 divide-gray-200">
            {tab?.href === "all" && tab?.current
              ? notify?.data?.fNotifications
                  ?.filter((w) => {
                    if (date) {
                      return (
                        moment(w.createdAt).format("DD/MM/YYYY") ===
                        moment(date).format("DD/MM/YYYY")
                      );
                    } else {
                      return w;
                    }
                  })
                  ?.filter((w) => {
                    if (query) {
                      return `${w.body} ${w.title}`
                        .toLowerCase()
                        .includes(query.toLowerCase());
                    } else {
                      return w;
                    }
                  })
                  .slice(pageRange.start, pageRange.end)
                  .map((person, key) => (
                    <li
                      key={key}
                      className={`${"p-4 border-b hover:bg-gray-100 cursor-pointer"}`}
                    >
                      <Link
                        to={
                          person.action == "PATIENT_VIEW"
                            ? `/view-patient/${person.patient_id}`
                            : person.action == "ENROLLMENT_PATIENT_VIEW"
                            ? `/patient-view/${person.enrollment_id}`
                            : "/"
                        }
                      >
                        <div className="flex items-center space-x-4">
                          <div className="min-w-0 flex-1">
                            <p className="truncate text-sm font-medium text-gray-900">
                              {person.title}{" "}
                              <span className="text-gray-400  ml-4">
                                {moment(person.createdAt).format("DD/MM/YYYY")}
                              </span>
                            </p>
                            <p className="truncate text-sm text-gray-500">
                              {person.body}
                            </p>
                          </div>
                        </div>
                      </Link>
                    </li>
                  ))
              : null}
            {tab?.href === "read" && tab?.current
              ? notify?.data?.readed_notifications
                  ?.filter((w) => {
                    if (date) {
                      return (
                        moment(w.createdAt).format("DD/MM/YYYY") ===
                        moment(date).format("DD/MM/YYYY")
                      );
                    } else {
                      return w;
                    }
                  })
                  .slice(pageRange.start, pageRange.end)
                  .map((person, key) => (
                    <li
                      key={key}
                      onClick={handleMarkAsRead}
                      className={`${"p-4 border-b hover:bg-gray-100 cursor-pointer"}`}
                    >
                      <Link
                        to={
                          person.action == "PATIENT_VIEW"
                            ? `/view-patient/${person.patient_id}`
                            : person.action == "ENROLLMENT_PATIENT_VIEW"
                            ? `/patient-view/${person.enrollment_id}`
                            : "/"
                        }
                        className="min-w-0 flex-1"
                      >
                        <p className="truncate text-sm font-medium text-gray-900">
                          {person.title}{" "}
                          <span className="text-gray-400  ml-4">
                            {moment(person.createdAt).format("DD/MM/YYYY")}
                          </span>
                        </p>
                        <p className="truncate text-sm text-gray-500">
                          {person.body}
                        </p>
                      </Link>
                    </li>
                  ))
              : null}
            {tab?.href === "unread" && tab?.current
              ? notify?.data?.unread_notifications
                  ?.filter((w) => {
                    if (date) {
                      return (
                        moment(w.createdAt).format("DD/MM/YYYY") ===
                        moment(date).format("DD/MM/YYYY")
                      );
                    } else {
                      return w;
                    }
                  })
                  .slice(pageRange.start, pageRange.end)
                  .map((person, key) => (
                    <li
                      key={key}
                      // onClick={handleMarkAsRead}
                      className={`${"p-4 border-b hover:bg-gray-100 cursor-pointer flex flex-row justify-between"}`}
                    >
                      <Link
                        to={
                          person.action == "PATIENT_VIEW"
                            ? `/view-patient/${person.patient_id}`
                            : person.action == "ENROLLMENT_PATIENT_VIEW"
                            ? `/patient-view/${person.enrollment_id}`
                            : "/"
                        }
                        className="min-w-0 flex-1"
                      >
                        <p className="truncate text-sm font-medium text-gray-900">
                          {person.title}{" "}
                          <span className="text-gray-400 ml-4">
                            {moment(person.createdAt).format("DD/MM/YYYY")}
                          </span>
                        </p>
                        <p className="truncate text-sm text-gray-500">
                          {person.body}
                        </p>
                      </Link>
                      <div
                        onClick={() => handleMarkAsRead(person.id)}
                        className="flex-shrink-0"
                      >
                        <span className="inline-flex px-2 ml-3 text-xs leading-5 text-blue-600 bg-blue-100 rounded-full">
                          <button size="default" variant="outlined">
                            Mark as Read
                            <span className="sr-only">, </span>
                          </button>
                        </span>
                      </div>
                    </li>
                  ))
              : null}
          </ul>
        </div>
      ))}
      <div className="flex items-center mt-10 w-full justify-center">
        <div className="py-3 flex w-full items-center justify-between">
          <div className="hidden md:inline-block lg:inline-block">
            <span>
              Page{" "}
              <strong>
                {currentPage} of{" "}
                {Math.ceil(notify.data?.fNotifications?.length / 50)}
              </strong>{" "}
            </span>
            <span>
              | Go to page:{" "}
              <input
                type="number"
                className="rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                defaultValue={currentPage + 1}
                onChange={(e) => {
                  const page = e.target.value ? Number(e.target.value) - 1 : 0;
                  gotoPage(page);
                }}
                style={{ width: "100px" }}
              />
            </span>{" "}
            <select
              className="form-select appearance-none
          pl-3
          pl-4
          py-1.5
          text-base
          font-normal
          text-gray-700
          bg-white bg-clip-padding bg-no-repeat
          border border-solid border-gray-300
          rounded
          transition
          ease-in-out
          m-0
          focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              onChange={async (e) => {
                if (e.target.value !== "all") {
                  await getAllLocations(e.target.value);
                } else {
                  await getAllLocations("");
                }
              }}
            >
              {[50, 100, 200, 400, 600, 800, "all"].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  Show {pageSize}
                </option>
              ))}
            </select>
          </div>
          <div className="flex-1 flex justify-between sm:hidden">
            <Button
              onClick={() => previousPage()}
              disabled={pageRange.start === 0}
            >
              Previous
            </Button>
            <Button
              onClick={() => nextPage()}
              disabled={pageRange.end >= notify.data?.fNotifications?.length}
            >
              Next
            </Button>
          </div>
          <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-end">
            <div>
              <nav
                className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px"
                aria-label="Pagination"
              >
                <PageButton
                  className="rounded-l-md"
                  onClick={() => gotoPage(0)}
                  disabled={
                    currentPage === 1 ||
                    Math.ceil(notify.data?.fNotifications?.length / 50) === 1
                  }
                >
                  <span className="sr-only">First</span>
                  <ChevronDoubleLeftIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </PageButton>
                <PageButton
                  onClick={() => {
                    previousPage();
                  }}
                  disabled={
                    currentPage === 1 ||
                    Math.ceil(notify.data?.fNotifications?.length / 50) === 1
                  }
                >
                  <span className="sr-only">Previous</span>
                  <ChevronLeftIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </PageButton>
                <PageButton
                  onClick={() => {
                    nextPage();
                  }}
                  disabled={
                    currentPage ===
                      Math.ceil(notify.data?.fNotifications?.length / 50) ||
                    Math.ceil(notify.data?.fNotifications?.length / 50) === 1
                  }
                >
                  <span className="sr-only">Next</span>
                  <ChevronRightIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </PageButton>
                <PageButton
                  className="rounded-r-md"
                  onClick={() => {
                    // this button take to the last page of pagination
                    gotoPage(
                      Math.ceil(notify.data?.fNotifications?.length / 50) - 1
                    );
                  }}
                  disabled={
                    currentPage ===
                      Math.ceil(notify.data?.fNotifications?.length / 50) ||
                    Math.ceil(notify.data?.fNotifications?.length / 50) === 1
                  }
                >
                  <span className="sr-only">Last</span>
                  <ChevronDoubleRightIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </PageButton>
              </nav>
            </div>
          </div>
        </div>
      </div>
      {/* {getTabsIndex == 0 ? (

                <div className="mt-6 flow-root">
                    <ul role="list" className="-my-5 divide-y divide-gray-200">
                        {people.map((person, key) => (
                            <li key={key} onClick={handleMarkAsRead} className="py-4 ">
                                <div className="flex items-center space-x-4">
                                    <div className="flex-shrink-0">
                                        <img className="h-8 w-8 rounded-full" src={person.imageUrl} alt="" />
                                    </div>
                                    <div className="min-w-0 flex-1">
                                        <p className="truncate text-sm font-medium text-gray-900">{person.name}</p>
                                        <p className="truncate text-sm text-gray-500">{'@' + person.handle}</p>
                                    </div>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
            ) : getTabsIndex == 1 ? (
                <div>Tab Read</div>
            ) : getTabsIndex == 2  ? (
                <div>Tab UnRead</div>
            ) : null} */}
      {/* {state == "Read" ? ():null} */}
    </div>
  );
}

export default NotificationList;
