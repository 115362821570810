import {
  GET_ALL_DEPARTMENT_BEGIN,
  GET_ALL_DEPARTMENT_SUCCESS,
  GET_ALL_DEPARTMENT_ERROR,
  ADD_DEPARTMENT_BEGIN,
  ADD_DEPARTMENT_SUCCESS,
  ADD_DEPARTMENT_ERROR,
  GET_DEPARTMENT_BEGIN,
  GET_DEPARTMENT_ERROR,
  GET_DEPARTMENT_SUCCESS,
  UPDATE_DEPARTMENT_BEGIN,
  UPDATE_DEPARTMENT_SUCCESS,
  UPDATE_DEPARTMENT_ERROR,
  DELETE_DEPARTMENT_BEGIN,
  DELETE_DEPARTMENT_SUCCESS,
  DELETE_DEPARTMENT_ERROR,
} from "./constants";

const initState = {
  list: [],
  loading: false,
  error: null,
};

const DepartmentReducer = (state = initState, action) => {
  const { type, data, error } = action;
  switch (type) {
    case GET_ALL_DEPARTMENT_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case GET_ALL_DEPARTMENT_SUCCESS:
      return {
        ...state,
        list: data,
        loading: false,
      };
    case GET_ALL_DEPARTMENT_ERROR:
      return {
        ...state,
        error,
        loading: false,
      };
      case ADD_DEPARTMENT_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case ADD_DEPARTMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        list: [...state.list, data],
      };
    case ADD_DEPARTMENT_ERROR:
      return {
        ...state,
        error,
        loading: false,
      };
      case GET_DEPARTMENT_BEGIN:
        return {
          ...state,
          loading: true,
          DepartmentData: "",
        };
      case GET_DEPARTMENT_SUCCESS:
        return {
          ...state,
          loading: false,
          DepartmentData: data,
        };
      case GET_DEPARTMENT_ERROR:
        return {
          ...state,
          error,
          loading: false,
        };
        case UPDATE_DEPARTMENT_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_DEPARTMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        list: state.list.map((ele) => {
          if (ele.id === data.id) {
            return { ...data };
          } else return ele;
        }),
      };
    case UPDATE_DEPARTMENT_ERROR:
      return {
        ...state,
        error,
        loading: false,
      };
    case DELETE_DEPARTMENT_BEGIN:
      return {
        ...state,
        loading: true,
      };
      case DELETE_DEPARTMENT_SUCCESS:
        return {
          ...state,
          loading: false,
          list: state.list.filter((ele) => ele.id !== data.id),
        };
      case DELETE_DEPARTMENT_ERROR:
        return {
          ...state,
          error,
          loading: false,
        };
    default:
      return state;
  }
};

export default DepartmentReducer;