import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteLocation,
  getAllLocations,
} from "../../../redux/location/actions";
import Pagination from "../../../components/Pagination";
import { FallingLinesLoader } from "../../../components/spinners/Spinner";
import Breadcrumb from "../../../components/Breadcrumb";
import ConfirmationModal from "../../../components/ConfirmationModal";

import Table from "../../../components/tables/table";
import { all_comments_columns } from "../../../components/tables/tableheader";
import { getHeaders } from "../../../redux/auth/actions";
import { toast } from "react-hot-toast";
import axios from "axios";
const api = process.env.REACT_APP_API_URL;
const AllComments = () => {
  useEffect(() => {
    getComments();
    document.title = "New Horizon | Locations";
  }, []);
  const [comments, setComment] = useState({
    data: [],
    loading: true,
    filterData: [],
  });
  const dispatch = useDispatch();
  async function getComments() {
    const headers = await dispatch(getHeaders());
    try {
      const res = await axios.get(`${api}/api/red-flags/rf-comments`, {
        headers: headers,
      });
      const { data, status } = res;
      if (data) {
        setComment((prev) => ({
          ...prev,
          data: data.data,
          filterData: data.data,
          loading: false,
        }));
      }
      console.log(res);
    } catch (error) {
      toast.error(error.message);
    }
  }

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center w-full mb-5">
        <div className="sm:flex-auto w-[80%]">
          <h1 className="text-xl font-semibold text-gray-900">All Comments</h1>
        </div>
        <div className="sm:grid sm:grid-cols-2 w-[20%]  items-end sm:gap-4  sm:border-gray-200 sm:pt-5">
          <div
            className={`mt-1 flex items-center justify-right  sm:col-span-2 sm:mt-0`}
          >
            <select
              id="type"
              name="type"
              onChange={(e) => {
                const value = e.target.value;
                if (value === "")
                  return setComment((prev) => ({
                    ...prev,
                    filterData: comments.data,
                  }));

                const filterData = comments.data.filter((item) => {
                  return item.status === value;
                });
                setComment((prev) => ({ ...prev, filterData }));
              }}
              className="block ml-1 w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-indigo-500 sm:max-w-xs sm:text-sm"
            >
              <option value="">Sort By</option>
              <option value="RESOLVED">Resolved</option>
              <option value="RAISED">Raised</option>
              <option value="PENDING">Pending</option>
            </select>
          </div>
        </div>
      </div>
      {comments.loading ? (
        <FallingLinesLoader />
      ) : (
        <Table
          columns={all_comments_columns()}
          data={comments.filterData}
          // onEditClose={cleanModalData}
        />
      )}
    </div>
  );
};

export default AllComments;
