import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Breadcrumb from "../../../components/Breadcrumb";
import { FallingLinesLoader } from "../../../components/spinners/Spinner";
import Table from "../../../components/tables/table";
import { category_master_columns } from "../../../components/tables/tableheader";
import {
  deleteCategory,
  getAllCategorys,
} from "../../../redux/CategoryMaster/actions";
import ConfirmationModal from "../../../components/ConfirmationModal";
function CategoryMaster() {
  const [confirmationModal, setConfirmationModal] = useState({
    status: false,
    id: null,
  });
  const pages = [{ title: "Category Master", href: "/category-master" }];
  const dispatch = useDispatch();
  const { loading, list: categoryList } = useSelector(
    (state) => state.category
  );
  useEffect(() => {
    document.title = "New Horizon | Category Master";
    dispatch(getAllCategorys());
  }, []);
  
  const onDeleteOpen = (id) => {
    setConfirmationModal({ id, status: true });
  };

  const onDeleteCategory = (id) => {
    dispatch(deleteCategory(id));
    setConfirmationModal({ id: null, status: false });
  };

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <ConfirmationModal
        open={confirmationModal.status}
        setOpen={setConfirmationModal}
        onDelete={() => onDeleteCategory(confirmationModal.id)}
      />
      <Breadcrumb pages={pages} />
      <div className="sm:flex sm:items-center mb-5">
        <div className="sm:flex-auto">
          <h1 className="text-xl font-semibold text-gray-900">
            Category Master
          </h1>
        </div>
        <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
          <Link
            to="/add-category"
            type="button"
            className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
          >
            Add Category
          </Link>
        </div>
      </div>
      {loading ? (
        <FallingLinesLoader />
      ) : (
        <Table
          columns={category_master_columns({ onDeleteOpen })}
          data={categoryList}
        />
      )}
    </div>
  );
}

export default CategoryMaster;
