import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import Breadcrumb from "../../../../components/Breadcrumb";
import { FallingLinesLoader } from "../../../../components/spinners/Spinner";
import Table from "../../../../components/tables/table";
import { toast } from "react-hot-toast";
import axios from "axios";
import { getHeaders } from "../../../../redux/auth/actions";
import { useDispatch } from "react-redux";
import { specialist_performance_data } from "../../../../components/tables/tableheader";
import moment from "moment";
import { getAllLocations } from "../../../../redux/location/actions";
import { useSelector } from "react-redux";

const api = process.env.REACT_APP_API_URL;
function SpecialistPerformance() {
  const pages = [{ title: "MIS Conversion " }];
  const [loading, setLoading] = useState(false);
  //   const [modal, setModal] = useState(initialModalState);
  const [quary, setQuary] = useState({
    start_date: moment().subtract(30, "days").format("YYYY-MM-DD"),
    end_date: moment().format("YYYY-MM-DD"),
  });

  const dispatch = useDispatch();
  const { loading: LocationLOading, list: locationList } = useSelector(
    (state) => state.location
  );
  useEffect(() => {
    dispatch(getAllLocations());
    getAllConversions();
    document.title = "New Horizon | Specialist Performance";
  }, []);

  const [appointmentList, setAppointmentList] = useState({
    loading: false,
    data: [],
    FilterData: [],
    options: "",
  });

  const getAllConversions = async () => {
    if (quary.locationId === "") {
      delete quary.locationId;
    }
    const headers = await dispatch(getHeaders());
    setAppointmentList((prev) => ({ ...prev, loading: true }));
    try {
      const res = await axios.get(
        `${api}/api/reporting/specialist-performance-data`,
        {
          headers: headers,
        }
      );
      const { data, status } = res;
      if (data) {
        setAppointmentList((prev) => ({
          ...prev,
          data: data.data,
          FilterData: data.data,
          loading: false,
          options: data.data[0].department_id,
        }));
      }
      console.log(res);
    } catch (error) {
      toast.error(error.message);
    }
  };
  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center mb-5">
        <div className="sm:flex-auto">
          <Breadcrumb pages={pages} />
          <h1 className="text-xl font-semibold text-gray-900">
            Specialist Performance Data Report
          </h1>
          <select
            value={appointmentList.options}
            onChange={(e) => {
              setAppointmentList((prev) => ({
                ...prev,
                loading: false,
                options: e.target.value,
              }));
            }}
            className="mt-5 block w-24 py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          >
            {appointmentList.data.map((a) => {
              return (
                <option value={a.department_id}>{a.department_name}</option>
              );
            })}
          </select>
        </div>
        <div className="sm:grid sm:grid-cols-2 w-max sm:items-start sm:gap-4  sm:border-gray-200 sm:pt-5"></div>
      </div>
      {appointmentList.loading ? (
        <FallingLinesLoader />
      ) : (
        appointmentList.FilterData.filter(
          (a) => a.department_id === Number(appointmentList.options)
        ).map((a) => {
          return (
            <div className="w-full mt-2 h-full">
              <span className="text-xl font-semibold text-gray-900">
                {a.department_name}
              </span>
              <Table
                columns={specialist_performance_data()}
                data={a.data}
                // loading={loading}
              />
            </div>
          );
        })
      )}
    </div>
  );
}
export default SpecialistPerformance;
