import { Fragment, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { useFormik } from 'formik';
import { overrideSchema } from '../../../../schemas';
import { useDispatch } from 'react-redux';
import { addException } from '../../../../redux/therapist/exception/actions';
import moment from 'moment/moment';
import { SingleBed } from '@mui/icons-material';
import { toast } from 'react-toast';

export default function Override({
  showModal,
  setShowModal,
  scheduleId,
  current_data,
}) {
  const dispatch = useDispatch();
  const [check, setcheck] = useState(false);
  const initialValues = {
    date: '',
    from_time: '',
    to_time: '',
    available: false,
  };
  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: initialValues,
      validationSchema: overrideSchema,
      onSubmit: (values, action) => {
        const body = { ...values };
        body.available = body.check ? true : false;

        let flags = current_data.map((sin) => {
          console.log(
            sin,
            body,
            moment(sin.date).format('YYYY-MM-DD') == body.date,
            sin.from_time == body.from_time,
            sin.to_time == body.to_time
          );
          return (
            moment(sin.date).format('YYYY-MM-DD') == body.date &&
            sin.from_time == body.from_time &&
            sin.to_time == body.to_time
          );
        });
        console.log(flags);
        if (flags.indexOf(true) > -1) {
          toast.error('entry already exists');
        } else {
          body.date = moment(values.date).format();
          delete body.check;
          dispatch(addException(scheduleId, body));
          action.resetForm();
          setShowModal(false);
        }
      },
    });
  // console.log(errors)

  return (
    <Transition.Root show={showModal} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setShowModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                <div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900"
                    >
                      Add Override
                    </Dialog.Title>
                    <div className="mt-2 w-full flex items-center justify-center">
                      <form className="w-[80%]" onSubmit={handleSubmit}>
                        <div className="mt-2 flex items-center justify-between">
                          <label className="text-left text-sm font-medium text-gray-700 sm:mt-px">
                            Date :
                          </label>
                          <input
                            type="date"
                            min={new Date().toISOString().slice(0, -14)}
                            id="override-date"
                            autoComplete="off"
                            name="date"
                            value={values.date}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            // className=" border-gray-300 focus:border-indigo-500 shadow-sm rounded-md"
                            className={
                              'w-[61%] border-gray-300  shadow-sm rounded-md' +
                              (errors.date && touched.date
                                ? ' border border-red-500'
                                : ' focus:border-indigo-500 text-sm font-medium  text-gray-700 sm:mt-px')
                            }
                          />
                        </div>
                        {errors.date && touched.date ? (
                          <p className="text-red-700 text-right mr-4">
                            {errors.date}
                          </p>
                        ) : null}

                        <div className="mt-2  items-center  flex justify-between">
                          <label
                            htmlFor="from-time"
                            className="text-left text-sm font-medium text-gray-700 sm:mt-px"
                          >
                            From Time :
                          </label>
                          <input
                            type="time"
                            id="from_time"
                            name="from_time"
                            value={values.from_time}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            autoComplete="off"
                            // className=" border-gray-300 focus:border-indigo-500 shadow-sm rounded-md"
                            className={
                              'w-[61%] border-gray-300  shadow-sm rounded-md text-sm ' +
                              (errors.from_time && touched.from_time
                                ? ' border border-red-500'
                                : ' focus:border-indigo-500 font-medium  text-gray-700 sm:mt-px')
                            }
                          />
                        </div>
                        {errors.from_time && touched.from_time ? (
                          <p className="text-red-700 text-right ">
                            {errors.from_time}
                          </p>
                        ) : null}

                        <div className="mt-2 items-center  flex justify-between">
                          <label className="text-left text-sm font-medium text-gray-700 sm:mt-px">
                            To Time
                          </label>
                          <input
                            type="time"
                            id="id-time"
                            autoComplete="off"
                            name="to_time"
                            value={values.to_time}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            // className="w-[61%] border-gray-300 focus:border-indigo-500 shadow-sm rounded-md"
                            className={
                              'w-[61%] border-gray-300  shadow-sm rounded-md' +
                              (errors.to_time && touched.to_time
                                ? ' border border-red-500'
                                : ' focus:border-indigo-500 text-sm font-medium  text-gray-700 sm:mt-px')
                            }
                          />
                        </div>
                        {errors.to_time && touched.to_time ? (
                          <p className="text-red-700 text-right ">
                            {errors.to_time}
                          </p>
                        ) : null}

                        <div>
                          <div className="mt-5 sm:mt-6">
                            <div className="mt-2 mb-2 flex justify-center items-center">
                              <input
                                type="checkbox"
                                id="check"
                                className={'mr-2'}
                                name="check"
                                autoComplete="off"
                                value={values.available}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              <label htmlFor="check">Available</label>
                            </div>
                            <button
                              type="submit"
                              className="inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm"
                            >
                              Save
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
