/* eslint-disable */
import axios from 'axios'
import { getHeaders } from '../auth/actions'
import { toast } from 'react-toast'
import { 
    GET_ALL_MEDICINE_MASTER_BEGIN,
    GET_ALL_MEDICINE_MASTER_SUCCESS,
    GET_ALL_MEDICINE_MASTER_ERROR,
    ADD_MEDICINE_MASTER_BEGIN,
    ADD_MEDICINE_MASTER_SUCCESS,
    ADD_MEDICINE_MASTER_ERROR,
    GET_MEDICINE_MASTER_BEGIN,
    GET_MEDICINE_MASTER_SUCCESS,
    GET_MEDICINE_MASTER_ERROR,
    UPDATE_MEDICINE_MASTER_BEGIN,
    UPDATE_MEDICINE_MASTER_SUCCESS,
    UPDATE_MEDICINE_MASTER_ERROR,
    DELETE_MEDICINE_MASTER_BEGIN,
    DELETE_MEDICINE_MASTER_SUCCESS,
    DELETE_MEDICINE_MASTER_ERROR
 } from './constants'

const API = process.env.REACT_APP_API_URL

export const getAllMedicineMasters = () => async(dispatch) => {
  try {
    dispatch({
      type: GET_ALL_MEDICINE_MASTER_BEGIN
    })
    const headers = dispatch(getHeaders());
    const {data} = await axios.get(`${API}/api/medicine/master`, { headers })
    console.log(data.data)

    dispatch({
      type: GET_ALL_MEDICINE_MASTER_SUCCESS, 
      data: data.data
    })
  }
  catch (error) {
    const msg = error.response.data.message;
    toast.error(msg)
    console.log(error)
    dispatch({
      type: GET_ALL_MEDICINE_MASTER_ERROR,
      error: msg
    })
  }
}

export const addMedicineMaster = (body) => async(dispatch) => {
    try {
        dispatch({
          type: ADD_MEDICINE_MASTER_BEGIN
        })
        const headers = dispatch(getHeaders());
        const {data} = await axios.post(`${API}/api/medicine/master`, body, { headers })
        console.log(data.data)
        toast.success("Medicine added successfully!")
        dispatch({
          type: ADD_MEDICINE_MASTER_SUCCESS, 
          data: data.data
        })
      }
      catch (error) {
        console.log(error.response)
        const msg = error.response.data.message;
        toast.error(msg)
        dispatch({
          type: ADD_MEDICINE_MASTER_ERROR,
          error: msg
        })
      }
}

export const getMedicineMasterWithId = (id) => async(dispatch) => {
  try {
    dispatch({
      type: GET_MEDICINE_MASTER_BEGIN
    })
    const headers = dispatch(getHeaders());
    const {data} = await axios.get(`${API}/api/medicine/master/${id}`, { headers })
    console.log(data.data)
    dispatch({
      type: GET_MEDICINE_MASTER_SUCCESS, 
      data: data.data
    })
  }
  catch (error) {
    console.log(error.response)
    const msg = error.response.data.message;
    toast.error(msg)
    dispatch({
      type: GET_MEDICINE_MASTER_ERROR,
      error: msg
    })
  }
}

export const updateMedicineMaster = (id, body) => async(dispatch) => {
  try {
    dispatch({
      type: UPDATE_MEDICINE_MASTER_BEGIN
    })
    const headers = dispatch(getHeaders());
    const {data} = await axios.put(`${API}/api/medicine/master/${id}`, body, { headers })
    console.log(data.data)
    toast.success("Medicine updated successfully!")
    dispatch({
      type: UPDATE_MEDICINE_MASTER_SUCCESS, 
      data: data.data
    })
  }
  catch (error) {
    const msg = error.response.data.message;
    toast.error(msg)
    dispatch({
      type: UPDATE_MEDICINE_MASTER_ERROR,
      error: msg
    })
  }
}

export const deleteMedicineMaster = (id) => async(dispatch) => {
  try {
    dispatch({
      type: DELETE_MEDICINE_MASTER_BEGIN
    })
    const headers = dispatch(getHeaders());
    const {data} = await axios.delete(`${API}/api/medicine/master/${id}`, { headers })
    toast.success("Medicine deleted successfully!")
    dispatch({
      type: DELETE_MEDICINE_MASTER_SUCCESS, 
      data: data.data
    })
  }
  catch (error) {
    const msg = error.response.data.message;
    toast.error(msg)
    dispatch({
      type: DELETE_MEDICINE_MASTER_ERROR,
      error: msg
    })
  }
}