/* eslint-disable */
import {
  ADD_APPOINTMENT_BEGIN,
  ADD_APPOINTMENT_SUCCESS,
  ADD_APPOINTMENT_ERROR,
  GET_APPOINTMENT_BEGIN,
  GET_APPOINTMENT_ERROR,
  GET_APPOINTMENT_SUCCESS,
  GET_AVAILABLE_TIME_SLOTS_BEGIN,
  GET_AVAILABLE_TIME_SLOTS_SUCCESS,
  GET_AVAILABLE_TIME_SLOTS_ERROR,
  DELETE_APPOINTMENT_BEGIN,
  DELETE_APPOINTMENT_ERROR,
  DELETE_APPOINTMENT_SUCCESS,
  UPDATE_APPOINTMENT_BEGIN,
  UPDATE_APPOINTMENT_ERROR,
  UPDATE_APPOINTMENT_SUCCESS,
} from "./constants";

const initState = {
  loading: false,
  error: null,
  list: [],
  aLoading: false,
  aError: null,
  availableList: [],
  delLoading: false,
  delError: null,
  uLoading: false,
  uError: null,
};

const appointmentReducer = (state = initState, action) => {
  const { type, data, error } = action;
  switch (type) {
    case ADD_APPOINTMENT_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case ADD_APPOINTMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        list: [...state.list, data],
      };
    case ADD_APPOINTMENT_ERROR:
      return {
        ...state,
        error,
        loading: false,
      };
    case GET_APPOINTMENT_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case GET_APPOINTMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        list: data,
      };
    case GET_APPOINTMENT_ERROR:
      return {
        ...state,
        error,
        loading: false,
      };
    case GET_AVAILABLE_TIME_SLOTS_BEGIN:
      return {
        ...state,
        aLoading: true,
      };
    case GET_AVAILABLE_TIME_SLOTS_SUCCESS:
      return {
        ...state,
        aLoading: false,
        aError: null,
        availableList: data,
      };
    case UPDATE_APPOINTMENT_BEGIN:
      return {
        ...state,
        uLoading: true,
      };
    case UPDATE_APPOINTMENT_ERROR:
      return {
        ...state,
        uLoading: false,
        uError: error,
      };
    case UPDATE_APPOINTMENT_SUCCESS:
      return {
        ...state,
        uLoading: false,
        uError: null,
        list: state.list.map((l) => {
          if (l.id === data.id) {
            return data.data;
          }
          return l;
        }),
      };
    case GET_AVAILABLE_TIME_SLOTS_ERROR:
      return {
        ...state,
        aLoading: false,
        aError: error,
      };
    case DELETE_APPOINTMENT_BEGIN:
      return {
        ...state,
        delLoading: true,
      };
    case DELETE_APPOINTMENT_ERROR:
      return { ...state, delLoading: false, delError: error };
    case DELETE_APPOINTMENT_SUCCESS:
      return {
        ...state,
        delLoading: false,
        delError: null,
        list: state.list.filter((l) => l.id !== data),
      };
    default:
      return state;
  }
};

export default appointmentReducer;
