import React from 'react'
import { Steps } from "./Steps";
import { Questions } from "./Questions";

function FormComponent() {


    const steps = [
        { id: '01', name: 'Main', description: 'Vitae sed mi luctus laoreet.', href: '#', status: 'complete' },
        { id: '02', name: 'Group 2', description: 'Cursus semper viverra.', href: '#', status: 'complete' },
        { id: '03', name: 'Group 3', description: 'Penatibus eu quis ante.', href: '#', status: 'current' },
        { id: '05', name: 'Group 4', description: 'Penatibus eu quis ante.', href: '#', status: 'upcoming' },
    ]
    //Get list of questions for each group
    //Selected Element stored here, pass the right list of questions to the Questions A

    // selectedSteps.questions
    // Mention if last form group, so button changes from Next to Submit
    return (
        <div className="container mx-auto">
            <Steps steps={steps}></Steps>
            <Questions></Questions>

        </div>
    )
}

export default FormComponent