import React, { useEffect, useState } from "react";
import Table from "../../../../components/tables/table";
import {
  Patient_And_Session_Report_columns,
  patient_session_total,
} from "../../../../components/tables/tableheader";
import { useDispatch, useSelector } from "react-redux";
import { FallingLinesLoader } from "../../../../components/spinners/Spinner";
import { getOpdAppointmentWithId } from "../../../../redux/opdreport/action";
import { getAllPatients } from "../../../../redux/patient/actions";
import moment from "moment";
import { async } from "q";
import axios from "axios";
import { toast } from "react-hot-toast";
import PatientAndSessionLineChart from "./patientAndSessionLine";
import TotalPatientSession from "./TotalPatientSession";
import { getHeaders } from "../../../../redux/auth/actions";
import Breadcrumb from "../../../../components/Breadcrumb";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const PatientAndSessionReport = () => {
  const [selected, setselected] = useState("all");
  const [date, setDate] = useState("");
  const dispatch = useDispatch();
  const { loading, list: patient } = useSelector((state) => state.patient);
  const [patient_data, set_patient_data] = useState({
    table_header: [],
    table_data: [],
    chart_data: [],
    chart_dataset: [],
  });
  const [session_data, set_session_data] = useState({
    table_header: [],
    table_data: [],
    chart_data: [],
    chart_dataset: [],
  });
  const [session_patient, set_session_patient] = useState({
    table_header: [],
    table_data: [],
    chart_data: [],
  });
  const [datefilter, set_date_filter] = useState({ from: null, to: null });
  const { list: opdReport } = useSelector((state) => state.patient);
  const [data, setData] = useState(opdReport);
  const [Patient, setPatient] = useState([]);
  const [Session, setSession] = useState([]);
  const [Total, setTotal] = useState([]);
  const [current_tab, set_current_tab] = useState("Patient");
  const [showAssigned, setShowAssigned] = useState(true);
  const [tabs, setTabs] = useState([
    { val: 0, name: "Patient", href: "#", current: true },
    { val: 1, name: "Session", href: "#", current: false },
    { val: 2, name: "Total", href: "#", current: false },
  ]);

  let fromDate, toDate;

  const { user } = useSelector((state) => state.auth);
  const API = process.env.REACT_APP_API_URL;

  useEffect(() => {
    document.title = "New Horizon | OPD report";
    dispatch(getAllPatients());
  }, []);

  const get_report_data = async (
    from = moment().subtract(3, "month").format("YYYY-MM-DD"),
    to = moment().add(1, "year").format("YYYY-MM-DD")
  ) => {
    console.log(from, to);
    const headers = dispatch(getHeaders());
    const response = await axios.post(
      `${API}/api/reporting/overall-session-patient`,
      { from, to },
      {
        headers,
      }
    );
    //patient data
    const { data } = response;
    let temp__header;
    let patient_tab_data = [];
    Object.keys(data.data.patient).forEach((elem) => {
      temp__header = [...Object.keys(data.data.patient[elem])];
      patient_tab_data.push({ date: elem, ...data.data.patient[elem] });
    });

    let filter_header = ["date", ...new Set(temp__header)];
    let header_for_table = filter_header.map((elem) => {
      return {
        Header: elem.toUpperCase(),
        accessor: elem,
      };
    });

    let required_dataset = [...new Set(temp__header)];
    let final_dtaset = [];
    required_dataset.forEach((elem) => {
      let rgb = `rgba(${Math.random() * 255}, ${Math.random() * 256}, ${
        Math.random() * 256
      }, 0.5)`;
      final_dtaset.push({
        label: elem,
        data: Object.values(data.data.patient).map((sin) => sin[elem]),
        borderColor: rgb,
        backgroundColor: rgb,
        borderWidth: 2,
      });
    });
    set_patient_data({
      table_header: header_for_table,
      table_data: patient_tab_data,
      chart_data: data.data.patient,
      chart_dataset: final_dtaset,
    });

    //session_data
    let session_tab_data = [];
    console.log(data.data, "session data");

    Object.keys(data.data.session).forEach((elem) => {
      temp__header = [...Object.keys(data.data.session[elem])];
      session_tab_data.push({ date: elem, ...data.data.session[elem] });
    });

    filter_header = ["date", ...new Set(temp__header)];
    header_for_table = filter_header.map((elem) => {
      return {
        Header: elem.toUpperCase(),
        accessor: elem,
      };
    });

    required_dataset = [...new Set(temp__header)];
    final_dtaset = [];
    required_dataset.forEach((elem) => {
      let rgb = `rgba(${Math.random() * 255}, ${Math.random() * 256}, ${
        Math.random() * 256
      }, 0.5)`;

      final_dtaset.push({
        label: elem,
        data: Object.values(data.data.session).map((sin) => sin[elem]),
        borderColor: rgb,
        backgroundColor: rgb,
        borderWidth: 2,
      });
    });
    set_session_data({
      table_header: header_for_table,
      table_data: session_tab_data,
      chart_data: data.data.session,
      chart_dataset: final_dtaset,
    });

    //session and patient
    let ps_tab_ddata = [];
    Object.keys(data.data.total).forEach((sin) => {
      ps_tab_ddata.push({
        date: sin,
        patient: data.data.total[sin].patient,
        session: data.data.total[sin].session,
      });
    });
    set_session_patient({
      table_data: ps_tab_ddata,
      chart_data: data.data.total,
    });
    // let apicall =  [
    //       {
    //         "count": 5,
    //         "date": "April 2023",
    //         "label": "GGNW"
    //       },
    //       {
    //         "count": 15,
    //         "date": "April 2023",
    //         "label": "GGN"
    //       },
    //       {
    //         "count": 25,
    //         "date": "May 2023",
    //         "label": "GGNW"
    //       },
    //       {
    //         "count": 35,
    //         "date": "May 2023",
    //         "label": "GGN"
    //       },
    //       {
    //         "count": 45,
    //         "date": "June 2023",
    //         "label": "GGNW"
    //       },
    //       {
    //         "count": 55,
    //         "date": "June 2023",
    //         "label": "GGN"
    //       },
    //       {
    //         "count": 65,
    //         "date": "July 2023",
    //         "label": "GGNW"
    //       },
    //       {
    //         "count": 75,
    //         "date": "July 2023",
    //         "label": "GGN"
    //       },
    //   ]
    // let tabdata = [];
    // Object.keys(apicall).forEach((element) => {
    //   tabdata.push({
    //     date: element,
    //     no_of_children: apicall[element].no_of_children,
    //     dropout: apicall[element].dropout,
    //     no_of_child_uk: apicall[element].no_of_child_uk,
    //     potential: apicall[element].potential,
    //   });
    // });
    // setData(tabdata);
  };

  useEffect(() => {
    get_report_data();
  }, []);

  const toggleAssignedTab = (idx) => {
    let thistabs = tabs.map((t, index) => {
      if (index === idx) {
        return { ...t, current: true };
      } else {
        return { ...t, current: false };
      }
    });

    set_current_tab(tabs[idx].name);
    setTabs(thistabs);
    if (idx) {
      setShowAssigned(false);
    } else {
      setShowAssigned(true);
    }
  };

  useEffect(() => {
    console.log(
      datefilter.from && datefilter.to,
      datefilter.from,
      datefilter.to,
      "------"
    );

    if (datefilter.from && datefilter.to) {
      get_report_data(datefilter.from, datefilter.to);
    }
  }, [datefilter]);

  const renderChart = (flag) => {
    if (opdReport != "" && flag == "p") {
      return (
        <PatientAndSessionLineChart
          label_chart="Pateint Location wise Chart"
          mydataset={patient_data.chart_dataset}
          cdata={patient_data.chart_data}
          selected={selected}
        />
      );
    } else if (opdReport != "" && flag == "s") {
      return (
        <PatientAndSessionLineChart
          label_chart="Session Location wise Chart"
          mydataset={session_data.chart_dataset}
          cdata={session_data.chart_data}
          selected={selected}
        />
      );
    } else if (opdReport != "" && flag == "ps") {
      return (
        <TotalPatientSession
          label_chart="Total Session and Pateint"
          cdata={session_patient.chart_data}
          selected={selected}
        />
      );
    } else {
      return "";
    }
  };

  const pages = [
    { title: "Patients & Session Report", href: "/patient-and-session-report" },
  ];

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center mb-5">
        <div className="sm:flex-auto">
          <Breadcrumb pages={pages} />
          <h1 className="text-xl font-semibold text-gray-900">
            Patients And Session Report
          </h1>
        </div>
        <div className="sm:grid sm:grid-cols-2    w-max sm:items-start sm:gap-4  sm:border-gray-200 sm:pt-5">
          <div
            className={`mt-1 flex items-center justify-right ${
              user.role !== "DOCTOR" && "grid-cols-2"
            } sm:col-span-2 sm:mt-0`}
          >
            <label>From: </label>
            <input
              type="date"
              name="date"
              // value={(e) => setDate(e.target.value)}
              onChange={(e) => {
                set_date_filter({ ...datefilter, from: e.target.value });
              }}
              placeholder="select date"
              className="mr-2 ml-2 block w-[90%] max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-indigo-500 sm:max-w-xs sm:text-sm"
            />
            <label>To: </label>
            <input
              type="date"
              name="date"
              // value={(e) => setDate(e.target.value)}
              onChange={(e) => {
                set_date_filter({ ...datefilter, to: e.target.value });
              }}
              placeholder="select date"
              className="mr-2 ml-2 block w-[90%] max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-indigo-500 sm:max-w-xs sm:text-sm"
            />

            {/* {errors.type && touched.type ? (
                    <p className="text-red-700">{errors.type}</p>
                  ) : null} */}
          </div>
        </div>
      </div>
      <div className="flex md:flex md:flex-grow flex-row justify-end space-x-1">
        <div className="float-right">
          <div className="hidden sm:block">
            <nav
              className="isolate flex divide-x divide-gray-200 rounded-lg shadow-2xl border"
              aria-label="Tabs"
            >
              {tabs.map((tab, tabIdx) => (
                <a
                  key={tab.name}
                  onClick={() => {
                    toggleAssignedTab(tabIdx);
                  }}
                  className={classNames(
                    tab.current
                      ? "text-gray-900"
                      : "text-gray-500 hover:text-gray-700",
                    tabIdx === 0 ? "rounded-l-lg" : "",
                    tabIdx === tabs.length - 1 ? "rounded-r-lg" : "",
                    "group relative min-w-0 flex-1 overflow-hidden bg-white py-2.5 px-3 text-sm font-medium text-center hover:bg-gray-50 focus:z-10 cursor-pointer"
                  )}
                  aria-current={tab.current ? "page" : undefined}
                >
                  <span>{tab.name}</span>
                  <span
                    aria-hidden="true"
                    className={classNames(
                      tab.current ? "bg-indigo-500" : "bg-transparent",
                      "absolute inset-x-0 bottom-0 h-0.5"
                    )}
                  />
                </a>
              ))}
            </nav>
          </div>
        </div>
      </div>
      {loading ? (
        <FallingLinesLoader />
      ) : (
        <>
          {current_tab == "Patient" ? (
            <>
              <Table
                columns={Patient_And_Session_Report_columns(
                  patient_data.table_header
                )}
                data={patient_data.table_data}
              />
              {renderChart("p")}
            </>
          ) : current_tab == "Session" ? (
            <>
              <Table
                columns={Patient_And_Session_Report_columns(
                  session_data.table_header
                )}
                data={session_data.table_data}
              />
              {renderChart("s")}
            </>
          ) : current_tab == "Total" ? (
            <>
              <Table
                columns={patient_session_total(session_patient.table_header)}
                data={session_patient.table_data}
              />
              {renderChart("ps")}
            </>
          ) : (
            ""
          )}
        </>
      )}
    </div>
  );
};

export default PatientAndSessionReport;
