import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import Modal from "../../../components/Modal";
import Breadcrumb from "../../../components/Breadcrumb";
import { FallingLinesLoader } from "../../../components/spinners/Spinner";
import Table from "../../../components/tables/table";
import { toast } from "react-hot-toast";
import ConfirmationModal from "../../../components/ConfirmationModal";
import Button from "../../../components/Button";
import InputBox from "../../../components/InputBox";
import axios from "axios";
import { getHeaders } from "../../../redux/auth/actions";
import { useDispatch } from "react-redux";
import { EvDepartment_colums } from "../../../components/tables/tableheader";
import moment from "moment";
import { useSelector } from "react-redux";
import { getAllLocations } from "../../../redux/location/actions";
import { getAllDepartments } from "../../../redux/department/actions";
const initialModalState = {
  type: "",
  state: false,
  index: null,
  edit_id: "",
  data: {
    department_id: "",
    evaluation_master_id: "",
    location_id: "",
    cost: "",
    time: "",
  },
};
const api = process.env.REACT_APP_API_URL;
function EvDepartment() {
  const pages = [
    { title: "Evaluation Department", href: "evaluation-department" },
  ];
  const [modal, setModal] = useState(initialModalState);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllDepartments());
    dispatch(getAllLocations());
    getAllEvMasters();
    getAllEvDepartments();
    document.title = "New Horizon | Evaluation Department";
  }, []);
  useEffect(() => {
    if (modal.edit_id !== "") {
      getEvDepartmentById(modal.edit_id);
    }
  }, [modal.edit_id]);

  const [confirmationModal, setConfirmationModal] = useState({
    status: false,
    id: null,
  });
  const [evDepartment, setEvDepartment] = useState({
    loading: false,
    data: [],
  });
  const [evMaster, setEvMaster] = useState({
    loading: false,
    data: [],
  });

  const { list: locationList } = useSelector((state) => state.location);

  const { loading, list: departmentList } = useSelector(
    (state) => state.department
  );

  const getAllEvMasters = async () => {
    const headers = await dispatch(getHeaders());
    setEvMaster((prev) => ({ ...prev, loading: true }));
    try {
      const res = await axios.get(`${api}/api/evaluation-master`, {
        headers: headers,
      });
      const { data, status } = res;
      if (status === 200) {
        setEvMaster((prev) => ({
          ...prev,
          data: data.data,
          loading: false,
        }));
      }
      console.log(res);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const getAllEvDepartments = async () => {
    const headers = await dispatch(getHeaders());
    setEvDepartment((prev) => ({ ...prev, loading: true }));
    try {
      const res = await axios.get(`${api}/api/evaluation-department-master`, {
        headers: headers,
      });
      const { data, status } = res;
      if (status === 200) {
        setEvDepartment((prev) => ({
          ...prev,
          data: data.data,
          loading: false,
        }));
      }
      console.log(res);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const createEvDepartment = async (body) => {
    try {
      const headers = await dispatch(getHeaders());
      const res = await axios.post(
        `${api}/api/evaluation-department-master`,
        body,
        {
          headers: headers,
        }
      );
      const { data, status } = res;
      if (status === 201) {
        getAllEvDepartments();
        toast.success("Evaluation Created Successfully");
        setModal((prev) => ({
          ...prev,
          state: false,
          data: {},
          edit_id: "",
          type: "",
        }));
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const getEvDepartmentById = async (id) => {
    try {
      const headers = await dispatch(getHeaders());
      const res = await axios.get(
        `${api}/api/evaluation-department-master/${id}`,
        {
          headers: headers,
        }
      );
      const { data, status } = res;
      if (status === 200) {
        setModal((prev) => ({
          ...prev,
          type: "edit",
          data: {
            department_id: data.data.department_id,
            evaluation_master_id: data.data.evaluation_master_id,
            location_id: data.data.location_id,
            cost: data.data.cost,
            time: data.data.time,
          },
        }));
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const updateEvDepartment = async (id, body) => {
    try {
      const headers = await dispatch(getHeaders());
      const res = await axios.put(
        `${api}/api/evaluation-department-master/${id}`,
        body,
        {
          headers: headers,
        }
      );
      const { data, status } = res;
      if (status === 200) {
        getAllEvDepartments();
        toast.success("Evaluation Updated Successfully");
        setModal((prev) => ({
          ...prev,
          state: false,
          data: {},
          edit_id: "",
          type: "",
        }));
      }
    } catch (error) {}
  };

  //   const onDeleteHoliday = async (id) => {
  //     const headers = await dispatch(getHeaders());
  //     try {
  //       const res = await axios.delete(`${api}/api/evaluation-department-master/${id}`, {
  //         headers: headers,
  //       });
  //       const { data, status } = res;
  //       if (status === 200) {
  //         getAllEvDepartments();
  //         toast.success("Holiday Deleted Successfully");
  //         setConfirmationModal({ id: null, status: false });
  //       }
  //     } catch (error) {}
  //   };

  const onDeleteOpen = (id) => {
    setConfirmationModal({ id: id, status: true });
  };

  const renderModal = () => {
    const { type, state, data, edit_id } = modal;

    return (
      <Formik
        initialValues={data}
        validationSchema={Yup.object({
          department_id: Yup.string().required("Please Select Department"),
          evaluation_master_id: Yup.string().required(
            "Please Select Evaluation"
          ),
          cost: Yup.string().required("Please enter cost"),
          location_id: Yup.string().required("Please Select Location"),
          time: Yup.string().required("Please enter time"),
        })}
        enableReinitialize={true}
        onSubmit={(values, action) => {
          const body = {
            department_id: Number(values.department_id),
            evaluation_master_id: Number(values.evaluation_master_id),
            cost: Number(values.cost),
            location_id: Number(values.location_id),
            time: values.time,
          };
          if (modal.type === "edit") {
            updateEvDepartment(modal.edit_id, body);
          } else {
            createEvDepartment(body);
            action.resetForm();
          }
          action.setSubmitting(false);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <Modal
            title={`${
              modal.type === "edit" ? "Update" : "Add"
            } Evaluation Department`}
            open={state}
            setOpen={() =>
              setModal((prev) => ({
                ...prev,
                state: false,
                data: {},
                edit_id: "",
                type: "",
              }))
            }
          >
            <form onSubmit={handleSubmit}>
              <div className="text-left mt-4">
                <div className="mt-4"></div>
                <label
                  htmlFor="lab_id"
                  className="block text-sm font-medium text-gray-700"
                >
                  Evaluation Master
                </label>
                <select
                  id="evaluation_master_id"
                  name="evaluation_master_id"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.evaluation_master_id}
                  className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-cyan-500 focus:outline-none focus:ring-cyan-500 sm:text-sm"
                  defaultValue="Canada"
                >
                  <option>Select a Evaluation</option>
                  {evMaster.data.map((ev, idx) => (
                    <option key={idx} value={ev.id}>
                      {ev.name}
                    </option>
                  ))}
                </select>
                {touched.evaluation_master_id && (
                  <p className="text-red-700 error_msg">
                    {errors.evaluation_master_id}
                  </p>
                )}
                <label
                  htmlFor="lab_id"
                  className="block text-sm font-medium text-gray-700"
                >
                  Location
                </label>
                <select
                  id="location_id"
                  name="location_id"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.location_id}
                  className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-cyan-500 focus:outline-none focus:ring-cyan-500 sm:text-sm"
                  defaultValue="Canada"
                >
                  <option>Select a Location</option>
                  {locationList.map((loc, idx) => (
                    <option key={idx} value={loc.id}>
                      {loc.name}
                    </option>
                  ))}
                </select>
                {touched.location_id && (
                  <p className="text-red-700 error_msg">{errors.location_id}</p>
                )}
                <label
                  htmlFor="lab_id"
                  className="block text-sm font-medium text-gray-700"
                >
                  Department
                </label>
                <select
                  id="department_id"
                  name="department_id"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.department_id}
                  className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-cyan-500 focus:outline-none focus:ring-cyan-500 sm:text-sm"
                  defaultValue="Canada"
                >
                  <option>Select a Department</option>
                  {departmentList.map((deprt, idx) => (
                    <option key={idx} value={deprt.id}>
                      {deprt.name}
                    </option>
                  ))}
                </select>
                {touched.department_id && (
                  <p className="text-red-700 error_msg">
                    {errors.department_id}
                  </p>
                )}
                <InputBox
                  name="cost"
                  label="Cost"
                  type="text"
                  value={values.cost}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  required={true}
                  placeholder="Enter Cost"
                />
                {touched.cost && (
                  <p className="text-red-700 error_msg">{errors.cost}</p>
                )}
                <InputBox
                  name="time"
                  label="Time"
                  type="text"
                  value={values.time}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  required={true}
                  placeholder="Enter Time"
                />
                {touched.time && (
                  <p className="text-red-700 error_msg">{errors.time}</p>
                )}
              </div>
              <div className="mt-4"></div>
              <Button disabled={isSubmitting} type="submit">
                {modal.type === "edit" ? "Update" : "Add"} Evaluation Department
              </Button>
            </form>
          </Modal>
        )}
      </Formik>
    );
  };

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <Breadcrumb pages={pages} />
      {renderModal()}
      <ConfirmationModal
        open={confirmationModal.status}
        setOpen={setConfirmationModal}
        // onDelete={() => onDeleteHoliday(confirmationModal.id)}
      />
      <div className="">
        <h1 className="text-xl font-semibold text-gray-900">
          Evaluation Department
        </h1>
      </div>
      <div className="mt-4 flex">
        <button
          type="button"
          onClick={() =>
            setModal((prev) => ({
              ...prev,
              data: {},
              type: "",
              edit_id: "",
              state: true,
            }))
          }
          className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 ml-auto"
        >
          Add
        </button>
      </div>
      {evDepartment.loading ? (
        <FallingLinesLoader />
      ) : (
        <Table
          columns={EvDepartment_colums({ setModal, onDeleteOpen })}
          data={evDepartment.data}
        />
      )}
    </div>
  );
}
export default EvDepartment;
