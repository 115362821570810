/* eslint-disable */
import axios from "axios";
import { getHeaders } from "../auth/actions";
import { toast } from "react-toast";
import {
    GET_ALL_PATIENTS_BEGIN,
    GET_ALL_PATIENTS_SUCCESS,
    GET_ALL_PATIENTS_ERROR,
    ADD_PATIENT_BEGIN,
    ADD_PATIENT_SUCCESS,
    ADD_PATIENT_ERROR,
    GET_PATIENT_BEGIN,
    GET_PATIENT_SUCCESS,
    GET_PATIENT_ERROR,
    UPDATE_PATIENT_BEGIN,
    UPDATE_PATIENT_SUCCESS,
    UPDATE_PATIENT_ERROR,
    DELETE_PATIENT_BEGIN,
    DELETE_PATIENT_SUCCESS,
    DELETE_PATIENT_ERROR,
} from "./constants";

const API = process.env.REACT_APP_API_URL;

export const getAllPatients = (limit) => async(dispatch) => {
    try {
        dispatch({
            type: GET_ALL_PATIENTS_BEGIN,
        });
        const headers = dispatch(getHeaders());
        let url = `${API}/api/patient`;
        if (limit) url += `?limit=${limit}`;
        const { data } = await axios.get(url, {
            headers,
        });
        console.log(data.data);

        dispatch({
            type: GET_ALL_PATIENTS_SUCCESS,
            data: data.data,
        });
    } catch (error) {
        const msg = error.response.data.message;
        toast.error(msg);
        dispatch({
            type: GET_ALL_PATIENTS_ERROR,
            error: msg,
        });
    }
};

export const addPatient = (body) => async(dispatch) => {
    try {
        dispatch({
            type: ADD_PATIENT_BEGIN,
        });
        const headers = dispatch(getHeaders());
        const { data } = await axios.post(`${API}/api/patient`, body, { headers });
        console.log(data.data);
        toast.success("Patient added successfully!");
        dispatch({
            type: ADD_PATIENT_SUCCESS,
            data: data.data,
        });
    } catch (error) {
        const msg = error.response.data.message;
        toast.error(msg);
        dispatch({
            type: ADD_PATIENT_ERROR,
            error: msg,
        });
    }
};

export const getPatientWithId = (id) => async(dispatch) => {
    try {
        dispatch({
            type: GET_PATIENT_BEGIN,
        });
        const headers = dispatch(getHeaders());
        const { data } = await axios.get(`${API}/api/patient/${id}`, { headers });
        console.log(data.data);

        dispatch({
            type: GET_PATIENT_SUCCESS,
            data: data.data,
        });
    } catch (error) {
        const msg = error.response.data.message;
        toast.error(msg);
        dispatch({
            type: GET_PATIENT_ERROR,
            error: msg,
        });
    }
};

export const updatePatient = (id, body) => async(dispatch) => {
    try {
        dispatch({
            type: UPDATE_PATIENT_BEGIN,
        });
        const headers = dispatch(getHeaders());
        const { data } = await axios.put(`${API}/api/patient/${id}`, body, {
            headers,
        });
        console.log(data.data);
        toast.success("Patient updated successfully!");
        dispatch({
            type: UPDATE_PATIENT_SUCCESS,
            data: data.data,
        });
    } catch (error) {
        const msg = error.response.data.message;
        toast.error(msg);
        dispatch({
            type: UPDATE_PATIENT_ERROR,
            error: msg,
        });
    }
};

export const deletePatient = (id, body) => async(dispatch) => {
    try {
        dispatch({
            type: DELETE_PATIENT_BEGIN,
        });
        const headers = dispatch(getHeaders());
        const { data } = await axios.delete(`${API}/api/patient/${id}`, {
            headers,
        });
        console.log(data.data);
        toast.success("Patient deleted successfully!");
        dispatch({
            type: DELETE_PATIENT_SUCCESS,
            data: data.data,
        });
    } catch (error) {
        console.log(error);
        const msg = error.response.data.message;
        toast.error(msg);
        dispatch({
            type: DELETE_PATIENT_ERROR,
            error: msg,
        });
    }
};