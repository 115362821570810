import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import Modal from "../../../components/Modal";
import Breadcrumb from "../../../components/Breadcrumb";
import { FallingLinesLoader } from "../../../components/spinners/Spinner";
import Table from "../../../components/tables/table";
import { toast } from "react-hot-toast";
import ConfirmationModal from "../../../components/ConfirmationModal";
import Button from "../../../components/Button";
import InputBox from "../../../components/InputBox";
import axios from "axios";
import { getHeaders } from "../../../redux/auth/actions";
import { useDispatch } from "react-redux";
import { PlanDashboardLog_colums } from "../../../components/tables/tableheader";
import moment from "moment";

const api = process.env.REACT_APP_API_URL;
function PlanDashboardLog() {
  const pages = [{ title: "Plan Dashboard Log", href: "/plan-dashboard" }];
  const [query, setQuery] = useState(200);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    getAllPlaners(100);
    // getAllStorageLocations();
    document.title = "New Horizon | Plan Dashboard Log";
  }, []);

  const [confirmationModal, setConfirmationModal] = useState({
    status: false,
    id: null,
    body: {},
  });
  const [rconfirmationModal, setrConfirmationModal] = useState({
    status: false,
    id: null,
    body: {},
  });
  const [plannerList, setPlanerList] = useState({
    loading: false,
    data: [],
    filterData: [],
  });

  const getAllPlaners = async (limit) => {
    const headers = await dispatch(getHeaders());
    setPlanerList((prev) => ({ ...prev, loading: true }));
    try {
      const res = await axios.get(
        `${api}/api/plan-builder/plan/dashboard?limit=${limit}`,
        {
          headers: headers,
        }
      );
      const { data, status } = res;
      if (data) {
        setPlanerList((prev) => ({
          ...prev,
          data: data.data,
          filterData: data.data,
          loading: false,
        }));
      }
      console.log(res);
    } catch (error) {
      toast.error(error.message);
    }
  };

  //   const createHoliday = async (body) => {
  //     try {
  //       const headers = await dispatch(getHeaders());
  //       const res = await axios.post(`${api}/api/holiday-master`, body, {
  //         headers: headers,
  //       });
  //       const { data, status } = res;
  //       if (status === 201) {
  //         getAllPlaners();
  //         toast.success("Holiday Created Successfully");
  //         setModal((prev) => ({
  //           ...prev,
  //           state: false,
  //           data: {},
  //           edit_id: "",
  //           type: "",
  //         }));
  //       }
  //     } catch (error) {
  //       toast.error(error.message);
  //     }
  //   };

  //   const getHolidayById = async (id) => {
  //     try {
  //       const headers = await dispatch(getHeaders());
  //       const res = await axios.get(`${api}/api/holiday-master/${id}`, {
  //         headers: headers,
  //       });
  //       const { data, status } = res;
  //       if (status === 200) {
  //         setModal((prev) => ({
  //           ...prev,
  //           type: "edit",
  //           data: {
  //             name: data.data.name,
  //             date: data.data.date,
  //           },
  //         }));
  //       }
  //     } catch (error) {
  //       toast.error(error.message);
  //     }
  //   };

  //   const updateHoliday = async (id, body) => {
  //     try {
  //       const headers = await dispatch(getHeaders());
  //       const res = await axios.put(`${api}/api/holiday-master/${id}`, body, {
  //         headers: headers,
  //       });
  //       const { data, status } = res;
  //       if (status === 200) {
  //         getAllPlaners();
  //         toast.success("Holiday Updated Successfully");
  //         setModal((prev) => ({
  //           ...prev,
  //           state: false,
  //           data: {},
  //           edit_id: "",
  //           type: "",
  //         }));
  //       }
  //     } catch (error) {}
  //   };
  const onDeleteOpen = (id) => {
    setConfirmationModal({ id: id, status: true });
  };

  const onReuse = async (body) => {
    const headers = await dispatch(getHeaders());
    try {
      const res = await axios.post(`${api}/api/plan-builder/reuse-plan`, body, {
        headers: headers,
      });
      const { data, status } = res;
      if (data) {
        getAllPlaners();
        toast.success("Plan Reused Successfully");
        setConfirmationModal({ id: null, status: false, body: {} });
      }
    } catch (error) { }
  };
  const onRquisit = async (body) => {
    const headers = await dispatch(getHeaders());
    try {
      const res = await axios.post(
        `${api}/api/plan-builder/requisition-plan`,
        body,
        {
          headers: headers,
        }
      );
      const { data, status } = res;
      if (data) {
        getAllPlaners();
        toast.success(data.message);
        setrConfirmationModal({ id: null, status: false, body: {} });
      }
    } catch (error) { }
  };

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <Breadcrumb pages={pages} />
      <ConfirmationModal
        description="Do you really want to Reuse The earlier Plan?"
        confirmationButtonText="Reuse"
        confirmationButtonColor="indigo"
        open={confirmationModal.status}
        setOpen={setConfirmationModal}
        onDelete={() => onReuse(confirmationModal.body)}
      />
      <ConfirmationModal
        description="Do you really want to requisition this plan?"
        confirmationButtonText="requisition"
        confirmationButtonColor="indigo"
        open={rconfirmationModal.status}
        setOpen={setrConfirmationModal}
        onDelete={() => onRquisit(rconfirmationModal.body)}
      />
      <div className="">
        <h1 className="text-xl font-semibold text-gray-900">
          Plan Dashboard Logs
        </h1>
      </div>
      <select
        name=""
        id=""
        className=" rounded-md "
        onChange={(e) => {
          if (e.target.value === "all") {
            setPlanerList((prev) => ({
              ...prev,
              filterData: prev.data,
            }));
          } else if (e.target.value === "M3") {
            setPlanerList((prev) => ({
              ...prev,
              filterData: prev.data.filter(
                (item) => item.program_number.split(" ")[1] === "M-3"
              ),
            }));
          }
        }}
      >
        <option value="all">All</option>
        <option value="M3">M-3 Patients</option>
      </select>
      <div className="mt-4 flex"></div>
      {plannerList.loading ? (
        <FallingLinesLoader />
      ) : (
        <Table
          columns={PlanDashboardLog_colums({
            onDeleteOpen,
            setConfirmationModal,
            setrConfirmationModal,
          })}
          data={plannerList.filterData}
          fetch={async (w) => {
            setQuery((prev) => w);

            await getAllPlaners(w);
          }}
          decrease={async () => {
            if (query > 200) {
              setQuery((prev) => prev - 200);

              await getAllPlaners(query);
            }
          }}
        />
      )}
    </div>
  );
}
export default PlanDashboardLog;
