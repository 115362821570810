import {
  GET_ALL_PAYMENT_BEGIN,
  GET_ALL_PAYMENT_SUCCESS,
  GET_ALL_PAYMENT_ERROR,
  SEND_PAYMENT_LINK_BEGIN,
  SEND_PAYMENT_LINK_SUCCESS,
  SEND_PAYMENT_LINK_ERROR,
  MARK_PAYMENT_PAID_BEGIN,
  MARK_PAYMENT_PAID_SUCCESS,
  MARK_PAYMENT_PAID_ERROR,
  SEND_INVOICE_LINK_ERROR,
  SEND_INVOICE_LINK_BEGIN,
  SEND_INVOICE_LINK_SUCCESS,
} from "./constants";

const initState = {
  list: [],
  loading: false,
  error: null,
};

const PaymentReducer = (state = initState, action) => {
  const { type, data, error } = action;
  switch (type) {
    case GET_ALL_PAYMENT_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case GET_ALL_PAYMENT_SUCCESS:
      return {
        ...state,
        list: data,
        loading: false,
      };
    case GET_ALL_PAYMENT_ERROR:
      return {
        ...state,
        error,
        loading: false,
      };
    case SEND_PAYMENT_LINK_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case SEND_PAYMENT_LINK_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case SEND_PAYMENT_LINK_ERROR:
      return {
        ...state,
        error,
        loading: false,
      };
    case MARK_PAYMENT_PAID_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case MARK_PAYMENT_PAID_SUCCESS:
      return {
        ...state,
        loading: false,
        list: [...state.list, data],
      };
    case MARK_PAYMENT_PAID_ERROR:
      return {
        ...state,
        error,
        loading: false,
      };
    case SEND_INVOICE_LINK_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case SEND_INVOICE_LINK_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case SEND_INVOICE_LINK_ERROR:
      return {
        ...state,
        error,
        loading: false,
      };
    default:
      return state;
  }
};

export default PaymentReducer;
