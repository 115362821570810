import {
    GET_ALL_GOAL_BEGIN,
    GET_ALL_GOAL_SUCCESS,
    GET_ALL_GOAL_ERROR,
    ADD_GOAL_BEGIN,
    ADD_GOAL_SUCCESS,
    ADD_GOAL_ERROR,
    GET_GOAL_BEGIN,
    GET_GOAL_ERROR,
    GET_GOAL_SUCCESS,
    UPDATE_GOAL_BEGIN,
    UPDATE_GOAL_SUCCESS,
    UPDATE_GOAL_ERROR,
    DELETE_GOAL_BEGIN,
    DELETE_GOAL_SUCCESS,
    DELETE_GOAL_ERROR,
  } from "./constants";
  
  const initState = {
    list: [],
    loading: false,
    error: null,
  };
  
  const GoalReducer = (state = initState, action) => {
    const { type, data, error } = action;
    switch (type) {
      case GET_ALL_GOAL_BEGIN:
        return {
          ...state,
          loading: true,
        };
      case GET_ALL_GOAL_SUCCESS:
        return {
          ...state,
          list: data,
          loading: false,
        };
      case GET_ALL_GOAL_ERROR:
        return {
          ...state,
          error,
          loading: false,
        };
      case ADD_GOAL_BEGIN:
        return {
          ...state,
          loading: true,
        };
      case ADD_GOAL_SUCCESS:
        return {
          ...state,
          loading: false,
          list: [...state.list, data],
        };
      case ADD_GOAL_ERROR:
        return {
          ...state,
          error,
          loading: false,
        };
      case GET_GOAL_BEGIN:
        return {
          ...state,
          loading: true,
          goalData: "",
        };
      case GET_GOAL_SUCCESS:
        return {
          ...state,
          loading: false,
          goalData: data,
        };
      case GET_GOAL_ERROR:
        return {
          ...state,
          error,
          loading: false,
        };
      case UPDATE_GOAL_BEGIN:
        return {
          ...state,
          loading: true,
        };
      case UPDATE_GOAL_SUCCESS:
        return {
          ...state,
          loading: false,
          list: state.list.map((ele) => {
            if (ele.id === data.id) {
              return { ...data };
            } else return ele;
          }),
        };
      case UPDATE_GOAL_ERROR:
        return {
          ...state,
          error,
          loading: false,
        };
      case DELETE_GOAL_BEGIN:
        return {
          ...state,
          loading: true,
        };
      case DELETE_GOAL_SUCCESS:
        return {
          ...state,
          loading: false,
          list: state.list.filter((ele) => ele.id !== data.id),
        };
      case DELETE_GOAL_ERROR:
        return {
          ...state,
          error,
          loading: false,
        };
      default:
        return state;
    }
  };
  
  export default GoalReducer;
  