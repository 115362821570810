import React from 'react'
import Breadcrumb from "../../../components/Breadcrumb";
import Table from '../../../components/tables/table';
import { userGroup_columns } from '../../../components/tables/tableheader';
import { Link } from 'react-router-dom';

const groupList = [
    {
        ug_id: 1,
        ug_name: "UG-01",
        ug_created_at: "2022-12-19T11:50:13.151Z",
        ug_updated_at: "2022-12-19T11:50:13.151Z",
        ug_is_active: "Y",
        ug_is_deleted: "N",
        ug_users: [
            {
                user_id: 1,
                user_email: "abhijit@enpointe.io"
            },
            {
                user_id: 3,
                user_email: "priyanshu@enpointe.io"
            }
        ],
        ug_u_oids: "",
        ug_creator_id: 2
    },
    {
        ug_id: 2,
        ug_name: "UG-02",
        ug_created_at: "2022-12-19T11:52:13.304Z",
        ug_updated_at: "2022-12-19T11:52:13.304Z",
        ug_is_active: "Y",
        ug_is_deleted: "N",
        ug_users: [
            {
                user_id: 4,
                user_email: "sanath@enpointe.io"
            },
            {
                user_id: 3,
                user_email: "priyanshu@enpointe.io"
            }
        ],
        ug_u_oids: "",
        ug_creator_id: 1
    },
    {
        ug_id: 3,
        ug_name: "UG-03",
        ug_created_at: "2022-12-19T11:53:06.220Z",
        ug_updated_at: "2022-12-19T11:53:06.220Z",
        ug_is_active: "Y",
        ug_is_deleted: "N",
        ug_users: [
            {
                user_id: 1,
                user_email: "abhijit@enpointe.io"
            },
            {
                user_id: 2,
                user_email: "ajay@enpointe.io"
            }
        ],
        ug_u_oids: "",
        ug_creator_id: 4
    },
    {
        ug_id: 4,
        ug_name: "UG-23",
        ug_created_at: "2022-12-21T13:42:46.664Z",
        ug_updated_at: "2022-12-21T13:42:46.664Z",
        ug_is_active: "Y",
        ug_is_deleted: "N",
        ug_users: [
            {
                user_id: 2,
                user_email: "ajay@enpointe.io"
            },
            {
                user_id: 5,
                user_email: "abhijit@gmail.com"
            }
        ],
        ug_u_oids: "",
        ug_creator_id: 4
    },
    {
        ug_id: 5,
        ug_name: "UG-21",
        ug_created_at: "2022-12-21T13:44:33.364Z",
        ug_updated_at: "2022-12-21T13:44:33.364Z",
        ug_is_active: "Y",
        ug_is_deleted: "N",
        ug_users: [
            {
                user_id: 2,
                user_email: "ajay@enpointe.io"
            },
            {
                user_id: 4,
                user_email: "sanath@enpointe.io"
            },
            {
                user_id: 17,
                user_email: "user32@gmail.com"
            }
        ],
        ug_u_oids: "2,4,17",
        ug_creator_id: 4
    },
    {
        ug_id: 6,
        ug_name: "UG-001",
        ug_created_at: "2022-12-21T13:51:46.400Z",
        ug_updated_at: "2022-12-21T13:51:46.400Z",
        ug_is_active: "Y",
        ug_is_deleted: "N",
        ug_users: [
            {
                user_id: 2,
                user_email: "ajay@enpointe.io"
            },
            {
                user_id: 1,
                user_email: "abhijit1@enpointe.io"
            },
            {
                user_id: 4,
                user_email: "sanath@enpointe.io"
            }
        ],
        ug_u_oids: "2,1,4",
        ug_creator_id: 4
    },
    {
        ug_id: 7,
        ug_name: "UG-002",
        ug_created_at: "2022-12-21T13:53:52.078Z",
        ug_updated_at: "2022-12-21T13:53:52.078Z",
        ug_is_active: "Y",
        ug_is_deleted: "N",
        ug_users: [
            {
                user_id: 1,
                user_email: "abhijit1@enpointe.io"
            },
            {
                user_id: 3,
                user_email: "priyanshu@enpointe.io"
            }
        ],
        ug_u_oids: "1,3",
        ug_creator_id: 4
    }
]


const UserGroupList = () => {
    const pages = [{ title: "UserGroup", href: "/user-group" }];

    return (
        <div>
            <Breadcrumb pages={pages} />
            <div className="px-4 sm:px-6 lg:px-8">
                <div className="sm:flex sm:items-center">
                    <div>
                        <h1 className="text-xl font-semibold text-gray-900">User Group</h1>
                        <p className="mt-1 max-w-2xl text-sm text-gray-500">
                            A list of all group the patient Invoices in your account including their name,
                            title, email and role.
                        </p>
                    </div>
                    <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                        <Link
                            to="/add-user-group"
                            type="button"
                            className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
                        >
                            Add User Group
                        </Link>
                    </div>
                </div>
                <Table
                    columns={userGroup_columns()}
                    data={groupList}
                // onEditClose={cleanModalData}
                />
            </div>
        </div>
    )
}

export default UserGroupList