import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { deleteProgram,getAllPrograms } from "../../../redux/ProgramMaster/actions";
import Pagination from "../../../components/Pagination";
import { FallingLinesLoader } from "../../../components/spinners/Spinner";
import Breadcrumb from "../../../components/Breadcrumb";
import ConfirmationModal from "../../../components/ConfirmationModal";

import Table from "../../../components/tables/table";
import {  program_master_columns } from '../../../components/tables/tableheader'

const ProgramMaster = () => {
  const pages = [{ title: "Program", href: "/program-master" }];
  const [confirmationModal, setConfirmationModal] = useState({
    status: false,
    id: null,
  });
  const dispatch = useDispatch();
  const { loading, list: programList } = useSelector(
    (state) => state.programMaster
  );

  useEffect(() => {
    document.title = "New Horizon | Program";
    dispatch(getAllPrograms());
  }, []);

  const onDeleteOpen = (id) => {
    setConfirmationModal({ id, status: true });
  };

  const onDeleteProgram = (id) => {
    dispatch(deleteProgram(id));
    setConfirmationModal({ id:null, status: false });
  };
  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <ConfirmationModal 
          open={confirmationModal.status}
          setOpen={setConfirmationModal}
          onDelete={() => onDeleteProgram(confirmationModal.id)}
      />
    <Breadcrumb pages={pages} />
    <div className="sm:flex sm:items-center mb-5">
      <div className="sm:flex-auto">
        <h1 className="text-xl font-semibold text-gray-900">Program Master</h1>
      </div>
      <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
        <Link
          to="/add-program"
          type="button"
          className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
        >
          Add Program
        </Link>
      </div>
    </div>
      {loading ? (
        <FallingLinesLoader />
      ) : (
        <Table
        columns={program_master_columns({onDeleteOpen})}
        data={programList}
      />
      )}
    </div>
  )
}

export default ProgramMaster
