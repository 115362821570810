import axios from "axios";
import { getHeaders } from "../auth/actions";
import { toast } from "react-hot-toast";
import {
  GET_ALL_PATIENT_RECOMMANDATION_BEGIN,
  GET_ALL_PATIENT_RECOMMANDATION_SUCCESS,
  GET_ALL_PATIENT_RECOMMANDATION_ERROR,
  GET__PATIENT_RECOMMANDATION_WITH_ID_BEGIN,
  GET__PATIENT_RECOMMANDATION_WITH_ID_SUCCESS,
  GET__PATIENT_RECOMMANDATION_WITH_ID_ERROR,
  CLEAR_PATIEN,
  CLEAR_PATIENT_RECOMMENDATION,
  GET_PATIENT_RECOMMENDATION_DEPARTMENTS_BEGIN,
  GET_PATIENT_RECOMMENDATION_DEPARTMENTS_ERROR,
  GET_PATIENT_RECOMMENDATION_DEPARTMENTS_SUCCESS,
  CLEAR_PATIENT_RECOMMENDATION_DEPARTMENTS,
  CHANGE_ACTIVE_DEPARTMENT,
  // TOGGEL_FOLLOW_UP_BEGIN,
  // TOGGEL_FOLLOW_UP_SUCCESS,
  // TOGGEL_FOLLOW_UP_ERROR,
} from "./constants";
const API = process.env.REACT_APP_API_URL;

export const getAllPatientRecommandation = (limit) => async (dispatch) => {
  try {
    dispatch({
      type: GET_ALL_PATIENT_RECOMMANDATION_BEGIN,
    });
    const headers = dispatch(getHeaders());
    const { data } = await axios.get(
      `${API}/api/program/patient-recommendation?limit=${limit}`,
      { headers }
    );
    console.log(data.data);

    dispatch({
      type: GET_ALL_PATIENT_RECOMMANDATION_SUCCESS,
      data: data.data,
    });
  } catch (error) {
    const msg = error.response.data.message;
    toast.error(msg);
    console.log(error);
    dispatch({
      type: GET_ALL_PATIENT_RECOMMANDATION_ERROR,
      error: msg,
    });
  }
};

export const getPatientRecommandationWithId = (id) => async (dispatch) => {
  try {
    dispatch({
      type: GET__PATIENT_RECOMMANDATION_WITH_ID_BEGIN,
    });
    const headers = dispatch(getHeaders());
    const { data } = await axios.get(
      `${API}/api/program/patient-recommendation/get-recommended-program/${id}`,
      { headers }
    );
    console.log(data.data);

    dispatch({
      type: GET__PATIENT_RECOMMANDATION_WITH_ID_SUCCESS,
      data: data.data,
    });
  } catch (error) {
    const msg = error.response.data.message;
    toast.error(msg);
    console.log(error);
    dispatch({
      type: GET__PATIENT_RECOMMANDATION_WITH_ID_ERROR,
      error: msg,
    });
  }
};

export const clearPatientRecommendation = () => (dispatch) => {
  dispatch({ type: CLEAR_PATIENT_RECOMMENDATION });
};

export const getDepartmentsByProgramId = (id) => async (dispatch) => {
  try {
    dispatch({
      type: GET_PATIENT_RECOMMENDATION_DEPARTMENTS_BEGIN,
    });
    const headers = dispatch(getHeaders());
    const { data } = await axios.get(
      `${API}/api/program/patient-recommendation/get-program-department/${id}`,
      { headers }
    );

    dispatch({
      type: GET_PATIENT_RECOMMENDATION_DEPARTMENTS_SUCCESS,
      data: data.data,
    });
  } catch (error) {
    const msg = error.response.data.message;
    toast.error(msg);
    dispatch({
      type: GET_PATIENT_RECOMMENDATION_DEPARTMENTS_ERROR,
      error: msg,
    });
  }
};

export const clearDepartment = () => (dispatch) => {
  dispatch({ type: CLEAR_PATIENT_RECOMMENDATION_DEPARTMENTS });
};

export const changeActiveDepartmentTab = (id) => (dispatch) => {
  dispatch({ type: CHANGE_ACTIVE_DEPARTMENT, data: id });
};

// export const toggleFollowUp = (id) => async (dispatch) => {
//   try {
//     dispatch({
//       type: TOGGEL_FOLLOW_UP_BEGIN,
//     });
//     const headers = dispatch(getHeaders());
//     const { data } = await axios.get(
//       `${API}/api/program/patient-recommendation/toggle-follow-up/${id}`,
//       { headers }
//     );
//     console.log(data.data);

//     dispatch({
//       type: TOGGEL_FOLLOW_UP_SUCCESS,
//       data: data.data,
//     });
//   } catch (error) {
//     const msg = error.response.data.message;
//     toast.error(msg);
//     console.log(error);
//     dispatch({
//       type: TOGGEL_FOLLOW_UP_ERROR,
//       error: msg,
//     });
//   }
// };
