import axios from "axios";
import { getHeaders } from "../auth/actions";
import { toast } from "react-hot-toast";
import {
  GET_ALL_PAYMENT_BEGIN,
  GET_ALL_PAYMENT_SUCCESS,
  GET_ALL_PAYMENT_ERROR,
  SEND_PAYMENT_LINK_BEGIN,
  SEND_PAYMENT_LINK_SUCCESS,
  SEND_PAYMENT_LINK_ERROR,
  MARK_PAYMENT_PAID_BEGIN,
  MARK_PAYMENT_PAID_SUCCESS,
  MARK_PAYMENT_PAID_ERROR,
  SEND_INVOICE_LINK_BEGIN,
  SEND_INVOICE_LINK_SUCCESS,
  SEND_INVOICE_LINK_ERROR,
} from "./constants";

// const API = "http://newhorizon-api.theharsh.me/api/appointment-payment"

const API = process.env.REACT_APP_API_URL;

export const getAllPayments = (limit) => async (dispatch) => {
  try {
    dispatch({
      type: GET_ALL_PAYMENT_BEGIN,
    });
    const headers = dispatch(getHeaders());
    const { data } = await axios.get(
      `${API}/api/appointment-payment?limit=${limit}`,
      {
        headers,
      }
    );
    console.log(data.data);

    dispatch({
      type: GET_ALL_PAYMENT_SUCCESS,
      data: data.data,
    });
  } catch (error) {
    const msg = error.response.data.message;
    toast.error(msg);
    dispatch({
      type: GET_ALL_PAYMENT_ERROR,
      error: msg,
    });
  }
};
export const sendPaymentsLink = (id) => async (dispatch) => {
  try {
    dispatch({
      type: SEND_PAYMENT_LINK_BEGIN,
    });
    const headers = dispatch(getHeaders());
    const { data } = await axios.get(
      `${API}/api/appointment-payment/send-payment-link/${id}`,
      { headers }
    );

    console.log(data.data);

    dispatch({
      type: SEND_PAYMENT_LINK_SUCCESS,
      data: data.data,
    });
    toast.success("Payment Link sent successfully!");
  } catch (error) {
    const msg = error.response.data.message;
    toast.error(msg);
    dispatch({
      type: SEND_PAYMENT_LINK_ERROR,
      error: msg,
    });
  }
};

export const markPaymentPaid = (body) => async (dispatch) => {
  try {
    dispatch({
      type: MARK_PAYMENT_PAID_BEGIN,
    });
    const headers = dispatch(getHeaders());
    const { data } = await axios.post(
      `${API}/api/appointment-payment/update-payment`,
      body,
      { headers }
    );
    console.log(data.data);
    toast.success("Payment Marked as Paid !");
    dispatch({
      type: MARK_PAYMENT_PAID_SUCCESS,
      data: data.data,
    });
  } catch (error) {
    console.log(error.response);
    const msg = error.response.data.message;
    toast.error(msg);
    dispatch({
      type: MARK_PAYMENT_PAID_ERROR,
      error: msg,
    });
  }
};

export const sendInvoiceSmsLink = (id) => async (dispatch) => {
  try {
    dispatch({
      type: SEND_INVOICE_LINK_BEGIN,
    });
    const headers = dispatch(getHeaders());
    const { data } = await axios.get(
      `${API}/api/appointment-payment/send-sms-invoice/${id}`,
      { headers }
    );

    console.log(data.data);

    dispatch({
      type: SEND_INVOICE_LINK_SUCCESS,
      data: data.data,
    });
    toast.success("Invoice Link sent successfully!");
  } catch (error) {
    const msg = error.response.data.message;
    toast.error(msg);
    dispatch({
      type: SEND_INVOICE_LINK_ERROR,
      error: msg,
    });
  }
};
