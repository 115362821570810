import React, { useEffect, useState } from "react";
import Table from "../../../../components/tables/table";
import { opd_Report_columns } from "../../../../components/tables/tableheader";
import { useDispatch, useSelector } from "react-redux";
import { FallingLinesLoader } from "../../../../components/spinners/Spinner";
import { getOpdAppointmentWithId } from "../../../../redux/opdreport/action";
import { getAllDoctors } from "../../../../redux/doctor/actions";
import moment from "moment";
import { async } from "q";
import axios from "axios";
import { toast } from "react-hot-toast";
import OPDLineChart from "./opdLine";
import Breadcrumb from "../../../../components/Breadcrumb";
import { getHeaders } from "../../../../redux/auth/actions";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const OPDReport = () => {
  const [selected, setselected] = useState("all");
  const [date, setDate] = useState("");
  const dispatch = useDispatch();
  const { loading, list: doctor } = useSelector((state) => state.doctor);
  const { list: opdReport } = useSelector((state) => state.opdAppointment);
  const [data, setData] = useState(opdReport);
  const [showAssigned, setShowAssigned] = useState(true);
  const [onlineOPD, setOnlineOPD] = useState([]);
  const [offlineOPD, setOfflineOPD] = useState([]);
  const [currentTab, setcurrentTab] = useState("Online");
  const [datefilter, set_date_filter] = useState({
    from: moment().subtract(3, "month").format("YYYY-MM-DD"),
    to: moment().add(1, "year").format("YYYY-MM-DD"),
    doctor_id: null,
  });

  const [tabs, setTabs] = useState([
    { val: 0, name: "Online", href: "#", current: true },
    { val: 1, name: "Offline", href: "#", current: false },
  ]);

  const API = process.env.REACT_APP_API_URL;

  const { user } = useSelector((state) => state.auth);
  let tabName = "Online";

  useEffect(() => {
    document.title = "New Horizon | OPD report";
    dispatch(getAllDoctors());
    dispatch(getOpdAppointmentWithId(selected));
  }, [selected]);

  const get_report_data = async (
    from = moment().subtract(3, "month").format("YYYY-MM-DD"),
    to = moment().add(1, "year").format("YYYY-MM-DD"),
    doctor_id = null
  ) => {
    const headers = dispatch(getHeaders());
    const response = await axios.post(
      `${API}/api/reporting/opd`,
      { from, to, doctor_id },
      {
        headers,
      }
    );
    //patient data
    const { data } = response;
    let apicall;
    if (currentTab == "Online") {
      apicall = data.data.online;
    } else {
      apicall = data.data.offline;
    }
    let tabdata = [];
    Object.keys(apicall).forEach((element) => {
      tabdata.push({
        date: element,
        followup: apicall[element].followup,
        new: apicall[element].new,
      });
    });
    setData([...tabdata]);
  };

  useEffect(() => {
    get_report_data(datefilter.from, datefilter.to, datefilter.doctor_id);
  }, [tabs]);
  useEffect(() => {
    get_report_data();
  }, []);
  const toggleAssignedTab = (idx) => {
    let thistabs = tabs.map((t, index) => {
      if (index === idx) {
        return { ...t, current: true };
      } else {
        return { ...t, current: false };
      }
    });
    setcurrentTab(tabs[idx].name);
    setTabs(thistabs);

    if (idx) {
      setShowAssigned(false);
    } else {
      setShowAssigned(true);
    }
  };

  useEffect(() => {
    console.log(
      datefilter,
      (datefilter.from && datefilter.to) || datefilter.doctor_id
    );
    if ((datefilter.from && datefilter.to) || datefilter.doctor_id) {
      get_report_data(datefilter.from, datefilter.to, datefilter.doctor_id);
    }
  }, [datefilter]);

  const renderChart = () => {
    if (opdReport != "") {
      return (
        <OPDLineChart
          cdata={data}
          selected={selected}
          tabName={tabs.find((l) => l.current == true).name}
        />
      );
    } else {
      return "";
    }
  };

  const pages = [{ title: "OPD Report", href: "/opdreport" }];

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center mb-5">
        <div className="sm:flex-auto">
          <Breadcrumb pages={pages} />
          <h1 className="text-xl font-semibold text-gray-900">OPD Report</h1>
        </div>
        <div className="sm:grid sm:grid-cols-2    w-max sm:items-start sm:gap-4  sm:border-gray-200 sm:pt-5">
          <div
            className={`mt-1 flex items-center justify-right ${
              user.role !== "DOCTOR" && "grid-cols-2"
            } sm:col-span-2 sm:mt-0`}
          >
            <label>From: </label>
            <input
              type="date"
              name="date"
              // value={(e) => setDate(e.target.value)}
              onChange={(e) =>
                set_date_filter({ ...datefilter, from: e.target.value })
              }
              placeholder="select date"
              className="mr-2 ml-2 block w-[90%] max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-indigo-500 sm:max-w-xs sm:text-sm"
            />
            <label>To: </label>
            <input
              type="date"
              name="date"
              // value={(e) => setDate(e.target.value)}
              onChange={(e) =>
                set_date_filter({ ...datefilter, to: e.target.value })
              }
              placeholder="select date"
              className="mr-2 ml-2 block w-[90%] max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-indigo-500 sm:max-w-xs sm:text-sm"
            />
            {user.role === "DOCTOR" ? null : (
              <select
                id="type"
                name="type"
                defaultValue={selected}
                onChange={(e) => {
                  set_date_filter({ ...datefilter, doctor_id: e.target.value });
                }}
                // value={(e)=>setselected(e.target.value)}
                className="block ml-1 w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-indigo-500 sm:max-w-xs sm:text-sm"
              >
                <option value="">Select a Doctor</option>

                {doctor.map((d, idx) => (
                  <option key={idx} value={d.id}>
                    {"Dr. " + d.first_name + " " + d.last_name}
                  </option>
                ))}
              </select>
            )}
            {/* {errors.type && touched.type ? (
                    <p className="text-red-700">{errors.type}</p>
                  ) : null} */}
          </div>
        </div>
      </div>
      <div className="flex md:flex md:flex-grow flex-row justify-end space-x-1">
        <div className="float-right">
          <div className="hidden sm:block">
            <nav
              className="isolate flex divide-x divide-gray-200 rounded-lg shadow-2xl border"
              aria-label="Tabs"
            >
              {tabs.map((tab, tabIdx) => (
                <a
                  key={tab.name}
                  onClick={() => {
                    toggleAssignedTab(tabIdx);
                  }}
                  className={classNames(
                    tab.current
                      ? "text-gray-900"
                      : "text-gray-500 hover:text-gray-700",
                    tabIdx === 0 ? "rounded-l-lg" : "",
                    tabIdx === tabs.length - 1 ? "rounded-r-lg" : "",
                    "group relative min-w-0 flex-1 overflow-hidden bg-white py-2.5 px-3 text-sm font-medium text-center hover:bg-gray-50 focus:z-10 cursor-pointer"
                  )}
                  aria-current={tab.current ? "page" : undefined}
                >
                  <span>{tab.name}</span>
                  <span
                    aria-hidden="true"
                    className={classNames(
                      tab.current ? "bg-indigo-500" : "bg-transparent",
                      "absolute inset-x-0 bottom-0 h-0.5"
                    )}
                  />
                </a>
              ))}
            </nav>
          </div>
        </div>
      </div>
      {loading ? (
        <FallingLinesLoader />
      ) : (
        data && (
          <>
            <Table columns={opd_Report_columns({})} data={data} />
            {renderChart()}
          </>
        )
      )}
    </div>
  );
};

export default OPDReport;
