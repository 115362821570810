export const GET_ALL_PROGRAM_SESSION_BEGIN = "GET_ALL_PROGRAM_SESSION_BEGIN"
export const GET_ALL_PROGRAM_SESSION_SUCCESS = "GET_ALL_PROGRAM_SESSION_SUCCESS"
export const GET_ALL_PROGRAM_SESSION_ERROR = "GET_ALL_PROGRAM_SESSION_ERROR"

export const ADD_PROGRAM_SESSION_BEGIN = "ADD_PROGRAM_SESSION_BEGIN"
export const ADD_PROGRAM_SESSION_SUCCESS = "ADD_PROGRAM_SESSION_SUCCESS"
export const ADD_PROGRAM_SESSION_ERROR = "ADD_PROGRAM_SESSION_ERROR"

export const GET_PROGRAM_SESSION_BEGIN = "GET_PROGRAM_SESSION_BEGIN"
export const GET_PROGRAM_SESSION_SUCCESS = "GET_PROGRAM_SESSION_SUCCESS"
export const GET_PROGRAM_SESSION_ERROR = "GET_PROGRAM_SESSION_ERROR"

export const UPDATE_PROGRAM_SESSION_BEGIN = "UPDATE_PROGRAM_SESSION_BEGIN"
export const UPDATE_PROGRAM_SESSION_SUCCESS = "UPDATE_PROGRAM_SESSION_SUCCESS"
export const UPDATE_PROGRAM_SESSION_ERROR = "UPDATE_PROGRAM_SESSION_ERROR"

export const DELETE_PROGRAM_SESSION_BEGIN = "DELETE_PROGRAM_SESSION_BEGIN"
export const DELETE_PROGRAM_SESSION_SUCCESS = "DELETE_PROGRAM_SESSION_SUCCESS"
export const DELETE_PROGRAM_SESSION_ERROR = "DELETE_PROGRAM_SESSION_ERROR"