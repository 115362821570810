export const GET_ALL_THERAPIST_BEGIN = "GET_ALL_THERAPIST_BEGIN"
export const GET_ALL_THERAPIST_SUCCESS = "GET_ALL_THERAPIST_SUCCESS"
export const GET_ALL_THERAPIST_ERROR = "GET_ALL_THERAPIST_ERROR"

export const ADD_THERAPIST_BEGIN = "ADD_THERAPIST_BEGIN"
export const ADD_THERAPIST_SUCCESS = "ADD_THERAPIST_SUCCESS"
export const ADD_THERAPIST_ERROR = "ADD_THERAPIST_ERROR"

export const GET_THERAPIST_BEGIN = "GET_THERAPIST_BEGIN"
export const GET_THERAPIST_SUCCESS = "GET_THERAPIST_SUCCESS"
export const GET_THERAPIST_ERROR = "GET_THERAPIST_ERROR"

export const UPDATE_THERAPIST_BEGIN = "UPDATE_THERAPIST_BEGIN"
export const UPDATE_THERAPIST_SUCCESS = "UPDATE_THERAPIST_SUCCESS"
export const UPDATE_THERAPIST_ERROR = "UPDATE_THERAPIST_ERROR"

export const DELETE_THERAPIST_BEGIN = "DELETE_THERAPIST_BEGIN"
export const DELETE_THERAPIST_SUCCESS = "DELETE_THERAPIST_SUCCESS"
export const DELETE_THERAPIST_ERROR = "DELETE_THERAPIST_ERROR"

export const CLEAR_THERAPISTS = 'CLEAR_THERAPISTS'