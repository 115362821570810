import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { deleteDoctor, getAllDoctors } from "../../../redux/doctor/actions";

import Table from "../../../components/tables/table";
import { doctor_columns } from "../../../components/tables/tableheader";
import { FallingLinesLoader } from "../../../components/spinners/Spinner";
import Breadcrumb from "../../../components/Breadcrumb";
import ConfirmationModal from "../../../components/ConfirmationModal";

const DoctorList = () => {
  const pages = [{ title: "Doctor", href: "/doctors" }];

  const [confirmationModal, setConfirmationModal] = useState({
    status: false,
    id: null,
  });

  const dispatch = useDispatch();
  const { loading, list: doctorsList } = useSelector((state) => state.doctor);

  useEffect(() => {
    document.title = "New Horizon | Doctors";
    dispatch(getAllDoctors());
  }, []);

  const onDeleteOpen = (id) => {
    setConfirmationModal({ id, status: true });
  };

  const onDeleteDoctor = (id) => {
    dispatch(deleteDoctor(id));
    setConfirmationModal({ id: null, status: false });
  };

  // console.log(window.location.pathname)
  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <ConfirmationModal
        open={confirmationModal.status}
        setOpen={setConfirmationModal}
        onDelete={() => onDeleteDoctor(confirmationModal.id)}
      />
      <Breadcrumb pages={pages} />
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-xl font-semibold text-gray-900">Doctors</h1>
          <p className="mt-2 text-sm text-gray-700">
            A list of all the doctors in your account including their name,
            title, email and role.
          </p>
        </div>
        <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
          <Link
            to="/add-doctor"
            type="button"
            className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
          >
            Add Doctor
          </Link>
        </div>
      </div>
      {loading ? (
        <FallingLinesLoader />
      ) : (
        <Table columns={doctor_columns({ onDeleteOpen })} data={doctorsList} />
      )}
    </div>
  );
};

export default DoctorList;
