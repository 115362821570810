import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import { scheduleSchema } from "../../../../schemas";
import { useDispatch, useSelector } from "react-redux";
import { FallingLinesLoader } from "../../../../components/spinners/Spinner";
import moment from "moment";
import { getAllLocations } from "../../../../redux/location/actions";
import Breadcrumb from "../../../../components/Breadcrumb";
import {
  getScheduleWithId,
  addSchedule,
  updateSchedule,
} from "../../../../redux/therapist/schedule/actions";
import { getTherapistWithId } from "../../../../redux/therapist/therpist/actions";
const AddTherapistSchedule = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id: therapistId, scheduleId } = useParams();

  const { loading: thLoading, TherapistData } = useSelector(
    (state) => state.Therapist
  );
  const { loading, TherapistScheduleData } = useSelector(
    (state) => state.TherapistSchedule
  );

  const {
    loading: lLoading,
    error: lError,
    list: locations,
  } = useSelector((state) => state.location);

  const initialValues = {
    name: "",
    start_date: "",
    end_date: "",
    location_id: "",
    accept_evaluation: false
  };

  const [formState, setFormState] = useState(initialValues);


  useEffect(() => {
    document.title = "New Horizon | Add-Schedule";
    if (scheduleId) dispatch(getScheduleWithId(scheduleId));
  }, [scheduleId]);

  useEffect(() => {
    if (scheduleId && TherapistScheduleData) {
      const newFormState = {
        name: TherapistScheduleData.name,
        start_date: moment(TherapistScheduleData.start_date).format(
          "yyyy-MM-DD"
        ),
        end_date: moment(TherapistScheduleData.end_date).format("yyyy-MM-DD"),
        location_id: TherapistScheduleData.location_id,
        accept_evaluation: TherapistScheduleData.accept_evaluation
      };
      setFormState(newFormState);
      console.log({newFormState});
    } else if (therapistId && TherapistData) {
      const newFormState = {
        name: TherapistData.first_name + " " + TherapistData.last_name,
        start_date: "",
        end_date: "",
        therapist_id: "",
        accept_evaluation: false,
      };
      setFormState(newFormState);
    }
  }, [therapistId, TherapistData, scheduleId, TherapistScheduleData]);

  useEffect(() => {
    dispatch(getAllLocations());
    dispatch(getTherapistWithId(therapistId));
  }, [therapistId]);

  const pages = [
    { title: "Therapist", href: "/therapist" },
    { title: "Schedule", href: `/therapist/${therapistId}/schedule` },
  ];
  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      enableReinitialize: true,
      initialValues: formState,
      validationSchema: scheduleSchema,
      onSubmit: (values, action) => {
        const body = {
          therapist_id: parseInt(therapistId),
          name: values.name,
          start_date: moment(values.start_date).format(),
          end_date: moment(values.end_date).format(),
          location_id: parseInt(values.location_id),
          accept_evaluation: values.accept_evaluation,
        };
        if (scheduleId) dispatch(updateSchedule(scheduleId, body));
        else dispatch(addSchedule(body));
        action.resetForm();
        navigate(`/therapist/${therapistId}/schedule`);
      },
    });
  // console.log(errors);
  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <Breadcrumb pages={pages} />
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-xl font-semibold text-gray-900">
            {scheduleId ? "Update Schedule" : "Add Schedule"}
          </h1>
        </div>
      </div>
      {thLoading ? (
        <FallingLinesLoader />
      ) : (
        <form
          onSubmit={handleSubmit}
          className="space-y-8 divide-y divide-gray-200"
        >
          <div className="space-y-6 sm:space-y-5">
            <div className="space-y-6 pt-8 sm:space-y-5 sm:pt-10">
              <div className="space-y-6 sm:space-y-5">
                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4  sm:border-gray-200 sm:pt-5">
                  <label
                    htmlFor="name"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Name :
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <input
                      type="text"
                      placeholder="Enter name"
                      name="name"
                      id="name"
                      autoComplete="off"
                      value={values.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-indigo-500 sm:max-w-xs sm:text-sm"
                    />
                    {errors.name && touched.name ? (
                      <p className="text-red-700">{errors.name}</p>
                    ) : null}
                  </div>
                </div>

                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4  sm:border-gray-200 sm:pt-5">
                  <label
                    htmlFor="start_date"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Start Date :
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <input
                      type="date"
                      name="start_date"
                      id="start_date"
                      autoComplete="off"
                      value={values.start_date}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-indigo-500 sm:max-w-xs sm:text-sm"
                    />
                    {errors.start_date && touched.start_date ? (
                      <p className="text-red-700">{errors.start_date}</p>
                    ) : null}
                  </div>
                </div>
                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4  sm:border-gray-200 sm:pt-5">
                  <label
                    htmlFor="start_date"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    End Date :
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <input
                      type="date"
                      name="end_date"
                      id="end_date"
                      autoComplete="off"
                      value={values.end_date}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-indigo-500 sm:max-w-xs sm:text-sm"
                    />
                    {errors.end_date && touched.end_date ? (
                      <p className="text-red-700">{errors.end_date}</p>
                    ) : null}
                  </div>
                </div>

                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4  sm:border-gray-200 sm:pt-5">
                  <label
                    htmlFor="location_id"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Select Location :
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    {lLoading ? null : lError !== null ? null : (
                      <select
                        id="location_id"
                        name="location_id"
                        value={values.location_id}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-indigo-500 sm:max-w-xs sm:text-sm"
                      >
                        <option>Select a location</option>
                        {locations.map((l, index) => (
                          <option key={index} value={l.id}>
                            {l.name}
                          </option>
                        ))}
                      </select>
                    )}
                    {errors.location_id && touched.location_id ? (
                      <p className="text-red-700">{errors.location_id}</p>
                    ) : null}
                  </div>
                </div>
                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4  sm:border-gray-200 sm:pt-5">
                  <label
                    htmlFor="accept_evaluation"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Evaluation :
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <input type="checkbox" name="accept_evaluation" id="accept_evaluation" value={values.accept_evaluation}
                        onChange={handleChange}
                        onBlur={handleBlur} checked={values.accept_evaluation}  />
                    {errors.accept_evaluation && touched.accept_evaluation ? (
                      <p className="text-red-700">{errors.accept_evaluation}</p>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="pt-5">
            <div className="flex justify-end">
              <button
                type="submit"
                className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              >
                Submit
              </button>
            </div>
          </div>
        </form>
      )}
    </div>
  );
};

export default AddTherapistSchedule;
