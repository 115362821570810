import React from 'react'
import Breadcrumb from "../../../components/Breadcrumb";
import Table from '../../../components/tables/table';
import { Link } from 'react-router-dom';
import { announcement_columns } from '../../../components/tables/tableheader';

const announcement = [
    {
        title: "title-1",
        description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. quaerat mollitia, nisi magnam deleniti alias eos architecto nesciunt.",
    },
    {
        title: "title-2",
        description: "Lorem ipsum dolor sit amet consectetur adipisicing elit.nisi magnam deleniti alias eos architecto nesciunt."
    },
    {
        title: "title-3",
        description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Autoriosam labore, nisi magnam deleniti alias eos architecto nesciunt."
    },
    {
        title: "title-4",
        description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Aum labore, quam harum quaerat mollitia, nisi magnam deleniti alias eos architecto nesciunt."
    },
    {
        title: "title-5",
        description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem aspernatur quae animi, alias eos architecto nesciunt."
    },
    {
        title: "title-6",
        description: "Lorem ipsum dolor sit amet quam harum quaerat mollitia, nisi magnam deleniti alias eos architecto nesciunt."
    }
]

const Announcement = () => {
    const pages = [{ title: "Announcement", href: "/announcement" }];
    return (
        <div>
            <Breadcrumb pages={pages} />
            <div className="px-4 sm:px-6 lg:px-8">
                <div className="sm:flex sm:items-center">
                    <div className="sm:flex-auto">
                        <h1 className="text-xl font-semibold text-gray-900">Announcement</h1>
                        <p className="mt-1 max-w-2xl text-sm text-gray-500">
                            This information will be displayed publicly so be careful what you share.
                        </p>
                    </div>
                    <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none absolute top-48 right-16">
                        <input
                            type="text"
                            className="inline-flex mr-2 items-center justify-center rounded-md border border-transparent border-gray-400  px-4 py-2 text-sm font-medium shadow-sm  focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
                            placeholder="Search"
                        />
                        <Link
                            to="/add-announcement"
                            type="button"
                            className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
                        >
                            Add Announcement
                        </Link>
                    </div>
                </div>

                <Table
                    columns={announcement_columns()}
                    data={announcement}
                // onEditClose={cleanModalData}
                />
            </div>
        </div>
    )
}

export default Announcement