import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { deleteLocation, getAllLocations } from "../../../redux/location/actions";
import Pagination from "../../../components/Pagination";
import { FallingLinesLoader } from "../../../components/spinners/Spinner";
import Breadcrumb from "../../../components/Breadcrumb";
import ConfirmationModal from "../../../components/ConfirmationModal";

import Table from "../../../components/tables/table";
import { location_columns } from '../../../components/tables/tableheader'

const LocationList = () => {
  const pages = [{ title: "Location", href: "/locations" }];
  const [confirmationModal, setConfirmationModal] = useState({
    status: false,
    id: null,
  });
  const dispatch = useDispatch();
  const { loading, list: locationList } = useSelector(
    (state) => state.location
  );

  useEffect(() => {
    document.title = "New Horizon | Locations";
    dispatch(getAllLocations());
  }, []);

  const onDeleteOpen = (id) => {
    setConfirmationModal({ id, status: true });
  };

  const onDeleteLocation = (id) => {
    dispatch(deleteLocation(id));
    setConfirmationModal({ id:null, status: false });
  };

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <ConfirmationModal
        open={confirmationModal.status}
        setOpen={setConfirmationModal}
        onDelete={() => onDeleteLocation(confirmationModal.id)}
      />
      <Breadcrumb pages={pages} />
      <div className="sm:flex sm:items-center mb-5">
        <div className="sm:flex-auto">
          <h1 className="text-xl font-semibold text-gray-900">Locations</h1>
        </div>
        <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
          <Link
            to="/add-location"
            type="button"
            className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
          >
            Add Location
          </Link>
        </div>
      </div>
        {loading ? (
          <FallingLinesLoader />
        ) : (
          <Table
          columns={location_columns({ onDeleteOpen })}
          data={locationList}
          // onEditClose={cleanModalData}
        />
        )}
      </div>
  );
};

export default LocationList;
