import axios from "axios";
import { getHeaders } from "../auth/actions";
import { toast } from "react-hot-toast";
import {
  ADD_APPOINTMENT_BEGIN,
  ADD_APPOINTMENT_SUCCESS,
  ADD_APPOINTMENT_ERROR,
  GET_APPOINTMENT_BEGIN,
  GET_APPOINTMENT_SUCCESS,
  GET_APPOINTMENT_ERROR,
  GET_AVAILABLE_TIME_SLOTS_BEGIN,
  GET_AVAILABLE_TIME_SLOTS_SUCCESS,
  GET_AVAILABLE_TIME_SLOTS_ERROR,
  DELETE_APPOINTMENT_ERROR,
  DELETE_APPOINTMENT_BEGIN,
  DELETE_APPOINTMENT_SUCCESS,
  UPDATE_APPOINTMENT_BEGIN,
  UPDATE_APPOINTMENT_ERROR,
  UPDATE_APPOINTMENT_SUCCESS,
} from "./constants";
import * as dateMath from "date-arithmetic";
import moment from "moment";

const API = process.env.REACT_APP_API_URL;

export const getAppointment =
  (
    start = dateMath.startOf(new Date(), "month"),
    end = dateMath.endOf(new Date(), "month")
  ) =>
  async (dispatch) => {
    try {
      dispatch({
        type: GET_APPOINTMENT_BEGIN,
      });
      const headers = dispatch(getHeaders());
      const { data } = await axios.post(
        `${API}/api/appointment/search`,
        {
          start_date: moment(start).format("YYYY-MM-DD"),
          end_date: moment(end).format("YYYY-MM-DD"),
        },
        { headers }
      );

      dispatch({
        type: GET_APPOINTMENT_SUCCESS,
        data: data.data,
      });
    } catch (error) {
      const msg = error.response.data.message;
      toast.error(msg);
      dispatch({
        type: GET_APPOINTMENT_ERROR,
        error: msg,
      });
    }
  };

export const getAvailableSlots =
  (
    start = dateMath.startOf(new Date(), "week"),
    end = dateMath.endOf(new Date(), "week"),
    doctorId = null,
    type = "OFFLINE"
  ) =>
  async (dispatch) => {
    try {
      dispatch({
        type: GET_AVAILABLE_TIME_SLOTS_BEGIN,
      });
      const headers = dispatch(getHeaders());
      const { data } = await axios.post(
        `${API}/api/appointment/search/unbooked`,
        {
          start_date: moment(start).format("YYYY-MM-DD"),
          end_date: moment(end).format("YYYY-MM-DD"),
          doctorId,
          appointment_type: type,
        },
        { headers }
      );

      dispatch({
        type: GET_AVAILABLE_TIME_SLOTS_SUCCESS,
        data: data.data,
      });
    } catch (err) {
      const msg = err.response.data.message;
      toast.error(msg);
      dispatch({
        type: GET_AVAILABLE_TIME_SLOTS_ERROR,
        error: msg,
      });
    }
  };

export const getAppointmentByDoctor =
  (start = dateMath.startOf(new Date(), "week"), end = new Date(), doctorId) =>
  async (dispatch) => {
    try {
      dispatch({
        type: GET_APPOINTMENT_BEGIN,
      });
      const headers = dispatch(getHeaders());
      const { data } = await axios.post(
        `${API}/api/appointment/search`,
        {
          start_date: moment(start).format("YYYY-MM-DD"),
          end_date: moment(end).format("YYYY-MM-DD"),
          doctorId,
        },
        { headers }
      );
      dispatch({
        type: GET_APPOINTMENT_SUCCESS,
        data: data.data,
      });
    } catch (error) {
      const msg = error.response.data.message;
      toast.error(msg);
      dispatch({
        type: GET_APPOINTMENT_ERROR,
        error: msg,
      });
    }
  };

export const addAppointment = (body) => async (dispatch) => {
  try {
    dispatch({
      type: ADD_APPOINTMENT_BEGIN,
    });
    const headers = dispatch(getHeaders());
    const { data } = await axios.post(`${API}/api/appointment`, body, {
      headers,
    });
    toast.success("Added appointment successfully!");
    dispatch({
      type: ADD_APPOINTMENT_SUCCESS,
      data: data.data,
    });
  } catch (error) {
    const msg = error.response.data.message;
    toast.error(msg);
    dispatch({
      type: ADD_APPOINTMENT_ERROR,
      error: msg,
    });
  }
};

export const editAppointment = (id, body) => async (dispatch) => {
  try {
    dispatch({
      type: UPDATE_APPOINTMENT_BEGIN,
    });
    const headers = dispatch(getHeaders());
    const { data } = await axios.put(`${API}/api/appointment/${id}`, body, {
      headers,
    });
    toast.success("Updated appointment successfully!");
    dispatch({
      type: UPDATE_APPOINTMENT_SUCCESS,
      data: { id: id, data: data.data },
    });
  } catch (error) {
    const msg = error.response.data.message;
    toast.error(msg);
    dispatch({
      type: UPDATE_APPOINTMENT_ERROR,
      error: msg,
    });
  }
};

export const deleteAppointment = (id, body) => async (dispatch) => {
  try {
    dispatch({
      type: DELETE_APPOINTMENT_BEGIN,
    });
    const headers = dispatch(getHeaders());
    const { data } = await axios.post(
      `${API}/api/appointment/cancel/${id}`,
      body,
      {
        headers,
      }
    );
    toast.success("Cancelled appointment successfully!");
    dispatch({
      type: DELETE_APPOINTMENT_SUCCESS,
      data: id,
    });
  } catch (err) {
    const msg = err.response.data.message;
    toast.error(msg);
    dispatch({
      type: DELETE_APPOINTMENT_ERROR,
      error: msg,
    });
  }
};
