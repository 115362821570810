import { useState, useEffect } from 'react';

export default function Heading(props) {
  let data = props.config;
  let [visibility, set_visibility] = useState(true);

  useEffect(() => {
    if (data.child) {
      let foi = Object.keys(props.all_fields).filter((field) => {
        if (props.all_fields[field].label.trim() == data.parent?.label.trim()) {
          return props.all_fields[field];
        }
      });
      if (
        props.current_data[props.all_fields[foi]?.ref_id] == data.parent?.option
      ) {
        set_visibility(true);
      } else {
        set_visibility(false);
      }
    }
  }, [props.current_data]);
  return (
    <>
      {visibility ? (
        <div class="flex items-center py-2">
          <div class="flex-grow h-px bg-gray-400"></div>

          <h3 className=" flex-shrink text-lg font-medium leading-6 pr-5 text-gray-900 pl-5">
            {' '}
            {data.label}
          </h3>
          <div class="flex-grow h-px bg-gray-300"></div>
        </div>
      ) : (
        ''
      )}
    </>
  );
}
