import React, { Fragment, useEffect, useState } from "react";
import Table from "../../../components/tables/table";
import { Therapist_payment_columns } from "../../../components/tables/tableheader";
import { useDispatch } from "react-redux";
import { sendInvoiceSmsLink } from "../../../redux/payments/action";
import { FallingLinesLoader } from "../../../components/spinners/Spinner";
import ConfirmationModal from "../../../components/ConfirmationModal";
import { Dialog, Transition } from "@headlessui/react";
import { Formik, useFormik } from "formik";
import { markPaidSchema } from "../../../schemas";
import moment from "moment";
import { getHeaders } from "../../../redux/auth/actions";
import axios from "axios";
import { toast } from "react-hot-toast";
import Modal from "../../../components/Modal";
const API = process.env.REACT_APP_API_URL;
const initialModalState = {
  id: "",
  state: false,
};
const TherapitPayment = () => {
  const [query, setQuery] = useState(200);
  // State and Variables
  const [paid, setPaid] = useState(false);
  const [date, setDate] = useState("");
  const [confirmationModal, setConfirmationModal] = useState({
    status: false,
    id: null,
    title: "Are you sure?",
    description: "",
    confirmationButtonText: "",
    func: null,
  });

  //   const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState(initialModalState);
  useEffect(() => {
    document.title = "New Horizon | Payments";
    getAllPayments(200);
    setData(payment.list);
    if (paid) {
      getAllPayments(query || 200);
    }
  }, [paid]);
  const dispatch = useDispatch();
  const [markPaidId, setMarkPaidId] = useState(null);
  const [payment, setPayment] = useState({
    loading: true,
    list: [],
  });

  const [data, setData] = useState(payment.list);

  // Handlers and Functions
  async function getAllPayments(limit) {
    const headers = await dispatch(getHeaders());

    try {
      const res = await axios.get(
        `${API}/api/evaluation-payment?limit=${limit}`,
        {
          headers: headers,
        }
      );
      setPayment({ loading: false, list: res.data.data });
    } catch (error) {}
  }
  async function sendPaymentLink(id) {
    const headers = await dispatch(getHeaders());

    try {
      const res = await axios.get(
        `${API}/api/evaluation-payment/send-payment-link/${id}`,
        {
          headers: headers,
        }
      );
      getAllPayments();
      toast.success("Payment Link Sent Successfully");
      setConfirmationModal({ status: false, id: null });
    } catch (error) {
      toast.error("Error Sending Payment Link");
    }
  }
  async function sendInvoiceSms(id) {
    const headers = await dispatch(getHeaders());

    try {
      const res = await axios.get(
        `${API}/api/evaluation-payment/send-sms-invoice/${id}`,
        {
          headers: headers,
        }
      );
      getAllPayments();
      toast.success("SMS for Invoice Link Sent Successfully");
      setConfirmationModal({ status: false, id: null });
    } catch (error) {
      toast.error("Error Sending Invoice Link");
    }
  }
  async function genInvoice(id) {
    const headers = await dispatch(getHeaders());

    try {
      const res = await axios.get(
        `${API}/api/evaluation-payment/gen-invoice/${id}`,
        {
          headers: headers,
        }
      );
      getAllPayments();
      toast.success("Invoice Generated Successfully");
      setConfirmationModal({ status: false, id: null });
    } catch (error) {
      toast.error("Error Generating Invoice");
    }
  }
  async function handleMarkPaid(body) {
    const headers = await dispatch(getHeaders());

    try {
      const res = await axios.post(
        `${API}/api/evaluation-payment/update-payment`,

        body,

        {
          headers: headers,
        }
      );
      setModalData((prev) => ({ ...prev, state: false, id: "" }));
      setConfirmationModal({ status: false, id: null });
      getAllPayments();
      toast.success(res.data.message);
    } catch (error) {
      toast.error("Error Sending Payment Link");
    }
  }

  const sendInvoiceLink = (id) => {
    setConfirmationModal({ id, status: true });
  };

  if (paid) {
    getAllPayments();
    setPaid(false);
  }

  useEffect(() => {
    setData(payment.list);
  }, [payment.list]);

  if (date !== "") {
    const newArr = payment.list.filter((item) => {
      var newDate = moment(item.appointment.date).format("YYYY-MM-DD");
      return newDate === date;
    });
    setData(newArr);
  }

  const handleChangeDate = (date) => {
    if (date !== "") {
      const newArr = payment.list.filter((item) => {
        var newDate = moment(item.appointment.date).format("YYYY-MM-DD");
        return newDate === date;
      });
      // console.log(newArr);
      console.log(newArr);
      setData(newArr);
    }
  };

  const RenderMarkPaid = () => {
    const { state, id } = modalData;
    return (
      <Modal
        size="max-w-sm"
        open={!confirmationModal.status && state}
        // open={true}
        title={`Mark paid`}
        setOpen={() =>
          setModalData((prev) => ({ ...prev, state: false, id: "" }))
        }
      >
        <Formik
          initialValues={{
            payment_id: "",
            payment_source: "",
          }}
          validationSchema={markPaidSchema}
          onSubmit={(values, action) => {
            const body = {
              payment_id: modalData.id,
              payment_source: values.payment_source,
            };
            // setConfirmationModal({
            //   status: true,
            //   id: body,
            //   title: "Are you sure?",
            //   description: "Do you want to mark this payment as paid?",
            //   confirmationButtonText: "Mark Paid",
            //   func: handleMarkPaid,
            // });
            const cnf = window.confirm(
              "Do you want to mark this payment as paid?"
            );
            if (cnf) {
              handleMarkPaid(body);
            }
          }}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
          }) => (
            <form className="w-full" onSubmit={handleSubmit}>
              <div className="mt-2 w-full flex items-center justify-between ">
                <select
                  id="payment_source"
                  name="payment_source"
                  onChange={handleChange}
                  value={values.payment_source}
                  onBlur={handleBlur}
                  className="w-full rounded-md border border-gray-300 shadow-sm focus:ring  focus:ring-opacity-50 sm:text-sm"
                >
                  <option>Payment Sources</option>
                  <option value={"RAZORPAY"}>Razorpay</option>
                  <option value={"CASH"}>Cash</option>
                  <option value={"UPI"}>UPI</option>
                  <option value={"CARD"}>Card</option>
                  <option value={"NET_BANKING"}>Net Banking</option>
                  <option value={"CHEQUE"}>Cheque</option>
                </select>
              </div>

              <div>
                <div className="mt-5 sm:mt-6">
                  <button
                    type="submit"
                    className="inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm"
                  >
                    Mark As Paid
                  </button>
                  {touched.payment_source && (
                    <div className="text-red-500 text-sm mt-1">
                      {errors.payment_source}
                    </div>
                  )}
                </div>
              </div>
            </form>
          )}
        </Formik>
      </Modal>
    );
  };
  return (
    <div className="px-4 sm:px-6 lg:px-8">
      {RenderMarkPaid()}
      <ConfirmationModal
        open={confirmationModal.status}
        setOpen={setConfirmationModal}
        title={confirmationModal.title}
        onSend={() => confirmationModal.func(confirmationModal.id)}
        confirmationButtonText={confirmationModal.confirmationButtonText}
        confirmationButtonColor="indigo"
        description={confirmationModal.description}
      />

      <div className="sm:flex sm:items-center mb-5">
        <div className="sm:flex-auto">
          <h1 className="text-xl font-semibold text-gray-900">
            Evaluation Payments
          </h1>
        </div>
        <input
          type="date"
          name="date"
          onChange={(e) => handleChangeDate(e.target.value)}
          placeholder="select date"
          className="mr-2 block w-[20%] max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-indigo-500 sm:max-w-xs sm:text-sm"
        />
      </div>
      {payment.loading ? (
        <FallingLinesLoader />
      ) : (
        <Table
          columns={Therapist_payment_columns({
            sendPaymentLink,
            sendInvoiceLink,
            setConfirmationModal,
            setModalData,
            sendInvoiceSms,
            genInvoice,
          })}
          data={data}
          fetch={async (d) => {
            setQuery((prev) => d);
            await getAllPayments(d);
          }}
        />
      )}
    </div>
  );
};

export default TherapitPayment;
