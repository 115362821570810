/* eslint-disable react/jsx-pascal-case */
import { Accordion } from "react-bootstrap-accordion";
import { Link, useNavigate, useParams } from "react-router-dom";
import React, { Fragment, useState, useEffect } from "react";
import { IoIosArrowUp } from "react-icons/io";
import axios from "axios";
import Form_submit from "../dynamicForm/FormSubmission";
import "react-bootstrap-accordion/dist/index.css";
import { FallingLinesLoader } from "../../../components/spinners/Spinner";
import ConfirmationModal from "../../../components/ConfirmationModal";
import { ArrowUturnLeftIcon } from "@heroicons/react/20/solid";
import Opd, { OpdRecoomndation, Prescription } from "./opd";
import { toast } from "react-hot-toast";
import moment from "moment";
const Accordian = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);

  const [sequence, setsequence] = useState([]);
  const [forms, setforms] = useState([]);
  const { id: patient_id } = useParams();
  const [loading, setloading] = useState(true);
  const [uname, setuname] = useState("");
  const [pi, setpi] = useState();
  const [fname, setfname] = useState("");
  const [locked, setLocked] = useState("");
  const [open, setOpen] = useState();
  async function get_dat() {
    const edit_resp = await axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/patient-form/form-fields/${patient_id}`,

        {}
      )
      .then((data) => {
        setloading(false);
        let all_fields = data.data.data;
        setuname(all_fields.name);
        setpi(all_fields.patient_id);
        setfname(all_fields.form);
        setLocked(all_fields.locked);
        // setforms(all_fields.form_data);
        const demographicFormElement = all_fields.form_data.find((item) =>
          item.form_group_name.includes("Demographic")
        );
        console.log(demographicFormElement);
        if (demographicFormElement) {
          // Assuming there is an array "fields" inside the demographicFormElement
          // and each field has a "label" and "defaultValue" property.

          const fieldsWithDefaultValue = [
            "First name ",
            "Surname",
            "DOB",
            "Gender",
            "Gender ",
            "Age",
          ];

          const updatedFields = demographicFormElement.fields.map((field) => {
            if (fieldsWithDefaultValue.includes(field.label)) {
              switch (field.label) {
                case "First name ":
                  return {
                    ...field,
                    defaultValue: all_fields.patient_info.firstName,
                  };
                case "Surname":
                  return {
                    ...field,
                    defaultValue: all_fields.patient_info.lastName,
                  };
                case "DOB":
                  return {
                    ...field,
                    defaultValue: moment(all_fields.patient_info.dob).format(
                      "YYYY-MM-DD"
                    ),
                  };
                case "Gender":
                  return {
                    ...field,
                    defaultValue:
                      all_fields.patient_info.gender === "M"
                        ? "Male"
                        : all_fields.patient_info.gender === "F"
                        ? "Female"
                        : "Transgender",
                  };
                case "Gender ":
                  return {
                    ...field,
                    defaultValue:
                      all_fields.patient_info.gender === "M"
                        ? "Male"
                        : all_fields.patient_info.gender === "F"
                        ? "Female"
                        : "Transgender",
                  };
                case "Age":
                  return {
                    ...field,
                    defaultValue: all_fields.patient_info.age,
                  };
                default:
                  return field;
              }
            } else {
              return field;
            }
          });
          // Update the form_data with the updatedFields
          const updatedForm_data = all_fields.form_data.map((item) => {
            if (item.form_group_name.includes("Demographic")) {
              return { ...item, fields: updatedFields };
            } else {
              return item;
            }
          });

          // Finally, set the state with the updated form_data
          setforms(updatedForm_data);
        }
        setData(all_fields);
      });
  }
  useEffect(() => {
    get_dat();
  }, []);
  const [lconfimationModal, setLConfimationModal] = useState({
    state: false,
  });
  const [fconfimationModal, setFConfimationModal] = useState({
    state: false,
  });
  const onOpenL = () => {
    setLConfimationModal({ state: true });
  };
  const onOpenF = () => {
    setFConfimationModal({ state: true });
  };
  async function handleFinailze() {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/patient-form/finalize-form/${patient_id}`
      );
      if (res) {
        toast.success("Finalized Successfully");
      }
    } catch (error) {
      toast.error("Error Finalizing Form");
    }
  }
  async function toggle() {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/patient-form/lock-form/${patient_id}`
      );
      if (res) {
        toast.success("Form Locked Successfully");
        window.location.reload();
      }
    } catch (error) {
      toast.error("Error Locking Form");
    }
  }

  return (
    <>
      <div className="px-4 sm:px-6 lg:px-8">
        {/* {console.log(JSON.stringify(fname))} */}
        <ConfirmationModal
          description="Are you sure you want to Lock/Unlock this form?"
          confirmationButtonColor="indigo"
          confirmationButtonText="Confirm"
          open={lconfimationModal.state}
          setOpen={() => {
            setLConfimationModal({ state: false });
          }}
          onDelete={() => {
            toggle();
          }}
        />
        <ConfirmationModal
          description="Are you sure you want to Finalize this form?"
          confirmationButtonColor="indigo"
          confirmationButtonText="Finalize"
          open={fconfimationModal.state}
          setOpen={() => {
            setFConfimationModal({ state: false });
          }}
          onDelete={() => {
            handleFinailze();
            handleFinailze();
          }}
        />

        <button
          type="button"
          onClick={() => navigate(-1)}
          className="inline-flex items-center rounded-md border mb-6 border-transparent bg-indigo-100 px-3 py-2 text-sm font-medium leading-4 text-indigo-700 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 mt-4"
        >
          <span className="w-5 mr-2">
            <ArrowUturnLeftIcon size={18} />
          </span>{" "}
          Back
        </button>
        {loading ? null : (
          <div className="flex items-center justify-between">
            <h2 className="">{`${uname} - ${fname}`}</h2>
            <span
              className={`flex items-center justify-between gap-2 text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"`}
            >
              <button
                onClick={(e) => {
                  e.preventDefault();
                  onOpenF();
                }}
                className="inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm"
              >
                Finalize
              </button>
              <button
                onClick={(e) => {
                  e.preventDefault();
                  onOpenL();
                }}
                className="inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm"
              >
                {locked ? "Unlock" : "Lock"}
              </button>
              <Link
                to={`/patient-report/${patient_id}`}
                className="inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm"
              >
                Report
              </Link>
            </span>
          </div>
        )}
        {loading ? (
          <FallingLinesLoader />
        ) : (
          forms.map((form, index) => {
            return (
              <AccordionData
                defaultActiveKey="0"
                key={index}
                name={form.form_group_name}
                open={open}
                setOpen={setOpen}
                title={
                  <h1 className="text-center text-sm font-semibold text-gray-500">
                    {form.form_group_name.includes("Current Concerns")
                      ? "Current Improvements/Concerns"
                      : form.form_group_name}
                  </h1>
                }
                content={
                  <Form_submit
                    form_name={form.form_group_name}
                    patientId={pi}
                    reFetch={get_dat}
                    pid={patient_id}
                    fields={form.fields}
                    formgroup={form.form_group_id}
                    locked={data.locked}
                  ></Form_submit>
                }
              />
            );
          })
        )}
        {loading ? (
          ""
        ) : (
          <>
            <AccordionData
              name={"Goals"}
              open={open}
              setOpen={setOpen}
              title={
                <h1 className="text-center text-sm font-semibold text-gray-500">
                  Goals
                </h1>
              }
              content={
                <div className="shadow-xl rounded-lg">
                  <Opd
                    locked={data.locked}
                    goals={data.goals}
                    g_commnet_department={data?.g_commnet_department}
                  />
                </div>
              }
            />

            <AccordionData
              name={"Recommendation"}
              open={open}
              setOpen={setOpen}
              title={
                <h1 className="text-center text-sm font-semibold text-gray-500">
                  Recommendation
                </h1>
              }
              content={
                <div className="shadow-xl rounded-lg">
                  <OpdRecoomndation
                    locked={data.locked}
                    golden_rules={data.golden_rules}
                    fetchData={get_dat}
                    commentData={data.patient_recommendation_comment}
                  />
                </div>
              }
            />
            <AccordionData
              name={"Prescriptions"}
              open={open}
              setOpen={setOpen}
              title={
                <h1 className="text-center text-sm font-semibold text-gray-500">
                  Prescriptions
                </h1>
              }
              content={
                <Prescription
                  locked={data.locked}
                  fprescriptionData={data.precription}
                />
              }
            />
            {}
          </>
        )}
      </div>
    </>
  );
};

export const AccordionData = ({
  title,
  content,
  open,
  setOpen,
  name,
  label,
}) => {
  const data = [{ title: title, content: content }];
  return (
    <NAccordian
      open={open}
      setOpen={setOpen}
      name={name}
      data={data}
      label={label}
    />
  );
};

function NAccordian({ data, open, setOpen, name, label }) {
  const [selected, setSelectd] = useState(null);
  const [fdata, setfData] = useState(" ");
  const toggle = (i) => {
    if (selected === i && open === name) {
      return setSelectd(null) && setOpen(" ");
    }
    setSelectd(i);
    setOpen(name);
  };

  return (
    <div className="accordian w-full">
      {data.map((item, i) => (
        <div key={i} className="border border-gray-200  ">
          <div
            className={`flex items-center justify-between border-b border-gray-300 ${
              open === name && selected == i ? "bg-blue-100" : "bg-white"
            }  `}
            onClick={() => {
              toggle(i);
            }}
          >
            <h2
              className={`ml-4 mt-6  text-left ${
                label && "text-sm font-normal"
              }`}
            >
              {label ? label : item.title}
            </h2>
            <span
              className={`mr-4 ${
                open === name && selected === i ? "-rotate-120" : "rotate-180"
              }`}
            >
              <IoIosArrowUp size={25} className="text-gray-500" />
            </span>
          </div>
          <div className="content">
            {open === name && selected === i ? item.content : ""}
          </div>
        </div>
      ))}
    </div>
  );
}

export default Accordian;
