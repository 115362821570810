import { useEffect, useState } from "react";
import app_api from "./api";
export const capitalise = (string) =>
  string ? string?.charAt(0)?.toUpperCase() + string?.slice(1) : string;

export function capitalizeFirstLetter(str) {
  if (typeof str !== "string") {
    return str;
  }

  str = str.toLowerCase();

  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function removeLeadingComma(str) {
  if (str && typeof str === "string" && str.startsWith(",")) {
    return str.slice(1);
  }

  return str;
}



export function useFetch(url, depens = []) {
  const [apiData, setApiData] = useState({
    data: [],
    loading: true,
    error: null,
  });

  useEffect(() => {
    app_api.fetchData({
      url: url,
      method: "get",
    })
      .then((res) => {
        setApiData({ ...apiData, data: res.data.data, loading: false });
      })
      .catch((err) => {
        setApiData({ ...apiData, error: err, loading: false });
      });
  }, [...depens]);

  return apiData;
};
