import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "../../../components/Pagination";
import { FallingLinesLoader } from "../../../components/spinners/Spinner";
import Breadcrumb from "../../../components/Breadcrumb";
import ConfirmationModal from "../../../components/ConfirmationModal";

import Table from "../../../components/tables/table";
import {
  activity_Columns,
  location_columns,
} from "../../../components/tables/tableheader";
import { getHeaders } from "../../../redux/auth/actions";
import { toast } from "react-hot-toast";
import axios from "axios";
import { CloudArrowUpIcon } from "@heroicons/react/24/outline";

import InputBox from "../../../components/InputBox";
import Modal from "../../../components/Modal";
import { Formik } from "formik";
import * as Yup from "yup";
import Button from "../../../components/Button";
const Api = process.env.REACT_APP_API_URL;
const initialModalState = {
  type: "",
  state: false,
  index: null,
  edit_id: "",
  data: {
    name: "",
    date: "",
  },
};
const ActivityMaster = () => {
  const [modal, setModal] = useState(initialModalState);
  const pages = [{ title: "ActivityMaster", href: "/locations" }];
  const [confirmationModal, setConfirmationModal] = useState({
    status: false,
    id: null,
  });
  const [activity, setActivity] = useState({
    loading: false,
    data: [],
  });
  useEffect(() => {
    getAllModules();
    getAllActivity();
    document.title = "New Horizon | Activity Master";
  }, []);

  const onDeleteOpen = (id) => {
    setConfirmationModal({ id, status: true });
  };
  const [formValues, setFormValues] = useState({
    module_id: "",
    file: "",
    loading: false,
  });
  const onDeleteActivity = (id) => {
    deleteActivity(id);
    setConfirmationModal({ id: null, status: false });
  };
  const dispatch = useDispatch();
  const getAllActivity = async () => {
    const headers = dispatch(getHeaders());
    try {
      setActivity({ ...activity, loading: true });
      const res = await axios.get(`${Api}/api/activity`, {
        headers: headers,
      });
      const { data, status } = res;
      if (status === 201) {
        setActivity({ data: data.data, loading: false });
      }
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };
  const deleteActivity = async (id) => {
    const headers = await dispatch(getHeaders());
    try {
      const res = await axios.delete(`${Api}/api/activity/${id}`, {
        headers: headers,
      });
      const { data, status } = res;
      if (status === 200) {
        toast.success(data.message);
        getAllActivity();
      }
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };
  const uploadFile = async (file, id) => {
    setFormValues({ ...formValues, loading: true });
    const headers = await dispatch(getHeaders());
    const newHeader = {
      ...headers,
      "Content-Type": "multipart/form-data",
    };
    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await axios.post(
        `${Api}/api/activity/import/${id}`,
        formData,
        {
          headers: newHeader,
        }
      );
      toast.success("Activity Imported Successfully");
      setFormValues({ ...formValues, loading: false });
      return response.data;
    } catch (error) {
      getAllModules();
      toast.error("Error Uploading Document");
      console.error(error);
    } finally {
      setModal({
        ...modal,
        state: false,
        data: {},
        edit_id: "",
        type: "",
      });
      setFormValues({ ...formValues, loading: false });
    }
  };

  const [moduleList, setModuleList] = useState({
    loading: false,
    data: [],
  });
  const getAllModules = async () => {
    const headers = await dispatch(getHeaders());
    setModuleList((prev) => ({ ...prev, loading: true }));
    try {
      const res = await axios.get(`${Api}/api/module-master`, {
        headers: headers,
      });
      const { data, status } = res;
      if (status === 200) {
        setModuleList((prev) => ({
          ...prev,
          data: data.data,
          loading: false,
        }));
      }
      console.log(res);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const renderModal = () => {
    const { type, state, data, edit_id } = modal;

    return (
      <Formik
        initialValues={data}
        validationSchema={Yup.object({
          module_id: Yup.string().required("Please Select Module"),
        })}
        enableReinitialize={true}
        onSubmit={(values, action) => {
          uploadFile(formValues.document, values.module_id);
          action.setSubmitting(false);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <Modal
            title="Import Activity"
            open={state}
            setOpen={() =>
              setModal((prev) => ({
                ...prev,
                state: false,
                data: {},
                edit_id: "",
                type: "",
              }))
            }
          >
            <form onSubmit={handleSubmit}>
              <div className="text-left mt-4">
                <div className="mt-4"></div>
                <label className="block text-sm font-medium text-gray-700">
                  Module
                </label>
                <select
                  name="module_id"
                  onChange={(event) => {
                    setFormValues({
                      ...formValues,
                      module_id: event.target.value,
                    });
                    handleChange(event);
                  }}
                  onBlur={handleBlur}
                  required={true}
                  value={values.module_id}
                  className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                >
                  <option>Select Modules</option>
                  {moduleList.data.map((item) => (
                    <option value={item.id}>{item.name}</option>
                  ))}
                </select>
                {touched.name && (
                  <p className="text-red-700 error_msg">{errors.name}</p>
                )}
                <InputBox
                  name="file"
                  label="Excel File"
                  type="file"
                  autotcomplete="off"
                  handleBlur={handleBlur}
                  onChange={(event) => {
                    setFormValues({
                      ...formValues,
                      document: event.target.files[0],
                    });
                  }}
                  required={true}
                  placeholder="Enter the Name"
                />
              </div>

              <div className="mt-4"></div>
              <Button disabled={isSubmitting} type="submit">
                {modal.type === "edit" ? "Update" : "Add"} Activity
              </Button>
            </form>
          </Modal>
        )}
      </Formik>
    );
  };
  function findDuplicateObjects(myArray) {
    let duplicates = [];
    let counts = {};

    for (let i = 0; i < myArray.length; i++) {
      let item = myArray[i];
      counts[item.domain_module_title] = counts[item.domain_module_title]
        ? counts[item.domain_module_title] + 1
        : 1;
      if (counts[item.domain_module_title] > 1) {
        duplicates.push(item);
      }
    }

    return duplicates;
  }

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      {renderModal()}
      <ConfirmationModal
        open={confirmationModal.status}
        description="This Activity might be used in Plan View Builders. Are you sure you want to delete this Activity?"
        setOpen={setConfirmationModal}
        onDelete={() => onDeleteActivity(confirmationModal.id)}
      />
      <Breadcrumb pages={pages} />
      <div className="sm:flex sm:items-center mb-5">
        <div className="sm:flex-auto">
          <h1 className="text-xl font-semibold text-gray-900">
            Activity Master
          </h1>
        </div>
        <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
          <button
            type="button"
            onClick={() =>
              setModal((prev) => ({
                ...prev,
                data: {},
                type: "",
                edit_id: "",
                state: true,
              }))
            }
            className="inline-flex mr-4 items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
          >
            Import Activity
          </button>
          <Link
            to="/add-activity"
            className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
          >
            Add Activity
          </Link>
          <Link
            to="/re-arrange"
            className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 ml-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
          >
            Re Arrange
          </Link>
          {console.log(findDuplicateObjects(activity.data))}
        </div>
      </div>
      {activity.loading ? (
        <FallingLinesLoader />
      ) : (
        <Table
          columns={activity_Columns({ onDeleteOpen })}
          data={activity.data}
          // onEditClose={cleanModalData}
        />
      )}
    </div>
  );
};

export default ActivityMaster;
