export const GET_ALL_MEDICINE_BEGIN = "GET_ALL_MEDICINE_BEGIN"
export const GET_ALL_MEDICINE_SUCCESS = "GET_ALL_MEDICINE_SUCCESS"
export const GET_ALL_MEDICINE_ERROR = "GET_ALL_MEDICINE_ERROR"

export const ADD_MEDICINE_BEGIN = "ADD_MEDICINE_BEGIN"
export const ADD_MEDICINE_SUCCESS = "ADD_MEDICINE_SUCCESS"
export const ADD_MEDICINE_ERROR = "ADD_MEDICINE_ERROR"

export const GET_MEDICINE_BEGIN = "GET_MEDICINE_BEGIN"
export const GET_MEDICINE_SUCCESS = "GET_MEDICINE_SUCCESS"
export const GET_MEDICINE_ERROR = "GET_MEDICINE_ERROR"

export const UPDATE_MEDICINE_BEGIN = "UPDATE_MEDICINE_BEGIN"
export const UPDATE_MEDICINE_SUCCESS = "UPDATE_MEDICINE_SUCCESS"
export const UPDATE_MEDICINE_ERROR = "UPDATE_MEDICINE_ERROR"

export const DELETE_MEDICINE_BEGIN = "DELETE_MEDICINE_BEGIN"
export const DELETE_MEDICINE_SUCCESS = "DELETE_MEDICINE_SUCCESS"
export const DELETE_MEDICINE_ERROR = "DELETE_MEDICINE_ERROR"