import axios from "axios";
import { toast } from "react-hot-toast";
import { getHeaders } from "../auth/actions";
import {
  GET_ALL_PROGRAM_BEGIN,
  GET_ALL_PROGRAM_SUCCESS,
  GET_ALL_PROGRAM_ERROR,
  ADD_PROGRAM_BEGIN,
  ADD_PROGRAM_SUCCESS,
  ADD_PROGRAM_ERROR,
  GET_PROGRAM_BEGIN,
  GET_PROGRAM_ERROR,
  GET_PROGRAM_SUCCESS,
  UPDATE_PROGRAM_BEGIN,
  UPDATE_PROGRAM_SUCCESS,
  UPDATE_PROGRAM_ERROR,
  DELETE_PROGRAM_BEGIN,
  DELETE_PROGRAM_SUCCESS,
  DELETE_PROGRAM_ERROR,
} from "./constants";

const API = process.env.REACT_APP_API_URL;

export const getAllPrograms = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_ALL_PROGRAM_BEGIN,
    });
    const headers = dispatch(getHeaders());
    const { data } = await axios.get(`${API}/api/program/program-master`, {
      headers,
    });
    console.log(data.data);

    dispatch({
      type: GET_ALL_PROGRAM_SUCCESS,
      data: data.data,
    });
  } catch (error) {
    const msg = error.response.data.message;
    toast.error(msg);
    console.log(error);
    dispatch({
      type: GET_ALL_PROGRAM_ERROR,
      error: msg,
    });
  }
};

export const addProgram = (body) => async (dispatch) => {
  try {
    dispatch({
      type: ADD_PROGRAM_BEGIN,
    });
    const headers = dispatch(getHeaders());
    const { data } = await axios.post(
      `${API}/api/program/program-master`,
      body,
      { headers }
    );
    console.log(data.data);
    toast.success("Program added successfully!");
    dispatch({
      type: ADD_PROGRAM_SUCCESS,
      data: data.data,
    });
  } catch (error) {
    console.log(error.response);
    const msg = error.response.data.message;
    toast.error(msg);
    dispatch({
      type: ADD_PROGRAM_ERROR,
      error: msg,
    });
  }
};

export const getProgramWithId = (id) => async (dispatch) => {
  try {
    dispatch({
      type: GET_PROGRAM_BEGIN,
    });
    const headers = dispatch(getHeaders());
    const { data } = await axios.get(
      `${API}/api/program/program-master/${id}`,
      { headers }
    );
    console.log(data.data);
    dispatch({
      type: GET_PROGRAM_SUCCESS,
      data: data.data,
    });
  } catch (error) {
    console.log(error.response);
    const msg = error.response.data.message;
    toast.error(msg);
    dispatch({
      type: GET_PROGRAM_ERROR,
      error: msg,
    });
  }
};

export const updateProgram = (id, body) => async (dispatch) => {
  try {
    dispatch({
      type: UPDATE_PROGRAM_BEGIN,
    });
    const headers = dispatch(getHeaders());
    const { data } = await axios.put(
      `${API}/api/program/program-master/${id}`,
      body,
      { headers }
    );
    console.log(data.data);
    toast.success("Program updated successfully!");
    dispatch({
      type: UPDATE_PROGRAM_SUCCESS,
      data: data.data,
    });
  } catch (error) {
    const msg = error.response.data.message;
    toast.error(msg);
    dispatch({
      type: UPDATE_PROGRAM_ERROR,
      error: msg,
    });
  }
};

export const deleteProgram = (id) => async (dispatch) => {
  try {
    dispatch({
      type: DELETE_PROGRAM_BEGIN,
    });
    const headers = dispatch(getHeaders());
    const { data } = await axios.delete(
      `${API}/api/program/program-master/${id}`,
      { headers }
    );
    toast.success("Program deleted successfully!");
    dispatch({
      type: DELETE_PROGRAM_SUCCESS,
      data: data.data,
    });
  } catch (error) {
    const msg = error.response.data.message;
    toast.error(msg);
    dispatch({
      type: DELETE_PROGRAM_ERROR,
      error: msg,
    });
  }
};
