import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { doctorSchema } from "../../../schemas";
import { ArrowUturnLeftIcon } from "@heroicons/react/24/outline";
import {
  addDoctor,
  getDoctorWithId,
  updateDoctor,
} from "../../../redux/doctor/actions";
import { FallingLinesLoader } from "../../../components/spinners/Spinner";
import Breadcrumb from "../../../components/Breadcrumb";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
const AddDoctor = () => {
  const pages = [{ title: "Doctor", href: "/doctors" }];

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id: doctorId } = useParams();
  const { loading, doctorData } = useSelector((state) => state.doctor);

  const initialValues = {
    first_name: "",
    last_name: "",
    email: "",
    phone_number: "",
    password: "",
    Confirm_Password: "",
    appointment_cost: "",
    is_follow_up_cost: "",
  };

  const [formState, setFormState] = useState(initialValues);

  useEffect(() => {
    document.title = "New Horizon | Add-Doctor";
    if (doctorId) dispatch(getDoctorWithId(doctorId));
  }, [doctorId]);

  useEffect(() => {
    if (doctorId && doctorData) {
      const newFormState = {
        first_name: doctorData.first_name,
        last_name: doctorData.last_name,
        email: doctorData.email,
        // phone_number: doctorData.phone_number,
        phone_number:
          doctorData.phone_number.length === 10
            ? "+91" + doctorData.phone_number
            : doctorData.phone_number,
        password: doctorData.password,
        Confirm_Password: doctorData.password,
        appointment_cost: doctorData.appointment_cost,
        is_follow_up_cost: doctorData.is_follow_up_cost,
      };
      setFormState(newFormState);
    }
  }, [doctorId, doctorData]);

  const {
    values,
    errors,
    touched,
    handleBlur,
    setValues,
    handleChange,
    handleSubmit,
  } = useFormik({
    enableReinitialize: true,
    initialValues: formState,
    validationSchema: doctorSchema,
    onSubmit: (values, action) => {
      console.log("On Submit");
      console.log(values);
      const body = { ...values };
      body.phone_number = "+" + values.phone_number;
      delete body.Confirm_Password;
      body.appointment_cost = body.appointment_cost
        ? Number(body.appointment_cost)
        : null;
      body.is_follow_up_cost = body.is_follow_up_cost
        ? Number(body.is_follow_up_cost)
        : null;
      if (doctorId) dispatch(updateDoctor(doctorId, body));
      else dispatch(addDoctor(body));
      action.resetForm();
      navigate("/doctors");
    },
  });

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <Breadcrumb pages={pages} />
      <div className="mb-5">
        <button
          type="button"
          onClick={() => navigate(-1)}
          className="inline-flex items-center rounded-md border mb-6 border-transparent bg-indigo-100 px-3 py-2 text-sm font-medium leading-4 text-indigo-700 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
        >
          <span className="w-5 mr-2">
            <ArrowUturnLeftIcon size={18} />
          </span>{" "}
          Back
        </button>
        <div className="sm:flex-auto">
          <h1 className="text-xl font-semibold text-gray-900">
            {doctorId ? "Update Doctor" : "Add Doctor"}
          </h1>
        </div>
      </div>
      {loading ? (
        <FallingLinesLoader />
      ) : (
        <form
          onSubmit={handleSubmit}
          className="space-y-8 divide-y divide-gray-200"
        >
          <div className="space-y-6 sm:space-y-5">
            <div className="space-y-6 sm:space-y-5 border-t">
              <div className="space-y-6 divide-y divide-gray-200">
                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4  sm:border-gray-200 sm:pt-5">
                  <label
                    htmlFor="location_name"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    First name
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <input
                      type="text"
                      name="first_name"
                      id="location-name"
                      autoComplete="off"
                      value={values.first_name}
                      placeholder="First Name"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-indigo-500 sm:max-w-xs sm:text-sm"
                    />
                    {errors.first_name && touched.first_name ? (
                      <p className="text-sm text-red-700">
                        {errors.first_name}
                      </p>
                    ) : null}
                  </div>
                </div>
                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4  sm:border-gray-200 sm:pt-5">
                  <label
                    htmlFor="last-name"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Last name
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <input
                      type="text"
                      name="last_name"
                      id="last-name"
                      autoComplete="off"
                      placeholder="Last Name"
                      value={values.last_name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-indigo-500 sm:max-w-xs sm:text-sm"
                    />
                    {errors.last_name && touched.last_name ? (
                      <p className="text-sm text-red-700">{errors.last_name}</p>
                    ) : null}
                  </div>
                </div>
                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4  sm:border-gray-200 sm:pt-5">
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Email
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <input
                      type="text"
                      name="email"
                      id="email"
                      placeholder="Email Address"
                      autoComplete="off"
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-indigo-500 sm:max-w-xs sm:text-sm"
                    />
                    {errors.email && touched.email ? (
                      <p className="text-sm text-red-700">{errors.email}</p>
                    ) : null}
                  </div>
                </div>
                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4  sm:border-gray-200 sm:pt-5">
                  <label
                    htmlFor="phone_number"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Phone number
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    {/* <input
                      type="text"
                      name="phone_number"
                      id="phone_number"
                      placeholder="Phone Number"
                      autoComplete="off"
                      value={values.phone_number}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-indigo-500 sm:max-w-xs sm:text-sm"
                    /> */}
                    <PhoneInput
                      country={"in"}
                      value={values.phone_number}
                      onChange={(phone) =>
                        setValues({ ...values, phone_number: phone })
                      }
                      inputProps={{
                        name: "phone_number",
                        required: true,
                        autoFocus: true,
                      }}
                    />
                    {errors.phone_number && touched.phone_number ? (
                      <p className="text-sm text-red-700">
                        {errors.phone_number}
                      </p>
                    ) : null}
                  </div>
                </div>

                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4  sm:border-gray-200 sm:pt-5">
                  <label
                    htmlFor="phone_number"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Appointment Cost
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <input
                      type="number"
                      name="appointment_cost"
                      id="appointment_cost"
                      placeholder="Appointment Cost"
                      autoComplete="off"
                      value={values.appointment_cost}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-indigo-500 sm:max-w-xs sm:text-sm"
                    />
                    {errors.appointment_cost && touched.appointment_cost ? (
                      <p className="text-sm text-red-700">
                        {errors.appointment_cost}
                      </p>
                    ) : null}
                  </div>
                </div>

                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4  sm:border-gray-200 sm:pt-5">
                  <label
                    htmlFor="phone_number"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Follow Up Cost
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <input
                      type="number"
                      name="is_follow_up_cost"
                      id="is_follow_up_cost"
                      placeholder="Follow Up Cost"
                      autoComplete="off"
                      value={values.is_follow_up_cost}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-indigo-500 sm:max-w-xs sm:text-sm"
                    />
                    {errors.is_follow_up_cost && touched.is_follow_up_cost ? (
                      <p className="text-sm text-red-700">
                        {errors.is_follow_up_cost}
                      </p>
                    ) : null}
                  </div>
                </div>

                {!doctorId && (
                  <div className="space-y-6 divide-y divide-gray-200">
                    <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4  sm:border-gray-200 sm:pt-5">
                      <label
                        htmlFor="password"
                        className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                      >
                        Password
                      </label>
                      <div className="mt-1 sm:col-span-2 sm:mt-0">
                        <input
                          type="password"
                          name="password"
                          id="password"
                          placeholder="Password"
                          autoComplete="off"
                          value={values.password}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-indigo-500 sm:max-w-xs sm:text-sm"
                        />
                        {errors.password && touched.password ? (
                          <p className="text-sm text-red-700">
                            {errors.password}
                          </p>
                        ) : null}
                      </div>
                    </div>
                    <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4  sm:border-gray-200 sm:pt-5">
                      <label
                        htmlFor="Cpassword"
                        className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                      >
                        Confirm password
                      </label>
                      <div className="mt-1 sm:col-span-2 sm:mt-0">
                        <input
                          type="password"
                          name="Confirm_Password"
                          id="Confirm_Password"
                          placeholder="Confirm Password"
                          autoComplete="off"
                          value={values.Confirm_Password}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-indigo-500 sm:max-w-xs sm:text-sm"
                        />
                        {errors.Confirm_Password && touched.Confirm_Password ? (
                          <p className="text-sm text-red-700">
                            {errors.Confirm_Password}
                          </p>
                        ) : null}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="pt-5">
            <div className="flex justify-end">
              <button
                type="submit"
                className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              >
                Submit
              </button>
            </div>
          </div>
        </form>
      )}
    </div>
  );
};

export default AddDoctor;
