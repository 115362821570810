/* eslint-disable */
import axios from 'axios'
import { getHeaders } from '../auth/actions'
import { toast } from 'react-toast'
import { 
    GET_ALL_MEDICINE_BEGIN,
    GET_ALL_MEDICINE_SUCCESS,
    GET_ALL_MEDICINE_ERROR,
    ADD_MEDICINE_BEGIN,
    ADD_MEDICINE_SUCCESS,
    ADD_MEDICINE_ERROR,
    GET_MEDICINE_BEGIN,
    GET_MEDICINE_SUCCESS,
    GET_MEDICINE_ERROR,
    UPDATE_MEDICINE_BEGIN,
    UPDATE_MEDICINE_SUCCESS,
    UPDATE_MEDICINE_ERROR,
    DELETE_MEDICINE_BEGIN,
    DELETE_MEDICINE_SUCCESS,
    DELETE_MEDICINE_ERROR
 } from './constants'

const API = process.env.REACT_APP_API_URL

export const getAllMedicines = () => async(dispatch) => {
  try {
    dispatch({
      type: GET_ALL_MEDICINE_BEGIN
    })
    const headers = dispatch(getHeaders());
    const {data} = await axios.get(`${API}/api/medicine`, { headers })
    console.log(data.data)

    dispatch({
      type: GET_ALL_MEDICINE_SUCCESS, 
      data: data.data
    })
  }
  catch (error) {
    const msg = error.response.data.message;
    toast.error(msg)
    console.log(error)
    dispatch({
      type: GET_ALL_MEDICINE_ERROR,
      error: msg
    })
  }
}

export const addMedicine = (body) => async(dispatch) => {
    try {
        dispatch({
          type: ADD_MEDICINE_BEGIN
        })
        const headers = dispatch(getHeaders());
        const {data} = await axios.post(`${API}/api/medicine`, body, { headers })
        console.log(data.data)
        toast.success("Medicine added successfully!")
        dispatch({
          type: ADD_MEDICINE_SUCCESS, 
          data: data.data
        })
      }
      catch (error) {
        console.log(error.response)
        const msg = error.response.data.message;
        toast.error(msg)
        dispatch({
          type: ADD_MEDICINE_ERROR,
          error: msg
        })
      }
}

export const getMedicineWithId = (id) => async(dispatch) => {
  try {
    dispatch({
      type: GET_MEDICINE_BEGIN
    })
    const headers = dispatch(getHeaders());
    const {data} = await axios.get(`${API}/api/medicine/${id}`, { headers })
    console.log(data.data)
    dispatch({
      type: GET_MEDICINE_SUCCESS, 
      data: data.data
    })
  }
  catch (error) {
    console.log(error.response)
    const msg = error.response.data.message;
    toast.error(msg)
    dispatch({
      type: GET_MEDICINE_ERROR,
      error: msg
    })
  }
}

export const updateMedicine = (id, body) => async(dispatch) => {
  try {
    dispatch({
      type: UPDATE_MEDICINE_BEGIN
    })
    const headers = dispatch(getHeaders());
    const {data} = await axios.put(`${API}/api/medicine/${id}`, body, { headers })
    console.log(data.data)
    toast.success("Medicine updated successfully!")
    dispatch({
      type: UPDATE_MEDICINE_SUCCESS, 
      data: data.data
    })
  }
  catch (error) {
    const msg = error.response.data.message;
    toast.error(msg)
    dispatch({
      type: UPDATE_MEDICINE_ERROR,
      error: msg
    })
  }
}

export const deleteMedicine = (id) => async(dispatch) => {
  try {
    dispatch({
      type: DELETE_MEDICINE_BEGIN
    })
    const headers = dispatch(getHeaders());
    const {data} = await axios.delete(`${API}/api/medicine/${id}`, { headers })
    toast.success("Medicine deleted successfully!")
    dispatch({
      type: DELETE_MEDICINE_SUCCESS, 
      data: data.data
    })
  }
  catch (error) {
    const msg = error.response.data.message;
    toast.error(msg)
    dispatch({
      type: DELETE_MEDICINE_ERROR,
      error: msg
    })
  }
}