import { useState, useEffect } from 'react';

export default function Text_area_input(props) {
  let data = props.config;
  let [visibility, set_visibility] = useState(true);

  useEffect(() => {
    if (data.child) {
      let foi = Object.keys(props.all_fields).filter((field) => {
        if (props.all_fields[field].label.trim() == data.parent?.label.trim()) {
          return props.all_fields[field];
        }
      });
      if (
        props.current_data[props.all_fields[foi]?.ref_id] == data.parent?.option
      ) {
        set_visibility(true);
      } else {
        if (props.current_data[data.ref_id] != null || data.defaultValue != null){
          props.record_change(null, data.ref_id);
          data.defaultValue=null;
        }
        set_visibility(false);
      }
    }
  }, [props.current_data]);
  return (
    <>
      {visibility ? (
        <div className="border-b pl-5 pb-5">
          <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4  sm:border-gray-200 ">
            <label
              htmlFor="about"
              className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
            >
              {data.label}
            </label>
            <div className="mt-1 sm:col-span-2 sm:mt-0">
              <textarea
                id="about"
                name="about"
                
                value={props.current_data[data.ref_id]?props.current_data[data.ref_id]:''}
                placeholder={data.placeholder}
                minLength={data.minlength}
                required={data.required}
                maxLength={data.maxlength}
                onChange={(e) => {
                  props.record_change(e.target.value, data.ref_id);
                }}
                rows={3}
                className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                defaultValue={data.defaultValue}
              />
            </div>
          </div>{' '}
        </div>
      ) : (
        ''
      )}
      {/* <div className="mt-5 flex justify-left">
        <div className="mb-3 xl:w-96">
          <label
            htmlFor="message"
            class="block mb-2 text-sm font-medium text-gray-900 "
          >
            {data.label}
          </label>
          <textarea
            id="message"
            rows="4"
            placeholder={data.placeholder}
            minLength={data.minlength}
            maxLength={data.maxlength}
            class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 "
          ></textarea>
        </div>
      </div> */}
    </>
  );
}
