import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import { domainSchema } from "../../../schemas";
import { ArrowUturnLeftIcon } from "@heroicons/react/20/solid";
import { useDispatch, useSelector } from "react-redux";
import { addDomain, getDomainWithId, updateDomain } from "../../../redux/DomainMaster/actions";
import { getAllAgeGroups } from "../../../redux/AgeGroupMaster/actions";
import { FallingLinesLoader } from "../../../components/spinners/Spinner";
import Breadcrumb from "../../../components/Breadcrumb";

const AddDomain = () => {
  const pages = [{ title: "Domain", href: "/domain-master" }];

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id: domainId } = useParams();
  const { loading, domainData } = useSelector((state) => state.domain);
  const { list: ageGroupList } = useSelector((state) => state.ageGroup);

  const initialValues = {
    name: "",
    age_group_id: "",
  };

  const [formState, setFormState] = useState(initialValues);

  useEffect(() => {
    document.title = "New Horizon | Add-Domain";
    dispatch(getAllAgeGroups());
    if (domainId) dispatch(getDomainWithId(domainId));
  }, [domainId]);

  useEffect(() => {
    if (domainId && domainData) {
      const newFormState = {
        name: domainData.name,
        age_group_id: parseInt(domainData.age_group_id),
      };
      setFormState(newFormState);
    }
  }, [domainId, domainData]);

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      enableReinitialize: true,
      initialValues: formState,
      validationSchema: domainSchema,
      onSubmit: (values, action) => {
        // TODO: Optimize code here
        const body = {
          name: values.name,
          age_group_id: parseInt(values.age_group_id),
        };
        if (domainId) dispatch(updateDomain(domainId, body));
        else dispatch(addDomain(body));
        action.resetForm();
        navigate("/domain-master");
      },
    });
  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <Breadcrumb pages={pages} />
      <div>
        <button
          type="button"
          onClick={() => navigate(-1)}
          className="inline-flex items-center rounded-md border mb-6 border-transparent bg-indigo-100 px-3 py-2 text-sm font-medium leading-4 text-indigo-700 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
        >
          <span className="w-5 mr-2">
            <ArrowUturnLeftIcon size={18} />
          </span>{" "}
          Back
        </button>
        <div className="sm:flex-auto">
          <h1 className="text-xl font-semibold text-gray-900">
            {domainId ? "Update Domain" : "Add Domain"}
          </h1>
        </div>
      </div>
      {loading ? (
        <FallingLinesLoader />
      ) : (
        <form
          onSubmit={handleSubmit}
          className="space-y-8 divide-y divide-gray-200"
        >
          <div className="space-y-6 sm:space-y-5">
            <div className="space-y-6 sm:space-y-5 sm:pt-5">
              <div className="space-y-6 sm:space-y-5">
                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                  <label
                    htmlFor="name"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Name
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <input
                      type="text"
                      name="name"
                      id="name"
                      autoComplete="off"
                      value={values.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                    />
                    {errors.name && touched.name ? (
                      <p className="text-red-700">{errors.name}</p>
                    ) : null}
                  </div>
                </div>
                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                  <label
                    htmlFor="age_group_id"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Age group
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <select
                      id="age_group_id"
                      name="age_group_id"
                      autoComplete="off"
                      value={values.age_group_id}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                    >
                      <option className="text-gray-700" value="">
                        Select a Age Group
                      </option>

                      {ageGroupList.map((d, idx) => (
                        <option key={idx} value={d.id}>
                          {d.name}
                        </option>
                      ))}
                    </select>
                    {errors.age_group_id && touched.age_group_id ? (
                      <p className="text-red-700">{errors.age_group_id}</p>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="pt-5">
            <div className="flex justify-end">
              <button
                type="submit"
                className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              >
                Submit
              </button>
            </div>
          </div>
        </form>
      )}
    </div>
  );
};

export default AddDomain;
