/* eslint-disable */
import {
  GET_ALL_SCHEDULE_BEGIN,
  GET_ALL_SCHEDULE_SUCCESS,
  GET_ALL_SCHEDULE_ERROR,
  ADD_SCHEDULE_BEGIN,
  ADD_SCHEDULE_SUCCESS,
  ADD_SCHEDULE_ERROR,
  GET_SCHEDULE_BEGIN,
  GET_SCHEDULE_ERROR,
  GET_SCHEDULE_SUCCESS,
  UPDATE_SCHEDULE_BEGIN,
  UPDATE_SCHEDULE_SUCCESS,
  UPDATE_SCHEDULE_ERROR,
  DELETE_SCHEDULE_BEGIN,
  DELETE_SCHEDULE_SUCCESS,
  DELETE_SCHEDULE_ERROR,
} from "./constants";

const initState = {
  list: [],
  loading: false,
  error: null,
};

const scheduleReducer = (state = initState, action) => {
  const { type, data, error } = action;
  switch (type) {
    case GET_ALL_SCHEDULE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case GET_ALL_SCHEDULE_SUCCESS:
      return {
        ...state,
        list: data,
        loading: false,
      };
    case GET_ALL_SCHEDULE_ERROR:
      return {
        ...state,
        error,
        loading: false,
      };
    case ADD_SCHEDULE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case ADD_SCHEDULE_SUCCESS:
      return {
        ...state,
        loading: false,
        list: [...state.list, data],
      };
    case ADD_SCHEDULE_ERROR:
      return {
        ...state,
        error,
        loading: false,
      };
    case GET_SCHEDULE_BEGIN:
      return {
        ...state,
        loading: true,
        doctorData: "",
      };
    case GET_SCHEDULE_SUCCESS:
      return {
        ...state,
        loading: false,
        scheduleData: data,
      };
    case GET_SCHEDULE_ERROR:
      return {
        ...state,
        error,
        loading: false,
      };
    case UPDATE_SCHEDULE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_SCHEDULE_SUCCESS:
      return {
        ...state,
        loading: false,
        list: state.list.map((ele) => {
          if (ele.id === data.id) {
            return { ...data };
          } else return ele;
        }),
      };
    case UPDATE_SCHEDULE_ERROR:
      return {
        ...state,
        error,
        loading: false,
      };
    case DELETE_SCHEDULE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case DELETE_SCHEDULE_SUCCESS:
      return {
        ...state,
        loading: false,
        list: state.list.filter((ele) => ele.id !== data.id),
      };
    case DELETE_SCHEDULE_ERROR:
      return {
        ...state,
        error,
        loading: false,
      };
    default:
      return state;
  }
};

export default scheduleReducer;
