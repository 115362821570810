import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getAllFormGroups, getAllForms } from '../../../redux/forms/action';
import { FallingLinesLoader } from '../../../components/spinners/Spinner';
import { forms_group_columns } from '../../../components/tables/tableheader';
import Table from '../../../components/tables/table';

const FormGroup = () => {
  const dispatch = useDispatch();
  const { loading, list: fromgroupList } = useSelector((state) => state.forms);
  const { list: formList } = useSelector((state) => state.forms);
  const { id: formId } = useParams();
  // const formName = JSON.stringify(formList)
  console.log('see id ', formId);
  useEffect(() => {
    document.title = 'New Horizon | Forms-Group';
    dispatch(getAllForms(formId));
    if (formId) dispatch(getAllFormGroups(formId));
  }, [formId]);

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      {/* <Breadcrumb pages={pages} /> */}
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-xl font-semibold text-gray-900">Form Group</h1>
        </div>
        <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none"></div>
      </div>

      {loading ? (
        <FallingLinesLoader />
      ) : (
        <Table data={fromgroupList} columns={forms_group_columns()} />
      )}
    </div>
  );
};

export default FormGroup;
