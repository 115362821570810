export const ADD_AVAILABILITY_BEGIN = "ADD_AVAILABILITY_BEGIN"
export const ADD_AVAILABILITY_SUCCESS = "ADD_AVAILABILITY_SUCCESS"
export const ADD_AVAILABILITY_ERROR = "ADD_AVAILABILITY_ERROR"

export const GET_AVAILABILITY_BEGIN = "GET_AVAILABILITY_BEGIN"
export const GET_AVAILABILITY_SUCCESS = "GET_AVAILABILITY_SUCCESS"
export const GET_AVAILABILITY_ERROR = "GET_AVAILABILITY_ERROR"

export const UPDATE_AVAILABILITY_BEGIN = "UPDATE_AVAILABILITY_BEGIN"
export const UPDATE_AVAILABILITY_SUCCESS = "UPDATE_AVAILABILITY_SUCCESS"
export const UPDATE_AVAILABILITY_ERROR = "UPDATE_AVAILABILITY_ERROR"

export const DELETE_AVAILABILITY_BEGIN = "DELETE_AVAILABILITY_BEGIN"
export const DELETE_AVAILABILITY_SUCCESS = "DELETE_AVAILABILITY_SUCCESS"
export const DELETE_AVAILABILITY_ERROR = "DELETE_AVAILABILITY_ERROR"