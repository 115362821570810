/* eslint-disable */
import axios from "axios";
import { getHeaders } from "../auth/actions";
import { toast } from "react-hot-toast";
import {
  GET_ALL_EXCEPTION_BEGIN,
  GET_ALL_EXCEPTION_SUCCESS,
  GET_ALL_EXCEPTION_ERROR,
  ADD_EXCEPTION_BEGIN,
  ADD_EXCEPTION_SUCCESS,
  ADD_EXCEPTION_ERROR,
  GET_EXCEPTION_BEGIN,
  GET_EXCEPTION_SUCCESS,
  GET_EXCEPTION_ERROR,
  UPDATE_EXCEPTION_BEGIN,
  UPDATE_EXCEPTION_SUCCESS,
  UPDATE_EXCEPTION_ERROR,
  DELETE_EXCEPTION_BEGIN,
  DELETE_EXCEPTION_SUCCESS,
  DELETE_EXCEPTION_ERROR,
} from "./constants";

const API = process.env.REACT_APP_API_URL;

export const getAllExceptions = (scheduleId) => async (dispatch) => {
  try {
    dispatch({
      type: GET_ALL_EXCEPTION_BEGIN,
    });
    const headers = dispatch(getHeaders());
    const { data } = await axios.get(
      `${API}/api/schedule-master/availibility/exception/${scheduleId}`,
      { headers }
    );
    console.log(data.data);

    dispatch({
      type: GET_ALL_EXCEPTION_SUCCESS,
      data: data.data,
    });
  } catch (error) {
    const msg = error.response.data.message;
    toast.error(msg);
    console.log(error);
    dispatch({
      type: GET_ALL_EXCEPTION_ERROR,
      error: msg,
    });
  }
};

export const addException = (scheduleId, body) => async (dispatch) => {
  try {
    dispatch({
      type: ADD_EXCEPTION_BEGIN,
    });
    const headers = dispatch(getHeaders());
    const { data } = await axios.post(
      `${API}/api/schedule-master/availibility/exception/${scheduleId}`,
      body,
      { headers }
    );
    console.log(data.data);
    toast.success("Override added successfully!");
    dispatch({
      type: ADD_EXCEPTION_SUCCESS,
      data: data.data,
    });
  } catch (error) {
    console.log(error.response);
    const msg = error.response.data.message;
    toast.error(msg);
    dispatch({
      type: ADD_EXCEPTION_ERROR,
      error: msg,
    });
  }
};

// export const getLocationWithId = (id) => async(dispatch) => {
//   try {
//     dispatch({
//       type: GET_EXCEPTION_BEGIN
//     })
//     const headers = dispatch(getHeaders());
//     const {data} = await axios.get(`${API}/api/location/${id}`, { headers })
//     console.log(data.data)
//     dispatch({
//       type: GET_EXCEPTION_SUCCESS,
//       data: data.data
//     })
//   }
//   catch (error) {
//     console.log(error.response)
//     const msg = error.response.data.message;
//     toast.error(msg)
//     dispatch({
//       type: GET_EXCEPTION_ERROR,
//       error: msg
//     })
//   }
// }

// export const updateLocation = (id, body) => async(dispatch) => {
//   try {
//     dispatch({
//       type: UPDATE_EXCEPTION_BEGIN
//     })
//     const headers = dispatch(getHeaders());
//     const {data} = await axios.put(`${API}/api/location/${id}`, body, { headers })
//     console.log(data.data)
//     toast.success("Location updated successfully!")
//     dispatch({
//       type: UPDATE_EXCEPTION_SUCCESS,
//       data: data.data
//     })
//   }
//   catch (error) {
//     const msg = error.response.data.message;
//     toast.error(msg)
//     dispatch({
//       type: UPDATE_EXCEPTION_ERROR,
//       error: msg
//     })
//   }
// }

export const deleteException = (id) => async (dispatch) => {
  try {
    dispatch({
      type: DELETE_EXCEPTION_BEGIN,
    });
    const headers = dispatch(getHeaders());
    const { data } = await axios.delete(
      `${API}/api/schedule-master/availibility/exception/${id}`,
      { headers }
    );
    toast.success("Exception deleted successfully!");
    dispatch({
      type: DELETE_EXCEPTION_SUCCESS,
      data: data.data,
    });
  } catch (error) {
    const msg = error.response.data.message;
    toast.error(msg);
    dispatch({
      type: DELETE_EXCEPTION_ERROR,
      error: msg,
    });
  }
};
