export const GET_ALL_PATIENTS_BEGIN = "GET_ALL_PATIENTS_BEGIN"
export const GET_ALL_PATIENTS_SUCCESS = "GET_ALL_PATIENTS_SUCCESS"
export const GET_ALL_PATIENTS_ERROR = "GET_ALL_PATIENTS_ERROR"

export const ADD_PATIENT_BEGIN = "ADD_PATIENT_BEGIN"
export const ADD_PATIENT_SUCCESS = "ADD_PATIENT_SUCCESS"
export const ADD_PATIENT_ERROR = "ADD_PATIENT_ERROR"

export const GET_PATIENT_BEGIN = "GET_PATIENT_BEGIN"
export const GET_PATIENT_SUCCESS = "GET_PATIENT_SUCCESS"
export const GET_PATIENT_ERROR = "GET_PATIENT_ERROR"

export const UPDATE_PATIENT_BEGIN = "UPDATE_PATIENT_BEGIN"
export const UPDATE_PATIENT_SUCCESS = "UPDATE_PATIENT_SUCCESS"
export const UPDATE_PATIENT_ERROR = "UPDATE_PATIENT_ERROR"

export const DELETE_PATIENT_BEGIN = "DELETE_PATIENT_BEGIN"
export const DELETE_PATIENT_SUCCESS = "DELETE_PATIENT_SUCCESS"
export const DELETE_PATIENT_ERROR = "DELETE_PATIENT_ERROR"