import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

import { FallingLinesLoader } from "../../../components/spinners/Spinner";

// import Table from "../../../components/tables/table";
// import { planViewColumns } from "../../../components/tables/tableheader";
import { ArrowUturnLeftIcon } from "@heroicons/react/20/solid";
import axios from "axios";
import { getHeaders } from "../../../redux/auth/actions";
import { toast } from "react-hot-toast";
import Select from "react-select";
import CustomTablePlanView from "../../../components/tables/CustomTable";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  PDFViewer,
  PDFDownloadLink,
} from "@react-pdf/renderer";
import Modal from "../../../components/Modal";
import { Formik } from "formik";
import Table from "../../../components/tables/table";
import {
  extra_activity_columns,
  extra_feedback,
} from "../../../components/tables/tableheader";
const api = process.env.REACT_APP_API_URL;
const PlanView = () => {
  const { id } = useParams();
  const { month_id } = useParams();
  const [month, setMonthFilter] = useState(month_id || 1);
  const [week, setWeek] = useState([1]);
  const [department, setDepartment] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [planData, setPlanData] = useState({
    loading: true,
    data: [],
    patientData: {},
    filrData: null,
    allDat: null,
  });

  useEffect(() => {
    if (planData.data && planData.data.length > 0) {
      const data = filterData(planData.data, month);
      setPlanData({
        ...planData,
        filrData: filterData(planData.data, month),
      });
      setDepartment(data[0]?.department_id);
      // console.log("data");
      // console.log(data);
    }
  }, [planData.data, month]);

  useEffect(() => {
    document.title = "New Horizon | Therapist - Plan View";
    getPlanById();
    getGoalsById();
  }, []);

  const filterData = (data, month) => {
    const filteredData = data.filter((item) => item.month == Number(month));
    return filteredData;
  };

  async function getPlanById(e) {
    if (e) e.preventDefault();

    const headers = await dispatch(getHeaders());
    try {
      const res = await axios.get(`${api}/api/plan-builder/${id}`, {
        headers: headers,
      });
      setPlanData({
        ...planData,
        patientData: res?.data?.data?.details,
        data: res?.data?.data?.final_data,
        allDat: res?.data?.data,
        loading: false,
      });
    } catch (error) {
      console.log(error.message);
      toast.error(error.message);
    }
  }

  const [goals, setGoals] = useState({
    loading: true,
    data: [],
  });

  async function getGoalsById() {
    const headers = await dispatch(getHeaders());
    try {
      const res = await axios.get(`${api}/api/goal/patient-wise/${id}`, {
        headers: headers,
      });
      setGoals({
        data: res?.data?.data,
        loading: false,
      });
    } catch (error) {
      console.log(error.message);
      toast.error(error.message);
    }
  }

  const inti = {
    state: false,
  };
  const pageStyle = {
    paddingTop: 16,
    paddingHorizontal: 40,
    paddingBottom: 56,
  };

  const tableStyle = {
    display: "table",
    width: "auto",
  };

  const tableRowStyle = {
    flexDirection: "row",
  };

  const firstTableColHeaderStyle = {
    width: "20%",
    borderStyle: "solid",
    borderColor: "#000",
    borderBottomColor: "#000",
    borderWidth: 1,
    backgroundColor: "#bdbdbd",
  };

  const tableColHeaderStyle = {
    width: "20%",
    borderStyle: "solid",
    borderColor: "#000",
    borderBottomColor: "#000",
    borderWidth: 1,
    borderLeftWidth: 0,
    backgroundColor: "#bdbdbd",
  };

  const firstTableColStyle = {
    width: "20%",
    borderStyle: "solid",
    borderColor: "#000",
    borderWidth: 1,
    borderTopWidth: 0,
  };

  const tableColStyle = {
    width: "20%",
    borderStyle: "solid",
    borderColor: "#000",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  };

  const tableCellHeaderStyle = {
    textAlign: "center",
    margin: 4,
    fontSize: 12,
    fontWeight: "bold",
    height: "auto",
  };
  const tableCellStyle = {
    textAlign: "center",
    margin: 5,
    fontSize: 10,
    whiteSpace: "break-word",
    wordWrap: "pre-wrap",
  };

  const styles = StyleSheet.create({
    logo: {
      position: "absolute",
      right: 0,
      width: 200,
      height: 80,
    },
    titleHead: {
      position: "relative",
      height: 80,
    },

    header: {
      fontSize: 12,
      marginTop: 25,
      marginBottom: 10,
      marginHorizontal: 20,
      fontWeight: "bold",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    headerDiv: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      marginBottom: 5,
    },
    pageNumber: {
      position: "absolute",
      fontSize: 12,
      bottom: 30,
      left: 0,
      right: 0,
      textAlign: "center",
      color: "grey",
    },
    body: {
      paddingTop: 35,
      paddingBottom: 65,
      paddingHorizontal: 35,
    },
    pageContainer: {
      marginHorizontal: 20,
      marginBottom: 7,
    },
    bellowHeader: {
      margin: 12,
      fontSize: 14,
      textAlign: "justify",
      fontFamily: "Times-Roman",
    },
    HeroHeading: {
      fontSize: 14,
      textAlign: "left",
      fontFamily: "Times-Roman",
      marginHorizontal: 20,
      borderTop: "1px solid #000",
      borderBottom: "1px solid #000",
    },
    page: {
      marginTop: 20,
      paddingBottom: 65,
      paddingHorizontal: 25,
    },
    headerSecond: {
      fontSize: 18,
      textAlign: "left",
      fontWeight: "900",
      marginHorizontal: 20,
      marginBottom: 10,
      marginTop: 20,
    },
    dataDiv: {
      display: "flex",
      flexDirection: "row",
      marginRight: 8,
      marginBottom: 5,
      fontSize: 12,
      fontWeight: "normal",
    },
    bold: {
      fontWeight: "bold",
      fontSize: 16,
      marginBottom: 10,
    },
    text: {
      fontSize: 8,
      fontWeight: "normal",
    },
    row: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      paddingHorizontal: 10,
      paddingVertical: 8,
      borderBottomWidth: 1,
      borderBottomColor: "#ccc",
      marginHorizontal: 18,
    },
    container: {
      flexDirection: "column",
      alignItems: "flex-start",
      padding: 10,
    },
    departmentContainer: {
      flexDirection: "row",
      borderBottom: "1pt solid #ccc",
      paddingBottom: 4,
      marginBottom: 4,
    },
    departmentTitle: {
      fontSize: 16,
      fontWeight: "bold",
      marginRight: 8,
      color: "#000",
    },
    goalContainer: {
      flexDirection: "row",
      borderBottom: "1pt solid #ccc",
      paddingBottom: 4,
      marginBottom: 4,
    },
    goalTitle: {
      fontSize: 16,
      fontWeight: "bold",
      marginRight: 8,
      color: "#000",
    },
    goalText: {
      fontSize: 14,
      color: "#000",
    },
    commentContainer: {
      flexDirection: "row",
      borderBottom: "1pt solid #ccc",
      paddingBottom: 4,
      marginBottom: 4,
    },
    commentTitle: {
      fontSize: 16,
      fontWeight: "bold",
      marginRight: 8,
      color: "#000",
    },
    commentText: {
      fontSize: 14,
      color: "#000",
    },
    container: {
      flexDirection: "column",
      alignItems: "flex-start",
      padding: 10,
    },
    weekNumber: {
      fontSize: 18,
      fontWeight: "bold",
      marginBottom: 10,
      color: "#000",
    },
    departmentContainer: {
      flexDirection: "row",
      borderBottom: "1pt solid #ccc",
      paddingBottom: 4,
      marginBottom: 4,
    },
    departmentTitle: {
      fontSize: 16,
      fontWeight: "bold",
      marginRight: 8,
      color: "#000",
    },
    goalContainer: {
      flexDirection: "row",
      borderBottom: "1pt solid #ccc",
      paddingBottom: 4,
      marginBottom: 4,
    },
    goalTitle: {
      fontSize: 16,
      fontWeight: "bold",
      marginRight: 8,
      color: "#000",
    },
    goalText: {
      fontSize: 14,
      color: "#000",
    },
    activityContainer: {
      flexDirection: "column",
      marginLeft: 16,
      marginBottom: 8,
    },
    activityTitle: {
      fontSize: 14,
      fontWeight: "bold",
      marginBottom: 4,
      color: "#000",
    },
    activityDescription: {
      fontSize: 14,
      color: "#000",
    },
  });
  const [pdfViewer, setState] = useState(inti);
  // PDF TEMPLATE START HERE
  function replaceNewLineWithSpace(inputString) {
    if (typeof inputString !== "string") {
      throw new Error("Input must be a string");
    }

    // Use the replace method with a regular expression to replace all occurrences of \n with a space
    const resultString = inputString.replace(/\n/g, " ");

    return resultString;
  }
  const renderPDFVirewer = () => {
    const PdfFile = () => (
      <Document>
        <Page size="A4" style={styles.page}>
          {planData.filrData &&
            planData.filrData?.length > 0 &&
            planData.filrData
              ?.filter((item) => item.department_id == department)
              .map((item, idx) => {
                console.log(item);
                return (
                  <View style={styles.header}>
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        marginBottom: 5,
                        gap: 10,
                      }}
                    >
                      <View style={styles.headerDiv}>
                        <Text style={styles.title}>Child's Name :</Text>
                        <Text>
                          {planData.patientData?.appointment?.patient
                            ?.firstName +
                            " " +
                            planData.patientData?.appointment?.patient
                              ?.lastName}
                        </Text>
                      </View>
                      <View style={styles.headerDiv}>
                        <Text style={styles.title}>Age : </Text>
                        <Text style={styles.title}>
                          {planData.patientData?.appointment?.patient?.age}
                        </Text>
                      </View>
                      <View style={styles.headerDiv}>
                        <Text style={styles.title}>Month : </Text>
                        <Text style={styles.title}>{month_id || month}</Text>
                      </View>
                      <View style={styles.headerDiv}>
                        <Text style={styles.title}>Department : </Text>
                        <Text>{item?.department?.name}</Text>
                      </View>
                    </View>
                  </View>
                );
              })}

          <View style={styles.HeroHeading}>
            <Text>Plan View</Text>
          </View>
          {/*Header ends */}
          {/* Body */}
          {planData.filrData &&
            planData.filrData?.length > 0 &&
            planData.filrData
              ?.filter((item) => item.department_id == department)
              .map((item, idx) => (
                <>
                  {planData.filrData &&
                    planData.filrData.length > 0 &&
                    item?.weekly_data_of_current_month_dep?.map(
                      (WeekItem, idx) => (
                        <>
                          <View
                            style={{
                              marginHorizontal: 18,
                              marginTop: 10,
                            }}
                          >
                            <Text
                              style={{
                                fontSize: 14,
                                marginBottom: 10,
                              }}
                            >
                              Week {WeekItem.week_number}
                            </Text>

                            {WeekItem?.week_data?.goal_activity_data?.map(
                              (item, idx) => (
                                <>
                                  {item?.goal_activity_datas?.map(
                                    (act, index) => (
                                      <View
                                        // wrap={false}
                                        style={{
                                          marginTop: 10,
                                          border: "1pt solid #ccc",
                                          padding: 10,
                                          borderRadius: 4,
                                        }}
                                      >
                                        <Text
                                          style={{
                                            fontSize: 15,
                                            fontWeight: "bold",
                                            marginBottom: 10,
                                          }}
                                        >
                                          Goal :{" "}
                                          <Text
                                            style={{
                                              fontSize: 12,
                                              fontWeight: "normal",
                                              marginBottom: 10,
                                            }}
                                          >
                                            {item?.goal_name}
                                          </Text>{" "}
                                        </Text>
                                        <Text
                                          style={{
                                            fontSize: 14,
                                            fontWeight: "bold",
                                            marginBottom: 14,
                                          }}
                                        >
                                          Module :{" "}
                                          <Text
                                            style={{
                                              fontSize: 12,
                                              fontWeight: "normal",
                                            }}
                                          >
                                            {act.moduleMaster?.name}
                                          </Text>{" "}
                                        </Text>
                                        {act?.Plan_builder_weekly_activity?.map(
                                          (weekly_act) => (
                                            <View
                                              style={{
                                                display: "flex",
                                                flexDirection: "column",
                                              }}
                                            >
                                              <Text
                                                style={{
                                                  fontSize: 14,
                                                  fontWeight: "bold",
                                                  marginBottom: 7,
                                                }}
                                              >
                                                Activity :{" "}
                                                <Text
                                                  style={{
                                                    fontSize: 12,
                                                    fontWeight: "normal",
                                                  }}
                                                >
                                                  {weekly_act?.activity?.title}
                                                </Text>{" "}
                                              </Text>
                                              <Text
                                                style={{
                                                  fontSize: 14,
                                                  fontWeight: "bold",
                                                  marginTop: 7,
                                                }}
                                              >
                                                Description :{" "}
                                                <Text
                                                  style={{
                                                    fontSize: 12,
                                                    fontWeight: "normal",
                                                  }}
                                                >
                                                  {weekly_act?.activity?.description
                                                    .replace("\n", " ")
                                                    .trim()}
                                                </Text>{" "}
                                              </Text>
                                              <View>
                                                <Text
                                                  style={{
                                                    fontSize: 14,
                                                    fontWeight: "bold",
                                                    marginBottom: 14,
                                                  }}
                                                >
                                                  Milestone :{" "}
                                                  <View
                                                    style={{
                                                      fontSize: 12,
                                                      fontWeight: "normal",
                                                      flexDirection: "column",
                                                    }}
                                                  >
                                                    <Text
                                                      style={{
                                                        fontSize: 12,
                                                        fontWeight: "normal",
                                                      }}
                                                    >
                                                      {weekly_act?.activity?.ActivityDomainMaster?.milestones
                                                        ?.map((e) => e)
                                                        .join(" , ")}
                                                    </Text>
                                                  </View>
                                                </Text>
                                              </View>
                                            </View>
                                          )
                                        )}
                                      </View>
                                    )
                                  )}
                                </>
                              )
                            )}
                          </View>
                        </>
                      )
                    )}
                </>
              ))}
          <View
            style={{
              fontSize: 12,
              marginTop: 5,
              marginHorizontal: 20,
              fontWeight: "bold",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Text style={styles.title}>
              Suggestion :{" "}
              {planData.allDat.plan_builder_master.suggestion || ""}
            </Text>
          </View>
          {/* Body ends */}

          {/* bottom part */}
          <Text
            style={styles.pageNumber}
            render={({ pageNumber, totalPages }) =>
              `${pageNumber} / ${totalPages}`
            }
          />
        </Page>
      </Document>
    );
    return (
      <Modal
        title={
          <button className="inline-flex items-center rounded-md border mt-6 border-transparent bg-indigo-100 px-3 py-2 text-sm font-medium leading-4 text-indigo-700 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
            <PDFDownloadLink
              document={<PdfFile />}
              fileName={`${
                planData.patientData?.appointment?.patient?.firstName ||
                "patient"
              }_planView.pdf`}
            >
              {({ blob, url, loading, error }) =>
                loading ? "Generateing..." : "Print"
              }
            </PDFDownloadLink>
          </button>
        }
        open={pdfViewer.state}
        setOpen={(e) => setState({ ...pdfViewer, state: false })}
        size="max-w-2xl"
      >
        <PDFViewer height={800} width={600} showToolbar={false}>
          <PdfFile />
        </PDFViewer>
      </Modal>
    );
  };
  const [dateFilter, setDateFilter] = useState("");
  return planData.loading ? (
    <FallingLinesLoader />
  ) : (
    <div className="px-4 sm:px-6 lg:px-8 ">
      <div>
        {renderPDFVirewer()}
        <button
          type="button"
          onClick={() => navigate(-1)}
          className="inline-flex items-center rounded-md border mb-6 border-transparent bg-indigo-100 px-3 py-2 text-sm font-medium leading-4 text-indigo-700 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
        >
          <span className="w-5 mr-2">
            <ArrowUturnLeftIcon size={18} />
          </span>{" "}
          Back
        </button>
        <div className="sm:flex-auto">
          <h1 className="text-xl font-semibold text-gray-900">Plan View</h1>
        </div>
        <div className="mt-4 sm:flex-auto border-t border-b border-gray-300 py-4">
          <p>
            <span className="text-gray-500 font-semibold">Name: </span>
            {planData.patientData?.appointment?.patient?.firstName +
              " " +
              planData.patientData?.appointment?.patient?.lastName +
              " - " +
              planData.patientData?.appointment?.patient?.patient_id}
          </p>
          <p>
            <span className="text-gray-500 font-semibold">Program Name: </span>
            NHDP/A1
          </p>
          <p>
            <span className="text-gray-500 font-semibold">Age: </span>
            {planData.data?.appointment?.patient?.age}
          </p>
          <div className="flex gap-3">
            <div>
              <span className="text-gray-500 font-semibold">Month </span>
              <select
                value={month_id || month}
                disabled={month_id ? true : false}
                className="border border-gray-300 rounded-md w-20 "
                onChange={(e) => {
                  setMonthFilter(e.target.value);
                }}
              >
                <option value={1}>{1}</option>
                <option value={2}>{2}</option>
                <option value={3}>{3}</option>
              </select>
            </div>
            <div>
              <span className="text-gray-500 font-semibold">Department: </span>
              <select
                value={department}
                className="border border-gray-300 rounded-md w-20 "
                onChange={(e) => {
                  setDepartment(Number(e.target.value));
                }}
              >
                <option>Select Department</option>
                {planData.filrData &&
                  planData.filrData?.length > 0 &&
                  planData.filrData?.map((item, idx) => (
                    <option value={item.department_id}>
                      {item?.department?.name}
                    </option>
                  ))}
              </select>
            </div>
            <div className="flex gap-3">
              <span className="text-gray-500 mr-2 font-semibold">Week: </span>
              <Select
                isMulti
                name="week"
                defaultValue={[{ value: 1, label: 1 }]}
                options={[
                  { value: 1, label: 1 },
                  { value: 2, label: 2 },
                  { value: 3, label: 3 },
                  { value: 4, label: 4 },
                ]}
                onChange={(e) => {
                  setWeek(e.map((item) => item.value));
                }}
              />
            </div>
            <input
              type="date"
              value={dateFilter}
              onChange={(e) => setDateFilter(e.target.value)}
              className="border border-gray-300 rounded-md w-40"
            />
            <button
              className="inline-flex items-center rounded-md border border-transparent bg-indigo-100 px-3 py-2 text-sm font-medium leading-4 text-indigo-700 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              type="button"
              onClick={() => {
                setState({ ...pdfViewer, state: true });
              }}
            >
              Preview
            </button>
          </div>
        </div>
        {planData.filrData &&
          planData.filrData?.length > 0 &&
          planData.filrData
            ?.filter((item) => item.department_id == department)
            .map((item, idx) => (
              <div className="mt-4 sm:flex-auto border-t border-b border-gray-300 py-4">
                <p>
                  <span className="text-gray-500 font-semibold">
                    Department:{" "}
                  </span>
                  {item?.department?.name}
                </p>

                {planData.filrData &&
                  planData.filrData.length > 0 &&
                  item?.weekly_data_of_current_month_dep
                    .filter((w) => week.includes(Number(w.week_number)))
                    ?.map((WeekItem, idx) => (
                      <div className="mt-4 sm:flex-auto ">
                        <p className="text-gray-500 font-semibold">
                          <span>Week:</span> {WeekItem?.week_number}
                        </p>
                        {/* {console.log(WeekItem?.week_data?.goal_activity_data)} */}
                        <CustomTablePlanView
                          api={getPlanById}
                          dateFilter={dateFilter}
                          setDateFilter={setDateFilter}
                          data={WeekItem?.week_data?.goal_activity_data}
                        />
                        <Table
                          data={WeekItem?.extraActivity}
                          columns={extra_activity_columns({
                            name: "Extra activity",
                          })}
                        />
                        <div className="mt-4 sm:flex-auto ">
                          <Formik
                            initialValues={{
                              week_number: WeekItem?.week_number,
                              value: "",
                              type: "extraActivity",
                              department_id: department,
                              enrollment_id: Number(id),
                            }}
                            onSubmit={async (values, action) => {
                              const header = dispatch(getHeaders());
                              try {
                                const res = await axios.post(
                                  `${process.env.REACT_APP_API_URL}/api/enrollment/add-extra-plan`,
                                  values,
                                  {
                                    headers: header,
                                  }
                                );
                                if (res) {
                                  getPlanById();
                                  toast.success(
                                    "Extra Activity Added Successfully"
                                  );
                                }
                              } catch (error) {
                                toast.error(error.response.data.message);
                              }
                            }}
                          >
                            {({ values, handleChange, handleSubmit }) => (
                              <form onSubmit={handleSubmit}>
                                <div className="mt-4">
                                  <div className="py-1">
                                    <div className="flex flex-col mt-2">
                                      <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                        Extra Activity
                                      </label>
                                      <textarea
                                        name="value"
                                        value={values.value}
                                        onChange={handleChange}
                                        rows={3}
                                        className="w-full mt-2 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                                        placeholder="Extra Activity"
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="w-full flex items-end justify-end">
                                  <button
                                    type="submit"
                                    className="mt-4 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                  >
                                    Submit
                                  </button>
                                </div>
                              </form>
                            )}
                          </Formik>
                        </div>
                        {Number(department) === 34 && (
                          <>
                            {" "}
                            <Table
                              data={WeekItem?.extraConcern}
                              columns={extra_activity_columns({
                                name: "Extra Concern",
                              })}
                            />
                            <div className="mt-4 sm:flex-auto ">
                              <Formik
                                initialValues={{
                                  week_number: WeekItem?.week_number,
                                  value: "",
                                  type: "extraConcern",
                                  department_id: department,
                                  enrollment_id: Number(id),
                                }}
                                onSubmit={async (values, action) => {
                                  const header = dispatch(getHeaders());
                                  try {
                                    const res = await axios.post(
                                      `${process.env.REACT_APP_API_URL}/api/enrollment/add-extra-plan`,
                                      values,
                                      {
                                        headers: header,
                                      }
                                    );
                                    if (res) {
                                      toast.success(
                                        "Extra Concern Added Successfully"
                                      );
                                      getPlanById();
                                    }
                                  } catch (error) {
                                    toast.error(error.response.data.message);
                                  }
                                }}
                              >
                                {({ values, handleChange, handleSubmit }) => (
                                  <form onSubmit={handleSubmit}>
                                    <div className="mt-1">
                                      <div className="py-1">
                                        <div className="flex flex-col mt-1">
                                          <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                            Extra Concern
                                          </label>
                                          <textarea
                                            name="value"
                                            value={values.value}
                                            onChange={handleChange}
                                            rows={3}
                                            className="w-full mt-2 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                                            placeholder="Extra Concern"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div className="w-full flex items-end justify-end">
                                      <button
                                        type="submit"
                                        className="mt-4 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                      >
                                        Submit
                                      </button>
                                    </div>
                                  </form>
                                )}
                              </Formik>
                            </div>{" "}
                          </>
                        )}
                        <Table
                          data={WeekItem?.extraFeedback}
                          columns={extra_feedback({
                            name: "Extra Feedback",
                          })}
                        />
                        <div className="mt-4 sm:flex-auto ">
                          <Formik
                            initialValues={{
                              week_number: WeekItem?.week_number,
                              value: "",
                              type: "extraFeedback",
                              department_id: department,
                              enrollment_id: Number(id),
                              selected_goals: {},
                              goal_master_id: "",
                            }}
                            onSubmit={async (values, action) => {
                              const header = dispatch(getHeaders());
                              try {
                                const res = await axios.post(
                                  `${process.env.REACT_APP_API_URL}/api/enrollment/add-extra-plan`,
                                  values,
                                  {
                                    headers: header,
                                  }
                                );
                                if (res) {
                                  toast.success(
                                    "Extra Feedback Added Successfully"
                                  );
                                  getPlanById();
                                }
                              } catch (error) {
                                toast.error(error.response.data.message);
                              }
                            }}
                          >
                            {({
                              values,
                              handleChange,
                              setValues,
                              handleSubmit,
                            }) => (
                              <form onSubmit={handleSubmit}>
                                <div className="mt-1">
                                  <div className="py-1">
                                    <div className="flex flex-col mt-1">
                                      <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                        Goals
                                      </label>
                                      <Select
                                        options={goals.data.map((p) => ({
                                          value: p.id,
                                          label: p.name,
                                        }))}
                                        value={values.selected_goals}
                                        onChange={(e) => {
                                          setValues({
                                            ...values,
                                            selected_goals: e,
                                            goal_master_id: Number(e.value),
                                          });
                                        }}
                                        classNamePrefix="select"
                                      />
                                    </div>
                                    <div className="flex flex-col mt-1">
                                      <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                        Extra Feedback
                                      </label>
                                      <textarea
                                        name="value"
                                        value={values.value}
                                        onChange={handleChange}
                                        rows={3}
                                        className="w-full mt-2 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                                        placeholder="Extra Feedback"
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="w-full flex items-end justify-end">
                                  <button
                                    type="submit"
                                    className="mt-4 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                  >
                                    Submit
                                  </button>
                                </div>
                              </form>
                            )}
                          </Formik>
                        </div>
                        {Number(department) === 34 && (
                          <>
                            {" "}
                            <Table
                              data={WeekItem?.extraImprovments}
                              columns={extra_activity_columns({
                                name: "Extra Improvments",
                              })}
                            />
                            <div className="mt-4 sm:flex-auto ">
                              <Formik
                                initialValues={{
                                  week_number: WeekItem?.week_number,
                                  value: "",
                                  type: "extraImprovments",
                                  department_id: department,
                                  enrollment_id: Number(id),
                                }}
                                onSubmit={async (values, action) => {
                                  const header = dispatch(getHeaders());
                                  try {
                                    const res = await axios.post(
                                      `${process.env.REACT_APP_API_URL}/api/enrollment/add-extra-plan`,
                                      values,
                                      {
                                        headers: header,
                                      }
                                    );
                                    if (res) {
                                      toast.success(
                                        "Extra Improvments Added Successfully"
                                      );
                                      getPlanById();
                                    }
                                  } catch (error) {
                                    toast.error(error.response.data.message);
                                  }
                                }}
                              >
                                {({ values, handleChange, handleSubmit }) => (
                                  <form onSubmit={handleSubmit}>
                                    <div className="mt-1">
                                      <div className="py-1">
                                        <div className="flex flex-col mt-1">
                                          <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                            Extra Improvments
                                          </label>
                                          <textarea
                                            name="value"
                                            value={values.value}
                                            onChange={handleChange}
                                            rows={3}
                                            className="w-full mt-2 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                                            placeholder="Extra Improvments"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div className="w-full flex items-end justify-end">
                                      <button
                                        type="submit"
                                        className="mt-4 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                      >
                                        Submit
                                      </button>
                                    </div>
                                  </form>
                                )}
                              </Formik>
                            </div>
                          </>
                        )}
                      </div>
                    ))}
              </div>
            ))}
      </div>
    </div>
  );
};

export default PlanView;
