import React, { Fragment, useState, useRef, useEffect } from "react";
import { toast } from "react-hot-toast";
import Confirm_model from "./Confirm";
import slugify from "slugify";
import moment from "moment";
import { autoBatchEnhancer } from "@reduxjs/toolkit";
export default function Field_comp(props) {
  const [editmode, seteditmode] = useState(props.editmode);
  const target_option = useRef(null);
  const type_default = useRef();
  const [mode, setmode] = useState("create");
  const [fieldtype, setfieldtype] = useState(props.filldata.type);
  const [confirm, setconfirm] = useState(false);
  const [delete_flag, setdelete_flag] = useState(false);
  const [child, set_child] = useState(false);
  const [parent_options, set_parent_options] = useState([
    props.filldata.parent?.option,
  ]);

  const [label, setlabel] = useState(props.filldata.label);
  const [data, setdata] = useState(props.filldata);
  const [options, setoptions] = useState(props.filldata.options);
  let [counter, setcounter] = useState(0);
  let fieldref = useRef();
  let labelref = useRef();
  let requiredref = useRef();
  let avail = props.available_fields;
  function process_type(e) {
    if (e.target.value == "RelationMaster") {
      setdata({
        label: labelref.current.value,
        placeholder: "",
        required: requiredref.current.checked,
        reference_column_name: props.filldata.ref_id,
        relation_master_table: "medicineMaster",
        child: false,
        max: "",
        min: "",
        options: "",
        maxlength: "",
        minlength: "",
        file_Type: "",
        default_Value: "",
        type: e.target.value,
      });
    } else if (props.filldata.type != e.target.value) {
      setdata({
        label: labelref.current.value,
        placeholder: "",
        reference_column_name: "",
        child: false,
        parent: null,
        required: requiredref.current.checked,
        max: "",
        min: "",
        options: "",
        maxlength: "",
        minlength: "",
        file_Type: "",
        default_Value: "",
        type: e.target.value,
      });
    } else {
      setdata({
        label: labelref.current.value,
        placeholder: "",
        required: requiredref.current.checked,
        reference_column_name: props.filldata.ref_id,
        child: false,
        parent: null,
        max: "",
        min: "",
        options: "",
        maxlength: "",
        minlength: "",
        file_Type: "",
        default_Value: "",
        type: e.target.value,
      });
    }
    setoptions({});

    if (["radio", "checkbox", "select"].includes(e.target.value)) {
      setfieldtype("options");
    } else {
      setfieldtype(e.target.value);
    }
  }
  function deletefield() {
    props.deletefield(props.identy);
  }
  function savedata() {
    if (editmode) {
      if (validate_data()) {
        seteditmode(0);
        setmode("edit");
        setconfirm(false);
        // setdata({
        //   ...data,
        //   fieldname: slugify(data.label, {
        //     replacement: '_',
        //     remove: '/[^w ]/g',
        //     lower: true,
        //   }),
        //   type: type_default.current.value,
        // });
        props.savedata(data, props.identy);
      }
    }
  }

  function delete_option(e) {
    let buf_opt = options;
    delete buf_opt[parseInt(e.target.id.split("__")[1])];
    setoptions({ ...buf_opt });
  }
  function cancel_edit() {
    if (mode === "create" && String(props.identy).includes("new")) {
      props.deletefield(props.identy);
    } else {
      setdata(props.filldata);
      setfieldtype(props.filldata.type);
      seteditmode(0);
    }
  }
  useEffect(() => {
    function check_condition() {
      let po = Object.keys(props.alldata).filter((curr_data) => {
        if (
          props.alldata[curr_data].type == "select" &&
          props.alldata[curr_data].label == props.filldata.parent?.label
        ) {
          return props.alldata[curr_data];
        }
      });
      if (po.length > 0 && props.alldata[po]) {
        set_parent_options(Object.values(props.alldata[po]?.options));
      }
    }
    check_condition();
  }, []);
  function validate_data() {
    // if (!data.fieldname) {
    //   toast.error('please add fieldname');
    //   return false;
    // }

    if (!data.label) {
      toast.error("please add label");
      return false;
    } else if (
      // fieldtype == 'text' ||
      // data.type == 'text' ||
      fieldtype == "password" &&
      (!data.maxlength || !data.minlength)
    ) {
      toast.error("please fill maxlength,minlength positve length only");
    } else if (
      (fieldtype == "text" ||
        data.type == "text" ||
        fieldtype == "password" ||
        fieldtype == "password") &&
      (data.minlength < 0 || data.maxlength < 0)
    ) {
      toast.error("please add valid length");
    } else if (
      (fieldtype == "text" ||
        data.type == "text" ||
        fieldtype == "password" ||
        fieldtype == "password") &&
      data.maxlength &&
      data.minlength &&
      data.maxlength < data.minlength
    ) {
      toast.error("max length can't be less that min length");
    }
    // else if (fieldtype == 'number' && (!data.max || !data.min)) {
    //   toast.error('please fill max and min value');
    // }
    else if (
      fieldtype == "number" &&
      data.max &&
      data.min &&
      data.max < data.min
    ) {
      toast.error("max  can't be less that min ");
    } else if (
      ["radio", "select", "checkbox"].includes(data.type) &&
      Object.keys(options).length < 2
    ) {
      toast.error("atleast two options required");
    } else if (
      (fieldtype == "date-time" || fieldtype == "date") &&
      (!data.max || !data.min)
    ) {
      toast.error("please fill max,min  positve length only");
    } else if (
      (fieldtype == "date-time" || fieldtype == "date") &&
      data.max < data.min
    ) {
      toast.error("min should be less than max ");
    } else if (
      data.child == true &&
      (data.parent == null || data.parent == "")
    ) {
      toast.error("please select parent");
    } else if (data?.parent?.label && !data?.parent?.option) {
      toast.error("please select parent option");
    } else {
      return true;
    }
  }
  function update_option(e) {
    let id = e.target.parentElement.id;
    if (
      e.target.value.replace(" ", "").length != 0 &&
      !Object.values(options).includes(e.target.value)
    ) {
      setoptions({
        ...options,
        [parseInt(id.split("_")[1])]: e.target.value,
      });
    } else {
      e.target.value = options[parseInt(id.split("_")[1])];
      e.preventDefault();
      toast.error("option not eligable");
    }
  }
  function process_child(e) {
    setdata({ ...data, child: e.target.checked, parent: null });
  }
  function process_parent(e) {
    if (e.target.value == "select option") {
      setdata({ ...data, parent: null });
    } else {
      let po = Object.keys(props.alldata).filter((curr_data) => {
        if (
          props.alldata[curr_data].type == "select" &&
          props.alldata[curr_data].label.trim() == e.target.value.trim()
        ) {
          return props.alldata[curr_data];
        }
      });
      set_parent_options(Object.values(props.alldata[po].options));
      setdata({ ...data, parent: { label: e.target.value } });
    }
  }
  function process_parent_option(e) {
    console.log(e.target.value);
    if (e.target.value != "select option") {
      setdata({ ...data, parent: { ...data.parent, option: e.target.value } });
    } else {
      setdata({ ...data, parent: { ...data.parent } });
    }
  }
  useEffect(() => {
    setdata({ ...data, options: options });
  }, [options]);

  function add_option() {
    if (Object.values(options).includes(target_option.current.value)) {
      toast.error("already added");
    } else if (target_option.current.value.replace(" ", "").length == 0) {
      toast.error("empty option not allowed");
    } else if (target_option.current.value.length) {
      let temp_count = counter;
      setoptions({ ...options, [temp_count + 1]: target_option.current.value });
      setcounter(counter + 1);

      target_option.current.value = "";
    }
  }
  return (
    <>
      <tr className="cursor-move" name={props.identy}>
        {editmode ? (
          <td>
            <div class="w-30 p-3">
              <div class="">
                <button
                  class="p-5 bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 border border-blue-500 hover:border-transparent rounded"
                  onClick={cancel_edit}
                >
                  Cancel
                </button>
              </div>
            </div>
          </td>
        ) : (
          <td>
            <div class=" w-30 p-3">
              <div class="">
                <button
                  class="p-5 bg-transparent hover:bg-red-500 text-red-700 font-semibold hover:text-white py-2 border border-red-500 hover:border-transparent rounded"
                  onClick={() => {
                    if (!editmode) {
                      setdelete_flag(true);
                    }
                  }}
                >
                  Delete
                </button>
              </div>
            </div>
          </td>
        )}

        <td>
          <div class=" w-20 p-0">
            <div class="">
              <button
                class="p-5 bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded"
                onClick={() => {
                  if (editmode && validate_data()) {
                    setconfirm(true);
                  } else {
                    seteditmode(1);
                  }
                }}
              >
                {editmode ? "Save" : "Edit"}
              </button>
            </div>
          </div>
        </td>
        {delete_flag ? (
          <Confirm_model
            className="z-50"
            header={"Delete Field"}
            para={"are you sure you want to delete this field?"}
            action={() => {
              props.delete_field_data(props.identy);
            }}
            setPopUp={() => {
              setdelete_flag(!delete_flag);
            }}
          />
        ) : (
          ""
        )}
        {confirm ? (
          <>
            <Confirm_model
              className="z-50"
              header={"Save Field"}
              para={"are you sure you want to save this field?"}
              action={savedata}
              setPopUp={() => {
                setconfirm(!confirm);
              }}
            />
          </>
        ) : (
          ""
        )}
        {/* <td className=" px-1 py-4 whitespace-nowrap">
          {' '}
          <input
            ref={fieldref}
            disabled={!editmode ? true : false}
            type="text"
            onChange={(e) => {
              setdata({ ...data, fieldname: e.target.value });
            }}
            value={data.fieldname}
            class={`bg-white disabled:bg-gray-100 border text-sm rounded-lg border-gray-300 focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 `}
            placeholder="Field name"
            required
          />
        </td> */}
        <td>
          <div class=" w-40 p-0">
            <div class="">
              <input
                disabled={!editmode ? true : false}
                ref={labelref}
                type="text"
                class={`bg-white disabled:bg-gray-100 border text-sm rounded-lg border-gray-300 focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 `}
                placeholder="Label"
                value={data.label}
                onChange={(e) => {
                  setdata({ ...data, label: e.target.value });
                }}
              />
            </div>
          </div>
        </td>
        <td>
          <div class=" w-48 p-0">
            <div className="flex flex-col">
              <div className="flex flex-row">
                <div class=" m-2 w-full">
                  <select
                    ref={type_default}
                    disabled={!editmode ? true : false}
                    class={`bg-white disabled:bg-gray-100 border text-sm rounded-lg border-gray-300 focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 `}
                    aria-label="Default select example"
                    value={data.type}
                    onChange={process_type}
                    defaultValue={"text"}
                  >
                    {(editmode == 0 &&
                      !String(data.id).includes("new") &&
                      (avail.string || fieldtype == "text")) ||
                    (editmode && fieldtype == "text") ? (
                      <option>text</option>
                    ) : editmode && avail.string ? (
                      <option>text</option>
                    ) : (
                      ""
                    )}
                    {(editmode == 0 &&
                      !String(data.id).includes("new") &&
                      (avail.string || fieldtype == "file")) ||
                    (editmode && fieldtype == "file") ? (
                      <option>file</option>
                    ) : editmode && avail.string ? (
                      <option>file</option>
                    ) : (
                      ""
                    )}
                    <option>heading</option>
                    {(editmode == 0 &&
                      !String(data.id).includes("new") &&
                      (avail.number || fieldtype == "number")) ||
                    (editmode && fieldtype == "number") ? (
                      <option>number</option>
                    ) : editmode && avail.number ? (
                      <option>number</option>
                    ) : (
                      ""
                    )}
                    {(editmode == 0 &&
                      !String(data.id).includes("new") &&
                      (avail.dateTime || fieldtype == "date-time")) ||
                    (editmode && fieldtype == "date-time") ? (
                      <option>date-time</option>
                    ) : editmode && avail.dateTime ? (
                      <option>date-time</option>
                    ) : (
                      ""
                    )}
                    {(editmode == 0 &&
                      !String(data.id).includes("new") &&
                      (avail.date || fieldtype == "date")) ||
                    (editmode && fieldtype == "date") ? (
                      <option>date</option>
                    ) : editmode && avail.dateTime ? (
                      <option>date</option>
                    ) : (
                      ""
                    )}
                    {(editmode == 0 &&
                      !String(data.id).includes("new") &&
                      (avail.string || fieldtype == "radio")) ||
                    (editmode && fieldtype == "radio") ? (
                      <option>radio</option>
                    ) : editmode && avail.string ? (
                      <option>radio</option>
                    ) : (
                      ""
                    )}
                    {(editmode == 0 &&
                      !String(data.id).includes("new") &&
                      (avail.string || fieldtype == "checkbox")) ||
                    (editmode && fieldtype == "checkbox") ? (
                      <option>checkbox</option>
                    ) : editmode && avail.string ? (
                      <option>checkbox</option>
                    ) : (
                      ""
                    )}
                    {(editmode == 0 &&
                      !String(data.id).includes("new") &&
                      (avail.string || fieldtype == "select")) ||
                    (editmode && fieldtype == "select") ? (
                      <option>select</option>
                    ) : editmode && avail.string ? (
                      <option>select</option>
                    ) : (
                      ""
                    )}
                    {(editmode == 0 &&
                      !String(data.id).includes("new") &&
                      (avail.string || fieldtype == "RelationMaster")) ||
                    (editmode && fieldtype == "RelationMaster") ? (
                      <option>RelationMaster</option>
                    ) : editmode && avail.string ? (
                      <option>RelationMaster</option>
                    ) : (
                      ""
                    )}
                    {(editmode == 0 &&
                      !String(data.id).includes("new") &&
                      (avail.string || fieldtype == "text-area")) ||
                    (editmode && fieldtype == "text-area") ? (
                      <option>text-area</option>
                    ) : editmode && avail.string ? (
                      <option>text-area</option>
                    ) : (
                      ""
                    )}
                    {(editmode == 0 &&
                      !String(data.id).includes("new") &&
                      (avail.string || fieldtype == "email")) ||
                    (editmode && fieldtype == "email") ? (
                      <option>email</option>
                    ) : editmode && avail.string ? (
                      <option>email</option>
                    ) : (
                      ""
                    )}
                    {(editmode == 0 &&
                      !String(data.id).includes("new") &&
                      (avail.string || fieldtype == "hiddden")) ||
                    (editmode && fieldtype == "hiddden") ? (
                      <option>hiddden</option>
                    ) : editmode && avail.string ? (
                      <option>hiddden</option>
                    ) : (
                      ""
                    )}
                    {(editmode == 0 &&
                      !String(data.id).includes("new") &&
                      (avail.string || fieldtype == "password")) ||
                    (editmode && fieldtype == "password") ? (
                      <option>password</option>
                    ) : editmode && avail.string ? (
                      <option>password</option>
                    ) : (
                      ""
                    )}
                  </select>
                </div>
                <div class=" mt-5">
                  <input
                    // value={data.options[val]}
                    disabled={!editmode ? true : false}
                    name="comments"
                    type="checkbox"
                    onChange={process_child}
                    checked={data.child}
                    className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                  />
                </div>
              </div>
              {data.child ? (
                <div className="flex flex-row">
                  <div class=" m-2 w-full">
                    <select
                      ref={type_default}
                      disabled={!editmode ? true : false}
                      class={`bg-white w-40 disabled:bg-gray-100 border text-sm rounded-lg border-gray-300 focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 `}
                      value={data?.parent?.label}
                      onChange={process_parent}
                    >
                      <option>select option</option>
                      {Object.keys(props.alldata).map((data) => {
                        if (props.alldata[data].type == "select") {
                          return <option>{props.alldata[data].label}</option>;
                        }
                      })}
                    </select>
                  </div>
                </div>
              ) : (
                ""
              )}
              {data?.parent ? (
                <div className="flex flex-row">
                  <div class=" m-2 w-full">
                    <select
                      ref={type_default}
                      disabled={!editmode ? true : false}
                      class={`bg-white w-40 disabled:bg-gray-100 border text-sm rounded-lg border-gray-300 focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 `}
                      aria-label="Default select example"
                      value={data?.parent?.option}
                      onChange={process_parent_option}
                      defaultValue={"text"}
                    >
                      <option>select option</option>
                      {parent_options.map((opt) => {
                        return <option>{opt}</option>;
                      })}
                    </select>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </td>
        {/* <td>
          <div class=" w-40 p-0">
            <div class="">
              <input
                disabled={
                  ['text', 'number', 'text-area', 'email', 'password'].includes(
                    fieldtype
                  ) && editmode
                    ? false
                    : true
                }
                type="text"
                ref={requiredref}
                value={data.placeholder}
                onChange={(e) => {
                  setdata({ ...data, placeholder: e.target.value });
                }}
                class={`bg-white disabled:bg-gray-100 border text-sm rounded-lg border-gray-300 focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 `}
                placeholder="Place Holder"
                required
              />{' '}
            </div>
          </div>
        </td> */}
        <td>
          <div class="w-24 flex justify-center">
            <div class="form-check form-switch">
              <input
                checked={data.required}
                ref={requiredref}
                disabled={!editmode ? true : false}
                onChange={(e) => {
                  setdata({ ...data, required: e.target.checked });
                }}
                class="bg-white disabled:bg-blue-300 border text-sm rounded-lg border-gray-300 checked focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  focus:outline-none cursor-pointer shadow-sm"
                type="checkbox"
                role="switch"
              />
            </div>
          </div>
        </td>

        {
          //---
        }
        {fieldtype == "date-time" || fieldtype == "date" ? (
          <td>
            <div class=" w-36 p-0">
              <div class="">
                <input
                  onChange={(e) => {
                    setdata({
                      ...data,
                      min: new Date(e.target.value).getTime(),
                    });
                  }}
                  disabled={
                    ["date-time", "date"].includes(fieldtype) && editmode
                      ? false
                      : true
                  }
                  type={fieldtype === "date" ? "date" : "datetime-local"}
                  defaultValue={
                    fieldtype === "date-time"
                      ? `${moment(new Date(props.filldata.min)).format(
                          "YYYY-MM-DD"
                        )}T${moment(new Date(props.filldata.min)).format(
                          "mm:ss"
                        )}`
                      : `${moment(new Date(props.filldata.min)).format(
                          "YYYY-MM-DD"
                        )}`
                  }
                  class={`bg-white disabled:bg-gray-100 border text-sm rounded-lg border-gray-300 focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 `}
                  placeholder="Min Date"
                  required
                />
              </div>
            </div>
          </td>
        ) : (
          <td>
            <div class=" w-36 p-0">
              <div class="">
                <input
                  onChange={(e) => {
                    setdata({ ...data, min: parseInt(e.target.value) });
                  }}
                  value={parseInt(data.min)}
                  disabled={
                    ["number"].includes(fieldtype) && editmode ? false : true
                  }
                  type={"number"}
                  class={`bg-white disabled:bg-gray-100 border text-sm rounded-lg border-gray-300 focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 `}
                  placeholder="Min number"
                  required
                />
              </div>
            </div>
          </td>
        )}
        {
          //---
        }
        {
          //---
        }
        {fieldtype == "date-time" || fieldtype == "date" ? (
          <td>
            <div class=" w-36 p-0">
              <div class="">
                <input
                  onChange={(e) => {
                    setdata({
                      ...data,
                      max: new Date(e.target.value).getTime(),
                    });
                  }}
                  defaultValue={
                    fieldtype === "date-time"
                      ? `${moment(new Date(props.filldata.max)).format(
                          "YYYY-MM-DD"
                        )}T${moment(new Date(props.filldata.max)).format(
                          "mm:ss"
                        )}`
                      : `${moment(new Date(props.filldata.max)).format(
                          "YYYY-MM-DD"
                        )}`
                  }
                  disabled={
                    ["date-time", "date"].includes(fieldtype) && editmode
                      ? false
                      : true
                  }
                  type={fieldtype === "date" ? "date" : "datetime-local"}
                  class={`bg-white disabled:bg-gray-100 border text-sm rounded-lg border-gray-300 focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 `}
                  placeholder="Max number"
                  required
                />{" "}
              </div>
            </div>
          </td>
        ) : (
          <td>
            <div class=" w-36 p-0">
              <div class="">
                <input
                  onChange={(e) => {
                    setdata({ ...data, max: parseInt(e.target.value) });
                  }}
                  value={parseInt(data.max)}
                  disabled={
                    ["number"].includes(fieldtype) && editmode ? false : true
                  }
                  type={"number"}
                  class={`bg-white disabled:bg-gray-100 border text-sm rounded-lg border-gray-300 focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 `}
                  placeholder="Max number"
                  required
                />
              </div>
            </div>
          </td>
        )}

        <td>
          <div class=" w-20 p-0">
            <div class="">
              <input
                value={parseInt(data.minlength)}
                onChange={(e) => {
                  setdata({ ...data, minlength: parseInt(e.target.value) });
                }}
                min="0"
                disabled={
                  ["text", "text-area", "password"].includes(fieldtype) &&
                  editmode
                    ? false
                    : true
                }
                type="number"
                class={`bg-white disabled:bg-gray-100 border text-sm rounded-lg border-gray-300 focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 `}
                placeholder="Min length"
                required
              />
            </div>
          </div>
        </td>
        <td>
          <div class=" w-20 p-0">
            <div class="">
              <input
                onChange={(e) => {
                  setdata({ ...data, maxlength: parseInt(e.target.value) });
                }}
                value={parseInt(data.maxlength)}
                disabled={
                  ["text", "text-area", "password"].includes(fieldtype) &&
                  editmode
                    ? false
                    : true
                }
                min="0"
                type="number"
                class={`bg-white disabled:bg-gray-100 border text-sm rounded-lg border-gray-300 focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 `}
                placeholder="Max length"
                required
              />
            </div>
          </div>
        </td>
        <td>
          <div class=" w-36 p-0">
            <div class="">
              <select
                disabled={
                  ["file"].includes(fieldtype) && editmode ? false : true
                }
                onChange={(e) => {
                  setdata({ ...data, file_Type: e.target.value });
                }}
                value={data.file_Type}
                class="form-select appearance-none
                disabled:bg-gray-100
        block
        w-full
        px-1
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding bg-no-repeat
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                aria-label="Default select example"
              >
                <option selected>all</option>

                <option>image</option>
                <option>video</option>
                <option>media</option>
                <option>excel</option>
                <option>pdf </option>
              </select>
            </div>
          </div>
        </td>
        <td>
          <div class=" w-40 p-0">
            <div class="">
              {fieldtype == "text" || fieldtype == "text-area" ? (
                <input
                  disabled={
                    ["text-area", "text", "number"].includes(fieldtype) &&
                    editmode
                      ? false
                      : true
                  }
                  defaultValue={data.defaultValue}
                  minLength={data.minlength}
                  maxLength={data.maxlength}
                  onChange={(e) => {
                    setdata({ ...data, defaultValue: e.target.value });
                  }}
                  type="text"
                  class={`bg-white disabled:bg-gray-100 border text-sm rounded-lg border-gray-300 focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 `}
                  placeholder="Default Value"
                  required
                />
              ) : (
                <input
                  disabled={
                    ["text-area", "text", "number"].includes(fieldtype) &&
                    editmode
                      ? false
                      : true
                  }
                  min={data.min}
                  max={data.max}
                  defaultValue={data.defaultValue}
                  onChange={(e) => {
                    setdata({ ...data, defaultValue: e.target.value });
                  }}
                  type="number"
                  class={`bg-white disabled:bg-gray-100 border text-sm rounded-lg border-gray-300 focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 `}
                  placeholder="Default Value"
                  required
                />
              )}
            </div>
          </div>
        </td>
        {/* <td className="mx-66 px-1 py-4 whitespace-nowrap">
          <button
            class="p-5 bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded"
            onClick={deletefield}
          >
            Delete
          </button>
        </td> */}
      </tr>
      {["select", "checkbox", "radio", "options"].includes(fieldtype) &&
      editmode ? (
        <tr>
          <td colspan="4" className="col-span-14">
            <span class="m-5 text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-indigo-600 bg-indigo-200 uppercase last:mr-0 mr-1">
              Options:
            </span>
            <div>
              {Object.keys(options).map((o) => {
                return (
                  <div
                    id={`${options}_${o}`}
                    className="flex mt-2 mb-5 flex-row"
                  >
                    <input
                      type="text"
                      key={`${options}_${o}`}
                      defaultValue={options[o]}
                      onChange={update_option}
                      class={`ml-5 mt-1 bg-gray-50 border text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 `}
                      placeholder="Options"
                      value={options[o]}
                    />
                    <button
                      id={`${options}__${o}`}
                      class="ml-5 bg-transparent hover:bg-red-500 text-red-700 font-semibold hover:text-white px-4 border border-red-500 hover:border-transparent rounded"
                      onClick={delete_option}
                    >
                      Delete
                    </button>
                  </div>
                );
              })}
            </div>
            <div className="flex mt-5 mb-5 flex-row">
              <input
                ref={target_option}
                type="text"
                class={`ml-5  bg-gray-50 border ${
                  !editmode ? "border-gray-300 text-gray-900 " : ""
                }text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 `}
                placeholder="Options"
              />
              <button
                class="p-5 ml-5 bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded"
                onClick={add_option}
              >
                Add
              </button>
            </div>
          </td>
        </tr>
      ) : (
        ""
      )}
      {["select", "checkbox", "radio", "options"].includes(fieldtype) &&
      !editmode ? (
        <tr>
          <td colSpan={25}>
            <div className="inline-flex items-baseline">
              <span class="m-1 text-xs font-semibold  py-1 px-2 uppercase rounded-full text-indigo-600 bg-indigo-200 uppercase last:mr-0 mr-1">
                Options:
              </span>
              {Object.keys(options).map((o) => {
                return (
                  <span class="m-1 text-xs font-semibold  py-1  px-2 uppercase overflow-hidden h-6  rounded-full	w-10	 text-green-600 bg-green-200 uppercase last:mr-0 mr-1">
                    {options[o]}
                  </span>
                );
              })}
            </div>
          </td>
        </tr>
      ) : (
        ""
      )}
    </>
  );
}
